import { isString, trim } from 'lodash';
import { stringify } from 'safe-stable-stringify';
import { Logger } from '../logger';
import { REGEX } from '../regex';
import { chainFlow } from './chain-flow';

export function toBase64URL(data: string | Buffer): string {
  const base64 = isString(data) ? data : data.toString('base64');

  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

export function fromBase64URL(base64URL: string): string {
  const padding = 4 - (base64URL.length % 4);

  return base64URL.replace(/-/g, '+').replace(/_/g, '/') + '='.repeat(padding);
}

export function base64ToBuffer(base64: string): Buffer {
  return Buffer.from(fromBase64URL(base64), 'base64');
}

export function dataUrlToBuffer(dataUrl: string): Buffer {
  Logger.assert(dataUrl.startsWith('data:'), 'Invalid data url', { dataUrl });

  return base64ToBuffer(dataUrl.split(',')[1]);
}

export function base64JsonStringify(obj: Record<string, any>): string {
  return chainFlow(
    stringify(obj),
    (json) => Buffer.from(json).toString('base64'),
    (base64) => trim(base64, '=')
  );
}

export function base64JsonParse(base64: string): any {
  Logger.assert(isValidBase64String(base64), 'Not a base64 string', { base64 });

  return JSON.parse(base64ToBuffer(base64).toString());
}

function isValidBase64String(value: string): boolean {
  return isString(value) && REGEX.BASE64_STRING.test(value);
}
