import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'alc-info-block',
  standalone: true,
  imports: [CommonModule],
  templateUrl: 'info-block.component.html',
  styles: `
    :host {
      display: block;
    }
  `
})
export class AlcInfoBlockComponent {
  public readonly label = input.required<string>();
}
