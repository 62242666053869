import { FeathersService } from '$/app/services/feathers.service';
import { IncidentReportResidentsWsActions } from '$/app/store/incident-report-residents';
import { IIncidentReportResident } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class IncidentReportResidentsApiService extends AbstractApiService<IIncidentReportResident> {
  constructor(feathers: FeathersService, store: Store) {
    super('incident-report-residents', feathers, store, {
      entityName: 'incidentReportResident',
      created: IncidentReportResidentsWsActions.incidentReportResidentCreated,
      patched: IncidentReportResidentsWsActions.incidentReportResidentPatched,
      removed: IncidentReportResidentsWsActions.incidentReportResidentRemoved
    });
  }
}
