import { Table } from '$/models/data/base';
import { PhoneTypeId } from '$shared/lookups/phone-types.lookup';

export class ResidentContactPhone extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  contactId?: string;
  type?: PhoneTypeId;
  number?: string;
  label?: string;
  isPrimary?: boolean;
}
