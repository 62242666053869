import { FeathersService } from '$/app/services/feathers.service';
import { PrnAmountsApiActions } from '$/app/store/prn-amounts';
import { TPrnAmount } from '$shared/medications/prn-amount.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class PrnAmountsApiService extends AbstractApiService<TPrnAmount> {
  constructor(feathers: FeathersService, store: Store) {
    super('prn-amounts', feathers, store, {
      entityName: 'prnAmount',
      created: PrnAmountsApiActions.prnAmountsCreated,
      patched: PrnAmountsApiActions.prnAmountsPatched,
      removed: PrnAmountsApiActions.prnAmountsRemoved
    });
  }
}
