import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { isNil } from 'lodash';

export const equal = (val: any): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!isNil(Validators.required(control))) {
      return null;
    }

    const v: any = control.value;

    return val === v ? null : { equal: { value: val } };
  };
};
