import { Logger } from '$shared/logger';
import { IAppInfo } from '$shared/services/app-info';
import { buildNumber, version } from '$shared/version';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { v4 as uuid } from 'uuid';

export const AppInfo: IAppInfo = {
  // Each instance of the app will have a unique ID
  // This allows us to tell the difference between multiple instances
  // of the app running on the same device (e.g. browser tabs)
  appInstanceId: uuid(),
  version,
  buildNumber,
  nativeAppInfo: null,
  deviceInfo: null,
  deviceId: null
};

export async function initializeAppInfo() {
  Logger.debug('Initializing AppInfo - fetching deviceInfo and deviceId');

  [AppInfo.deviceInfo, AppInfo.deviceId] = await Promise.all([
    Device.getInfo(),
    Device.getId().then(({ identifier }) => identifier)
  ]);

  if (AppInfo.deviceInfo.platform !== 'web') {
    Logger.debug(
      'Initializing AppInfo - fetching nativeAppInfo and nativeVersion'
    );
    AppInfo.nativeAppInfo = await App.getInfo();
  }
}
