import { FeathersService } from '$/app/services/feathers.service';
import { MedicalProfessionalsApiActions } from '$/app/store/medical-professionals/actions';
import { MedicalProfessional } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class MedicalProfessionalsApiService extends AbstractApiService<MedicalProfessional> {
  constructor(store: Store, feathers: FeathersService) {
    super('medical-professionals', feathers, store, {
      entityName: 'medicalProfessional',
      created: MedicalProfessionalsApiActions.medicalProfessionalsCreated,
      patched: MedicalProfessionalsApiActions.medicalProfessionalsPatched,
      removed: MedicalProfessionalsApiActions.medicalProfessionalsRemoved
    });
  }
}
