import { ResidentInsuranceFormPageActions } from '$/app/pages/residents/resident-detail/shared/resident-insurance-form/resident-insurance-form.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ResidentInsurancesApiActions,
  ResidentInsurancesGeneralActions,
  ResidentInsurancesWsActions
} from './resident-insurances.actions';
import {
  initialState,
  residentInsurancesAdapter
} from './resident-insurances.state';

export const residentInsurancesFeature = createFeature({
  name: 'residentInsurances',
  reducer: createReducer(
    initialState,

    on(
      ResidentInsuranceFormPageActions.createInsurance,
      ResidentInsuranceFormPageActions.updateInsurance,
      ResidentInsuranceFormPageActions.deleteInsurance,
      (state) => {
        return {
          ...state,
          loading: true
        };
      }
    ),

    on(
      ResidentInsurancesApiActions.createResidentInsuranceSuccess,
      (state, action) => {
        return residentInsurancesAdapter.addOne(action.residentInsurance, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      ResidentInsurancesApiActions.updateResidentInsuranceSuccess,
      (state, action) => {
        return residentInsurancesAdapter.updateOne(action.residentInsurance, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      ResidentInsurancesApiActions.deleteResidentInsuranceSuccess,
      (state, action) => {
        return residentInsurancesAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      ResidentInsurancesApiActions.createResidentInsuranceFail,
      ResidentInsurancesApiActions.updateResidentInsuranceFail,
      ResidentInsurancesApiActions.deleteResidentInsuranceFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      ResidentInsurancesWsActions.residentInsurancesCreated,
      ResidentInsurancesWsActions.residentInsurancesPatched,
      (state, action) => {
        return residentInsurancesAdapter.upsertOne(
          action.residentInsurance,
          state
        );
      }
    ),

    on(
      ResidentInsurancesWsActions.residentInsurancesRemoved,
      (state, action) => {
        return residentInsurancesAdapter.removeOne(action.id, state);
      }
    ),

    on(ResidentInsurancesGeneralActions.clearResidentInsurances, (state) => {
      return residentInsurancesAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }),

    on(
      ResidentInsurancesGeneralActions.addResidentInsurances,
      (state, action) => {
        return residentInsurancesAdapter.upsertMany(action.residentInsurances, {
          ...state,
          loading: false,
          error: null
        });
      }
    )
  )
});
