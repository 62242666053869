import { ILibraryCollection } from '$shared/services/library-collection';
import { Paginated } from '$shared/types/pagination';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LibraryCollectionsApiActions = createActionGroup({
  source: 'Library Collections API',
  events: {
    // Success Actions
    'Load Library Collections Success': props<{
      libraryCollections: Paginated<ILibraryCollection>;
    }>(),
    'Get Library Collections Success': props<{
      libraryCollections: Paginated<ILibraryCollection>;
    }>(),
    'Fetch Library Collection Success': props<{
      libraryCollection: ILibraryCollection;
    }>(),
    'Create Library Collection Success': props<{
      libraryCollection: ILibraryCollection;
    }>(),
    'Update Library Collection Success': props<{
      libraryCollection: ILibraryCollection;
    }>(),
    'Delete Library Collection Success': props<{ id: string }>(),
    // Fail Actions
    'Load Library Collections Fail': props<{ error: Error }>(),
    'Get Library Collections Fail': props<{ error: Error }>(),
    'Fetch Library Collection Fail': props<{ error: Error }>(),
    'Create Library Collection Fail': props<{ error: Error }>(),
    'Update Library Collection Fail': props<{ error: Error }>(),
    'Delete Library Collection Fail': props<{ error: Error }>()
  }
});

export const LibraryCollectionsWsActions = createActionGroup({
  source: 'Library Collections WS',
  events: {
    'Library Collection Created': props<{
      libraryCollection: ILibraryCollection;
    }>(),
    'Library Collection Patched': props<{
      libraryCollection: ILibraryCollection;
    }>(),
    'Library Collection Removed': props<{ id: string }>()
  }
});

export const LibraryCollectionsGeneralActions = createActionGroup({
  source: 'Library Collections General',
  events: {
    'Clear Library Collections': emptyProps(),
    'Add Library Collections': props<{
      libraryCollections: ILibraryCollection[];
    }>()
  }
});
