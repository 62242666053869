import { createSelector } from '@ngrx/store';
import { countBy, keyBy } from 'lodash';

import { documentPacketDocumentsFeature } from './document-packet-documents.reducer';
import { documentPacketDocumentsAdapter } from './document-packet-documents.state';

export const {
  selectDocumentPacketDocumentsState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = documentPacketDocumentsFeature;

export const { selectAll, selectTotal } =
  documentPacketDocumentsAdapter.getSelectors(
    selectDocumentPacketDocumentsState
  );

export const selectByPacketIdAndDocumentId = createSelector(
  selectAll,
  (packetDocuments) => {
    return keyBy(
      packetDocuments,
      (packetDocument) =>
        `${packetDocument.documentPacketId}-${packetDocument.documentId}`
    );
  }
);

export const selectPacketCountByDocumentId = createSelector(
  selectAll,
  (packetDocuments) => {
    return countBy(packetDocuments, 'documentId');
  }
);
