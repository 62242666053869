import { Table } from '$/models/data/base';
import { FacilityUserGroupMember } from '$/models/data/entities/facility-user/facility-user-group-member.model';

export class FacilityUserGroup extends Table {
  orgId?: string;
  facilityId?: string;

  name?: string;
  description?: string;
  members?: FacilityUserGroupMember[];
}
