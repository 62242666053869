import { Update } from '$/models';
import { IBloodPressureLog } from '$shared/services/blood-pressure-log';
import { createActionGroup, props } from '@ngrx/store';

export const BloodPressureLogFormPageActions = createActionGroup({
  source: 'Blood Pressure Log Form Page',
  events: {
    'Create Blood Pressure Log': props<{
      bloodPressureLog: IBloodPressureLog;
    }>(),
    'Update Blood Pressure Log': props<Update<IBloodPressureLog>>()
  }
});
