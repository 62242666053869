import { createActionGroup, emptyProps } from '@ngrx/store';

export const AppActions = createActionGroup({
  source: 'App',
  events: {
    'Clear Store': emptyProps,
    'Clear All Non User State': emptyProps,

    // NOTE(2024-03-14): These are currently unused, but we can use these to implement the inactivity logout feature that Dario has requested
    'Reset Activity Timer': emptyProps,
    'Inactivity Logout': emptyProps
  }
});
