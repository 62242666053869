import { Table } from '$/models/data/base/table.model';
import { ITableBase } from '$shared/types';

export interface IIncidentReportNotificationRecipient {
  type?: string;
  name?: string;
  phoneNumber?: string;
}

export interface IIncidentReport extends ITableBase {
  orgId?: string;
  facilityId?: string;

  status?: string;
  occurredAt?: string;
  recordedBy?: string;
  approvedBy?: string;
  approvedAt?: string;
  submittedAt?: string;
  incidentTypes?: string[];
  incidentTypeOther?: string;
  typeOther?: string;
  summary?: string;
  description?: string;
  witnesses?: string;
  immediateAction?: string;
  medicalTreatmentNeeded?: boolean;
  medicalTreatmentDescription?: string;
  medicalTreatmentLocation?: string;
  medicalTreatmentGivenBy?: string;
  medicalTreatmentFollowup?: string;
  actionTaken?: string;
  licenseeComments?: string;
  notes?: string;
  attendingPhysician?: string;
  notificationRecipients?: IIncidentReportNotificationRecipient[];
}

export interface IIncidentReportResident extends ITableBase {
  orgId?: string;
  facilityId?: string;

  incidentReportId?: string;
  residentId?: string;
}

export class IncidentReport extends Table implements IIncidentReport {
  id: string;
  createdBy: string;
  createdAt: string;
  orgId?: string;
  facilityId?: string;

  status?: string;
  occurredAt?: string;
  recordedBy?: string;
  approvedBy?: string;
  approvedAt?: string;
  submittedAt?: string;
  incidentTypes?: string[];
  incidentTypeOther?: string;
  typeOther?: string;
  summary?: string;
  description?: string;
  witnesses?: string;
  immediateAction?: string;
  medicalTreatmentNeeded?: boolean;
  medicalTreatmentDescription?: string;
  medicalTreatmentLocation?: string;
  medicalTreatmentGivenBy?: string;
  medicalTreatmentFollowup?: string;
  actionTaken?: string;
  licenseeComments?: string;
  notes?: string;
  attendingPhysician?: string;
  notificationRecipients?: IIncidentReportNotificationRecipient[];

  constructor(incidentReport?: IIncidentReport) {
    super();

    if (incidentReport) {
      Object.assign(this, incidentReport);
    }
  }
}
