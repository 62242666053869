import { FeathersService } from '$/app/services/feathers.service';
import { BodyCheckObservationsWsActions } from '$/app/store/body-check-observations/body-check-observations.actions';
import { IBodyCheckObservation } from '$shared/services/body-check-observation';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class BodyCheckObservationsApiService extends AbstractApiService<IBodyCheckObservation> {
  constructor(feathers: FeathersService, store: Store) {
    super('body-check-observations', feathers, store, {
      entityName: 'bodyCheckObservation',
      created: BodyCheckObservationsWsActions.bodyCheckObservationsCreated,
      patched: BodyCheckObservationsWsActions.bodyCheckObservationsPatched,
      removed: BodyCheckObservationsWsActions.bodyCheckObservationsRemoved
    });
  }
}
