import { isValidPhoneNumber } from '$shared/utils';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isString } from 'lodash';

export function phoneNumber(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }

  return isString(control.value) && isValidPhoneNumber(control.value)
    ? null
    : { phoneNumber: true };
}
