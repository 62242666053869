import { AppointmentModalActions } from '$/app/pages/notebook/residents/appointment-modal/appointment.modal.actions';
import { EventsListActions } from '$/app/pages/notebook/residents/events-list/events-list.actions';
import { getPaginationData } from '$/app/utils/reducers/get-pagination-data';
import { getRecords } from '$/app/utils/reducers/get-records';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AppointmentsApiActions,
  AppointmentsWsActions
} from './actions/appointments-api.actions';
import { AppointmentsGeneralActions } from './actions/appointments-gen.actions';
import { appointmentsAdapter, initialState } from './appointments.state';

export const appointmentsFeature = createFeature({
  name: 'appointments',
  reducer: createReducer(
    initialState,

    on(
      EventsListActions.loadAppointments,
      AppointmentModalActions.createAppointment,
      AppointmentModalActions.updateAppointment,
      EventsListActions.deleteAppointment,
      (state) => {
        return {
          ...state,
          loading: true
        };
      }
    ),

    on(AppointmentsApiActions.loadAppointmentsSuccess, (state, action) => {
      const pagination = getPaginationData(state, action.appointments);
      const data = getRecords(action.appointments);

      return appointmentsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true
      });
    }),

    on(AppointmentsApiActions.createAppointmentSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(AppointmentsApiActions.updateAppointmentSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(AppointmentsApiActions.deleteAppointmentSuccess, (state, action) => {
      return appointmentsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      AppointmentsApiActions.loadAppointmentsFail,
      AppointmentsApiActions.createAppointmentFail,
      AppointmentsApiActions.updateAppointmentFail,
      AppointmentsApiActions.deleteAppointmentFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      AppointmentsWsActions.appointmentsCreated,
      AppointmentsWsActions.appointmentsPatched,
      (state, action) => {
        return appointmentsAdapter.upsertOne(action.appointment, state);
      }
    ),

    on(AppointmentsWsActions.appointmentsRemoved, (state, action) => {
      return appointmentsAdapter.removeOne(action.id, state);
    }),

    on(AppointmentsGeneralActions.addAppointments, (state, action) => {
      return appointmentsAdapter.upsertMany(action.appointments, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(AppointmentsGeneralActions.clearAppointments, (state) => {
      return appointmentsAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
