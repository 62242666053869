import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const GivePrnFormActions = createActionGroup({
  source: 'Give PRN Form',
  events: {
    'Get Residents And Prns': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Previous Prns': (medicationId: string): { params: Params } => ({
      params: {
        query: {
          medicationId,
          $limit: 10,
          $sort: {
            givenAt: -1
          },
          $eager: {
            amounts: true,
            giver: true
          }
        }
      }
    }),
    'Load Facility Users': (params: Params) => ({ params }),
    'Get Behavior Logs': (params: Params) => ({ params }),
    'Get Body Check Logs': (params: Params) => ({ params }),
    'Get Blood Pressure Logs': (params: Params) => ({ params }),
    'Get Blood Sugar Logs': (params: Params) => ({ params }),
    'Get Bowel Movement Logs': (params: Params) => ({ params }),
    'Get Food Logs': (params: Params) => ({ params }),
    'Get Mood Logs': (params: Params) => ({ params }),
    'Get Oxygen Logs': (params: Params) => ({ params }),
    'Get Position Logs': (params: Params) => ({ params }),
    'Get Seizure Logs': (params: Params) => ({ params }),
    'Get Sleep Logs': (params: Params) => ({ params }),
    'Get Shower Logs': (params: Params) => ({ params }),
    'Get Temperature Logs': (params: Params) => ({ params }),
    'Get Urine Logs': (params: Params) => ({ params }),
    'Get Water Logs': (params: Params) => ({ params }),
    'Get Weight Logs': (params: Params) => ({ params }),
    'Delete Behavior Log': props<{ id: string; params?: Params }>(),
    'Delete Body Check Log': props<{ id: string; params?: Params }>(),
    'Delete Blood Pressure Log': props<{ id: string; params?: Params }>(),
    'Delete Blood Sugar Log': props<{ id: string; params?: Params }>(),
    'Delete Bowel Movement Log': props<{ id: string; params?: Params }>(),
    'Delete Food Log': props<{ id: string; params?: Params }>(),
    'Delete Mood Log': props<{ id: string; params?: Params }>(),
    'Delete Oxygen Log': props<{ id: string; params?: Params }>(),
    'Delete Position Log': props<{ id: string; params?: Params }>(),
    'Delete Seizure Log': props<{ id: string; params?: Params }>(),
    'Delete Sleep Log': props<{ id: string; params?: Params }>(),
    'Delete Shower Log': props<{ id: string; params?: Params }>(),
    'Delete Temperature Log': props<{ id: string; params?: Params }>(),
    'Delete Urine Log': props<{ id: string; params?: Params }>(),
    'Delete Water Log': props<{ id: string; params?: Params }>(),
    'Delete Weight Log': props<{ id: string; params?: Params }>()
  }
});
