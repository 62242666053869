import { FacilityUserDetailPageActions } from '$/app/pages/staff/facility-user-detail/facility-user-detail.actions';
import { FacilityUserGroupMemberPickerActions } from '$/app/pages/staff/facility-user-group-member-picker/facility-user-group-member-picker.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  FacilityUserGroupMembersApiActions,
  FacilityUserGroupMembersGeneralActions,
  FacilityUserGroupMembersWsActions
} from './actions';
import {
  facilityUserGroupMembersAdapter,
  initialState
} from './facility-user-group-members.state';

const reducer = createReducer(
  initialState,

  on(
    FacilityUserGroupMemberPickerActions.createFacilityUserGroupMember,
    FacilityUserDetailPageActions.deleteFacilityUserGroupMember,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    FacilityUserGroupMembersApiActions.getFacilityUserGroupMembersSuccess,
    (state, action) => {
      return facilityUserGroupMembersAdapter.upsertMany(
        action.facilityUserGroupMembers.data,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    FacilityUserGroupMembersApiActions.createFacilityUserGroupMemberSuccess,
    (state, action) => {
      const facilityUserGroupMembers = castArray(
        action.facilityUserGroupMember
      );

      return facilityUserGroupMembersAdapter.addMany(facilityUserGroupMembers, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupMembersApiActions.deleteFacilityUserGroupMemberSuccess,
    (state, action) => {
      return facilityUserGroupMembersAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupMembersApiActions.createFacilityUserGroupMemberFail,
    FacilityUserGroupMembersApiActions.deleteFacilityUserGroupMemberFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    FacilityUserGroupMembersWsActions.facilityUserGroupMembersCreated,
    FacilityUserGroupMembersWsActions.facilityUserGroupMembersPatched,
    (state, action) => {
      return facilityUserGroupMembersAdapter.upsertOne(
        action.facilityUserGroupMember,
        {
          ...state
        }
      );
    }
  ),

  on(
    FacilityUserGroupMembersWsActions.facilityUserGroupMembersRemoved,
    (state, action) => {
      return facilityUserGroupMembersAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(
    FacilityUserGroupMembersGeneralActions.clearFacilityUserGroupMembers,
    (state) => {
      return facilityUserGroupMembersAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupMembersGeneralActions.addFacilityUserGroupMembers,
    (state, action) => {
      return facilityUserGroupMembersAdapter.upsertMany(
        action.facilityUserGroupMembers,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  )
);

export const facilityUserGroupMembersFeature = createFeature({
  name: 'facilityUserGroupMembers',
  reducer
});
