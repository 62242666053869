import { DateTimeFormats } from '$shared/constants/datetime-formats';
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'convertFrom24To12Format', standalone: true })
export class AlcTimeFormatPipe implements PipeTransform {
  transform(time: string): string {
    return DateTime.fromFormat(time, DateTimeFormats.TIME_FROM_24).toFormat(
      DateTimeFormats.TIME_12
    );
  }
}
