import { ResidentsSelectors } from '$/app/store/residents/residents.selectors';
import * as RouterSelectors from '$/app/store/router/router.selectors';
import { CarePlanItem, CarePlanItemRelations } from '$/models';
import { Dictionary } from '$shared/types';
import { createSelector } from '@ngrx/store';
import { filter } from 'lodash';
import { carePlanItemsFeature } from './care-plan-items.reducer';
import { carePlanItemsAdapter } from './care-plan-items.state';

export const {
  selectCarePlanItemsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = carePlanItemsFeature;

export const { selectAll, selectTotal } = carePlanItemsAdapter.getSelectors(
  selectCarePlanItemsState
);

export const selectEntitiesWithRelations = createSelector(
  selectEntities,
  ResidentsSelectors.selectEntitiesWithRelations,
  (carePlanItems, residents) => {
    return Object.entries(carePlanItems).reduce(
      (
        acc,
        [id, carePlanItem]
      ): Dictionary<CarePlanItem & CarePlanItemRelations> => {
        acc[id] = {
          ...carePlanItem,
          resident: residents[carePlanItem.residentId]
        };

        return acc;
      },
      {}
    );
  }
);

export const selectById = (id: string) =>
  createSelector(selectEntitiesWithRelations, (carePlanItems) => {
    if (!id) {
      return {};
    }

    return carePlanItems[id];
  });

export const selectResidentCarePlanItems = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (carePlanItems, residentId) =>
    residentId ? filter(carePlanItems, { residentId }) : []
);

// VIEW MODEL SELECTORS
export const selectCarePlanItem = createSelector(
  selectEntitiesWithRelations,
  RouterSelectors.selectParam('careItemId'),
  (entities, careItemId): CarePlanItem => {
    if (!entities || !careItemId) {
      return {};
    }

    return entities[careItemId];
  }
);
