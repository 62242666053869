import { Paginated, Pharmacy, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadPharmaciesSuccess = createAction(
  '[Pharmacies API] Load Pharmacies Success',
  props<{ pharmacies: Paginated<Pharmacy> }>()
);
export const loadPharmaciesFail = createAction(
  '[Pharmacies API] Load Pharmacies Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getPharmaciesSuccess = createAction(
  '[Pharmacies API] Get Pharmacies Success',
  props<{ pharmacies: Paginated<Pharmacy> }>()
);
export const getPharmaciesFail = createAction(
  '[Pharmacies API] Get Pharmacies Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchPharmacySuccess = createAction(
  '[Pharmacy API] Fetch Pharmacy Success',
  props<{ pharmacy: Pharmacy }>()
);
export const fetchPharmacyFail = createAction(
  '[Pharmacy API] Fetch Pharmacy Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createPharmacySuccess = createAction(
  '[Pharmacies API] Add Pharmacy Success',
  props<{ pharmacy: MaybeArray<Pharmacy> }>()
);
export const createPharmacyFail = createAction(
  '[Pharmacies API] Add Pharmacy Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updatePharmacySuccess = createAction(
  '[Pharmacies API] Update Pharmacy Success',
  props<{ pharmacy: Update<Pharmacy> }>()
);
export const updatePharmacyFail = createAction(
  '[Pharmacies API] Update Pharmacy Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deletePharmacySuccess = createAction(
  '[Pharmacies API] Delete Pharmacy Success',
  props<{ id: string }>()
);
export const deletePharmacyFail = createAction(
  '[Pharmacies API] Delete Pharmacy Fail',
  props<{ error: Error }>()
);

// [WS] Pharmacies Created
export const pharmaciesCreated = createAction(
  '[Pharmacies WS] Pharmacies Created',
  props<{ pharmacy: Pharmacy }>()
);
// [WS] Pharmacies Patched
export const pharmaciesPatched = createAction(
  '[Pharmacies WS] Pharmacies Patched',
  props<{ pharmacy: Pharmacy }>()
);
// [WS] Pharmacies Removed
export const pharmaciesRemoved = createAction(
  '[Pharmacies WS] Pharmacies Removed',
  props<{ id: string }>()
);
