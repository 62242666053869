import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IOxygenLog } from '$shared/services/oxygen-log';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const oxygenLogsAdapter = createEntityAdapter<IOxygenLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<IOxygenLog> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = oxygenLogsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
