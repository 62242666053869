import { IDocumentTemplatePacketTemplate, Paginated } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DocumentTemplatePacketTemplatesApiActions = createActionGroup({
  source: 'Document Template Packet Templates API',
  events: {
    // Success Actions
    'Load Document Template Packet Templates Success': props<{
      documentTemplatePacketTemplates: Paginated<IDocumentTemplatePacketTemplate>;
    }>(),
    'Get Document Template Packet Templates Success': props<{
      documentTemplatePacketTemplates: Paginated<IDocumentTemplatePacketTemplate>;
    }>(),
    'Fetch Document Template Packet Template Success': props<{
      documentTemplatePacketTemplate: IDocumentTemplatePacketTemplate;
    }>(),
    'Create Document Template Packet Template Success': props<{
      documentTemplatePacketTemplate: IDocumentTemplatePacketTemplate;
    }>(),
    'Update Document Template Packet Template Success': props<{
      documentTemplatePacketTemplate: IDocumentTemplatePacketTemplate;
    }>(),
    'Delete Document Template Packet Template Success': props<{ id: string }>(),
    // Fail Actions
    'Load Document Template Packet Templates Fail': props<{ error: Error }>(),
    'Get Document Template Packet Templates Fail': props<{ error: Error }>(),
    'Fetch Document Template Packet Template Fail': props<{ error: Error }>(),
    'Create Document Template Packet Template Fail': props<{ error: Error }>(),
    'Update Document Template Packet Template Fail': props<{ error: Error }>(),
    'Delete Document Template Packet Template Fail': props<{ error: Error }>()
  }
});

export const DocumentTemplatePacketTemplatesWsActions = createActionGroup({
  source: 'Document Template Packet Templates WS',
  events: {
    'Document Template Packet Template Created': props<{
      documentTemplatePacketTemplate: IDocumentTemplatePacketTemplate;
    }>(),
    'Document Template Packet Template Patched': props<{
      documentTemplatePacketTemplate: IDocumentTemplatePacketTemplate;
    }>(),
    'Document Template Packet Template Removed': props<{ id: string }>()
  }
});

export const DocumentTemplatePacketTemplatesGeneralActions = createActionGroup({
  source: 'Document Template Packet Templates General',
  events: {
    'Clear Document Template Packet Templates': emptyProps(),
    'Add Document Template Packet Templates': props<{
      documentTemplatePacketTemplates: IDocumentTemplatePacketTemplate[];
    }>()
  }
});
