import { ErrorMessage } from '$shared/utils';
import { AlcomyErrorCodes } from './error-codes';

const AMOUNT_NOT_GIVEN = new ErrorMessage(
  'Alcomy is unable to infer the amount, because the dose type is either set to range or option',
  'Indicate the amount of medication you are giving',
  'For additional help please visit our support page'
);

const NO_VALID_LOG_RULE = new ErrorMessage(
  "We are unable to determine the amount of medication to give, because the recorded log does not match any rule's conditions in the log dependency configuration",
  'You can fix this by changing the log dependency rules so that the conditions encompass all possible values',
  'For additional help on this issue see our help page.'
);
const INVALID_FORM = new ErrorMessage(
  "The form was not submitted because it's missing required fields or some of the fields are not formatted correctly",
  'Fix the fields marked in red and then resubmit'
);

const NO_LOG_FOUND = new ErrorMessage(
  'This medication could not be given, because it has a dependency on a log, but no log was recorded.',
  'Record the required log and then try again',
  'You can learn more about log dependencies by visiting the help page.'
);

const INSUFFICIENT_INVENTORY = new ErrorMessage(
  'There is not enough medication in inventory to satisfy the required dose',
  'To fix this, add a new inventory item with enough medication to satisfy the required dose, or consider turning off inventory tracking for this medication.',
  'To learn more about inventory tracking visit our help page'
);

export const ErrorCodeMessages: Record<AlcomyErrorCodes, ErrorMessage> = {
  [AlcomyErrorCodes.USER_ABORTED]: AMOUNT_NOT_GIVEN,
  [AlcomyErrorCodes.NO_VALID_LOG_RULE]: NO_VALID_LOG_RULE,
  [AlcomyErrorCodes.INVALID_FORM]: INVALID_FORM,
  [AlcomyErrorCodes.NO_LOG_FOUND]: NO_LOG_FOUND,
  [AlcomyErrorCodes.INSUFFICIENT_INVENTORY]: INSUFFICIENT_INVENTORY
};
