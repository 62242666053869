import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { MoodLogsPageActions } from '$/app/pages/notebook/shared/logs/mood-log/mood-log.actions';
import { EffectHelpersService, MoodLogsApiService } from '$/app/services';
import { MoodLogFormPageActions } from '$/app/shared/pages/forms/log-forms/mood-log-form/mood-log-form.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { IMoodLog } from '$shared/services/mood-log';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { MoodLogsApiActions } from './actions';

@Injectable()
export class MoodLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly moodLogsService = inject(MoodLogsApiService);

  loadMoodLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MoodLogsPageActions.loadMoodLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.moodLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('moodLogs', logs);
            responseData.setPrimaryAction(
              MoodLogsApiActions.loadMoodLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getMoodLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MoodLogsPageActions.getMoodLogs,
        LogsPageActions.getMoodLogs,
        GivePrnFormActions.getMoodLogs
      ),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.moodLogsService.getAll(action.params).pipe(
          getAllPages(this.moodLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('moodLogs', logs);
            responseData.setPrimaryAction(
              MoodLogsApiActions.getMoodLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createMoodLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MoodLogFormPageActions.createMoodLog),
      exhaustMap((action) => {
        return this.moodLogsService.create(action.moodLog).pipe(
          tap((data: IMoodLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((moodLog: IMoodLog) =>
            MoodLogsApiActions.createMoodLogSuccess({
              moodLog
            })
          ),
          catchError((error) =>
            of(
              MoodLogsApiActions.createMoodLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateMoodLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MoodLogFormPageActions.updateMoodLog),
      switchMap((action) => {
        return this.moodLogsService.patch(action.id, action.changes).pipe(
          tap((data: IMoodLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((moodLog: IMoodLog) => {
            return MoodLogsApiActions.updateMoodLogSuccess({
              moodLog
            });
          }),
          catchError((error) => {
            return of(MoodLogsApiActions.updateMoodLogFail({ error }));
          })
        );
      })
    );
  });

  deleteMoodLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MoodLogsPageActions.deleteMoodLog,
        ResidentMedicationTasksPageActions.deleteMoodLog,
        MissedMedicationsPageActions.deleteMoodLog,
        RoutineMarDetailPageActions.deleteMoodLog,
        RoutineMarListPageActions.deleteMoodLog,
        GivePrnFormActions.deleteMoodLog,
        CompleteCareTaskModalActions.deleteMoodLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete mood log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.moodLogsService.delete(action.id, action.params);
        },
        onSuccess: (moodLog: IMoodLog) =>
          MoodLogsApiActions.deleteMoodLogSuccess({
            id: moodLog.id
          }),
        onError: (error) => MoodLogsApiActions.deleteMoodLogFail({ error })
      })
    );
  });
}
