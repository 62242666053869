import { Params, Role, RolePermission } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const FacilityRoleDetailPageActions = createActionGroup({
  source: 'Facility Role Detail Page',
  events: {
    'Fetch Role': (id: string, params?: Params) => ({ id, params }),
    'Update Role': (id: string, changes: Partial<Role>) => ({ id, changes }),
    'Create Role Permission': (
      rolePermission: RolePermission,
      params?: Params
    ) => ({
      rolePermission,
      params
    }),
    'Delete Role Permission': (id: string, params: Params) => ({ id, params })
  }
});
