import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { TMedicationTaskAmount } from '$shared/medications/medication-task-amount.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MedicationTaskAmountsWsActions } from './medication-task-amounts.actions';

@Injectable({ providedIn: 'root' })
export class MedicationTaskAmountsApiService extends AbstractApiService<TMedicationTaskAmount> {
  constructor(feathers: FeathersService, store: Store) {
    super('medication-task-amounts', feathers, store, {
      entityName: 'medicationTaskAmount',
      created: MedicationTaskAmountsWsActions.medicationTaskAmountCreated,
      patched: MedicationTaskAmountsWsActions.medicationTaskAmountPatched,
      removed: MedicationTaskAmountsWsActions.medicationTaskAmountRemoved
    });
  }
}
