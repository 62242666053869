import { Symptom } from '$/models';
import { createSelector } from '@ngrx/store';
import { keyBy } from 'lodash';

import { symptomsFeature } from './symptoms.reducer';
import { symptomsAdapter } from './symptoms.state';

export const {
  selectSymptomsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = symptomsFeature;

export const { selectAll, selectTotal } =
  symptomsAdapter.getSelectors(selectSymptomsState);

export const selectSeizureSymptoms = createSelector(
  selectAll,
  (symptoms: Symptom[]) => {
    const seizureSymptoms = symptoms.filter((symptom) => symptom.seizure);

    return seizureSymptoms;
  }
);

export const selectSeizureSymptomEntities = createSelector(
  selectSeizureSymptoms,
  (symptoms: Symptom[]) => keyBy(symptoms, 'id')
);
