import { FeathersService } from '$/app/services/feathers.service';
import { PharmacyPhonesApiActions } from '$/app/store/pharmacy-phones/actions';
import { toUpdated } from '$/app/utils';
import { Paginated, Params, PharmacyPhone, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PharmacyPhonesApiService {
  private pharmacyPhonesService: Service<PharmacyPhone>;

  constructor(feathers: FeathersService, store: Store) {
    this.pharmacyPhonesService = feathers.client.service('pharmacy-phones');

    this.pharmacyPhonesService.on('created', (pharmacyPhone) => {
      store.dispatch(
        PharmacyPhonesApiActions.pharmacyPhonesCreated({ pharmacyPhone })
      );
    });

    this.pharmacyPhonesService.on('patched', (pharmacyPhone) => {
      store.dispatch(
        PharmacyPhonesApiActions.pharmacyPhonesPatched({ pharmacyPhone })
      );
    });

    this.pharmacyPhonesService.on('removed', (pharmacyPhone) => {
      store.dispatch(
        PharmacyPhonesApiActions.pharmacyPhonesRemoved({ id: pharmacyPhone.id })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<Paginated<PharmacyPhone>> {
    const promise = this.pharmacyPhonesService.find(params) as Promise<
      Paginated<PharmacyPhone>
    >;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<PharmacyPhone> {
    const promise = this.pharmacyPhonesService.get(id, params);
    return from(promise);
  }

  create(pharmacyPhone: MaybeArray<PharmacyPhone>) {
    const promise = this.pharmacyPhonesService.create(pharmacyPhone);
    return from(promise);
  }

  patch(
    id: string,
    pharmacyPhone: Partial<PharmacyPhone>,
    params: Params = { query: {} }
  ): Observable<Update<PharmacyPhone>> {
    const promise = this.pharmacyPhonesService.patch(id, pharmacyPhone, params);

    return from(promise).pipe(map(toUpdated));
  }

  delete(
    id: string,
    params: Params = { query: {} }
  ): Observable<PharmacyPhone> {
    const promise = this.pharmacyPhonesService.remove(id, params);
    return from(promise);
  }
}
