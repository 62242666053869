import { Paginated, SleepLog } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Sleep Logs API]';
const sourceWS = '[Sleep Logs WS]';

// Load
export const loadSleepLogsSuccess = createAction(
  `${source} Load Sleep Logs Success`,
  props<{
    sleepLogs: Paginated<SleepLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const loadSleepLogsFail = createAction(
  `${source} Load Sleep Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getSleepLogsSuccess = createAction(
  `${source} Get Sleep Logs Success`,
  props<{
    sleepLogs: Paginated<SleepLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const getSleepLogsFail = createAction(
  `${source} Get Sleep Logs Fail`,
  props<{ error: Error }>()
);
// Create
export const createSleepLogSuccess = createAction(
  `${source} Create Sleep Log Success`,
  props<{ sleepLog: SleepLog }>()
);
export const createSleepLogFail = createAction(
  `${source} Create Sleep Log Fail`,
  props<{ error: Error }>()
);

export const updateSleepLogSuccess = createAction(
  `${source} Update Sleep Log Success`,
  props<{ sleepLog: SleepLog }>()
);
export const updateSleepLogFail = createAction(
  `${source} Update Sleep Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteSleepLogSuccess = createAction(
  `${source} Delete Sleep Log Success`,
  props<{ id: string }>()
);
export const deleteSleepLogFail = createAction(
  `${source} Delete Sleep Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Sleep Logs Created
export const sleepLogsCreated = createAction(
  `${sourceWS} Sleep Logs Created`,
  props<{ sleepLog: SleepLog }>()
);
// [WS] Sleep Logs Patched
export const sleepLogsPatched = createAction(
  `${sourceWS} Sleep Logs Patched`,
  props<{ sleepLog: SleepLog }>()
);
// [WS] Sleep Logs Removed
export const sleepLogsRemoved = createAction(
  `${sourceWS} Sleep Logs Removed`,
  props<{ id: string }>()
);
