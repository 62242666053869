import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { stringify } from 'safe-stable-stringify';
import { RoutineMarListFilters } from '../routine-mar-list.vmodel';

export const RoutineMarListFilterPageActions = createActionGroup({
  source: 'Routine Mar List Filter Page',
  events: {
    'Filter Changed': (
      filters: RoutineMarListFilters
    ): { filters: RoutineMarListFilters; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          state: stringify(filters.state),
          missedOnly: filters.missedOnly,
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
