import { FeathersService } from '$/app/services/feathers.service';
import { FacilityUserPhonesWsActions } from '$/app/store/facility-user-phones/actions';
import { toUpdated } from '$/app/utils';
import { FacilityUserPhone, Paginated, Params, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FacilityUserPhonesApiService {
  private facilityUserPhonesService: Service<FacilityUserPhone>;

  constructor(feathers: FeathersService, store: Store) {
    this.facilityUserPhonesService = feathers.client.service(
      'facility-user-phones'
    );

    this.facilityUserPhonesService.on('created', (facilityUserPhone) => {
      store.dispatch(
        FacilityUserPhonesWsActions.facilityUserPhonesCreated({
          facilityUserPhone
        })
      );
    });

    this.facilityUserPhonesService.on('patched', (facilityUserPhone) => {
      facilityUserPhone = toUpdated(facilityUserPhone);

      store.dispatch(
        FacilityUserPhonesWsActions.facilityUserPhonesPatched({
          facilityUserPhone
        })
      );
    });

    this.facilityUserPhonesService.on('removed', (facilityUserPhone) => {
      store.dispatch(
        FacilityUserPhonesWsActions.facilityUserPhonesRemoved({
          id: facilityUserPhone.id
        })
      );
    });
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<FacilityUserPhone>> {
    const promise = this.facilityUserPhonesService.find(params) as Promise<
      Paginated<FacilityUserPhone>
    >;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<FacilityUserPhone> {
    const promise = this.facilityUserPhonesService.get(id, params);
    return from(promise);
  }

  create(facilityUserPhone: MaybeArray<FacilityUserPhone>) {
    const promise = this.facilityUserPhonesService.create(facilityUserPhone);
    return from(promise);
  }

  patch(
    id: string,
    facilityUserPhone: Partial<FacilityUserPhone>
  ): Observable<Update<FacilityUserPhone>> {
    const promise = this.facilityUserPhonesService.patch(id, facilityUserPhone);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string): Observable<FacilityUserPhone> {
    const promise = this.facilityUserPhonesService.remove(id);
    return from(promise);
  }
}
