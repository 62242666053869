import {
  Acknowledgement,
  Announcement,
  AnnouncementReply,
  Params,
  Update
} from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const AnnouncementDetailPageActions = createActionGroup({
  source: 'Announcement Detail Page',
  events: {
    'Fetch Announcement Detail Page Info': (
      id: string,
      params: Params = { query: {} }
    ) => ({ id, params }),

    'Update Announcement': props<Update<Announcement>>(),

    'Acknowledge Announcement': (
      announcementId: string
    ): Update<Acknowledgement> => {
      return {
        id: null,
        changes: {
          isRead: true
        },
        params: {
          query: {
            announcementId
          }
        }
      };
    },

    'Create Reply': props<{ reply: AnnouncementReply; params?: Params }>()
  }
});
