import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DestructionMethodsEffects } from './destruction-methods.effects';
import { destructionMethodsFeature } from './destruction-methods.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(destructionMethodsFeature),
    EffectsModule.forFeature([DestructionMethodsEffects])
  ],
  providers: [DestructionMethodsEffects]
})
export class DestructionMethodsStoreModule {}
