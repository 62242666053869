import { createFeature, createReducer, on } from '@ngrx/store';
import {
  LibraryCollectionDocumentTemplatePacketsApiActions,
  LibraryCollectionDocumentTemplatePacketsGeneralActions,
  LibraryCollectionDocumentTemplatePacketsWsActions
} from './actions';
import {
  initialState,
  libraryCollectionDocumentTemplatePacketsAdapter
} from './library-collection-document-template-packets.state';

export const libraryCollectionDocumentTemplatePacketsFeature = createFeature({
  name: 'libraryCollectionDocumentTemplatePackets',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      LibraryCollectionDocumentTemplatePacketsApiActions.loadLibraryCollectionDocumentTemplatePacketsSuccess,
      (state, action) => {
        const {
          libraryCollectionDocumentTemplatePackets: {
            data: records,
            ...pagination
          }
        } = action;

        return libraryCollectionDocumentTemplatePacketsAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsApiActions.getLibraryCollectionDocumentTemplatePacketsSuccess,
      (state, action) => {
        const {
          libraryCollectionDocumentTemplatePackets: {
            data: records,
            ...pagination
          }
        } = action;

        return libraryCollectionDocumentTemplatePacketsAdapter.upsertMany(
          records,
          {
            ...state,
            pagination,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsApiActions.fetchLibraryCollectionDocumentTemplatePacketSuccess,
      (state, action) => {
        return libraryCollectionDocumentTemplatePacketsAdapter.upsertOne(
          action.libraryCollectionDocumentTemplatePacket,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsApiActions.createLibraryCollectionDocumentTemplatePacketSuccess,
      (state, action) => {
        return libraryCollectionDocumentTemplatePacketsAdapter.addOne(
          action.libraryCollectionDocumentTemplatePacket,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsApiActions.updateLibraryCollectionDocumentTemplatePacketSuccess,
      (state, action) => {
        return libraryCollectionDocumentTemplatePacketsAdapter.upsertOne(
          action.libraryCollectionDocumentTemplatePacket,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsApiActions.deleteLibraryCollectionDocumentTemplatePacketSuccess,
      (state, action) =>
        libraryCollectionDocumentTemplatePacketsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsApiActions.loadLibraryCollectionDocumentTemplatePacketsFail,
      LibraryCollectionDocumentTemplatePacketsApiActions.getLibraryCollectionDocumentTemplatePacketsFail,
      LibraryCollectionDocumentTemplatePacketsApiActions.createLibraryCollectionDocumentTemplatePacketFail,
      LibraryCollectionDocumentTemplatePacketsApiActions.updateLibraryCollectionDocumentTemplatePacketFail,
      LibraryCollectionDocumentTemplatePacketsApiActions.deleteLibraryCollectionDocumentTemplatePacketFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsWsActions.libraryCollectionDocumentTemplatePacketCreated,
      LibraryCollectionDocumentTemplatePacketsWsActions.libraryCollectionDocumentTemplatePacketPatched,
      (state, action) => {
        return libraryCollectionDocumentTemplatePacketsAdapter.upsertOne(
          action.libraryCollectionDocumentTemplatePacket,
          state
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsWsActions.libraryCollectionDocumentTemplatePacketRemoved,
      (state, action) => {
        return libraryCollectionDocumentTemplatePacketsAdapter.removeOne(
          action.id,
          state
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsGeneralActions.addLibraryCollectionDocumentTemplatePackets,
      (state, action) => {
        return libraryCollectionDocumentTemplatePacketsAdapter.upsertMany(
          action.libraryCollectionDocumentTemplatePackets,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatePacketsGeneralActions.clearLibraryCollectionDocumentTemplatePackets,
      () => {
        return libraryCollectionDocumentTemplatePacketsAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
