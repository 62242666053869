import { ResidentMedicalProfessional } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const residentMedicalProfessionalsAdapter =
  createEntityAdapter<ResidentMedicalProfessional>();

export interface State extends EntityState<ResidentMedicalProfessional> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  residentMedicalProfessionalsAdapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
  });
