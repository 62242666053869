import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  inject,
  Input
} from '@angular/core';
import { AlcIconContainerComponent } from '../icon-container/icon-container.component';

@Component({
  selector: 'alc-empty-placeholder',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss']
})
export class AlcEmptyPlaceholderComponent implements AfterContentInit {
  private readonly elRef = inject(ElementRef);

  @Input() size: string = '300px';

  @ContentChild(AlcIconContainerComponent)
  iconContainer: AlcIconContainerComponent;

  ngAfterContentInit() {
    if (this.iconContainer) {
      this.iconContainer.size = this.size;
    }
    this.elRef.nativeElement.style.setProperty(
      '--placeholder-image-size',
      this.size
    );
  }
}
