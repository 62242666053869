import { sortByDatetime } from '$/app/utils';
import { IMedicationDestruction } from '$shared/services/medication-destructions.schema';
import { createEntityAdapter } from '@ngrx/entity';

export const medicationDestructionsAdapter =
  createEntityAdapter<IMedicationDestruction>({
    sortComparer: sortByDatetime('performedAt', true)
  });

export const initialState = medicationDestructionsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
