import { CareTask, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const CareTaskInfoPageActions = createActionGroup({
  source: 'Care Task Info Page',
  events: {
    'Fetch Care Task': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Care Task State Reset': props<Update<CareTask>>(),
    'Care Task Completed': props<Update<CareTask>>()
  }
});
