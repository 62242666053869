import { Params, ShiftNote, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ShiftNotesFormPageActions = createActionGroup({
  source: 'Shift Note Log Form Page',
  events: {
    'Create Shift Note': props<{ shiftNote: ShiftNote; params?: Params }>(),
    'Update Shift Note': props<Update<ShiftNote>>()
  }
});
