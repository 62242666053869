import {
  AfterViewInit,
  Directive,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SkipSelf
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective
} from '@angular/forms';
import { castArray } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ErrorDetails, ErrorOptions } from './ngxerrors';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngxErrors]', exportAs: 'ngxErrors' })
export class NgxErrorsDirective implements OnChanges, OnDestroy, AfterViewInit {
  @Input('ngxErrors')
  controlName: string;

  subject = new BehaviorSubject<ErrorDetails>(null);

  control: AbstractControl;

  private _parent: ControlContainer;

  ready: boolean = false;

  constructor(
    private form: FormGroupDirective,
    @Optional() @Host() @SkipSelf() parent: ControlContainer
  ) {
    this._parent = parent;
  }

  get errors() {
    if (!this.ready) {
      return;
    }
    return this.control.errors;
  }

  get hasErrors() {
    return !!this.errors;
  }

  hasError(name: string, conditions: ErrorOptions): boolean {
    return this.checkPropState('invalid', name, conditions);
  }

  isValid(name: string, conditions: ErrorOptions): boolean {
    return this.checkPropState('valid', name, conditions);
  }

  getError(name: string) {
    if (!this.ready) {
      return;
    }
    return this.control.getError(name);
  }

  private checkPropState(
    prop: string,
    name: string,
    conditions: ErrorOptions
  ): boolean {
    if (!this.ready) {
      return;
    }
    const controlPropsState =
      !conditions ||
      castArray(conditions).every(
        (condition: string) => this.control[condition]
      );
    if (name.charAt(0) === '*') {
      return this.control[prop] && controlPropsState;
    }
    return prop === 'valid'
      ? !this.control.hasError(name)
      : this.control.hasError(name) && controlPropsState;
  }

  private checkStatus() {
    const control = this.control;
    const errors = control.errors;
    this.ready = true;
    if (!errors) {
      return;
    }
    for (const errorName in errors) {
      this.subject.next({ control, errorName });
    }
  }

  private get pathFormControl() {
    return [...this._parent.path, this.controlName];
  }

  ngOnChanges() {
    this.control = this.form.control.get(this.pathFormControl);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkStatus();
      this.control.statusChanges.subscribe(this.checkStatus.bind(this));
    });
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
  }
}
