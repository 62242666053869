import { Table } from '$/models/data/base';

export class Pharmacy extends Table {
  orgId?: string;
  facilityId?: string;

  name: string;
  isPrimary?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  email?: string;
  orderingMethod?: string;
}
