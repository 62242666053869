import { ILibraryCollectionDocumentTemplatePacket, Paginated } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const LibraryCollectionDocumentTemplatePacketsApiActions =
  createActionGroup({
    source: 'Library Collection Document Template Packets API',
    events: {
      // Success Actions
      'Load Library Collection Document Template Packets Success': props<{
        libraryCollectionDocumentTemplatePackets: Paginated<ILibraryCollectionDocumentTemplatePacket>;
      }>(),
      'Get Library Collection Document Template Packets Success': props<{
        libraryCollectionDocumentTemplatePackets: Paginated<ILibraryCollectionDocumentTemplatePacket>;
      }>(),
      'Fetch Library Collection Document Template Packet Success': props<{
        libraryCollectionDocumentTemplatePacket: ILibraryCollectionDocumentTemplatePacket;
      }>(),
      'Create Library Collection Document Template Packet Success': props<{
        libraryCollectionDocumentTemplatePacket: ILibraryCollectionDocumentTemplatePacket;
      }>(),
      'Update Library Collection Document Template Packet Success': props<{
        libraryCollectionDocumentTemplatePacket: ILibraryCollectionDocumentTemplatePacket;
      }>(),
      'Delete Library Collection Document Template Packet Success': props<{
        id: string;
      }>(),
      // Fail Actions
      'Load Library Collection Document Template Packets Fail': props<{
        error: Error;
      }>(),
      'Get Library Collection Document Template Packets Fail': props<{
        error: Error;
      }>(),
      'Fetch Library Collection Document Template Packet Fail': props<{
        error: Error;
      }>(),
      'Create Library Collection Document Template Packet Fail': props<{
        error: Error;
      }>(),
      'Update Library Collection Document Template Packet Fail': props<{
        error: Error;
      }>(),
      'Delete Library Collection Document Template Packet Fail': props<{
        error: Error;
      }>()
    }
  });

export const LibraryCollectionDocumentTemplatePacketsWsActions =
  createActionGroup({
    source: 'Library Collection Document Template Packets WS',
    events: {
      'Library Collection Document Template Packet Created': props<{
        libraryCollectionDocumentTemplatePacket: ILibraryCollectionDocumentTemplatePacket;
      }>(),
      'Library Collection Document Template Packet Patched': props<{
        libraryCollectionDocumentTemplatePacket: ILibraryCollectionDocumentTemplatePacket;
      }>(),
      'Library Collection Document Template Packet Removed': props<{
        id: string;
      }>()
    }
  });
