import { Paginated } from '$/models';
import { IBmLog } from '$shared/services/bm-log';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BmLogsApiActions = createActionGroup({
  source: 'Bm Logs API',
  events: {
    'Load Bm Logs Success': props<{
      bmLogs: Paginated<IBmLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Bm Logs Success': props<{
      bmLogs: Paginated<IBmLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Create Bm Log Success': props<{ bmLog: IBmLog }>(),
    'Update Bm Log Success': props<{ bmLog: IBmLog }>(),
    'Delete Bm Log Success': props<{ id: string }>(),

    'Load Bm Logs Fail': props<{ error: Error }>(),
    'Get Bm Logs Fail': props<{ error: Error }>(),
    'Create Bm Log Fail': props<{ error: Error }>(),
    'Update Bm Log Fail': props<{ error: Error }>(),
    'Delete Bm Log Fail': props<{ error: Error }>()
  }
});

export const BmLogsWsActions = createActionGroup({
  source: 'Bm Logs WS',
  events: {
    'Bm Logs Created': props<{ bmLog: IBmLog }>(),
    'Bm Logs Patched': props<{ bmLog: IBmLog }>(),
    'Bm Logs Removed': props<{ id: string }>()
  }
});

export const BmLogsGeneralActions = createActionGroup({
  source: 'Bm Logs General',
  events: {
    'Clear Bm Logs': emptyProps(),
    'Add Bm Logs': props<{
      bmLogs: IBmLog[];
    }>()
  }
});
