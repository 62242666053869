import { IIncidentReportResident } from '$/models';
import { Dictionary } from '$shared/types';
import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import { incidentReportResidentsFeature } from './incident-report-residents.reducer';
import { incidentReportResidentsAdapter } from './incident-report-residents.state';

export const {
  selectIncidentReportResidentsState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = incidentReportResidentsFeature;

export const { selectAll, selectTotal } =
  incidentReportResidentsAdapter.getSelectors(
    selectIncidentReportResidentsState
  );

export const selectGroupedByIncidentReportId = createSelector(
  selectAll,
  (incidentReportResidents): Dictionary<IIncidentReportResident[]> => {
    return groupBy(incidentReportResidents, 'incidentReportId');
  }
);
