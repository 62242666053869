import { Directive, HostListener, inject, Input } from '@angular/core';
import { MenuController } from '@ionic/angular/standalone';

@Directive({ selector: '[alcMenuButtonToggle]', standalone: true })
export class AlcMenuButtonToggleDirective {
  private readonly menu = inject(MenuController);

  @Input() alcMenuButtonToggle: string;

  @HostListener('click')
  async toggle() {
    await this.menu.enable(true, this.alcMenuButtonToggle);
    await this.menu.toggle(this.alcMenuButtonToggle);
  }
}
