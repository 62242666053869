import { ToLuxonParam, ToLuxonParamOrCallable, toLuxon } from '$shared/utils';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxDateTime(max: ToLuxonParamOrCallable): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const maxVal = max instanceof Function ? max() : max;
    if (!maxVal) {
      return null;
    }

    const maxDateTime = toLuxon(maxVal);
    const value = control.value as ToLuxonParam;

    if (!value) {
      return null;
    }

    const valueDateTime = toLuxon(value);

    return valueDateTime <= maxDateTime
      ? null
      : { maxDateTime: 'Later than maximum datetime' };
  };
}
