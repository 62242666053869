import { FeathersService } from '$/app/services/feathers.service';
import { DocumentsWsActions } from '$/app/store/documents/documents.actions';
import { IDocument } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class DocumentsApiService extends AbstractApiService<IDocument> {
  constructor(feathers: FeathersService, store: Store) {
    super('documents', feathers, store, {
      entityName: 'document',
      created: DocumentsWsActions.documentCreated,
      patched: DocumentsWsActions.documentPatched,
      removed: DocumentsWsActions.documentRemoved
    });
  }
}
