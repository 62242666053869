import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  MedicationInventoriesApiActions,
  MedicationInventoriesGeneralActions,
  MedicationInventoriesListActions,
  MedicationInventoryDetailActions,
  MedicationInventoryFormActions
} from './actions';
import {
  State,
  initialState,
  medicationInventoriesAdapter
} from './medication-inventories.state';

const reducer = createReducer(
  initialState,

  on(
    MedicationInventoriesListActions.loadMedicationInventories,
    MedicationInventoriesListActions.getMedicationInventories,
    MedicationInventoryDetailActions.fetchMedicationInventory,
    MedicationInventoryFormActions.createMedicationInventory,
    MedicationInventoryDetailActions.updateMedicationInventory,
    MedicationInventoryDetailActions.deleteMedicationInventory,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    MedicationInventoriesApiActions.loadMedicationInventoriesSuccess,
    (state, action) => {
      return medicationInventoriesAdapter.setAll(
        action.medicationInventories.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoriesApiActions.getMedicationInventoriesSuccess,
    (state, action) => {
      return medicationInventoriesAdapter.upsertMany(
        action.medicationInventories.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoriesApiActions.addMedicationInventories_dashboardRoute,
    (state, action) => {
      const medicationInventories = castArray(action.medicationInventory);
      return medicationInventoriesAdapter.upsertMany(medicationInventories, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicationInventoriesApiActions.fetchMedicationInventorySuccess,
    (state, action) => {
      return medicationInventoriesAdapter.upsertOne(
        action.medicationInventory,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoriesApiActions.createMedicationInventorySuccess,
    (state, action) => {
      const medicationInventories = castArray(action.medicationInventory);

      return medicationInventoriesAdapter.addMany(medicationInventories, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicationInventoriesApiActions.updateMedicationInventorySuccess,
    (state, action) => {
      return medicationInventoriesAdapter.updateOne(
        action.medicationInventory,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoriesApiActions.deleteMedicationInventorySuccess,
    (state, action) => {
      return medicationInventoriesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicationInventoriesApiActions.loadMedicationInventoriesFail,
    MedicationInventoriesApiActions.getMedicationInventoriesFail,
    MedicationInventoriesApiActions.fetchMedicationInventoryFail,
    MedicationInventoriesApiActions.createMedicationInventoryFail,
    MedicationInventoriesApiActions.updateMedicationInventoryFail,
    MedicationInventoriesApiActions.deleteMedicationInventoryFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    MedicationInventoriesApiActions.medicationInventoriesCreated,
    MedicationInventoriesApiActions.medicationInventoriesPatched,
    (state, action) => {
      return medicationInventoriesAdapter.upsertOne(
        action.medicationInventory,
        {
          ...state
        }
      );
    }
  ),

  on(
    MedicationInventoriesApiActions.medicationInventoriesRemoved,
    (state, action) => {
      return medicationInventoriesAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(
    MedicationInventoriesGeneralActions.addMedicationInventories,
    (state, action) => {
      return medicationInventoriesAdapter.upsertMany(
        action.medicationInventories,
        {
          ...state,
          loading: false,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoriesGeneralActions.clearMedicationInventories,
    (state) => {
      return medicationInventoriesAdapter.removeAll({
        ...state,

        loading: false,
        loaded: false,
        error: null
      });
    }
  )
);

export function medicationInventoriesReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
