import { IDocumentPacket, Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const CreatePacketFromTemplateModalActions = createActionGroup({
  source: 'Create Packet From Template Modal',
  events: {
    'Get Document Template Packets': (params: Params = { query: {} }) => ({
      params
    }),
    'Create Document Packets': (
      documentPackets: IDocumentPacket[],
      params: Params = { query: {} }
    ) => ({
      documentPackets,
      params
    })
  }
});
