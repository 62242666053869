import { Logger } from '$shared/logger';
import { Directive, Input, OnChanges } from '@angular/core';

@Directive({ selector: '[alcDebug]', standalone: true })
export class AlcDebugDirective implements OnChanges {
  @Input({ required: true }) alcDebug: any;

  ngOnChanges() {
    Logger.debug('alcDebug', { payload: this.alcDebug });
  }
}
