import { Component, input } from '@angular/core';
import { IonSpinner } from '@ionic/angular/standalone';

@Component({
  standalone: true,
  selector: 'alc-loading-placeholder',
  imports: [IonSpinner],
  template: `
    <div class="flex h-full flex-col items-center justify-center">
      <ion-spinner />
      <div class="p-4">{{ text() }}</div>
    </div>
  `
})
export class AlcLoadingPlaceholderComponent {
  public text = input('Loading...');
}
