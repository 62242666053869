import { Medication, Params } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

export const fetchMedication_MedicationDetail = createAction(
  '[Medication Detail] Fetch Medication',
  (id: string, params: Params = { query: {} }) => ({ id, params })
);

export const addMedication_ResidentMedicationsRoute = createAction(
  '[Resident Medications Route Effect] Add Medications',
  props<{ medication: MaybeArray<Medication> }>()
);
