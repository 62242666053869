import { Table } from '$/models/data/base';

export class Room extends Table {
  orgId?: string;
  facilityId?: string;

  identifier?: string;
  description?: string;
  capacity?: number;
  hasBath?: boolean;
}
