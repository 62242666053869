import { CreatePacketFromTemplateModalActions } from '$/app/pages/documents/create-packet-from-template/create-packet-from-template.actions';
import { DocumentTemplatePacketFormPageActions } from '$/app/pages/documents/document-template-packet-form/document-template-packet-form.actions';
import { FacilityLibraryPageActions } from '$/app/pages/documents/facility-document-library/facility-document-library.actions';
import {
  DocumentTemplatePacketsApiService,
  EffectHelpersService
} from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { IDocumentTemplatePacket } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap
} from 'rxjs';
import { DocumentTemplatePacketsApiActions } from './document-template-packets.actions';

@Injectable()
export class DocumentTemplatePacketsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly documentTemplatePacketsService = inject(
    DocumentTemplatePacketsApiService
  );

  getDocumentTemplatePackets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FacilityLibraryPageActions.getDocumentTemplatePackets,
        CreatePacketFromTemplateModalActions.getDocumentTemplatePackets
      ),
      switchMap((action) => {
        return this.documentTemplatePacketsService.getAll(action.params).pipe(
          getAllPages(
            this.documentTemplatePacketsService,
            action?.params?.query
          ),
          reducePaginatedResponses(),
          mergeMap((response) => {
            const responseData = new ApiData(
              'documentTemplatePackets',
              response,
              DocumentTemplatePacketsApiActions.getDocumentTemplatePacketsSuccess
            );

            return responseData.getActions();
          }),
          catchError((error) => {
            return of(
              DocumentTemplatePacketsApiActions.getDocumentTemplatePacketsFail({
                error
              })
            );
          })
        );
      })
    );
  });

  createDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DocumentTemplatePacketFormPageActions.createDocumentTemplatePacket
      ),
      exhaustMap((action) =>
        from(this.effectHelpers.handleParamsOnRequest(action.params)).pipe(
          switchMap(() =>
            this.documentTemplatePacketsService
              .create(action.documentTemplatePacket, action.params)
              .pipe(
                tap((data: IDocumentTemplatePacket) => {
                  this.effectHelpers.handleParamsOnSuccess(action.params, data);
                }),
                map((documentTemplatePacket: IDocumentTemplatePacket) =>
                  DocumentTemplatePacketsApiActions.createDocumentTemplatePacketSuccess(
                    {
                      documentTemplatePacket
                    }
                  )
                ),
                catchError((error) => {
                  this.effectHelpers.handleParamsOnFail(action.params, {
                    error
                  });

                  return of(
                    DocumentTemplatePacketsApiActions.createDocumentTemplatePacketFail(
                      {
                        error
                      }
                    )
                  );
                })
              )
          )
        )
      )
    );
  });

  updateDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DocumentTemplatePacketFormPageActions.updateDocumentTemplatePacket
      ),
      exhaustMap((action) =>
        from(this.effectHelpers.handleParamsOnRequest(action.params)).pipe(
          switchMap(() =>
            this.documentTemplatePacketsService
              .patch(action.id, action.changes, action.params)
              .pipe(
                tap((data: IDocumentTemplatePacket) => {
                  this.effectHelpers.handleParamsOnSuccess(action.params, data);
                }),
                map((documentTemplatePacket: IDocumentTemplatePacket) =>
                  DocumentTemplatePacketsApiActions.updateDocumentTemplatePacketSuccess(
                    {
                      documentTemplatePacket
                    }
                  )
                ),
                catchError((error) => {
                  this.effectHelpers.handleParamsOnFail(action.params, {
                    error
                  });

                  return of(
                    DocumentTemplatePacketsApiActions.updateDocumentTemplatePacketFail(
                      { error }
                    )
                  );
                })
              )
          )
        )
      )
    );
  });

  deleteDocumentTemplatePacket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityLibraryPageActions.deleteDocumentTemplatePacket),
      exhaustMap((action) =>
        from(this.effectHelpers.handleParamsOnRequest(action.params)).pipe(
          switchMap(() =>
            this.documentTemplatePacketsService
              .delete(action.id, action.params)
              .pipe(
                tap((data: IDocumentTemplatePacket) => {
                  this.effectHelpers.handleParamsOnSuccess(action.params, data);
                }),
                map((documentTemplatePacket: IDocumentTemplatePacket) =>
                  DocumentTemplatePacketsApiActions.deleteDocumentTemplatePacketSuccess(
                    {
                      id: documentTemplatePacket.id
                    }
                  )
                ),
                catchError((error) => {
                  this.effectHelpers.handleParamsOnFail(action.params, {
                    error
                  });

                  return of(
                    DocumentTemplatePacketsApiActions.deleteDocumentTemplatePacketFail(
                      {
                        error
                      }
                    )
                  );
                })
              )
          )
        )
      )
    );
  });
}
