import { ILibraryCollectionDocumentTemplatePacket } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LibraryCollectionDocumentTemplatePacketsGeneralActions =
  createActionGroup({
    source: 'Library Collection Document Template Packets General',
    events: {
      'Clear Library Collection Document Template Packets': emptyProps(),
      'Add Library Collection Document Template Packets': props<{
        libraryCollectionDocumentTemplatePackets: ILibraryCollectionDocumentTemplatePacket[];
      }>()
    }
  });
