import { medicationDestructionFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-form/medication-destruction-form.actions';
import { MedicationDestructionItemFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-item-form/medication-destruction-item.actions';
import { DashboardMedicationDestructionPageActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction.actions';
import { InventoryItemDestructionModalActions } from '$/app/pages/medications/residents/inventory-item-destruction-modal/inventory-item-destruction.modal.actions';
import { EffectHelpersService } from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicationDestructionItemsApiActions } from './medication-destruction-items.actions';
import { MedicationDestructionItemsService } from './medication-destruction-items.service';

@Injectable()
export class MedicationDestructionItemsEffects {
  private readonly medicationDestructionItemsService = inject(
    MedicationDestructionItemsService
  );
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadMedicationDestructionItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardMedicationDestructionPageActions.loadMedicationDestructionItems,
        medicationDestructionFormActions.loadMedicationDestructionItems
      ),
      this.effectHelpers.apiRequest({
        description: 'Load Medication Destruction Items',
        useMapOperator: 'switchMap',
        onRequest: (action) =>
          this.medicationDestructionItemsService
            .getAll(action.params)
            .pipe(
              getAllPages(
                this.medicationDestructionItemsService,
                action?.params?.query
              ),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medicationDestructionItems',
            response,
            MedicationDestructionItemsApiActions.loadMedicationDestructionItemsSuccess
          );
          return responseData.getActions();
        },
        onError: (error) =>
          MedicationDestructionItemsApiActions.loadMedicationDestructionItemsFail(
            {
              error
            }
          )
      })
    );
  });

  createMedicationDestructionItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationDestructionItemFormActions.createMedicationDestructionItems,
        InventoryItemDestructionModalActions.createMedicationDestructionItems
      ),
      this.effectHelpers.apiRequest({
        description: 'Create Medication Destruction Item',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.medicationDestructionItemsService.create(
            action.data,
            action.params
          ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medicationDestructionItems',
            response,
            MedicationDestructionItemsApiActions.createMedicationDestructionItemSuccess,
            {
              payloadKey: 'medicationDestructionItem'
            }
          );
          return responseData.getActions();
        },
        onError: (error) =>
          MedicationDestructionItemsApiActions.createMedicationDestructionItemFail(
            {
              error
            }
          )
      })
    );
  });

  deleteMedicationDestructionItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardMedicationDestructionPageActions.deleteMedicationDestructionItem
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete medication destruction item',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.medicationDestructionItemsService.delete(
            action.id,
            action.params
          );
        },
        onSuccess: (document) =>
          MedicationDestructionItemsApiActions.deleteMedicationDestructionItemSuccess(
            {
              id: document.id
            }
          ),
        onError: (error) =>
          MedicationDestructionItemsApiActions.deleteMedicationDestructionItemFail(
            { error }
          )
      })
    );
  });

  updateMedicationDestructionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MedicationDestructionItemFormActions.updateMedicationDestructionItem
      ),
      this.effectHelpers.apiRequest({
        description: 'Updating Medication Destruction Item',
        onRequest: (action) =>
          this.medicationDestructionItemsService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (medicationDestructionItem) =>
          MedicationDestructionItemsApiActions.updateMedicationDestructionItemSuccess(
            { medicationDestructionItem }
          ),
        onError: (error) =>
          MedicationDestructionItemsApiActions.updateMedicationDestructionItemFail(
            { error }
          )
      })
    )
  );
}
