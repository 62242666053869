import { Injectable, inject } from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  PopoverController,
  ToastController
} from '@ionic/angular/standalone';
import { RouterUtilityService } from '../../utils';
import { showAlert } from './#alerts';
import { confirm, confirmKeyword, confirmWhen } from './#confirm';
import { dismissAll, dismissOverlay } from './#dismiss';
import { hideLoading, loading, showLoading } from './#loading';
import {
  dismissModal,
  getModalResult,
  getTopModal,
  showModal,
  showModalSingleton,
  showModal_sync
} from './#modals';
import { getPopoverResult, showPopover } from './#popovers';
import { prompt } from './#prompt';
import { getSheetModalResult, showSheetModal } from './#sheet-modals';
import { showErrorMessage } from './#show-error-message';
import { showLogModal } from './#show-log-modal';
import { showToast } from './#toast';
import { AlcModalOptions, AlcPopoverOptions } from './types';

@Injectable({ providedIn: 'root' })
export class OverlayService {
  protected readonly alertController = inject(AlertController);
  protected readonly menuController = inject(MenuController);
  protected readonly actionSheetController = inject(ActionSheetController);
  protected readonly modalController = inject(ModalController);
  protected readonly toastController = inject(ToastController);
  protected readonly popController = inject(PopoverController);
  protected readonly loadingController = inject(LoadingController);
  protected readonly routerUtils = inject(RouterUtilityService);

  // NOTE: This is a helper function that allows us to bind the context of a function to the current class
  // and maintain the type information of the bound function
  private bind<TFn extends (...args: any[]) => any>(fn: TFn): TFn {
    return fn.bind(this);
  }

  public dismissAll = this.bind(dismissAll);
  protected dismissOverlay = this.bind(dismissOverlay);

  public showAlert = this.bind(showAlert);

  public confirm = this.bind(confirm);
  public confirmWhen = this.bind(confirmWhen);
  public confirmKeyword = this.bind(confirmKeyword);

  public prompt = this.bind(prompt);

  public loading = this.bind(loading);
  public showLoading = this.bind(showLoading);
  public hideLoading = this.bind(hideLoading);

  public showErrorMessage = this.bind(showErrorMessage);
  public showToast = this.bind(showToast);

  protected _showModal = this.bind(showModal);
  public async showModal<TComponent>(
    opts?: AlcModalOptions<TComponent>
  ): Promise<HTMLIonModalElement> {
    return await this._showModal(opts);
  }
  public showModal_sync = this.bind(showModal_sync);
  public getModalResult = this.bind(getModalResult);
  public showModalSingleton = this.bind(showModalSingleton);
  public getTopModal = this.bind(getTopModal);
  public dismissModal = this.bind(dismissModal);

  public showLogModal = this.bind(showLogModal);

  protected _showSheetModal = this.bind(showSheetModal);
  public async showSheetModal<TComponent = any>(
    this: OverlayService,
    opts: AlcModalOptions<TComponent>
  ): Promise<void> {
    await this._showSheetModal(opts);
  }
  public getSheetModalResult = this.bind(getSheetModalResult);

  protected _showPopover = this.bind(showPopover);
  public async showPopover<TComponent = any>(
    this: OverlayService,
    opts: AlcPopoverOptions<TComponent>
  ): Promise<void> {
    await this._showPopover(opts);
  }
  public getPopoverResult = this.bind(getPopoverResult);
}
