import * as RouterSelectors from '$/app/store/router/router.selectors';
import { SeizureLogSymptomsSelectors } from '$/app/store/seizure-log-symptoms';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { SeizureLog, Symptom } from '$/models';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { SymptomsSelectors } from '../symptoms';
import { seizureLogsFeature } from './seizure-logs.reducer';
import { seizureLogsAdapter } from './seizure-logs.state';

type SeizureLogGraph = SeizureLog & {
  symptoms?: Symptom[];
};

export const {
  selectSeizureLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = seizureLogsFeature;

export const { selectAll, selectTotal } = seizureLogsAdapter.getSelectors(
  selectSeizureLogsState
);

export const selectAllWithRelations = createSelector(
  selectAll,
  SeizureLogSymptomsSelectors.selectSymptomsBySeizureLogId,
  SymptomsSelectors.selectSeizureSymptoms,
  (
    logs,
    seizureLogSymptomsBySeizureLogId,
    seizureSymptoms
  ): SeizureLogGraph[] => {
    return logs.map((log) => {
      const seizureLogSymptoms = seizureLogSymptomsBySeizureLogId[log.id] ?? [];

      return {
        ...log,
        symptoms: seizureLogSymptoms.map((seizureLogSymptom) => {
          return seizureSymptoms[seizureLogSymptom.symptomId];
        })
      };
    });
  }
);

export const selectResidentSeizureLogs = createSelector(
  selectAllWithRelations,
  RouterSelectors.selectParam('residentId'),
  (bpLogs, residentId): SeizureLogGraph[] => {
    if (isEmpty(bpLogs) || !residentId) {
      return [];
    }

    return bpLogs.filter((bpLog) => {
      return bpLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask = createLogsByMedicationTaskSelector(
  selectAllWithRelations
);

export const selectLogsByCareTask = createLogsByCareTaskSelector(
  selectAllWithRelations
);

export const selectLogsByPrn = createLogsByPrnSelector(selectAllWithRelations);

export const selectLogsByQuery = (query: Query<SeizureLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<SeizureLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<SeizureLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => ({
    metrics: [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length ?? 0
      }
    ],
    logs
  }));
};
