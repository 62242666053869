import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { bmLogsFeature } from './bowel-movement-logs.reducer';
import { BmLogsEffects } from './bowel-movements-logs.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(bmLogsFeature),
    EffectsModule.forFeature([BmLogsEffects])
  ],
  providers: [BmLogsEffects]
})
export class BmLogsStoreModule {}
