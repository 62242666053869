import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResidentContactPhonesEffects } from './resident-contact-phones.effects';
import { residentContactPhonesReducer } from './resident-contact-phones.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'resident-contact-phones',
      residentContactPhonesReducer
    ),
    EffectsModule.forFeature([ResidentContactPhonesEffects])
  ],
  providers: [ResidentContactPhonesEffects]
})
export class ResidentContactPhoneStoreModule {}
