import { FacilitySettingsSelectors } from '$/app/store';
import { SettingCodes } from '$shared/facility-settings/constants';
import { ComparisonOperator, compare } from '$shared/utils';
import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  effect,
  inject,
  input
} from '@angular/core';
import { Store } from '@ngrx/store';

type Comparison = {
  operator: ComparisonOperator;
  value: any;
};

@Directive({ selector: '[alcSetting]', standalone: true })
export class AlcSettingDirective {
  private readonly view = inject(ViewContainerRef);
  private readonly template = inject(TemplateRef);
  private readonly store = inject(Store);

  alcSetting = input.required<string>();
  alcSettingValue = input(undefined, {
    transform: (value: any): Comparison => {
      if (typeof value === 'object') {
        return value;
      } else {
        return {
          operator: '=',
          value
        };
      }
    }
  });

  alcSettingElse: TemplateRef<any>;

  constructor() {
    effect(() => {
      if (!this.alcSetting) {
        return;
      }

      const settingValue = this.store.selectSignal(
        FacilitySettingsSelectors.selectCurrentFacilitySettingValueByCode(
          this.alcSetting() as SettingCodes
        )
      )();

      const operator = this.alcSettingValue()?.operator || '=';
      const value = this.alcSettingValue()?.value;
      const shouldShow = value
        ? compare(settingValue['value'], operator, value)
        : !!settingValue['value'];

      this.view.clear();

      if (shouldShow) {
        this.view.createEmbeddedView(this.template);
      } else if (this.alcSettingElse) {
        this.view.createEmbeddedView(this.alcSettingElse);
      }
    });
  }
}
