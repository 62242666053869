import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AnnouncementsEffects } from './announcements.effects';
import { announcementsFeature } from './announcements.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(announcementsFeature),
    EffectsModule.forFeature([AnnouncementsEffects])
  ],
  providers: [AnnouncementsEffects]
})
export class AnnouncementsStoreModule {}
