import { Table } from '$/models/data/base';
import { MedicationTaskStateId } from '$shared/lookups/medication-task-states.lookup';

export class MedicationTask extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  medicationId?: string;
  medicationScheduleId?: string;
  medicationBatchId?: string;
  scheduledFor?: string;
  remindAt?: string;
  currentState?: MedicationTaskStateId;
  amountNotes: string;
  // This are properties defined by the $modify
  movementCount?: string;
  lastMovementType?: string;

  constructor(medicationTask: MedicationTask) {
    super();
    Object.assign(this, medicationTask);
  }
}
