import { ResidentInsurancesStoreModule } from './resident-insurances-store.module';
import * as ResidentInsurancesSelectors from './resident-insurances.selectors';
import * as ResidentInsurancesState from './resident-insurances.state';

export * from './resident-insurances.actions';
export {
  ResidentInsurancesStoreModule,
  ResidentInsurancesSelectors,
  ResidentInsurancesState
};
