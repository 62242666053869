import { ILibraryCollectionDocumentTemplate, IPaginationInfo } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const libraryCollectionDocumentTemplatesAdapter =
  createEntityAdapter<ILibraryCollectionDocumentTemplate>();

export interface State extends EntityState<ILibraryCollectionDocumentTemplate> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  libraryCollectionDocumentTemplatesAdapter.getInitialState({
    pagination: {},
    loading: false,
    loaded: false,
    error: null
  });
