import { FeathersService } from '$/app/services/feathers.service';
import { BodyCheckLogsWsActions } from '$/app/store/body-check-logs/body-check-logs.actions';
import { IBodyCheckLog } from '$shared/services/body-check-log';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class BodyCheckLogsApiService extends AbstractApiService<IBodyCheckLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('body-check-logs', feathers, store, {
      entityName: 'bodyCheckLog',
      created: BodyCheckLogsWsActions.bodyCheckLogsCreated,
      patched: BodyCheckLogsWsActions.bodyCheckLogsPatched,
      removed: BodyCheckLogsWsActions.bodyCheckLogsRemoved
    });
  }
}
