import {
  EffectHelpersService,
  ResidentContactsApiService
} from '$/app/services';
import { ResidentContactFormActions } from '$/app/shared/pages/forms/resident-contact-form/resident-contact-form.actions';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, switchMap, tap } from 'rxjs/operators';

import { ResidentContactComponentActions } from '$/app/pages/residents/resident-detail/resident-contact-list-item/resident-contact.actions';
import { ResidentContactsApiActions } from './actions';

@Injectable()
export class ResidentContactsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly residentContactsService = inject(ResidentContactsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  createResidentContact$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentContactFormActions.createResidentContact),
        exhaustMap((action) => {
          return this.residentContactsService
            .create(action.residentContact)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'Contact created successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  ResidentContactsApiActions.createResidentContactFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  createResidentContactFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentContactsApiActions.createResidentContactFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateResidentContact$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentContactFormActions.updateResidentContact,
          ResidentContactComponentActions.setPrimaryContact,
          ResidentContactComponentActions.setEmergencyContact,
          ResidentContactComponentActions.setPowerOfAttorney,
          ResidentContactComponentActions.setConservator
        ),
        switchMap((action) => {
          return this.residentContactsService
            .patch(action.id, action.changes)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'Contact updated successfully!'
                )
              ),

              catchError((error) => {
                this.store.dispatch(
                  ResidentContactsApiActions.updateResidentContactFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateResidentContactSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentContactsApiActions.updateResidentContactSuccess)
      );
    },
    { dispatch: false }
  );

  updateResidentContactFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentContactsApiActions.updateResidentContactFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteResidentContact$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentContactComponentActions.deleteResidentContact),
        exhaustMap((action) => {
          return this.residentContactsService.delete(action.id).pipe(
            catchError((error) => {
              this.store.dispatch(
                ResidentContactsApiActions.deleteResidentContactFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteResidentContactFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentContactsApiActions.deleteResidentContactFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
