import { incidentReportsFeature } from './incident-reports.reducer';
import { incidentReportsAdapter } from './incident-reports.state';

export const {
  selectIncidentReportsState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = incidentReportsFeature;

export const { selectAll, selectTotal } = incidentReportsAdapter.getSelectors(
  selectIncidentReportsState
);
