import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { medicationOrdersQueueReducer } from './medication-orders-queue.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'medicationOrdersQueue',
      medicationOrdersQueueReducer
    )
  ],
  providers: []
})
export class MedicationOrdersQueueStoreModule {}
