import { UiCarListPageStoreModule } from '$/app/pages/care/residents/car-list/state/car-list-page-store.module';
import { CommunicationRoutingEffects } from '$/app/pages/communication/communication-routing.effects';
import { UiMarSummaryPageStoreModule } from '$/app/pages/medications/residents/mar-summary/state/mar-summary-page-store.module';
import { UiMedicationListPageStoreModule } from '$/app/pages/medications/residents/medication-list/state/medication-list-page-store.module';
import { UiPrnMarListPageStoreModule } from '$/app/pages/medications/residents/prn-mar-list/state/prn-mar-list-page-store.module';
import { UiRoutineMarListPageStoreModule } from '$/app/pages/medications/residents/routine-mar-list/state/routine-mar-list-page-store.module';
import { UiShiftNotesPageStoreModule } from '$/app/pages/notebook/shared/shift-notes/state/shift-notes-store.module';
import { StaffRoutingEffects } from '$/app/pages/staff/staff-routing.effects';
import { AllergiesStoreModule } from '$/app/store/allergies';
import { AppEffects } from '$/app/store/app.effects';
import { AppointmentsStoreModule } from '$/app/store/appointments/appointments-store.module';
import { BloodPressureLogsStoreModule } from '$/app/store/blood-pressure-logs';
import { BmLogsStoreModule } from '$/app/store/bowel-movement-logs';
import { FacilitiesStoreModule } from '$/app/store/facilities';
import { FacilitySettingsStoreModule } from '$/app/store/facility-settings';
import { FacilityUsersStoreModule } from '$/app/store/facility-users';
import { FoodLogStoreModule } from '$/app/store/food-logs';
import { MedicalEventsStoreModule } from '$/app/store/medical-events';
import { MedicationDestructionItemsStoreModule } from '$/app/store/medication-destruction-items/medication-destruction-items-store.module';
import { MedicationDestructionsStoreModule } from '$/app/store/medication-destructions/medication-destructions-store.module';
import { MedicationSchedulesStoreModule } from '$/app/store/medication-schedules/medication-schedules-store.module';
import { MedicationsStoreModule } from '$/app/store/medications/medications-store.module';
import { getMetaReducers } from '$/app/store/meta-reducers';
import { MoodLogStoreModule } from '$/app/store/mood-logs';
import { OrganizationsStoreModule } from '$/app/store/organizations/organizations-store.module';
import { reducers } from '$/app/store/reducers';
import { ResidentContactsStoreModule } from '$/app/store/resident-contacts';
import { ResidentInsurancesStoreModule } from '$/app/store/resident-insurances';
import { ResidentsStoreModule } from '$/app/store/residents/residents-store.module';
import { AlcomyCustomRouteSerializer } from '$/app/store/router/custom-route-serializer';
import { ShiftNotesStoreModule } from '$/app/store/shift-notes';
import { UrineLogsStoreModule } from '$/app/store/urine-logs/urine-logs-store.module';
import { UserStoreModule } from '$/app/store/user';
import { WaterLogStoreModule } from '$/app/store/water-logs';
import { WeightLogStoreModule } from '$/app/store/weight-logs';
import { environment } from '$/environments/environment';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UiMedicationOrdersListPageStoreModule } from '../pages/medications/dashboard/medication-orders/medication-orders-list/state/medication-orders-list-page-store.module';
import { MedicationDetailInventoryEffects } from '../pages/medications/residents/medication-detail/medication-detail-inventory/medication-detail-inventory.effects';
import { UiEventListPageStoreModule } from '../pages/notebook/residents/event-list-filter/state/event-list-filter-store.module';
import { MixpanelService } from '../services/utils/mixpanel.service';
import { UiResidentsFilterStoreModule } from '../shared/pages/popovers/residents-filter/state/residents-filter-store.module';
import { AcknowledgementsStoreModule } from './acknowledgements/acknowledgements-store.module';
import { AnnouncementRepliesStoreModule } from './announcement-replies';
import { AnnouncementsStoreModule } from './announcements/announcements-store.module';
import { BehaviorLogsStoreModule } from './behavior-logs/behavior-logs-store.module';
import { BehaviorsStoreModule } from './behaviors/behaviors-store.module';
import { BloodSugarLogsStoreModule } from './blood-sugar-logs/blood-sugar-logs-store.module';
import { BodyCheckLogsStoreModule } from './body-check-logs/body-check-logs-store.module';
import { BodyCheckObservationsStoreModule } from './body-check-observations/body-check-observations-store.module';
import { CarePlanItemSchedulesStoreModule } from './care-plan-item-schedules/care-plan-item-schedules-store.module';
import { CarePlanItemsStoreModule } from './care-plan-items/care-plan-items-store.module';
import { CareTasksStoreModule } from './care-tasks/care-tasks-store.module';
import { DestructionMethodsStoreModule } from './destruction-methods';
import { DiagnosesStoreModule } from './diagnoses/diagnoses-store.module';
import { DocumentPacketDocumentsStoreModule } from './document-packet-documents';
import { DocumentPacketsStoreModule } from './document-packets';
import { DocumentParticipantsStoreModule } from './document-participants';
import { DocumentRelationsStoreModule } from './document-relations';
import { DocumentTemplatePacketTemplatesStoreModule } from './document-template-packet-templates';
import { DocumentTemplatePacketsStoreModule } from './document-template-packets';
import { DocumentTemplatesStoreModule } from './document-templates';
import { DocumentsStoreModule } from './documents';
import { FacilityPhonesStoreModule } from './facility-phones/facility-phones-store.module';
import { FacilityUserGroupMembersStoreModule } from './facility-user-group-members/facility-user-group-members-store.module';
import { FacilityUserGroupsStoreModule } from './facility-user-groups/facility-user-groups-store.module';
import { FacilityUserPhonesStoreModule } from './facility-user-phones/facility-user-phones-store.module';
import { FacilityUserRolesStoreModule } from './facility-user-roles/facility-user-roles-store.module';
import { FilesStoreModule } from './files';
import { GeolocationStoreModule } from './geolocation/geolocation-store.module';
import { IncidentReportResidentsStoreModule } from './incident-report-residents';
import { IncidentReportsStoreModule } from './incident-reports';
import { InstalledLibraryCollectionsStoreModule } from './installed-library-collections/installed-library-collections-store.module';
import { LibraryCollectionDocumentTemplatePacketsStoreModule } from './library-collection-document-template-packets';
import { LibraryCollectionDocumentTemplatesStoreModule } from './library-collection-document-templates';
import { LibraryCollectionsStoreModule } from './library-collections/library-collections-store.module';
import { LibraryDocumentTemplatePacketTemplatesStoreModule } from './library-document-template-packet-templates';
import { LibraryDocumentTemplatePacketsStoreModule } from './library-document-template-packets';
import { LibraryDocumentTemplatesStoreModule } from './library-document-templates';
import { MedicalHistoriesStoreModule } from './medical-histories/medical-histories-store.module';
import { MedicalProfessionalPhonesStoreModule } from './medical-professional-phones/medical-professional-phones-store.module';
import { MedicalProfessionalsStoreModule } from './medical-professionals/medical-professionals-store.module';
import { MedicationInventoriesStoreModule } from './medication-inventories/medication-inventories-store.module';
import { MedicationInventoryItemCountsStoreModule } from './medication-inventory-item-counts/medication-inventory-item-counts-store.module';
import { MedicationInventoryItemsStoreModule } from './medication-inventory-items/medication-inventory-items-store.module';
import { MedicationInventoryMovementsStoreModule } from './medication-inventory-movements/medication-inventory-movements-store.module';
import { MedicationMarsStoreModule } from './medication-mars/medication-mars-store.module';
import { MedicationOrderItemsStoreModule } from './medication-order-items/medication-order-items-store.module';
import { MedicationOrdersQueueStoreModule } from './medication-orders-queue/medication-orders-queue-store.module';
import { MedicationOrdersStoreModule } from './medication-orders/medication-orders-store.module';
import { MedicationTaskAmountsStoreModule } from './medication-task-amounts/medication-task-amounts-store.module';
import { MedicationTasksStoreModule } from './medication-tasks/medication-tasks-store.module';
import { NotificationsStoreModule } from './notifications/notifications-store.module';
import { OmittedAmountsStoreModule } from './omitted-amounts/omitted-amounts-store.module';
import { OmittedMedicationsStoreModule } from './omitted-medications/omitted-medications-store.module';
import { OxygenLogsStoreModule } from './oxygen-logs';
import { PharmaciesStoreModule } from './pharmacies/pharmacies-store.module';
import { PharmacyPhonesStoreModule } from './pharmacy-phones/pharmacy-phones-store.module';
import { PositionLogsStoreModule } from './position-logs';
import { PrnAmountsStoreModule } from './prn-amounts/prn-amounts-store.module';
import { PrnsStoreModule } from './prns/prns-store.module';
import { ResidentContactPhoneStoreModule } from './resident-contact-phones';
import { ResidentGroupMembersStoreModule } from './resident-group-members/resident-group-members-store.module';
import { ResidentGroupsStoreModule } from './resident-groups/resident-groups-store.module';
import { ResidentLogTypesStoreModule } from './resident-log-types';
import { ResidentMedicalProfessionalsStoreModule } from './resident-medical-professionals/resident-medical-professionals-store.module';
import { ResidentReportConfigsStoreModule } from './resident-report-configs/resident-report-configs-store.module';
import { RolePermissionsStoreModule } from './role-permissions/role-permissions-store.module';
import { RolesStoreModule } from './roles/roles-store.module';
import { RoomsStoreModule } from './rooms/rooms-store.module';
import { SeizureLogSymptomsStoreModule } from './seizure-log-symptoms';
import { SeizureLogsStoreModule } from './seizure-logs';
import { ShowerLogsStoreModule } from './shower-logs';
import { SleepLogsStoreModule } from './sleep-logs/sleep-logs-store.module';
import { SymptomsStoreModule } from './symptoms';
import { TemperatureLogsStoreModule } from './temperature-logs';

@NgModule({
  imports: [
    CommonModule,
    // Store Modules
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        // NOTE: Turning this on technically worked, however, I would get weird
        // errors occasionally. Something about 'microTask' not being defined.
        strictStateImmutability: false,
        // NOTE: The app loses its mind when this is turned on.
        strictActionImmutability: false,
        // NOTE: The Error object is not serializable by default. In order to
        // turn this on we would need to serialize all errors before they get
        // stored to the store.
        strictStateSerializability: false,
        // NOTE: We can't turn this on because we break this rule with our
        // special params object that can contain functions which are not
        // serializable. This is not necessarily bad since we ignore params in
        // the reducers. i.e. they never get saved to the store.
        strictActionSerializability: false,
        // NOTE: Something is causing the app to lose its mind when either of
        // these next two get turned on.
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false
      }
    }),
    FacilitySettingsStoreModule,
    EffectsModule.forRoot([
      AppEffects,

      MedicationDetailInventoryEffects,
      StaffRoutingEffects,
      CommunicationRoutingEffects
    ]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({ name: 'Alcomy', connectInZone: true }),
    AcknowledgementsStoreModule,
    AllergiesStoreModule,
    AppointmentsStoreModule,
    AnnouncementRepliesStoreModule,
    AnnouncementsStoreModule,
    BehaviorLogsStoreModule,
    BehaviorsStoreModule,
    BloodPressureLogsStoreModule,
    BloodSugarLogsStoreModule,
    BmLogsStoreModule,
    BodyCheckLogsStoreModule,
    BodyCheckObservationsStoreModule,
    CarePlanItemSchedulesStoreModule,
    CarePlanItemsStoreModule,
    CareTasksStoreModule,
    DestructionMethodsStoreModule,
    DiagnosesStoreModule,
    DocumentPacketDocumentsStoreModule,
    DocumentPacketsStoreModule,
    DocumentParticipantsStoreModule,
    DocumentRelationsStoreModule,
    DocumentsStoreModule,
    DocumentTemplatePacketsStoreModule,
    DocumentTemplatePacketTemplatesStoreModule,
    DocumentTemplatesStoreModule,
    FacilitiesStoreModule,
    FacilityPhonesStoreModule,
    FacilityUserGroupMembersStoreModule,
    FacilityUserGroupsStoreModule,
    FacilityUserPhonesStoreModule,
    FacilityUserRolesStoreModule,
    FacilityUsersStoreModule,
    FilesStoreModule,
    FoodLogStoreModule,
    GeolocationStoreModule,
    IncidentReportResidentsStoreModule,
    IncidentReportsStoreModule,
    InstalledLibraryCollectionsStoreModule,
    LibraryCollectionDocumentTemplatePacketsStoreModule,
    LibraryCollectionDocumentTemplatesStoreModule,
    LibraryCollectionsStoreModule,
    LibraryDocumentTemplatePacketsStoreModule,
    LibraryDocumentTemplatePacketTemplatesStoreModule,
    LibraryDocumentTemplatesStoreModule,
    MedicalEventsStoreModule,
    MedicalHistoriesStoreModule,
    MedicalProfessionalPhonesStoreModule,
    MedicalProfessionalsStoreModule,
    MedicationDestructionItemsStoreModule,
    MedicationDestructionsStoreModule,
    MedicationInventoriesStoreModule,
    MedicationInventoryItemCountsStoreModule,
    MedicationInventoryItemsStoreModule,
    MedicationInventoryMovementsStoreModule,
    MedicationMarsStoreModule,
    MedicationOrderItemsStoreModule,
    MedicationOrdersQueueStoreModule,
    MedicationOrdersStoreModule,
    MedicationSchedulesStoreModule,
    MedicationsStoreModule,
    MedicationTaskAmountsStoreModule,
    MedicationTasksStoreModule,
    MoodLogStoreModule,
    NotificationsStoreModule,
    OmittedAmountsStoreModule,
    OmittedMedicationsStoreModule,
    OrganizationsStoreModule,
    OxygenLogsStoreModule,
    PharmaciesStoreModule,
    PharmacyPhonesStoreModule,
    PositionLogsStoreModule,
    PrnAmountsStoreModule,
    PrnsStoreModule,
    ResidentContactPhoneStoreModule,
    ResidentContactsStoreModule,
    ResidentInsurancesStoreModule,
    ResidentGroupsStoreModule,
    ResidentGroupMembersStoreModule,
    ResidentLogTypesStoreModule,
    ResidentMedicalProfessionalsStoreModule,
    ResidentReportConfigsStoreModule,
    ResidentsStoreModule,
    RolePermissionsStoreModule,
    RolesStoreModule,
    RoomsStoreModule,
    SeizureLogsStoreModule,
    SeizureLogSymptomsStoreModule,
    ShiftNotesStoreModule,
    ShowerLogsStoreModule,
    SleepLogsStoreModule,
    StoreRouterConnectingModule.forRoot({
      serializer: AlcomyCustomRouteSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    SymptomsStoreModule,
    TemperatureLogsStoreModule,
    UiMarSummaryPageStoreModule,
    UiMedicationListPageStoreModule,
    UiMedicationOrdersListPageStoreModule,
    UiResidentsFilterStoreModule,
    UiRoutineMarListPageStoreModule,
    UiEventListPageStoreModule,
    UiPrnMarListPageStoreModule,
    UiCarListPageStoreModule,
    UiShiftNotesPageStoreModule,
    UrineLogsStoreModule,
    UserStoreModule,
    WaterLogStoreModule,
    WeightLogStoreModule
  ],
  providers: [
    {
      provide: USER_PROVIDED_META_REDUCERS,
      deps: [MixpanelService],
      useFactory: getMetaReducers
    }
  ]
})
export class AppStoreModule {}
