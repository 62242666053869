import { Update } from '$/models';
import { IBodyCheckLog } from '$shared/services/body-check-log';
import { createActionGroup, props } from '@ngrx/store';

export const BodyCheckLogFormPageActions = createActionGroup({
  source: 'Body Check Log Form Page',
  events: {
    'Create Body Check Log': props<{ bodyCheckLog: IBodyCheckLog }>(),
    'Update Body Check Log': props<Update<IBodyCheckLog>>()
  }
});
