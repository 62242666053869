import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { IResidentReportConfig } from '$shared/services/resident-report-configs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResidentReportConfigsWsActions } from './resident-report-configs.actions';

@Injectable({ providedIn: 'root' })
export class ResidentReportConfigService extends AbstractApiService<IResidentReportConfig> {
  constructor(feathers: FeathersService, store: Store) {
    super('resident-report-configs', feathers, store, {
      entityName: 'residentReportConfig',
      created: ResidentReportConfigsWsActions.residentReportConfigsCreated,
      patched: ResidentReportConfigsWsActions.residentReportConfigsPatched,
      removed: ResidentReportConfigsWsActions.residentReportConfigsRemoved
    });
  }
}
