import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MedicationTaskAmountsEffects } from './medication-task-amounts.effects';
import { medicationTaskAmountsFeature } from './medication-task-amounts.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(medicationTaskAmountsFeature),
    EffectsModule.forFeature([MedicationTaskAmountsEffects])
  ],
  providers: [MedicationTaskAmountsEffects]
})
export class MedicationTaskAmountsStoreModule {}
