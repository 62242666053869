import { feetInches } from '$shared/formatters/common';
import { HeightUnitId } from '$shared/lookups/height-units.lookup';
import { convertCentimetersToInches } from '$shared/utils/converters/convert-centimeters-to-inches';
import { convertInchesToFeet } from '$shared/utils/converters/convert-inches-to-feet';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feetInches',
  standalone: true
})
export class AlcFeetInchesPipe implements PipeTransform {
  transform(value: string | number, units: HeightUnitId = 'in') {
    if (!value) {
      return '';
    }

    if (units === 'cm') {
      value = convertCentimetersToInches(value);
    }

    const feetAndInches = convertInchesToFeet(value);

    return feetInches(feetAndInches);
  }
}
