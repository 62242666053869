import { createActionGroup, props } from '@ngrx/store';

import { ResidentsFilterVM } from '../residents-filter.vmodel';

export const ResidentsFilterActions = createActionGroup({
  source: 'Residents Filter Component',
  events: {
    'Filter Changed': props<{ filters: ResidentsFilterVM }>()
  }
});
