import { Type } from '@angular/core';

export function createComponentLoader<
  TModule extends Record<string, Type<unknown>>
>(
  componentName: keyof TModule,
  importer: () => Promise<TModule>
): () => Promise<TModule[typeof componentName]> {
  return async () => (await importer())[componentName];
}
