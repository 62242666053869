import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'alc-release-item',
  standalone: true,
  imports: [CommonModule, IonIcon],
  templateUrl: './release-item.component.html',
  styleUrls: ['./release-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcReleaseItemComponent implements OnInit {
  icon;
  @Input() type: 'improvement' | 'feature' | 'bugfix' | 'change';

  ngOnInit() {
    switch (this.type) {
      case 'improvement':
        this.icon = 'assets/icon/material/trending_up.svg';
        break;
      case 'feature':
        this.icon = 'assets/icon/material/new_releases_outline.svg';

        break;
      case 'bugfix':
        this.icon = 'assets/icon/material/bug_report.svg';
        break;
      case 'change':
        this.icon = 'assets/icon/material/change_circle.svg';
        break;
      default:
        throw new Error(
          `Invalid type for release highlight. Should be either "improvement", "change", "feature", or "bugfix". Got "${this.type}" instead`
        );
    }
  }
}
