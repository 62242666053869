import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PrnAmountsEffects } from './prn-amounts.effects';
import { prnAmountsFeature } from './prn-amounts.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(prnAmountsFeature),
    EffectsModule.forFeature([PrnAmountsEffects])
  ],
  providers: [PrnAmountsEffects]
})
export class PrnAmountsStoreModule {}
