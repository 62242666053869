import { MedicationOrderItem, Update } from '$/models';
import { Params } from '@feathersjs/feathers';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationPendingOrderItemComponentActions = createActionGroup({
  source: 'Medication Pending Order Item',
  events: {
    'Cancel Medication Order Item': props<Update<MedicationOrderItem>>(),
    'Delete Medication Order Item': props<{
      id: string;
      params: Params;
    }>()
  }
});
