import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AnnouncementRepliesEffects } from './announcement-replies.effects';
import { announcementRepliesFeature } from './announcement-replies.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(announcementRepliesFeature),
    EffectsModule.forFeature([AnnouncementRepliesEffects])
  ],
  providers: [AnnouncementRepliesEffects]
})
export class AnnouncementRepliesStoreModule {}
