import { MedicationSchedule } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const medicationSchedulesAdapter =
  createEntityAdapter<MedicationSchedule>();

export interface State extends EntityState<MedicationSchedule> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationSchedulesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
