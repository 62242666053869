import { FacilityUserGroupMember, Paginated, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserGroupMembersApiActions = createActionGroup({
  source: 'Facility User Group Members API',
  events: {
    'Load Facility User Group Members Success': props<{
      facilityUserGroupMembers: FacilityUserGroupMember[];
    }>(),
    'Get Facility User Group Members Success': props<{
      facilityUserGroupMembers: Paginated<FacilityUserGroupMember>;
    }>(),
    'Fetch Facility User Group Member Success': props<{
      facilityUserGroupMember: FacilityUserGroupMember;
    }>(),
    'Create Facility User Group Member Success': props<{
      facilityUserGroupMember:
        | FacilityUserGroupMember
        | FacilityUserGroupMember[];
    }>(),
    'Update Facility User Group Member Success': props<{
      facilityUserGroupMember: Update<FacilityUserGroupMember>;
    }>(),
    'Delete Facility User Group Member Success': props<{ id: string }>(),

    'Load Facility User Group Members Fail': props<{ error: Error }>(),
    'Get Facility User Group Members Fail': props<{ error: Error }>(),
    'Fetch Facility User Group Member Fail': props<{ error: Error }>(),
    'Create Facility User Group Member Fail': props<{ error: Error }>(),
    'Update Facility User Group Member Fail': props<{ error: Error }>(),
    'Delete Facility User Group Member Fail': props<{ error: Error }>()
  }
});

export const FacilityUserGroupMembersWsActions = createActionGroup({
  source: 'Facility User Group Members WS',
  events: {
    'Facility User Group Members Created': props<{
      facilityUserGroupMember: FacilityUserGroupMember;
    }>(),
    'Facility User Group Members Patched': props<{
      facilityUserGroupMember: FacilityUserGroupMember;
    }>(),
    'Facility User Group Members Removed': props<{ id: string }>()
  }
});
