import { ResidentContact, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentContactFormActions = createActionGroup({
  source: 'Resident Contact Form',
  events: {
    'Create Resident Contact': props<{ residentContact: ResidentContact }>(),
    'Update Resident Contact': props<Update<ResidentContact>>()
  }
});
