import { MedicalProfessional } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

export const createMedicalProfessional_MedicalProfessionalForm = createAction(
  '[MedicalProfessional Form] Add MedicalProfessional',
  props<{ medicalProfessional: MaybeArray<MedicalProfessional> }>()
);

export const createMedicalProfessional_ResidentMedicalProfessionalsRoute =
  createAction(
    '[Facesheet Route Effect] Add MedicalProfessionals',
    props<{
      medicalProfessional: MaybeArray<MedicalProfessional>;
    }>()
  );
