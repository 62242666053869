import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  medicationInventoriesAdapter,
  State
} from './medication-inventories.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;

// MedicationInventories State Selector
export const selectMedicationInventoriesState = createFeatureSelector<State>(
  'medicationInventories'
);

const entitySelectors = medicationInventoriesAdapter.getSelectors(
  selectMedicationInventoriesState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectMedicationInventoriesState,
  getLoading
);
export const selectLoaded = createSelector(
  selectMedicationInventoriesState,
  getLoaded
);
export const selectError = createSelector(
  selectMedicationInventoriesState,
  getError
);
