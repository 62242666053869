import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { MedicalHistoryFormPageActions } from '$/app/pages/residents/resident-detail/medical-history-form/medical-history-form.actions';
import {
  EffectHelpersService,
  MedicalHistoriesApiService
} from '$/app/services';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MedicalHistoriesApiActions } from './actions';

@Injectable()
export class MedicalHistoriesEffects {
  private readonly actions$ = inject(Actions);
  private readonly medicalHistoriesService = inject(MedicalHistoriesApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  createMedicalHistory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicalHistoryFormPageActions.createMedicalHistory),
        this.effectHelpers.apiRequest({
          description: 'Create Medical History',
          onRequest: (action) =>
            this.medicalHistoriesService.create(action.medicalHistory),
          useMapOperator: 'exhaustMap',
          onSuccess: (medicalHistory) =>
            MedicalHistoriesApiActions.createMedicalHistorySuccess({
              medicalHistory
            }),
          onError: (error) =>
            MedicalHistoriesApiActions.createMedicalHistoryFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  createMedicalHistoryFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicalHistoriesApiActions.createMedicalHistoryFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateMedicalHistory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicalHistoryFormPageActions.updateMedicalHistory),
        this.effectHelpers.apiRequest({
          description: 'Update Medical History',
          onRequest: (action) =>
            this.medicalHistoriesService.patch(action.id, action.changes),
          useMapOperator: 'exhaustMap',
          onSuccess: (medicalHistory) =>
            MedicalHistoriesApiActions.updateMedicalHistorySuccess({
              medicalHistory
            }),
          onError: (error) =>
            MedicalHistoriesApiActions.updateMedicalHistoryFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  updateMedicalHistorySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicalHistoriesApiActions.updateMedicalHistorySuccess)
      );
    },
    { dispatch: false }
  );

  updateMedicalHistoryFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicalHistoriesApiActions.updateMedicalHistoryFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteMedicalHistory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentFacesheetPageActions.deleteResidentMedicalHistory),
        this.effectHelpers.apiRequest({
          description: 'Delete Medical History',
          onRequest: (action) => this.medicalHistoriesService.delete(action.id),
          useMapOperator: 'exhaustMap',
          onSuccess: (medicalHistory) =>
            MedicalHistoriesApiActions.deleteMedicalHistorySuccess({
              id: medicalHistory.id
            }),
          onError: (error) =>
            MedicalHistoriesApiActions.deleteMedicalHistoryFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  deleteMedicalHistoryFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicalHistoriesApiActions.deleteMedicalHistoryFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
