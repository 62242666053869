import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SymptomsEffects } from './symptoms.effects';
import { symptomsFeature } from './symptoms.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(symptomsFeature),
    EffectsModule.forFeature([SymptomsEffects])
  ],
  providers: [SymptomsEffects]
})
export class SymptomsStoreModule {}
