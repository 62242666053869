<ion-header>
  <ion-toolbar color="{{ theme }}-primary">
    <ion-title>{{ pharmacy ? 'Edit Pharmacy' : 'New Pharmacy' }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="form-content {{ theme }}-theme">
  <form [formGroup]="form" class="grid grid-cols-2 gap-6 p-6" novalidate>
    <alc-form-divider class="col-span-2">Basic Info</alc-form-divider>

    <mat-form-field class="form-input-stretch col-span-2">
      <mat-label>Pharmacy Name</mat-label>
      <input matInput data-testid="name-input" formControlName="name" />
      <mat-error [alcError]="form.controls.name" />
    </mat-form-field>

    <mat-checkbox
      class="col-span-2 sm:col-span-1"
      data-testid="is-primary-checkbox"
      formControlName="isPrimary"
    >
      Is Primary?
    </mat-checkbox>

    <mat-form-field class="form-input-stretch col-span-2 mt-4">
      <mat-label>Email Address</mat-label>
      <input matInput formControlName="email" data-testid="email-input" />
      <mat-error [alcError]="form.controls.email" />
    </mat-form-field>

    @if (form.controls.phones) {
      <alc-form-divider class="col-span-2">
        Phone Information
      </alc-form-divider>

      @for (phone of formPhones.controls; track phone; let p = $index) {
        <div class="col-span-2 flex flex-col gap-6" formArrayName="phones">
          <div
            class="rounded-2xl border border-medium-light"
            [formGroupName]="p"
            @expansion
          >
            <div
              class="flex h-12 items-center justify-between border-b border-b-medium-light pl-4"
            >
              <p>Phone {{ p + 1 }}</p>

              @if (formPhones.controls.length > 1) {
                <ion-button
                  fill="clear"
                  color="medium-dark"
                  (click)="removePhone(p)"
                >
                  <ion-icon
                    slot="icon-only"
                    src="/assets/icon/material/delete.svg"
                  />
                </ion-button>
              }
            </div>
            <div class="grid grid-cols-2 gap-6 p-6">
              <mat-form-field
                class="form-input-stretch col-span-2 sm:col-span-1"
              >
                <mat-label>Label</mat-label>
                <input matInput formControlName="label" />
                <mat-error [alcError]="phoneFormGroup(p).controls.label" />
              </mat-form-field>
              <mat-form-field
                class="form-input-stretch col-span-2 sm:col-span-1"
              >
                <mat-label>Phone Number</mat-label>
                <input
                  matInput
                  type="tel"
                  formControlName="number"
                  mask="(000) 000-0000"
                />
                <mat-error [alcError]="phone.get('number')" />
              </mat-form-field>

              <mat-form-field
                class="form-input-stretch col-span-2 sm:col-span-1"
              >
                <mat-label>Type</mat-label>
                <mat-select formControlName="type">
                  @for (phoneType of lookups.phoneTypes; track phoneType.id) {
                    <mat-option [value]="phoneType.id">
                      {{ phoneType.name }}
                    </mat-option>
                  }
                </mat-select>
                <mat-error [alcError]="phone.get('type')" />
              </mat-form-field>
            </div>
          </div>

          <div class="flex justify-center">
            <ion-button
              fill="outline"
              shape="round"
              color="{{ theme }}-primary"
              (click)="addPhone($event)"
            >
              Add Phone
            </ion-button>
          </div>
        </div>
      }
    }

    <alc-form-divider class="col-span-2">Address Info</alc-form-divider>

    <mat-form-field class="form-input-stretch col-span-2">
      <mat-label>Address Line 1</mat-label>
      <input matInput formControlName="address1" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2">
      <mat-label>Address Line 2</mat-label>
      <input matInput formControlName="address2" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>City</mat-label>
      <input matInput formControlName="city" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>State</mat-label>
      <mat-select formControlName="state">
        <mat-option [value]="null">Blank</mat-option>

        @for (state of lookups.states; track state.id) {
          <mat-option [value]="state.id">
            {{ state.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Zipcode</mat-label>
      <input matInput formControlName="postalCode" />
      <mat-error [alcError]="form.controls.postalCode" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Country</mat-label>
      <mat-select formControlName="country">
        <mat-option [value]="null">Blank</mat-option>

        @for (country of lookups.countries; track country.id) {
          <mat-option [value]="country.id">
            {{ country.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <alc-form-divider class="col-span-2"> Ordering </alc-form-divider>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Preferred Ordering Method</mat-label>
      <mat-select formControlName="orderingMethod">
        @for (
          orderingMethod of lookups.pharmacyOrderingMethods;
          track orderingMethod.id
        ) {
          <mat-option [value]="orderingMethod.id">
            {{ orderingMethod.name }}
          </mat-option>
        }
      </mat-select>
      <mat-hint> How does your pharmacy want orders sent? </mat-hint>
    </mat-form-field>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button
        fill="solid"
        shape="round"
        color="{{ theme }}-primary"
        (click)="submit()"
      >
        {{ pharmacy ? 'Save' : 'Create' }}
      </ion-button>
      <ion-button
        fill="outline"
        shape="round"
        color="{{ theme }}-primary"
        (click)="close()"
      >
        Cancel
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
