import { Create, Delete, Load, Update } from '$/models';
import { IResidentReportConfig } from '$shared/services/resident-report-configs';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentReportsPageActions = createActionGroup({
  source: 'Resident Reports Page',
  events: {
    'Load Resident Report Configs': props<Load>(),
    'Create Resident Report Config': props<Create<IResidentReportConfig>>(),
    'Update Report Config': props<Update<IResidentReportConfig>>(),
    'Delete Report Config': props<Delete>()
  }
});
