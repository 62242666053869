import { FacilityUserGroup, Params, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥👈 USER ACTIONS
//

// Fetch FacilityUserGroup
export const fetchFacilityUserGroup = createAction(
  '[FacilityUser Group Detail Page] Fetch FacilityUserGroup',
  (id: string, params: Params = { query: {} }) => ({ id, params })
);

// Update FacilityUserGroup
export const updateFacilityUserGroup = createAction(
  '[FacilityUser Group Detail Page] Update FacilityUser Group',
  props<Update<FacilityUserGroup>>()
);

// Delete FacilityUserGroup
export const deleteFacilityUserGroup = createAction(
  '[FacilityUser Group Detail Page] Delete Facility User Group',
  props<{ id: string }>()
);
