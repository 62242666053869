import { MedicalHistory } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearMedicalHistories = createAction(
  '[MedicalHistories] Clear MedicalHistories'
);

export const addMedicalHistories = createAction(
  'Add Medical Histories',
  props<{ medicalHistories: MedicalHistory[] }>()
);
