import { isNil } from 'lodash';

/**
 * Takes a stringed file size and converts it to bytes.
 * If no unit is specified, the number is assumed to be in bytes (integer).
 */
export function sizeToBytes(size: string | number): number {
  if (isNil(size)) {
    return size;
  }

  if (typeof size === 'number') {
    if (!Number.isInteger(size)) {
      throw new Error('A size that is of type number must be an integer.');
    }

    return size;
  }

  const stringedNumber = size.replace(/[a-zA-Z\s]+/g, '');
  const sizeNumber = Number(stringedNumber);
  const sizeUnit = size.replace(/[\d\s.]+/g, '').toLowerCase();

  switch (sizeUnit) {
    case 'k':
    case 'kb':
      return Math.ceil(sizeNumber * 1024);
    case 'm':
    case 'mb':
      return Math.ceil(sizeNumber * 1024 * 1024);
    case 'g':
    case 'gb':
      return Math.ceil(sizeNumber * 1024 * 1024 * 1024);
    case 't':
    case 'tb':
      return Math.ceil(sizeNumber * 1024 * 1024 * 1024 * 1024);
    default:
      if (RegExp(/\./g).test(stringedNumber)) {
        throw new Error(
          'A size without a supported unit (KB, MB, GB, TB) is not allowed to have a decimal point.'
        );
      }

      return sizeNumber;
  }
}

export function bytesToSize(bytes: number, precision = 2) {
  if (isNil(bytes)) {
    return bytes;
  }

  const units = ['B', 'Kb', 'Mb', 'Gb', 'Tb'];

  const index = Math.floor(Math.log(bytes) / Math.log(1024));
  const size =
    Math.round((bytes / Math.pow(1024, index)) * Math.pow(10, precision)) /
    Math.pow(10, precision);
  const unit = units[index];

  return `${size} ${unit}`;
}

export function getFileSize(size: string | number, precision: number) {
  if (isNil(size)) {
    return size;
  }

  const units = {
    b: 'B',
    k: 'Kb',
    kb: 'Kb',
    m: 'Mb',
    mb: 'Mb',
    g: 'Gb',
    gb: 'Gb',
    t: 'Tb',
    tb: 'Tb'
  };

  if (typeof size === 'string') {
    size = size.trim().toLowerCase();
    const num =
      Math.round(
        Number(size.match(/\d+\.?\d*/g)[0]) * Math.pow(10, precision)
      ) / Math.pow(10, precision);
    const unit = size.match(/[bBkKmMgGtT]+$/g)[0];
    if (unit) {
      return `${num} ${units[unit]}`;
    }

    size = Number(num);
  }

  return bytesToSize(size, precision);
}
