import { createSelector } from '@ngrx/store';
import { keyBy } from 'lodash';

import { libraryDocumentTemplatePacketTemplatesFeature } from './library-document-template-packet-templates.reducer';
import { libraryDocumentTemplatePacketTemplatesAdapter } from './library-document-template-packet-templates.state';

export const {
  selectLibraryDocumentTemplatePacketTemplatesState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = libraryDocumentTemplatePacketTemplatesFeature;

export const { selectAll, selectTotal } =
  libraryDocumentTemplatePacketTemplatesAdapter.getSelectors(
    selectLibraryDocumentTemplatePacketTemplatesState
  );

export const selectByPacketIdAndTemplateId = createSelector(
  selectAll,
  (packetTemplates) => {
    return keyBy(
      packetTemplates,
      (packetTemplate) =>
        `${packetTemplate.libraryDocumentTemplatePacketId}-${packetTemplate.libraryDocumentTemplateId}`
    );
  }
);

export const selectPacketCountByTemplateId = createSelector(
  selectAll,
  (packetTemplates) => {
    return packetTemplates.reduce(
      (acc: Record<string, number>, packetTemplate) => {
        const templateId = packetTemplate.libraryDocumentTemplateId;
        acc[templateId] = acc[templateId] ? acc[templateId] + 1 : 1;

        return acc;
      },
      {}
    );
  }
);
