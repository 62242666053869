import { FeathersService } from '$/app/services/feathers.service';
import { BehaviorLogsWsActions } from '$/app/store/behavior-logs/behavior-logs.actions';
import { IBehaviorLog } from '$shared/services/behavior-log';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class BehaviorLogsApiService extends AbstractApiService<IBehaviorLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('behavior-logs', feathers, store, {
      entityName: 'behaviorLog',
      created: BehaviorLogsWsActions.behaviorLogsCreated,
      patched: BehaviorLogsWsActions.behaviorLogsPatched,
      removed: BehaviorLogsWsActions.behaviorLogsRemoved
    });
  }
}
