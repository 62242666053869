import { MedicationOrderItemComponentActions } from '$/app/pages/medications/dashboard/medication-orders/medication-to-order-item/medication-to-order-item.actions';
import { MedicationsToOrderListPageActions } from '$/app/pages/medications/dashboard/medication-orders/medications-to-order-list/medications-to-order-list.actions';
import { chainFlow } from '$shared/utils';
import { Action, createReducer, on } from '@ngrx/store';
import { filter, includes, map, uniqBy } from 'lodash';
import { State, initialState } from './medication-orders-queue.state';

const reducer = createReducer(
  initialState,

  on(
    MedicationsToOrderListPageActions.addAllMedicationsToOrderQueue,
    MedicationOrderItemComponentActions.addMedicationToOrderQueue,
    (state, action) => ({
      ...state,
      ordersQueue: chainFlow(
        [...state.ordersQueue, ...action.medications],
        (meds) => uniqBy(meds, 'id')
      )
    })
  ),

  on(
    MedicationsToOrderListPageActions.removeAllMedicationsFromOrderQueue,
    MedicationOrderItemComponentActions.removeMedicationFromOrderQueue,
    (state, action) => ({
      ...state,
      // Remove the action's medications from the current order queue
      ordersQueue: chainFlow(map(action.medications, 'id'), (ids) =>
        filter(state.ordersQueue, (med) => !includes(ids, med.id))
      )
    })
  ),

  on(MedicationsToOrderListPageActions.clearOrderQueue, (state) => {
    return {
      ...state,
      ordersQueue: []
    };
  })
);

export function medicationOrdersQueueReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
