import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { IResident } from '$shared/residents/residents.interface';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import {
  MedicalProfessional,
  Medication,
  MedicationSchedule,
  OmittedMedication,
  Pharmacy
} from '../entities';

export class MedicationGraph extends Medication {
  schedules?: MedicationSchedule[];
  inventoryItems?: IMedicationInventoryItem[];
  resident?: IResident;
  facilityUser?: IFacilityUser;
  medicalProfessional?: MedicalProfessional;
  pharmacy?: Pharmacy;
  omittedMedications?: OmittedMedication[];
}
