import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MedicalProfessionalsManagerActions = createActionGroup({
  source: 'Medical Professionals Manager',
  events: {
    'Load Medical Professionals': (params: Params = { query: {} }) => ({
      params
    }),

    'Add Resident Medical Professional': props<{
      medicalProfessionalId: string;
      residentId: string;
    }>(),

    'Remove Resident Medical Professional': props<{
      id: string;
      params?: Params;
    }>()
  }
});
