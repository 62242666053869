import {
  ILibraryDocumentTemplatePacketTemplate,
  IPaginationInfo
} from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const libraryDocumentTemplatePacketTemplatesAdapter =
  createEntityAdapter<ILibraryDocumentTemplatePacketTemplate>();

export interface State
  extends EntityState<ILibraryDocumentTemplatePacketTemplate> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  libraryDocumentTemplatePacketTemplatesAdapter.getInitialState({
    pagination: {},
    loading: false,
    loaded: false,
    error: null
  });
