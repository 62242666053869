import { FacilityUserGroupMember } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FacilityUserGroupMembersGeneralActions = createActionGroup({
  source: 'Facility User Group Members General',
  events: {
    'Clear Facility User Group Members': emptyProps(),
    'Add Facility User Group Members': props<{
      facilityUserGroupMembers: FacilityUserGroupMember[];
    }>()
  }
});
