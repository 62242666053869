import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FacilitySettingsEffects } from './facility-settings.effects';
import { facilitySettingsReducer } from './facility-settings.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('facilitySettings', facilitySettingsReducer),
    EffectsModule.forFeature([FacilitySettingsEffects])
  ],
  providers: [FacilitySettingsEffects]
})
export class FacilitySettingsStoreModule {}
