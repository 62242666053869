import { getFileSize } from '$/app/utils';
import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'fileSize',
  standalone: true
})
export class AlcFileSizePipe implements PipeTransform {
  transform(value: string | number, precision: number = 1): unknown {
    return isNil(value) ? value : getFileSize(value, precision)?.toUpperCase();
  }
}
