import { IHydratedNotification } from '$shared/notifications';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NotificationsGeneralActions = createActionGroup({
  source: 'Notification General',
  events: {
    'Add Notifications': props<{ notifications: IHydratedNotification[] }>(),
    'Clear Notifications': emptyProps
  }
});
