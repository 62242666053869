import { createFeature, createReducer, on } from '@ngrx/store';
import {
  LibraryCollectionsApiActions,
  LibraryCollectionsGeneralActions,
  LibraryCollectionsWsActions
} from './library-collections.actions';
import {
  initialState,
  libraryCollectionsAdapter
} from './library-collections.state';

export const libraryCollectionsFeature = createFeature({
  name: 'libraryCollections',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      LibraryCollectionsApiActions.loadLibraryCollectionsSuccess,
      (state, action) => {
        const {
          libraryCollections: { data: records, ...pagination }
        } = action;

        return libraryCollectionsAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryCollectionsApiActions.getLibraryCollectionsSuccess,
      (state, action) => {
        const {
          libraryCollections: { data: records, ...pagination }
        } = action;

        return libraryCollectionsAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryCollectionsApiActions.fetchLibraryCollectionSuccess,
      (state, action) => {
        return libraryCollectionsAdapter.upsertOne(action.libraryCollection, {
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    ),

    on(
      LibraryCollectionsApiActions.createLibraryCollectionSuccess,
      (state, action) => {
        return libraryCollectionsAdapter.addOne(action.libraryCollection, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(
      LibraryCollectionsApiActions.updateLibraryCollectionSuccess,
      (state, action) => {
        return libraryCollectionsAdapter.upsertOne(action.libraryCollection, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(
      LibraryCollectionsApiActions.deleteLibraryCollectionSuccess,
      (state, action) =>
        libraryCollectionsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      LibraryCollectionsApiActions.loadLibraryCollectionsFail,
      LibraryCollectionsApiActions.getLibraryCollectionsFail,
      LibraryCollectionsApiActions.createLibraryCollectionFail,
      LibraryCollectionsApiActions.updateLibraryCollectionFail,
      LibraryCollectionsApiActions.deleteLibraryCollectionFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      LibraryCollectionsWsActions.libraryCollectionCreated,
      LibraryCollectionsWsActions.libraryCollectionPatched,
      (state, action) => {
        return libraryCollectionsAdapter.upsertOne(
          action.libraryCollection,
          state
        );
      }
    ),

    on(
      LibraryCollectionsWsActions.libraryCollectionRemoved,
      (state, action) => {
        return libraryCollectionsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      LibraryCollectionsGeneralActions.addLibraryCollections,
      (state, action) => {
        return libraryCollectionsAdapter.upsertMany(action.libraryCollections, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(LibraryCollectionsGeneralActions.clearLibraryCollections, () => {
      return libraryCollectionsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
