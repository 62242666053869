import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { TMedicationInventoryItemCount } from '$shared/medications/medication-inventory-item-count.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MedicationInventoryItemCountsWsActions } from './medication-inventory-item-counts.actions';

@Injectable({ providedIn: 'root' })
export class MedicationInventoryItemCountsApiService extends AbstractApiService<TMedicationInventoryItemCount> {
  constructor(feathers: FeathersService, store: Store) {
    super('medication-inventory-item-counts', feathers, store, {
      entityName: 'medicationInventoryItemCount',
      created:
        MedicationInventoryItemCountsWsActions.medicationInventoryItemCountCreated,
      patched:
        MedicationInventoryItemCountsWsActions.medicationInventoryItemCountPatched,
      removed:
        MedicationInventoryItemCountsWsActions.medicationInventoryItemCountRemoved
    });
  }
}
