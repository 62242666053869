import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MedicalHistoriesEffects } from '$/app/store/medical-histories/medical-histories.effects';
import { medicalHistoriesReducer } from '$/app/store/medical-histories/medical-histories.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('medicalHistories', medicalHistoriesReducer),
    EffectsModule.forFeature([MedicalHistoriesEffects])
  ],
  providers: [MedicalHistoriesEffects]
})
export class MedicalHistoriesStoreModule {}
