import { FacilitiesSelectors } from '$/app/store/facilities';
import { FeatureFlag } from '$shared/constants/feature-flags';
import {
  Directive,
  inject,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Store } from '@ngrx/store';

@Directive({ selector: '[alcFeatureFlag]', standalone: true })
export class AlcFeatureFlagDirective implements OnChanges {
  private readonly view = inject(ViewContainerRef);
  private readonly template = inject(TemplateRef);
  private readonly store = inject(Store);

  @Input('alcFeatureFlag') featureFlag: FeatureFlag | undefined;
  @Input('alcFeatureFlagElse') elseTemplate: TemplateRef<any>;

  private readonly featureFlags = this.store.selectSignal(
    FacilitiesSelectors.selectFeatureFlags
  );

  ngOnChanges() {
    this.updateView();
  }

  private updateView() {
    this.view.clear();
    const featureFlags = this.featureFlags();
    const enabled = !this.featureFlag || featureFlags[this.featureFlag];
    const template = enabled ? this.template : this.elseTemplate;
    if (template) {
      this.view.createEmbeddedView(template);
    }
  }
}
