import * as RouterSelectors from '$/app/store/router/router.selectors';
import { UserSelectors } from '$/app/store/user/user.selectors';
import { ShiftNote } from '$/models';
import { Dictionary } from '$shared/types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';

import { shiftNotesAdapter, State } from './shift-notes.state';

// Selector Helpers
export const getError = (state: State) => state.error;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;

// Rooms State Selector
export const selectShiftNotesState = createFeatureSelector<State>('shiftNotes');

const entitySelectors = shiftNotesAdapter.getSelectors(selectShiftNotesState);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(selectShiftNotesState, getLoading);
export const selectLoaded = createSelector(selectShiftNotesState, getLoaded);
export const selectError = createSelector(selectShiftNotesState, getError);

export const selectPagination = (query?: string) => {
  return createSelector(selectShiftNotesState, (state) => {
    return query ? state.pagination[query] : state.pagination;
  });
};

export const selectResidentShiftNotes = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (shiftNotes, residentId): ShiftNote[] => {
    if (isEmpty(shiftNotes) || !residentId) {
      return [];
    }

    return shiftNotes.filter((shiftNote) => {
      return shiftNote.residentId === residentId;
    });
  }
);

export const selectGeneralShiftNotes = createSelector(
  selectAll,
  UserSelectors.selectFacilityId,
  (shiftNotes, facilityId): ShiftNote[] => {
    if (isEmpty(shiftNotes) || !facilityId) {
      return [];
    }

    return shiftNotes.filter((shiftNote) => {
      return (
        shiftNote.residentId === null && shiftNote.facilityId === facilityId
      );
    });
  }
);

export const selectShiftNotesByCareTask = createSelector(
  selectAll,
  (notes): Dictionary<ShiftNote> => {
    const logEntities = notes.reduce(
      (dictionary: Dictionary<ShiftNote>, note) => {
        if (note.careTaskId) {
          dictionary[note.careTaskId] = dictionary[note.careTaskId] || note;
        }

        return dictionary;
      },
      {}
    );

    return logEntities;
  }
);
