import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomFieldErrorMatcher implements ErrorStateMatcher {
  constructor(
    private customControl: NgControl,
    private errors?: any
  ) {}

  isErrorState(
    _control: FormControl | null,
    _form: FormGroupDirective | NgForm | null
  ): boolean {
    return (
      this.customControl &&
      this.customControl.touched &&
      (this.customControl.invalid || this.errors)
    );
  }
}

// This is an extension of Angular Material's ShowOnDirtyErrorStateMatcher
// with the addition of checking for touched state.
@Injectable()
export class ShowOnDirtyTouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: AbstractControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || (form && form.submitted))
    );
  }
}
