import { OverlayService } from '$/app/services';
import { SharedModule } from '$/app/shared/shared.module';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { UnreadAnnouncementPageActions } from './unread-announcement.actions';
import { UnreadAnnouncementSelectors } from './unread-announcement.selectors';

@Component({
  selector: 'alc-unread-announcements',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './unread-announcements.component.html',
  styleUrls: ['./unread-announcements.component.scss']
})
export class AlcUnreadAnnouncementsComponent {
  private readonly store = inject(Store);
  private readonly overlay = inject(OverlayService);

  announcements$ = this.store.select(
    UnreadAnnouncementSelectors.selectAnnouncementsCards
  );

  close() {
    this.overlay.dismissModal();
  }

  confirm(announcementId: string) {
    this.store.dispatch(
      UnreadAnnouncementPageActions.acknowledgeAnnouncement(announcementId)
    );
  }
}
