import { FacilityRoleDetailPageActions } from '$/app/pages/facilities/facility-detail/facility-role-detail/facility-role-detail.actions';
import { FacilityRolesPageActions } from '$/app/pages/facilities/facility-detail/facility-roles/facility-roles.actions';
import { NotificationSettingDetailPageActions } from '$/app/pages/facilities/facility-detail/facility-settings/notification-setting-detail/notification-setting-detail.actions';
import { NotificationSettingsPageActions } from '$/app/pages/facilities/facility-detail/facility-settings/notification-settings/notification-settings.actions';
import { RolePickerFormPageActions } from '$/app/pages/staff/role-picker-form/role-picker-form.actions';
import { EffectHelpersService, RolesApiService } from '$/app/services';
import { ApiData } from '$/app/utils';
import { Role } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { RolesApiActions } from './actions';

@Injectable()
export class RolesEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly rolesService = inject(RolesApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FacilityRolesPageActions.loadRoles,
        RolePickerFormPageActions.loadRoles,
        NotificationSettingsPageActions.loadRoles,
        NotificationSettingDetailPageActions.loadRoles
      ),
      this.effectHelpers.apiRequest({
        description: 'Load Roles',
        onRequest: (action) => this.rolesService.getAll(action.params),
        onSuccess: (response) =>
          new ApiData(
            'roles',
            response,
            RolesApiActions.loadRolesSuccess
          ).getActions(),
        onError: (error) => RolesApiActions.loadRolesFail({ error })
      })
    )
  );

  getRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityRolesPageActions.getRoles),
      switchMap((action) =>
        this.rolesService.getAll(action.params).pipe(
          map((roles: Role[]) => RolesApiActions.getRolesSuccess({ roles })),
          catchError((error) => of(RolesApiActions.getRolesFail({ error })))
        )
      )
    )
  );

  fetchRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityRoleDetailPageActions.fetchRole),
      this.effectHelpers.apiRequest({
        description: 'Fetch Role',
        onRequest: (action) => this.rolesService.get(action.id, action.params),
        onSuccess: (response) =>
          new ApiData('roles', response, RolesApiActions.fetchRoleSuccess, {
            payloadKey: 'role'
          }).getActions(),
        onError: (error) => RolesApiActions.fetchRoleFail({ error })
      })
    )
  );

  createRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityRolesPageActions.createRole),
      this.effectHelpers.apiRequest({
        description: 'Create Role',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.rolesService.create(action.role, action.params),
        onSuccess: (role) => RolesApiActions.createRoleSuccess({ role }),
        onError: (error) => RolesApiActions.createRoleFail({ error })
      })
    )
  );

  updateRole$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FacilityRoleDetailPageActions.updateRole),
        switchMap((action) =>
          this.rolesService.patch(action.id, action.changes).pipe(
            catchError((error) => {
              this.store.dispatch(RolesApiActions.updateRoleFail({ error }));

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  updateRoleFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RolesApiActions.updateRoleFail),
        tap(this.effectHelpers.onFormSubmitFail())
      ),
    { dispatch: false }
  );

  deleteRole$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FacilityRolesPageActions.deleteRole),
        exhaustMap((action) =>
          this.rolesService.delete(action.id).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Role deleted successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(RolesApiActions.deleteRoleFail({ error }));

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  deleteRoleFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RolesApiActions.deleteRoleFail),
        tap(this.effectHelpers.onFormSubmitFail())
      ),
    { dispatch: false }
  );
}
