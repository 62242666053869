import { ShiftNote } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Shift Notes API]';
const sourceWS = '[Shift Notes WS]';

// Load
export const loadShiftNotesSuccess = createAction(
  `${source} Load Shift Notes Success`,
  props<{ shiftNotes: ShiftNote[] }>()
);
export const loadShiftNotesFail = createAction(
  `${source} Load Shift Notes Fail`,
  props<{ error: Error }>()
);
// Get
export const getShiftNotesSuccess = createAction(
  `${source} Get Shift Notes Success`,
  props<{ shiftNotes: ShiftNote[] }>()
);
export const getShiftNotesFail = createAction(
  `${source} Get Shift Notes Fail`,
  props<{ error: Error }>()
);
// Create
export const createShiftNoteSuccess = createAction(
  `${source} Create Shift Notes Success`,
  props<{ shiftNote: ShiftNote }>()
);
export const createShiftNoteFail = createAction(
  `${source} Create Shift Notes Fail`,
  props<{ error: Error }>()
);

export const updateShiftNoteSuccess = createAction(
  `${source} Update Shift Note Success`,
  props<{ shiftNote: ShiftNote }>()
);
export const updateShiftNoteFail = createAction(
  `${source} Update Shift Note Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteShiftNoteSuccess = createAction(
  `${source} Delete Shift Note Success`,
  props<{ id: string }>()
);
export const deleteShiftNoteFail = createAction(
  `${source} Delete Shift Note Fail`,
  props<{ error: Error }>()
);

// [WS] Shift Notes Created
export const shiftNotesCreated = createAction(
  `${sourceWS} Shift Notes Created`,
  props<{ shiftNote: ShiftNote }>()
);
// [WS] Shift Notes Patched
export const shiftNotesPatched = createAction(
  `${sourceWS} Shift Notes Patched`,
  props<{ shiftNote: ShiftNote }>()
);
// [WS] Shift Notes Removed
export const shiftNotesRemoved = createAction(
  `${sourceWS} Shift Notes Removed`,
  props<{ id: string }>()
);
