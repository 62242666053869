import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DocumentParticipantsEffects } from './document-participants.effects';
import { documentParticipantsFeature } from './document-participants.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(documentParticipantsFeature),
    EffectsModule.forFeature([DocumentParticipantsEffects])
  ],
  providers: [DocumentParticipantsEffects]
})
export class DocumentParticipantsStoreModule {}
