<form [formGroup]="form">
  <div class="mb-6">
    <mat-radio-group
      aria-labelledby="type-radio-group"
      class="flex flex-col"
      formControlName="type"
      (change)="onTypeChange($event)"
    >
      <mat-radio-button class="m-0" value="preset">
        Preset Range
      </mat-radio-button>

      <mat-radio-button class="m-0" value="custom">
        Custom Range
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-form-field
    class="form-input-stretch"
    *ngIf="form.get('type').value === 'preset'"
  >
    <mat-label>Year</mat-label>

    <mat-select formControlName="year" (selectionChange)="onYearChange($event)">
      <mat-option *ngFor="let year of years" [value]="year">
        {{ year }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="form-input-stretch"
    *ngIf="form.get('type').value === 'preset'"
  >
    <mat-label>Preset</mat-label>
    <mat-select
      formControlName="preset"
      panelClass="date-range-presets"
      (selectionChange)="onPresetChange($event)"
    >
      <mat-option *ngFor="let preset of presets" [value]="preset.id">
        {{ preset.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <alc-date-input
    label="Start Date"
    *ngIf="form.get('type').value === 'custom'"
    formControlName="startDate"
  />

  <alc-date-input
    label="End Date"
    *ngIf="form.get('type').value === 'custom'"
    formControlName="endDate"
  />
</form>
