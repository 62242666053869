import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  OmittedAmountsApiActions,
  OmittedAmountsGeneralActions,
  OmittedAmountsWsActions
} from './omitted-amounts.actions';
import { initialState, omittedAmountsAdapter } from './omitted-amounts.state';

export const omittedAmountsFeature = createFeature({
  name: 'omittedAmounts',
  reducer: createReducer(
    initialState,

    on(OmittedAmountsApiActions.loadOmittedAmountsSuccess, (state, action) => {
      return omittedAmountsAdapter.setAll(action.omittedAmounts, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OmittedAmountsApiActions.getOmittedAmountsSuccess, (state, action) => {
      return omittedAmountsAdapter.upsertMany(action.omittedAmounts, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OmittedAmountsApiActions.fetchOmittedAmountSuccess, (state, action) => {
      return omittedAmountsAdapter.upsertOne(action.omittedAmount, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OmittedAmountsApiActions.createOmittedAmountSuccess, (state, action) => {
      const omittedAmounts = castArray(action.omittedAmount);

      return omittedAmountsAdapter.addMany(omittedAmounts, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OmittedAmountsApiActions.updateOmittedAmountSuccess, (state, action) => {
      return omittedAmountsAdapter.upsertOne(action.omittedAmount, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OmittedAmountsApiActions.deleteOmittedAmountSuccess, (state, action) => {
      return omittedAmountsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      OmittedAmountsApiActions.loadOmittedAmountsFail,
      OmittedAmountsApiActions.getOmittedAmountsFail,
      OmittedAmountsApiActions.fetchOmittedAmountFail,
      OmittedAmountsApiActions.createOmittedAmountFail,
      OmittedAmountsApiActions.updateOmittedAmountFail,
      OmittedAmountsApiActions.deleteOmittedAmountFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      OmittedAmountsWsActions.omittedAmountsCreated,
      OmittedAmountsWsActions.omittedAmountsPatched,
      (state, action) => {
        return omittedAmountsAdapter.upsertOne(action.omittedAmount, {
          ...state
        });
      }
    ),

    on(OmittedAmountsWsActions.omittedAmountsRemoved, (state, action) => {
      return omittedAmountsAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(OmittedAmountsGeneralActions.addOmittedAmounts, (state, action) => {
      return omittedAmountsAdapter.upsertMany(action.omittedAmounts, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(OmittedAmountsGeneralActions.clearOmittedAmounts, (state) => {
      return omittedAmountsAdapter.removeAll({
        ...state,
        total: 0,
        skip: 0,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
