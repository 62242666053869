import { IDocument, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentDocumentPacketsPageActions = createActionGroup({
  source: 'Resident Document Packets Page',
  events: {
    'Get Document Packets': (params: Params) => ({ params }),
    'Get Documents': (params: Params) => ({ params }),
    'Delete Document Packet': (id: string, params: Params) => ({ id, params }),
    'Create Document From Template': props<{
      document: IDocument;
      params: Params;
    }>()
  }
});
