import { Update } from '$/models';
import { IHydratedNotification } from '$shared/notifications';
import { RefresherEventDetail } from '@ionic/angular/standalone';
import { createActionGroup } from '@ngrx/store';

const query = {
  status: { $in: ['unread', 'read'] }
};

export const NotificationMenuPageActions = createActionGroup({
  source: 'Notification Menu Page',
  events: {
    'Load Notifications': () => ({
      params: { query }
    }),
    'Refresh Notifications': () => ({ params: { query } }),
    'Pull Refresh Notifications': (refresher: RefresherEventDetail) => ({
      params: { query, refresher }
    }),
    'Archive Notification': (
      notificationId: string
    ): Update<IHydratedNotification> => ({
      id: notificationId,
      changes: { status: 'archived' }
    }),
    'Archive All Notifications': (): Update<IHydratedNotification> => ({
      id: null,
      changes: { status: 'archived' },
      params: { query: {} }
    }),
    'Read Notification': (
      notificationId: string
    ): Update<IHydratedNotification> => ({
      id: notificationId,
      changes: { status: 'read' }
    }),
    'Read Notifications': (): Update<IHydratedNotification> => ({
      id: null,
      changes: { status: 'read' },
      params: {
        query: {
          status: 'unread'
        }
      }
    })
  }
});
