import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MoodLogsPageActions = createActionGroup({
  source: 'Mood Log Page',
  events: {
    'Load Mood Logs': (params: Params) => ({ params }),
    'Get Mood Logs': (params: Params) => ({ params }),
    'Delete Mood Log': props<{ id: string; params?: Params }>()
  }
});
