import { RolePermission } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

const source = '[Role Permissions API]';
const sourceWS = '[Role Permissions API WS]';

export const createRolePermissionSuccess = createAction(
  `${source} Create Role Permission Success`,
  props<{ rolePermission: MaybeArray<RolePermission> }>()
);

export const createRolePermissionFail = createAction(
  `${source} Create Role Permission Fail`,
  props<{ error: Error }>()
);

export const deleteRolePermissionSuccess = createAction(
  `${source} Delete RolePermission Success`,
  props<{ id: string }>()
);

export const deleteRolePermissionFail = createAction(
  `${source} Delete RolePermission Fail`,
  props<{ error: Error }>()
);

export const rolePermissionsCreated = createAction(
  `${sourceWS} RolePermissions Created`,
  props<{ rolePermission: RolePermission }>()
);

export const rolePermissionsPatched = createAction(
  `${sourceWS} RolePermissions Patched`,
  props<{ rolePermission: RolePermission }>()
);

export const rolePermissionsRemoved = createAction(
  `${sourceWS} RolePermissions Removed`,
  props<{ id: string }>()
);
