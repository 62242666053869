import { Paginated, Prn, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadPrnsSuccess = createAction(
  '[Prns API] Load Prns Success',
  props<{ prns: Paginated<Prn> }>()
);
export const loadPrnsFail = createAction(
  '[Prns API] Load Prns Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getPrnsSuccess = createAction(
  '[Prns API] Get Prns Success',
  props<{ prns: Paginated<Prn> }>()
);
export const getPrnsFail = createAction(
  '[Prns API] Get Prns Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchPrnSuccess = createAction(
  '[Prn API] Fetch Prn Success',
  props<{ prn: Prn }>()
);
export const fetchPrnFail = createAction(
  '[Prn API] Fetch Prn Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createPrnSuccess = createAction(
  '[Prns API] Add Prn Success',
  props<{ prn: Prn }>()
);
export const createPrnFail = createAction(
  '[Prns API] Add Prn Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updatePrnSuccess = createAction(
  '[Prns API] Update Prn Success',
  props<{ prn: Update<Prn> }>()
);
export const updatePrnFail = createAction(
  '[Prns API] Update Prn Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deletePrnSuccess = createAction(
  '[Prns API] Delete Prn Success',
  props<{ id: string }>()
);
export const deletePrnFail = createAction(
  '[Prns API] Delete Prn Fail',
  props<{ error: Error }>()
);
