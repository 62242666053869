import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const RoutineMedicationsPageActions = createActionGroup({
  source: 'Routine Medications Page',
  events: {
    'Get Medication Tasks': (params: Params = { query: {} }) => ({
      params
    })
  }
});
