import { AllergyFormPageActions } from '$/app/pages/residents/resident-detail/allergy-form/allergy-form.actions';
import {
  allergiesAdapter,
  initialState
} from '$/app/store/allergies/allergies.state';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  AllergiesApiActions,
  AllergiesGeneralActions,
  AllergiesWsActions
} from './allergies.actions';

export const allergiesFeature = createFeature({
  name: 'allergies',
  reducer: createReducer(
    initialState,

    on(
      AllergyFormPageActions.createAllergy,
      AllergyFormPageActions.updateAllergy,
      (state) => {
        return {
          ...state,
          loading: true
        };
      }
    ),

    on(AllergiesApiActions.createAllergySuccess, (state, action) => {
      const allergies = castArray(action.allergy);

      return allergiesAdapter.addMany(allergies, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(AllergiesApiActions.updateAllergySuccess, (state, action) => {
      return allergiesAdapter.updateOne(action.allergy, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(AllergiesApiActions.deleteAllergySuccess, (state, action) => {
      return allergiesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      AllergiesApiActions.createAllergyFail,
      AllergiesApiActions.updateAllergyFail,
      AllergiesApiActions.deleteAllergyFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      AllergiesWsActions.allergiesCreated,
      AllergiesWsActions.allergiesPatched,
      (state, action) => {
        return allergiesAdapter.upsertOne(action.allergy, state);
      }
    ),
    on(AllergiesWsActions.allergiesRemoved, (state, action) => {
      return allergiesAdapter.removeOne(action.id, state);
    }),

    on(AllergiesGeneralActions.clearAllergies, (state) => {
      return allergiesAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }),

    on(AllergiesGeneralActions.addAllergies, (state, action) => {
      return allergiesAdapter.upsertMany(action.allergies, {
        ...state,
        loading: false,
        error: null
      });
    })
  )
});
