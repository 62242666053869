import { AlcAction, Prn, Update } from '$/models';
import { Params } from '@angular/router';
import { createActionGroup, props } from '@ngrx/store';
import { GivePrnFormData } from '../give-prn-form/give-prn-form.vmodel';

export const DashboardPrnMedicationsPageActions = createActionGroup({
  source: 'Dashboard PRN Medications Page',
  events: {
    'Get Dashboard Prn Medications Page Info': (params: Params) => ({ params }),
    'Create Prn': props<{ prn: GivePrnFormData; params?: Params }>(),
    'Update Prn': props<Update<Prn>>(),
    'Delete Prn': AlcAction<{ id: string }>(),
    'Clear Prn Response': props<Update<Prn>>()
  }
});
