import { IPaginationInfo } from '$/models';

// given a pagination object with the fields total, skip, limit, and data
// determine the next skip and limit values for the next page.
// if there is no next page, return null;
export function getNextPage(pagination: IPaginationInfo): {
  $skip: number;
  $limit: number;
} {
  const { total, skip, limit } = pagination;

  // NOTE: When limit is set to 0, feathers will perform a count query and return that as the total
  // but it will not return the records themselves. Therefore, we will not request any more
  // pages if limit is set to 0.
  if (limit === 0) {
    return null;
  }

  const remaining = total - skip - limit;

  const $skip = skip + limit;
  const $limit = limit;

  if (remaining <= 0) {
    return null;
  }

  return { $skip, $limit };
}
