import { IBodyCheckObservation } from '$shared/services/body-check-observation';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const bodyCheckObservationsAdapter =
  createEntityAdapter<IBodyCheckObservation>();

export interface State extends EntityState<IBodyCheckObservation> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = bodyCheckObservationsAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null
  }
);
