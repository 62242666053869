import { AnnouncementDetailPageActions } from '$/app/pages/communication/announcement-detail/announcement-detail.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AnnouncementRepliesApiActions,
  AnnouncementRepliesGeneralActions,
  AnnouncementRepliesWsActions
} from './announcement-replies.actions';
import {
  announcementRepliesAdapter,
  initialState
} from './announcement-replies.state';

export const announcementRepliesFeature = createFeature({
  name: 'announcementReplies',
  reducer: createReducer(
    initialState,

    on(AnnouncementDetailPageActions.createReply, (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      AnnouncementRepliesApiActions.loadAnnouncementRepliesSuccess,
      (state, action) => {
        return announcementRepliesAdapter.setAll(action.announcementReplies, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      AnnouncementRepliesApiActions.getAnnouncementRepliesSuccess,
      (state, action) => {
        return announcementRepliesAdapter.upsertMany(
          action.announcementReplies,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      AnnouncementRepliesApiActions.updateAnnouncementReplySuccess,
      (state, action) => {
        return announcementRepliesAdapter.updateOne(action.announcementReply, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      AnnouncementRepliesApiActions.loadAnnouncementRepliesFail,
      AnnouncementRepliesApiActions.updateAnnouncementReplyFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      AnnouncementRepliesWsActions.announcementRepliesCreated,
      AnnouncementRepliesWsActions.announcementRepliesPatched,
      (state, action) => {
        return announcementRepliesAdapter.upsertOne(action.announcementReply, {
          ...state
        });
      }
    ),

    on(
      AnnouncementRepliesWsActions.announcementRepliesRemoved,
      (state, action) => {
        return announcementRepliesAdapter.removeOne(action.id, {
          ...state
        });
      }
    ),

    on(AnnouncementRepliesGeneralActions.clearAnnouncementReplies, (state) => {
      return announcementRepliesAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }),

    on(
      AnnouncementRepliesGeneralActions.addAnnouncementReplies,
      (state, action) => {
        return announcementRepliesAdapter.addMany(action.announcementReplies, {
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
