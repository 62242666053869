import { includes, isString, trim } from 'lodash';
import { lookupsById } from '../lookups';
import { MeasurementId } from '../lookups/measurements.lookup';
import { MedicationFormId } from '../lookups/medication-forms.lookup';
import {
  AmountSegment,
  AmountWithMeasurement,
  isAmountSegmentArray
} from '../types/medications/amount.interface';
import { AmountDisplayOptions, stringifyAmount } from './stringify-amount';
import { parseAmountString } from './parse-amount-string';

export function formatAmount(
  value?: string | AmountSegment[] | null,
  measurementId?: MeasurementId | null,
  formId?: MedicationFormId | null,
  { showMeasurement = 'all', includeSpacing = false }: AmountDisplayOptions = {}
): string {
  const options = {
    showMeasurement,
    includeSpacing
  };

  if (!value) {
    return '';
  }

  const measurement = measurementId && lookupsById.measurements[measurementId];

  const form = formId && lookupsById.medicationForms[formId];

  if (!measurement) {
    return isAmountSegmentArray(value)
      ? stringifyAmount(value, options)
      : value;
  }

  if (typeof value === 'string' && trim(value) === '-') {
    return '';
  }

  let amountSegments: AmountSegment[] = isAmountSegmentArray(value)
    ? value
    : parseAmountString(value);

  amountSegments = amountSegments.map((segment, index) => {
    if (isString(segment)) {
      return segment;
    }

    const isLastSegment = index === amountSegments.length - 1;

    const newSegment = { ...segment };

    switch (measurement.id) {
      case 'mgml':
        newSegment.measurement = !isLastSegment ? 'mg' : 'ml';
        break;
      case 'unitml':
        newSegment.measurement = !isLastSegment ? 'unit' : 'ml';
        break;
      case 'gml':
        newSegment.measurement = !isLastSegment ? 'g' : 'ml';
        break;
      default:
        newSegment.measurement = measurement.abbreviation;
    }

    return newSegment;
  });

  if (amountSegments.length > 1) {
    return stringifyAmount(amountSegments, options);
  }

  const countableForms = ['tablet', 'capsule'];

  if (measurement.id === 'unit') {
    if (includes(countableForms, form?.id)) {
      const unitAmount = (amountSegments[0] as AmountWithMeasurement).amount;
      return `${unitAmount} ${form?.name}${
        parseFloat(unitAmount) > 1 ? 's' : ''
      }`;
    }

    return `${(amountSegments[0] as AmountWithMeasurement).amount}`;
  }

  return stringifyAmount(amountSegments, options);
}
