import { IPaginationInfo } from '$/models';

import { determinePagination } from '../pagination/determine-pagination';

/**
 * Return an object containing skip and total from the pagination
 * objection. If a field is passed in the pagination data will be
 * set on that field. If the paginated argument is not paginated
 * the pagination object returned will set to the default value.
 */
export function getPaginationData<P = IPaginationInfo>(
  state,
  paginated,
  field?: string
): P {
  let paginationData: P;

  if (determinePagination(paginated)) {
    if (field) {
      paginationData = {
        ...state.pagination,
        [field]: {
          total: paginated.total,
          skip: paginated.skip
        }
      };
    } else {
      paginationData = {
        ...state.pagination,
        total: paginated.total,
        skip: paginated.skip
      };
    }
  } else {
    if (field) {
      paginationData = {
        ...state.selectPagination,
        [field]: {
          total: 0,
          skip: 0
        }
      };
    } else {
      paginationData = {
        ...state.pagination,
        total: 0,
        skip: 0
      };
    }
  }

  return paginationData;
}
