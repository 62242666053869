import { MedicalProfessionalPhone, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Create Success/Fail
export const createMedicalProfessionalPhoneSuccess = createAction(
  '[MedicalProfessionalPhones API] Create MedicalProfessionalPhone Success',
  props<{
    medicalProfessionalPhone:
      | MedicalProfessionalPhone
      | MedicalProfessionalPhone[];
  }>()
);
export const createMedicalProfessionalPhoneFail = createAction(
  '[MedicalProfessionalPhones API] Create MedicalProfessionalPhone Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateMedicalProfessionalPhoneSuccess = createAction(
  '[MedicalProfessionalPhones API] Update MedicalProfessionalPhone Success',
  props<{ medicalProfessionalPhone: Update<MedicalProfessionalPhone> }>()
);
export const updateMedicalProfessionalPhoneFail = createAction(
  '[MedicalProfessionalPhones API] Update MedicalProfessionalPhone Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteMedicalProfessionalPhoneSuccess = createAction(
  '[MedicalProfessionalPhones API] Delete MedicalProfessionalPhone Success',
  props<{ id: string }>()
);
export const deleteMedicalProfessionalPhoneFail = createAction(
  '[MedicalProfessionalPhones API] Delete MedicalProfessionalPhone Fail',
  props<{ error: Error }>()
);

// [WS] MedicalProfessionalPhones Created
export const medicalProfessionalPhonesCreated = createAction(
  '[MedicalProfessionalPhones WS] MedicalProfessionalPhones Created',
  props<{ medicalProfessionalPhone: MedicalProfessionalPhone }>()
);
// [WS] MedicalProfessionalPhones Patched
export const medicalProfessionalPhonesPatched = createAction(
  '[MedicalProfessionalPhones WS] MedicalProfessionalPhones Patched',
  props<{ medicalProfessionalPhone: MedicalProfessionalPhone }>()
);
// [WS] MedicalProfessionalPhones Removed
export const medicalProfessionalPhonesRemoved = createAction(
  '[MedicalProfessionalPhones WS] MedicalProfessionalPhones Removed',
  props<{ id: string }>()
);
