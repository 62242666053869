import { IIncidentReportResident } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const IncidentReportResidentsWsActions = createActionGroup({
  source: 'Incident Report Residents WS',
  events: {
    'Incident Report Resident Created': props<{
      incidentReportResident: IIncidentReportResident;
    }>(),
    'Incident Report Resident Patched': props<{
      incidentReportResident: IIncidentReportResident;
    }>(),
    'Incident Report Resident Removed': props<{ id: string }>()
  }
});
