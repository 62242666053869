import { createAction, props } from '@ngrx/store';

import { MedicationInventoryMovement, Paginated, Update } from '$/models';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadMedicationInventoryMovementsSuccess = createAction(
  '[MedicationInventoryMovements API] Load MedicationInventoryMovements Success',
  props<{
    medicationInventoryMovements: Paginated<MedicationInventoryMovement>;
  }>()
);
export const loadMedicationInventoryMovementsFail = createAction(
  '[MedicationInventoryMovements API] Load MedicationInventoryMovements Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getMedicationInventoryMovementsSuccess = createAction(
  '[MedicationInventoryMovements API] Get MedicationInventoryMovements Success',
  props<{
    medicationInventoryMovements: Paginated<MedicationInventoryMovement>;
  }>()
);
export const getMedicationInventoryMovementsFail = createAction(
  '[MedicationInventoryMovements API] Get MedicationInventoryMovements Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchMedicationInventoryMovementSuccess = createAction(
  '[MedicationInventoryMovement API] Fetch MedicationInventoryMovement Success',
  props<{ medicationInventoryMovement: MedicationInventoryMovement }>()
);
export const fetchMedicationInventoryMovementFail = createAction(
  '[MedicationInventoryMovement API] Fetch MedicationInventoryMovement Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createMedicationInventoryMovementSuccess = createAction(
  '[MedicationInventoryMovements API] Create MedicationInventoryMovement Success',
  props<{
    medicationInventoryMovement:
      | MedicationInventoryMovement
      | MedicationInventoryMovement[];
  }>()
);
export const createMedicationInventoryMovementFail = createAction(
  '[MedicationInventoryMovements API] Create MedicationInventoryMovement Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateMedicationInventoryMovementSuccess = createAction(
  '[MedicationInventoryMovements API] Update MedicationInventoryMovement Success',
  props<{ medicationInventoryMovement: Update<MedicationInventoryMovement> }>()
);
export const updateMedicationInventoryMovementFail = createAction(
  '[MedicationInventoryMovements API] Update MedicationInventoryMovement Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteMedicationInventoryMovementSuccess = createAction(
  '[MedicationInventoryMovements API] Delete MedicationInventoryMovement Success',
  props<{ id: string }>()
);
export const deleteMedicationInventoryMovementFail = createAction(
  '[MedicationInventoryMovements API] Delete MedicationInventoryMovement Fail',
  props<{ error: Error }>()
);

// [WS] MedicationInventoryMovements Created
export const medicationInventoryMovementsCreated = createAction(
  '[MedicationInventoryMovements WS] MedicationInventoryMovements Created',
  props<{ medicationInventoryMovement: MedicationInventoryMovement }>()
);
// [WS] MedicationInventoryMovements Patched
export const medicationInventoryMovementsPatched = createAction(
  '[MedicationInventoryMovements WS] MedicationInventoryMovements Patched',
  props<{ medicationInventoryMovement: MedicationInventoryMovement }>()
);
// [WS] MedicationInventoryMovements Removed
export const medicationInventoryMovementsRemoved = createAction(
  '[MedicationInventoryMovements WS] MedicationInventoryMovements Removed',
  props<{ id: string }>()
);

export const addMedicationInventoryMovements_DashboardRoute = createAction(
  '[MedicationInventoryMovements Dashboard Route] add MedicationInventoryMovements DashboardRoute Success',
  props<{
    medicationInventoryMovement:
      | MedicationInventoryMovement
      | MedicationInventoryMovement[];
  }>()
);
