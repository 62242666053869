import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { BloodSugarLogsPageActions } from '$/app/pages/notebook/shared/logs/blood-sugar-log/blood-sugar-log.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  BloodSugarLogsApiActions,
  BloodSugarLogsGeneralActions,
  BloodSugarLogsWsActions
} from './blood-sugar-logs.actions';
import {
  BloodSugarLogPageQueries,
  bloodSugarLogsAdapter,
  initialState
} from './blood-sugar-logs.state';
import { BloodSugarLogFormPageActions } from '$/app/shared/pages/forms/log-forms/blood-sugar-log-form/blood-sugar-log-form.actions';

export const bloodSugarLogsFeature = createFeature({
  name: 'bloodSugarLogs',
  reducer: createReducer(
    initialState,
    on(
      BloodSugarLogsPageActions.loadBloodSugarLogs,
      BloodSugarLogsPageActions.getBloodSugarLogs,
      BloodSugarLogFormPageActions.createBloodSugarLog,
      BloodSugarLogFormPageActions.updateBloodSugarLog,
      BloodSugarLogsPageActions.deleteBloodSugarLog,
      ResidentMedicationTasksPageActions.deleteBloodSugarLog,
      MissedMedicationsPageActions.deleteBloodSugarLog,
      RoutineMarListPageActions.deleteBloodSugarLog,
      RoutineMarDetailPageActions.deleteBloodSugarLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),
    on(BloodSugarLogsApiActions.loadBloodSugarLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<BloodSugarLogPageQueries>(
        state,
        action.bloodSugarLogs,
        queryType
      );
      const data = getRecords(action.bloodSugarLogs);

      return bloodSugarLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(BloodSugarLogsApiActions.getBloodSugarLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<BloodSugarLogPageQueries>(
        state,
        action.bloodSugarLogs,
        queryType
      );
      const data = getRecords(action.bloodSugarLogs);

      return bloodSugarLogsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(BloodSugarLogsApiActions.createBloodSugarLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(BloodSugarLogsApiActions.updateBloodSugarLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(BloodSugarLogsApiActions.deleteBloodSugarLogSuccess, (state, action) =>
      bloodSugarLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),
    on(
      BloodSugarLogsApiActions.loadBloodSugarLogsFail,
      BloodSugarLogsApiActions.getBloodSugarLogsFail,
      BloodSugarLogsApiActions.createBloodSugarLogFail,
      BloodSugarLogsApiActions.updateBloodSugarLogFail,
      BloodSugarLogsApiActions.deleteBloodSugarLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),
    on(
      BloodSugarLogsWsActions.bloodSugarLogsCreated,
      BloodSugarLogsWsActions.bloodSugarLogsPatched,
      (state, action) => {
        return bloodSugarLogsAdapter.upsertOne(action.bloodSugarLog, state);
      }
    ),
    on(BloodSugarLogsWsActions.bloodSugarLogsRemoved, (state, action) => {
      return bloodSugarLogsAdapter.removeOne(action.id, state);
    }),
    on(BloodSugarLogsGeneralActions.addBloodSugarLogs, (state, action) => {
      return bloodSugarLogsAdapter.upsertMany(action.bloodSugarLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(BloodSugarLogsGeneralActions.clearBloodSugarLogs, () => {
      return bloodSugarLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
