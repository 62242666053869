import { ResidentsSelectors } from '$/app/store/residents/residents.selectors';
import * as RouterSelectors from '$/app/store/router/router.selectors';
import { ResidentGroupMember } from '$/models/data/entities/residents/resident-group-member.model';
import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { IResident } from '$shared/residents/residents.interface';
import { Dictionary } from '$shared/types/general';
import { createSelector } from '@ngrx/store';
import { residentGroupMembersFeature } from './resident-group-members.reducer';
import { residentGroupMembersAdapter } from './resident-group-members.state';

export const {
  selectResidentGroupMembersState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = residentGroupMembersFeature;

export const { selectAll, selectTotal } =
  residentGroupMembersAdapter.getSelectors(selectResidentGroupMembersState);

export const selectResidentGroupMembers = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (groupMembers, facUserId) => {
    if (!facUserId) {
      return [];
    }

    return groupMembers.filter((groupMember) => {
      return groupMember.residentId === facUserId;
    });
  }
);

export const selectResidentsFromResidentGroupMembers = (
  residentGroup: ResidentGroup
) => {
  return createSelector(
    selectAll,
    ResidentsSelectors.selectAll,
    (residentGroupMembers, residents): IResident[] => {
      residentGroupMembers = residentGroupMembers.filter(
        (x) => x.residentGroupId === residentGroup.id
      );
      return residentGroupMembers.map((group) => {
        return residents.find((x) => x.id === group.residentId);
      });
    }
  );
};

export const selectResidentGroupMembersWithGroupId = (
  residentGroupId: string
) => {
  return createSelector(
    selectAll,
    (residentGroupMembers): ResidentGroupMember[] => {
      return residentGroupMembers.filter(
        (x) => x.residentGroupId === residentGroupId
      );
    }
  );
};

export const selectMapResidentGroupIdsToResidentIds = createSelector(
  selectAll,
  (residentGroupMembers): Dictionary<string[]> => {
    return residentGroupMembers.reduce(
      (acc, residentGroupMember) => {
        const residentGroupId = residentGroupMember.residentGroupId;
        acc[residentGroupId] = acc[residentGroupId] || [];
        acc[residentGroupId].push(residentGroupMember.residentId);
        return acc;
      },
      {} as Dictionary<string[]>
    );
  }
);
