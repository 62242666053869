import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PositionLogsEffects } from './position-logs.effects';
import { positionLogsFeature } from './position-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(positionLogsFeature),
    EffectsModule.forFeature([PositionLogsEffects])
  ],
  providers: [PositionLogsEffects]
})
export class PositionLogsStoreModule {}
