import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { stringify } from 'safe-stable-stringify';
import { PrnMarListFilters } from '../prn-mar-list.vmodel';

export const PrnMarListFilterPageActions = createActionGroup({
  source: 'Prn Mar List Filter Page',
  events: {
    'Filter Changed': (
      filters: PrnMarListFilters
    ): { filters: PrnMarListFilters; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          responses: stringify(filters.responses),
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
