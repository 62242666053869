<ion-card class="m-0 h-full rounded-2xl {{ cardClasses }}">
  <div
    class="flex h-12 min-h-12 items-center justify-between border-b border-b-medium-light pl-4"
  >
    <ng-content select="[info-card-title]">
      <h2 class="m-0 text-base text-dark">{{ title }}</h2>
    </ng-content>

    @if (showButton) {
      <ng-content select="[info-card-button]">
        <ion-button
          fill="clear"
          (click)="onButtonClick($event)"
          [attr.data-testid]="buttonTestId"
        >
          <ion-icon [src]="setIconPath()" slot="icon-only" color="dark" />
        </ion-button>
      </ng-content>
    }
  </div>
  <ng-content select="ion-card-header" />

  <div #body>
    <ng-content />
  </div>

  @if (!body.children.length) {
    <div class="p-6">
      <!-- typically used with alc-callout -->
      <ng-content select="[info-card-empty-state]" />
    </div>
  }
</ion-card>
