import { CarListFilterPageActions } from '$/app/pages/care/residents/car-list/car-list-filter/car-list-filter.actions';
import { MarSummaryFilterComponentActions } from '$/app/pages/medications/residents/mar-summary/mar-summary-filter/mar-summary-filter.actions';
import { MedicationListFilterComponentActions } from '$/app/pages/medications/residents/medication-list/medication-list-filter/medication-list-filter.actions';
import { PrnMarListFilterPageActions } from '$/app/pages/medications/residents/prn-mar-list/prn-mar-list-filter/prn-mar-list-filter.actions';
import { RoutineMarListFilterPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list-filter/routine-mar-list-filter.actions';
import { ShiftNotesFilterPageActions } from '$/app/pages/notebook/shared/shift-notes/shift-notes-filter/shift-notes-filter.actions';
import { EffectHelpersService } from '$/app/services';
import { BehaviorLogsApiActions } from '$/app/store/behavior-logs';
import { BehaviorsApiActions } from '$/app/store/behaviors';
import { BloodPressureLogsApiActions } from '$/app/store/blood-pressure-logs';
import { BloodSugarLogsApiActions } from '$/app/store/blood-sugar-logs';
import { BodyCheckLogsApiActions } from '$/app/store/body-check-logs';
import { BmLogsApiActions } from '$/app/store/bowel-movement-logs';
import { FoodLogsApiActions } from '$/app/store/food-logs';
import { MedicalEventsApiActions } from '$/app/store/medical-events/actions';
import { MoodLogsApiActions } from '$/app/store/mood-logs';
import { PrnsApiActions } from '$/app/store/prns';
import { SleepLogsApiActions } from '$/app/store/sleep-logs';
import { TemperatureLogsApiActions } from '$/app/store/temperature-logs';
import { WaterLogsApiActions } from '$/app/store/water-logs';
import { WeightLogsApiActions } from '$/app/store/weight-logs';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MedicationOrdersListFilterPageActions } from '../pages/medications/dashboard/medication-orders/medication-orders-list/medication-orders-list-filters/medication-orders-list-filters.actions';
import { EventListFilterPageActions } from '../pages/notebook/residents/event-list-filter/event-list-filter.action';

@Injectable()
export class AppEffects {
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly router = inject(Router);

  showFormSubmitError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PrnsApiActions.createPrnFail,
          PrnsApiActions.updatePrnFail,
          BehaviorLogsApiActions.createBehaviorLogFail,
          BehaviorLogsApiActions.updateBehaviorLogFail,
          BehaviorLogsApiActions.deleteBehaviorLogFail,
          BehaviorsApiActions.createBehaviorFail,
          BehaviorsApiActions.updateBehaviorFail,
          BehaviorsApiActions.deleteBehaviorFail,
          BloodPressureLogsApiActions.createBloodPressureLogFail,
          BloodPressureLogsApiActions.updateBloodPressureLogFail,
          BloodPressureLogsApiActions.deleteBloodPressureLogFail,
          BloodSugarLogsApiActions.createBloodSugarLogFail,
          BloodSugarLogsApiActions.updateBloodSugarLogFail,
          BloodSugarLogsApiActions.deleteBloodSugarLogFail,
          BmLogsApiActions.createBmLogFail,
          BmLogsApiActions.updateBmLogFail,
          BmLogsApiActions.deleteBmLogFail,
          BodyCheckLogsApiActions.createBodyCheckLogFail,
          BodyCheckLogsApiActions.updateBodyCheckLogFail,
          BodyCheckLogsApiActions.deleteBodyCheckLogFail,
          FoodLogsApiActions.createFoodLogFail,
          FoodLogsApiActions.updateFoodLogFail,
          FoodLogsApiActions.deleteFoodLogFail,
          MoodLogsApiActions.createMoodLogFail,
          MoodLogsApiActions.updateMoodLogFail,
          MoodLogsApiActions.deleteMoodLogFail,
          SleepLogsApiActions.createSleepLogFail,
          SleepLogsApiActions.updateSleepLogFail,
          SleepLogsApiActions.deleteSleepLogFail,
          TemperatureLogsApiActions.createTemperatureLogFail,
          TemperatureLogsApiActions.updateTemperatureLogFail,
          TemperatureLogsApiActions.deleteTemperatureLogFail,
          WaterLogsApiActions.createWaterLogFail,
          WaterLogsApiActions.updateWaterLogFail,
          WaterLogsApiActions.deleteWaterLogFail,
          WeightLogsApiActions.createWeightLogFail,
          WeightLogsApiActions.updateWeightLogFail,
          WeightLogsApiActions.deleteWeightLogFail,
          MedicalEventsApiActions.createMedicalEventFail,
          MedicalEventsApiActions.updateMedicalEventFail,
          MedicalEventsApiActions.updateMedicalEventFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      ),
    { dispatch: false }
  );

  handleFilterChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RoutineMarListFilterPageActions.filterChanged,
          PrnMarListFilterPageActions.filterChanged,
          CarListFilterPageActions.filterChanged,
          ShiftNotesFilterPageActions.filterChanged,
          MarSummaryFilterComponentActions.filterChanged,
          MedicationListFilterComponentActions.filterChanged,
          MedicationOrdersListFilterPageActions.filterChanged,
          EventListFilterPageActions.filterChanged
        ),
        tap((action) => {
          this.router.navigate([], { queryParams: action.queryParams });
        })
      ),
    { dispatch: false }
  );
}
