import { AlcomyColor } from '$/models';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2,
  inject
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlcCommonModule } from '../../alc-common.module';

@Component({
  selector: 'alc-checkbox',
  standalone: true,
  imports: [AlcCommonModule],
  template: `
    @if (icon) {
      <ion-icon
        [ngStyle]="{ 'font-size': iconSize }"
        src="/assets/icon/material/{{ icon }}.svg"
      />
    } @else {
      <span name="checkbox-text">
        <ng-content />
      </span>
    }
  `,
  styles: [
    `
      :host {
        box-sizing: border-box;

        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: transparent;
        font-weight: 800;
        color: var(--alcomy-color);
        border: 1px solid var(--alcomy-color);
        [name='checkbox-text'] {
          font-size: 0.45em;
        }

        &.selected {
          color: var(--alcomy-color-contrast);
          background-color: var(--alcomy-color);
        }
        &:hover {
          cursor: pointer;
        }
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AlcCheckboxControlComponent,
      multi: true
    }
  ]
})
export class AlcCheckboxControlComponent
  implements ControlValueAccessor, AfterViewInit
{
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  _color: AlcomyColor = 'medium';

  onChange: (value: any) => any;

  @Input() size: string = '32px';
  @Input() iconSize: string = '0.6em';
  @Input() icon: string;

  @Input()
  set color(value: AlcomyColor) {
    if (value) {
      this._color = value;
    }
  }
  get color(): AlcomyColor {
    return this._color;
  }
  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.selected') value: boolean = false;

  @HostListener('click') toggleState() {
    this.value = !this.value;
    if (this.onChange) {
      this.onChange(this.value);
    }

    this.changed.emit(this.value);
  }

  ngAfterViewInit() {
    const element = this.elementRef.nativeElement;
    this.renderer.setStyle(element, 'min-width', this.size);
    this.renderer.setStyle(element, 'min-height', this.size);
    this.renderer.setStyle(element, 'font-size', this.size);

    this.renderer.addClass(
      this.elementRef.nativeElement,
      `alcomy-${this.color}`
    );
  }

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => any) {
    this.onChange = fn;
  }

  registerOnTouched(_fn: (value: any) => any) {}
}
