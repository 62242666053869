import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { stringify } from 'safe-stable-stringify';
import { CarListFilters } from '../car-list.vmodel';

export const CarListFilterPageActions = createActionGroup({
  source: 'Car List Filter Page',
  events: {
    'Filter Changed': (
      filters: CarListFilters
    ): { filters: CarListFilters; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          states: stringify(filters.states),
          missedOnly: filters.missedOnly,
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
