import { normalize } from 'normalizr';

import { normalizeToArrayAll } from './normalize-to-array-all';

interface FieldsToRemove {
  [key: string]: string[];
}

/**
 * Normalizes graph-like data based on a given schema and will remove
 * unwanted fields from each entity in the flattened graph.
 */
export const normalizeAndRemove = (
  data,
  schema,
  fieldsToRemove?: FieldsToRemove
) => {
  const normalizedGraph = normalize(data, schema);

  const normalizedData = normalizeToArrayAll(
    normalizedGraph.entities,
    fieldsToRemove
  );

  return normalizedData;
};
