import { SymptomsListPageActions } from '$/app/pages/facilities/facility-detail/symptoms-list/symptom-list.actions';
import { EffectHelpersService, SymptomsApiService } from '$/app/services';
import { SymptomFormPageActions } from '$/app/shared/pages/forms/symptom-form/symptom-form.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Symptom } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap
} from 'rxjs/operators';
import { SymptomsApiActions } from './actions';

@Injectable()
export class SymptomsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly symptomsService = inject(SymptomsApiService);

  getSymptomsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SymptomsListPageActions.getSymptoms),
      switchMap((action) => {
        return this.symptomsService.getAll(action.params).pipe(
          getAllPages(this.symptomsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) =>
            new ApiData(
              'symptoms',
              logs,
              SymptomsApiActions.getSymptomsSuccess
            ).getActions()
          )
        );
      })
    );
  });

  createSymptom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SymptomsListPageActions.createSymptom,
        SymptomFormPageActions.createSymptom
      ),
      this.effectHelpers.apiRequest({
        description: 'Create Symptom',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.symptomsService.create(action.symptom, action.params),
        onSuccess: (symptom) =>
          SymptomsApiActions.createSymptomSuccess({ symptom }),
        onError: (error) => SymptomsApiActions.createSymptomFail({ error })
      })
    );
  });

  updateSymptom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SymptomFormPageActions.updateSymptom),
      this.effectHelpers.apiRequest({
        description: 'Update Symptom',
        onRequest: (action) =>
          this.symptomsService.patch(action.id, action.changes, action.params),
        onSuccess: (symptom) =>
          SymptomsApiActions.updateSymptomSuccess({
            symptom
          }),
        onError: (error) => SymptomsApiActions.updateSymptomFail({ error })
      })
    );
  });

  deleteSymptom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SymptomsListPageActions.deleteSymptom),
      exhaustMap((action) => {
        return this.symptomsService.delete(action.id).pipe(
          map((symptom: Symptom) =>
            SymptomsApiActions.deleteSymptomSuccess({
              id: symptom.id
            })
          ),
          catchError((error) =>
            of(
              SymptomsApiActions.deleteSymptomFail({
                error
              })
            )
          )
        );
      })
    );
  });
}
