import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { MarSummaryFilters } from '../mar-summary.vmodel';

export const MarSummaryFilterComponentActions = createActionGroup({
  source: 'Mar Summary Filter Component',
  events: {
    'Filter Changed': (
      filters: MarSummaryFilters
    ): { filters: MarSummaryFilters; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          statuses: filters.statuses,
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
