import { EventsListActions } from '$/app/pages/notebook/residents/events-list/events-list.actions';
import { MedicalEventModalActions } from '$/app/pages/notebook/residents/medical-event-modal/medical-event.modal.actions';
import {
  initialState,
  medicalEventsAdapter
} from '$/app/store/medical-events/medical-events.state';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  MedicalEventsApiActions,
  MedicalEventsGeneralActions
} from './actions';

export const medicalEventsFeature = createFeature({
  name: 'medicalEvents',
  reducer: createReducer(
    initialState,

    on(
      EventsListActions.loadMedicalEvents,
      MedicalEventModalActions.createMedicalEvent,
      MedicalEventModalActions.updateMedicalEvent,
      EventsListActions.deleteMedicalEvent,
      (state) => {
        return {
          ...state,
          loading: true
        };
      }
    ),

    on(MedicalEventsApiActions.loadMedicalEventsSuccess, (state, action) => {
      return medicalEventsAdapter.setAll(action.medicalEvents, {
        ...state,
        loading: false,
        loaded: true
      });
    }),

    on(MedicalEventsApiActions.createMedicalEventSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(MedicalEventsApiActions.updateMedicalEventSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(MedicalEventsApiActions.deleteMedicalEventSuccess, (state, action) => {
      return medicalEventsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      MedicalEventsApiActions.loadMedicalEventsFail,
      MedicalEventsApiActions.createMedicalEventFail,
      MedicalEventsApiActions.updateMedicalEventFail,
      MedicalEventsApiActions.deleteMedicalEventFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicalEventsApiActions.medicalEventsCreated,
      MedicalEventsApiActions.medicalEventsPatched,
      (state, action) => {
        return medicalEventsAdapter.upsertOne(action.medicalEvent, state);
      }
    ),

    on(MedicalEventsApiActions.medicalEventsRemoved, (state, action) => {
      return medicalEventsAdapter.removeOne(action.id, state);
    }),

    on(MedicalEventsGeneralActions.addMedicalEvents, (state, action) => {
      return medicalEventsAdapter.upsertMany(action.medicalEvents, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(MedicalEventsGeneralActions.clearMedicalEvents, (state) => {
      return medicalEventsAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
