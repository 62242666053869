import {
  ILibraryCollectionDocumentTemplatePacket,
  IPaginationInfo
} from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const libraryCollectionDocumentTemplatePacketsAdapter =
  createEntityAdapter<ILibraryCollectionDocumentTemplatePacket>();

export interface State
  extends EntityState<ILibraryCollectionDocumentTemplatePacket> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  libraryCollectionDocumentTemplatePacketsAdapter.getInitialState({
    pagination: {},
    loading: false,
    loaded: false,
    error: null
  });
