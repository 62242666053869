import { FeathersService } from '$/app/services/feathers.service';
import { AllergiesWsActions } from '$/app/store/allergies/allergies.actions';
import { getPaginatedData, toUpdated } from '$/app/utils';
import { Allergy, Paginated, Params, Update } from '$/models';
import { AtLeast, MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AllergiesApiService {
  private allergiesService: Service<Allergy>;

  constructor(feathers: FeathersService, store: Store) {
    this.allergiesService = feathers.client.service('allergies');

    this.allergiesService.on('created', (allergy) => {
      store.dispatch(AllergiesWsActions.allergiesCreated({ allergy }));
    });

    this.allergiesService.on('patched', (allergy) => {
      store.dispatch(AllergiesWsActions.allergiesPatched({ allergy }));
    });

    this.allergiesService.on('removed', (allergy) => {
      store.dispatch(AllergiesWsActions.allergiesRemoved({ id: allergy.id }));
    });
  }

  getAll(params: Params = { query: {} }): Observable<Allergy[]> {
    const promise = this.allergiesService.find(params) as Promise<
      Paginated<Allergy>
    >;
    return from(promise).pipe(map(getPaginatedData));
  }

  get(id: string, params: Params = { query: {} }): Observable<Allergy> {
    const promise = this.allergiesService.get(id, params);
    return from(promise);
  }

  create(allergy: MaybeArray<Allergy>, params: Params = { query: {} }) {
    const promise = this.allergiesService.create(allergy, params);
    return from(promise);
  }

  patch(
    id: string,
    allergy: AtLeast<Allergy, 'levelId' | 'name'>,
    params: Params = { query: {} }
  ): Observable<Update<Allergy>> {
    const promise = this.allergiesService.patch(id, allergy, params);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string, params: Params = { query: {} }): Observable<Allergy> {
    const promise = this.allergiesService.remove(id, params);
    return from(promise);
  }
}
