import { FeathersService } from '$/app/services/feathers.service';
import { MedicalEventsApiActions } from '$/app/store/medical-events/actions';
import { getPaginatedData, toUpdated } from '$/app/utils';
import { MedicalEvent, Paginated, Params, Update } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MedicalEventsApiService {
  private medicalEventsService: Service<MedicalEvent>;

  constructor(feathers: FeathersService, store: Store) {
    this.medicalEventsService = feathers.client.service('medical-events');

    this.medicalEventsService.on('created', (medicalEvent) => {
      store.dispatch(
        MedicalEventsApiActions.medicalEventsCreated({
          medicalEvent
        })
      );
    });

    this.medicalEventsService.on('patched', (medicalEvent) => {
      store.dispatch(
        MedicalEventsApiActions.medicalEventsPatched({
          medicalEvent
        })
      );
    });

    this.medicalEventsService.on('removed', (medicalEvent) => {
      store.dispatch(
        MedicalEventsApiActions.medicalEventsRemoved({
          id: medicalEvent.id
        })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<MedicalEvent[]> {
    const promise = this.medicalEventsService.find(params) as Promise<
      Paginated<MedicalEvent>
    >;
    return from(promise).pipe(map(getPaginatedData));
  }

  get(id: string, params: Params = { query: {} }): Observable<MedicalEvent> {
    const promise = this.medicalEventsService.get(id, params);
    return from(promise);
  }

  create(medicalEvent: MedicalEvent) {
    const promise = this.medicalEventsService.create(medicalEvent);
    return from(promise);
  }

  patch(
    id: string,
    medicalEvent: Partial<MedicalEvent>
  ): Observable<Update<MedicalEvent>> {
    const promise = this.medicalEventsService.patch(id, medicalEvent);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string): Observable<MedicalEvent> {
    const promise = this.medicalEventsService.remove(id);
    return from(promise);
  }
}
