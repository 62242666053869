import { FacilityUserGroupFormActions } from '$/app/pages/staff/facility-user-group-form/facility-user-group-form.actions';
import { FacilityUserGroupActions } from '$/app/pages/staff/staff-groups/facility-user-groups-list.actions';
import { FacilityUserGroupPageRouteActions } from '$/app/pages/staff/staff-routing.actions';
import {
  EffectHelpersService,
  FacilityUserGroupsApiService
} from '$/app/services';
import {
  NormalizedAction,
  createActionsFromNormalizedData,
  normalizeAndRemove
} from '$/app/utils';
import { FacilityUserGroup } from '$/models';
import { facilityUserGroupSchema } from '$shared';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { FacilityUserGroupMembersGeneralActions } from '../facility-user-group-members';
import {
  FacilityUserGroupDetailActions,
  FacilityUserGroupsApiActions,
  FacilityUserGroupsGeneralActions
} from './actions';

@Injectable()
export class FacilityUserGroupsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly facilityUserGroupsService = inject(
    FacilityUserGroupsApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  loadFacilityUserGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FacilityUserGroupActions.loadFacilityUserGroups,
        FacilityUserGroupPageRouteActions.loadFacilityUserGroups
      ),
      switchMap((action) => {
        return this.facilityUserGroupsService.getAll(action.params).pipe(
          mergeMap((facilityUserGroups: FacilityUserGroup[]) => {
            const normalizedData = normalizeAndRemove(
              facilityUserGroups,
              [facilityUserGroupSchema],
              { facilityUserGroups: ['members'] }
            );

            return createActionsFromNormalizedData(normalizedData, {
              facilityUserGroups: new NormalizedAction(
                FacilityUserGroupsGeneralActions.addFacilityUserGroups,
                { dispatchOnEmpty: true }
              ),
              facilityUserGroupMembers: new NormalizedAction(
                FacilityUserGroupMembersGeneralActions.addFacilityUserGroupMembers,
                { dispatchOnEmpty: true }
              )
            });
          }),
          catchError((error) => {
            return of(
              FacilityUserGroupsApiActions.loadFacilityUserGroupsFail({
                error
              })
            );
          })
        );
      })
    );
  });

  getFacilityUserGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityUserGroupActions.getFacilityUserGroups),
      switchMap((action) => {
        return this.facilityUserGroupsService.getAll(action.params).pipe(
          map((facilityUserGroups: FacilityUserGroup[]) => {
            return FacilityUserGroupsApiActions.getFacilityUserGroupsSuccess({
              facilityUserGroups
            });
          }),
          catchError((error) => {
            return of(
              FacilityUserGroupsApiActions.getFacilityUserGroupsFail({
                error
              })
            );
          })
        );
      })
    );
  });

  fetchFacilityUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityUserGroupDetailActions.fetchFacilityUserGroup),
      switchMap((action) => {
        return this.facilityUserGroupsService
          .get(action.id, action.params)
          .pipe(
            map((facilityUserGroup: FacilityUserGroup) => {
              return FacilityUserGroupsApiActions.fetchFacilityUserGroupSuccess(
                { facilityUserGroup }
              );
            }),
            catchError((error) => {
              return of(
                FacilityUserGroupsApiActions.fetchFacilityUserGroupFail({
                  error
                })
              );
            })
          );
      })
    );
  });

  createFacilityUserGroup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserGroupFormActions.createFacilityUserGroup),
        exhaustMap((action) => {
          return this.facilityUserGroupsService
            .create(action.facilityUserGroup)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'Created successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  FacilityUserGroupsApiActions.createFacilityUserGroupFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateFacilityUserGroup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserGroupDetailActions.updateFacilityUserGroup),
        switchMap((action) => {
          return this.facilityUserGroupsService
            .patch(action.id, action.changes)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'Updated successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  FacilityUserGroupsApiActions.updateFacilityUserGroupFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  deleteFacilityUserGroup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FacilityUserGroupDetailActions.deleteFacilityUserGroup,
          FacilityUserGroupActions.deleteFacilityUserGroup
        ),
        exhaustMap((action) => {
          return this.facilityUserGroupsService.delete(action.id).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Deleted successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                FacilityUserGroupsApiActions.deleteFacilityUserGroupFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  facilityUserGroupsFailMessages$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FacilityUserGroupsApiActions.createFacilityUserGroupFail,
          FacilityUserGroupsApiActions.updateFacilityUserGroupFail,
          FacilityUserGroupsApiActions.deleteFacilityUserGroupFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
