import { Medication, Params } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

const createMedication = createAction(
  '[Medication Form] Create Medication',
  props<{ medication: MaybeArray<Medication>; params: Params }>()
);

const updateMedication = createAction(
  '[Medication Form] Update Medication',
  props<{ id: string; medication: Medication; params: Params }>()
);

export const MedicationFormPageActions = {
  createMedication,
  updateMedication
};
