import { destructionMethodsFeature } from './destruction-methods.reducer';
import { destructionMethodsAdapter } from './destruction-methods.state';

export const {
  selectDestructionMethodsState,
  selectEntities,
  selectMetrics,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = destructionMethodsFeature;

export const { selectAll, selectTotal } =
  destructionMethodsAdapter.getSelectors(selectDestructionMethodsState);

export const DestructionMethodsSelectors = {
  selectAll
};
