import { environment } from '$/environments/environment';
import { Params } from '$/models';
import { createAction } from '@ngrx/store';

const defaultLoadParams = {
  query: {
    $limit: environment.pageLimit,
    $skip: 0
  }
};

export const loadOmittedMedications = createAction(
  '[OmittedMedications Page] Load OmittedMedications',
  (params: Params = defaultLoadParams) => ({ params })
);

export const getOmittedMedications = createAction(
  '[OmittedMedications Page] Get OmittedMedications',
  (params: Params = { query: { $limit: environment.pageLimit } }) => ({
    params
  })
);
