import { sortByFields } from '$/app/utils';
import { RolePermission } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const rolePermissionsAdapter = createEntityAdapter<RolePermission>({
  sortComparer: sortByFields('permissionId')
});

export interface State extends EntityState<RolePermission> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = rolePermissionsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
