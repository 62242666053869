import { createReducer, on, Action } from '@ngrx/store';
import {
  MedicationScheduleDetailActions,
  MedicationSchedulesListActions,
  MedicationSchedulesApiActions,
  MedicationSchedulesGeneralActions
} from './actions';
import {
  medicationSchedulesAdapter,
  initialState,
  State
} from './medication-schedules.state';

const reducer = createReducer(
  initialState,

  on(
    MedicationSchedulesListActions.loadMedicationSchedules,
    MedicationScheduleDetailActions.fetchMedicationSchedule,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    MedicationSchedulesApiActions.loadMedicationSchedulesSuccess,
    (state, action) => {
      return medicationSchedulesAdapter.setAll(
        action.medicationSchedules.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationSchedulesApiActions.getMedicationSchedulesSuccess,
    (state, action) => {
      return medicationSchedulesAdapter.upsertMany(
        action.medicationSchedules.data,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationSchedulesApiActions.fetchMedicationScheduleSuccess,
    (state, action) => {
      return medicationSchedulesAdapter.upsertOne(action.medicationSchedule, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicationSchedulesApiActions.loadMedicationSchedulesFail,
    MedicationSchedulesApiActions.getMedicationSchedulesFail,
    MedicationSchedulesApiActions.fetchMedicationScheduleFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    MedicationSchedulesApiActions.medicationSchedulesCreated,
    MedicationSchedulesApiActions.medicationSchedulesPatched,
    (state, action) => {
      return medicationSchedulesAdapter.upsertOne(action.medicationSchedule, {
        ...state
      });
    }
  ),

  on(
    MedicationSchedulesApiActions.medicationSchedulesRemoved,
    (state, action) => {
      return medicationSchedulesAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(
    MedicationSchedulesGeneralActions.addMedicationSchedules,
    (state, action) => {
      return medicationSchedulesAdapter.upsertMany(action.medicationSchedules, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(MedicationSchedulesGeneralActions.clearMedicationSchedules, (state) => {
    return medicationSchedulesAdapter.removeAll({
      ...state,

      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function medicationSchedulesReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
