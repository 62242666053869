import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { ResidentGroupFormActions } from '$/app/pages/residents/resident-group-form/resident-group-form.actions';
import { ResidentGroupMemberPickerActions } from '$/app/pages/residents/resident-group-member-picker/resident-group-member-picker.actions';
import { ResidentGroupPickerFormPageActions } from '$/app/pages/residents/resident-group-picker-form/resident-group-picker-form.actions';
import { EffectHelpersService } from '$/app/services';
import { ResidentGroupMembersApiService } from '$/app/services/api/resident-group-members.service';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { ResidentGroupMembersApiActions } from './actions/resident-group-members-api.actions';

@Injectable()
export class ResidentGroupMembersEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly residentGroupMembersService = inject(
    ResidentGroupMembersApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  getResidentGroupMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentGroupMemberPickerActions.getResidentGroupMembers,
        ResidentGroupFormActions.getResidentGroupMembers
      ),
      switchMap((action) => {
        return this.residentGroupMembersService.getAll(action.params).pipe(
          getAllPages(this.residentGroupMembersService, action.params.query),
          reducePaginatedResponses(),
          mergeMap((groupMembers) => {
            const responseData = new ApiData(
              'residentGroupMembers',
              groupMembers
            );
            responseData.setPrimaryAction(
              ResidentGroupMembersApiActions.getResidentGroupMembersSuccess
            );

            return responseData.getActions();
          }),
          catchError((error) => {
            return of(
              ResidentGroupMembersApiActions.getResidentGroupMembersFail({
                error
              })
            );
          })
        );
      })
    );
  });

  createResidentGroupMember$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentGroupMemberPickerActions.createResidentGroupMember,
          ResidentGroupPickerFormPageActions.createResidentGroupMember
        ),
        exhaustMap((action) => {
          return this.residentGroupMembersService
            .create(action.residentGroupMember, action.params)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  ResidentGroupMembersApiActions.createResidentGroupMemberFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  createResidentGroupMemberFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentGroupMembersApiActions.createResidentGroupMemberFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteResidentGroupMember$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentGroupMemberPickerActions.deleteResidentGroupMember,
          ResidentFacesheetPageActions.deleteResidentGroupMember,
          ResidentGroupPickerFormPageActions.deleteResidentGroupMember
        ),
        exhaustMap((action) => {
          return this.residentGroupMembersService.delete(action.id).pipe(
            catchError((error) => {
              this.store.dispatch(
                ResidentGroupMembersApiActions.deleteResidentGroupMemberFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteResidentGroupMemberFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentGroupMembersApiActions.deleteResidentGroupMemberFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
