import { IDocumentPacket, Paginated } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DocumentPacketsApiActions = createActionGroup({
  source: 'Document Packets API',
  events: {
    // Success Actions
    'Load Document Packets Success': props<{
      documentPackets: Paginated<IDocumentPacket>;
    }>(),
    'Get Document Packets Success': props<{
      documentPackets: Paginated<IDocumentPacket>;
    }>(),
    'Fetch Document Packet Success': props<{
      documentPacket: IDocumentPacket;
    }>(),
    'Create Document Packet Success': props<{
      documentPacket: IDocumentPacket;
    }>(),
    'Create Document Packets Success': props<{
      documentPackets: IDocumentPacket[];
    }>(),
    'Update Document Packet Success': props<{
      documentPacket: IDocumentPacket;
    }>(),
    'Delete Document Packet Success': props<{ id: string }>(),
    // Fail Actions
    'Load Document Packets Fail': props<{ error: Error }>(),
    'Get Document Packets Fail': props<{ error: Error }>(),
    'Fetch Document Packet Fail': props<{ error: Error }>(),
    'Create Document Packet Fail': props<{ error: Error }>(),
    'Create Document Packets Fail': props<{ error: Error }>(),
    'Update Document Packet Fail': props<{ error: Error }>(),
    'Delete Document Packet Fail': props<{ error: Error }>()
  }
});

export const DocumentPacketsWsActions = createActionGroup({
  source: 'Document Packets WS',
  events: {
    'Document Packet Created': props<{
      documentPacket: IDocumentPacket;
    }>(),
    'Document Packet Patched': props<{
      documentPacket: IDocumentPacket;
    }>(),
    'Document Packet Removed': props<{ id: string }>()
  }
});

export const DocumentPacketsGeneralActions = createActionGroup({
  source: 'Document Packets General',
  events: {
    'Clear Document Packets': emptyProps(),
    'Add Document Packets': props<{
      documentPackets: IDocumentPacket[];
    }>()
  }
});
