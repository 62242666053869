import { createFeature, createReducer, on } from '@ngrx/store';

import { CarListFilterPageActions } from '../car-list-filter/car-list-filter.actions';
import { initialState } from './car-list-page.state';

export const uiCarListPageFeature = createFeature({
  name: 'uiCarListPage',
  reducer: createReducer(
    initialState,
    on(CarListFilterPageActions.filterChanged, (state, action) => {
      return {
        ...state,
        filters: action.filters
      };
    })
  )
});
