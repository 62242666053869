import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  MedicationTaskAmountsApiActions,
  MedicationTaskAmountsGeneralActions,
  MedicationTaskAmountsWsActions
} from './medication-task-amounts.actions';
import {
  initialState,
  medicationTaskAmountsAdapter
} from './medication-task-amounts.state';

export const medicationTaskAmountsFeature = createFeature({
  name: 'medicationTaskAmounts',
  reducer: createReducer(
    initialState,

    on(
      MedicationTaskAmountsApiActions.loadMedicationTaskAmountsSuccess,
      (state, action) => {
        return medicationTaskAmountsAdapter.setAll(
          action.medicationTaskAmounts,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationTaskAmountsApiActions.getMedicationTaskAmountsSuccess,
      (state, action) => {
        return medicationTaskAmountsAdapter.upsertMany(
          action.medicationTaskAmounts,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationTaskAmountsApiActions.fetchMedicationTaskAmountSuccess,
      (state, action) => {
        return medicationTaskAmountsAdapter.upsertOne(
          action.medicationTaskAmount,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationTaskAmountsApiActions.createMedicationTaskAmountSuccess,
      (state, action) => {
        const medicationTaskAmounts = castArray(action.medicationTaskAmount);

        return medicationTaskAmountsAdapter.addMany(medicationTaskAmounts, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationTaskAmountsApiActions.updateMedicationTaskAmountSuccess,
      (state, action) => {
        return medicationTaskAmountsAdapter.upsertOne(
          action.medicationTaskAmount,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationTaskAmountsApiActions.deleteMedicationTaskAmountSuccess,
      (state, action) => {
        return medicationTaskAmountsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationTaskAmountsApiActions.loadMedicationTaskAmountsFail,
      MedicationTaskAmountsApiActions.getMedicationTaskAmountsFail,
      MedicationTaskAmountsApiActions.fetchMedicationTaskAmountFail,
      MedicationTaskAmountsApiActions.createMedicationTaskAmountFail,
      MedicationTaskAmountsApiActions.updateMedicationTaskAmountFail,
      MedicationTaskAmountsApiActions.deleteMedicationTaskAmountFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicationTaskAmountsWsActions.medicationTaskAmountCreated,
      MedicationTaskAmountsWsActions.medicationTaskAmountPatched,
      (state, action) => {
        return medicationTaskAmountsAdapter.upsertOne(
          action.medicationTaskAmount,
          {
            ...state
          }
        );
      }
    ),

    on(
      MedicationTaskAmountsWsActions.medicationTaskAmountRemoved,
      (state, action) => {
        return medicationTaskAmountsAdapter.removeOne(action.id, {
          ...state
        });
      }
    ),

    on(
      MedicationTaskAmountsGeneralActions.addMedicationTaskAmounts,
      (state, action) => {
        return medicationTaskAmountsAdapter.upsertMany(
          action.medicationTaskAmounts,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      MedicationTaskAmountsGeneralActions.clearMedicationTaskAmounts,
      (state) => {
        return medicationTaskAmountsAdapter.removeAll({
          ...state,
          total: 0,
          skip: 0,
          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
