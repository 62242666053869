import { CreatePacketFromTemplateModalActions } from '$/app/pages/documents/create-packet-from-template/create-packet-from-template.actions';
import { DocumentPacketFormPageActions } from '$/app/pages/documents/document-packet-form/document-packet-form.actions';
import { ResidentDocumentPacketsPageActions } from '$/app/pages/documents/resident-document-packets/resident-document-packets.actions';
import {
  DocumentPacketsApiService,
  EffectHelpersService
} from '$/app/services';
import { ApiData, getAllPages } from '$/app/utils';
import { IDocumentPacket } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { DocumentPacketsApiActions } from './document-packets.actions';

@Injectable()
export class DocumentPacketsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly documentPacketsService = inject(DocumentPacketsApiService);

  getDocumentPackets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentDocumentPacketsPageActions.getDocumentPackets),
      this.effectHelpers.apiRequest({
        description: 'Get Document Packets',
        onRequest: (action) =>
          this.documentPacketsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.documentPacketsService, action?.params?.query)
            ),
        onSuccess: (documentPackets) => {
          const responseData = new ApiData(
            'documentPackets',
            documentPackets,
            DocumentPacketsApiActions.getDocumentPacketsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          DocumentPacketsApiActions.getDocumentPacketsFail({ error })
      })
    );
  });

  createDocumentPacket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentPacketFormPageActions.createDocumentPacket),
      this.effectHelpers.apiRequest({
        description: 'creating document packet',
        onRequest: (action) =>
          this.documentPacketsService.create(
            action.documentPacket,
            action.params
          ),
        useMapOperator: 'exhaustMap',
        onSuccess: (documentPacket) =>
          DocumentPacketsApiActions.createDocumentPacketSuccess({
            documentPacket
          }),
        onError: (error) =>
          DocumentPacketsApiActions.createDocumentPacketFail({ error })
      })
    );
  });

  createDocumentPackets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreatePacketFromTemplateModalActions.createDocumentPackets),
      this.effectHelpers.apiRequest({
        description: 'creating multiple document packets',
        onRequest: (action) =>
          this.documentPacketsService.create(
            action.documentPackets,
            action.params
          ) as Observable<IDocumentPacket[]>,
        useMapOperator: 'exhaustMap',
        onSuccess: (documentPackets: IDocumentPacket[]) =>
          DocumentPacketsApiActions.createDocumentPacketsSuccess({
            documentPackets
          }),
        onError: (error) =>
          DocumentPacketsApiActions.createDocumentPacketsFail({ error })
      })
    );
  });

  updateDocumentPacket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentPacketFormPageActions.updateDocumentPacket),
      this.effectHelpers.apiRequest({
        description: 'Update Document Packet',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.documentPacketsService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (documentPacket) =>
          DocumentPacketsApiActions.updateDocumentPacketSuccess({
            documentPacket
          }),
        onError: (error) =>
          DocumentPacketsApiActions.updateDocumentPacketFail({ error })
      })
    );
  });

  deleteDocumentPacket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentDocumentPacketsPageActions.deleteDocumentPacket),
      this.effectHelpers.apiRequest({
        description: 'Delete Document Packet',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.documentPacketsService.delete(action.id, action.params);
        },
        onSuccess: (documentPacket) =>
          DocumentPacketsApiActions.deleteDocumentPacketSuccess({
            id: documentPacket.id
          }),
        onError: (error) =>
          DocumentPacketsApiActions.deleteDocumentPacketFail({ error })
      })
    );
  });
}
