import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { DashboardPrnMedicationsPageActions } from '$/app/pages/medications/dashboard/prn-medications/prn-medications.actions';
import { PrnMarDetailPageActions } from '$/app/pages/medications/residents/prn-mar-detail/prn-mar-detail.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  PrnFormActions,
  PrnsApiActions,
  PrnsGeneralActions,
  PrnsListActions,
  PrnsWsActions
} from './actions';
import { State, initialState, prnsAdapter } from './prns.state';

const reducer = createReducer(
  initialState,

  on(
    PrnsListActions.loadPrns,
    PrnsListActions.getPrns,
    PrnMarDetailPageActions.fetchPrn,
    PrnFormActions.addPrn,
    PrnMarDetailPageActions.deletePrn,
    GivePrnFormActions.getPreviousPrns,
    DashboardPrnMedicationsPageActions.getDashboardPrnMedicationsPageInfo,
    DashboardPrnMedicationsPageActions.createPrn,
    DashboardPrnMedicationsPageActions.updatePrn,
    DashboardPrnMedicationsPageActions.clearPrnResponse,
    DashboardPrnMedicationsPageActions.deletePrn,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(DashboardPrnMedicationsPageActions.updatePrn, (state, action) => {
    return prnsAdapter.updateOne(action, {
      ...state,
      loading: true,
      error: null
    });
  }),

  on(PrnsApiActions.loadPrnsSuccess, (state, action) => {
    return prnsAdapter.setAll(action.prns.data, {
      ...state,
      total: action.prns.total,
      skip: action.prns.skip,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PrnsApiActions.getPrnsSuccess, (state, action) => {
    return prnsAdapter.upsertMany(action.prns.data, {
      ...state,
      total: action.prns.total,
      skip: action.prns.skip,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PrnsApiActions.fetchPrnSuccess, (state, action) => {
    return prnsAdapter.upsertOne(action.prn, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PrnsApiActions.deletePrnSuccess, (state, action) => {
    return prnsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    PrnsApiActions.loadPrnsFail,
    PrnsApiActions.getPrnsFail,
    PrnsApiActions.fetchPrnFail,
    PrnsApiActions.createPrnFail,
    PrnsApiActions.updatePrnFail,
    PrnsApiActions.deletePrnFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(PrnsWsActions.prnsCreated, PrnsWsActions.prnsPatched, (state, action) => {
    return prnsAdapter.upsertOne(action.prn, {
      ...state
    });
  }),

  on(PrnsWsActions.prnsRemoved, (state, action) => {
    return prnsAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(PrnsGeneralActions.addPrns, (state, action) => {
    return prnsAdapter.upsertMany(action.prns, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(PrnsGeneralActions.clearPrns, (state) => {
    return prnsAdapter.removeAll({
      ...state,
      total: 0,
      skip: 0,
      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function prnsReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
