import { IIncidentReportResident } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const IncidentReportResidentsGeneralActions = createActionGroup({
  source: 'Incident Report Residents General',
  events: {
    'Clear Incident Report Residents': emptyProps(),
    'Add Incident Report Residents': props<{
      incidentReportResidents: IIncidentReportResident[];
    }>()
  }
});
