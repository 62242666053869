import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const ResidentGroupPageRouteActions = createActionGroup({
  source: 'Resident Groups Page Route',
  events: {
    'Load Resident Groups': (params: Params = { query: {} }) => ({
      params
    })
  }
});
