import { CareTask } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const careTasksAdapter = createEntityAdapter<CareTask>();

export interface State extends EntityState<CareTask> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = careTasksAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
