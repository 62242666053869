import * as ResidentContactsActions from '$/app/store/resident-contacts/actions';
import { ResidentContactsStoreModule } from '$/app/store/resident-contacts/resident-contacts-store.module';
import * as ResidentContactsSelectors from '$/app/store/resident-contacts/resident-contacts.selectors';
import * as ResidentContactsState from '$/app/store/resident-contacts/resident-contacts.state';

export {
  ResidentContactsActions,
  ResidentContactsSelectors,
  ResidentContactsState,
  ResidentContactsStoreModule
};
