import { Update } from '$/models';
import { IPositionLog } from '$shared/services/position-log';
import { createActionGroup, props } from '@ngrx/store';

export const PositionLogFormPageActions = createActionGroup({
  source: 'Position Log Form Page',
  events: {
    'Create Position Log': props<{ positionLog: IPositionLog }>(),
    'Update Position Log': props<Update<IPositionLog>>()
  }
});
