import { ResidentGroupMember } from '$/models/data/entities/residents/resident-group-member.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const residentGroupMembersAdapter =
  createEntityAdapter<ResidentGroupMember>();

export interface State extends EntityState<ResidentGroupMember> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = residentGroupMembersAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
