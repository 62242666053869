import { isNil } from 'lodash';
import { MeasurementId } from '../lookups/measurements.lookup';
import { MedicationFormId } from '../lookups/medication-forms.lookup';
import { DoseValue, isOptionDose, isRangedDose, isValueDose } from './doses';
import { formatAmount } from './format-amount';
import { AmountDisplayOptions } from './stringify-amount';

export function stringifyDose(
  dose: DoseValue | null | undefined,
  measurementId?: MeasurementId | null,
  formId?: MedicationFormId | null,
  { showMeasurement = 'all', includeSpacing = false }: AmountDisplayOptions = {}
): string {
  const options = {
    showMeasurement,
    includeSpacing
  };

  if (isNil(dose)) {
    return '';
  }

  if (isOptionDose(dose)) {
    return dose
      .map((dose) => formatAmount(dose, measurementId, formId, options))
      .join(', ');
  }

  if (isValueDose(dose)) {
    return formatAmount(dose.value, measurementId, formId, options);
  }

  if (isRangedDose(dose)) {
    const min = formatAmount(dose.min, measurementId, formId, options);
    const max = formatAmount(dose.max, measurementId, formId, options);

    return `${min} to ${max}`;
  }

  return 'Record log for dose';
}
