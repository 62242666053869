import { sortByDatetime } from '$/app/utils';
import { MedicalEvent } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicalEventsAdapter = createEntityAdapter<MedicalEvent>({
  sortComparer: sortByDatetime('occurredAt', true)
});

export interface State extends EntityState<MedicalEvent> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicalEventsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
