import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { MedicationMarsApiService } from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MedicationMarsApiActions } from './medication-mars.actions';

@Injectable()
export class MedicationMarsEffects {
  private readonly actions$ = inject(Actions);
  private readonly medicationMarsService = inject(MedicationMarsApiService);

  patchMedicationMar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoutineMarDetailPageActions.changeMARPerformedBy),
      switchMap((action) => {
        return this.medicationMarsService.patch(action.id, action.changes).pipe(
          map((medicationMar) =>
            MedicationMarsApiActions.patchMedicationMarSuccess({
              medicationMar
            })
          ),
          catchError((error) =>
            of(MedicationMarsApiActions.patchMedicationMarFail({ error }))
          )
        );
      })
    );
  });
}
