import { MedicationsSelectors } from '$/app/store/medications/medications.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter, groupBy } from 'lodash';
import {
  State,
  medicationSchedulesAdapter
} from './medication-schedules.state';

const selectMedicationSchedulesState = createFeatureSelector<State>(
  'medicationSchedules'
);

const { selectAll, selectEntities } = medicationSchedulesAdapter.getSelectors(
  selectMedicationSchedulesState
);

const selectAllActive = createSelector(selectAll, (schedules) =>
  schedules.filter((schedule) => schedule.isActive)
);

const selectActiveGroupedBy = (field: string) =>
  createSelector(selectAllActive, (activeSchedules) =>
    groupBy(activeSchedules, field)
  );

// Select all schedules for the latest medication in selected batch
const selectCurrentMedicationSchedules = createSelector(
  MedicationsSelectors.selectMedication,
  selectAllActive,
  (medication, schedules) => filter(schedules, { medicationId: medication?.id })
);

export const MedicationSchedulesSelectors = {
  selectAll,
  selectEntities,
  selectActiveGroupedBy,
  selectCurrentMedicationSchedules
};
