import { IDocumentPacketDocument, Paginated } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DocumentPacketDocumentsApiActions = createActionGroup({
  source: 'Document Packet Documents API',
  events: {
    // Success Actions
    'Load Document Packet Documents Success': props<{
      documentPacketDocuments: Paginated<IDocumentPacketDocument>;
    }>(),
    'Get Document Packet Documents Success': props<{
      documentPacketDocuments: Paginated<IDocumentPacketDocument>;
    }>(),
    'Fetch Document Packet Document Success': props<{
      documentPacketDocument: IDocumentPacketDocument;
    }>(),
    'Create Document Packet Document Success': props<{
      documentPacketDocument: IDocumentPacketDocument;
    }>(),
    'Update Document Packet Document Success': props<{
      documentPacketDocument: IDocumentPacketDocument;
    }>(),
    'Delete Document Packet Document Success': props<{ id: string }>(),
    // Fail Actions
    'Load Document Packet Documents Fail': props<{ error: Error }>(),
    'Get Document Packet Documents Fail': props<{ error: Error }>(),
    'Fetch Document Packet Document Fail': props<{ error: Error }>(),
    'Create Document Packet Document Fail': props<{ error: Error }>(),
    'Update Document Packet Document Fail': props<{ error: Error }>(),
    'Delete Document Packet Document Fail': props<{ error: Error }>()
  }
});

export const DocumentPacketDocumentsWsActions = createActionGroup({
  source: 'Document Packet Documents WS',
  events: {
    'Document Packet Document Created': props<{
      documentPacketDocument: IDocumentPacketDocument;
    }>(),
    'Document Packet Document Patched': props<{
      documentPacketDocument: IDocumentPacketDocument;
    }>(),
    'Document Packet Document Removed': props<{ id: string }>()
  }
});

export const DocumentPacketDocumentsGeneralActions = createActionGroup({
  source: 'Document Packet Documents General',
  events: {
    'Clear Document Packet Documents': emptyProps(),
    'Add Document Packet Documents': props<{
      documentPacketDocuments: IDocumentPacketDocument[];
    }>()
  }
});
