import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { SeizureLog } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const seizureLogsAdapter = createEntityAdapter<SeizureLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<SeizureLog> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = seizureLogsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
