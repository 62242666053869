<button
  mat-icon-button
  aria-label="Notification menu button"
  alcMenuButtonToggle="notifications"
  class="{{ 'theme' | alcTheme }}"
>
  <mat-icon
    [matBadge]="count()"
    aria-hidden="false"
    matBadgePosition="above after"
    [matBadgeHidden]="!count() || count() === '0'"
    matBadgeColor="accent"
    svgIcon="notifications"
  />
</button>
