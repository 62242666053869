import { sortByDatetime } from '$/app/utils/sorting/sort-by-datetime';
import { IAppointment } from '$shared/appointments/appointments.interface';
import { createEntityAdapter } from '@ngrx/entity';

export const appointmentsAdapter = createEntityAdapter<IAppointment>({
  sortComparer: sortByDatetime('startAt', false)
});

export const initialState = appointmentsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
