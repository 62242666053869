import { FeathersService } from '$/app/services/feathers.service';
import { ResidentContactPhonesApiActions } from '$/app/store/resident-contact-phones';
import { ResidentContactPhone } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class ResidentContactPhonesApiService extends AbstractApiService<ResidentContactPhone> {
  constructor(feathers: FeathersService, store: Store) {
    super('resident-contact-phones', feathers, store, {
      entityName: 'residentContactPhone',
      created: ResidentContactPhonesApiActions.residentContactPhonesCreated,
      patched: ResidentContactPhonesApiActions.residentContactPhonesPatched,
      removed: ResidentContactPhonesApiActions.residentContactPhonesRemoved
    });
  }
}
