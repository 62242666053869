import { Params } from '$/models/general/ngrx.interface';
import { IAppointment } from '$shared/appointments/appointments.interface';
import { createActionGroup, props } from '@ngrx/store';

export const AppointmentsGeneralActions = createActionGroup({
  source: 'Appointments General',
  events: {
    'Add Appointments': props<{ appointments: IAppointment[] }>(),
    'Clear Appointments': (params: Params = { query: {} }) => ({ params })
  }
});
