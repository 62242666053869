import { FeathersService } from '$/app/services/feathers.service';
import { LibraryCollectionDocumentTemplatePacketsWsActions } from '$/app/store/library-collection-document-template-packets';
import { ILibraryCollectionDocumentTemplatePacket } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class LibraryCollectionDocumentTemplatePacketsApiService extends AbstractApiService<ILibraryCollectionDocumentTemplatePacket> {
  constructor(feathers: FeathersService, store: Store) {
    super('library-collection-document-templates', feathers, store, {
      entityName: 'libraryCollectionDocumentTemplatePacket',
      created:
        LibraryCollectionDocumentTemplatePacketsWsActions.libraryCollectionDocumentTemplatePacketCreated,
      patched:
        LibraryCollectionDocumentTemplatePacketsWsActions.libraryCollectionDocumentTemplatePacketPatched,
      removed:
        LibraryCollectionDocumentTemplatePacketsWsActions.libraryCollectionDocumentTemplatePacketRemoved
    });
  }
}
