import { Prn } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥🔄 BACKEND WS ACTIONS
//

// [WS] Prns Created
export const prnsCreated = createAction(
  '[Prns WS] Prns Created',
  props<{ prn: Prn }>()
);
// [WS] Prns Patched
export const prnsPatched = createAction(
  '[Prns WS] Prns Patched',
  props<{ prn: Prn }>()
);
// [WS] Prns Removed
export const prnsRemoved = createAction(
  '[Prns WS] Prns Removed',
  props<{ id: string }>()
);
