import {
  EffectHelpersService,
  MedicalProfessionalPhonesApiService
} from '$/app/services';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MedicalProfessionalPhonesApiActions } from './actions';

@Injectable()
export class MedicalProfessionalPhonesEffects {
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);
  // NOTE: Even though this is not used, it still needs to be here so that Angular
  // will instantiate the service and setup websocket event handling
  private readonly medicalProfessionalPhonesService = inject(
    MedicalProfessionalPhonesApiService
  );

  createMedicalProfessionalPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicalProfessionalPhonesApiActions.createMedicalProfessionalPhoneFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateMedicalProfessionalPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicalProfessionalPhonesApiActions.updateMedicalProfessionalPhoneFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteMedicalProfessionalPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicalProfessionalPhonesApiActions.deleteMedicalProfessionalPhoneFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
  // TODO: deal with this when adding the archive functionality

  // deleteMedicalProfessionalPhone$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(
  //         MedicalProfessionalInfoPageActions.deleteMedicalProfessionalPhone
  //       ),
  //       exhaustMap((action) => {
  //         return this.medicalProfessionalPhonesService
  //           .delete(action.id, action.params)
  //           .pipe(
  //             tap(
  //               this.effectHelpers.onModalFormSubmitSuccess(
  //                 'MedicalProfessionalPhone deleted successfully!'
  //               )
  //             ),
  //             catchError((error) => {
  //               this.store.dispatch(
  //                 MedicalProfessionalPhonesApiActions.deleteMedicalProfessionalPhoneFail(
  //                   { error }
  //                 )
  //               );
  //               return EMPTY;
  //             })
  //           );
  //       })
  //     );
  //   },
  //   { dispatch: false }
  // );

  // updateMedicalProfessionalPhone$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(
  //         MedicalProfessionalInfoPageActions.updateMedicalProfessionalPhone
  //       ),
  //       switchMap((action) => {
  //         return this.medicalProfessionalPhonesService
  //           .patch(action.id, action.changes)
  //           .pipe(
  //             tap(
  //               this.effectHelpers.onPopFormSubmitSuccess(
  //                 'Phone updated successfully!'
  //               )
  //             ),
  //             catchError((error) => {
  //               this.store.dispatch(
  //                 MedicalProfessionalPhonesApiActions.updateMedicalProfessionalPhoneFail(
  //                   { error }
  //                 )
  //               );
  //               return EMPTY;
  //             })
  //           );
  //       })
  //     );
  //   },
  //   { dispatch: false }
  // );

  // createMedicalProfessionalPhone$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(
  //         MedicalProfessionalInfoPageActions.createMedicalProfessionalPhone
  //       ),
  //       exhaustMap((action) => {
  //         return this.medicalProfessionalPhonesService
  //           .create(action.medicalProfessionalPhone)
  //           .pipe(
  //             tap(
  //               this.effectHelpers.onPopFormSubmitSuccess(
  //                 'Phone created successfully!'
  //               )
  //             ),
  //             catchError((error) => {
  //               this.store.dispatch(
  //                 MedicalProfessionalPhonesApiActions.createMedicalProfessionalPhoneFail(
  //                   { error }
  //                 )
  //               );
  //               return EMPTY;
  //             })
  //           );
  //       })
  //     );
  //   },
  //   { dispatch: false }
  // );
}
