import { IDocumentTemplatePacket, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DocumentTemplatePacketFormPageActions = createActionGroup({
  source: 'Document Template Packet Form Page',
  events: {
    'Create Document Template Packet': props<{
      documentTemplatePacket: IDocumentTemplatePacket & {
        templateIds: string[];
      };
      params: Params;
    }>(),
    'Update Document Template Packet':
      props<Update<IDocumentTemplatePacket & { templateIds: string[] }>>()
  }
});
