import { LogDependency } from './log-dependency';

export interface ValueDose {
  value: string;
}

export class RangedDose {
  min: string;
  max: string;

  constructor(min: string, max: string) {
    this.min = min;
    this.max = max;
  }
}

export type OptionDose = string[];

export type NormalDoseValue = ValueDose | RangedDose | OptionDose;

export type DoseValue = ValueDose | RangedDose | OptionDose | LogDependency;

export function isValueDose(dose: DoseValue): dose is ValueDose {
  return !!(dose && 'value' in dose);
}

export function isRangedDose(dose: DoseValue): dose is RangedDose {
  return !!(dose && 'min' in dose && 'max' in dose);
}

export function isOptionDose(dose: DoseValue): dose is OptionDose {
  return Array.isArray(dose);
}

export function isLogDependency(dose: DoseValue): dose is LogDependency {
  return !!(dose && 'type' in dose);
}

export function isNormalDoseValue(dose: DoseValue): dose is NormalDoseValue {
  return !!(
    dose &&
    ('value' in dose || Array.isArray(dose) || ('min' in dose && 'max' in dose))
  );
}
