import { IIncidentReport, IPaginationInfo } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const incidentReportsAdapter = createEntityAdapter<IIncidentReport>();

export interface State extends EntityState<IIncidentReport> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = incidentReportsAdapter.getInitialState({
  pagination: {},
  loading: false,
  loaded: false,
  error: null
});
