import { FeathersService } from '$/app/services/feathers.service';
import { DocumentTemplatesWsActions } from '$/app/store/document-templates/document-templates.actions';
import { IDocumentTemplate } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class DocumentTemplatesApiService extends AbstractApiService<IDocumentTemplate> {
  constructor(feathers: FeathersService, store: Store) {
    super('document-templates', feathers, store, {
      entityName: 'documentTemplate',
      created: DocumentTemplatesWsActions.documentTemplateCreated,
      patched: DocumentTemplatesWsActions.documentTemplatePatched,
      removed: DocumentTemplatesWsActions.documentTemplateRemoved
    });
  }
}
