import { AlcomyRouterState } from '$/app/store/router/custom-route-serializer';
import { MaybeArray } from '$shared/types/utility-types';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { castArray, isString, some } from 'lodash';
import { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export function ofRoute(
  route: MaybeArray<string | RegExp>
): OperatorFunction<
  RouterNavigationAction<AlcomyRouterState>,
  RouterNavigationAction<AlcomyRouterState>
> {
  return filter((action) => {
    if (action.type !== ROUTER_NAVIGATION) {
      return false;
    }

    const configPath = action.payload.routerState.routeConfigPath;

    return isMatchingRoute(castArray(route), configPath);
  });
}

export function isMatchingRoute(
  routes: (string | RegExp)[],
  path: string
): boolean {
  return (
    path &&
    some(
      routes,
      (route) =>
        (isString(route) && route === path) ||
        (route instanceof RegExp && route.test(path))
    )
  );
}
