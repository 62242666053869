import { createSelector } from '@ngrx/store';
import { residentInsurancesFeature } from './resident-insurances.reducer';
import { residentInsurancesAdapter } from './resident-insurances.state';

export const {
  selectResidentInsurancesState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = residentInsurancesFeature;

export const { selectAll, selectTotal } =
  residentInsurancesAdapter.getSelectors(selectResidentInsurancesState);

export const selectPagination = (query) => {
  return createSelector(selectResidentInsurancesState, (state) => {
    return state[query];
  });
};

export const entitySelectors = residentInsurancesAdapter.getSelectors(
  selectResidentInsurancesState
);
