import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FacilityPhonesEffects } from './facility-phones.effects';
import { facilityPhonesFeature } from './facility-phones.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(facilityPhonesFeature),
    EffectsModule.forFeature([FacilityPhonesEffects])
  ],
  providers: [FacilityPhonesEffects]
})
export class FacilityPhonesStoreModule {}
