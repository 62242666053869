import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { DashboardPrnMedicationsPageActions } from '$/app/pages/medications/dashboard/prn-medications/prn-medications.actions';
import { PrnMarDetailPageActions } from '$/app/pages/medications/residents/prn-mar-detail/prn-mar-detail.actions';
import { PrnMarListPageActions } from '$/app/pages/medications/residents/prn-mar-list/prn-mar-list.actions';
import { EffectHelpersService, PrnsApiService } from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PrnsApiActions, PrnsListActions } from './actions';

@Injectable()
export class PrnsEffects {
  private readonly actions$ = inject(Actions);
  private readonly prnsService = inject(PrnsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadPrns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrnsListActions.loadPrns, PrnMarListPageActions.loadPrns),
      this.effectHelpers.apiRequest({
        description: 'Load PRNs',
        onRequest: (action) =>
          this.prnsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.prnsService, action?.params?.query),
              reducePaginatedResponses()
            ),
        onSuccess: (prns) => {
          const responseData = new ApiData(
            'prns',
            prns,
            PrnsApiActions.loadPrnsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => PrnsApiActions.loadPrnsFail({ error })
      })
    );
  });

  getPrns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        PrnsListActions.getPrns,
        PrnMarListPageActions.getPrns,
        GivePrnFormActions.getPreviousPrns,
        DashboardPrnMedicationsPageActions.getDashboardPrnMedicationsPageInfo
      ),
      this.effectHelpers.apiRequest({
        description: 'Get PRNs',
        onRequest: (action) =>
          this.prnsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.prnsService, action?.params?.query),
              reducePaginatedResponses()
            ),
        onSuccess: (prns) => {
          const responseData = new ApiData(
            'prns',
            prns,
            PrnsApiActions.getPrnsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => PrnsApiActions.getPrnsFail({ error })
      })
    );
  });

  fetchPrn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrnMarDetailPageActions.fetchPrn),
      this.effectHelpers.apiRequest({
        description: 'Get PRNs',
        onRequest: (action) => this.prnsService.get(action.id, action.params),
        onSuccess: (prns) => {
          const responseData = new ApiData(
            'prns',
            prns,
            PrnsApiActions.fetchPrnSuccess,
            {
              payloadKey: 'prn'
            }
          );

          return responseData.getActions();
        },
        onError: (error) => PrnsApiActions.fetchPrnFail({ error })
      })
    );
  });

  createPrn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardPrnMedicationsPageActions.createPrn),
      this.effectHelpers.apiRequest({
        description: 'Create PRN',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.prnsService.create(action.prn, action.params),
        onSuccess: (prn) => PrnsApiActions.createPrnSuccess({ prn }),
        onError: (error) => PrnsApiActions.createPrnFail({ error })
      })
    );
  });

  updatePrn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardPrnMedicationsPageActions.updatePrn,
        DashboardPrnMedicationsPageActions.clearPrnResponse,
        PrnMarListPageActions.updatePrn,
        PrnMarDetailPageActions.updatePrn
      ),
      this.effectHelpers.apiRequest({
        description: 'Update PRN',
        onRequest: (action) =>
          this.prnsService.patch(action.id, action.changes, action.params),
        onSuccess: (prn) => PrnsApiActions.updatePrnSuccess({ prn }),
        onError: (error) => PrnsApiActions.updatePrnFail({ error })
      })
    );
  });

  deletePrn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardPrnMedicationsPageActions.deletePrn,
        PrnMarDetailPageActions.deletePrn
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete PRN',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.prnsService.delete(action.id, action.params),
        onSuccess: ({ id }) => PrnsApiActions.deletePrnSuccess({ id }),
        onError: (error) => PrnsApiActions.deletePrnFail({ error })
      })
    );
  });
}
