import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { RoomsWsActions } from '$/app/store/rooms/rooms.actions';
import { Room } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class RoomsApiService extends AbstractApiService<Room> {
  constructor(feathers: FeathersService, store: Store) {
    super('rooms', feathers, store, {
      entityName: 'room',
      created: RoomsWsActions.roomsCreated,
      patched: RoomsWsActions.roomsPatched,
      removed: RoomsWsActions.roomsRemoved
    });
  }
}
