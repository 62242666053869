import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { PositionLogsPageActions } from '$/app/pages/notebook/shared/logs/position-log/position-log.actions';
import { EffectHelpersService, PositionLogsApiService } from '$/app/services';
import { PositionLogFormPageActions } from '$/app/shared/pages/forms/log-forms/position-log-form/position-log-form.actions';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { IPositionLog } from '$shared/services/position-log';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { PositionLogsApiActions } from './actions';

@Injectable()
export class PositionLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly positionLogsService = inject(PositionLogsApiService);

  loadPositionLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PositionLogsPageActions.loadPositionLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.positionLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('positionLogs', logs);
            responseData.setPrimaryAction(
              PositionLogsApiActions.loadPositionLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getPositionLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        PositionLogsPageActions.getPositionLogs,
        LogsPageActions.getPositionLogs,
        GivePrnFormActions.getPositionLogs
      ),
      switchMap((action) => {
        return this.positionLogsService.getAll(action.params).pipe(
          getAllPages(this.positionLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('positionLogs', logs);
            responseData.setPrimaryAction(
              PositionLogsApiActions.getPositionLogsSuccess
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createPositionLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PositionLogFormPageActions.createPositionLog),
      exhaustMap((action) => {
        return this.positionLogsService.create(action.positionLog).pipe(
          tap((data: IPositionLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((positionLog: IPositionLog) =>
            PositionLogsApiActions.createPositionLogSuccess({
              positionLog
            })
          ),
          catchError((error) =>
            of(
              PositionLogsApiActions.createPositionLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updatePositionLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PositionLogFormPageActions.updatePositionLog),
      switchMap((action) => {
        return this.positionLogsService.patch(action.id, action.changes).pipe(
          tap((data: IPositionLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((positionLog: IPositionLog) => {
            return PositionLogsApiActions.updatePositionLogSuccess({
              positionLog
            });
          }),
          catchError((error) => {
            return of(
              PositionLogsApiActions.updatePositionLogFail({
                error
              })
            );
          })
        );
      })
    );
  });

  deletePositionLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        PositionLogsPageActions.deletePositionLog,
        ResidentMedicationTasksPageActions.deletePositionLog,
        MissedMedicationsPageActions.deletePositionLog,
        RoutineMarDetailPageActions.deletePositionLog,
        RoutineMarListPageActions.deletePositionLog,
        GivePrnFormActions.deletePositionLog,
        CompleteCareTaskModalActions.deletePositionLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete position log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.positionLogsService.delete(action.id, action.params);
        },
        onSuccess: (positionLog: IPositionLog) =>
          PositionLogsApiActions.deletePositionLogSuccess({
            id: positionLog.id
          }),
        onError: (error) =>
          PositionLogsApiActions.deletePositionLogFail({ error })
      })
    );
  });
}
