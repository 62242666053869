import { DocumentDetailsFormPageActions } from '$/app/pages/documents/document-details/document-details-form/document-details-form.actions';
import { DocumentDetailsInfoPageActions } from '$/app/pages/documents/document-details/document-details-info/document-details-info.actions';
import { DocumentsOverviewPageActions } from '$/app/pages/documents/documents-overview/documents-overview.actions';
import { DocumentsPortalFormShellPageActions } from '$/app/pages/documents/documents-portal-form-shell/documents-portal-form-shell.page.actions';
import { DocumentsPortalOverviewPageActions } from '$/app/pages/documents/documents-portal-overview/documents-portal-overview.actions';
import { ResidentDocumentPacketsPageActions } from '$/app/pages/documents/resident-document-packets/resident-document-packets.actions';
import { ResidentDocumentTrackingPageActions } from '$/app/pages/documents/resident-document-tracking/resident-document-tracking.actions';
import { getPaginationInfo } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  DocumentsApiActions,
  DocumentsGeneralActions,
  DocumentsWsActions
} from './documents.actions';
import { documentsAdapter, initialState } from './documents.state';

export const documentsFeature = createFeature({
  name: 'documents',
  reducer: createReducer(
    initialState,
    on(
      DocumentsOverviewPageActions.getDocuments,
      ResidentDocumentTrackingPageActions.loadDocuments,
      DocumentDetailsInfoPageActions.fetchDocument,
      DocumentsPortalOverviewPageActions.loadDocuments,
      DocumentsPortalFormShellPageActions.fetchDocument,
      ResidentDocumentTrackingPageActions.createDocument,
      ResidentDocumentTrackingPageActions.createDocumentFromTemplate,
      ResidentDocumentPacketsPageActions.createDocumentFromTemplate,
      DocumentsPortalFormShellPageActions.updateDocumentForm,
      DocumentDetailsFormPageActions.updateDocumentForm,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(DocumentsApiActions.loadDocumentsSuccess, (state, { documents }) => {
      const pagination = getPaginationInfo(documents);

      return documentsAdapter.setAll(documents.data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(DocumentsApiActions.getDocumentsSuccess, (state, { documents }) => {
      const pagination = getPaginationInfo(documents);

      return documentsAdapter.upsertMany(documents.data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(DocumentsApiActions.fetchDocumentSuccess, (state, { document }) => {
      return documentsAdapter.upsertMany(castArray(document), {
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }),

    on(DocumentsApiActions.createDocumentSuccess, (state, action) => {
      return documentsAdapter.addOne(action.document, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(DocumentsApiActions.updateDocumentSuccess, (state, action) => {
      return documentsAdapter.upsertOne(action.document, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(DocumentsApiActions.deleteDocumentSuccess, (state, action) =>
      documentsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      DocumentsApiActions.loadDocumentsFail,
      DocumentsApiActions.getDocumentsFail,
      DocumentsApiActions.fetchDocumentFail,
      DocumentsApiActions.createDocumentFail,
      DocumentsApiActions.updateDocumentFail,
      DocumentsApiActions.deleteDocumentFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      DocumentsWsActions.documentCreated,
      DocumentsWsActions.documentPatched,
      (state, action) => {
        return documentsAdapter.upsertOne(action.document, state);
      }
    ),

    on(DocumentsWsActions.documentRemoved, (state, action) => {
      return documentsAdapter.removeOne(action.id, state);
    }),

    on(DocumentsGeneralActions.addDocuments, (state, action) => {
      return documentsAdapter.upsertMany(action.documents, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(DocumentsGeneralActions.clearDocuments, () => {
      return documentsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
