import { Acknowledgement, Update } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AcknowledgementsApiActions = createActionGroup({
  source: 'Acknowledgements API',
  events: {
    'Load Acknowledgements Success': props<{
      acknowledgements: Acknowledgement[];
    }>(),
    'Update Acknowledgement Success': props<{
      acknowledgement: Update<Acknowledgement>;
    }>(),
    'Load Acknowledgements Fail': props<{ error: Error }>(),
    'Update Acknowledgement Fail': props<{ error: Error }>()
  }
});

export const AcknowledgementsWsActions = createActionGroup({
  source: 'Acknowledgements WS',
  events: {
    'Acknowledgements Created': props<{ acknowledgement: Acknowledgement }>(),
    'Acknowledgements Patched': props<{ acknowledgement: Acknowledgement }>(),
    'Acknowledgements Removed': props<{ id: string }>()
  }
});

export const AcknowledgementsGeneralActions = createActionGroup({
  source: 'Acknowledgements General',
  events: {
    'Clear Acknowledgements': emptyProps(),
    'Add Acknowledgements': props<{ acknowledgements: Acknowledgement[] }>()
  }
});
