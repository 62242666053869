import { Params } from '$/models';
import { IResident } from '$shared/residents/residents.interface';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentsGeneralActions = createActionGroup({
  source: 'Residents General',
  events: {
    'Clear Residents': (params: Params = { query: {} }) => ({ params }),

    'Add Residents': props<{ residents: IResident[] }>()
  }
});
