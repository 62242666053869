import { AlcCommonModule } from '$/app/shared/alc-common.module';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'alc-info-card',
  standalone: true,
  imports: [AlcCommonModule],
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class AlcInfoCardComponent {
  @Input() title: string;
  @Input() showButton: boolean = true;
  @Input() icon: string = 'more_vert';
  @Input() buttonTestId: string;
  @Input() cardClasses: string;

  @Output() buttonClicked = new EventEmitter<MouseEvent>();

  protected onButtonClick(ev: MouseEvent) {
    this.buttonClicked.emit(ev);
  }

  protected setIconPath() {
    return `/assets/icon/material/${this.icon}.svg`;
  }
}
