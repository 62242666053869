import * as RouterSelectors from '$/app/store/router/router.selectors';
import { Logger } from '$shared/logger';
import { createSelector } from '@ngrx/store';
import { filter, groupBy } from 'lodash';
import { medicalProfessionalPhonesFeature } from './medical-professional-phones.reducer';
import { medicalProfessionalPhonesAdapter } from './medical-professional-phones.state';

export const { selectEntities, selectMedicalProfessionalPhonesState } =
  medicalProfessionalPhonesFeature;

export const { selectAll } = medicalProfessionalPhonesAdapter.getSelectors(
  selectMedicalProfessionalPhonesState
);

/**
 * Selects all phones for a given medical professional with the given id or the id from the router
 */
export const selectMedicalProfessionalPhones = (
  medicalProfessionalId?: string
) =>
  createSelector(
    selectAll,
    RouterSelectors.selectParam('medicalProfessionalId'),
    (phones, idFromRouter) => {
      medicalProfessionalId ??= idFromRouter;

      if (!medicalProfessionalId) {
        Logger.error('medicalProfessionalId is required');
      }

      return filter(phones, { medicalProfessionalId });
    }
  );

export const selectAllGroupedByMedicalProfessionalId = createSelector(
  selectAll,
  (phones) => groupBy(phones, 'medicalProfessionalId')
);
