import { DashboardGeneralNotesPageActions } from '$/app/pages/notebook/dashboard/dashboard-general-notes/dashboard-general-notes.page.actions';
import { ShiftNotesListPageActions } from '$/app/pages/notebook/shared/shift-notes/shift-notes-list/shift-notes-list.actions';
import { EffectHelpersService } from '$/app/services';
import { ShiftNotesApiService } from '$/app/services/api/shift-notes.service';
import { ShiftNotesFormPageActions } from '$/app/shared/pages/forms/log-forms/note-log-form/shift-note-form.actions';
import { getAllPages, reducePaginatedResponses } from '$/app/utils';
import { ApiData } from '$/app/utils/api-data';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ShiftNotesApiActions } from './actions';

@Injectable()
export class ShiftNotesEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly shiftNotesService = inject(ShiftNotesApiService);

  onLoadShiftNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ShiftNotesListPageActions.loadShiftNotes,
        ShiftNotesListPageActions.refreshShiftNotes,
        DashboardGeneralNotesPageActions.loadShiftNotes,
        DashboardGeneralNotesPageActions.refreshShiftNotes
      ),
      this.effectHelpers.apiRequest({
        description: 'Loading shift notes',
        onRequest: (action) =>
          this.shiftNotesService
            .getAll(action.params)
            .pipe(
              getAllPages(this.shiftNotesService, action?.params?.query),
              reducePaginatedResponses()
            ),
        onSuccess: (shiftNotes) =>
          new ApiData(
            'shiftNotes',
            shiftNotes,
            ShiftNotesApiActions.loadShiftNotesSuccess
          ).getActions(),
        onError: (error) => ShiftNotesApiActions.loadShiftNotesFail({ error })
      })
    );
  });

  onGetShiftNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardGeneralNotesPageActions.getShiftNotes),
      this.effectHelpers.apiRequest({
        description: 'Getting shift notes',
        onRequest: (action) =>
          this.shiftNotesService
            .getAll(action.params)
            .pipe(
              getAllPages(this.shiftNotesService, action?.params?.query),
              reducePaginatedResponses()
            ),
        onSuccess: (shiftNotes) => {
          const responseData = new ApiData(
            'shiftNotes',
            shiftNotes,
            ShiftNotesApiActions.getShiftNotesSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => ShiftNotesApiActions.getShiftNotesFail({ error })
      })
    );
  });

  onCreateShiftNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ShiftNotesListPageActions.createShiftNote,
        DashboardGeneralNotesPageActions.createShiftNote,
        ShiftNotesFormPageActions.createShiftNote
      ),
      this.effectHelpers.apiRequest({
        description: 'Creating shift note',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.shiftNotesService.create(action.shiftNote, action.params),
        onSuccess: (shiftNote) =>
          ShiftNotesApiActions.createShiftNoteSuccess({ shiftNote }),
        onError: (error) => ShiftNotesApiActions.createShiftNoteFail({ error })
      })
    );
  });

  updateShiftNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardGeneralNotesPageActions.updateShiftNote,
        ShiftNotesFormPageActions.updateShiftNote
      ),
      this.effectHelpers.apiRequest({
        description: 'Updating shift note',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.shiftNotesService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (shiftNote) =>
          ShiftNotesApiActions.updateShiftNoteSuccess({ shiftNote }),
        onError: (error) => ShiftNotesApiActions.updateShiftNoteFail({ error })
      })
    );
  });

  onDeleteShiftNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ShiftNotesListPageActions.deleteShiftNote,
        DashboardGeneralNotesPageActions.deleteShiftNote
      ),
      this.effectHelpers.apiRequest({
        description: 'Deleting shift note',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.shiftNotesService.delete(action.id, action.params),
        onSuccess: ({ id }) =>
          ShiftNotesApiActions.deleteShiftNoteSuccess({ id }),
        onError: (error) => ShiftNotesApiActions.deleteShiftNoteFail({ error })
      })
    );
  });
}
