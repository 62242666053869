import { sortByDatetime } from '$/app/utils';
import { MedicationTask } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationTasksAdapter = createEntityAdapter<MedicationTask>({
  sortComparer: sortByDatetime('scheduledFor')
});

export interface State extends EntityState<MedicationTask> {
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationTasksAdapter.getInitialState({
  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
