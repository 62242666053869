import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RoomsEffects } from '$/app/store/rooms/rooms.effects';
import { roomsReducer } from '$/app/store/rooms/rooms.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('rooms', roomsReducer),
    EffectsModule.forFeature([RoomsEffects])
  ],
  providers: [RoomsEffects]
})
export class RoomsStoreModule {}
