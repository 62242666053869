import { ILibraryDocumentTemplate, IPaginationInfo } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const libraryDocumentTemplatesAdapter =
  createEntityAdapter<ILibraryDocumentTemplate>();

export interface State extends EntityState<ILibraryDocumentTemplate> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  libraryDocumentTemplatesAdapter.getInitialState({
    pagination: {},
    loading: false,
    loaded: false,
    error: null
  });
