import { NotificationMenuPageActions } from '$/app/pages/facility-workspace/notification-menu/notification-menu.actions';
import { EffectHelpersService } from '$/app/services';
import { NotificationsApiService } from '$/app/services/api/notifications.service';
import {
  NotificationsApiActions,
  NotificationsPushActions
} from '$/app/store/notifications/actions';
import { getAllPages, reducePaginatedResponses } from '$/app/utils';
import { IPaginatedResponse } from '$/models';
import { IHydratedNotification } from '$shared/notifications';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class NotificationsEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly notificationsService = inject(NotificationsApiService);

  loadNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        NotificationMenuPageActions.loadNotifications,
        NotificationMenuPageActions.refreshNotifications,
        NotificationMenuPageActions.pullRefreshNotifications
      ),
      switchMap((action) => {
        return this.notificationsService.getAll(action.params).pipe(
          getAllPages(this.notificationsService, action?.params?.query),
          reducePaginatedResponses(),
          tap(() => {
            this.effectHelpers.handleParamsOnSuccess(action.params);
          }),
          map((page: IPaginatedResponse<IHydratedNotification>) => {
            return NotificationsApiActions.loadNotificationsSuccess({
              notifications: page.data
            });
          }),
          catchError((error) => {
            return of(NotificationsApiActions.loadNotificationsFail({ error }));
          })
        );
      })
    );
  });

  updateNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          NotificationMenuPageActions.archiveNotification,
          NotificationMenuPageActions.archiveAllNotifications,
          NotificationMenuPageActions.readNotification,
          NotificationMenuPageActions.readNotifications
        ),
        switchMap((action) => {
          return this.notificationsService
            .patch(action.id, action.changes, action.params)
            .pipe(
              map((notification) => {
                return NotificationsApiActions.updateNotificationSuccess({
                  notification
                });
              }),
              catchError((error) => {
                this.store.dispatch(
                  NotificationsApiActions.updateNotificationFail({ error })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  pushNotificationReceived$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationsPushActions.notificationReceived),
        switchMap((action) => {
          return this.notificationsService.get(action.data.id).pipe(
            map((notification) => {
              this.store.dispatch(
                NotificationsApiActions.getNotificationSuccess({
                  notification: Array.isArray(notification)
                    ? notification[0]
                    : notification
                })
              );
            }),
            catchError((error) => {
              this.store.dispatch(
                NotificationsApiActions.getNotificationFail({ error })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );
}
