import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectRouterState } from '$/app/store/router/router.selectors';
import { selectParam } from '../router/router.selectors';
import { rolesAdapter, State } from './roles.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;

// Feature Selector
export const selectRolesState = createFeatureSelector<State>('roles');
const entitySelectors = rolesAdapter.getSelectors(selectRolesState);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extra Selectors
export const selectLoading = createSelector(selectRolesState, getLoading);
export const selectLoaded = createSelector(selectRolesState, getLoaded);
export const selectError = createSelector(selectRolesState, getError);

export const selectRoles = createSelector(
  selectAll,
  selectRouterState,
  (roles, routerState) => {
    if (routerState) {
      return roles.filter((role) => {
        return role.facilityId === routerState.params.facilityId;
      });
    }
    return [];
  }
);

export const selectRole = createSelector(
  selectRoles,
  selectParam('roleId'),
  (roles, roleId) => {
    return roles.find((r) => {
      return r.id === roleId;
    });
  }
);

// VIEW MODEL SELECTORS
