import { Table } from '$/models/data/base';
import { MedicalEventTypeId } from '$shared/lookups/medical-event-types.lookup';
import { ITableBase } from '$shared/types/general';

export class MedicalEvent extends Table implements ITableBase {
  id: string;
  createdBy: string;
  createdAt: string;
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  typeId?: MedicalEventTypeId;
  dateReturned?: string;
  name?: string;
  notes?: string;
  occurredAt?: string;
  observerId?: string;
}
