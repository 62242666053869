import { sortByFields } from '$/app/utils';
import { MedicationOrderItem } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationOrderItemsAdapter =
  createEntityAdapter<MedicationOrderItem>({
    sortComparer: sortByFields('createdAt')
  });

export interface State extends EntityState<MedicationOrderItem> {
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationOrderItemsAdapter.getInitialState({
  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
