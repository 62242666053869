import { AnnouncementDetailPageActions } from '$/app/pages/communication/announcement-detail/announcement-detail.actions';
import { AnnouncementRepliesApiService } from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AnnouncementRepliesApiActions } from './announcement-replies.actions';

@Injectable()
export class AnnouncementRepliesEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly announcementRepliesService = inject(
    AnnouncementRepliesApiService
  );

  createAnnouncementReply$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AnnouncementDetailPageActions.createReply),
        switchMap((action) => {
          return this.announcementRepliesService
            .create(action.reply, action.params)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  AnnouncementRepliesApiActions.createAnnouncementReplyFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );
}
