import { PhoneTypeId } from '$shared/lookups/phone-types.lookup';
import { Table } from '../data/base/table.model';

export class PhoneNumber extends Table {
  orgId?: string;
  facilityId?: string;
  label?: string;
  number?: string;
  type?: PhoneTypeId;
  isPrimary?: boolean;
}
