import { FeathersService } from '$/app/services/feathers.service';
import { LibraryDocumentTemplatePacketTemplatesWsActions } from '$/app/store/library-document-template-packet-templates';
import { ILibraryDocumentTemplatePacketTemplate } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class LibraryDocumentTemplatePacketTemplatesApiService extends AbstractApiService<ILibraryDocumentTemplatePacketTemplate> {
  constructor(feathers: FeathersService, store: Store) {
    super('library-document-template-packet-templates', feathers, store, {
      entityName: 'libraryDocumentTemplatePacketTemplate',
      created:
        LibraryDocumentTemplatePacketTemplatesWsActions.libraryDocumentTemplatePacketTemplateCreated,
      patched:
        LibraryDocumentTemplatePacketTemplatesWsActions.libraryDocumentTemplatePacketTemplatePatched,
      removed:
        LibraryDocumentTemplatePacketTemplatesWsActions.libraryDocumentTemplatePacketTemplateRemoved
    });
  }
}
