import { MedicationInventoryMovement, Params, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥👈 USER ACTIONS
//

// Fetch MedicationInventoryMovement
export const fetchMedicationInventoryMovement = createAction(
  '[MedicationInventoryMovement Detail Page] Fetch MedicationInventoryMovement',
  (id: string, params: Params = { query: {} }) => ({ id, params })
);

// Update MedicationInventoryMovement
export const updateMedicationInventoryMovement = createAction(
  '[MedicationInventoryMovement Detail Page] Update MedicationInventoryMovement',
  props<Update<MedicationInventoryMovement>>()
);

// Delete MedicationInventoryMovement
export const deleteMedicationInventoryMovement = createAction(
  '[MedicationInventoryMovement Detail Page] Delete MedicationInventoryMovement',
  props<{ id: string }>()
);
