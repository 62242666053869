import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { BloodPressureLogsPageActions } from '$/app/pages/notebook/shared/logs/blood-pressure-log/blood-pressure-log.actions';
import {
  BloodPressureLogsApiService,
  EffectHelpersService
} from '$/app/services';
import { BloodPressureLogFormPageActions } from '$/app/shared/pages/forms/log-forms/blood-pressure-log-form/blood-pressure-log-form.actions';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { IBloodPressureLog } from '$shared/services/blood-pressure-log';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { BloodPressureLogsApiActions } from './blood-pressure-logs.actions';

@Injectable()
export class BloodPressureLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly bloodPressureLogsService = inject(
    BloodPressureLogsApiService
  );

  loadBloodPressureLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BloodPressureLogsPageActions.loadBloodPressureLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.bloodPressureLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('bloodPressureLogs', logs);
            responseData.setPrimaryAction(
              BloodPressureLogsApiActions.loadBloodPressureLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getBloodPressureLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BloodPressureLogsPageActions.getBloodPressureLogs,
        LogsPageActions.getBloodPressureLogs,
        GivePrnFormActions.getBloodPressureLogs
      ),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.bloodPressureLogsService.getAll(action.params).pipe(
          getAllPages(this.bloodPressureLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('bloodPressureLogs', logs);
            responseData.setPrimaryAction(
              BloodPressureLogsApiActions.getBloodPressureLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createBloodPressureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BloodPressureLogFormPageActions.createBloodPressureLog),
      exhaustMap((action) => {
        return this.bloodPressureLogsService
          .create(action.bloodPressureLog)
          .pipe(
            tap((data: IBloodPressureLog) => {
              this.effectHelpers.dismissModal({
                data
              });
            }),
            map((bloodPressureLog: IBloodPressureLog) =>
              BloodPressureLogsApiActions.createBloodPressureLogSuccess({
                bloodPressureLog
              })
            ),
            catchError((error) =>
              of(
                BloodPressureLogsApiActions.createBloodPressureLogFail({
                  error
                })
              )
            )
          );
      })
    );
  });

  updateBloodPressureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BloodPressureLogFormPageActions.updateBloodPressureLog),
      switchMap((action) => {
        return this.bloodPressureLogsService
          .patch(action.id, action.changes)
          .pipe(
            tap((data: IBloodPressureLog) => {
              this.effectHelpers.dismissModal({
                data
              });
            }),
            map((bloodPressureLog: IBloodPressureLog) => {
              return BloodPressureLogsApiActions.updateBloodPressureLogSuccess({
                bloodPressureLog
              });
            }),
            catchError((error) => {
              return of(
                BloodPressureLogsApiActions.updateBloodPressureLogFail({
                  error
                })
              );
            })
          );
      })
    );
  });

  deleteBloodPressureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BloodPressureLogsPageActions.deleteBloodPressureLog,
        ResidentMedicationTasksPageActions.deleteBloodPressureLog,
        MissedMedicationsPageActions.deleteBloodPressureLog,
        RoutineMarDetailPageActions.deleteBloodPressureLog,
        RoutineMarListPageActions.deleteBloodPressureLog,
        GivePrnFormActions.deleteBloodPressureLog,
        CompleteCareTaskModalActions.deleteBloodPressureLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete blood pressure log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.bloodPressureLogsService.delete(action.id, action.params);
        },
        onSuccess: (bloodPressureLog: IBloodPressureLog) =>
          BloodPressureLogsApiActions.deleteBloodPressureLogSuccess({
            id: bloodPressureLog.id
          }),
        onError: (error) =>
          BloodPressureLogsApiActions.deleteBloodPressureLogFail({ error })
      })
    );
  });
}
