import { PrnAmountsSelectors } from '$/app/store/prn-amounts';
import * as PrnsSelectors from '$/app/store/prns/prns.selectors';
import { createSelector } from '@ngrx/store';

export const selectSelectedPrnAmounts = createSelector(
  PrnAmountsSelectors.selectAll,
  PrnsSelectors.selectPrn,
  (prnAmounts = [], prn) => {
    return prnAmounts.filter((amount) => amount?.prnId === prn?.id);
  }
);

export const SharedPrnsSelectors = {
  selectSelectedPrnAmounts
};
