import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  OmittedMedicationDetailActions,
  OmittedMedicationsApiActions,
  OmittedMedicationsGeneralActions,
  OmittedMedicationsListActions
} from './actions';
import {
  State,
  initialState,
  omittedMedicationsAdapter
} from './omitted-medications.state';

const reducer = createReducer(
  initialState,

  on(
    OmittedMedicationsListActions.loadOmittedMedications,
    OmittedMedicationsListActions.getOmittedMedications,
    OmittedMedicationDetailActions.fetchOmittedMedication,
    OmittedMedicationDetailActions.deleteOmittedMedication,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    OmittedMedicationDetailActions.updateOmittedMedication,
    (state, action) => {
      return omittedMedicationsAdapter.updateOne(action, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    OmittedMedicationsApiActions.addOmittedMedication_DashboardRoute,
    (state, action) => {
      const omittedMedications = castArray(action.omittedMedication);
      return omittedMedicationsAdapter.upsertMany(omittedMedications, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    OmittedMedicationsApiActions.loadOmittedMedicationsSuccess,
    (state, action) => {
      return omittedMedicationsAdapter.setAll(action.omittedMedications.data, {
        ...state,
        total: action.omittedMedications.total,
        skip: action.omittedMedications.skip,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    OmittedMedicationsApiActions.getOmittedMedicationsSuccess,
    (state, action) => {
      return omittedMedicationsAdapter.upsertMany(
        action.omittedMedications.data,
        {
          ...state,
          total: action.omittedMedications.total,
          skip: action.omittedMedications.skip,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    OmittedMedicationsApiActions.fetchOmittedMedicationSuccess,
    (state, action) => {
      return omittedMedicationsAdapter.upsertOne(action.omittedMedication, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    OmittedMedicationsApiActions.updateOmittedMedicationSuccess,
    (state, action) => {
      return omittedMedicationsAdapter.updateOne(action.omittedMedication, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    OmittedMedicationsApiActions.deleteOmittedMedicationSuccess,
    (state, action) => {
      return omittedMedicationsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    OmittedMedicationsApiActions.loadOmittedMedicationsFail,
    OmittedMedicationsApiActions.getOmittedMedicationsFail,
    OmittedMedicationsApiActions.fetchOmittedMedicationFail,
    OmittedMedicationsApiActions.updateOmittedMedicationFail,
    OmittedMedicationsApiActions.deleteOmittedMedicationFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    OmittedMedicationsApiActions.omittedMedicationsCreated,
    OmittedMedicationsApiActions.omittedMedicationsPatched,
    (state, action) => {
      return omittedMedicationsAdapter.upsertOne(action.omittedMedication, {
        ...state
      });
    }
  ),

  on(
    OmittedMedicationsApiActions.omittedMedicationsRemoved,
    (state, action) => {
      return omittedMedicationsAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(
    OmittedMedicationsGeneralActions.addOmittedMedications,
    (state, action) => {
      return omittedMedicationsAdapter.upsertMany(action.omittedMedications, {
        ...state,
        loading: false,
        error: null
      });
    }
  ),

  on(OmittedMedicationsGeneralActions.clearOmittedMedications, (state) => {
    return omittedMedicationsAdapter.removeAll({
      ...state,
      total: 0,
      skip: 0,
      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function omittedMedicationsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
