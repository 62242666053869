import { RouterUtilityService } from '$/app/services';
import { Pipe, PipeTransform, inject } from '@angular/core';

type ThemeVariants = 'primary' | 'accent' | 'theme';

/** Used for tailwind */
type ThemePrefix = 'text' | 'bg' | 'border' | null;
type ThemeSuffix = 'contrast' | null;

@Pipe({ name: 'alcTheme', standalone: true })
export class AlcThemePipe implements PipeTransform {
  private readonly routerService = inject(RouterUtilityService);

  transform(
    variant: ThemeVariants,
    prefix?: ThemePrefix,
    suffix?: ThemeSuffix
  ) {
    return [prefix, this.routerService.getTheme(), variant, suffix]
      .filter(Boolean)
      .join('-');
  }
}
