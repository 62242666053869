import { trim } from 'lodash';
import { stringifyDose } from '../doses';
import { Logger } from '../logger';
import { lookupValue } from '../lookups/lookup-value';
import { IMedication } from '../medications';

export function medicationName(
  medication: Pick<IMedication, 'brandName' | 'genericName'>,
  fallback?: string
): string {
  const names = [
    trim(medication.brandName ?? ''),
    trim(medication.genericName ?? '')
  ].filter(Boolean);

  if (!names.length && fallback) {
    return fallback;
  }

  Logger.assert(names.length, 'Medication has no name', { medication });

  return names.length === 2 ? `${names[0]} (${names[1]})` : names[0];
}

export function medicationStrength(medication: IMedication): string {
  const form = lookupValue('medicationForms', medication.formId);
  const measurement = lookupValue(
    'measurements',
    medication.measurementId,
    'abbreviation'
  );

  return medication.measurementId === 'unit'
    ? `${medication.strength} ${form}`
    : `${medication.strength} ${measurement}`;
}

export function medicationDose(
  dose: IMedication['dose'],
  medication: IMedication
): string {
  return stringifyDose(dose, medication.measurementId, medication.formId, {
    includeSpacing: true
  });
}
