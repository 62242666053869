import { Params, ResidentMedicalProfessional, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥👈 USER ACTIONS
//

// Fetch ResidentMedicalProfessional
export const fetchResidentMedicalProfessional = createAction(
  '[ResidentMedicalProfessional Detail Page] Fetch ResidentMedicalProfessional',
  (id: string, params: Params = { query: {} }) => ({ id, params })
);

// Update ResidentMedicalProfessional
export const updateResidentMedicalProfessional = createAction(
  '[ResidentMedicalProfessional Detail Page] Update ResidentMedicalProfessional',
  props<Update<ResidentMedicalProfessional>>()
);

// Delete ResidentMedicalProfessional
export const deleteResidentMedicalProfessional = createAction(
  '[ResidentMedicalProfessional Detail Page] Delete ResidentMedicalProfessional',
  props<{ id: string }>()
);
