import { RoomsSelectors } from '$/app/store/rooms/rooms.selectors';
import { Room } from '$/models';
import { Dictionary } from '$shared/types';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

@Pipe({ name: 'room', standalone: true })
export class AlcRoomPipe implements PipeTransform {
  private readonly store = inject(Store);

  private rooms$ = this.store.select(RoomsSelectors.selectEntities);

  transform(roomId: string, property = 'identifier'): any {
    let _rooms: Dictionary<Room>;

    this.rooms$.pipe(first()).subscribe((rooms) => {
      _rooms = rooms;
    });

    return _rooms[roomId]?.[property] || null;
  }
}
