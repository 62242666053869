import * as commonFormatters from '$shared/formatters/common';
import * as medicationFormatters from '$shared/formatters/medications';
import * as residentUtils from '$shared/residents/utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatter',
  standalone: true
})
export class AlcFormatterPipe implements PipeTransform {
  transform(
    functionName:
      | keyof typeof commonFormatters
      | keyof typeof residentUtils
      | keyof typeof medicationFormatters,
    ...args: any[]
  ) {
    if (commonFormatters[functionName]) {
      return commonFormatters[functionName](...args);
    } else if (medicationFormatters[functionName]) {
      return medicationFormatters[functionName](...args);
    } else if (residentUtils[functionName]) {
      return residentUtils[functionName](...args);
    }
    return args[0];
  }
}
