import { createFeature, createReducer, on } from '@ngrx/store';
import {
  SeizureLogSymptomsApiActions,
  SeizureLogSymptomsGeneralActions
} from './actions';
import {
  initialState,
  seizureLogSymptomsAdapter
} from './seizure-log-symptoms.state';

export const seizureLogSymptomsFeature = createFeature({
  name: 'seizureLogSymptoms',
  reducer: createReducer(
    initialState,

    on(
      SeizureLogSymptomsApiActions.seizureLogSymptomsCreated,
      SeizureLogSymptomsApiActions.seizureLogSymptomsPatched,
      (state, action) => {
        return seizureLogSymptomsAdapter.upsertOne(
          action.seizureLogSymptom,
          state
        );
      }
    ),

    on(
      SeizureLogSymptomsApiActions.seizureLogSymptomsRemoved,
      (state, action) => {
        return seizureLogSymptomsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      SeizureLogSymptomsGeneralActions.addSeizureLogSymptoms,
      (state, action) => {
        return seizureLogSymptomsAdapter.upsertMany(action.seizureLogSymptoms, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(SeizureLogSymptomsGeneralActions.clearSeizureLogSymptoms, () => {
      return seizureLogSymptomsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
