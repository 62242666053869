import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { UrineLogsPageActions } from '$/app/pages/notebook/shared/logs/urine-log/urine-log.actions';
import { UrineLogFormPageActions } from '$/app/shared/pages/forms/log-forms/urine-log-form/urine-log-form.actions';
import { getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  UrineLogsApiActions,
  UrineLogsGeneralActions,
  UrineLogsWsActions
} from './urine-logs.actions';
import { initialState, urineLogsAdapter } from './urine-logs.state';

export const urineLogsFeature = createFeature({
  name: 'urineLogs',
  reducer: createReducer(
    initialState,

    on(
      UrineLogsPageActions.loadUrineLogs,
      UrineLogsPageActions.getUrineLogs,
      UrineLogFormPageActions.createUrineLog,
      UrineLogFormPageActions.updateUrineLog,
      UrineLogsPageActions.deleteUrineLog,
      ResidentMedicationTasksPageActions.deleteUrineLog,
      MissedMedicationsPageActions.deleteUrineLog,
      RoutineMarListPageActions.deleteUrineLog,
      RoutineMarDetailPageActions.deleteUrineLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(UrineLogsApiActions.loadUrineLogsSuccess, (state, action) => {
      const data = getRecords(action.urineLogs);

      return urineLogsAdapter.setAll(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(UrineLogsApiActions.getUrineLogsSuccess, (state, action) => {
      const data = getRecords(action.urineLogs);

      return urineLogsAdapter.upsertMany(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(UrineLogsApiActions.createUrineLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(UrineLogsApiActions.updateUrineLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(UrineLogsApiActions.deleteUrineLogSuccess, (state, action) =>
      urineLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      UrineLogsApiActions.loadUrineLogsFail,
      UrineLogsApiActions.getUrineLogsFail,
      UrineLogsApiActions.createUrineLogFail,
      UrineLogsApiActions.updateUrineLogFail,
      UrineLogsApiActions.deleteUrineLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      UrineLogsWsActions.urineLogsCreated,
      UrineLogsWsActions.urineLogsPatched,
      (state, action) => {
        return urineLogsAdapter.upsertOne(action.urineLog, state);
      }
    ),

    on(UrineLogsWsActions.urineLogsRemoved, (state, action) => {
      return urineLogsAdapter.removeOne(action.id, state);
    }),

    on(UrineLogsGeneralActions.addUrineLogs, (state, action) => {
      return urineLogsAdapter.upsertMany(action.urineLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(UrineLogsGeneralActions.clearUrineLogs, () => {
      return urineLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
