import { AlcErrorComponent } from '$/app/shared/components/alc-error.component';
import { AlcAvatarComponent } from '$/app/shared/components/avatar/avatar.component';
import { AlcGenericModalShellComponent } from '$/app/shared/components/shells/generic-modal-shell/generic-modal-shell.component';
import { AlcDateRangePickerControlComponent } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { AlcTimeInputComponent } from '$/app/shared/form-controls/time-input/time-input.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AlcCommonModule } from './alc-common.module';
import { AlcBirthdayRoomLabelComponent } from './components/birthday-room-label/birthday-room-label';
import { AlcCalloutComponent } from './components/callout/callout.component';
import { AlcCheckboxControlComponent } from './components/checkbox/checkbox.component';
import { AlcDateFilterHeaderComponent } from './components/date-filter-header/date-filter-header.component';
import { AlcDateRangeFilterButtonComponent } from './components/date-range-filter-button/date-range-filter-button.component';
import { AlcEmptyPlaceholderComponent } from './components/empty-placeholder/empty-placeholder.component';
import { AlcFilterButtonComponent } from './components/filter-button/filter-button.component';
import { AlcFormDividerComponent } from './components/form-divider/form-divider.component';
import { AlcIconContainerComponent } from './components/icon-container/icon-container.component';
import { AlcIndicatorComponent } from './components/indicator/indicator.component';
import { AlcInfoBlockComponent } from './components/info-block/info-block.component';
import { AlcInfoCardItemComponent } from './components/info-card/info-card-item/info-card-item.component';
import { AlcInfoCardComponent } from './components/info-card/info-card.component';
import { AlcGeneralListItemComponent } from './components/list-items/general-list-item/general-list-item.component';
import { AlcLoadingPlaceholderComponent } from './components/loading-placeholder/loading-placeholder.component';
import { AlcMenuButtonComponent } from './components/menu-button/menu-button.component';
import { AlcNotificationButtonComponent } from './components/notification-button/notification-button.component';
import { AlcPillComponent } from './components/pill/pill.component';
import { AlcResponsiveContentComponent } from './components/shells/responsive-content/responsive-content.component';
import { AlcSkeletonItemComponent } from './components/skeleton/skeleton-item/skeleton-item.component';
import { AlcButtonToggleGroupComponent } from './form-controls/button-toggle-group/button-toggle-group.component';
import { AlcDateInputComponent } from './form-controls/date-input/date-input.component';
import { AlcDatetimeInputComponent } from './form-controls/datetime-input/datetime-input.component';
import { AlcResidentAvatarComponent } from './form-controls/resident-select/components/resident-avatar.component';

const components = [
  AlcAvatarComponent,
  AlcButtonToggleGroupComponent,
  AlcCalloutComponent,
  AlcCheckboxControlComponent,
  AlcCommonModule,
  AlcDateFilterHeaderComponent,
  AlcDateInputComponent,
  AlcDateRangeFilterButtonComponent,
  AlcDateRangePickerControlComponent,
  AlcDatetimeInputComponent,
  AlcEmptyPlaceholderComponent,
  AlcErrorComponent,
  AlcFilterButtonComponent,
  AlcFormDividerComponent,
  AlcGeneralListItemComponent,
  AlcGenericModalShellComponent,
  AlcIconContainerComponent,
  AlcIndicatorComponent,
  AlcInfoBlockComponent,
  AlcInfoCardComponent,
  AlcInfoCardItemComponent,
  AlcLoadingPlaceholderComponent,
  AlcMenuButtonComponent,
  AlcNotificationButtonComponent,
  AlcPillComponent,
  AlcResidentAvatarComponent,
  AlcResponsiveContentComponent,
  AlcSkeletonItemComponent,
  AlcTimeInputComponent,
  AlcBirthdayRoomLabelComponent
];

@NgModule({
  imports: components,
  declarations: [],
  exports: [...components],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
