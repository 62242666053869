import { FacilityUserPhone } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserPhoneFormActions = createActionGroup({
  source: 'Facility User Phone Form',
  events: {
    'Add Facility User Phone': props<{
      facilityUserPhone: MaybeArray<FacilityUserPhone>;
    }>()
  }
});
