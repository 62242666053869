import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IFoodLog } from '$shared/services/food-log';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { State, foodLogsAdapter } from './food-logs.state';

export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;

// Food Logs State Selectors
export const selectFoodLogsState = createFeatureSelector<State>('food-log');

const entitySelectors = foodLogsAdapter.getSelectors(selectFoodLogsState);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(selectFoodLogsState, getLoading);
export const selectLoaded = createSelector(selectFoodLogsState, getLoaded);
export const selectError = createSelector(selectFoodLogsState, getError);

export const selectPagination = (query) => {
  return createSelector(selectFoodLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentFoodLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (foodLogs, residentId): IFoodLog[] => {
    if (isEmpty(foodLogs) || !residentId) {
      return [];
    }

    return foodLogs.filter((foodLog) => {
      return foodLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<IFoodLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IFoodLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IFoodLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const computedMetrics = logs.reduce(
      (acc, log) => {
        acc.count += 1;

        acc.breakCount += log.mealTypeId === 'breakfast' ? 1 : 0;
        acc.lunchCount += log.mealTypeId === 'lunch' ? 1 : 0;
        acc.dinnerCount += log.mealTypeId === 'dinner' ? 1 : 0;

        acc.sumBreak += log.mealTypeId === 'breakfast' ? log.amount : 0;
        acc.sumLunch += log.mealTypeId === 'lunch' ? log.amount : 0;
        acc.sumDinner += log.mealTypeId === 'dinner' ? log.amount : 0;

        acc.avgBreak = Math.round(acc.sumBreak / acc.breakCount);
        acc.avgLunch = Math.round(acc.sumLunch / acc.lunchCount);
        acc.avgDinner = Math.round(acc.sumDinner / acc.dinnerCount);

        return acc;
      },
      {
        count: 0,
        breakCount: 0,
        lunchCount: 0,
        dinnerCount: 0,
        sumBreak: 0,
        sumLunch: 0,
        sumDinner: 0,
        avgBreak: 0,
        avgLunch: 0,
        avgDinner: 0
      }
    );

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length || 0
      },
      {
        type: 'value',
        label: 'Avg. Breakfast',
        unit: '%',
        data: (computedMetrics.avgBreak || 0).toFixed(0)
      },
      {
        type: 'value',
        label: 'Avg. Lunch',
        unit: '%',
        data: (computedMetrics.avgLunch || 0).toFixed(0)
      },
      {
        type: 'value',
        label: 'Avg. Dinner',
        unit: '%',
        data: (computedMetrics.avgDinner || 0).toFixed(0)
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
