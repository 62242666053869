import { Table } from '$/models/data/base';
import { MedicalHistoryCategoryId } from '$shared/lookups/medical-history-categories.lookup';

export class MedicalHistory extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  categoryId?: MedicalHistoryCategoryId;
  symptom?: string;
  notes?: string;
}
