import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  inject,
  Input
} from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';
import { AlcCommonModule } from '../../alc-common.module';

@Component({
  selector: 'alc-icon-container',
  standalone: true,
  imports: [AlcCommonModule],
  templateUrl: './icon-container.component.html',
  styleUrls: ['./icon-container.component.scss']
})
export class AlcIconContainerComponent implements AfterContentInit {
  private readonly elementRef = inject(ElementRef);

  _size: string = '40px';
  @Input()
  get size() {
    return this._size;
  }
  set size(value) {
    this._size = value;
    this.elementRef.nativeElement.style.setProperty(
      '--icon-container-size',
      value
    );
  }
  @Input('icon-size') iconSize: string = '1em';
  @Input() color: string;

  @ContentChild(IonIcon) icon: IonIcon;

  ngAfterContentInit() {
    if (this.size) {
      this.elementRef.nativeElement.style.setProperty(
        '--icon-container-size',
        this.size
      );
    }
    if (this.iconSize) {
      this.elementRef.nativeElement.style.setProperty(
        '--icon-size',
        this.iconSize
      );
    }
    if (this.color) {
      this.elementRef.nativeElement.style.setProperty(
        '--icon-background-color',
        this.color
      );
      this.elementRef.nativeElement.style.setProperty(
        '--icon-color',
        '#FFFFFF'
      );
    }
  }
}
