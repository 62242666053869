import { Params, ResidentLogType } from '$/models';
import { createAction } from '@ngrx/store';

const source = '[Logs Page]';

const baseFields = ['id', 'residentId', 'recordAt'];

export const getBehaviorLogs = createAction(
  `${source} Get Behavior Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'behaviorId', 'occurrences'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getBloodPressureLogs = createAction(
  `${source} Get Blood Pressure Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'diastolic', 'systolic', 'pulse'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getBloodSugarLogs = createAction(
  `${source} Get Blood Sugar Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'level'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getBmLogs = createAction(
  `${source} Get Bowel Movement Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'bmSizeId', 'bmTextureId'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getBodyCheckLogs = createAction(
  `${source} Get Body Check Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId,
          $eager: { observations: true }
        }
      }
    };
  }
);

export const getFoodLogs = createAction(
  `${source} Get Food Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'mealTypeId', 'amount'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getMoodLogs = createAction(
  `${source} Get Mood Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'mood'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);
export const getOxygenLogs = createAction(
  `${source} Get Oxygen Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'level', 'pulse'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);
export const getPositionLogs = createAction(
  `${source} Get Position Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [
            ...baseFields,
            'fromPositionId',
            'toPositionId',
            'fromSideId',
            'toSideId'
          ],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);
export const getSeizureLogs = createAction(
  `${source} Get Seizure Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'duration'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getShowerLogs = createAction(
  `${source} Get Shower Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'wasGiven'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getSleepLogs = createAction(
  `${source} Get Sleep Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'hoursAwake', 'hoursAsleep', 'timesUp'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getTemperatureLogs = createAction(
  `${source} Get Temperature Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'temperature'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getUrineLogs = createAction(
  `${source} Get Urine Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'amount', 'measurementId'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getWaterLogs = createAction(
  `${source} Get Water Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'amount'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getWeightLogs = createAction(
  `${source} Get Weight Logs`,
  (query: { residentId: string; startDate: string; endDate: string }) => {
    return {
      params: {
        query: {
          $select: [...baseFields, 'weight'],
          recordAt: {
            $between: [query.startDate, query.endDate]
          },
          residentId: query.residentId
        }
      }
    };
  }
);

export const getResidentLogTypes = createAction(
  `${source} Get Resident Log Types`,
  (params: Params = { query: {} }) => ({ params })
);

export const turnOnLog = createAction(
  `${source} Turn On Log`,
  (residentLogType: ResidentLogType) => ({ residentLogType })
);

export const turnOffLog = createAction(
  `${source} Turn Off Log`,
  (id: string, query?: any) => {
    return {
      id,
      params: { query }
    };
  }
);

export const LogsPageActions = {
  getBehaviorLogs,
  getBloodPressureLogs,
  getBloodSugarLogs,
  getBmLogs,
  getBodyCheckLogs,
  getFoodLogs,
  getMoodLogs,
  getOxygenLogs,
  getPositionLogs,
  getSeizureLogs,
  getShowerLogs,
  getSleepLogs,
  getTemperatureLogs,
  getUrineLogs,
  getWaterLogs,
  getWeightLogs,
  getResidentLogTypes,
  turnOnLog,
  turnOffLog
};
