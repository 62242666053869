import { IDocumentParticipant, Params, Update } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const DocumentParticipantManagerPageActions = createActionGroup({
  source: 'Document Participant Manager Page',
  events: {
    'Update Document Participants': (
      params: Params
    ): Update<IDocumentParticipant> => ({
      id: null,
      changes: {},
      params
    })
  }
});
