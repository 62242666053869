import {
  ConsoleTransportBase,
  ILoggerOptions,
  ILoggerTransportPayload,
  LogLevel,
  LogLevels,
  Logger
} from '$shared/logger';
import { getConsoleFunc } from '$shared/logger/#console-shim';
import {
  LoggerColorLevel,
  colorByLevel,
  standardColors
} from '$shared/logger/colors';
import { LoggerConfig } from '$shared/logger/logger-config';
import { Memoize } from '$shared/utils';
import { isEmpty, isNil, omit, omitBy } from 'lodash';
import { DateTime } from 'luxon';
import { stringify } from 'safe-stable-stringify';

const MIN_LOG_LEVEL = {
  develop: 'debug',
  staging: 'debug',
  production: 'error'
};

export const styleString = Memoize((level: LoggerColorLevel) => {
  const color = colorByLevel[level];
  const hexColor =
    level === 'debug'
      ? `${standardColors[color]}7F` // 50% opacity
      : standardColors[color];

  return `color: ${hexColor}; font-weight: 200;`;
});

const SENTINEL = 'COLORIZED_CONSOLE_TRANSPORT_SENTINEL';

export class ColorizedConsoleTransport extends ConsoleTransportBase {
  log(
    level: LogLevel,
    message: string,
    payload: ILoggerTransportPayload,
    options: ILoggerOptions
  ): void {
    try {
      if (!this.shouldLog(level, options)) {
        return;
      }

      const messageLine = [
        `[${this.formatTimestamp(payload.timestamp)}] ${level}: ${message}`,

        payload.traceId ? `[${payload.traceId}]` : '',

        !isEmpty(
          omit(payload?.alcomyContext, ['accessToken', 'geofenceEnforced'])
        )
          ? stringify(
              omit(payload.alcomyContext, ['accessToken', 'geofenceEnforced'])
            )
          : ''
      ]
        .filter(Boolean)
        .join(' ');

      logCollapsedWithCallstack(level, messageLine, payload);
    } catch (error) {
      if (payload?.metadata?.SENTINEL === SENTINEL) {
        return;
      }

      Logger.error('Failed to log to console', {
        error,
        level,
        message,
        SENTINEL
      });
    }
  }

  override shouldLog(level: LogLevel, options: ILoggerOptions) {
    const minLogLevel =
      MIN_LOG_LEVEL[LoggerConfig.activeEnvironment] ?? 'debug';

    if (LogLevels[level] < LogLevels[minLogLevel]) {
      return false;
    }

    return super.shouldLog(level, options);
  }

  private formatTimestamp(timestamp: string) {
    return DateTime.fromISO(timestamp)
      .toLocal()
      .toFormat('yyyy-MM-dd h:mm:ss.SSS a');
  }
}

const groupCollapsed = getConsoleFunc('groupCollapsed');
const log = getConsoleFunc('log');
const trace = getConsoleFunc('trace');
const groupEnd = getConsoleFunc('groupEnd');

function logCollapsedWithCallstack(
  level: LoggerColorLevel,
  message: string,
  payload: any
) {
  groupCollapsed(`%c${message}`, styleString(level));

  const metadata = omitBy(
    payload?.metadata,
    (value, key) => isNil(value) || key === 'callStack'
  );

  if (!isEmpty(metadata)) {
    log(metadata);
  }

  trace('stack trace');

  groupEnd();
}
