import { sortByFields } from '$/app/utils';
import { MedicationInventory } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationInventoriesAdapter =
  createEntityAdapter<MedicationInventory>({
    sortComparer: sortByFields('createdAt')
  });

export interface State extends EntityState<MedicationInventory> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationInventoriesAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null
  }
);
