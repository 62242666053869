import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PharmaciesEffects } from './pharmacies.effects';
import { pharmaciesReducer } from './pharmacies.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('pharmacies', pharmaciesReducer),
    EffectsModule.forFeature([PharmaciesEffects])
  ],
  providers: [PharmaciesEffects]
})
export class PharmaciesStoreModule {}
