import { Dictionary } from '$shared/types';
import { MaybeArray } from '$shared/types/utility-types';
import { castArray } from 'lodash';

/**
 * Creates a dictionary whose keys are either the id of the source record(s)
 * or a custom key/compound key provided as input. The value is an object
 * with the copied keys from the source record(s).
 *
 * The copied keys are removed from the source record
 */
export function extractFieldsToDict<T = any, K = any>(
  data: MaybeArray<K>,
  fields: string[],
  key?: MaybeArray<string>
): Dictionary<T> {
  const dataArray = castArray(data);
  const result = dataArray.reduce((dict, curr) => {
    let dictKey: string = '';

    // construct the unique key for the dictionary
    if (key) {
      key = castArray(key);
      key.forEach((k) => {
        dictKey = dictKey + k;
      });
    } else {
      dictKey = curr['id'];
    }

    // Create an object with the copied keys and assign it to the dict key
    dict[dictKey] = fields.reduce((value, field) => {
      value[field] = curr[field] || null;
      if (curr[field]) {
        delete curr[field];
      }

      return value;
    }, {});

    return dict;
  }, {});

  return result;
}
