import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResidentsEffects } from '$/app/store/residents/residents.effects';
import { residentsReducer } from '$/app/store/residents/residents.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('residents', residentsReducer),
    EffectsModule.forFeature([ResidentsEffects])
  ],
  providers: [ResidentsEffects]
})
export class ResidentsStoreModule {}
