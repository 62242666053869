import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { TemperatureLog } from '$/models';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';

import { temperatureLogsFeature } from './temperature-logs.reducer';
import { temperatureLogsAdapter } from './temperature-logs.state';

export const {
  selectTemperatureLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = temperatureLogsFeature;

export const { selectAll, selectTotal } = temperatureLogsAdapter.getSelectors(
  selectTemperatureLogsState
);

export const selectPagination = (query) => {
  return createSelector(selectTemperatureLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentTemperatures = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (temperatureLogs, residentId) => {
    if (isEmpty(temperatureLogs) || !residentId) {
      return [];
    }

    return temperatureLogs.filter((temperatureLog) => {
      return temperatureLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<TemperatureLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<TemperatureLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<TemperatureLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const avg =
      Math.round(
        (logs.reduce((acc, log) => acc + log.temperature, 0) / logs.length) * 10
      ) / 10;
    const min =
      logs.length > 0 && Math.min(...logs.map((log) => log.temperature));
    const max =
      logs.length > 0 && Math.max(...logs.map((log) => log.temperature));

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length || 0
      },
      {
        type: 'value',
        label: 'Avg.',
        data: (avg || 0).toFixed(1)
      },
      {
        type: 'value',
        label: 'Low',
        data: (min || 0).toFixed(1)
      },
      {
        type: 'value',
        label: 'High',
        data: (max || 0).toFixed(1)
      }
    ];

    return {
      metrics,
      logs
    };
  });
};

export const TemperatureLogsSelectors = {
  selectTemperatureLogsState,
  selectEntities,
  selectIds,
  selectAll,
  selectTotal,
  selectLoading,
  selectLoaded,
  selectError,
  selectPagination,
  selectResidentTemperatures,
  selectLogsByMedicationTask,
  selectLogsByCareTask,
  selectLogsByQuery,
  selectLogsAndMetrics,
  selectLogsByPrn
};
