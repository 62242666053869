import { ResidentMedicalProfessional } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearResidentMedicalProfessionals = createAction(
  '[ResidentMedicalProfessionals] Clear ResidentMedicalProfessionals'
);

export const addResidentMedicalProfessionals = createAction(
  'Add Resident Medical Professionals',
  props<{ residentMedicalProfessionals: ResidentMedicalProfessional[] }>()
);
