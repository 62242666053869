import { CareTask, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const CarListPageActions = createActionGroup({
  source: 'Car List Page',
  events: {
    'Load Care Tasks': (params: Params) => ({ params }),
    'Care Task Completed': props<Update<CareTask>>(),
    'Care Task State Reset': props<Update<CareTask>>()
  }
});
