import { ResidentContact, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentContactComponentActions = createActionGroup({
  source: 'Resident Contacts Page',
  events: {
    'Delete Resident Contact': props<{ id: string }>(),
    'Set Primary Contact': props<Update<ResidentContact>>(),
    'Set Emergency Contact': props<Update<ResidentContact>>(),
    'Set Power of Attorney': props<Update<ResidentContact>>(),
    'Set Conservator': props<Update<ResidentContact>>()
  }
});
