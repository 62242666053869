import { EffectHelpersService, PharmacyPhonesApiService } from '$/app/services';
import * as PharmacyPhoneFormActions from '$/app/shared/pages/forms/pharmacy-phone-form/pharmacy-phone-form.actions';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, switchMap, tap } from 'rxjs/operators';
import { PharmacyPhonesApiActions } from './actions';

@Injectable()
export class PharmacyPhonesEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly pharmacyPhonesService = inject(PharmacyPhonesApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  createPharmacyPhone$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PharmacyPhoneFormActions.createPharmacyPhone),
        exhaustMap((action) => {
          return this.pharmacyPhonesService.create(action.pharmacyPhone).pipe(
            tap(
              this.effectHelpers.onPopFormSubmitSuccess(
                'Pharmacy Phone created successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                PharmacyPhonesApiActions.createPharmacyPhoneFail({ error })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  createPharmacyPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PharmacyPhonesApiActions.createPharmacyPhoneFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updatePharmacyPhone$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PharmacyPhoneFormActions.updatePharmacyPhone),
        switchMap((action) => {
          return this.pharmacyPhonesService
            .patch(
              action.pharmacyPhone.id,
              action.pharmacyPhone.changes,
              action.params
            )
            .pipe(
              tap(
                this.effectHelpers.onPopFormSubmitSuccess(
                  'Pharmacy Phone updated successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  PharmacyPhonesApiActions.updatePharmacyPhoneFail({ error })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updatePharmacyPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PharmacyPhonesApiActions.updatePharmacyPhoneFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deletePharmacyPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PharmacyPhonesApiActions.deletePharmacyPhoneFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
