import { Update } from '$/models';
import { IFacilitySetting } from '$shared/facility-settings/types';
import { createActionGroup, props } from '@ngrx/store';

export const GeneralSettingsPageActions = createActionGroup({
  source: 'General Settings Page',
  events: {
    'Update Facility Setting': props<Update<IFacilitySetting>>()
  }
});
