import { MedicationInventory, Params, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥👈 USER ACTIONS
//

// Fetch MedicationInventory
export const fetchMedicationInventory = createAction(
  '[MedicationInventory Detail Page] Fetch MedicationInventory',
  (id: string, params: Params = { query: {} }) => ({ id, params })
);

// Update MedicationInventory
export const updateMedicationInventory = createAction(
  '[MedicationInventory Detail Page] Update MedicationInventory',
  props<Update<MedicationInventory>>()
);

// Delete MedicationInventory
export const deleteMedicationInventory = createAction(
  '[MedicationInventory Detail Page] Delete MedicationInventory',
  props<{ id: string }>()
);
