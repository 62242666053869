import { createFeature, createReducer, on } from '@ngrx/store';

import { RoutineMarListFilterPageActions } from '../routine-mar-list-filter/routine-mar-list-filter.actions';
import { initialState } from './routine-mar-list-page.state';

export const uiRoutineMarListPageFeature = createFeature({
  name: 'uiRoutineMarListPage',
  reducer: createReducer(
    initialState,
    on(RoutineMarListFilterPageActions.filterChanged, (state, action) => {
      return {
        ...state,
        filters: action.filters
      };
    })
  )
});
