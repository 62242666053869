import { OverlayService } from '$/app/services/ui/overlays';
import { FacilityTimeService } from '$/app/services/utils/facility-time.service';
import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { DateRangePickerPage } from '$/app/shared/pages/popovers/date-range-picker/date-range-picker.page';
import { RouterQuerySelectors } from '$/app/store/shared/selectors/router-query-params.selectors';
import { getDateRange, InputProps, stringifyDateRange } from '$/app/utils';
import { DateTimeFormats } from '$shared/constants/datetime-formats';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DateFilterService {
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly ft = inject(FacilityTimeService);
  private readonly overlay = inject(OverlayService);

  private dateRange: IDateRangePickerValue;
  private defaultPreset = 'past30Days';

  public readonly dateRange$ = this.store
    .select(RouterQuerySelectors.selectDateRangePickerParams)
    .pipe(
      map((params): IDateRangePickerValue => {
        this.dateRange = getDateRange(params, this.ft, this.defaultPreset);

        return this.dateRange;
      })
    );

  /**
   * Returns an observable that emits the string representation of the active
   * date range.
   */
  public readonly activeDateRangeString$ = this.dateRange$.pipe(
    map((dateRange) => this.getActiveString(dateRange))
  );

  public async openPicker(
    event: Event,
    inputs: InputProps<DateRangePickerPage> = {},
    noQueryParams = false
  ): Promise<IDateRangePickerValue> {
    const dateRange = this.dateRange ?? (await firstValueFrom(this.dateRange$));

    const data = await this.overlay.getPopoverResult<IDateRangePickerValue>({
      component: DateRangePickerPage,
      componentProps: {
        dateRange,
        ...inputs
      },
      cssClass: 'form-popover',
      event
    });

    if (data && !noQueryParams) {
      this.router.navigate([], {
        queryParams: {
          type: data.type,
          preset: data.preset,
          year: data.year,
          startDate: data.startDate.toFormat(DateTimeFormats.DATE_SHORT),
          endDate: data.endDate.toFormat(DateTimeFormats.DATE_SHORT)
        }
      });
    }

    return data;
  }

  public getQueryString(): string {
    if (!this.dateRange) {
      return '';
    }

    const type = this.dateRange.type;
    const preset = this.dateRange.preset;
    const year = this.dateRange.year;
    const startDate = this.dateRange.startDate.toFormat(
      DateTimeFormats.DATE_SHORT
    );
    const endDate = this.dateRange.endDate.toFormat(DateTimeFormats.DATE_SHORT);

    return `type=${type}&preset=${preset}&year=${year}&startDate=${startDate}&endDate=${endDate}`;
  }

  public getActiveString(dateRange: IDateRangePickerValue) {
    const presets = this.ft.getPresetDictionary(dateRange.year);

    return stringifyDateRange(dateRange, presets);
  }
}
