import {
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

/**
 * @description
 * This validator will check for valid date ranges.
 * This validator must go inside the form groups AbstractControlOptions object, so it can be passed the entire form. */
export function validDateRange(
  startControlName: string,
  endControlName: string
): ValidatorFn {
  return (form: UntypedFormGroup): ValidationErrors => {
    const start = form.get(startControlName)?.value;
    const end = form.get(endControlName)?.value;

    if (!end) {
      return null;
    }

    const invalidDateRange = !start || start > end;

    if (invalidDateRange) {
      form.get(endControlName).setErrors({ invalidDateRange: true });
      return { invalidDateRange: true };
    }

    return null;
  };
}
