import { PermissionId } from '$shared/permissions';
import { LoginData, LoginDataUser } from '$shared/services/user';

export interface State {
  user: LoginDataUser;
  facilityUserId: string;
  facilityId: string;
  orgId: string;
  isAuthenticated: boolean;
  geofenceEnforced: boolean;
  // We store the permissions in the user state because the user
  // represends the concept of user, not simply the user record.
  permissions: Partial<Record<PermissionId, boolean>>;
  facilityList: LoginData['facilityList'];
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = {
  user: null,
  facilityUserId: null,
  facilityId: null,
  orgId: null,
  permissions: {},
  facilityList: [],
  isAuthenticated: false,
  geofenceEnforced: false,
  loading: false,
  loaded: false,
  error: null
};
