import { OpenIncidentReportsPageActions } from '$/app/pages/notebook/dashboard/open-incident-reports/open-incident-reports.actions';
import { EventsListActions } from '$/app/pages/notebook/residents/events-list/events-list.actions';
import { IncidentReportModalActions } from '$/app/pages/notebook/residents/incident-report-modal/incident-report.modal.actions';
import {
  EffectHelpersService,
  IncidentReportsApiService
} from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IncidentReportsApiActions } from './actions';

@Injectable()
export class IncidentReportsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly incidentReportsService = inject(IncidentReportsApiService);

  loadIncidentReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EventsListActions.loadIncidentReports,
        OpenIncidentReportsPageActions.loadIncidentReports
      ),
      this.effectHelpers.apiRequest({
        description: 'loading incident reports',
        onRequest: (action) =>
          this.incidentReportsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.incidentReportsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'incidentReports',
            response,
            IncidentReportsApiActions.loadIncidentReportsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          IncidentReportsApiActions.loadIncidentReportsFail({ error })
      })
    )
  );

  fetchIncidentReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsListActions.generateIncidentReportPdf),
      this.effectHelpers.apiRequest({
        description: 'fetching incident report',
        onRequest: (action) =>
          this.incidentReportsService.get(action.id, action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'incidentReports',
            response,
            IncidentReportsApiActions.fetchIncidentReportSuccess,
            { payloadKey: 'incidentReport' }
          );

          return responseData.getActions();
        },
        onError: (error) =>
          IncidentReportsApiActions.fetchIncidentReportFail({ error })
      })
    );
  });

  createIncidentReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentReportModalActions.createIncidentReport),
      this.effectHelpers.apiRequest({
        description: 'creating incident report',
        onRequest: (action) =>
          this.incidentReportsService.create(
            action.incidentReport,
            action.params
          ),
        useMapOperator: 'exhaustMap',
        onSuccess: (incidentReport) =>
          IncidentReportsApiActions.createIncidentReportSuccess({
            incidentReport
          }),
        onError: (error) =>
          IncidentReportsApiActions.createIncidentReportFail({ error })
      })
    )
  );

  updateIncidentReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IncidentReportModalActions.updateIncidentReport),
      this.effectHelpers.apiRequest({
        description: 'updating incidentReport',
        onRequest: (action) =>
          this.incidentReportsService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (incidentReport) =>
          IncidentReportsApiActions.updateIncidentReportSuccess({
            incidentReport
          }),
        onError: (error) =>
          IncidentReportsApiActions.updateIncidentReportFail({ error })
      })
    )
  );

  deleteIncidentReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsListActions.deleteIncidentReport),
      this.effectHelpers.apiRequest({
        description: 'deleting incident report',
        onRequest: (action) =>
          this.incidentReportsService.delete(action.id, action.params),
        onSuccess: (incidentReport) =>
          IncidentReportsApiActions.deleteIncidentReportSuccess({
            id: incidentReport.id
          }),
        onError: (error) =>
          IncidentReportsApiActions.deleteIncidentReportFail({ error })
      })
    )
  );
}
