import { FeathersService } from '$/app/services/feathers.service';
import { DiagnosesWsActions } from '$/app/store/diagnoses/diagnoses.actions';
import { getPaginatedData, toUpdated } from '$/app/utils';
import { Diagnosis, Paginated, Params, Update } from '$/models';
import { AtLeast } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DiagnosesApiService {
  private diagnosesService: Service<Diagnosis>;

  constructor(feathers: FeathersService, store: Store) {
    this.diagnosesService = feathers.client.service('diagnoses');

    this.diagnosesService.on('created', (diagnosis) => {
      store.dispatch(DiagnosesWsActions.diagnosesCreated({ diagnosis }));
    });

    this.diagnosesService.on('patched', (diagnosis) => {
      store.dispatch(DiagnosesWsActions.diagnosesPatched({ diagnosis }));
    });

    this.diagnosesService.on('removed', (diagnosis) => {
      store.dispatch(
        DiagnosesWsActions.diagnosesRemoved({
          id: diagnosis.id
        })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<Diagnosis[]> {
    const promise = this.diagnosesService.find(params) as Promise<
      Paginated<Diagnosis>
    >;
    return from(promise).pipe(map(getPaginatedData));
  }

  get(id: string, params: Params = { query: {} }): Observable<Diagnosis> {
    const promise = this.diagnosesService.get(id, params);
    return from(promise);
  }

  create(diagnosis: Diagnosis) {
    const promise = this.diagnosesService.create(diagnosis);
    return from(promise);
  }

  patch(
    id: string,
    diagnosis: AtLeast<Diagnosis, 'levelId' | 'name'>
  ): Observable<Update<Diagnosis>> {
    const promise = this.diagnosesService.patch(id, diagnosis);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string): Observable<Diagnosis> {
    const promise = this.diagnosesService.remove(id);
    return from(promise);
  }
}
