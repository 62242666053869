import { Logger } from '$shared/logger';
import { createDisposable } from '$shared/utils/create-disposable';
import { IonicSafeString } from '@ionic/angular/standalone';
import { LoadingOptions } from '@ionic/core';
import { OverlayService } from './index';

export function loading(
  this: OverlayService,
  message: string | IonicSafeString,
  options?: LoadingOptions & { disableAutoHide: true }
) {
  const show = () => {
    this.showLoading(message, options).catch((error) =>
      Logger.error('Failed to show loading overlay', { error })
    );
  };

  const hide = () => {
    this.hideLoading().catch((error) =>
      Logger.error('Failed to dismiss loading overlay', { error })
    );
  };

  return createDisposable(
    {
      show,
      hide
    },
    () => {
      if (!options?.disableAutoHide) {
        hide();
      }
    }
  );
}

export async function showLoading(
  this: OverlayService,
  message: string | IonicSafeString,
  options: LoadingOptions = {}
) {
  // TODO(2024-03-18): The loading indicator needs new styling - it doesn't look like the rest of the app
  const loading = await this.loadingController.create({
    message,
    spinner: 'circles',
    ...options
  });

  await loading.present();

  return loading;
}

export async function hideLoading(this: OverlayService) {
  const loading = await this.loadingController.getTop();
  if (loading) {
    await loading.dismiss();
  }
}
