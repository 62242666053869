import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OmittedMedicationsEffects } from './omitted-medications.effects';
import { omittedMedicationsReducer } from './omitted-medications.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('omittedMedications', omittedMedicationsReducer),
    EffectsModule.forFeature([OmittedMedicationsEffects])
  ],
  providers: [OmittedMedicationsEffects]
})
export class OmittedMedicationsStoreModule {}
