import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { LoginDataUser } from '$shared/services/user';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

type UserWithFacilityUsers = LoginDataUser & { facilityUsers: IFacilityUser[] };

@Injectable({ providedIn: 'root' })
export class UserApiService extends AbstractApiService<LoginDataUser> {
  constructor(feathers: FeathersService, store: Store) {
    super('users', feathers, store);
  }

  findByEmail(email: string) {
    return this.service.find({
      query: { $actions: [{ findByEmail: { email } }] }
    }) as Promise<UserWithFacilityUsers[]>;
  }
}
