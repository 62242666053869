import { IDocument, Params, Update } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const DocumentDetailsFormPageActions = createActionGroup({
  source: 'Document Details Form Page',
  events: {
    'Fetch Document': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Update Document Form': (
      id: string,
      changes: Partial<Pick<IDocument, 'formData' | 'formSchemaVersion'>>,
      params: Params
    ): Update<IDocument> => ({
      id,
      changes,
      params
    })
  }
});
