import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FacilityUserRolesEffects } from './facility-user-roles.effects';
import { facilityUserRolesFeature } from './facility-user-roles.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(facilityUserRolesFeature),
    EffectsModule.forFeature([FacilityUserRolesEffects])
  ],
  providers: [FacilityUserRolesEffects]
})
export class FacilityUserRolesStoreModule {}
