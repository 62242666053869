import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MedicationDestructionsEffects } from './medication-destructions.effects';
import { medicationDestructionsFeature } from './medication-destructions.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(medicationDestructionsFeature),
    EffectsModule.forFeature([MedicationDestructionsEffects])
  ],
  providers: [MedicationDestructionsEffects]
})
export class MedicationDestructionsStoreModule {}
