import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';

@Pipe({
  name: 'extractFileNameFromPath',
  standalone: true
})
export class AlcExtractFileNameFromPathPipe implements PipeTransform {
  transform(value: string): string {
    return isString(value) ? value.split('/').pop() : value;
  }
}
