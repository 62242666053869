import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { MedicationOrder } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MedicationOrdersWsActions } from './medication-orders.actions';

@Injectable({ providedIn: 'root' })
export class MedicationOrdersApiService extends AbstractApiService<MedicationOrder> {
  constructor(feathers: FeathersService, store: Store) {
    super('medication-orders', feathers, store, {
      entityName: 'medicationOrder',
      created: MedicationOrdersWsActions.medicationOrdersCreated,
      patched: MedicationOrdersWsActions.medicationOrdersPatched,
      removed: MedicationOrdersWsActions.medicationOrdersRemoved
    });
  }
}
