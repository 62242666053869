import { Dictionary, PascalCase, Prettify } from '$shared/types';
import {
  CapitalizeWords,
  Join,
  LiteralString
} from '$shared/types/utility-types';
import {
  Action,
  ActionCreator,
  ActionCreatorProps,
  NotAllowedCheck,
  createAction,
  emptyProps,
  props
} from '@ngrx/store';
import { camelCase, upperFirst } from 'lodash';

type ActionName<EventName extends string> = Uncapitalize<
  Join<CapitalizeWords<EventName>>
>;

type TAction<TSource extends string, K extends string, T> = {
  [key in ActionName<K>]: ActionCreator<
    `[${TSource}] ${K}`,
    (props: T) => T & Action<`[${TSource}] ${K}`>
  >;
};

type ApiActions<
  TSingleName extends string,
  TMultipleName extends string,
  TSingle,
  TMultiple
> = Prettify<
  TAction<TMultipleName, `Load ${TMultipleName} Success`, TMultiple> &
    TAction<TMultipleName, `Get ${TMultipleName} Success`, TMultiple> &
    TAction<TMultipleName, `Create ${TSingleName} Success`, TSingle> &
    TAction<TMultipleName, `Update ${TSingleName} Success`, TSingle> &
    TAction<TMultipleName, `Delete ${TSingleName} Success`, { id: string }> &
    TAction<TMultipleName, `Load ${TMultipleName} Fail`, { error: Error }> &
    TAction<TMultipleName, `Get ${TMultipleName} Fail`, { error: Error }> &
    TAction<TMultipleName, `Create ${TSingleName} Fail`, { error: Error }> &
    TAction<TMultipleName, `Update ${TSingleName} Fail`, { error: Error }> &
    TAction<TMultipleName, `Delete ${TSingleName} Fail`, { error: Error }>
>;

type WebSocketActions<TMultipleName extends string, TSingle> = Prettify<
  TAction<TMultipleName, `${TMultipleName} Created`, TSingle> &
    TAction<TMultipleName, `${TMultipleName} Patched`, TSingle> &
    TAction<TMultipleName, `${TMultipleName} Removed`, { id: string }>
>;

type GeneralActions<TMultipleName extends string, TMultiple> = Prettify<
  TAction<
    TMultipleName,
    `Clear ${TMultipleName}`,
    ReturnType<typeof emptyProps>
  > &
    TAction<TMultipleName, `Add ${TMultipleName}`, TMultiple>
>;

function createApiActions<
  TSingleName extends string,
  TMultipleName extends string,
  TSingle extends Dictionary<any>,
  TMultiple extends Dictionary<any>
>(
  singleName: LiteralString<TSingleName>,
  multipleName: LiteralString<TMultipleName>,
  single: ActionCreatorProps<TSingle> & NotAllowedCheck<TSingle>,
  multiple: ActionCreatorProps<TMultiple> & NotAllowedCheck<TMultiple>
): ApiActions<TSingleName, TMultipleName, typeof single, typeof multiple> {
  const prefix = `[${multipleName} API]`;

  return {
    [camelCase(`Load ${multipleName} Success`)]: createAction(
      `${prefix} Load ${multipleName} Success`,
      multiple
    ),

    [camelCase(`Get ${multipleName} Success`)]: createAction(
      `${prefix} Get ${multipleName} Success`,
      multiple
    ),

    [camelCase(`Create ${singleName} Success`)]: createAction(
      `${prefix} Create ${singleName} Success`,
      single
    ),

    [camelCase(`Update ${singleName} Success`)]: createAction(
      `${prefix} Update ${singleName} Success`,
      single
    ),

    [camelCase(`Delete ${singleName} Success`)]: createAction(
      `${prefix} Delete ${singleName} Success`,
      props<{ id: string }>()
    ),

    [camelCase(`Load ${multipleName} Fail`)]: createAction(
      `${prefix} Load ${multipleName} Fail`,
      props<{ error: Error }>()
    ),

    [camelCase(`Get ${multipleName} Fail`)]: createAction(
      `${prefix} Get ${multipleName} Fail`,
      props<{ error: Error }>()
    ),

    [camelCase(`Create ${singleName} Fail`)]: createAction(
      `${prefix} Create ${singleName} Fail`,
      props<{ error: Error }>()
    ),

    [camelCase(`Update ${singleName} Fail`)]: createAction(
      `${prefix} Update ${singleName} Fail`,
      props<{ error: Error }>()
    ),

    [camelCase(`Delete ${singleName} Fail`)]: createAction(
      `${prefix} Delete ${singleName} Fail`,
      props<{ error: Error }>()
    )
  } as any;
}

function createWebSocketActions<
  TTitle extends string,
  TSingle extends Dictionary<any>
>(
  title: LiteralString<TTitle>,
  single: ActionCreatorProps<TSingle> & NotAllowedCheck<TSingle>
): WebSocketActions<TTitle, typeof single> {
  const prefix = `[${title} WS]`;

  return {
    [camelCase(`${title} Created`)]: createAction(
      `${prefix} ${title} Created`,
      single
    ),

    [camelCase(`${title} Patched`)]: createAction(
      `${prefix} ${title} Patched`,
      single
    ),

    [camelCase(`${title} Removed`)]: createAction(
      `${prefix} ${title} Removed`,
      props<{ id: string }>()
    )
  } as any;
}

function createGeneralActions<
  TTitle extends string,
  TMultiple extends Dictionary<any>
>(
  title: LiteralString<TTitle>,
  multiple: ActionCreatorProps<TMultiple> & NotAllowedCheck<TMultiple>
): GeneralActions<TTitle, typeof multiple> {
  const prefix = `[${title} General]`;

  return {
    [camelCase(`Clear ${title}`)]: createAction(`${prefix} Clear ${title}`),

    [camelCase(`Add ${title}`)]: createAction(
      `${prefix} Add ${title}`,
      multiple
    )
  } as any;
}

type AlcomyActions<
  TSingleName extends string,
  TMultipleName extends string,
  TSingle,
  TMultiple
> = Prettify<
  {
    [key in `${PascalCase<TMultipleName>}ApiActions`]: ApiActions<
      TSingleName,
      TMultipleName,
      TSingle,
      TMultiple
    >;
  } & {
    [key in `${PascalCase<TMultipleName>}WsActions`]: WebSocketActions<
      TMultipleName,
      TSingle
    >;
  } & {
    [key in `${PascalCase<TMultipleName>}GeneralActions`]: GeneralActions<
      TMultipleName,
      TMultiple
    >;
  }
>;

export function createStoreActions<
  TSingleName extends string,
  TMultipleName extends string,
  TSingle extends Dictionary<any>,
  TMultiple extends Dictionary<any>
>(
  singleName: LiteralString<TSingleName>,
  multipleName: LiteralString<TMultipleName>,
  single: ActionCreatorProps<TSingle> & NotAllowedCheck<TSingle>,
  multiple: ActionCreatorProps<TMultiple> & NotAllowedCheck<TMultiple>
): AlcomyActions<TSingleName, TMultipleName, TSingle, TMultiple> {
  const prefix = upperFirst(camelCase(multipleName));

  return {
    [`${prefix}ApiActions`]: createApiActions(
      singleName,
      multipleName,
      single,
      multiple
    ),
    [`${prefix}WsActions`]: createWebSocketActions(multipleName, single),
    [`${prefix}GeneralActions`]: createGeneralActions(multipleName, multiple)
  } as any;
}
