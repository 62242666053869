import { IResident } from '$shared/residents';
import { Component, ViewEncapsulation, input } from '@angular/core';
import { AlcCommonModule } from '../../alc-common.module';

@Component({
  selector: 'alc-birthday-room-label',
  standalone: true,
  imports: [AlcCommonModule],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'm-0 flex items-center gap-2 flex-wrap'
  },
  template: `
    <span>
      {{ resident()?.birthDate | facilityDateTime: 'DATE' }}
    </span>
    <span class="!text-3xl/4">⋅</span>
    @if (resident()?.roomId) {
      <span>Room {{ resident()?.roomId | room }}</span>
    } @else {
      <span>Room <i>unassigned</i></span>
    }
  `
})
export class AlcBirthdayRoomLabelComponent {
  resident = input.required<IResident | null | undefined>();
}
