import { TOmittedAmount } from '$shared/medications/omitted-amount.schema';
import { MaybeArray } from '$shared/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const OmittedAmountsApiActions = createActionGroup({
  source: 'Omitted Amounts API',
  events: {
    'Load Omitted Amounts Success': props<{
      omittedAmounts: TOmittedAmount[];
    }>(),
    'Load Omitted Amounts Fail': props<{ error: Error }>(),
    'Get Omitted Amounts Success': props<{
      omittedAmounts: TOmittedAmount[];
    }>(),
    'Get Omitted Amounts Fail': props<{ error: Error }>(),
    'Fetch Omitted Amount Success': props<{ omittedAmount: TOmittedAmount }>(),
    'Fetch Omitted Amount Fail': props<{ error: Error }>(),
    'Create Omitted Amount Success': props<{
      omittedAmount?: MaybeArray<TOmittedAmount>;
    }>(),
    'Create Omitted Amount Fail': props<{ error: Error }>(),
    'Update Omitted Amount Success': props<{ omittedAmount: TOmittedAmount }>(),
    'Update Omitted Amount Fail': props<{ error: Error }>(),
    'Delete Omitted Amount Success': props<{ id: string }>(),
    'Delete Omitted Amount Fail': props<{ error: Error }>()
  }
});

export const OmittedAmountsWsActions = createActionGroup({
  source: 'Omitted Amounts WS',
  events: {
    'Omitted Amounts Created': props<{ omittedAmount: TOmittedAmount }>(),
    'Omitted Amounts Patched': props<{ omittedAmount: TOmittedAmount }>(),
    'Omitted Amounts Removed': props<{ id: string }>()
  }
});

export const OmittedAmountsGeneralActions = createActionGroup({
  source: 'Omitted Amounts General',
  events: {
    'Clear Omitted Amounts': emptyProps(),
    'Add Omitted Amounts': props<{ omittedAmounts: TOmittedAmount[] }>()
  }
});
