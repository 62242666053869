import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { BehaviorLogsPageActions } from '$/app/pages/notebook/shared/logs/behavior-logs/behavior-log.actions';
import { BehaviorLogModalActions } from '$/app/shared/pages/forms/log-forms/behavior-log-modal/behavior-log.modal.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  BehaviorLogsApiActions,
  BehaviorLogsGeneralActions,
  BehaviorLogsWsActions
} from './behavior-logs.actions';
import {
  BehaviorLogPageQueries,
  behaviorLogsAdapter,
  initialState
} from './behavior-logs.state';

export const behaviorLogsFeature = createFeature({
  name: 'behaviorLogs',
  reducer: createReducer(
    initialState,
    on(
      BehaviorLogModalActions.createBehaviorLog,
      BehaviorLogModalActions.updateBehaviorLog,
      BehaviorLogsPageActions.deleteBehaviorLog,
      ResidentMedicationTasksPageActions.deleteBehaviorLog,
      MissedMedicationsPageActions.deleteBehaviorLog,
      RoutineMarDetailPageActions.deleteBehaviorLog,
      RoutineMarListPageActions.deleteBehaviorLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),
    on(BehaviorLogsApiActions.loadBehaviorLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<BehaviorLogPageQueries>(
        state,
        action.behaviorLogs,
        queryType
      );
      const data = getRecords(action.behaviorLogs);

      return behaviorLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(BehaviorLogsApiActions.getBehaviorLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<BehaviorLogPageQueries>(
        state,
        action.behaviorLogs,
        queryType
      );
      const data = getRecords(action.behaviorLogs);

      return behaviorLogsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(BehaviorLogsApiActions.createBehaviorLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(BehaviorLogsApiActions.updateBehaviorLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(BehaviorLogsApiActions.deleteBehaviorLogSuccess, (state, action) =>
      behaviorLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),
    on(
      BehaviorLogsApiActions.loadBehaviorLogsFail,
      BehaviorLogsApiActions.getBehaviorLogsFail,
      BehaviorLogsApiActions.createBehaviorLogFail,
      BehaviorLogsApiActions.updateBehaviorLogFail,
      BehaviorLogsApiActions.deleteBehaviorLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),
    on(
      BehaviorLogsWsActions.behaviorLogsCreated,
      BehaviorLogsWsActions.behaviorLogsPatched,
      (state, action) => {
        return behaviorLogsAdapter.upsertOne(action.behaviorLog, state);
      }
    ),
    on(BehaviorLogsWsActions.behaviorLogsRemoved, (state, action) => {
      return behaviorLogsAdapter.removeOne(action.id, state);
    }),
    on(BehaviorLogsGeneralActions.addBehaviorLogs, (state, action) => {
      return behaviorLogsAdapter.upsertMany(action.behaviorLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(BehaviorLogsGeneralActions.clearBehaviorLogs, () => {
      return behaviorLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
