import { LibraryCollectionDocumentTemplatesApiService } from '$/app/services';
import { Injectable, inject } from '@angular/core';

@Injectable()
export class LibraryCollectionDocumentTemplatesEffects {
  private readonly libraryCollectionDocumentTemplatesService = inject(
    LibraryCollectionDocumentTemplatesApiService
  );

  // NOTE: We currently don't have any effects for this, but without this file
  // the libraryCollectionDocumentTemplatesService will not be instantiated
}
