import { getModifiedFilterCount } from '$/app/utils/page-filters/page-filter.utils';
import { Dictionary } from '$shared/types';
import { Component, computed, input } from '@angular/core';
import { pick } from 'lodash';
import { AlcCommonModule } from '../../alc-common.module';
import { AlcPipesModule } from '../../pipes';

@Component({
  selector: 'alc-filter-button',
  standalone: true,
  imports: [AlcCommonModule, AlcPipesModule],
  template: `
    <button
      mat-icon-button
      aria-label="Filter button"
      class="{{ 'theme' | alcTheme }}"
    >
      <mat-icon
        aria-hidden="false"
        [matBadge]="badgeCount()"
        matBadgePosition="above after"
        [matBadgeHidden]="!badgeCount()"
        matBadgeColor="accent"
        svgIcon="filter_outline"
      />
    </button>
  `,
  styles: `
    ::ng-deep .mat-badge-medium .mat-badge-content {
      min-width: 22px;
      min-height: 22px;
      width: initial;
      height: initial;
      padding-inline: 4px;
      border-radius: 9999px;
    }
  `
})
export class AlcFilterButtonComponent {
  defaultFilters = input.required<Dictionary<any>>();
  filters = input.required<Dictionary<any>>();
  /**
   * This input prevents unused filters from being factored in to the
   * modified filter count.
   *
   * // NOTE: This is needed because resident filters were implemented in a
   * different way than page filters. Page filters are unique to a certain page
   * and are preserved in a store feature for that page. Resident filters on the
   * other hand are more global. They are contained in a single store feature
   * called residentFilters that get consumed by multiple pages. Because of
   * this, some pages may not need to show all the resident filters that are
   * available in the store.
   */
  includeFilters = input<string[]>([]);

  protected badgeCount = computed(() => {
    let filters = this.filters();
    let defaults = this.defaultFilters();

    if (this.includeFilters().length) {
      this.includeFilters().forEach((filter) => {
        filters = pick(filters, filter);
        defaults = pick(defaults, filter);
      });
    }

    return getModifiedFilterCount({
      filters: this.filters(),
      defaults: this.defaultFilters()
    });
  });
}
