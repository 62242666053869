import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResidentContactsEffects } from '$/app/store/resident-contacts/resident-contacts.effects';
import { residentContactsReducer } from '$/app/store/resident-contacts/resident-contacts.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('residentContacts', residentContactsReducer),
    EffectsModule.forFeature([ResidentContactsEffects])
  ],
  providers: [ResidentContactsEffects]
})
export class ResidentContactsStoreModule {}
