import { Params, Pharmacy } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const PharmaciesGeneralActions = createActionGroup({
  source: 'Pharmacies General',
  events: {
    'Clear Pharmacies': (params: Params) => ({ params }),
    'Add Pharmacies': props<{ pharmacies: Pharmacy[] }>()
  }
});
