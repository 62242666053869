import { ILibraryDocumentTemplate, Paginated } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const LibraryDocumentTemplatesApiActions = createActionGroup({
  source: 'Library Document Templates API',
  events: {
    // Success Actions
    'Load Library Document Templates Success': props<{
      libraryDocumentTemplates: Paginated<ILibraryDocumentTemplate>;
    }>(),
    'Get Library Document Templates Success': props<{
      libraryDocumentTemplates: Paginated<ILibraryDocumentTemplate>;
    }>(),
    'Fetch Library Document Template Success': props<{
      libraryDocumentTemplate: ILibraryDocumentTemplate;
    }>(),
    'Create Library Document Template Success': props<{
      libraryDocumentTemplate: ILibraryDocumentTemplate;
    }>(),
    'Update Library Document Template Success': props<{
      libraryDocumentTemplate: ILibraryDocumentTemplate;
    }>(),
    'Delete Library Document Template Success': props<{ id: string }>(),
    // Fail Actions
    'Load Library Document Templates Fail': props<{ error: Error }>(),
    'Get Library Document Templates Fail': props<{ error: Error }>(),
    'Fetch Library Document Template Fail': props<{ error: Error }>(),
    'Create Library Document Template Fail': props<{ error: Error }>(),
    'Update Library Document Template Fail': props<{ error: Error }>(),
    'Delete Library Document Template Fail': props<{ error: Error }>()
  }
});

export const LibraryDocumentTemplatesWsActions = createActionGroup({
  source: 'Library Document Templates WS',
  events: {
    'Library Document Template Created': props<{
      libraryDocumentTemplate: ILibraryDocumentTemplate;
    }>(),
    'Library Document Template Patched': props<{
      libraryDocumentTemplate: ILibraryDocumentTemplate;
    }>(),
    'Library Document Template Removed': props<{ id: string }>()
  }
});
