import { Table } from '$/models/data/base';
import { ResidentContactRelationshipId } from '$shared/lookups/resident-contact-relationships.lookup';

export class ResidentContact extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  notes?: string;
  relationshipId?: ResidentContactRelationshipId;
  isPrimary?: boolean;
  isEmergency?: boolean;
  isPowerOfAttorney?: boolean;
  isConservator?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  [key: string]: any;
}
