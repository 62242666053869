import { TOmittedAmount } from '$shared/medications/omitted-amount.schema';
import { createEntityAdapter } from '@ngrx/entity';

export const omittedAmountsAdapter = createEntityAdapter<TOmittedAmount>();

export const initialState = omittedAmountsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
