import { Lookups, lookups } from '$shared/lookups';
import { Pipe, PipeTransform } from '@angular/core';

type LookupKeys = keyof Lookups;

/**
 * Allows you to lookup a field from a lookup table using dot notation (table[.column]).
 * If field is not specified the pipe will default to the name field.
 */
@Pipe({ name: 'lookupTable', standalone: true })
export class AlcLookupTablePipe implements PipeTransform {
  transform<T extends LookupKeys>(table: T): Lookups[T] {
    if (!table) {
      return null;
    }

    return lookups[table];
  }
}
