import { FeathersService } from '$/app/services/feathers.service';
import { RolePermissionsApiActions } from '$/app/store/role-permissions/actions';
import { toUpdated } from '$/app/utils';
import { RolePermission } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Params, Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RolePermissionsApiService {
  private rolePermissionsService: Service<RolePermission>;

  constructor(feathers: FeathersService, store: Store) {
    this.rolePermissionsService = feathers.client.service('role-permissions');

    this.rolePermissionsService.on('created', (rolePermission) => {
      store.dispatch(
        RolePermissionsApiActions.rolePermissionsCreated({ rolePermission })
      );
    });

    this.rolePermissionsService.on('patched', (rolePermission) => {
      rolePermission = toUpdated(rolePermission);

      store.dispatch(
        RolePermissionsApiActions.rolePermissionsPatched({ rolePermission })
      );
    });

    this.rolePermissionsService.on('removed', (rolePermission) => {
      store.dispatch(
        RolePermissionsApiActions.rolePermissionsRemoved({
          id: rolePermission.id
        })
      );
    });
  }

  create(
    rolePermission: MaybeArray<RolePermission>,
    _params: Params = { query: {} }
  ) {
    const promise = this.rolePermissionsService.create(
      rolePermission
    ) as Promise<RolePermission>;
    return from(promise);
  }

  delete(
    id: string,
    params: Params = { query: {} }
  ): Observable<RolePermission> {
    const promise = this.rolePermissionsService.remove(id, params);
    return from(promise);
  }
}
