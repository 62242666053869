import { IHydratedNotification } from '$shared/notifications';
import { createActionGroup, props } from '@ngrx/store';

export const NotificationsApiActions = createActionGroup({
  source: 'Notifications API',
  events: {
    'Load Notifications Success': props<{
      notifications: IHydratedNotification[];
    }>(),
    'Load Notifications Fail': props<{ error: Error }>(),
    'Get Notifications Success': props<{
      notifications: IHydratedNotification[];
    }>(),
    'Get Notifications Fail': props<{ error: Error }>(),
    'Get Notification Success': props<{
      notification: IHydratedNotification;
    }>(),
    'Get Notification Fail': props<{ error: Error }>(),
    'Update Notification Success': props<{
      notification: IHydratedNotification;
    }>(),
    'Update Notification Fail': props<{ error: Error }>(),
    'Notification Created': props<{
      notification: IHydratedNotification;
    }>(),
    'Notifications Created': props<{
      notifications: IHydratedNotification[];
    }>(),
    'Notification Patched': props<{
      notification: IHydratedNotification;
    }>(),
    'Notifications Patched': props<{
      notifications: IHydratedNotification[];
    }>(),
    'Notification Removed': props<{
      id: string;
    }>(),
    'Notifications Removed': props<{
      ids: string[];
    }>(),
    'All Notifications Archived': props<{
      notifications: IHydratedNotification[];
    }>(),
    'All Notifications Read': props<{
      notifications: IHydratedNotification[];
    }>()
  }
});
