import { FeathersService } from '$/app/services/feathers.service';
import { OmittedMedicationsApiActions } from '$/app/store/omitted-medications/actions';
import { toUpdated } from '$/app/utils';
import { OmittedMedication, Paginated, Params, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OmittedMedicationsApiService {
  private omittedMedicationsService: Service<OmittedMedication>;

  constructor(feathers: FeathersService, store: Store) {
    this.omittedMedicationsService = feathers.client.service(
      'omitted-medications'
    );

    this.omittedMedicationsService.on('created', (omittedMedication) => {
      store.dispatch(
        OmittedMedicationsApiActions.omittedMedicationsCreated({
          omittedMedication
        })
      );
    });

    this.omittedMedicationsService.on('patched', (omittedMedication) => {
      omittedMedication = toUpdated(omittedMedication);

      store.dispatch(
        OmittedMedicationsApiActions.omittedMedicationsPatched({
          omittedMedication
        })
      );
    });

    this.omittedMedicationsService.on('removed', (omittedMedication) => {
      store.dispatch(
        OmittedMedicationsApiActions.omittedMedicationsRemoved({
          id: omittedMedication.id
        })
      );
    });
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<OmittedMedication>> {
    const promise = this.omittedMedicationsService.find(params) as Promise<
      Paginated<OmittedMedication>
    >;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<OmittedMedication> {
    const promise = this.omittedMedicationsService.get(id, params);
    return from(promise);
  }

  create(
    omittedMedication: MaybeArray<OmittedMedication>,
    params: Params = { query: {} }
  ) {
    const promise = this.omittedMedicationsService.create(
      omittedMedication,
      params
    );
    return from(promise);
  }

  patch(
    id: string,
    omittedMedication: Partial<OmittedMedication>,
    params: Params = { query: {} }
  ): Observable<Update<OmittedMedication>> {
    const promise = this.omittedMedicationsService.patch(
      id,
      omittedMedication,
      params
    );

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string): Observable<OmittedMedication> {
    const promise = this.omittedMedicationsService.remove(id);
    return from(promise);
  }
}
