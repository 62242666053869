import { FacilityProfilePageActions } from '$/app/pages/facilities/facility-detail/facility-profile/facility-profile.actions';
import { ResidentModalActions } from '$/app/pages/residents/resident-modal/resident.modal.actions';
import { EffectHelpersService, OverlayService } from '$/app/services';
import { RoomsApiService } from '$/app/services/api/rooms.service';
import { RoomFormPageActions } from '$/app/shared/pages/forms/room-form/room-form.page.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Paginated, Room, RoomGraph } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap
} from 'rxjs/operators';
import { RoomsApiActions } from './rooms.actions';

@Injectable()
export class RoomsEffects {
  private readonly actions$ = inject(Actions);
  private readonly roomsService = inject(RoomsApiService);
  private readonly overlay = inject(OverlayService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadRooms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentModalActions.loadRooms,
        FacilityProfilePageActions.loadRooms
      ),
      switchMap((action) => {
        return this.roomsService.getAll(action.params).pipe(
          getAllPages(this.roomsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((rooms: Paginated<RoomGraph>) => {
            const data = new ApiData('rooms', rooms);
            data.setPrimaryAction(RoomsApiActions.loadRoomsSuccess);
            return data.getActions();
          }),
          catchError((error) => {
            return of(RoomsApiActions.loadRoomsFail({ error }));
          })
        );
      })
    );
  });

  createRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomFormPageActions.createRoom),
      this.effectHelpers.apiRequest({
        description: 'Create Room',
        onRequest: (action) => this.roomsService.create(action.room),
        onSuccess: (room) => RoomsApiActions.createRoomSuccess({ room }),
        onError: (error) => RoomsApiActions.createRoomFail({ error })
      })
    );
  });

  updateRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomFormPageActions.updateRoom),
      this.effectHelpers.apiRequest({
        description: 'Update Room',
        onRequest: (action) =>
          this.roomsService.patch(action.id, action.changes, action.params),
        onSuccess: (room) => RoomsApiActions.updateRoomSuccess({ room }),
        onError: (error) => RoomsApiActions.updateRoomFail({ error })
      })
    );
  });

  deleteRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityProfilePageActions.deleteRoom),
      exhaustMap((action) => {
        return this.roomsService.delete(action.id).pipe(
          map((room: Room) => {
            return RoomsApiActions.deleteRoomSuccess({
              id: room.id
            });
          }),
          catchError((error) => {
            return of(RoomsApiActions.deleteRoomFail({ error }));
          })
        );
      })
    );
  });

  deleteRoomFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RoomsApiActions.deleteRoomFail),
        exhaustMap(async (action) => {
          let message = action.error.message;
          if (action.error.message === 'Foreign Key Violation') {
            message = 'Cannot delete room with residents';
          }
          await this.overlay.showToast('failure', message);
        })
      );
    },
    { dispatch: false }
  );
}
