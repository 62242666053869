export const NotificationEventNames = {
  SINGLE_MISSED_MEDICATION_TASK_ALERT: 'medicationTask.alert.missed',
  MULTIPLE_MISSED_MEDICATION_TASKS_ALERT: 'medicationTask.alert.missedMultiple',
  MULTIPLE_RESIDENT_MISSED_MEDICATION_TASKS_ALERT:
    'medicationTask.alert.missedMultipleResidents',

  MISSED_PRN_RESPONSE_ALERT: 'prn.alert.missedResponse',

  OMITTED_MEDICATION_ALERT: 'medicationTask.alert.omitted',
  MULTIPLE_OMITTED_MEDICATIONS_ALERT: 'medicationTask.alert.omittedMultiple',

  SINGLE_MISSED_CARE_TASK_ALERT: 'careTask.alert.missed',
  MULTIPLE_MISSED_CARE_TASKS_ALERT: 'careTask.alert.missedMultiple',
  MULTIPLE_RESIDENT_MISSED_CARE_TASKS_ALERT:
    'careTask.alert.missedMultipleResidents',

  OMITTED_CARE_TASK_ALERT: 'careTask.alert.omitted',

  NO_BOWEL_MOVEMENT_ALERT: 'notebooks.alert.noBowelMovement',
  REFUSED_FOOD_ALERT: 'notebooks.alert.refusedFood',
  REFUSED_WATER_ALERT: 'notebooks.alert.refusedWater',

  SINGLE_MEDICATION_TASK_REMINDER: 'medicationTask.reminder.single',
  MULTIPLE_MEDICATION_TASKS_REMINDER: 'medicationTask.reminder.multiple',
  MULTIPLE_RESIDENT_MEDICATION_TASKS_REMINDER:
    'medicationTask.reminder.multipleResidents',

  PRN_RESPONSE_REMINDER: 'prn.reminder.response',

  SINGLE_CARE_TASK_REMINDER: 'careTask.reminder.single',
  MULTIPLE_CARE_TASKS_REMINDER: 'careTask.reminder.multiple',
  MULTIPLE_RESIDENT_CARE_TASKS_REMINDER: 'careTask.reminder.multipleResidents'
} as const;

export type NotificationEventNameValues =
  (typeof NotificationEventNames)[keyof typeof NotificationEventNames];

export type NotificationTypeValue = 'alert' | 'reminder' | 'message';

export interface INotificationTemplate {
  title: string;
  messageTemplate: string;
  urlTemplate: string;
  type: NotificationTypeValue;
  icon: string;
}

export const NotificationEventTemplateMap: Record<
  NotificationEventNameValues,
  INotificationTemplate
> = {
  // MISSED MEDICATIONS
  [NotificationEventNames.SINGLE_MISSED_MEDICATION_TASK_ALERT]: {
    title: 'Missed Medication Task',
    messageTemplate:
      '<b>{{ residentName }}</b> did not receive <b>{{ medicationName }}</b> scheduled for <b>{{ scheduledFor }}</b>',
    urlTemplate:
      '/dashboard/overview/missed-medications?type=custom&startDate={{startDate}}&endDate={{endDate}}',
    type: 'alert',
    icon: 'assets/icon/alcomy/pill_check.svg'
  },
  [NotificationEventNames.MULTIPLE_MISSED_MEDICATION_TASKS_ALERT]: {
    title: 'Missed Medication Tasks',
    messageTemplate:
      '<b>{{ residentName }}</b> has <b>{{ taskCount }}</b> missed routine medications scheduled for <b>{{ scheduledFor }}</b>',
    urlTemplate:
      '/dashboard/overview/missed-medications?type=custom&startDate={{startDate}}&endDate={{endDate}}',
    type: 'alert',
    icon: 'assets/icon/alcomy/pill_check.svg'
  },
  [NotificationEventNames.MULTIPLE_RESIDENT_MISSED_MEDICATION_TASKS_ALERT]: {
    title: 'Missed Medication Tasks',
    messageTemplate:
      '<b>{{ residentCount }}</b> residents have <b>{{ taskCount }}</b> missed routine medications scheduled for <b>{{ scheduledFor }}</b>',
    urlTemplate:
      '/dashboard/overview/missed-medications?type=custom&startDate={{startDate}}&endDate={{endDate}}',
    type: 'alert',
    icon: 'assets/icon/alcomy/pill_check.svg'
  },
  // MISSED PRN RESPONSES
  [NotificationEventNames.MISSED_PRN_RESPONSE_ALERT]: {
    title: 'Missed PRN Response',
    messageTemplate:
      '<b>{{ medicationName }}</b> given to <b>{{ residentName }}</b> at <b>{{ time }}</b> did not receive a response',
    urlTemplate: '/dashboard/medications/prn',
    type: 'alert',
    icon: 'assets/icon/material/pill.svg'
  },
  // OMITTED MEDICATIONS
  [NotificationEventNames.OMITTED_MEDICATION_ALERT]: {
    title: 'Omitted Medication',
    messageTemplate:
      '<b>{{ medicationName }}</b> scheduled for <b>{{ residentName }}</b> at <b>{{ time }}</b> was omitted',
    urlTemplate:
      '/residents/{{ resident.id }}/medications/mar/{{ medication.batchId }}/tasks/{{ medicationTask.id }}',
    type: 'alert',
    icon: 'assets/icon/alcomy/pill_omit.svg'
  },
  [NotificationEventNames.MULTIPLE_OMITTED_MEDICATIONS_ALERT]: {
    title: 'Omitted Medications',
    messageTemplate:
      '<b>{{ omittedMedicationCount }}</b> medications scheduled for <b>{{ residentName }}</b> at <b>{{ time }}</b> were omitted',
    urlTemplate:
      'dashboard/medications/resident-tasks?residentId={{ resident.id }}&time={{time}}&date={{date}}',
    type: 'alert',
    icon: 'assets/icon/alcomy/pill_omit.svg'
  },

  // MISSED CARE TASKS
  [NotificationEventNames.SINGLE_MISSED_CARE_TASK_ALERT]: {
    title: 'Missed Care Task',
    messageTemplate:
      '<b>{{ carePlanItem.name }}</b> for <b>{{ residentName }}</b> scheduled for <b>{{ scheduledFor }}</b> has not been completed',
    urlTemplate: '/dashboard/care?date={{ date }}',
    type: 'alert',
    icon: 'assets/icon/material/healing.svg'
  },
  [NotificationEventNames.MULTIPLE_MISSED_CARE_TASKS_ALERT]: {
    title: 'Missed Care Tasks',
    messageTemplate:
      '<b>{{ residentName }}</b> has <b>{{ taskCount }}</b> care tasks scheduled for <b>{{ scheduledFor }}</b> that were not completed',
    urlTemplate: '/dashboard/care?date={{ date }}',
    type: 'alert',
    icon: 'assets/icon/material/healing.svg'
  },
  [NotificationEventNames.MULTIPLE_RESIDENT_MISSED_CARE_TASKS_ALERT]: {
    title: 'Missed Care Tasks',
    messageTemplate:
      '<b>{{ residentCount }}</b> residents have <b>{{ taskCount }}</b> care tasks scheduled for <b>{{ scheduledFor }}</b> that were not completed',
    urlTemplate: '/dashboard/care?date={{ date }}',
    type: 'alert',
    icon: 'assets/icon/material/healing.svg'
  },
  // OMITTED CARE TASKS
  [NotificationEventNames.OMITTED_CARE_TASK_ALERT]: {
    title: 'Omitted Care Task',
    messageTemplate: `The care task <b>{{ carePlanItem.name }}</b> scheduled for <b>{{ residentName }}</b> at <b>{{ time }}</b> was omitted`,
    urlTemplate: `/residents/{{ residentId }}/careplan/caretask/{{ careTaskId }}`,
    type: 'alert',
    icon: 'assets/icon/material/healing.svg'
  },
  // NO BOWEL MOVEMENT
  [NotificationEventNames.NO_BOWEL_MOVEMENT_ALERT]: {
    title: 'No Bowel Movement',
    messageTemplate:
      '<b>{{ residentName }}</b> has not had a bowel movement in <b>{{ days }}</b> days',
    urlTemplate: '/residents/{{ resident.id }}/notebook/bowel-movements',
    type: 'alert',
    icon: 'assets/icon/material/poop.svg'
  },
  // REFUSED FOOD
  [NotificationEventNames.REFUSED_FOOD_ALERT]: {
    title: 'Refused Food',
    messageTemplate:
      '<b> {{ residentName }} </b> refused to eat their <b>{{ mealType }}</b> at <b>{{ time }}</b>',
    urlTemplate: '/residents/{{ resident.id }}/notebook/food',
    type: 'alert',
    icon: 'assets/icon/material/silverware.svg'
  },
  // REFUSED WATER
  [NotificationEventNames.REFUSED_WATER_ALERT]: {
    title: 'Refused Water',
    messageTemplate:
      '<b>{{ residentName }}</b> refused to drink <b>water</b> at <b>{{ time }}</b>',
    urlTemplate: '/residents/{{ resident.id }}/notebook/water',
    type: 'alert',
    icon: 'assets/icon/material/water.svg'
  },
  // MEDICATION TASK REMINDERS
  [NotificationEventNames.SINGLE_MEDICATION_TASK_REMINDER]: {
    title: 'Medication Task Reminder',
    messageTemplate:
      '<b>{{ residentName }}</b> is scheduled to receive <b>{{ medicationName }}</b> at <b>{{ scheduledFor }}</b>',
    urlTemplate: '/dashboard/medications/tasks',
    type: 'reminder',
    icon: 'assets/icon/alcomy/pill_check.svg'
  },
  [NotificationEventNames.MULTIPLE_MEDICATION_TASKS_REMINDER]: {
    title: 'Medication Task Reminder',
    messageTemplate:
      '<b>{{ residentName }}</b> is scheduled to receive <b>{{ taskCount }}</b> routine medications at <b>{{ scheduledFor }}</b>',
    urlTemplate: '/dashboard/medications/tasks',
    type: 'reminder',
    icon: 'assets/icon/alcomy/pill_check.svg'
  },
  [NotificationEventNames.MULTIPLE_RESIDENT_MEDICATION_TASKS_REMINDER]: {
    title: 'Medication Task Reminder',
    messageTemplate:
      '<b>{{ residentCount }}</b> residents are scheduled to receive <b>{{ taskCount }}</b> routine medications at <b>{{ scheduledFor }}</b>',
    urlTemplate: '/dashboard/medications/tasks',
    type: 'reminder',
    icon: 'assets/icon/alcomy/pill_check.svg'
  },
  // PRN RESPONSE REMINDERS
  [NotificationEventNames.PRN_RESPONSE_REMINDER]: {
    title: 'PRN Response Reminder',
    messageTemplate:
      '<b>{{ medicationName }}</b> given to <b>{{ residentName }}</b> at <b>{{ time }}</b> is awaiting a response',
    urlTemplate: '/dashboard/medications/prn',
    type: 'reminder',
    icon: 'assets/icon/material/pill.svg'
  },
  // CARE TASK REMINDERS
  [NotificationEventNames.SINGLE_CARE_TASK_REMINDER]: {
    title: 'Care Task Reminder',
    messageTemplate:
      'The care task <b>{{ carePlanItem.name }}</b> for <b>{{ residentName }}</b> is scheduled for <b>{{ time }}</b>',
    urlTemplate: '/dashboard/care?date={{ date }}',
    type: 'reminder',
    icon: 'assets/icon/material/healing.svg'
  },
  [NotificationEventNames.MULTIPLE_CARE_TASKS_REMINDER]: {
    title: 'Care Task Reminder',
    messageTemplate:
      '<b>{{ taskCount }}</b> care tasks for <b>{{ residentName }}</b> are scheduled for <b>{{ time }}</b>',
    urlTemplate: '/dashboard/care?date={{ date }}',
    type: 'reminder',
    icon: 'assets/icon/material/healing.svg'
  },
  [NotificationEventNames.MULTIPLE_RESIDENT_CARE_TASKS_REMINDER]: {
    title: 'Care Task Reminder',
    messageTemplate:
      '<b>{{ taskCount }}</b> care tasks for <b>{{ residentCount }}</b> residents are scheduled for <b>{{ time }}</b>',
    urlTemplate: '/dashboard/care?date={{ date }}',
    type: 'reminder',
    icon: 'assets/icon/material/healing.svg'
  }
};
