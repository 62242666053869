import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IPositionLog } from '$shared/services/position-log';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const positionLogsAdapter = createEntityAdapter<IPositionLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<IPositionLog> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = positionLogsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
