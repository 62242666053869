import { FeathersService } from '$/app/services/feathers.service';
import { ResidentsWebsocketActions } from '$/app/store/residents/actions/residents-api.actions';
import { toUpdated } from '$/app/utils';
import { Paginated, Params, ResidentGraph, Update } from '$/models';
import { IResident } from '$shared/residents/residents.interface';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ResidentsApiService {
  private residentsService: Service<IResident>;

  constructor(feathers: FeathersService, store: Store) {
    this.residentsService = feathers.client.service('residents');

    this.residentsService.on('created', (resident) => {
      store.dispatch(ResidentsWebsocketActions.residentsCreated({ resident }));
    });

    this.residentsService.on('patched', (resident) => {
      store.dispatch(ResidentsWebsocketActions.residentsPatched({ resident }));
    });

    this.residentsService.on('removed', (resident) => {
      store.dispatch(
        ResidentsWebsocketActions.residentsRemoved({ id: resident.id })
      );
    });
  }

  getAll(params: Params): Observable<Paginated<IResident>> {
    const promise = this.residentsService.find(params) as Promise<
      Paginated<IResident>
    >;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<ResidentGraph> {
    const promise = this.residentsService.get(
      id,
      params
    ) as Promise<ResidentGraph>;
    return from(promise);
  }

  create(resident: IResident, params: Params = { query: {} }) {
    const promise = this.residentsService.create(resident, params);
    return from(promise);
  }

  patch(
    id: string,
    resident: Partial<IResident>,
    params: Params = { query: {} }
  ): Observable<Update<IResident>> {
    const promise = this.residentsService.patch(id, resident, params);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string, params: Params = { query: {} }): Observable<IResident> {
    const promise = this.residentsService.remove(id, params);
    return from(promise);
  }
}
