import { FacilityRoleDetailPageActions } from '$/app/pages/facilities/facility-detail/facility-role-detail/facility-role-detail.actions';
import {
  EffectHelpersService,
  RolePermissionsApiService
} from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, tap } from 'rxjs/operators';
import { RolePermissionsApiActions } from './actions';

@Injectable()
export class RolePermissionsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly rolePermissionsService = inject(RolePermissionsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  createRolePermission$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityRoleDetailPageActions.createRolePermission),
        exhaustMap((action) => {
          return this.rolePermissionsService.create(action.rolePermission);
        }),
        catchError((error) => {
          this.store.dispatch(
            RolePermissionsApiActions.createRolePermissionFail({ error })
          );
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  createRolePermissionFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RolePermissionsApiActions.createRolePermissionFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteRolePermission$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityRoleDetailPageActions.deleteRolePermission),
        exhaustMap((action) => {
          return this.rolePermissionsService
            .delete(action.id, action.params)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  RolePermissionsApiActions.deleteRolePermissionFail({ error })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  deleteRolePermissionFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RolePermissionsApiActions.deleteRolePermissionFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
