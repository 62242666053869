import { FacilityUserGroup } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const facilityUserGroupsAdapter =
  createEntityAdapter<FacilityUserGroup>();

export interface State extends EntityState<FacilityUserGroup> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = facilityUserGroupsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
