import { MedicalEvent, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MedicalEventModalActions = createActionGroup({
  source: 'Medical Event Modal',
  events: {
    'Create Medical Event': props<{ medicalEvent: MedicalEvent }>(),
    'Update Medical Event': props<Update<MedicalEvent>>()
  }
});
