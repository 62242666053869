import { Allergy, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AllergiesApiActions = createActionGroup({
  source: 'Allergies API',
  events: {
    'Create Allergy Success': props<{ allergy: MaybeArray<Allergy> }>(),
    'Update Allergy Success': props<{ allergy: Update<Allergy> }>(),
    'Delete Allergy Success': props<{ id: string }>(),

    'Create Allergy Fail': props<{ error: Error }>(),
    'Update Allergy Fail': props<{ error: Error }>(),
    'Delete Allergy Fail': props<{ error: Error }>()
  }
});

export const AllergiesWsActions = createActionGroup({
  source: 'Allergies WS',
  events: {
    'Allergies Created': props<{ allergy: Allergy }>(),
    'Allergies Patched': props<{ allergy: Allergy }>(),
    'Allergies Removed': props<{ id: string }>()
  }
});

export const AllergiesGeneralActions = createActionGroup({
  source: 'Allergies General',
  events: {
    'Clear Allergies': emptyProps(),
    'Add Allergies': props<{ allergies: Allergy[] }>()
  }
});
