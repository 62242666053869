import { NotificationMenuPageActions } from '$/app/pages/facility-workspace/notification-menu/notification-menu.actions';
import { getRecords, toUpdated } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import { map } from 'lodash';
import { NotificationsGeneralActions } from './actions';
import { NotificationsApiActions } from './actions/notifications-api.actions';
import { initialState, notificationsAdapter } from './notifications.state';

export const notificationsFeature = createFeature({
  name: 'notifications',
  reducer: createReducer(
    initialState,

    on(
      NotificationMenuPageActions.loadNotifications,
      NotificationMenuPageActions.refreshNotifications,
      NotificationMenuPageActions.pullRefreshNotifications,
      NotificationMenuPageActions.readNotifications,
      NotificationMenuPageActions.archiveNotification,
      NotificationMenuPageActions.archiveAllNotifications,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(NotificationsApiActions.loadNotificationsSuccess, (state, action) => {
      const data = getRecords(action.notifications);

      return notificationsAdapter.setAll(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(NotificationsApiActions.updateNotificationSuccess, (state, action) => {
      return notificationsAdapter.upsertOne(action.notification, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(
      NotificationsApiActions.loadNotificationsFail,
      NotificationsApiActions.updateNotificationFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      NotificationsApiActions.notificationCreated,
      NotificationsApiActions.notificationPatched,
      NotificationsApiActions.getNotificationSuccess,
      (state, action) => {
        return notificationsAdapter.upsertOne(action.notification, {
          ...state
        });
      }
    ),
    on(
      NotificationsApiActions.notificationsCreated,
      NotificationsApiActions.notificationsPatched,
      (state, action) => {
        return notificationsAdapter.upsertMany(action.notifications, {
          ...state
        });
      }
    ),

    on(NotificationsApiActions.notificationRemoved, (state, action) => {
      return notificationsAdapter.removeOne(action.id, { ...state });
    }),

    on(NotificationsApiActions.notificationsRemoved, (state, action) => {
      return notificationsAdapter.removeMany(action.ids, { ...state });
    }),

    on(NotificationsApiActions.allNotificationsArchived, (state, action) => {
      return notificationsAdapter.removeMany(map(action.notifications, 'id'), {
        ...state
      });
    }),

    on(NotificationsApiActions.allNotificationsRead, (state, action) => {
      const updates = map(action.notifications, (notification) => {
        return toUpdated(notification);
      });

      return notificationsAdapter.updateMany(updates, { ...state });
    }),

    on(NotificationsGeneralActions.addNotifications, (state, action) => {
      return notificationsAdapter.upsertMany(action.notifications, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(NotificationsGeneralActions.clearNotifications, () => {
      return notificationsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
