import { IFile } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FilesGeneralActions = createActionGroup({
  source: 'Files General',
  events: {
    'Clear Files': emptyProps(),
    'Add Files': props<{ files: IFile[] }>()
  }
});
