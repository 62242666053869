import { BehaviorLogsSelectors } from '$/app/store/behavior-logs';
import { BloodPressureLogsSelectors } from '$/app/store/blood-pressure-logs';
import { BloodSugarLogsSelectors } from '$/app/store/blood-sugar-logs';
import { BodyCheckLogsSelectors } from '$/app/store/body-check-logs';
import { BmLogsSelectors } from '$/app/store/bowel-movement-logs';
import { FoodLogsSelectors } from '$/app/store/food-logs';
import { MoodLogsSelectors } from '$/app/store/mood-logs';
import { OxygenLogsSelectors } from '$/app/store/oxygen-logs';
import { PositionLogsSelectors } from '$/app/store/position-logs';
import { SeizureLogsSelectors } from '$/app/store/seizure-logs';
import { SleepLogsSelectors } from '$/app/store/sleep-logs';
import { TemperatureLogsSelectors } from '$/app/store/temperature-logs';
import { UrineLogsSelectors } from '$/app/store/urine-logs/urine-logs.selectors';
import { WaterLogsSelectors } from '$/app/store/water-logs';
import { WeightLogsSelectors } from '$/app/store/weight-logs';
import { createSelector } from '@ngrx/store';

export const selectTaskLogs = createSelector(
  BehaviorLogsSelectors.selectLogsByMedicationTask,
  BloodPressureLogsSelectors.selectLogsByMedicationTask,
  BloodSugarLogsSelectors.selectLogsByMedicationTask,
  BodyCheckLogsSelectors.selectLogsByMedicationTask,
  BmLogsSelectors.selectLogsByMedicationTask,
  FoodLogsSelectors.selectLogsByMedicationTask,
  MoodLogsSelectors.selectLogsByMedicationTask,
  OxygenLogsSelectors.selectLogsByMedicationTask,
  PositionLogsSelectors.selectLogsByMedicationTask,
  SeizureLogsSelectors.selectLogsByMedicationTask,
  SleepLogsSelectors.selectLogsByMedicationTask,
  TemperatureLogsSelectors.selectLogsByMedicationTask,
  UrineLogsSelectors.selectLogsByMedicationTask,
  WaterLogsSelectors.selectLogsByMedicationTask,
  WeightLogsSelectors.selectLogsByMedicationTask,
  (
    behaviorLogs,
    bloodPressureLogs,
    bloodSugarLogs,
    bodyCheckLogs,
    bmLogs,
    foodLogs,
    moodLogs,
    oxygenLogs,
    positionLogs,
    seizureLogs,
    sleepLogs,
    temperatureLogs,
    urineLogs,
    waterLogs,
    weightLogs
  ) => {
    const logs = {
      ...behaviorLogs,
      ...bloodPressureLogs,
      ...bloodSugarLogs,
      ...bodyCheckLogs,
      ...bmLogs,
      ...foodLogs,
      ...moodLogs,
      ...oxygenLogs,
      ...positionLogs,
      ...seizureLogs,
      ...sleepLogs,
      ...temperatureLogs,
      ...urineLogs,
      ...waterLogs,
      ...weightLogs
    };

    return logs;
  }
);

export const DerivedMedicationTasksSelectors = {
  selectTaskLogs
};
