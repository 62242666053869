import { Directive, ElementRef, inject, Input, OnChanges } from '@angular/core';

@Directive({ selector: '[alcColor]', standalone: true })
export class AlcColorDirective implements OnChanges {
  private readonly element = inject(ElementRef);

  @Input() alcColor: string;

  ngOnChanges() {
    if (this.alcColor) {
      this.element.nativeElement.setAttribute(
        'style',
        `color: var(--ion-color-${this.alcColor})`
      );
    }
  }
}
