import { AlcAction, FacilityUserPhone } from '$/models';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { createActionGroup } from '@ngrx/store';

export const NewFacilityUserModalActions = createActionGroup({
  source: 'New Facility User Modal',
  events: {
    'Create Facility User': AlcAction<{
      facilityUser: IFacilityUser;
      roleIds: string[];
      phones: FacilityUserPhone[];
    }>()
  }
});
