import { AlcAction, Params, Update } from '$/models';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserDetailPageActions = createActionGroup({
  source: 'Facility User Detail Page',
  events: {
    'Fetch Facility User': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Delete Facility User': props<{ id: string }>(),
    'Delete Facility User Group Member': props<{ id: string }>(),
    'Delete Facility User Phone': props<{ id: string }>(),
    'Update Facility User': props<Update<IFacilityUser>>(),

    'Update Facility User Roles': AlcAction<{
      id: string;
      roleIds: string[];
    }>(),

    'Remove Facility User Role': AlcAction<{
      id: string;
      roleId: string;
    }>()
  }
});
