<div
  class="form-divider"
  [ngClass]="{
    'appearance-underline': appearance === 'underline',
    'appearance-fill': appearance === 'fill',
    'appearance-plain': appearance === 'plain'
  }"
>
  <ng-content />
</div>
