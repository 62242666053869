import { isNil, toNumber } from 'lodash';
import { Logger } from '../logger';
import { AmountTypeId } from '../lookups/amount-types.lookup';
import { MeasurementId } from '../lookups/measurements.lookup';
import { MedicationFormId } from '../lookups/medication-forms.lookup';
import { convertToQty } from './convert-to-qty';
import { formatAmount } from './format-amount';
import { multiplyAmountString } from './multiply-amount-string';
import { AmountDisplayOptions } from './stringify-amount';

export type TAmount = {
  amountTypeId: AmountTypeId;
  amount: string;
  strength?: string | null;
  medicationInventoryItemId?: string | null | undefined;
};

export class Amount implements TAmount {
  amountTypeId!: AmountTypeId;
  amount!: string;
  strength?: string | null | undefined;
  medicationInventoryItemId?: string | null | undefined;

  constructor(
    amount: TAmount,
    public measurementId?: MeasurementId,
    public medicationFormId?: MedicationFormId
  ) {
    Object.assign(this, amount);
  }

  setMeasurementId(measurementId: MeasurementId): void {
    this.measurementId = measurementId;
  }

  setMedicationFormId(medicationFormId: MedicationFormId): void {
    this.medicationFormId = medicationFormId;
  }

  getQuantity(): number | undefined {
    Logger.assert(
      this.measurementId,
      'measurementId must be passed to the constructor or initialized via setMeasurementId prior to calling getQuantity.'
    );

    switch (this.amountTypeId) {
      case 'qty':
        return toNumber(this.amount);

      case 'total':
        if (!this.strength) {
          return;
        }

        return convertToQty(this.amount, this.strength, this.measurementId);

      default:
        Logger.throw(`Invalid amount type: ${this.amountTypeId}`);
        break;
    }
  }

  getTotal(): string | undefined {
    switch (this.amountTypeId) {
      case 'qty':
        // TODO(2024-02-14): This technically should not be possible, but we have instances
        // where users have entered 0 or . as the strength. We need to evaluate what to do about
        // that both in how we should fix the current records and how we should validate against
        // those patterns in the future.
        if (
          !this.amount ||
          !this.strength ||
          ['.', '0'].includes(this.strength)
        ) {
          return;
        }

        return multiplyAmountString(+this.amount, this.strength, {
          showMeasurement: 'none'
        });

      case 'total':
        return this.amount || undefined;

      default:
        Logger.throw(`Invalid amount type: ${this.amountTypeId}`);
    }
  }

  updateStrength(strength: string): void {
    const total = this.getTotal();

    if (total) {
      if (this.amountTypeId === 'qty') {
        const newQuantity = convertToQty(total, strength, this.measurementId);

        if (!isNil(newQuantity)) {
          this.amount = newQuantity.toString();
          this.strength = strength;
        }
      } else {
        this.strength = strength;
      }
    }
  }

  getStrength(options?: AmountDisplayOptions): string | undefined {
    Logger.assert(
      this.measurementId,
      'measurementId must be passed to the constructor or initialized via setMeasurementId prior to calling getStrength.'
    );

    if (!this.strength) {
      return;
    }

    return formatAmount(
      this.strength,
      this.measurementId,
      this.medicationFormId,
      options
    );
  }

  toQuantityString(options?: AmountDisplayOptions): string {
    Logger.assert(
      this.measurementId,
      'measurementId must be passed to the constructor or initialized via setMeasurementId prior to calling toQuantityString.'
    );

    const quantity = this.getQuantity();

    if (isNil(quantity)) {
      return `${formatAmount(
        this.amount,
        this.measurementId,
        this.medicationFormId,
        options
      )}`;
    }

    return `${quantity} x ${formatAmount(
      this.strength,
      this.measurementId,
      this.medicationFormId,
      options
    )}`;
  }

  toTotalString(options?: AmountDisplayOptions): string {
    Logger.assert(
      this.measurementId,
      'measurementId must be passed to the constructor or initialized via setMeasurementId prior to calling toTotalString.'
    );

    const total = this.getTotal();

    if (!total) {
      return `${formatAmount(
        this.amount,
        this.measurementId,
        this.medicationFormId,
        options
      )}`;
    }

    return `${formatAmount(
      total,
      this.measurementId,
      this.medicationFormId,
      options
    )}`;
  }

  toString(options?: AmountDisplayOptions): string {
    switch (this.amountTypeId) {
      case 'qty':
        return this.toQuantityString(options);
      case 'total':
        return this.toTotalString(options);
      default:
        Logger.throw(`Invalid amount type: ${this.amountTypeId}`);
    }
  }

  toJSON(): any {
    const { measurementId, medicationFormId, ...rest } = this;
    return rest;
  }
}
