import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  MedicationInventoryMovementDetailActions,
  MedicationInventoryMovementFormActions,
  MedicationInventoryMovementsApiActions,
  MedicationInventoryMovementsGeneralActions,
  MedicationInventoryMovementsListActions
} from './actions';
import {
  State,
  initialState,
  medicationInventoryMovementsAdapter
} from './medication-inventory-movements.state';

const reducer = createReducer(
  initialState,

  on(
    MedicationInventoryMovementsListActions.loadMedicationInventoryMovements,
    MedicationInventoryMovementsListActions.getMedicationInventoryMovements,
    MedicationInventoryMovementDetailActions.fetchMedicationInventoryMovement,
    MedicationInventoryMovementFormActions.createMedicationInventoryMovement,
    MedicationInventoryMovementDetailActions.updateMedicationInventoryMovement,
    MedicationInventoryMovementDetailActions.deleteMedicationInventoryMovement,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.loadMedicationInventoryMovementsSuccess,
    (state, action) => {
      return medicationInventoryMovementsAdapter.setAll(
        action.medicationInventoryMovements.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.addMedicationInventoryMovements_DashboardRoute,
    (state, action) => {
      const medicationInventoryMovements = castArray(
        action.medicationInventoryMovement
      );
      return medicationInventoryMovementsAdapter.upsertMany(
        medicationInventoryMovements,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.getMedicationInventoryMovementsSuccess,
    (state, action) => {
      return medicationInventoryMovementsAdapter.upsertMany(
        action.medicationInventoryMovements.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.fetchMedicationInventoryMovementSuccess,
    (state, action) => {
      return medicationInventoryMovementsAdapter.upsertOne(
        action.medicationInventoryMovement,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.createMedicationInventoryMovementSuccess,
    (state, action) => {
      const medicationInventoryMovements = castArray(
        action.medicationInventoryMovement
      );

      return medicationInventoryMovementsAdapter.addMany(
        medicationInventoryMovements,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.updateMedicationInventoryMovementSuccess,
    (state, action) => {
      return medicationInventoryMovementsAdapter.updateOne(
        action.medicationInventoryMovement,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.deleteMedicationInventoryMovementSuccess,
    (state, action) => {
      return medicationInventoryMovementsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.loadMedicationInventoryMovementsFail,
    MedicationInventoryMovementsApiActions.getMedicationInventoryMovementsFail,
    MedicationInventoryMovementsApiActions.fetchMedicationInventoryMovementFail,
    MedicationInventoryMovementsApiActions.createMedicationInventoryMovementFail,
    MedicationInventoryMovementsApiActions.updateMedicationInventoryMovementFail,
    MedicationInventoryMovementsApiActions.deleteMedicationInventoryMovementFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.medicationInventoryMovementsCreated,
    MedicationInventoryMovementsApiActions.medicationInventoryMovementsPatched,
    (state, action) => {
      return medicationInventoryMovementsAdapter.upsertOne(
        action.medicationInventoryMovement,
        {
          ...state
        }
      );
    }
  ),

  on(
    MedicationInventoryMovementsApiActions.medicationInventoryMovementsRemoved,
    (state, action) => {
      return medicationInventoryMovementsAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(
    MedicationInventoryMovementsGeneralActions.addMedicationInventoryMovements,
    (state, action) => {
      return medicationInventoryMovementsAdapter.upsertMany(
        action.medicationInventoryMovements,
        {
          ...state,
          loading: false,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryMovementsGeneralActions.clearMedicationInventoryMovements,
    (state) => {
      return medicationInventoryMovementsAdapter.removeAll({
        ...state,

        loading: false,
        loaded: false,
        error: null
      });
    }
  )
);

export function medicationInventoryMovementsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
