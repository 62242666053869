import { ILibraryDocumentTemplatePacketTemplate, Paginated } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const LibraryDocumentTemplatePacketTemplatesApiActions =
  createActionGroup({
    source: 'Library Document Template Packet Templates API',
    events: {
      // Success Actions
      'Load Library Document Template Packet Templates Success': props<{
        libraryDocumentTemplatePacketTemplates: Paginated<ILibraryDocumentTemplatePacketTemplate>;
      }>(),
      'Get Library Document Template Packet Templates Success': props<{
        libraryDocumentTemplatePacketTemplates: Paginated<ILibraryDocumentTemplatePacketTemplate>;
      }>(),
      'Fetch Library Document Template Packet Template Success': props<{
        libraryDocumentTemplatePacketTemplate: ILibraryDocumentTemplatePacketTemplate;
      }>(),
      'Create Library Document Template Packet Template Success': props<{
        libraryDocumentTemplatePacketTemplate: ILibraryDocumentTemplatePacketTemplate;
      }>(),
      'Update Library Document Template Packet Template Success': props<{
        libraryDocumentTemplatePacketTemplate: ILibraryDocumentTemplatePacketTemplate;
      }>(),
      'Delete Library Document Template Packet Template Success': props<{
        id: string;
      }>(),
      // Fail Actions
      'Load Library Document Template Packet Templates Fail': props<{
        error: Error;
      }>(),
      'Get Library Document Template Packet Templates Fail': props<{
        error: Error;
      }>(),
      'Fetch Library Document Template Packet Template Fail': props<{
        error: Error;
      }>(),
      'Create Library Document Template Packet Template Fail': props<{
        error: Error;
      }>(),
      'Update Library Document Template Packet Template Fail': props<{
        error: Error;
      }>(),
      'Delete Library Document Template Packet Template Fail': props<{
        error: Error;
      }>()
    }
  });

export const LibraryDocumentTemplatePacketTemplatesWsActions =
  createActionGroup({
    source: 'Library Document Template Packet Templates WS',
    events: {
      'Library Document Template Packet Template Created': props<{
        libraryDocumentTemplatePacketTemplate: ILibraryDocumentTemplatePacketTemplate;
      }>(),
      'Library Document Template Packet Template Patched': props<{
        libraryDocumentTemplatePacketTemplate: ILibraryDocumentTemplatePacketTemplate;
      }>(),
      'Library Document Template Packet Template Removed': props<{
        id: string;
      }>()
    }
  });
