import { environment } from '$/environments/environment';
import { LoggerConfig } from '$shared/logger/logger-config';
import { ColorizedConsoleTransport } from './#colorized-console-transport';
import { LoggerTransformer } from './#logger-transformer';
import { LogtailTransport } from './#logtail-transport';

export function initLogger() {
  Error.stackTraceLimit = 100;

  const activeEnvironment = environment.name;

  LoggerConfig.configure({
    activeEnvironment,
    transformer: new LoggerTransformer(),
    transports: [new ColorizedConsoleTransport(), new LogtailTransport()]
  });
}
