import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FoodLogsPageActions = createActionGroup({
  source: 'Food Log Page',
  events: {
    'Load Food Logs': (params: Params) => ({ params }),
    'Get Food Logs': (params: Params) => ({ params }),
    'Delete Food Log': props<{ id: string; params?: Params }>()
  }
});
