import { ResidentContactPhone } from '$/models';
import { createAction, props } from '@ngrx/store';

const sourceWS = '[Resident Contact Phones WS]';

// [WS] Resident Contact Phones Created
export const residentContactPhonesCreated = createAction(
  `${sourceWS} Resident Contact Phones Created`,
  props<{ residentContactPhone: ResidentContactPhone }>()
);
// [WS] Resident Contact Phones Patched
export const residentContactPhonesPatched = createAction(
  `${sourceWS} Resident Contact Phones Patched`,
  props<{ residentContactPhone: ResidentContactPhone }>()
);
// [WS] Resident Contact Phones Removed
export const residentContactPhonesRemoved = createAction(
  `${sourceWS} Resident Contact Phones Removed`,
  props<{ id: string }>()
);
