import { FeathersService } from '$/app/services/feathers.service';
import { CarePlanItemsWsActions } from '$/app/store/care-plan-items/care-plan-items.actions';
import { toUpdated } from '$/app/utils';
import { CarePlanItem, Paginated, Params } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CarePlanItemsApiService {
  private carePlanItemsService: Service<CarePlanItem>;

  constructor(feathers: FeathersService, store: Store) {
    this.carePlanItemsService = feathers.client.service('care-plan-items');

    this.carePlanItemsService.on('created', (carePlanItem) => {
      store.dispatch(
        CarePlanItemsWsActions.carePlanItemsCreated({ carePlanItem })
      );
    });

    this.carePlanItemsService.on('patched', (carePlanItem) => {
      carePlanItem = toUpdated(carePlanItem);

      store.dispatch(
        CarePlanItemsWsActions.carePlanItemsPatched({ carePlanItem })
      );
    });

    this.carePlanItemsService.on('removed', (carePlanItem) => {
      store.dispatch(
        CarePlanItemsWsActions.carePlanItemsRemoved({ id: carePlanItem.id })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<Paginated<CarePlanItem>> {
    const promise = this.carePlanItemsService.find(params) as Promise<
      Paginated<CarePlanItem>
    >;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<CarePlanItem> {
    const promise = this.carePlanItemsService.get(id, params);
    return from(promise);
  }

  create(carePlanItem: MaybeArray<CarePlanItem>) {
    const promise = this.carePlanItemsService.create(carePlanItem);
    return from(promise);
  }

  patch(
    id: string,
    carePlanItem: Partial<CarePlanItem>,
    params: Params = { query: {} }
  ): Observable<CarePlanItem> {
    const promise = this.carePlanItemsService.patch(id, carePlanItem, params);

    return from(promise);
  }

  delete(id: string): Observable<CarePlanItem> {
    const promise = this.carePlanItemsService.remove(id);
    return from(promise);
  }
}
