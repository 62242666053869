import { CareTask, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MissedCareTasksPageActions = createActionGroup({
  source: 'Missed Care Tasks Page',
  events: {
    'Load Care Tasks': (params: Params = { query: {} }) => ({ params }),

    'Care Task State Reset': props<Update<CareTask>>(),
    'Care Task Completed': props<Update<CareTask>>()
  }
});
