import { createFeature, createReducer, on } from '@ngrx/store';

import { MedicationOrdersListFilterPageActions } from '../medication-orders-list-filters/medication-orders-list-filters.actions';
import { initialState } from './medication-orders-list-page.state';

export const uiMedicationOrdersListPageFeature = createFeature({
  name: 'uiMedicationOrdersListPage',
  reducer: createReducer(
    initialState,
    on(MedicationOrdersListFilterPageActions.filterChanged, (state, action) => {
      return {
        ...state,
        filters: action.filters
      };
    })
  )
});
