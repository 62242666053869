import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { FoodLogsPageActions } from '$/app/pages/notebook/shared/logs/food-log/food-log.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { Action, createReducer, on } from '@ngrx/store';
import { FoodLogsApiActions, FoodLogsGeneralActions } from './actions';
import {
  FoodLogPageQueries,
  State,
  foodLogsAdapter,
  initialState
} from './food-logs.state';
import { FoodLogFormPageActions } from '$/app/shared/pages/forms/log-forms/food/food-log-form.actions';

const reducer = createReducer(
  initialState,

  on(
    FoodLogsPageActions.loadFoodLogs,
    FoodLogsPageActions.getFoodLogs,
    FoodLogFormPageActions.createFoodLog,
    FoodLogFormPageActions.updateFoodLog,
    FoodLogsPageActions.deleteFoodLog,
    ResidentMedicationTasksPageActions.deleteFoodLog,
    MissedMedicationsPageActions.deleteFoodLog,
    RoutineMarListPageActions.deleteFoodLog,
    RoutineMarDetailPageActions.deleteFoodLog,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(FoodLogsApiActions.loadFoodLogsSuccess, (state, action) => {
    const queryType = action.metadata?.pageQueryType;
    const pagination = getPaginationData<FoodLogPageQueries>(
      state,
      action.foodLogs,
      queryType
    );
    const data = getRecords(action.foodLogs);

    return foodLogsAdapter.setAll(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(FoodLogsApiActions.getFoodLogsSuccess, (state, action) => {
    const queryType = action.metadata?.pageQueryType;
    const pagination = getPaginationData<FoodLogPageQueries>(
      state,
      action.foodLogs,
      queryType
    );
    const data = getRecords(action.foodLogs);

    return foodLogsAdapter.upsertMany(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(FoodLogsApiActions.createFoodLogSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(FoodLogsApiActions.updateFoodLogSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(FoodLogsApiActions.deleteFoodLogSuccess, (state, action) =>
    foodLogsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      error: null
    })
  ),

  on(
    FoodLogsApiActions.loadFoodLogsFail,
    FoodLogsApiActions.getFoodLogsFail,
    FoodLogsApiActions.createFoodLogFail,
    FoodLogsApiActions.updateFoodLogFail,
    FoodLogsApiActions.deleteFoodLogFail,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error
    })
  ),

  on(
    FoodLogsApiActions.foodLogsCreated,
    FoodLogsApiActions.foodLogsPatched,
    (state, action) => {
      return foodLogsAdapter.upsertOne(action.foodLog, state);
    }
  ),

  on(FoodLogsApiActions.foodLogsRemoved, (state, action) => {
    return foodLogsAdapter.removeOne(action.id, state);
  }),

  on(FoodLogsGeneralActions.addFoodLogs, (state, action) => {
    return foodLogsAdapter.upsertMany(action.foodLogs, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(FoodLogsGeneralActions.clearFoodLogs, () => {
    return foodLogsAdapter.removeAll({
      ...initialState
    });
  })
);

export function foodLogsReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
