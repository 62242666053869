import { calculateAmount } from '$shared/doses';
import { MeasurementId } from '$shared/lookups/measurements.lookup';
import { IAmount } from '$shared/types';
import { MaybeArray } from '$shared/types/utility-types';
import { castArray, sumBy } from 'lodash';

export function sumAmounts<T extends IAmount>(
  amountRecords: MaybeArray<T>,
  measurement: MeasurementId
): number {
  if (!amountRecords) {
    return 0;
  }

  if (!measurement) {
    throw new Error('Measurement is required to calculate amounts');
  }

  const amounts = castArray(amountRecords ?? []);

  return sumBy(amounts, (amount) => {
    if (!amount.amountTypeId || !amount.amount || !amount.strength) {
      return 0;
    }

    if (amount.amountTypeId === 'qty') {
      return (
        parseInt(amount.amount) * calculateAmount(amount.strength, measurement)
      );
    }

    return calculateAmount(amount.amount, measurement);
  });
}
