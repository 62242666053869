import { FeathersService } from '$/app/services/feathers.service';
import { ResidentLogTypesApiActions } from '$/app/store/resident-log-types';
import { ResidentLogType } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class ResidentLogTypesApiService extends AbstractApiService<ResidentLogType> {
  constructor(feathers: FeathersService, store: Store) {
    super('resident-log-types', feathers, store, {
      entityName: 'residentLogType',
      created: ResidentLogTypesApiActions.residentLogTypesCreated,
      patched: ResidentLogTypesApiActions.residentLogTypesPatched,
      removed: ResidentLogTypesApiActions.residentLogTypesRemoved
    });
  }
}
