import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const BmLogsPageActions = createActionGroup({
  source: 'Bowel Movement Log Page',
  events: {
    'Load Bm Logs': (params: Params) => ({ params }),
    'Get Bm Logs': (params: Params) => ({ params }),
    'Delete Bm Log': props<{ id: string; params?: Params }>()
  }
});
