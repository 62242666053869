import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { FacilityUserRolesWsActions } from '$/app/store/facility-user-roles/facility-user-roles-api.actions';
import { FacilityUserRole } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class FacilityUserRolesApiService extends AbstractApiService<FacilityUserRole> {
  constructor(feathers: FeathersService, store: Store) {
    super('facility-user-roles', feathers, store, {
      entityName: 'facilityUserRole',
      created: FacilityUserRolesWsActions.facilityUserRolesCreated,
      removed: FacilityUserRolesWsActions.facilityUserRolesRemoved
    });
  }
}
