import { checkPermission } from '$/app/services';
import { UserSelectors } from '$/app/store/user';
import { PermissionId } from '$shared/permissions';
import { MaybeArray } from '$shared/types/utility-types';
import {
  Directive,
  inject,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { castArray } from 'lodash';

@Directive({ selector: '[alcPermission]', standalone: true })
export class AlcPermissionDirective implements OnChanges {
  private readonly view = inject(ViewContainerRef);
  private readonly template = inject(TemplateRef);
  private readonly store = inject(Store);

  private readonly permissions = this.store.selectSignal(
    UserSelectors.selectPermissions
  );

  /** @description permissionId array works as an OR operator */
  @Input('alcPermission') permissionId: MaybeArray<PermissionId>;

  @Input('alcPermissionElse') elseTemplate: TemplateRef<any>;

  ngOnChanges() {
    this.checkPermission();
  }

  private checkPermission() {
    const permissionIds = this.permissionId ? castArray(this.permissionId) : [];

    // If no permissions were given, or if at least one of the given permissions
    // is granted, then show the element.
    const shouldShow =
      !permissionIds.length ||
      checkPermission(this.permissions(), permissionIds);

    this.view.clear();

    if (shouldShow) {
      this.view.createEmbeddedView(this.template);
    } else if (this.elseTemplate) {
      this.view.createEmbeddedView(this.elseTemplate);
    }
  }
}
