import { AlcAction, Params, Role } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityRolesPageActions = createActionGroup({
  source: 'Facility Roles Page',
  events: {
    'Load Roles': (params: Params = { query: {} }) => ({ params }),
    'Get Roles': (params: Params = { query: {} }) => ({ params }),
    'Create Role': AlcAction<{ role: Role }>(),
    'Delete Role': props<{ id: string }>()
  }
});
