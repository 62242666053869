import { RouterUtilityService } from '$/app/services/utils/router-utility.service';
import { extractTouchedChanges, getThemeColor } from '$/app/utils';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IonButton, IonIcon } from '@ionic/angular/standalone';
import { DateTime } from 'luxon';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { filter } from 'rxjs/operators';
import { AlcPipesModule } from '../../pipes';

@Component({
  selector: 'alc-time-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    IonButton,
    IonIcon,
    AlcPipesModule
  ],
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class AlcTimeInputComponent implements OnInit, ControlValueAccessor {
  private readonly destroyRef = inject(DestroyRef);

  protected readonly time = new UntypedFormControl(null);
  protected readonly theme = this.getTimePickerTheme();

  /** The label for the input control. Default is 'Time' */
  @Input() label = 'Time';

  /** Whether the button that clears the input should be displayed. Default false. */
  @Input() showClear = false;

  /** The maximum time the timepicker will allow. */
  @Input()
  get max(): string {
    return this._max;
  }
  set max(value: string) {
    if (!value) {
      this._max = null;
      return;
    }
    if (typeof value === 'string') {
      this._max = value;
    } else {
      this._max = (value as DateTime).toLocaleString(DateTime.TIME_SIMPLE);
    }
  }
  private _max: string;

  /** The minimum time the timepicker will allow. */
  @Input()
  get min(): string {
    return this._min;
  }
  set min(value: string) {
    if (!value) {
      this._min = null;
      return;
    }
    if (typeof value === 'string') {
      this._min = value;
    } else {
      this._min = (value as DateTime).toLocaleString(DateTime.TIME_SIMPLE);
    }
  }
  private _min: string;

  constructor(public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.time.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => this.onChange(value));

    extractTouchedChanges(this.time)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((touched) => touched)
      )
      .subscribe(() => {
        this.onTouch();

        if (this.ngControl.invalid && this.ngControl.touched) {
          this.time.setErrors(this.ngControl.errors, { emitEvent: false });
        }
      });
  }

  protected clearControl() {
    this.time.patchValue(null);
    this.onTouch();
    this.onChange(null);
  }

  get invalid(): boolean {
    return this.ngControl?.control?.invalid ?? this.time.invalid ?? false;
  }

  get errors(): ValidationErrors | null {
    if (!this.ngControl?.control?.errors && !this.time.errors) {
      return null;
    }

    return {
      ...(this.ngControl?.control?.errors || {}),
      ...(this.time.errors || {})
    };
  }

  get showError(): boolean {
    if (!this.time) {
      return false;
    }

    const control = this.ngControl.control || this.time;

    const { dirty, touched } = control;

    if (touched) {
      this.time.markAsTouched();

      if (this.errors) {
        this.time.setErrors(this.errors);
      }
    }

    return this.invalid ? dirty || touched : false;
  }

  /*  Control Value Accessor Implementation */

  onChange: (Value: any) => any = () => {};
  onTouch: () => any = () => {};

  writeValue(value: any) {
    this.time.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: any) => any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.time.disable();
    } else {
      this.time.enable();
    }
  }

  /**
   * Generates the "theme" for the ngx-material-timepicker
   *
   * Because this uses "inject", it can only be called at construction
   */
  private getTimePickerTheme() {
    const routerService = inject(RouterUtilityService);
    const themeColor = getThemeColor(`${routerService.getTheme()}-primary`);

    return {
      container: { buttonColor: themeColor },
      dial: { dialBackgroundColor: themeColor },
      clockFace: { clockHandColor: themeColor }
    };
  }
}
