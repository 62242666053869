import { calculateAmount } from '$shared/doses';
import { MeasurementId } from '$shared/lookups/measurements.lookup';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDose(
  min: string,
  measurementId: MeasurementId | undefined | null
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    const calculatedDose = calculateAmount(value, measurementId);
    const calculatedMin = calculateAmount(min, measurementId);

    return calculatedDose >= calculatedMin ? null : { minDose: true };
  };
}
