import { documentTemplatePacketsFeature } from './document-template-packets.reducer';
import { documentTemplatePacketsAdapter } from './document-template-packets.state';

export const {
  selectDocumentTemplatePacketsState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = documentTemplatePacketsFeature;

export const { selectAll, selectTotal } =
  documentTemplatePacketsAdapter.getSelectors(
    selectDocumentTemplatePacketsState
  );
