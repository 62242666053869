<div class="flex items-center">
  <mat-form-field class="form-input-stretch grow">
    <mat-label>{{ label }}</mat-label>
    <input
      #timeInput
      matInput
      [ngxTimepicker]="timePicker"
      readonly
      [formControl]="time"
      [max]="max"
      [min]="min"
    />

    <ngx-material-timepicker
      #timePicker
      [defaultTime]="time.value"
      [enableKeyboardInput]="false"
      [theme]="theme"
      (closed)="onTouch()"
    />
    <mat-icon matIconSuffix (click)="timePicker.open()"> schedule </mat-icon>
    @if (showError) {
      <mat-error>
        @if (errors.required) {
          <span>Required</span>
        }
      </mat-error>
    }
  </mat-form-field>

  @if (showClear) {
    <ion-button
      class="mb-6 ml-2"
      fill="clear"
      [color]="'accent' | alcTheme"
      (click)="clearControl(); timeInput.value = null"
    >
      <ion-icon
        slot="icon-only"
        src="assets/icon/material/cancel_outline.svg"
      />
    </ion-button>
  }
</div>
