import { sortByFields } from '$/app/utils';
import { IDestructionMethod } from '$shared/services/destruction-method.schema';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface DestructionMethodMetricsDict {
  [key: string]: {
    count: number;
    occurrences: number;
    averageDuration: number;
    averageSeverityRating: number;
  };
}

export const destructionMethodsAdapter =
  createEntityAdapter<IDestructionMethod>({
    sortComparer: sortByFields('name')
  });

export interface State extends EntityState<IDestructionMethod> {
  metrics: DestructionMethodMetricsDict;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = destructionMethodsAdapter.getInitialState({
  metrics: {},
  loading: false,
  loaded: false,
  error: null
});
