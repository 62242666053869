import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SeizureLogsEffects } from './seizure-logs.effects';
import { seizureLogsFeature } from './seizure-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(seizureLogsFeature),
    EffectsModule.forFeature([SeizureLogsEffects])
  ],
  providers: [SeizureLogsEffects]
})
export class SeizureLogsStoreModule {}
