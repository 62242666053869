import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const SeizureLogsPageActions = createActionGroup({
  source: 'Seizure Log Page',
  events: {
    'Load Seizure Logs': (params: Params) => ({ params }),
    'Get Seizure Logs': (params: Params) => ({ params }),
    'Delete Seizure Log': props<{ id: string; params?: Params }>()
  }
});
