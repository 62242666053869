<div class="mb-2 mt-6 flex flex-col items-start gap-2">
  <div
    class="flex items-center rounded-full px-3 py-1 text-white"
    [ngClass]="{
      'bg-primary': type === 'improvement',
      'bg-warning': type === 'change',
      'bg-success': type === 'feature',
      'bg-danger': type === 'bugfix'
    }"
  >
    <ion-icon class="mr-2 text-lg" [src]="icon" />
    <span class="text-sm font-bold tracking-wider">{{ type | uppercase }}</span>
  </div>
  <h4 class="m-0 font-bold">
    {{ title }}
  </h4>
  <ng-content />
</div>
