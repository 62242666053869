import { sortByFields } from '$/app/utils';
import { MedicationMar } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationMarsAdapter = createEntityAdapter<MedicationMar>({
  sortComparer: sortByFields('medicationTaskId')
});

export interface State extends EntityState<MedicationMar> {
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationMarsAdapter.getInitialState({
  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
