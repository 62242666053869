import { Paginated, PharmacyPhone, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadPharmacyPhonesSuccess = createAction(
  '[PharmacyPhones API] Load PharmacyPhones Success',
  props<{ pharmacyPhones: Paginated<PharmacyPhone> }>()
);
export const loadPharmacyPhonesFail = createAction(
  '[PharmacyPhones API] Load PharmacyPhones Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getPharmacyPhonesSuccess = createAction(
  '[PharmacyPhones API] Get PharmacyPhones Success',
  props<{ pharmacyPhones: Paginated<PharmacyPhone> }>()
);
export const getPharmacyPhonesFail = createAction(
  '[PharmacyPhones API] Get PharmacyPhones Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchPharmacyPhoneSuccess = createAction(
  '[PharmacyPhone API] Fetch PharmacyPhone Success',
  props<{ pharmacyPhone: PharmacyPhone }>()
);
export const fetchPharmacyPhoneFail = createAction(
  '[PharmacyPhone API] Fetch PharmacyPhone Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createPharmacyPhoneSuccess = createAction(
  '[PharmacyPhones API] Create PharmacyPhone Success',
  props<{ pharmacyPhone: MaybeArray<PharmacyPhone> }>()
);
export const createPharmacyPhoneFail = createAction(
  '[PharmacyPhones API] Create PharmacyPhone Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updatePharmacyPhoneSuccess = createAction(
  '[PharmacyPhones API] Update PharmacyPhone Success',
  props<{ pharmacyPhone: Update<PharmacyPhone> }>()
);
export const updatePharmacyPhoneFail = createAction(
  '[PharmacyPhones API] Update PharmacyPhone Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deletePharmacyPhoneSuccess = createAction(
  '[PharmacyPhones API] Delete PharmacyPhone Success',
  props<{ id: string }>()
);
export const deletePharmacyPhoneFail = createAction(
  '[PharmacyPhones API] Delete PharmacyPhone Fail',
  props<{ error: Error }>()
);

// [WS] PharmacyPhones Created
export const pharmacyPhonesCreated = createAction(
  '[PharmacyPhones WS] Pharmacy Phones Created',
  props<{ pharmacyPhone: PharmacyPhone }>()
);
// [WS] PharmacyPhones Patched
export const pharmacyPhonesPatched = createAction(
  '[PharmacyPhones WS] Pharmacy Phones Patched',
  props<{ pharmacyPhone: PharmacyPhone }>()
);
// [WS] PharmacyPhones Removed
export const pharmacyPhonesRemoved = createAction(
  '[PharmacyPhones WS] Pharmacy Phones Removed',
  props<{ id: string }>()
);
