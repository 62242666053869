import { checkPermission } from '$/app/services';
import {
  State,
  facilityUsersAdapter
} from '$/app/store/facility-users/facility-users.state';
import { selectRouterState } from '$/app/store/router/router.selectors';
import { UserSelectors } from '$/app/store/user/user.selectors';
import { PermissionId } from '$shared/permissions';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { MaybeArray } from '$shared/types/utility-types';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Selector Helpers
export const getError = (state: State) => state.error;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;

// FacilityUsers State Selector
export const selectFacilityUsersState =
  createFeatureSelector<State>('facilityUsers');

const entitySelectors = facilityUsersAdapter.getSelectors(
  selectFacilityUsersState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectFacilityUsersState,
  getLoading
);
export const selectLoaded = createSelector(selectFacilityUsersState, getLoaded);
export const selectError = createSelector(selectFacilityUsersState, getError);

// Other State Selectors
const selectById = (id: string) => {
  return createSelector(selectEntities, (entities): IFacilityUser => {
    if (!id) {
      throw new Error(`Selector 'FacilityUsers.selectById' requires an id`);
    }

    return entities[id];
  });
};

export const selectFacilityUser = createSelector(
  selectEntities,
  selectRouterState,
  (entities, routerState) => {
    return routerState && entities[routerState.params.facilityUserId];
  }
);

// Other State Selectors
export const selectFacilityUserSupervisor = createSelector(
  selectEntities,
  selectRouterState,
  (entities, routerState) => {
    const facilityUser =
      routerState && entities[routerState.params.facilityUserId];
    return entities[facilityUser?.supervisorId];
  }
);

export const selectCurrentFacilityUsers = (
  status?: string | readonly string[]
) => {
  return createSelector(
    selectAll,
    UserSelectors.selectFacilityId,
    (facilityUsers, facilityId): IFacilityUser[] => {
      status = typeof status === 'string' ? [status] : status;

      if (status) {
        return facilityUsers.filter(
          (user) =>
            user.facilityId === facilityId &&
            status.includes(user.employmentStatus)
        );
      } else {
        return facilityUsers.filter((user) => user.facilityId === facilityId);
      }
    }
  );
};

export const selectCurrentFacilityLinkedUsers = createSelector(
  selectAll,
  UserSelectors.selectFacilityId,
  (facilityUsers, facilityId) => {
    return facilityUsers.filter(
      (user) => user.facilityId === facilityId && user.userId
    );
  }
);

export const selectFacilityUserInSession = createSelector(
  selectEntities,
  UserSelectors.selectFacilityUserId,
  (facilityUsersDict, facilityUserId) => facilityUsersDict[facilityUserId]
);

export const selectFacilityUserName = (facilityUserId: string) => {
  return createSelector(
    selectById(facilityUserId),
    UserSelectors.selectUser,
    (facilityUser, user) => {
      if (!facilityUser) {
        return '';
      }

      if (facilityUser.userId === user.id) {
        return 'You';
      }

      return `${
        facilityUser.displayName ||
        facilityUser.firstName + ' ' + facilityUser.lastName
      }`;
    }
  );
};

export const selectHasPermission = (permissionId: MaybeArray<PermissionId>) =>
  createSelector(UserSelectors.selectPermissions, (permissions) =>
    checkPermission(permissions, permissionId)
  );
