import { Allergy, Params } from '$/models';
import { AtLeast } from '$shared/types/utility-types';
import { createActionGroup, props } from '@ngrx/store';

export const AllergyFormPageActions = createActionGroup({
  source: 'Allergy Form Page',
  events: {
    'Create Allergy': props<{ allergy: Allergy; params: Params }>(),
    'Update Allergy': props<{
      id: string;
      changes: AtLeast<Allergy, 'levelId' | 'name'>;
      params: Params;
    }>()
  }
});
