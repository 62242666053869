import { ObjectOptions, TObject, TProperties } from '@sinclair/typebox';
import { omitBy } from 'lodash';
import {
  BanProperties,
  MakeRequired,
  Prettify
} from '../../types/utility-types';
import { OptionalNullable } from './nullable';
import { LooseObject, StrictObject } from './object';
import { DateTimeString, Id } from './strings';

type OmitFalse<T> = Pick<
  T,
  {
    [K in keyof T]: T[K] extends false ? never : K;
  }[keyof T]
>;

type UnionFalse<T> = {
  [P in keyof T]: T[P] | false;
};

export const tableEntityFields = [
  'id',
  'orgId',
  'facilityId',
  'createdAt',
  'createdBy',
  'updatedAt',
  'updatedBy',
  'deletedAt',
  'deletedBy'
] as const;

const StandardEntitySchema = LooseObject({
  id: Id(),
  orgId: Id(),
  facilityId: Id(),

  createdAt: DateTimeString(),
  createdBy: Id(),

  updatedAt: OptionalNullable(DateTimeString()),
  updatedBy: OptionalNullable(Id()),

  deletedAt: OptionalNullable(DateTimeString()),
  deletedBy: OptionalNullable(Id())
});

type TStandardEntityPropertiesSchema =
  (typeof StandardEntitySchema)['properties'];

export function TableEntity<
  T extends UnionFalse<TProperties>,
  TResult extends TProperties = Prettify<
    Omit<TStandardEntityPropertiesSchema, keyof T> & OmitFalse<T>
  >
>(
  properties: T,
  options: BanProperties<
    MakeRequired<ObjectOptions, 'title'>,
    'additionalProperties'
  >
): TObject<TResult> {
  return StrictObject<TResult>(
    omitBy(
      {
        ...StandardEntitySchema.properties,
        ...properties
      },
      (v) => v === false
    ) as any,
    { ...options }
  );
}
