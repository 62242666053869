import * as MedicalProfessionalsSelectors from '$/app/store/medical-professionals/medical-professionals.selectors';
import { ResidentMedicalProfessionalsSelectors } from '$/app/store/resident-medical-professionals';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';

export const selectMedicalProfessionalsDropdown = createSelector(
  ResidentMedicalProfessionalsSelectors.selectAll,
  MedicalProfessionalsSelectors.selectEntities,
  (residentMedicalProfessionals, medicalProfessionalEntities) => {
    if (
      isEmpty(residentMedicalProfessionals) ||
      isEmpty(medicalProfessionalEntities)
    ) {
      return [];
    }
    return residentMedicalProfessionals.map((rmp) => {
      const mp = medicalProfessionalEntities[rmp.medicalProfessionalId];
      return {
        residentId: rmp?.residentId,
        medicalProfessionalId: rmp?.medicalProfessionalId,
        firstName: mp?.firstName,
        lastName: mp?.lastName,
        isPrimary: rmp?.isPrimary
      };
    });
  }
);
