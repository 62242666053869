import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserGroupActions = createActionGroup({
  source: 'FacilityUser Groups Page',
  events: {
    'Load Facility User Groups': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Facility User Groups': (params: Params = { query: {} }) => ({
      params
    }),
    'Delete Facility User Group': props<{ id: string }>()
  }
});
