import { createFeature, createReducer, on } from '@ngrx/store';
import {
  MedicationDestructionsApiActions,
  MedicationDestructionsGeneralActions,
  MedicationDestructionsWsActions
} from './medication-destructions.actions';
import {
  initialState,
  medicationDestructionsAdapter
} from './medication-destructions.state';
import { getPaginatedData } from '$/app/utils';

export const medicationDestructionsFeature = createFeature({
  name: 'medicationDestructions',
  reducer: createReducer(
    initialState,

    on(
      MedicationDestructionsApiActions.loadMedicationDestructionsSuccess,
      (state, action) => {
        return medicationDestructionsAdapter.setAll(
          getPaginatedData(action.medicationDestructions),
          {
            ...state,
            loading: false,
            loaded: true
          }
        );
      }
    ),

    on(
      MedicationDestructionsApiActions.createMedicationDestructionSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: null
        };
      }
    ),

    on(
      MedicationDestructionsApiActions.updateMedicationDestructionSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: null
        };
      }
    ),

    on(
      MedicationDestructionsApiActions.deleteMedicationDestructionSuccess,
      (state, action) => {
        return medicationDestructionsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationDestructionsApiActions.loadMedicationDestructionsFail,
      MedicationDestructionsApiActions.createMedicationDestructionFail,
      MedicationDestructionsApiActions.updateMedicationDestructionFail,
      MedicationDestructionsApiActions.deleteMedicationDestructionFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicationDestructionsWsActions.medicationDestructionsCreated,
      MedicationDestructionsWsActions.medicationDestructionsPatched,
      (state, action) => {
        return medicationDestructionsAdapter.upsertOne(
          action.medicationDestruction,
          state
        );
      }
    ),

    on(
      MedicationDestructionsWsActions.medicationDestructionsRemoved,
      (state, action) => {
        return medicationDestructionsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      MedicationDestructionsGeneralActions.addMedicationDestructions,
      (state, action) => {
        return medicationDestructionsAdapter.upsertMany(
          action.medicationDestructions,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationDestructionsGeneralActions.clearMedicationDestructions,
      (state) => {
        return medicationDestructionsAdapter.removeAll({
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
