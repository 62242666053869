import { environment } from '$/environments/environment';
import { parallelMap } from '$shared';
import { Logger } from '$shared/logger';
import { Dictionary } from '$shared/types/general';
import { chainFlow } from '$shared/utils';
import { Preferences } from '@capacitor/preferences';
import {
  every,
  forEach,
  fromPairs,
  isArray,
  isNil,
  isString,
  isUndefined,
  keys,
  map,
  trim,
  uniq
} from 'lodash';
import { stringify } from 'safe-stable-stringify';
import { v4 as uuid } from 'uuid';

function tryParseJSON(json: string, defaultValue: any = undefined) {
  try {
    return JSON.parse(json);
  } catch (error) {
    return defaultValue;
  }
}

function parseBoolean(value: any) {
  return value === true || value === 'true';
}

function parseString(value: any) {
  if (!isString(value)) {
    return null;
  }

  return tryParseJSON(value, value);
}

function parseJSONEmailArray(value: any): string[] {
  if (!isString(value)) {
    return [];
  }

  const parsed = tryParseJSON(value);

  if (!isArray(parsed) || !every(parsed, isString)) {
    Logger.error('Parsed json is not an array of strings', { parsed, value });

    return [];
  }

  return chainFlow(parsed, (emails) => map(emails, trim), uniq);
}

/**
 * When you need a new local setting (settings that get saved to local storage)
 * add a new entry to this object.
 * You can then retrieve it or set it via SettingsValues like any other property.
 * The custom getter or setter takes care of saves to local storage.
 */

const DEFAULTS_AND_CONVERTERS = {
  DASHBOARD_CARETASKS_SHOWCOMPLETED: [true, parseBoolean],
  DASHBOARD_PRNS_SHOWCOMPLETED: [true, parseBoolean],
  MAIN_MENU_COLLAPSED: [false, parseBoolean],
  ACCESS_TOKEN: [null, parseString],
  FCM_TOKEN: [null, parseString],
  RELEASE_NOTES_APP_VERSION: [null, parseString],
  EMAILS_ON_THIS_DEVICE: [[], parseJSONEmailArray],
  LAST_USED_EMAIL: [null, parseString],
  VIEWS__ROUTINE_MEDICATIONS__GROUP_BY: ['time', parseString],
  FILTERS__MEDICATION_ORDERS_LIST_PAGE: [{}, tryParseJSON]
} as const;

type ISettingsValues = {
  -readonly [K in keyof typeof DEFAULTS_AND_CONVERTERS]: ReturnType<
    (typeof DEFAULTS_AND_CONVERTERS)[K][1]
  >;
} & {
  commitChanges: () => Promise<void>;
};

const settingsValueCache = new Map<string, any>();

const commitPromises = {} as Dictionary<Promise<void>>;

export const SettingsValues = {
  commitChanges: async () => {
    try {
      await Promise.all(Object.values(commitPromises));
    } catch (error) {
      Logger.error('Failed to commit setting changes', { error });
    }
  }
} as ISettingsValues;

for (const name of keys(DEFAULTS_AND_CONVERTERS)) {
  Object.defineProperty(SettingsValues, name, {
    get: () => {
      Logger.assert(
        settingsValueCache.size > 0,
        'Settings values not loaded yet.',
        { name }
      );

      const value = settingsValueCache.get(name);

      Logger.assert(!isUndefined(value), 'Setting value does not exist.', {
        name
      });

      return value;
    },

    set: (value) => {
      settingsValueCache.set(name, value);

      const commitId = uuid();
      const promise = commitChange(commitId, name, value);
      commitPromises[commitId] = promise;
    }
  });
}

async function commitChange(commitId: string, name: string, value: any) {
  if (isNil(value)) {
    try {
      await Preferences.remove({ key: name });
    } catch (error) {
      Logger.error('Failed to remove setting value', { error, name });
    }
  } else {
    try {
      await Preferences.set({ key: name, value: stringify(value) });
    } catch (error) {
      Logger.error('Failed to save setting value', {
        error,
        name,
        value
      });
    }
  }

  delete commitPromises[commitId];
}

export async function loadSettingsValues() {
  settingsValueCache.clear();

  Logger.assert(
    environment.name !== 'test',
    "Support for the 'test' environment has not been implemented yet."
  );

  const { keys } = await Preferences.keys();

  // Remove any stale settings
  const keysAndValues: [string, string][] = await parallelMap(
    keys,
    async (key) => {
      const unkonwn = isNil(DEFAULTS_AND_CONVERTERS[key]);

      if (unkonwn) {
        // Remove any stale settings
        await Preferences.remove({ key });
        return [key, null];
      }

      const { value } = await Preferences.get({ key });

      return [key, value];
    }
  );
  const valuesByKey = fromPairs(keysAndValues);

  forEach(DEFAULTS_AND_CONVERTERS, ([defaultValue, converter], key) => {
    const value = valuesByKey[key] ?? defaultValue;
    settingsValueCache.set(key, value ? converter(value) : value);
  });

  Logger.debug('Settings', { settingsValueCache });
}
