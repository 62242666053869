import { NormalDoseValue, calculateAmount, formatAmount } from '$shared/doses';
import { Amount } from '$shared/doses/amount.class';
import { sumAmountStrings } from '$shared/doses/sum-amount-strings';
import { Logger } from '$shared/logger';
import { MeasurementId } from '$shared/lookups/measurements.lookup';
import { MedicationFormId } from '$shared/lookups/medication-forms.lookup';
import { MedicationInventoryItem } from '$shared/medication-inventory-items/medication-inventory-item.class';
import { Dictionary } from '$shared/types';
import { chainFlow } from '$shared/utils';
import {
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { every, isNil, map } from 'lodash';
import {
  isOptionDose,
  isRangedDose,
  isValueDose
} from '../../../../../shared/src/doses/doses';
import { padStringDecimal } from '../../../../../shared/src/utils/pad-string-decimal';

export function satisfyDose(
  dose: NormalDoseValue,
  inventoryItemsById: Dictionary<MedicationInventoryItem>,
  defaultMeasurementId: MeasurementId,
  medicationFormId: MedicationFormId,
  allowNoAmounts: boolean = false
): ValidatorFn {
  return (control: FormArray): ValidationErrors => {
    Logger.assert(
      control instanceof FormArray,
      'This validator must be registered on a FormArray'
    );

    if (allowNoAmounts && !control.length) {
      return null;
    }

    const areControlsValid = controlsAreValid(control);

    if (!areControlsValid) {
      return null;
    }

    const amountControls = control.controls;

    if (!amountControls.length) {
      return {
        amounts: {
          message: 'At least one amount is required'
        }
      };
    }

    const amounts = control.getRawValue();

    const totalAmount: string | undefined = chainFlow(
      amounts,
      (amounts) => {
        return map(amounts, (amount) => {
          const measurementId =
            inventoryItemsById[amount.medicationInventoryItemId]
              ?.amountMeasurementId ?? defaultMeasurementId;

          return new Amount(amount, measurementId, medicationFormId);
        });
      },
      (amounts): string | undefined => {
        const amountStrings = map(amounts, (amount) => {
          return amount.getTotal();
        }).filter(Boolean);

        return amountStrings.length
          ? sumAmountStrings(amountStrings)
          : undefined;
      }
    );

    switch (true) {
      case isValueDose(dose):
        return totalAmount === padStringDecimal(dose.value.trim())
          ? null
          : {
              satisfyDose: {
                message: 'The total amount must equal the expected dose',
                expected: formatAmount(
                  dose.value,
                  defaultMeasurementId,
                  medicationFormId
                ),
                actual: formatAmount(
                  totalAmount,
                  defaultMeasurementId,
                  medicationFormId
                ),
                remaining: null
              }
            };

      case isOptionDose(dose):
        return dose.some(
          (option) => padStringDecimal(option.trim()) === totalAmount
        )
          ? null
          : {
              satisfyDose: {
                message:
                  'The total amount must equal one of the expected dose options',
                expected: dose
                  .map((option) =>
                    formatAmount(option, defaultMeasurementId, medicationFormId)
                  )
                  .join(', '),
                actual: formatAmount(
                  totalAmount,
                  defaultMeasurementId,
                  medicationFormId
                ),
                remaining: null
              }
            };

      case isRangedDose(dose):
        const min = calculateAmount(
          padStringDecimal(dose.min.trim()),
          defaultMeasurementId
        );
        const max = calculateAmount(
          padStringDecimal(dose.max.trim()),
          defaultMeasurementId
        );
        const totalDose = calculateAmount(totalAmount, defaultMeasurementId);

        return totalDose >= min && totalDose <= max
          ? null
          : {
              satisfyDose: {
                message:
                  'The total amount must be within the expected dose range',
                expected: [
                  formatAmount(
                    dose.min,
                    defaultMeasurementId,
                    medicationFormId
                  ),
                  formatAmount(dose.max, defaultMeasurementId, medicationFormId)
                ].join(' - '),
                actual: formatAmount(
                  totalAmount,
                  defaultMeasurementId,
                  medicationFormId
                ),
                remaining: null
              }
            };

      default:
        Logger.throw('Unknown dose type');
        break;
    }
  };
}

function controlsAreValid(formArray: FormArray<FormGroup>) {
  return every(formArray.controls, (control) =>
    every(
      Object.entries(control.controls).filter(([key]) =>
        ['amountTypeId', 'amount', 'strength'].includes(key)
      ),
      ([, control]) => !isNil(control.value)
    )
  );
}
