import { MedicationOrderDetailsPageActions } from '$/app/pages/medications/dashboard/medication-orders/medication-order-details/medication-order-details.actions';
import { MedicationOrdersListPageActions } from '$/app/pages/medications/dashboard/medication-orders/medication-orders-list/medication-orders-list.actions';
import { MedicationsToOrderListPageActions } from '$/app/pages/medications/dashboard/medication-orders/medications-to-order-list/medications-to-order-list.actions';
import { EffectHelpersService } from '$/app/services/utils/effect-helpers.service';
import { MedicationOrderQueueSelectors } from '$/app/store/medication-orders-queue/medication-orders-queue.selectors';
import { ApiData, getAllPages, scanPaginatedResponses } from '$/app/utils';
import { IMedicationOrder } from '$shared/medications/medication-order.schema';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { MedicationOrdersApiActions } from './medication-orders.actions';
import { MedicationOrdersApiService } from './medication-orders.service';

@Injectable()
export class MedicationOrdersEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly medicationOrdersService = inject(MedicationOrdersApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadMedicationOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationOrdersListPageActions.loadMedicationOrders),
      this.effectHelpers.apiRequest({
        description: 'Load Medication Orders',
        onRequest: (action) =>
          this.medicationOrdersService
            .getAll(action.params)
            .pipe(
              getAllPages(this.medicationOrdersService, action?.params?.query),
              scanPaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medicationOrders',
            response,
            MedicationOrdersApiActions.loadMedicationOrdersSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicationOrdersApiActions.loadMedicationOrdersFail({ error })
      })
    );
  });

  getMedicationOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationOrdersListPageActions.getMedicationOrders),
      this.effectHelpers.apiRequest({
        description: 'Get Medication Orders',
        onRequest: (action) =>
          this.medicationOrdersService
            .getAll(action.params)
            .pipe(
              getAllPages(this.medicationOrdersService, action?.params?.query),
              scanPaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medicationOrders',
            response,
            MedicationOrdersApiActions.getMedicationOrdersSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicationOrdersApiActions.getMedicationOrdersFail({ error })
      })
    );
  });

  fetchMedicationOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationOrderDetailsPageActions.fetchMedicationOrder),
      this.effectHelpers.apiRequest({
        description: 'Fetch Medication Order',
        onRequest: (action) =>
          this.medicationOrdersService.get(action.id, action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medicationOrders',
            response,
            MedicationOrdersApiActions.fetchMedicationOrderSuccess,
            {
              payloadKey: 'medicationOrder'
            }
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicationOrdersApiActions.fetchMedicationOrderFail({
            error
          })
      })
    );
  });

  updateMedicationOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationOrdersListPageActions.cancelMedicationOrder,
        MedicationOrderDetailsPageActions.cancelMedicationOrder
      ),
      this.effectHelpers.apiRequest({
        description: 'Update Medication Order',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.medicationOrdersService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (medicationOrder) =>
          MedicationOrdersApiActions.updateMedicationOrderSuccess({
            medicationOrder: {
              id: medicationOrder.id,
              changes: medicationOrder
            }
          }),
        onError: (error) =>
          MedicationOrdersApiActions.updateMedicationOrderFail({ error })
      })
    );
  });

  deleteMedicationOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationOrdersListPageActions.deleteMedicationOrder,
        MedicationOrderDetailsPageActions.deleteMedicationOrder
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete Medication Order',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.medicationOrdersService.delete(action.id, action.params);
        },
        onSuccess: (medicationOrder: IMedicationOrder) =>
          MedicationOrdersApiActions.deleteMedicationOrderSuccess({
            id: medicationOrder.id
          }),
        onError: (error) =>
          MedicationOrdersApiActions.deleteMedicationOrderFail({ error })
      })
    );
  });

  orderQueuedMedication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationsToOrderListPageActions.orderQueuedMedications),
      tap(
        this.effectHelpers.showLoading('Preparing your order...', {
          duration: 20000
        })
      ),
      withLatestFrom(
        this.store.select(
          MedicationOrderQueueSelectors.selectMedicationOrdersQueue
        )
      ),
      exhaustMap(([, medicationsToOrder]) => {
        const medicationOrderItems = medicationsToOrder
          .filter((medication) => medication.pharmacyId)
          .map((medication) => ({
            medicationId: medication.id,
            medicationBatchId: medication.batchId,
            pharmacyId: medication.pharmacyId,
            residentId: medication.residentId
          }));

        return this.medicationOrdersService
          .create(
            {},
            { query: { $medicationOrderItems: medicationOrderItems } }
          )
          .pipe(
            map(() => MedicationsToOrderListPageActions.clearOrderQueue()),
            tap(this.effectHelpers.hideLoading()),
            tap(this.effectHelpers.onModalFormSubmitSuccess('Order sent!')),
            catchError((error) => {
              return of(
                MedicationOrdersApiActions.createMedicationOrderFail({ error })
              );
            })
          );
      })
    );
  });

  orderQueuedMedicationFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationOrdersApiActions.createMedicationOrderFail),
        tap(this.effectHelpers.hideLoading()),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
