import { FeathersService } from '$/app/services/feathers.service';
import { AcknowledgementsWsActions } from '$/app/store/acknowledgements';
import { AnnouncementsGeneralActions } from '$/app/store/announcements';
import { normalizeAndRemove } from '$/app/utils';
import { Acknowledgement, Params } from '$/models';
import { acknowledgementSchema } from '$shared';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AcknowledgementsApiService {
  private acknowledgementsService: Service<Acknowledgement>;

  constructor(store: Store, feathers: FeathersService) {
    this.acknowledgementsService = feathers.client.service('acknowledgements');

    this.acknowledgementsService.on('created', (acknowledgement) => {
      store.dispatch(
        AcknowledgementsWsActions.acknowledgementsCreated({ acknowledgement })
      );
    });

    this.acknowledgementsService.on('patched', (acknowledgement) => {
      if (acknowledgement.announcement) {
        const normalizedData = normalizeAndRemove(
          acknowledgement,
          acknowledgementSchema,
          {
            acknowledgements: ['announcement']
          }
        );

        store.dispatch(
          AnnouncementsGeneralActions.addAnnouncements({
            announcements: normalizedData.announcements
          })
        );
      }

      store.dispatch(
        AcknowledgementsWsActions.acknowledgementsPatched({ acknowledgement })
      );
    });

    this.acknowledgementsService.on('removed', (acknowledgement) => {
      store.dispatch(
        AcknowledgementsWsActions.acknowledgementsRemoved({
          id: acknowledgement.id
        })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<Acknowledgement[]> {
    const promise = this.acknowledgementsService.find(params) as Promise<
      Acknowledgement[]
    >;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<Acknowledgement> {
    const promise = this.acknowledgementsService.get(id, params);
    return from(promise);
  }

  patch(
    id: string | null,
    acknowledgement: Partial<Acknowledgement>,
    params: Params
  ): Observable<Acknowledgement[]> {
    const promise = this.acknowledgementsService.patch(
      id,
      acknowledgement,
      params
    );

    return from(promise as unknown as Promise<Acknowledgement[]>);
  }
}
