import { FeathersService } from '$/app/services/feathers.service';
import { DocumentParticipantsWsActions } from '$/app/store/document-participants/document-participants.actions';
import { IDocumentParticipant } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class DocumentParticipantsApiService extends AbstractApiService<IDocumentParticipant> {
  constructor(feathers: FeathersService, store: Store) {
    super('document-participants', feathers, store, {
      entityName: 'documentParticipant',
      created: DocumentParticipantsWsActions.documentParticipantCreated,
      patched: DocumentParticipantsWsActions.documentParticipantPatched,
      removed: DocumentParticipantsWsActions.documentParticipantRemoved
    });
  }
}
