import { CreatePacketFromTemplateModalActions } from '$/app/pages/documents/create-packet-from-template/create-packet-from-template.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DocumentTemplatePacketsApiActions,
  DocumentTemplatePacketsGeneralActions,
  DocumentTemplatePacketsWsActions
} from './document-template-packets.actions';
import {
  documentTemplatePacketsAdapter,
  initialState
} from './document-template-packets.state';

export const documentTemplatePacketsFeature = createFeature({
  name: 'documentTemplatePackets',
  reducer: createReducer(
    initialState,

    on(
      CreatePacketFromTemplateModalActions.getDocumentTemplatePackets,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      DocumentTemplatePacketsApiActions.loadDocumentTemplatePacketsSuccess,
      (state, action) => {
        const {
          documentTemplatePackets: { data: records, ...pagination }
        } = action;

        return documentTemplatePacketsAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatePacketsApiActions.getDocumentTemplatePacketsSuccess,
      (state, action) => {
        const {
          documentTemplatePackets: { data: records, ...pagination }
        } = action;

        return documentTemplatePacketsAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatePacketsApiActions.fetchDocumentTemplatePacketSuccess,
      (state, action) => {
        return documentTemplatePacketsAdapter.upsertOne(
          action.documentTemplatePacket,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentTemplatePacketsApiActions.createDocumentTemplatePacketSuccess,
      (state, action) => {
        return documentTemplatePacketsAdapter.addOne(
          action.documentTemplatePacket,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentTemplatePacketsApiActions.updateDocumentTemplatePacketSuccess,
      (state, action) => {
        return documentTemplatePacketsAdapter.upsertOne(
          action.documentTemplatePacket,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentTemplatePacketsApiActions.deleteDocumentTemplatePacketSuccess,
      (state, action) =>
        documentTemplatePacketsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      DocumentTemplatePacketsApiActions.loadDocumentTemplatePacketsFail,
      DocumentTemplatePacketsApiActions.getDocumentTemplatePacketsFail,
      DocumentTemplatePacketsApiActions.createDocumentTemplatePacketFail,
      DocumentTemplatePacketsApiActions.updateDocumentTemplatePacketFail,
      DocumentTemplatePacketsApiActions.deleteDocumentTemplatePacketFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      DocumentTemplatePacketsWsActions.documentTemplatePacketCreated,
      DocumentTemplatePacketsWsActions.documentTemplatePacketPatched,
      (state, action) => {
        return documentTemplatePacketsAdapter.upsertOne(
          action.documentTemplatePacket,
          state
        );
      }
    ),

    on(
      DocumentTemplatePacketsWsActions.documentTemplatePacketRemoved,
      (state, action) => {
        return documentTemplatePacketsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      DocumentTemplatePacketsGeneralActions.addDocumentTemplatePackets,
      (state, action) => {
        return documentTemplatePacketsAdapter.upsertMany(
          action.documentTemplatePackets,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentTemplatePacketsGeneralActions.clearDocumentTemplatePackets,
      () => {
        return documentTemplatePacketsAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
