import { MedicationSchedulesApiActions } from '$/app/store/medication-schedules/actions';
import { MedicationSchedule, Paginated, Params } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { FeathersService } from '../feathers.service';

@Injectable({ providedIn: 'root' })
export class MedicationSchedulesApiService {
  private medicationSchedulesService: Service<MedicationSchedule>;

  constructor(feathers: FeathersService, store: Store) {
    this.medicationSchedulesService = feathers.client.service(
      'medication-schedules'
    );

    this.medicationSchedulesService.on('created', (medicationSchedule) => {
      store.dispatch(
        MedicationSchedulesApiActions.medicationSchedulesCreated({
          medicationSchedule
        })
      );
    });

    this.medicationSchedulesService.on('patched', (medicationSchedule) => {
      store.dispatch(
        MedicationSchedulesApiActions.medicationSchedulesPatched({
          medicationSchedule
        })
      );
    });

    this.medicationSchedulesService.on('removed', (medicationSchedule) => {
      store.dispatch(
        MedicationSchedulesApiActions.medicationSchedulesRemoved({
          id: medicationSchedule.id
        })
      );
    });
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<MedicationSchedule>> {
    const promise = this.medicationSchedulesService.find(params) as Promise<
      Paginated<MedicationSchedule>
    >;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<MedicationSchedule> {
    const promise = this.medicationSchedulesService.get(id, params);
    return from(promise);
  }
}
