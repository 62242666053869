import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const residentGroupsAdapter = createEntityAdapter<ResidentGroup>();

export interface State extends EntityState<ResidentGroup> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = residentGroupsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
