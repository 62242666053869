import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Paginated, Update } from '$/models';
import { IMedicationOrder } from '$shared/medications/medication-order.schema';

export const MedicationOrdersApiActions = createActionGroup({
  source: 'Medication Orders API',
  events: {
    'Load Medication Orders Success': props<{
      medicationOrders: Paginated<IMedicationOrder>;
    }>(),
    'Get Medication Orders Success': props<{
      medicationOrders: Paginated<IMedicationOrder>;
    }>(),
    'Fetch Medication Order Success': props<{
      medicationOrder: IMedicationOrder;
    }>(),
    'Create Medication Order Success': props<{
      medicationOrder: IMedicationOrder | IMedicationOrder[];
    }>(),
    'Update Medication Order Success': props<{
      medicationOrder: Update<IMedicationOrder>;
    }>(),
    'Delete Medication Order Success': props<{
      id: string;
    }>(),

    'Load Medication Orders Fail': props<{ error: Error }>(),
    'Get Medication Orders Fail': props<{ error: Error }>(),
    'Fetch Medication Order Fail': props<{ error: Error }>(),
    'Create Medication Order Fail': props<{ error: Error }>(),
    'Update Medication Order Fail': props<{ error: Error }>(),
    'Delete Medication Order Fail': props<{ error: Error }>()
  }
});

export const MedicationOrdersWsActions = createActionGroup({
  source: 'Medication Orders WS',
  events: {
    'Medication Orders Created': props<{ medicationOrder: IMedicationOrder }>(),
    'Medication Orders Patched': props<{ medicationOrder: IMedicationOrder }>(),
    'Medication Orders Removed': props<{ id: string }>()
  }
});

export const MedicationOrdersGeneralActions = createActionGroup({
  source: 'Medication Orders General',
  events: {
    'Clear Medication Orders': emptyProps(),
    'Add Medication Orders': props<{
      medicationOrders: IMedicationOrder[];
    }>()
  }
});

// 💥💤 BACKEND API ACTIONS

// [API] Load Success/Fail
// export const loadMedicationOrdersSuccess = createAction(
//   `${source} Load Medication Orders Success`,
//   props<{ medicationOrders: Paginated<MedicationOrder> }>()
// );
// export const loadMedicationOrdersFail = createAction(
//   `${source} Load Medication Orders Fail`,
//   props<{ error: Error }>()
// );

// // [API] Get Success/Fail
// export const getMedicationOrdersSuccess = createAction(
//   `${source} Get Medication Orders Success`,
//   props<{ medicationOrders: MedicationOrderGraph[] }>()
// );
// export const getMedicationOrdersFail = createAction(
//   `${source} Get Medication Orders Fail`,
//   props<{ error: Error }>()
// );

// // [API] Fetch Success/Fail
// export const fetchMedicationOrderSuccess = createAction(
//   `${source} Fetch Medication Order Success`,
//   props<{ medicationOrder: MedicationOrder }>()
// );
// export const fetchMedicationOrderFail = createAction(
//   `${source} Fetch Medication Order Fail`,
//   props<{ error: Error }>()
// );

// // [API] Create Success/Fail
// export const createMedicationOrderSuccess = createAction(
//   `${source} Create Medication Order Success`,
//   props<{ medicationOrder: MaybeArray<MedicationOrder> }>()
// );
// export const createMedicationOrderFail = createAction(
//   `${source} Create Medication Order Fail`,
//   props<{ error: Error }>()
// );

// // [API] Update Success/Fail
// export const updateMedicationOrderSuccess = createAction(
//   `${source} Update Medication Order Success`,
//   props<{ medicationOrder: Update<MedicationOrder> }>()
// );
// export const updateMedicationOrderFail = createAction(
//   `${source} Update Medication Order Fail`,
//   props<{ error: Error }>()
// );

// // [API] Delete Success/Fail
// export const deleteMedicationOrderSuccess = createAction(
//   `${source} Delete Medication Order Success`,
//   props<{ id: string }>()
// );
// export const deleteMedicationOrderFail = createAction(
//   `${source} Delete Medication Order Fail`,
//   props<{ error: Error }>()
// );

// // [WS] MedicationOrders Created
// export const medicationOrdersCreated = createAction(
//   `${sourceWS} Medication Orders Created`,
//   props<{ medicationOrder: MedicationOrder }>()
// );
// // [WS] MedicationOrders Patched
// export const medicationOrdersPatched = createAction(
//   `${sourceWS} Medication Orders Patched`,
//   props<{ medicationOrder: MedicationOrder }>()
// );
// // [WS] MedicationOrders Removed
// export const medicationOrdersRemoved = createAction(
//   `${sourceWS} Medication Orders Removed`,
//   props<{ id: string }>()
// );
