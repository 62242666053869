import { MedicationDestructionStatusId } from '$shared/lookups/medication-destruction-statuses.lookup';
import { Params } from '@angular/router';
import { createActionGroup, props } from '@ngrx/store';

export const medicationDestructionFormActions = createActionGroup({
  source: 'Medication Destruction From',
  events: {
    'Update Medication Destruction': props<{
      id: string;
      changes: Partial<{
        items: string[];
        status: MedicationDestructionStatusId;
        method: string;
      }>;
      params?: Params;
    }>(),
    'Load Facility Users': props<{ params?: Params }>(),
    'Load Medication Destruction Items': props<{ params?: Params }>()
  }
});
