import { createFeature, createReducer, on } from '@ngrx/store';
import {
  LibraryDocumentTemplatePacketTemplatesApiActions,
  LibraryDocumentTemplatePacketTemplatesGeneralActions,
  LibraryDocumentTemplatePacketTemplatesWsActions
} from './actions';
import {
  initialState,
  libraryDocumentTemplatePacketTemplatesAdapter
} from './library-document-template-packet-templates.state';

export const libraryDocumentTemplatePacketTemplatesFeature = createFeature({
  name: 'libraryDocumentTemplatePacketTemplates',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      LibraryDocumentTemplatePacketTemplatesApiActions.loadLibraryDocumentTemplatePacketTemplatesSuccess,
      (state, action) => {
        const {
          libraryDocumentTemplatePacketTemplates: {
            data: records,
            ...pagination
          }
        } = action;

        return libraryDocumentTemplatePacketTemplatesAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesApiActions.getLibraryDocumentTemplatePacketTemplatesSuccess,
      (state, action) => {
        const {
          libraryDocumentTemplatePacketTemplates: {
            data: records,
            ...pagination
          }
        } = action;

        return libraryDocumentTemplatePacketTemplatesAdapter.upsertMany(
          records,
          {
            ...state,
            pagination,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesApiActions.fetchLibraryDocumentTemplatePacketTemplateSuccess,
      (state, action) => {
        return libraryDocumentTemplatePacketTemplatesAdapter.upsertOne(
          action.libraryDocumentTemplatePacketTemplate,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesApiActions.createLibraryDocumentTemplatePacketTemplateSuccess,
      (state, action) => {
        return libraryDocumentTemplatePacketTemplatesAdapter.addOne(
          action.libraryDocumentTemplatePacketTemplate,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesApiActions.updateLibraryDocumentTemplatePacketTemplateSuccess,
      (state, action) => {
        return libraryDocumentTemplatePacketTemplatesAdapter.upsertOne(
          action.libraryDocumentTemplatePacketTemplate,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesApiActions.deleteLibraryDocumentTemplatePacketTemplateSuccess,
      (state, action) =>
        libraryDocumentTemplatePacketTemplatesAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesApiActions.loadLibraryDocumentTemplatePacketTemplatesFail,
      LibraryDocumentTemplatePacketTemplatesApiActions.getLibraryDocumentTemplatePacketTemplatesFail,
      LibraryDocumentTemplatePacketTemplatesApiActions.createLibraryDocumentTemplatePacketTemplateFail,
      LibraryDocumentTemplatePacketTemplatesApiActions.updateLibraryDocumentTemplatePacketTemplateFail,
      LibraryDocumentTemplatePacketTemplatesApiActions.deleteLibraryDocumentTemplatePacketTemplateFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesWsActions.libraryDocumentTemplatePacketTemplateCreated,
      LibraryDocumentTemplatePacketTemplatesWsActions.libraryDocumentTemplatePacketTemplatePatched,
      (state, action) => {
        return libraryDocumentTemplatePacketTemplatesAdapter.upsertOne(
          action.libraryDocumentTemplatePacketTemplate,
          state
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesWsActions.libraryDocumentTemplatePacketTemplateRemoved,
      (state, action) => {
        return libraryDocumentTemplatePacketTemplatesAdapter.removeOne(
          action.id,
          state
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesGeneralActions.addLibraryDocumentTemplatePacketTemplates,
      (state, action) => {
        return libraryDocumentTemplatePacketTemplatesAdapter.upsertMany(
          action.libraryDocumentTemplatePacketTemplates,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketTemplatesGeneralActions.clearLibraryDocumentTemplatePacketTemplates,
      () => {
        return libraryDocumentTemplatePacketTemplatesAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
