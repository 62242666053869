import { FeathersService } from '$/app/services/feathers.service';
import { ResidentContactsApiActions } from '$/app/store/resident-contacts/actions';
import { ResidentContact } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class ResidentContactsApiService extends AbstractApiService<ResidentContact> {
  constructor(store: Store, feathers: FeathersService) {
    super('resident-contacts', feathers, store, {
      entityName: 'residentContact',
      created: ResidentContactsApiActions.residentContactsCreated,
      patched: ResidentContactsApiActions.residentContactsPatched,
      removed: ResidentContactsApiActions.residentContactsRemoved
    });
  }
}
