import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LibraryCollectionsEffects } from './library-collections.effects';
import { libraryCollectionsFeature } from './library-collections.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(libraryCollectionsFeature),
    EffectsModule.forFeature([LibraryCollectionsEffects])
  ],
  providers: [LibraryCollectionsEffects]
})
export class LibraryCollectionsStoreModule {}
