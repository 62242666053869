import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { getPresetDictionary } from './get-preset-dictionary';

/**
 * Returns a date range picker object and fills in a default value for
 * any fields that are not defined. This is usually used before passing
 * the date range picker object to the date range picker component or
 * before any network requests that utilize the information from the date
 * range picker object.
 */
export function getDateRange(
  dateRange: IDateRangePickerValue | null | undefined,
  ft: FacilityTime,
  defaultPreset = 'past30Days'
): IDateRangePickerValue {
  const range: IDateRangePickerValue = {
    type: dateRange?.type || 'preset',
    preset: dateRange?.preset || defaultPreset,
    year: dateRange?.year || ft.createDate().year
  };

  const presets = getPresetDictionary(dateRange?.year, ft);

  if (range.type === 'preset') {
    const preset = presets[range.preset];

    range.startDate = preset.range.start;
    range.endDate = preset.range.end;
  } else {
    range.startDate =
      dateRange?.startDate || presets[defaultPreset].range.start;
    range.endDate = dateRange?.endDate || presets[defaultPreset].range.end;
  }

  return range;
}
