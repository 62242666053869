import { DateTime } from 'luxon';

export function sortByDatetime(key: string, desc: boolean = false) {
  return function (a, b) {
    if (desc) {
      const aCopy = Object.assign({}, a);
      const bCopy = Object.assign({}, b);
      a = bCopy;
      b = aCopy;
    }

    if (!a[key]) {
      throw new Error(`Property "${key}" is undefined`);
    }

    const result =
      DateTime.fromISO(a[key]).toMillis() - DateTime.fromISO(b[key]).toMillis();
    return result;
  };
}
