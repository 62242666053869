import * as RouterSelectors from '$/app/store/router/router.selectors';
import { createSelector } from '@ngrx/store';

import { announcementsFeature } from './announcements.reducer';
import { announcementsAdapter } from './announcements.state';

export const {
  selectAnnouncementsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError,
  selectMetrics,
  selectSkip,
  selectTotal: selectTotalRecords
} = announcementsFeature;

export const { selectAll, selectTotal } = announcementsAdapter.getSelectors(
  selectAnnouncementsState
);

export const selectAnnouncement = createSelector(
  selectEntities,
  RouterSelectors.selectParam('announcementId'),
  (announcementEntities, announcementId) => {
    return announcementEntities[announcementId];
  }
);
