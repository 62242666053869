import { AlcAction, Params, ShiftNote } from '$/models';
import { createActionGroup } from '@ngrx/store';
import { ShiftNotesFilters } from '../shift-notes.vmodel';

export const ShiftNotesListPageActions = createActionGroup({
  source: 'Shift Notes List Page',
  events: {
    'Load Shift Notes': (
      residentId: string,
      filters: ShiftNotesFilters,
      params?: Params
    ) => ({
      params: {
        ...params,
        query: {
          ...params?.query,
          $sort: { recordAt: -1 },
          $eager: { facilityUser: true },
          residentId,
          recordAt: {
            $between: [filters.dateRange.startDate, filters.dateRange.endDate]
          }
        }
      }
    }),

    'Refresh Shift Notes': (
      residentId: string,
      filters: ShiftNotesFilters,
      params?: Params
    ) => ({
      params: {
        ...params,
        query: {
          ...params?.query,
          $sort: { recordAt: -1 },
          $eager: { facilityUser: true },
          residentId,
          recordAt: {
            $between: [filters.dateRange.startDate, filters.dateRange.endDate]
          }
        }
      }
    }),

    'Create Shift Note': AlcAction<{ shiftNote: ShiftNote }>(),

    'Delete Shift Note': AlcAction<{ id: string }>()
  }
});
