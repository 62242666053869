export const PRIMARY = '#03a9f4';
export const SECONDARY = '#ff4081';
export const SUCCESS = '#10dc60';
export const SUCCESS_TINT = '#28e070';
export const WARNING = '#ffce00';
export const WARNING_TINT = '#ffd31a';
export const DANGER = '#f04141';
export const DARK = '#424242';
export const MEDIUM = '#989aa2';
export const LIGHT = '#f4f5f8';
export const MEDIUM_LIGHT = '#d7d8da';

export const DASHBOARD_PRIMARY = '#03a9f4';
export const DASHBOARD_ACCENT = '#ff4081';
export const RESIDENTS_PRIMARY = '#ff216b';
export const RESIDENTS_ACCENT = '#00e5ff';
export const STAFF_PRIMARY = '#ffc107';
export const STAFF_ACCENT = '#bc78ff';
export const COMMUNICATION_PRIMARY = '#673ab7';
export const COMMUNICATION_ACCENT = '#1de9b6';
export const ORGANIZATION_PRIMARY = '#00c853';
export const ORGANIZATION_ACCENT = '#80d8ff';
export const DOCUMENTS_PRIMARY = '#00bcd4';
export const DOCUMENTS_ACCENT = '#ffeb3b';
export const TRIGGERS_PRIMARY = '#ffeb3b';
export const TRIGGERS_ACCENT = '#00e5ff';

export const UNMARKED = MEDIUM;
export const LOG = PRIMARY;
export const LOG_DISABLED = '#B3E5FC';
export const POP = WARNING;
export const POP_DISABLED = '#ffe57f';
export const PASS = SUCCESS;
export const PASS_DISABLED = '#a5e5bc';
export const OMIT = DANGER;

export const NOTIFICATION_ALERT = '#f44336';
export const NOTIFICATION_REMINDER = '#03a9f4';
export const NOTIFICATION_MESSAGE = '#673ab7';

export const Color = {
  PRIMARY,
  SECONDARY,
  SUCCESS,
  SUCCESS_TINT,
  WARNING,
  WARNING_TINT,
  DANGER,
  DARK,
  MEDIUM,
  LIGHT,
  MEDIUM_LIGHT,
  DASHBOARD_PRIMARY,
  DASHBOARD_ACCENT,
  RESIDENTS_PRIMARY,
  RESIDENTS_ACCENT,
  STAFF_PRIMARY,
  STAFF_ACCENT,
  COMMUNICATION_PRIMARY,
  COMMUNICATION_ACCENT,
  ORGANIZATION_PRIMARY,
  ORGANIZATION_ACCENT,
  DOCUMENTS_PRIMARY,
  DOCUMENTS_ACCENT,
  TRIGGERS_PRIMARY,
  TRIGGERS_ACCENT,
  UNMARKED,
  LOG,
  LOG_DISABLED,
  POP,
  POP_DISABLED,
  PASS,
  PASS_DISABLED,
  OMIT,
  NOTIFICATION_ALERT,
  NOTIFICATION_REMINDER,
  NOTIFICATION_MESSAGE
};
