import { lookupValue } from '$shared/lookups/lookup-value';
import { IAddress } from '$shared/types';
import { Pipe, PipeTransform } from '@angular/core';

export type AddressFields = Array<keyof IAddress>;

@Pipe({
  name: 'address',
  standalone: true
})
export class AlcAddressPipe implements PipeTransform {
  transform<T extends IAddress>(
    value: T,
    fields: AddressFields = [
      'address1',
      'address2',
      'city',
      'state',
      'postalCode'
    ]
  ) {
    return fields
      .map((field, index) => {
        if (!value?.[field]) {
          return;
        }

        if (field === 'city' && fields[index + 1] === 'state') {
          return `${value[field]},`;
        }

        if (field === 'state') {
          return lookupValue('states', value[field], 'abbreviation');
        }

        return value?.[field];
      })
      .filter(Boolean)
      .join(' ');
  }
}
