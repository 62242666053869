<ion-header>
  <ion-toolbar color="{{ theme }}-primary">
    <ion-title>Date Range</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="form-content {{ theme }}-theme">
  <div class="p-6">
    <alc-date-range-picker-control [formControl]="control" />
  </div>
</ion-content>

<div class="px-6 pb-4">
  <ion-button
    color="{{ theme }}-primary"
    expand="full"
    shape="round"
    (click)="submit()"
  >
    {{ btnLabel }}
  </ion-button>
</div>
