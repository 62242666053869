import { Update } from '$/models';
import { IMoodLog } from '$shared/services/mood-log';
import { createActionGroup, props } from '@ngrx/store';

export const MoodLogFormPageActions = createActionGroup({
  source: 'Mood Log Form Page',
  events: {
    'Create Mood Log': props<{ moodLog: IMoodLog }>(),
    'Update Mood Log': props<Update<IMoodLog>>()
  }
});
