import { sortByFields } from '$/app/utils';
import { PharmacyPhone } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const pharmacyPhonesAdapter = createEntityAdapter<PharmacyPhone>({
  sortComparer: sortByFields('createdAt')
});

export interface State extends EntityState<PharmacyPhone> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = pharmacyPhonesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
