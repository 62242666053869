import { IDocumentTemplatePacketTemplate, IPaginationInfo } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const documentTemplatePacketTemplatesAdapter =
  createEntityAdapter<IDocumentTemplatePacketTemplate>();

export interface State extends EntityState<IDocumentTemplatePacketTemplate> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  documentTemplatePacketTemplatesAdapter.getInitialState({
    pagination: {},
    loading: false,
    loaded: false,
    error: null
  });
