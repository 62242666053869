import { Paginated } from '$/models';
import { IUrineLog } from '$shared/services/urine-log';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UrineLogsApiActions = createActionGroup({
  source: 'Urine Logs API',
  events: {
    'Load Urine Logs Success': props<{ urineLogs: Paginated<IUrineLog> }>(),
    'Get Urine Logs Success': props<{ urineLogs: Paginated<IUrineLog> }>(),
    'Create Urine Log Success': props<{ urineLog: IUrineLog }>(),
    'Update Urine Log Success': props<{ urineLog: IUrineLog }>(),
    'Delete Urine Log Success': props<{ id: string }>(),

    'Load Urine Logs Fail': props<{ error: Error }>(),
    'Get Urine Logs Fail': props<{ error: Error }>(),
    'Create Urine Log Fail': props<{ error: Error }>(),
    'Update Urine Log Fail': props<{ error: Error }>(),
    'Delete Urine Log Fail': props<{ error: Error }>()
  }
});

export const UrineLogsWsActions = createActionGroup({
  source: 'Urine Logs WS',
  events: {
    'Urine Logs Created': props<{ urineLog: IUrineLog }>(),
    'Urine Logs Patched': props<{ urineLog: IUrineLog }>(),
    'Urine Logs Removed': props<{ id: string }>()
  }
});

export const UrineLogsGeneralActions = createActionGroup({
  source: 'Urine Logs General',
  events: {
    'Clear Urine Logs': emptyProps(),
    'Add Urine Logs': props<{ urineLogs: IUrineLog[] }>()
  }
});
