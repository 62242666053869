import { Action, createReducer, on } from '@ngrx/store';
import {
  FacilitySettingsApiActions,
  FacilitySettingsGeneralActions,
  FacilitySettingsWsActions
} from './actions';

import {
  facilitySettingsAdapter,
  initialState,
  State
} from './facility-settings.state';

const reducer = createReducer(
  initialState,

  on(
    FacilitySettingsApiActions.updateFacilitySettingSuccess,
    (state, action) => {
      return facilitySettingsAdapter.updateOne(action.facilitySetting, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(FacilitySettingsApiActions.updateFacilitySettingFail, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  }),

  on(FacilitySettingsWsActions.facilitySettingsPatched, (state, action) => {
    return facilitySettingsAdapter.upsertOne(action.facilitySetting, {
      ...state
    });
  }),

  on(FacilitySettingsGeneralActions.addFacilitySettings, (state, action) => {
    return facilitySettingsAdapter.upsertMany(action.facilitySettings, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(FacilitySettingsGeneralActions.clearFacilitySettings, (state) => {
    return facilitySettingsAdapter.removeAll({
      ...state,
      total: 0,
      skip: 0,
      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function facilitySettingsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
