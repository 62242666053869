import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import { prnAmountsFeature } from './prn-amounts.reducer';
import { prnAmountsAdapter } from './prn-amounts.state';

export const {
  selectPrnAmountsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = prnAmountsFeature;

export const { selectAll, selectTotal } = prnAmountsAdapter.getSelectors(
  selectPrnAmountsState
);

export const selectAmountsByPrn = createSelector(selectAll, (prnAmounts) => {
  return groupBy(prnAmounts, 'prnId');
});

export const selectAllByInventoryId = createSelector(
  selectAll,
  (prnAmounts) => {
    return groupBy(prnAmounts, 'medicationInventoryItemId');
  }
);
