import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { medicationSchedulesReducer } from './medication-schedules.reducer';
import { MedicationSchedulesEffects } from './medication-schedules.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('medicationSchedules', medicationSchedulesReducer),
    EffectsModule.forFeature([MedicationSchedulesEffects])
  ],
  providers: [MedicationSchedulesEffects]
})
export class MedicationSchedulesStoreModule {}
