import { createSelector } from '@ngrx/store';
import { documentParticipantsFeature } from './document-participants.reducer';
import { documentParticipantsAdapter } from './document-participants.state';

export const {
  selectDocumentParticipantsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = documentParticipantsFeature;

export const { selectAll, selectTotal } =
  documentParticipantsAdapter.getSelectors(selectDocumentParticipantsState);

export const selectAllSigners = createSelector(selectAll, (participants) =>
  participants.filter((p) => p.role !== 'editor')
);
