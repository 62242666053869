import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LibraryDocumentTemplatePacketsEffects } from './library-document-template-packets.effects';
import { libraryDocumentTemplatePacketsFeature } from './library-document-template-packets.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(libraryDocumentTemplatePacketsFeature),
    EffectsModule.forFeature([LibraryDocumentTemplatePacketsEffects])
  ],
  providers: [LibraryDocumentTemplatePacketsEffects]
})
export class LibraryDocumentTemplatePacketsStoreModule {}
