import { ScheduleTime, Table } from '$/models/data/base';
import { DoseValue } from '$shared/doses';
import { DoseTypeId } from '$shared/lookups/dose-types.lookup';
import { MeasurementId } from '$shared/lookups/measurements.lookup';
import { MedicationScheduleTypeId } from '$shared/lookups/medication-schedule-types.lookup';

export class MedicationSchedule extends Table {
  orgId?: string;
  facilityId?: string;
  medicationId?: string;
  dtstart?: string;
  until?: string;
  frequencyTypeId?: MedicationScheduleTypeId;
  su?: boolean;
  mo?: boolean;
  tu?: boolean;
  we?: boolean;
  th?: boolean;
  fr?: boolean;
  sa?: boolean;
  weekInterval?: number;
  dayInterval?: number;
  doseTypeId?: DoseTypeId;
  doseMeasurementId?: MeasurementId;
  dose?: DoseValue;
  isActive?: string;

  times?: ScheduleTime[];

  constructor(schedule: MedicationSchedule) {
    super();
    Object.assign(this, schedule);
  }
}
