import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IMoodLog } from '$shared/services/mood-log';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { State, moodLogsAdapter } from './mood-logs.state';

export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;

// Mood Logs State Selector
export const selectMoodLogsState = createFeatureSelector<State>('moodLogs');

const entitySelectors = moodLogsAdapter.getSelectors(selectMoodLogsState);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(selectMoodLogsState, getLoading);
export const selectLoaded = createSelector(selectMoodLogsState, getLoaded);
export const selectError = createSelector(selectMoodLogsState, getError);

export const selectPagination = (query) => {
  return createSelector(selectMoodLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentMoodLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (moodLogs, residentId): IMoodLog[] => {
    if (isEmpty(moodLogs) || !residentId) {
      return [];
    }

    return moodLogs.filter((moodLog) => {
      return moodLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<IMoodLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IMoodLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IMoodLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => ({
    metrics: [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length ?? 0
      }
    ],
    logs
  }));
};
