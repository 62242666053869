import { DiagnosesEffects } from '$/app/store/diagnoses/diagnoses.effects';
import { diagnosesFeature } from '$/app/store/diagnoses/diagnoses.reducer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(diagnosesFeature),
    EffectsModule.forFeature([DiagnosesEffects])
  ],
  providers: [DiagnosesEffects]
})
export class DiagnosesStoreModule {}
