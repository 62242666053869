import { MedicalEventsEffects } from '$/app/store/medical-events/medical-events.effects';
import { medicalEventsFeature } from '$/app/store/medical-events/medical-events.reducer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(medicalEventsFeature),
    EffectsModule.forFeature([MedicalEventsEffects])
  ],
  providers: [MedicalEventsEffects]
})
export class MedicalEventsStoreModule {}
