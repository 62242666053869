import { OpenIncidentReportsPageActions } from '$/app/pages/notebook/dashboard/open-incident-reports/open-incident-reports.actions';
import { EventsListActions } from '$/app/pages/notebook/residents/events-list/events-list.actions';
import { IncidentReportModalActions } from '$/app/pages/notebook/residents/incident-report-modal/incident-report.modal.actions';
import { getPaginationInfo } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  IncidentReportsApiActions,
  IncidentReportsGeneralActions,
  IncidentReportsWsActions
} from './actions';
import { incidentReportsAdapter, initialState } from './incident-reports.state';

export const incidentReportsFeature = createFeature({
  name: 'incidentReports',
  reducer: createReducer(
    initialState,
    on(
      EventsListActions.loadIncidentReports,
      OpenIncidentReportsPageActions.loadIncidentReports,
      IncidentReportModalActions.createIncidentReport,
      IncidentReportModalActions.updateIncidentReport,
      EventsListActions.deleteIncidentReport,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      IncidentReportsApiActions.loadIncidentReportsSuccess,
      (state, { incidentReports }) => {
        const pagination = getPaginationInfo(incidentReports);

        return incidentReportsAdapter.setAll(incidentReports.data, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      IncidentReportsApiActions.getIncidentReportsSuccess,
      (state, { incidentReports }) => {
        const pagination = getPaginationInfo(incidentReports);

        return incidentReportsAdapter.upsertMany(incidentReports.data, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      IncidentReportsApiActions.fetchIncidentReportSuccess,
      (state, { incidentReport }) => {
        return incidentReportsAdapter.upsertOne(incidentReport, {
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    ),

    // HACK(2024-03-01): Come up with a better way to handle this.
    /**
     * We do not want to create, patch, or remove the incident report in the store from the response
     * of the API call, but instead make those updates when the user receives the websocket
     * event. This is because the user may not have permission view the record.
     * As it stands, API calls will return the record that is created, patched or removed
     * regardless of the user's permissions. On the other hand websocket events are routed
     * to channels that are based on the user's permission to view said record. i.e.
     * Any created, patched or removed events will not be routed to those who do not have the
     * view permission. The only thing we do want to handle in response to successful create,
     * patch, and remove calls is update the loading state to false in indicate the request is complete.
     */
    on(
      IncidentReportsApiActions.createIncidentReportSuccess,
      IncidentReportsApiActions.updateIncidentReportSuccess,
      IncidentReportsApiActions.deleteIncidentReportSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          error: null
        };
      }
    ),

    on(
      IncidentReportsApiActions.loadIncidentReportsFail,
      IncidentReportsApiActions.getIncidentReportsFail,
      IncidentReportsApiActions.fetchIncidentReportFail,
      IncidentReportsApiActions.createIncidentReportFail,
      IncidentReportsApiActions.updateIncidentReportFail,
      IncidentReportsApiActions.deleteIncidentReportFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      IncidentReportsWsActions.incidentReportCreated,
      IncidentReportsWsActions.incidentReportPatched,
      (state, action) => {
        return incidentReportsAdapter.upsertOne(action.incidentReport, state);
      }
    ),

    on(IncidentReportsWsActions.incidentReportRemoved, (state, action) => {
      return incidentReportsAdapter.removeOne(action.id, state);
    }),

    on(IncidentReportsGeneralActions.addIncidentReports, (state, action) => {
      return incidentReportsAdapter.upsertMany(action.incidentReports, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(IncidentReportsGeneralActions.clearIncidentReports, () => {
      return incidentReportsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
