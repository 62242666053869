import { AlcAction, IDocument, Update } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const DocumentDetailsInfoPageActions = createActionGroup({
  source: 'Document Details Page',
  events: {
    'Fetch Document': AlcAction<{
      id: string;
    }>(),

    'Update Document': AlcAction<Update<IDocument>>(),

    'Upload File': AlcAction<{
      id: string;
      changes: Pick<IDocument, 'status' | 'statusChangedAt' | 'expiresAt'>;
      file: File;
    }>()
  }
});
