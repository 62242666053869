import { sortByDesc } from '$/app/utils';
import { IPaginationInfo, ShiftNote } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface ShiftNotesPageQueries {
  all: IPaginationInfo;
}

export const shiftNotesAdapter = createEntityAdapter<ShiftNote>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<ShiftNote> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = shiftNotesAdapter.getInitialState({
  pagination: {
    total: 0,
    skip: 0
  },
  loading: false,
  loaded: false,
  error: null
});
