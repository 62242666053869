import { NarcoticCountListPageActions } from '$/app/pages/medications/dashboard/narcotic-counts/narcotic-count-list/narcotic-count-list.actions';
import { InventoryItemDetailPageActions } from '$/app/pages/medications/residents/inventory-item-detail/inventory-item-detail.actions';
import { InventoryItemCountModalActions } from '$/app/pages/medications/shared/components/inventory-item-count-modal/inventory-item-count.modal.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  MedicationInventoryItemCountsApiActions,
  MedicationInventoryItemCountsGeneralActions,
  MedicationInventoryItemCountsWsActions
} from './medication-inventory-item-counts.actions';
import {
  initialState,
  medicationInventoryItemCountsAdapter
} from './medication-inventory-item-counts.state';

export const medicationInventoryItemCountsFeature = createFeature({
  name: 'medicationInventoryItemCounts',
  reducer: createReducer(
    initialState,
    on(
      InventoryItemCountModalActions.createMedicationInventoryItemCount,
      InventoryItemCountModalActions.updateMedicationInventoryItemCount,
      NarcoticCountListPageActions.deleteMedicationInventoryItemCount,
      InventoryItemDetailPageActions.deleteMedicationInventoryItemCount,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      MedicationInventoryItemCountsApiActions.loadMedicationInventoryItemCountsSuccess,
      (state, action) => {
        return medicationInventoryItemCountsAdapter.setAll(
          action.medicationInventoryItemCounts,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationInventoryItemCountsApiActions.getMedicationInventoryItemCountsSuccess,
      (state, action) => {
        return medicationInventoryItemCountsAdapter.upsertMany(
          action.medicationInventoryItemCounts,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationInventoryItemCountsApiActions.fetchMedicationInventoryItemCountSuccess,
      (state, action) => {
        return medicationInventoryItemCountsAdapter.upsertOne(
          action.medicationInventoryItemCount,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationInventoryItemCountsApiActions.createMedicationInventoryItemCountSuccess,
      (state, action) => {
        const medicationInventoryItemCounts = castArray(
          action.medicationInventoryItemCount
        );

        return medicationInventoryItemCountsAdapter.addMany(
          medicationInventoryItemCounts,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationInventoryItemCountsApiActions.updateMedicationInventoryItemCountSuccess,
      (state, action) => {
        return medicationInventoryItemCountsAdapter.upsertOne(
          action.medicationInventoryItemCount,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationInventoryItemCountsApiActions.deleteMedicationInventoryItemCountSuccess,
      (state, action) => {
        return medicationInventoryItemCountsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationInventoryItemCountsApiActions.loadMedicationInventoryItemCountsFail,
      MedicationInventoryItemCountsApiActions.getMedicationInventoryItemCountsFail,
      MedicationInventoryItemCountsApiActions.fetchMedicationInventoryItemCountFail,
      MedicationInventoryItemCountsApiActions.createMedicationInventoryItemCountFail,
      MedicationInventoryItemCountsApiActions.updateMedicationInventoryItemCountFail,
      MedicationInventoryItemCountsApiActions.deleteMedicationInventoryItemCountFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicationInventoryItemCountsWsActions.medicationInventoryItemCountCreated,
      MedicationInventoryItemCountsWsActions.medicationInventoryItemCountPatched,
      (state, action) => {
        return medicationInventoryItemCountsAdapter.upsertOne(
          action.medicationInventoryItemCount,
          {
            ...state
          }
        );
      }
    ),

    on(
      MedicationInventoryItemCountsWsActions.medicationInventoryItemCountRemoved,
      (state, action) => {
        return medicationInventoryItemCountsAdapter.removeOne(action.id, {
          ...state
        });
      }
    ),

    on(
      MedicationInventoryItemCountsGeneralActions.addMedicationInventoryItemCounts,
      (state, action) => {
        return medicationInventoryItemCountsAdapter.upsertMany(
          action.medicationInventoryItemCounts,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      MedicationInventoryItemCountsGeneralActions.clearMedicationInventoryItemCounts,
      (state) => {
        return medicationInventoryItemCountsAdapter.removeAll({
          ...state,
          total: 0,
          skip: 0,
          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
