import { CareTask, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DashboardCarePageActions = createActionGroup({
  source: 'Dashboard Care Page',
  events: {
    'Get Care Tasks': (params: Params = { query: {} }) => ({ params }),
    'Care Task Completed': props<Update<CareTask>>(),
    'Care Task State Reset': props<Update<CareTask>>()
  }
});
