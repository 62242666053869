import { FeathersService } from '$/app/services/feathers.service';
import { NotificationsApiActions } from '$/app/store/notifications';
import { IHydratedNotification } from '$shared/notifications';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService extends AbstractApiService<IHydratedNotification> {
  constructor(feathers: FeathersService, store: Store) {
    super('notifications', feathers, store, {
      entityName: 'notification',
      created: NotificationsApiActions.notificationCreated,
      patched: NotificationsApiActions.notificationPatched,
      removed: NotificationsApiActions.notificationRemoved,
      multipleCreated: NotificationsApiActions.notificationsCreated,
      multiplePatched: NotificationsApiActions.notificationsPatched,
      multipleRemoved: NotificationsApiActions.notificationsRemoved
    });

    this.service.on('archivedAll', ({ bulkRecords: notifications }) => {
      this.store.dispatch(
        NotificationsApiActions.allNotificationsArchived({ notifications })
      );
    });

    this.service.on('readAll', ({ bulkRecords: notifications }) => {
      this.store.dispatch(
        NotificationsApiActions.allNotificationsRead({ notifications })
      );
    });
  }
}
