<div class="@container">
  <form
    [formGroup]="datetime"
    class="flex flex-col gap-x-6 gap-y-4 @sm:flex-row"
  >
    <alc-date-input
      [required]="required"
      [label]="dateLabel"
      [showClear]="showClear?.includes('date')"
      formControlName="date"
      [min]="minDate"
      [max]="maxDate"
    />
    <alc-time-input
      [label]="timeLabel"
      [showClear]="showClear?.includes('time')"
      formControlName="time"
      [min]="minTime"
      [max]="maxTime"
    />
  </form>
</div>

<!-- DO NOT REMOVE: This is here to make Angular's change detection system do the right thing. -->
<span class="m-0 size-0" *ngIf="showError"></span>
