import { FeathersService } from '$/app/services/feathers.service';
import { toUpdated } from '$/app/utils';
import { MedicationMar, Update } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { MedicationMarsWsActions } from './medication-mars.actions';

@Injectable({ providedIn: 'root' })
export class MedicationMarsApiService {
  private medicationMarsService: Service<MedicationMar>;

  constructor(
    feathers: FeathersService,
    private store: Store
  ) {
    this.medicationMarsService = feathers.client.service('medication-mars');

    this.medicationMarsService.on('created', (medicationMar) => {
      if ('bulkRecords' in medicationMar) {
        this.store.dispatch(
          MedicationMarsWsActions.medicationMarsBatchCreated({
            medicationMars: medicationMar.bulkRecords
          })
        );
      } else {
        this.store.dispatch(
          MedicationMarsWsActions.medicationMarsCreated({ medicationMar })
        );
      }
    });

    this.medicationMarsService.on('patched', (medicationMar) => {
      if ('bulkRecords' in medicationMar) {
        this.store.dispatch(
          MedicationMarsWsActions.medicationMarsBatchPatched({
            medicationMars: medicationMar.bulkRecords
          })
        );
      } else {
        this.store.dispatch(
          MedicationMarsWsActions.medicationMarsPatched({ medicationMar })
        );
      }
    });

    this.medicationMarsService.on('removed', (medicationMar) => {
      if ('bulkRecords' in medicationMar) {
        this.store.dispatch(
          MedicationMarsWsActions.medicationMarsBatchRemoved({
            ids: medicationMar.bulkRecords.map((record) => record.id)
          })
        );
      } else {
        this.store.dispatch(
          MedicationMarsWsActions.medicationMarsRemoved({
            id: medicationMar.id
          })
        );
      }
    });
  }

  patch(
    id: string,
    medicationMar: Partial<MedicationMar>
  ): Observable<Update<MedicationMar>> {
    const promise = this.medicationMarsService.patch(id, medicationMar);

    return from(promise).pipe(map(toUpdated));
  }
}
