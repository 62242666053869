import { CommunityLibraryModalActions } from '$/app/pages/documents/community-library/community-library.modal.actions';
import { FacilityLibraryPageActions } from '$/app/pages/documents/facility-document-library/facility-document-library.actions';
import { EffectHelpersService } from '$/app/services';
import { InstalledLibraryCollectionsApiService } from '$/app/services/api/installed-library-collections.service';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { InstalledLibraryCollectionsApiActions } from './installed-library-collections.actions';

@Injectable()
export class InstalledLibraryCollectionsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly installedLibraryCollectionsService = inject(
    InstalledLibraryCollectionsApiService
  );

  loadInstalledLibraryCollections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityLibraryModalActions.loadInstalledLibraryCollections),
      switchMap((action) => {
        return this.installedLibraryCollectionsService
          .getAll(action.params)
          .pipe(
            getAllPages(
              this.installedLibraryCollectionsService,
              action?.params?.query
            ),
            reducePaginatedResponses(),
            mergeMap((response) => {
              const responseData = new ApiData(
                'installedLibraryCollections',
                response,
                InstalledLibraryCollectionsApiActions.loadInstalledLibraryCollectionsSuccess
              );

              return responseData.getActions();
            }),
            catchError((error) => {
              return of(
                InstalledLibraryCollectionsApiActions.loadInstalledLibraryCollectionsFail(
                  { error }
                )
              );
            })
          );
      })
    );
  });

  createInstalledLibraryCollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityLibraryPageActions.installLibraryCollections),
      this.effectHelpers.apiRequest({
        description: 'Create installed library collection',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.installedLibraryCollectionsService.installCollections(
            action.data,
            action.params
          );
        },
        onSuccess: (installedLibraryCollection) =>
          InstalledLibraryCollectionsApiActions.createInstalledLibraryCollectionSuccess(
            {
              installedLibraryCollection
            }
          ),
        onError: (error) =>
          InstalledLibraryCollectionsApiActions.createInstalledLibraryCollectionFail(
            { error }
          )
      })
    );
  });
}
