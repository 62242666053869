import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import { omittedAmountsFeature } from './omitted-amounts.reducer';
import { omittedAmountsAdapter } from './omitted-amounts.state';

const { selectOmittedAmountsState } = omittedAmountsFeature;

const { selectAll } = omittedAmountsAdapter.getSelectors(
  selectOmittedAmountsState
);

const selectAllByOmittedId = createSelector(selectAll, (omittedAmounts) => {
  return groupBy(omittedAmounts, 'omittedMedicationId');
});

export const OmittedAmountsSelectors = {
  selectAllByOmittedId
};
