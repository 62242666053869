import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IUrineLog } from '$shared/services/urine-log';
import { createEntityAdapter } from '@ngrx/entity';

export const urineLogsAdapter = createEntityAdapter<IUrineLog>({
  sortComparer: sortByDesc('recordAt')
});

export const initialState = urineLogsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
