import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { SleepLogsPageActions } from '$/app/pages/notebook/shared/logs/sleep-logs/sleep-logs.actions';
import { SleepLogFormPageActions } from '$/app/shared/pages/forms/log-forms/sleep-log-form/sleep-log-form.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { SleepLog } from '$/models/data/entities/logbook/sleep-log.model';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { createFeature, createReducer, on } from '@ngrx/store';
import { SleepLogsApiActions, SleepLogsGeneralActions } from './actions';
import {
  SleepLogPageQueries,
  initialState,
  sleepLogsAdapter
} from './sleep-logs.state';

export const sleepLogsFeature = createFeature({
  name: 'sleepLogs',
  reducer: createReducer(
    initialState,

    on(
      SleepLogsPageActions.loadSleepLogs,
      SleepLogsPageActions.getSleepLogs,
      SleepLogFormPageActions.createSleepLog,
      SleepLogFormPageActions.updateSleepLog,
      SleepLogsPageActions.deleteSleepLog,
      ResidentMedicationTasksPageActions.deleteSleepLog,
      MissedMedicationsPageActions.deleteSleepLog,
      RoutineMarListPageActions.deleteSleepLog,
      RoutineMarDetailPageActions.deleteSleepLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(SleepLogsApiActions.loadSleepLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<SleepLogPageQueries>(
        state,
        action.sleepLogs,
        queryType
      );
      const data = getRecords(action.sleepLogs);

      return sleepLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(SleepLogsApiActions.getSleepLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<SleepLogPageQueries>(
        state,
        action.sleepLogs,
        queryType
      );
      const data = getRecords(action.sleepLogs);

      return sleepLogsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(SleepLogsApiActions.createSleepLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(SleepLogsApiActions.updateSleepLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(SleepLogsApiActions.deleteSleepLogSuccess, (state, action) =>
      sleepLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      SleepLogsApiActions.loadSleepLogsFail,
      SleepLogsApiActions.getSleepLogsFail,
      SleepLogsApiActions.createSleepLogFail,
      SleepLogsApiActions.updateSleepLogFail,
      SleepLogsApiActions.deleteSleepLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      SleepLogsApiActions.sleepLogsCreated,
      SleepLogsApiActions.sleepLogsPatched,
      (state, action) => {
        const { facilityUser, ...sleepLog } = action.sleepLog as SleepLog & {
          facilityUser?: IFacilityUser;
        };

        return sleepLogsAdapter.upsertOne(sleepLog, state);
      }
    ),

    on(SleepLogsApiActions.sleepLogsRemoved, (state, action) => {
      return sleepLogsAdapter.removeOne(action.id, state);
    }),

    on(SleepLogsGeneralActions.addSleepLogs, (state, action) => {
      return sleepLogsAdapter.upsertMany(action.sleepLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(SleepLogsGeneralActions.clearSleepLogs, () => {
      return sleepLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
