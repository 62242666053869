import { Paginated, SeizureLogSymptom } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Seizure Log Symptom Symptoms API]';
const sourceWS = '[Seizure Log Symptom Symptoms WS]';

// Load
export const loadSeizureLogSymptomsSuccess = createAction(
  `${source} Load Seizure Log Symptom Symptoms Success`,
  props<{ seizureLogSymptoms: Paginated<SeizureLogSymptom> }>()
);
export const loadSeizureLogSymptomsFail = createAction(
  `${source} Load Seizure Log Symptom Symptoms Fail`,
  props<{ error: Error }>()
);
// Get
export const getSeizureLogSymptomsSuccess = createAction(
  `${source} Get Seizure Log Symptom Symptoms Success`,
  props<{ seizureLogSymptoms: Paginated<SeizureLogSymptom> }>()
);
export const getSeizureLogSymptomsFail = createAction(
  `${source} Get Seizure Log Symptom Symptoms Fail`,
  props<{ error: Error }>()
);
// Create
export const createSeizureLogSymptomSuccess = createAction(
  `${source} Add Seizure Log Symptoms Success`,
  props<{ seizureLogSymptom: SeizureLogSymptom }>()
);
export const createSeizureLogSymptomFail = createAction(
  `${source} Add Seizure Log Symptoms Fail`,
  props<{ error: Error }>()
);
// Update
export const updateSeizureLogSymptomSuccess = createAction(
  `${source} Update Seizure Log Symptom Success`,
  props<{ seizureLogSymptom: SeizureLogSymptom }>()
);
export const updateSeizureLogSymptomFail = createAction(
  `${source} Update Seizure Log Symptom Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteSeizureLogSymptomSuccess = createAction(
  `${source} Delete Seizure Log Symptom Success`,
  props<{ id: string }>()
);
export const deleteSeizureLogSymptomFail = createAction(
  `${source} Delete Seizure Log Symptom Symptoms Fail`,
  props<{ error: Error }>()
);

// [WS] Seizure Log Symptom Symptoms Created
export const seizureLogSymptomsCreated = createAction(
  `${sourceWS} Seizure Log Symptom Symptoms Created`,
  props<{ seizureLogSymptom: SeizureLogSymptom }>()
);
// [WS] Seizure Log Symptom Symptoms Patched
export const seizureLogSymptomsPatched = createAction(
  `${sourceWS} Seizure Log Symptom Symptoms Patched`,
  props<{ seizureLogSymptom: SeizureLogSymptom }>()
);
// [WS] Seizure Log Symptom Symptoms Removed
export const seizureLogSymptomsRemoved = createAction(
  `${sourceWS} Seizure Log Symptom Symptoms Removed`,
  props<{ id: string }>()
);
