import { AlcomyErrorCodes } from '$/config';
import { ErrorMessage } from '$shared/utils';

export type AlcomyErrorMessage = string | ErrorMessage;

export class AlcomyError extends Error {
  code: number;
  data: any;

  constructor(
    message: AlcomyErrorMessage,
    name: string,
    code: AlcomyErrorCodes,
    data
  ) {
    const properties = { name, code, data };

    super(message.toString());
    Object.assign(this, properties);
  }

  toJSON() {
    const result: any = {
      name: this.name,
      message: this.message,
      code: this.code
    };

    if (this.data !== undefined) {
      result.data = this.data;
    }

    return result;
  }
}
