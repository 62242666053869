import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './geolocation.state';

const selectGeolocationState = createFeatureSelector<State>('geolocation');

const selectGeolocation = createSelector(
  selectGeolocationState,
  (state: State) => state.geolocation
);

export const GeolocationSelectors = {
  selectGeolocation
};
