import { Table } from '$/models/data/base';
import { AllergyLevelId } from '$shared/lookups/allergy-levels.lookup';
import { AllergyTypeId } from '$shared/lookups/allergy-types.lookup';

export class Allergy extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  name: string;
  typeId?: AllergyTypeId;
  levelId: AllergyLevelId;
  notes?: string;
}
