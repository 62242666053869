import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  RolePermissionsApiActions,
  RolePermissionsGeneralActions
} from './actions';
import {
  State,
  initialState,
  rolePermissionsAdapter
} from './role-permissions.state';

const reducer = createReducer(
  initialState,

  on(RolePermissionsApiActions.createRolePermissionSuccess, (state, action) => {
    const rolePermissions = castArray(action.rolePermission);

    return rolePermissionsAdapter.addMany(rolePermissions, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(RolePermissionsApiActions.deleteRolePermissionSuccess, (state, action) => {
    return rolePermissionsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    RolePermissionsApiActions.createRolePermissionFail,
    RolePermissionsApiActions.deleteRolePermissionFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    RolePermissionsApiActions.rolePermissionsCreated,
    RolePermissionsApiActions.rolePermissionsPatched,
    (state, action) => {
      return rolePermissionsAdapter.upsertOne(action.rolePermission, {
        ...state
      });
    }
  ),

  on(RolePermissionsApiActions.rolePermissionsRemoved, (state, action) => {
    return rolePermissionsAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(RolePermissionsGeneralActions.addRolePermissions, (state, action) => {
    return rolePermissionsAdapter.upsertMany(action.rolePermissions, {
      ...state,
      loading: false,
      error: null
    });
  })
);

export function rolePermissionsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
