import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const SleepLogsPageActions = createActionGroup({
  source: 'Sleep Log Page',
  events: {
    'Load Sleep Logs': (params: Params) => ({ params }),
    'Get Sleep Logs': (params: Params) => ({ params }),
    'Delete Sleep Log': props<{ id: string; params?: Params }>()
  }
});
