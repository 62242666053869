import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IBodyCheckLog } from '$shared/services/body-check-log';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const bodyCheckLogsAdapter = createEntityAdapter<IBodyCheckLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<IBodyCheckLog> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = bodyCheckLogsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
