import { FeathersService } from '$/app/services/feathers.service';
import { BehaviorsWsActions } from '$/app/store/behaviors/behaviors.actions';
import { Params } from '$/models';
import { IBehavior } from '$shared/services/behavior';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { AbstractApiService } from './abstract-api-service.service';

interface BehaviorMetrics {
  id: string;
  count: string;
  occurrences: string;
  averageDuration: string;
  averageSeverityRating: string;
}

@Injectable({ providedIn: 'root' })
export class BehaviorsApiService extends AbstractApiService<IBehavior> {
  constructor(feathers: FeathersService, store: Store) {
    super('behaviors', feathers, store, {
      entityName: 'behavior',
      created: BehaviorsWsActions.behaviorsCreated,
      patched: BehaviorsWsActions.behaviorsPatched,
      removed: BehaviorsWsActions.behaviorsRemoved
    });
  }

  getMetrics(params: Params): Observable<BehaviorMetrics[]> {
    const result = this.service.find(params) as unknown as Promise<
      BehaviorMetrics[]
    >;

    return from(result);
  }
}
