import { AnnouncementReply, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AnnouncementRepliesApiActions = createActionGroup({
  source: 'Announcement Replies API',
  events: {
    'Load Announcement Replies Success': props<{
      announcementReplies: AnnouncementReply[];
    }>(),
    'Get Announcement Replies Success': props<{
      announcementReplies: AnnouncementReply[];
    }>(),
    'Create Announcement Reply Success': props<{
      announcementReply: MaybeArray<AnnouncementReply>;
    }>(),
    'Update Announcement Reply Success': props<{
      announcementReply: Update<AnnouncementReply>;
    }>(),

    'Load Announcement Replies Fail': props<{ error: Error }>(),
    'Get Announcement Replies Fail': props<{ error: Error }>(),
    'Create Announcement Reply Fail': props<{ error: Error }>(),
    'Update Announcement Reply Fail': props<{ error: Error }>()
  }
});

export const AnnouncementRepliesWsActions = createActionGroup({
  source: 'Announcement Replies WS',
  events: {
    'Announcement Replies Created': props<{
      announcementReply: AnnouncementReply;
    }>(),
    'Announcement Replies Patched': props<{
      announcementReply: AnnouncementReply;
    }>(),
    'Announcement Replies Removed': props<{ id: string }>()
  }
});

export const AnnouncementRepliesGeneralActions = createActionGroup({
  source: 'Announcement Replies General',
  events: {
    'Clear Announcement Replies': emptyProps(),
    'Add Announcement Replies': props<{
      announcementReplies: AnnouncementReply[];
    }>()
  }
});
