import { BehaviorModalActions } from '$/app/pages/notebook/residents/behavior-modal/behavior.modal.actions';
import { BehaviorsPageActions } from '$/app/pages/notebook/residents/behaviors/behaviors.actions';
import { BehaviorLogsPageActions } from '$/app/pages/notebook/shared/logs/behavior-logs/behavior-log.actions';
import { BehaviorsApiService, EffectHelpersService } from '$/app/services';
import { ApiData } from '$/app/utils';
import { IBehavior } from '$shared/services/behavior';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  tap
} from 'rxjs';
import { BehaviorsApiActions } from './behaviors.actions';

@Injectable()
export class BehaviorsEffects {
  private readonly actions$ = inject(Actions);
  private readonly behaviorsService = inject(BehaviorsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  getBehaviors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BehaviorsPageActions.getBehaviors,
        BehaviorLogsPageActions.getBehaviors
      ),
      switchMap((action) => {
        return this.behaviorsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('behaviors', logs);
            responseData.setPrimaryAction(
              BehaviorsApiActions.getBehaviorsSuccess
            );
            return responseData.getActions();
          })
        );
      })
    );
  });
  getBehaviorMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BehaviorsPageActions.getBehaviorMetrics),
      switchMap((action) => {
        return this.behaviorsService.getMetrics(action.params).pipe(
          map((behaviors) => {
            const metrics = behaviors.reduce((acc, behavior) => {
              acc[behavior.id] = {
                count: +behavior.count,
                occurrences: +behavior.occurrences,
                averageDuration: +behavior.averageDuration,
                averageSeverityRating: +behavior.averageSeverityRating
              };
              return acc;
            }, {});

            return BehaviorsApiActions.getBehaviorMetricsSuccess({
              metrics
            });
          }),
          catchError((error) => {
            return of(
              BehaviorsApiActions.createBehaviorFail({
                error
              })
            );
          })
        );
      })
    );
  });

  createBehavior$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BehaviorModalActions.createBehavior),
      exhaustMap((action) => {
        return this.behaviorsService.create(action.behavior).pipe(
          tap((data: IBehavior) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((behavior: IBehavior) =>
            BehaviorsApiActions.createBehaviorSuccess({
              behavior
            })
          ),
          catchError((error) => {
            return of(
              BehaviorsApiActions.createBehaviorFail({
                error
              })
            );
          })
        );
      })
    );
  });

  updateBehavior$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BehaviorModalActions.updateBehavior,
        BehaviorsPageActions.archiveBehavior,
        BehaviorsPageActions.unarchiveBehavior
      ),
      switchMap((action) => {
        return this.behaviorsService.patch(action.id, action.changes).pipe(
          tap((data: IBehavior) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((behavior: IBehavior) =>
            BehaviorsApiActions.updateBehaviorSuccess({
              behavior
            })
          ),
          catchError((error) => {
            return of(BehaviorsApiActions.updateBehaviorFail({ error }));
          })
        );
      })
    );
  });

  deleteBehavior$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BehaviorsPageActions.deleteBehavior),
      this.effectHelpers.apiRequest({
        description: 'deleting behavior',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.behaviorsService.delete(action.id, action.params),
        onSuccess: (behavior: IBehavior) =>
          BehaviorsApiActions.createBehaviorSuccess({ behavior })
      })
    );
  });
}
