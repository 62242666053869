import { Paginated } from '$/models';
import { IAppointment } from '$shared/appointments/appointments.interface';
import { createActionGroup, props } from '@ngrx/store';

export const AppointmentsApiActions = createActionGroup({
  source: 'Appointments API',
  events: {
    'Load Appointments Success': props<{
      appointments: Paginated<IAppointment>;
    }>(),
    'Load Appointments Fail': props<{ error: Error }>(),
    'Get Appointments Success': props<{
      appointments: Paginated<IAppointment>;
    }>(),
    'Get Appointments Fail': props<{ error: Error }>(),
    'Fetch Appointment Success': props<{ appointment: IAppointment }>(),
    'Fetch Appointment Fail': props<{ error: Error }>(),
    'Create Appointment Success': props<{ appointment: IAppointment }>(),
    'Create Appointment Fail': props<{ error: Error }>(),
    'Update Appointment Success': props<{
      appointment: IAppointment;
    }>(),
    'Update Appointment Fail': props<{ error: Error }>(),
    'Delete Appointment Success': props<{ id: string }>(),
    'Delete Appointment Fail': props<{ error: Error }>()
  }
});

export const AppointmentsWsActions = createActionGroup({
  source: 'Appointments Websocket',
  events: {
    'Appointments Created': props<{ appointment: IAppointment }>(),
    'Appointments Patched': props<{ appointment: IAppointment }>(),
    'Appointments Removed': props<{ id: string }>()
  }
});
