import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MedicationInventoriesEffects } from './medication-inventories.effects';
import { medicationInventoriesReducer } from './medication-inventories.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'medicationInventories',
      medicationInventoriesReducer
    ),
    EffectsModule.forFeature([MedicationInventoriesEffects])
  ],
  providers: [MedicationInventoriesEffects]
})
export class MedicationInventoriesStoreModule {}
