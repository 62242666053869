import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { uiMedicationOrdersListPageFeature } from './medication-orders-list-page.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(uiMedicationOrdersListPageFeature)
  ]
})
export class UiMedicationOrdersListPageStoreModule {}
