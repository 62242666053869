import { Paginated, ShowerLog } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Shower Logs API]';
const sourceWS = '[Shower Logs WS]';

// Load
export const loadShowerLogsSuccess = createAction(
  `${source} Load Shower Logs Success`,
  props<{
    showerLogs: Paginated<ShowerLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const loadShowerLogsFail = createAction(
  `${source} Load Shower Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getShowerLogsSuccess = createAction(
  `${source} Get Shower Logs Success`,
  props<{
    showerLogs: Paginated<ShowerLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const getShowerLogsFail = createAction(
  `${source} Get Shower Logs Fail`,
  props<{ error: Error }>()
);
// Create
export const createShowerLogSuccess = createAction(
  `${source} Create Shower Log Success`,
  props<{ showerLog: ShowerLog }>()
);
export const createShowerLogFail = createAction(
  `${source} Create Shower Log Fail`,
  props<{ error: Error }>()
);

export const updateShowerLogSuccess = createAction(
  `${source} Update Shower Log Success`,
  props<{ showerLog: ShowerLog }>()
);
export const updateShowerLogFail = createAction(
  `${source} Update Shower Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteShowerLogSuccess = createAction(
  `${source} Delete Shower Log Success`,
  props<{ id: string }>()
);
export const deleteShowerLogFail = createAction(
  `${source} Delete Shower Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Shower Logs Created
export const showerLogsCreated = createAction(
  `${sourceWS} Shower Logs Created`,
  props<{ showerLog: ShowerLog }>()
);
// [WS] Shower Logs Patched
export const showerLogsPatched = createAction(
  `${sourceWS} Shower Logs Patched`,
  props<{ showerLog: ShowerLog }>()
);
// [WS] Shower Logs Removed
export const showerLogsRemoved = createAction(
  `${sourceWS} Shower Logs Removed`,
  props<{ id: string }>()
);
