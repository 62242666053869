import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { ResidentInsuranceFormPageActions } from '$/app/pages/residents/resident-detail/shared/resident-insurance-form/resident-insurance-form.actions';
import { EffectHelpersService } from '$/app/services';
import { ResidentInsurancesApiService } from '$/app/services/api/resident-insurances.service';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ResidentInsurancesApiActions } from './resident-insurances.actions';

@Injectable()
export class ResidentInsurancesEffects {
  private readonly actions$ = inject(Actions);
  private readonly residentInsurancesApiService = inject(
    ResidentInsurancesApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  createResidentInsurance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentInsuranceFormPageActions.createInsurance),
      this.effectHelpers.apiRequest({
        description: 'creating resident insurance',
        onRequest: (action) =>
          this.residentInsurancesApiService.create(action.insurance),
        useMapOperator: 'exhaustMap',
        onSuccess: (residentInsurance) =>
          ResidentInsurancesApiActions.createResidentInsuranceSuccess({
            residentInsurance
          }),
        onError: (error) =>
          ResidentInsurancesApiActions.createResidentInsuranceFail({ error })
      })
    );
  });

  createResidentInsuranceFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentInsurancesApiActions.createResidentInsuranceFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateResidentInsurance$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentInsuranceFormPageActions.updateInsurance),
        this.effectHelpers.apiRequest({
          description: 'updating resident insurance',
          onRequest: (action) =>
            this.residentInsurancesApiService.patch(action.id, action.changes),
          onSuccess: (residentInsurance) =>
            ResidentInsurancesApiActions.createResidentInsuranceSuccess({
              residentInsurance
            }),
          onError: (error) => {
            ResidentInsurancesApiActions.updateResidentInsuranceFail({
              error
            });
          }
        })
      );
    },
    { dispatch: false }
  );

  updateResidentInsuranceSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentInsurancesApiActions.updateResidentInsuranceSuccess)
      );
    },
    { dispatch: false }
  );

  updateResidentInsuranceFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentInsurancesApiActions.updateResidentInsuranceFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteResidentInsurance$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentInsuranceFormPageActions.deleteInsurance,
          ResidentFacesheetPageActions.deleteResidentInsurance
        ),
        this.effectHelpers.apiRequest({
          description: 'deleting resident insurance',
          onRequest: (action) =>
            this.residentInsurancesApiService.delete(action.id),
          onSuccess: (residentInsurance) =>
            ResidentInsurancesApiActions.createResidentInsuranceSuccess({
              residentInsurance
            }),
          onError: (error) => {
            ResidentInsurancesApiActions.deleteResidentInsuranceFail({
              error
            });
          }
        })
      );
    },
    { dispatch: false }
  );

  deleteResidentInsuranceFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentInsurancesApiActions.deleteResidentInsuranceFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
