import { FeathersService } from '$/app/services/feathers.service';
import { IncidentReportsWsActions } from '$/app/store/incident-reports';
import { IIncidentReport } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class IncidentReportsApiService extends AbstractApiService<IIncidentReport> {
  constructor(feathers: FeathersService, store: Store) {
    super('incident-reports', feathers, store, {
      entityName: 'incidentReport',
      created: IncidentReportsWsActions.incidentReportCreated,
      patched: IncidentReportsWsActions.incidentReportPatched,
      removed: IncidentReportsWsActions.incidentReportRemoved
    });
  }
}
