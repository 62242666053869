import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const PositionLogsPageActions = createActionGroup({
  source: 'Position Log Page',
  events: {
    'Load Position Logs': (params: Params) => ({ params }),
    'Get Position Logs': (params: Params) => ({ params }),
    'Delete Position Log': props<{ id: string; params?: Params }>()
  }
});
