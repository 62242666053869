/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { TSchema, Type } from '@sinclair/typebox';

export function Nullable<T extends TSchema>(schema: T) {
  return Type.Union([schema, Type.Null()]);
}

export function OptionalNullable<T extends TSchema>(schema: T) {
  return Type.Optional(Nullable(schema));
}
