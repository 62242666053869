<div class="flex items-center gap-4">
  <div>
    <mat-button-toggle-group
      [disabled]="isDisabled"
      [value]="value"
      (change)="onButtonToggleChange($event)"
    >
      @for (option of options(); track option.value) {
        <mat-button-toggle
          [value]="option.value"
          (blur)="onTouch()"
          [attr.data-testid]="option.testId ?? ''"
        >
          {{ option.label }}
        </mat-button-toggle>
      }
    </mat-button-toggle-group>

    @if (hasRequiredError) {
      <mat-error class="fixed ml-4 text-xs">Required</mat-error>
    }
  </div>

  @if (label()) {
    <p class="text-dark">
      {{ label() }}
    </p>
  }
</div>
