import { sortByName } from '$/app/utils';
import { Facility } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const facilitiesAdapter = createEntityAdapter<Facility>({
  sortComparer: sortByName
});

export interface State extends EntityState<Facility> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = facilitiesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
