import { Lookups, lookupsById } from '$shared/lookups';
import { Pipe, PipeTransform } from '@angular/core';

type LookupKeys = keyof Lookups;

type LookupValues<T extends LookupKeys> =
  Lookups[T] extends Array<infer U> ? U : never;

type LookupProperties<T extends LookupKeys> = Extract<
  keyof Omit<LookupValues<T>, 'name'>,
  string
>;

type LookupPath<T extends LookupKeys> = T | `${T}.${LookupProperties<T>}`;

/**
 * Allows you to lookup a field from a lookup table using dot notation (table[.column]).
 * If field is not specified the pipe will default to the name field.
 */
@Pipe({ name: 'lookup', standalone: true })
export class AlcLookupPipe implements PipeTransform {
  transform<T extends LookupKeys = LookupKeys>(
    value: any,
    lookupPath: LookupPath<T>
  ): string {
    if (!value) {
      return value;
    }

    const [table, field = 'name'] = lookupPath.split('.');

    return lookupsById[table][value]?.[field] || null;
  }
}
