import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TemperatureLogsEffects } from './temperature-logs.effects';
import { temperatureLogsFeature } from './temperature-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(temperatureLogsFeature),
    EffectsModule.forFeature([TemperatureLogsEffects])
  ],
  providers: [TemperatureLogsEffects]
})
export class TemperatureLogsStoreModule {}
