import { Action, createReducer, on } from '@ngrx/store';
import {
  MedicationOrderItemsApiActions,
  MedicationOrderItemsGeneralActions,
  MedicationOrderItemsWSActions
} from './medication-order-items.actions';
import {
  State,
  initialState,
  medicationOrderItemsAdapter
} from './medication-order-items.state';

const reducer = createReducer(
  initialState,

  on(
    MedicationOrderItemsApiActions.updateMedicationOrderItemSuccess,
    (state, action) => {
      return medicationOrderItemsAdapter.updateOne(action.medicationOrderItem, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicationOrderItemsApiActions.deleteMedicationOrderItemSuccess,
    (state, action) => {
      return medicationOrderItemsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicationOrderItemsApiActions.getMedicationOrderItemsFail,
    MedicationOrderItemsApiActions.updateMedicationOrderItemFail,
    MedicationOrderItemsApiActions.deleteMedicationOrderItemFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    MedicationOrderItemsWSActions.medicationOrderItemsCreated,
    MedicationOrderItemsWSActions.medicationOrderItemsPatched,
    (state, action) => {
      return medicationOrderItemsAdapter.upsertOne(action.medicationOrderItem, {
        ...state
      });
    }
  ),

  on(
    MedicationOrderItemsWSActions.medicationOrderItemsRemoved,
    (state, action) => {
      return medicationOrderItemsAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(
    MedicationOrderItemsGeneralActions.addMedicationOrderItems,
    (state, action) => {
      return medicationOrderItemsAdapter.upsertMany(
        action.medicationOrderItems,
        {
          ...state,
          loading: false,
          error: null
        }
      );
    }
  )
);

export function medicationOrderItemsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
