import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'operator', standalone: true })
export class AlcOperatorPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case '<=':
        return '≤';
      case '>=':
        return '≥';
      case '!=':
        return '≠';
      default:
        return value;
    }
  }
}
