import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { FacilityPhonesWsActions } from '$/app/store/facility-phones/actions';
import { FacilityPhone } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class FacilityPhonesApiService extends AbstractApiService<FacilityPhone> {
  constructor(feathers: FeathersService, store: Store) {
    super('facility-phones', feathers, store, {
      entityName: 'facilityPhone',
      created: FacilityPhonesWsActions.facilityPhonesCreated,
      patched: FacilityPhonesWsActions.facilityPhonesPatched,
      removed: FacilityPhonesWsActions.facilityPhonesRemoved
    });
  }
}
