import { SleepLog, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const SleepLogFormPageActions = createActionGroup({
  source: 'Sleep Log Form Page',
  events: {
    'Create Sleep Log': props<{
      sleepLog: SleepLog;
    }>(),
    'Update Sleep Log': props<Update<SleepLog>>()
  }
});
