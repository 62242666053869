export * from './facility-user-phone-detail.actions';
export * from './facility-user-phone-form.actions';
export * from './facility-user-phones-list.actions';
import * as FacilityUserPhonesApiActions from './facility-user-phones-api.actions';
import * as FacilityUserPhonesGeneralActions from './facility-user-phones-gen.actions';
import * as FacilityUserPhonesWsActions from './facility-user-phones-ws.actions';

export {
  FacilityUserPhonesApiActions,
  FacilityUserPhonesGeneralActions,
  FacilityUserPhonesWsActions
};
