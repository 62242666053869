import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'alc-form-metadata',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-metadata.component.html',
  styleUrls: ['./form-metadata.component.scss']
})
export class AlcFormMetadataComponent {
  @Input() name: string;
  @Input() control: AbstractControl;
}
