import { createFeature, createReducer, on } from '@ngrx/store';
import { getPaginatedData } from '$/app/utils';
import {
  ResidentReportConfigsApiActions,
  ResidentReportConfigsGeneralActions,
  ResidentReportConfigsWsActions
} from './resident-report-configs.actions';
import {
  initialState,
  residentReportConfigsAdapter
} from './resident-report-configs.state';

export const residentReportConfigsFeature = createFeature({
  name: 'residentReportConfigs',
  reducer: createReducer(
    initialState,
    on(
      ResidentReportConfigsApiActions.loadResidentReportConfigsSuccess,
      (state, action) => {
        return residentReportConfigsAdapter.setAll(
          getPaginatedData(action.residentReportConfigs),
          {
            ...state,
            loading: false,
            loaded: true
          }
        );
      }
    ),

    on(
      ResidentReportConfigsApiActions.createResidentReportConfigSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: null
        };
      }
    ),

    on(
      ResidentReportConfigsApiActions.updateResidentReportConfigSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: null
        };
      }
    ),

    on(
      ResidentReportConfigsApiActions.deleteResidentReportConfigSuccess,
      (state, action) => {
        return residentReportConfigsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      ResidentReportConfigsApiActions.loadResidentReportConfigsFail,
      ResidentReportConfigsApiActions.createResidentReportConfigFail,
      ResidentReportConfigsApiActions.updateResidentReportConfigFail,
      ResidentReportConfigsApiActions.deleteResidentReportConfigFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      ResidentReportConfigsWsActions.residentReportConfigsCreated,
      ResidentReportConfigsWsActions.residentReportConfigsPatched,
      (state, action) => {
        return residentReportConfigsAdapter.upsertOne(
          action.residentReportConfig,
          state
        );
      }
    ),

    on(
      ResidentReportConfigsWsActions.residentReportConfigsRemoved,
      (state, action) => {
        return residentReportConfigsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      ResidentReportConfigsGeneralActions.addResidentReportConfigs,
      (state, action) => {
        return residentReportConfigsAdapter.upsertMany(
          action.residentReportConfigs,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      ResidentReportConfigsGeneralActions.clearResidentReportConfigs,
      (state) => {
        return residentReportConfigsAdapter.removeAll({
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
