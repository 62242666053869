import { LogLevel, Logger, LoggerPayload } from '$shared/logger';
import { MaybePromise } from '$shared/types';
import { isPromise } from '$shared/utils/predicates/is-promise';
import { AuthenticationRequest } from '@feathersjs/authentication';
import { AuthenticationClient } from '@feathersjs/authentication-client';
import { FeathersError } from '@feathersjs/errors';
import { Params } from '@feathersjs/feathers';

export class AuthenticationClientWithLogging extends AuthenticationClient {
  override handleSocket(socket: any) {
    return this.log('handleSocket', { socket }, () =>
      super.handleSocket(socket)
    );
  }

  override reset() {
    return this.log('reset', {}, () => super.reset());
  }

  override handleError(error: FeathersError, type: 'authenticate' | 'logout') {
    return this.log('handleError', { error, type }, () =>
      super.handleError(error, type)
    );
  }

  override reAuthenticate(force?: boolean, strategy?: string) {
    return this.log(
      'reAuthenticate',
      { force, strategy },
      () => super.reAuthenticate(force, strategy),
      'warn'
    );
  }

  override authenticate(
    authentication?: AuthenticationRequest,
    params?: Params
  ) {
    return this.log('authenticate', { authentication, params }, () =>
      super.authenticate(authentication, params)
    );
  }

  override logout() {
    return this.log('logout', {}, () => super.logout(), 'warn');
  }

  private log(
    method: string,
    args: LoggerPayload,
    cb: () => MaybePromise<any>,
    logLevel: LogLevel = 'info'
  ) {
    let result: any;

    Logger[logLevel](`--> [AuthenticationClientWithLogging] ${method}`, {
      args
    });

    const logError = (error: any) => {
      Logger.error(`XXX: [AuthenticationClientWithLogging] ${method} failed`, {
        args,
        error
      });

      throw error;
    };

    const logResult = (result: any) => {
      Logger[logLevel](`<-- [AuthenticationClientWithLogging] ${method}`, {
        args,
        result
      });

      return result;
    };

    try {
      result = cb();

      if (isPromise(result)) {
        result = result.then(logResult).catch(logError);
      }

      return result;
    } catch (error) {
      logError(error);
    } finally {
      if (!isPromise(result)) {
        logResult(result);
      }
    }
  }
}
