import { ILibraryDocumentTemplatePacket } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LibraryDocumentTemplatePacketsGeneralActions = createActionGroup({
  source: 'Library Document Template Packets General',
  events: {
    'Clear Library Document Template Packets': emptyProps(),
    'Add Library Document Template Packets': props<{
      libraryDocumentTemplatePackets: ILibraryDocumentTemplatePacket[];
    }>()
  }
});
