import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MedicationMarsEffects } from './medication-mars.effects';
import { medicationMarsReducer } from './medication-mars.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('medicationMars', medicationMarsReducer),
    EffectsModule.forFeature([MedicationMarsEffects])
  ],
  providers: [MedicationMarsEffects]
})
export class MedicationMarsStoreModule {}
