import { FeathersService } from '$/app/services/feathers.service';
import { MoodLogsApiActions } from '$/app/store/mood-logs/actions';
import { ApiData } from '$/app/utils';
import { Paginated, Params } from '$/models';
import { IMoodLog } from '$shared/services/mood-log';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MoodLogsApiService {
  private moodLogsService: Service<IMoodLog>;

  constructor(store: Store, feathers: FeathersService) {
    this.moodLogsService = feathers.client.service('mood-logs');

    this.moodLogsService.on('created', (moodLog) => {
      const responseData = new ApiData('moodLogs', moodLog);
      responseData.setPrimaryAction(MoodLogsApiActions.moodLogsCreated, {
        payloadKey: 'moodLog'
      });
      responseData.getActions().forEach((action) => {
        store.dispatch(action);
      });
    });

    this.moodLogsService.on('patched', (moodLog) => {
      store.dispatch(
        MoodLogsApiActions.moodLogsPatched({
          moodLog
        })
      );
    });

    this.moodLogsService.on('removed', (moodLog) => {
      store.dispatch(
        MoodLogsApiActions.moodLogsRemoved({
          id: moodLog.id
        })
      );
    });
  }

  getAll(params: Params) {
    const result = this.moodLogsService.find(params) as Promise<
      Paginated<IMoodLog>
    >;

    return from(result);
  }

  create(moodLog: IMoodLog): Observable<IMoodLog> {
    return from(this.moodLogsService.create(moodLog));
  }

  patch(
    id: string,
    moodLog: Partial<IMoodLog>,
    params: Params = { query: {} }
  ): Observable<IMoodLog> {
    const promise = this.moodLogsService.patch(id, moodLog, params);

    return from(promise);
  }

  delete(id: string, params: Params = { query: {} }): Observable<IMoodLog> {
    return from(this.moodLogsService.remove(id, params));
  }
}
