import { Acknowledgement } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const acknowledgementsAdapter = createEntityAdapter<Acknowledgement>();

export interface State extends EntityState<Acknowledgement> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = acknowledgementsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
