// @index((!^.*$)): 🔥🔥🔥🔥🔥 WE ARE RETIRING BARREL FILES - REMOVE THIS FILE AND FIX ALL RELATED IMPORTS 🔥🔥🔥🔥🔥
export * from './address.pipe';
export * from './alcomy-pipes.module';
export * from './convert24to12hours.pipe';
export * from './document-name.pipe';
export * from './dose-placeholder.pipe';
export * from './extract-file-name-from-path.pipe';
export * from './facility-date-time.pipe';
export * from './feet-inches.pipe';
export * from './file-id-to-url.pipe';
export * from './file-size.pipe';
export * from './formatter.pipe';
export * from './lookup.pipe';
export * from './lookupTable.pipe';
export * from './medication-measurement.pipe';
export * from './medication-name.pipe';
export * from './null-symbol.pipe';
export * from './operator.pipe';
export * from './ordinal.pipe';
export * from './parenthesize.pipe';
export * from './person-name.pipe';
export * from './pluralize-med-form.pipe';
export * from './pluralize.pipe';
export * from './relative-time.pipe';
export * from './room.pipe.pipe';
export * from './utc-date.pipe';
export * from './yesNo.pipe';
