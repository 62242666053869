import { FeathersService } from '$/app/services/feathers.service';
import { LibraryCollectionsWsActions } from '$/app/store/library-collections/library-collections.actions';
import { ILibraryCollection } from '$shared/services/library-collection';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class LibraryCollectionsApiService extends AbstractApiService<ILibraryCollection> {
  constructor(feathers: FeathersService, store: Store) {
    super('library-collections', feathers, store, {
      entityName: 'libraryCollection',
      created: LibraryCollectionsWsActions.libraryCollectionCreated,
      patched: LibraryCollectionsWsActions.libraryCollectionPatched,
      removed: LibraryCollectionsWsActions.libraryCollectionRemoved
    });
  }
}
