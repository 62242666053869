import { IDestructionMethod } from '$shared/services/destruction-method.schema';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DestructionMethodsApiActions = createActionGroup({
  source: 'Destruction Methods API',
  events: {
    'Create Destruction Method Success': props<{
      destructionMethod: IDestructionMethod;
    }>(),
    'Update Destruction Method Success': props<{
      destructionMethod: IDestructionMethod;
    }>(),
    'Delete Destruction Method Success': props<{ id: string }>(),

    'Create Destruction Method Fail': props<{ error: Error }>(),
    'Update Destruction Method Fail': props<{ error: Error }>(),
    'Delete Destruction Method Fail': props<{ error: Error }>()
  }
});

export const DestructionMethodsWsActions = createActionGroup({
  source: 'Destruction Methods WS',
  events: {
    'Destruction Methods Created': props<{
      destructionMethod: IDestructionMethod;
    }>(),
    'Destruction Methods Patched': props<{
      destructionMethod: IDestructionMethod;
    }>(),
    'Destruction Methods Removed': props<{ id: string }>()
  }
});

export const DestructionMethodsGeneralActions = createActionGroup({
  source: 'Destruction Methods General',
  events: {
    'Clear Destruction Methods': emptyProps(),
    'Add Destruction Methods': props<{
      destructionMethods: IDestructionMethod[];
    }>()
  }
});
