import { MedicationListFilters } from '$/app/pages/medications/residents/medication-list/medication-list.vmodel';
import { createActionGroup } from '@ngrx/store';

export const MedicationListFilterComponentActions = createActionGroup({
  source: 'Medication List Filter Component',
  events: {
    'Filter Changed': (
      filters: MedicationListFilters
    ): { filters: MedicationListFilters; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          statuses: filters.statuses
        }
      };
    }
  }
});
