import { medicalEventsAdapter } from '$/app/store/medical-events/medical-events.state';

import { medicalEventsFeature } from './medical-events.reducer';

export const {
  selectMedicalEventsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = medicalEventsFeature;

export const { selectAll, selectTotal } = medicalEventsAdapter.getSelectors(
  selectMedicalEventsState
);
