import { IDocument, IPaginatedResponse } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DocumentsApiActions = createActionGroup({
  source: 'Documents API',
  events: {
    // Success Actions
    'Load Documents Success': props<{
      documents: IPaginatedResponse<IDocument>;
    }>(),
    'Load Documents Portal Overview Success': props<{
      documents: IPaginatedResponse<IDocument>;
    }>(),
    'Get Documents Success': props<{
      documents: IPaginatedResponse<IDocument>;
    }>(),
    'Fetch Document Success': props<{ document: IDocument }>(),
    'Create Document Success': props<{ document: IDocument }>(),
    'Update Document Success': props<{ document: IDocument }>(),
    'Delete Document Success': props<{ id: string }>(),
    // Fail Actions
    'Load Documents Fail': props<{ error: Error }>(),
    'Get Documents Fail': props<{ error: Error }>(),
    'Fetch Document Fail': props<{ error: Error }>(),
    'Create Document Fail': props<{ error: Error }>(),
    'Update Document Fail': props<{ error: Error }>(),
    'Delete Document Fail': props<{ error: Error }>()
  }
});

export const DocumentsWsActions = createActionGroup({
  source: 'Documents WS',
  events: {
    'Document Created': props<{ document: IDocument }>(),
    'Document Patched': props<{ document: IDocument }>(),
    'Document Removed': props<{ id: string }>()
  }
});

export const DocumentsGeneralActions = createActionGroup({
  source: 'Documents General',
  events: {
    'Clear Documents': emptyProps(),
    'Add Documents': props<{ documents: IDocument[] }>()
  }
});
