import { Params, Update } from '$/models';
import { IAppointment } from '$shared/appointments/appointments.interface';
import { createActionGroup, props } from '@ngrx/store';

export const AppointmentModalActions = createActionGroup({
  source: 'Appointment Modal',
  events: {
    'Create Appointment': props<{
      appointment: IAppointment;
      params?: Params;
    }>(),

    'Update Appointment': props<Update<IAppointment>>()
  }
});
