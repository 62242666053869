import { MedicalProfessionalsManagerActions } from '$/app/pages/residents/resident-detail/medical-professionals-manager/medical-professionals-manager.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  ResidentMedicalProfessionalDetailActions,
  ResidentMedicalProfessionalsApiActions,
  ResidentMedicalProfessionalsGeneralActions,
  ResidentMedicalProfessionalsListActions,
  ResidentMedicalProfessionalsWsActions
} from './actions';
import {
  State,
  initialState,
  residentMedicalProfessionalsAdapter
} from './resident-medical-professionals.state';

const reducer = createReducer(
  initialState,

  on(
    ResidentMedicalProfessionalsListActions.loadResidentMedicalProfessionals,
    ResidentMedicalProfessionalsListActions.getResidentMedicalProfessionals,
    MedicalProfessionalsManagerActions.addResidentMedicalProfessional,
    ResidentMedicalProfessionalDetailActions.fetchResidentMedicalProfessional,
    ResidentMedicalProfessionalDetailActions.updateResidentMedicalProfessional,
    ResidentMedicalProfessionalDetailActions.deleteResidentMedicalProfessional,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    ResidentMedicalProfessionalsApiActions.loadResidentMedicalProfessionalsSuccess,
    (state, action) => {
      return residentMedicalProfessionalsAdapter.setAll(
        action.residentMedicalProfessionals.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    ResidentMedicalProfessionalsApiActions.getResidentMedicalProfessionalsSuccess,
    (state, action) => {
      return residentMedicalProfessionalsAdapter.upsertMany(
        action.residentMedicalProfessionals.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    ResidentMedicalProfessionalsApiActions.fetchResidentMedicalProfessionalSuccess,
    (state, action) => {
      return residentMedicalProfessionalsAdapter.upsertOne(
        action.residentMedicalProfessional,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    ResidentMedicalProfessionalsApiActions.createResidentMedicalProfessionalSuccess,
    (state, action) => {
      const residentMedicalProfessionals = castArray(
        action.residentMedicalProfessional
      );

      return residentMedicalProfessionalsAdapter.upsertMany(
        residentMedicalProfessionals,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    ResidentMedicalProfessionalsApiActions.updateResidentMedicalProfessionalSuccess,
    (state, action) => {
      return residentMedicalProfessionalsAdapter.updateOne(
        action.residentMedicalProfessional,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    ResidentMedicalProfessionalsApiActions.deleteResidentMedicalProfessionalSuccess,
    (state, action) => {
      return residentMedicalProfessionalsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    ResidentMedicalProfessionalsApiActions.loadResidentMedicalProfessionalsFail,
    ResidentMedicalProfessionalsApiActions.getResidentMedicalProfessionalsFail,
    ResidentMedicalProfessionalsApiActions.fetchResidentMedicalProfessionalFail,
    ResidentMedicalProfessionalsApiActions.createResidentMedicalProfessionalFail,
    ResidentMedicalProfessionalsApiActions.updateResidentMedicalProfessionalFail,
    ResidentMedicalProfessionalsApiActions.deleteResidentMedicalProfessionalFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    ResidentMedicalProfessionalsWsActions.residentMedicalProfessionalsCreated,
    ResidentMedicalProfessionalsWsActions.residentMedicalProfessionalsPatched,
    (state, action) => {
      return residentMedicalProfessionalsAdapter.upsertOne(
        action.residentMedicalProfessional,
        {
          ...state
        }
      );
    }
  ),

  on(
    ResidentMedicalProfessionalsWsActions.residentMedicalProfessionalsRemoved,
    (state, action) => {
      return residentMedicalProfessionalsAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(
    ResidentMedicalProfessionalsGeneralActions.addResidentMedicalProfessionals,
    (state, action) => {
      return residentMedicalProfessionalsAdapter.upsertMany(
        action.residentMedicalProfessionals,
        {
          ...state,
          loading: false,
          error: null
        }
      );
    }
  ),

  on(
    ResidentMedicalProfessionalsGeneralActions.clearResidentMedicalProfessionals,
    (state) => {
      return residentMedicalProfessionalsAdapter.removeAll({
        ...state,

        loading: false,
        loaded: false,
        error: null
      });
    }
  )
);

export function residentMedicalProfessionalsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
