import { AmountSegment } from '../types';

export function sumAmountSegments(segments: AmountSegment[]): number {
  return segments.reduce((total, segment) => {
    if (typeof segment === 'string') {
      return total;
    }

    total += Number.parseFloat(segment.amount);

    return total;
  }, 0);
}
