import { Action, createReducer, on } from '@ngrx/store';
import {
  MedicationMarsApiActions,
  MedicationMarsGeneralActions,
  MedicationMarsWsActions
} from './medication-mars.actions';
import {
  State,
  initialState,
  medicationMarsAdapter
} from './medication-mars.state';

const reducer = createReducer(
  initialState,

  on(MedicationMarsApiActions.patchMedicationMarSuccess, (state, action) => {
    return medicationMarsAdapter.upsertOne(action.medicationMar, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(MedicationMarsApiActions.patchMedicationMarFail, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  }),

  on(
    MedicationMarsWsActions.medicationMarsCreated,
    MedicationMarsWsActions.medicationMarsPatched,
    (state, action) => {
      return medicationMarsAdapter.upsertOne(action.medicationMar, {
        ...state
      });
    }
  ),

  on(
    MedicationMarsWsActions.medicationMarsBatchCreated,
    MedicationMarsWsActions.medicationMarsBatchPatched,
    (state, action) => {
      return medicationMarsAdapter.upsertMany(action.medicationMars, {
        ...state
      });
    }
  ),

  on(MedicationMarsWsActions.medicationMarsRemoved, (state, action) => {
    return medicationMarsAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(MedicationMarsWsActions.medicationMarsBatchRemoved, (state, action) => {
    return medicationMarsAdapter.removeMany(action.ids, {
      ...state
    });
  }),

  on(MedicationMarsGeneralActions.addMedicationMars, (state, action) => {
    return medicationMarsAdapter.upsertMany(action.medicationMars, {
      ...state,
      loading: false,
      error: null
    });
  })
);

export function medicationMarsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
