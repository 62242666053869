import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'alc-contact-support',
  template: `
    <span class="text-dark">
      {{ message }} <b>Alcomy Support</b>

      by voice or text at
      <a class="whitespace-nowrap" href="tel:385-331-1990">385-331-1990</a>

      or by email at
      <a class="whitespace-nowrap" href="mailto:support@alcomy.com">
        support&#64;alcomy.com
      </a>
    </span>
  `,
  imports: [CommonModule]
})
export class AlcContactSupportComponent {
  @Input() message = 'If you have questions or concerns, please contact';
}
