import { isNil } from 'lodash';

interface EntityWithName {
  name?: string;
  firstName?: string;
  lastName?: string;
}

export function sortByName(a: EntityWithName, b: EntityWithName) {
  if (isNil(a.name) && isNil(a.firstName) && isNil(a.lastName)) {
    throw new Error(`Entity must have "name" or "firstName" and "lastName"`);
  }

  let aName: string;
  let bName: string;

  if (a.name) {
    aName = a.name;
    bName = b.name;
  } else {
    aName = (a.firstName ? a.firstName : '') + (a.lastName ? a.lastName : '');
    bName = (b.firstName ? b.firstName : '') + (b.lastName ? b.lastName : '');
  }

  return aName.localeCompare(bName, 'en', { sensitivity: 'base' });
}
