import { IIncidentReport, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const IncidentReportModalActions = createActionGroup({
  source: 'Incident Report Modal',
  events: {
    'Create Incident Report': props<{
      incidentReport: IIncidentReport;
      params: Params;
    }>(),

    'Update Incident Report': props<Update<IIncidentReport>>()
  }
});
