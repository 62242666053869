import { isPlatformBrowser } from '@angular/common';
import {
  booleanAttribute,
  Directive,
  effect,
  ElementRef,
  inject,
  input,
  PLATFORM_ID
} from '@angular/core';

@Directive({ selector: '[alcScrollIntoView]', standalone: true })
export class AlcScrollIntoViewDirective {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly elementRef = inject(ElementRef);

  alcScrollIntoView = input(false, { transform: booleanAttribute });

  constructor() {
    effect(() => {
      if (isPlatformBrowser(this.platformId)) {
        if (this.alcScrollIntoView()) {
          // Do the scroll inside a zero-delay timeout, to handle cases
          // where the DOM needs to catch up before we scroll
          setTimeout(() => {
            const element = this.elementRef.nativeElement as HTMLElement;
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }, 0);
        }
      }
    });
  }
}
