import { FeathersService } from '$/app/services/feathers.service';
import { LoginData } from '$shared/services/user';
import { ValidateSignupTokenResult } from '$shared/types';
import {
  ServiceActionName,
  ServiceActions
} from '$shared/types/service-actions';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';

@Injectable({ providedIn: 'root' })
export class AuthManagementApiService {
  private readonly authManagementService: Service<any>;

  constructor(feathers: FeathersService) {
    this.authManagementService = feathers.client.service('auth-management');
  }

  public readonly sendResetPwd = this.createAction('sendResetPwd');

  public readonly resetPwdLong = this.createAction('resetPwdLong');

  public readonly passwordChange = this.createAction('passwordChange');

  public readonly validateResetToken = this.createAction(
    'validateResetToken',
    (result) => result as { resetExpires: string }
  );

  public readonly requestNewInvitation = this.createAction(
    'requestNewInvitation',
    (result) => result as { status: 'success' }
  );

  public readonly validateSignupToken = this.createAction(
    'validateSignupToken',
    (result) => result as ValidateSignupTokenResult
  );

  public readonly completeSignup = this.createAction(
    'completeSignup',
    (result) => result as LoginData
  );

  private createAction<TActionName extends ServiceActionName, TResult = any>(
    action: TActionName,
    projection: (result: any) => TResult = (result) => result
  ) {
    return async (options: ServiceActions[TActionName]): Promise<TResult> => {
      const result = await this.authManagementService.create(
        {},
        { query: { $actions: [{ [action]: options }] } }
      );

      return projection(result);
    };
  }
}
