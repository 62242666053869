import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IUrineLog } from '$shared/services/urine-log';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { urineLogsFeature } from './urine-logs.reducer';
import { urineLogsAdapter } from './urine-logs.state';

const { selectUrineLogsState, selectLoading } = urineLogsFeature;

const { selectAll } = urineLogsAdapter.getSelectors(selectUrineLogsState);

const selectResidentUrineLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (logs, residentId): IUrineLog[] => {
    if (isEmpty(logs) || !residentId) {
      return [];
    }

    return logs.filter((bpLog) => {
      return bpLog.residentId === residentId;
    });
  }
);

const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

const selectLogsByQuery = (query: Query<IUrineLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IUrineLog>(query));
    return filteredLogs;
  });
};

const selectLogsAndMetrics = (query: Query<IUrineLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => ({
    metrics: [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length ?? 0
      }
    ],
    logs
  }));
};

export const UrineLogsSelectors = {
  selectLoading,
  selectLogsByCareTask,
  selectLogsByMedicationTask,
  selectResidentUrineLogs,
  selectLogsAndMetrics,
  selectLogsByPrn
};
