import { DashboardCarePageActions } from '$/app/pages/care/dashboard/dashboard-care.actions';
import { MissedCareTasksPageActions } from '$/app/pages/care/dashboard/missed-care-tasks/missed-care-tasks.actions';
import { CarListPageActions } from '$/app/pages/care/residents/car-list/car-list.actions';
import { CareTaskDetailPageActions } from '$/app/pages/care/residents/care-task-detail/care-task-detail.actions';
import { CareTaskInfoPageActions } from '$/app/pages/care/shared/components/care-task-info/care-task-into.actions';
import { OmitCareTaskModalActions } from '$/app/pages/care/shared/components/omit-care-task-modal/omit-care-task.modal.actions';
import { DashboardOverviewPageActions } from '$/app/pages/dashboard/dashboard-overview/dashboard-overview.actions';
import { CareTasksApiService, EffectHelpersService } from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { CareTask, Paginated } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  concatMap,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { CareTasksApiActions } from './care-tasks.actions';

@Injectable()
export class CareTasksEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly careTasksService = inject(CareTasksApiService);
  private readonly effectHelper = inject(EffectHelpersService);

  loadCareTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CarListPageActions.loadCareTasks,
        MissedCareTasksPageActions.loadCareTasks
      ),
      switchMap((action) => {
        return this.careTasksService.getAll(action.params).pipe(
          getAllPages(this.careTasksService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((careTasks: Paginated<CareTask>) => {
            const responseData = new ApiData(
              'careTasks',
              careTasks,
              CareTasksApiActions.loadCareTasksSuccess
            );

            return responseData.getActions();
          }),
          catchError((error) => {
            return of(CareTasksApiActions.loadCareTasksFail({ error }));
          })
        );
      })
    );
  });

  getCareTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardOverviewPageActions.getCareTasks,
        DashboardCarePageActions.getCareTasks
      ),
      switchMap((action) => {
        return this.careTasksService.getAll(action.params).pipe(
          getAllPages(this.careTasksService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((careTasks: Paginated<CareTask>) => {
            const responseData = new ApiData(
              'careTasks',
              careTasks,
              CareTasksApiActions.getCareTasksSuccess
            );

            return responseData.getActions();
          }),
          catchError((error) => {
            return of(CareTasksApiActions.getCareTasksFail({ error }));
          })
        );
      })
    );
  });

  fetchCareTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CareTaskDetailPageActions.fetchCareTask,
        CareTaskInfoPageActions.fetchCareTask
      ),
      switchMap((action) => {
        return this.careTasksService.get(action.id, action.params).pipe(
          mergeMap((careTask: CareTask) => {
            const apiData = new ApiData(
              'careTasks',
              careTask,
              CareTasksApiActions.fetchCareTaskSuccess,
              {
                payloadKey: 'careTask'
              }
            );

            return apiData.getActions();
          }),
          catchError((error) => {
            return of(CareTasksApiActions.fetchCareTaskFail({ error }));
          })
        );
      })
    );
  });

  updateCareTask$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          DashboardCarePageActions.careTaskCompleted,
          CareTaskDetailPageActions.careTaskCompleted,
          CarListPageActions.careTaskCompleted,
          CareTaskInfoPageActions.careTaskCompleted,
          MissedCareTasksPageActions.careTaskCompleted,
          DashboardCarePageActions.careTaskStateReset,
          CareTaskInfoPageActions.careTaskStateReset,
          CarListPageActions.careTaskStateReset,
          CareTaskDetailPageActions.careTaskStateReset,
          MissedCareTasksPageActions.careTaskStateReset,
          OmitCareTaskModalActions.careTaskOmitted
        ),
        concatMap((action) => {
          return this.careTasksService
            .patch(action.id, action.changes, action.params)
            .pipe(
              tap(() => {
                this.effectHelper.dismissModal({
                  message:
                    typeof action?.params?.toast === 'string'
                      ? action?.params?.toast
                      : action?.params?.toast?.message
                });
              }),
              catchError((error) => {
                this.store.dispatch(
                  CareTasksApiActions.updateCareTaskFail({ error })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateCareTaskFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CareTasksApiActions.updateCareTaskFail),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
