import { sortByFields } from '$/app/utils';
import { CarePlanItemSchedule } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const carePlanItemSchedulesAdapter =
  createEntityAdapter<CarePlanItemSchedule>({
    sortComparer: sortByFields('createdAt')
  });

export interface State extends EntityState<CarePlanItemSchedule> {
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = carePlanItemSchedulesAdapter.getInitialState(
  {
    total: 0,
    skip: 0,
    loading: false,
    loaded: false,
    error: null
  }
);
