import { Update } from '$/models';
import { IBloodSugarLog } from '$shared/services/blood-sugar-log';
import { createActionGroup, props } from '@ngrx/store';

export const BloodSugarLogFormPageActions = createActionGroup({
  source: 'Blood Sugar Log Form Page',
  events: {
    'Create Blood Sugar Log': props<{ bloodSugarLog: IBloodSugarLog }>(),
    'Update Blood Sugar Log': props<Update<IBloodSugarLog>>()
  }
});
