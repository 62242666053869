import * as PharmacyPhoneFormActions from '$/app/shared/pages/forms/pharmacy-phone-form/pharmacy-phone-form.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  PharmacyPhonesApiActions,
  PharmacyPhonesGeneralActions
} from './actions';
import {
  State,
  initialState,
  pharmacyPhonesAdapter
} from './pharmacy-phones.state';

const reducer = createReducer(
  initialState,

  on(
    PharmacyPhoneFormActions.createPharmacyPhone,
    PharmacyPhoneFormActions.updatePharmacyPhone,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(PharmacyPhonesApiActions.createPharmacyPhoneSuccess, (state, action) => {
    const pharmacyPhones = castArray(action.pharmacyPhone);

    return pharmacyPhonesAdapter.addMany(pharmacyPhones, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PharmacyPhonesApiActions.updatePharmacyPhoneSuccess, (state, action) => {
    return pharmacyPhonesAdapter.updateOne(action.pharmacyPhone, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PharmacyPhonesApiActions.deletePharmacyPhoneSuccess, (state, action) => {
    return pharmacyPhonesAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    PharmacyPhonesApiActions.createPharmacyPhoneFail,
    PharmacyPhonesApiActions.updatePharmacyPhoneFail,
    PharmacyPhonesApiActions.deletePharmacyPhoneFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    PharmacyPhonesApiActions.pharmacyPhonesCreated,
    PharmacyPhonesApiActions.pharmacyPhonesPatched,
    (state, action) => {
      return pharmacyPhonesAdapter.upsertOne(action.pharmacyPhone, {
        ...state
      });
    }
  ),

  on(PharmacyPhonesApiActions.pharmacyPhonesRemoved, (state, action) => {
    return pharmacyPhonesAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(PharmacyPhonesGeneralActions.addPharmacyPhones, (state, action) => {
    return pharmacyPhonesAdapter.upsertMany(action.pharmacyPhones, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(PharmacyPhonesGeneralActions.clearPharmacyPhones, (state) => {
    return pharmacyPhonesAdapter.removeAll({
      ...state,

      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function pharmacyPhonesReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
