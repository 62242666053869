export function getDocumentName<
  T extends { code?: string | null; name?: string | null }
>(document: T): string {
  if (!document) {
    return '';
  }

  return [
    document?.code ? `${document.code}:` : null,
    document?.name ?? 'Untitled'
  ]
    .filter(Boolean)
    .join(' ');
}
