import { FilesApiService } from '$/app/services';
import { Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({ name: 'fileIdToUrl', standalone: true })
export class AlcFileIdToUrlPipe implements PipeTransform {
  private readonly fileService = inject(FilesApiService);

  async transform(fileId: string) {
    if (!fileId) {
      return null;
    }

    return await this.fileService.getUrl(fileId);
  }
}
