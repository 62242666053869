import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  booleanAttribute,
  inject
} from '@angular/core';

@Directive({
  selector: '[alcHighlight]',
  standalone: true
})
export class AlcHighlightDirective implements OnChanges, AfterViewInit {
  private readonly el = inject(ElementRef);

  @Input('alcHighlight') textToHighlight: string;

  @Input({
    transform: (value: string | boolean) =>
      coerceBooleanProperty(value) ?? value !== undefined
  })
  caseSensitive = false;

  @Input() highlightClasses = '';

  /**
   * If true, treats every space separated word as a separate search term;
   * otherwise, treats the entire string as a single search term.
   */
  @Input({
    transform: booleanAttribute
  })
  multi = false;

  text: string = '';

  ngOnChanges({ textToHighlight }: SimpleChanges) {
    if (textToHighlight) {
      this.highlightText();
    }
  }

  ngAfterViewInit() {
    this.highlightText();
  }

  private highlightText() {
    const element = this.el.nativeElement as HTMLElement;
    this.text ||= element.textContent;

    if (this.text) {
      let searchTerms = this.multi
        ? this.textToHighlight?.split(' ')
        : [this.textToHighlight];

      searchTerms = searchTerms.map((term, index) => {
        if (index === 0) {
          return `(?:\\b\\s*${term})`;
        } else {
          return `(?:\\b${term})`;
        }
      });

      const highlightedText = this.text.replace(
        new RegExp(`${searchTerms.join('|')}`, this.caseSensitive ? 'g' : 'gi'),
        `<mark class="highlight ${this.highlightClasses}">$&</mark>`
      );

      this.el.nativeElement.innerHTML = highlightedText;
    }
  }
}
