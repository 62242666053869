import { AppInfo } from '$/app/services';
import { Directive, HostListener, Input, inject } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

@Directive({ selector: '[alcPhone]', standalone: true })
export class AlcPhoneDirective {
  private readonly callNumber = inject(CallNumber);

  @Input() alcPhone: string;

  @HostListener('click') async onClick() {
    if (AppInfo.deviceInfo.platform === 'web') {
      document.location.href = `tel:${this.alcPhone}`;
    } else {
      await this.callNumber.callNumber(this.alcPhone, false);
    }
  }
}
