import { FeathersService } from '$/app/services/feathers.service';
import { DocumentPacketsWsActions } from '$/app/store/document-packets/document-packets.actions';
import { IDocumentPacket } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class DocumentPacketsApiService extends AbstractApiService<IDocumentPacket> {
  constructor(feathers: FeathersService, store: Store) {
    super('document-packets', feathers, store, {
      entityName: 'documentPacket',
      created: DocumentPacketsWsActions.documentPacketCreated,
      patched: DocumentPacketsWsActions.documentPacketPatched,
      removed: DocumentPacketsWsActions.documentPacketRemoved
    });
  }
}
