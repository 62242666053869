import { sortByFields } from '$/app/utils';
import { IDocumentPacket, IPaginationInfo } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const documentPacketsAdapter = createEntityAdapter<IDocumentPacket>({
  sortComparer: sortByFields('name')
});

export interface State extends EntityState<IDocumentPacket> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = documentPacketsAdapter.getInitialState({
  pagination: {},
  loading: false,
  loaded: false,
  error: null
});
