import { AlcBreakpointId, AlcBreakpointIds, AlcBreakpoints } from '$/config';
import { Logger } from '$shared/logger';
import { isType } from '$shared/utils';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  DestroyRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { findLastIndex, values } from 'lodash';

const operators = ['>', '<', '>=', '<='] as const;

type Operator = (typeof operators)[number];
type OperatorBreakpoint = `${Operator}${AlcBreakpointId}`;
type BreakpointQuery = Exclude<OperatorBreakpoint, '<sm' | '>xl'>;

@Directive({ selector: '[alcWhenWindow]', standalone: true })
export class AlcWhenWindowDirective implements OnInit {
  private readonly view = inject(ViewContainerRef);
  private readonly template = inject(TemplateRef<any>);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly destroyRef = inject(DestroyRef);

  @Input() alcWhenWindow: BreakpointQuery;
  @Input() alcWhenWindowElse: TemplateRef<any>;

  private currentTemplate: TemplateRef<any> | undefined;

  ngOnInit() {
    return this.breakpointObserver
      .observe(values(AlcBreakpoints))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        const template = this.isActive(state)
          ? this.template
          : this.alcWhenWindowElse;

        if (template === this.currentTemplate) {
          return;
        }

        this.currentTemplate = template;

        this.view.clear();

        if (template) {
          this.view.createEmbeddedView(template);
        }
      });
  }

  isActive(state: BreakpointState) {
    const [, operator, breakpoint] =
      this.alcWhenWindow.match(/^([><]=?)([a-z]+)$/) ?? [];

    if (
      !isType<AlcBreakpointId>(breakpoint, (b) => AlcBreakpointIds.includes(b))
    ) {
      Logger.throw('Invalid breakpoint', {
        breakpoint,
        alcWhenWindow: this.alcWhenWindow
      });
    }

    if (!isType<Operator>(operator, (o) => operators.includes(o))) {
      Logger.throw('Invalid operator', {
        operator,
        breakpoint,
        alcWhenWindow: this.alcWhenWindow
      });
    }

    const targetIndex = AlcBreakpointIds.indexOf(breakpoint);

    const activeIndex = findLastIndex(AlcBreakpointIds, (b) => {
      const mediaQuery = AlcBreakpoints[b];
      return state.breakpoints[mediaQuery];
    });

    switch (operator) {
      case '>':
        return activeIndex > targetIndex;

      case '<':
        return activeIndex < targetIndex;

      case '>=':
        return activeIndex >= targetIndex;

      case '<=':
        return activeIndex <= targetIndex;

      default:
        Logger.throw('Invalid operator', { operator });
    }
  }
}
