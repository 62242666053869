import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RolesEffects } from './roles.effects';
import { rolesReducer } from './roles.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('roles', rolesReducer),
    EffectsModule.forFeature([RolesEffects])
  ],
  providers: [RolesEffects]
})
export class RolesStoreModule {}
