import { WeightLog, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const WeightLogFormPageActions = createActionGroup({
  source: 'Weight Log Form Page',
  events: {
    'Create Weight Log': props<{
      weightLog: WeightLog;
    }>(),
    'Update Weight Log': props<Update<WeightLog>>()
  }
});
