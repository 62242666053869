import {
  SchemaOptions,
  TLiteral,
  TLiteralValue,
  TUnion,
  Type
} from '@sinclair/typebox';
import { map } from 'lodash';

// NOTE: This is a wholesale replacement for the Type.Enum function.
// TypeBox's Enum function specifies a Typescript-native enum, which
// is not what we want. We want a union of literal values.
export function Enum<T extends readonly TLiteralValue[]>(
  values: T,
  options?: SchemaOptions
): TUnion<TLiteral<T[number]>[]> {
  return Type.Union(
    map(values, (value) => Type.Literal(value)),
    options
  ) as any;
}
