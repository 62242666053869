import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UrineLogsEffects } from './urine-logs.effects';
import { urineLogsFeature } from './urine-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(urineLogsFeature),
    EffectsModule.forFeature([UrineLogsEffects])
  ],
  providers: [UrineLogsEffects]
})
export class UrineLogsStoreModule {}
