import { DashboardOverviewPageActions } from '$/app/pages/dashboard/dashboard-overview/dashboard-overview.actions';
import { AppointmentModalActions } from '$/app/pages/notebook/residents/appointment-modal/appointment.modal.actions';
import { EventsListActions } from '$/app/pages/notebook/residents/events-list/events-list.actions';
import { AppointmentsApiService, EffectHelpersService } from '$/app/services';
import { ApiData } from '$/app/utils/api-data';
import { getAllPages, reducePaginatedResponses } from '$/app/utils/pagination';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { AppointmentsApiActions } from './actions/appointments-api.actions';

@Injectable()
export class AppointmentsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly appointmentsService = inject(AppointmentsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadAppointments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EventsListActions.loadAppointments,
        DashboardOverviewPageActions.getAppointments
      ),
      this.effectHelpers.apiRequest({
        description: 'Load appointments',
        onRequest: (action) =>
          this.appointmentsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.appointmentsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (appointments) => {
          const responseData = new ApiData(
            'appointments',
            appointments,
            AppointmentsApiActions.loadAppointmentsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          AppointmentsApiActions.loadAppointmentsFail({ error })
      })
    );
  });

  createAppointment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppointmentModalActions.createAppointment),
      exhaustMap((action) => {
        return this.appointmentsService.create(action.appointment).pipe(
          map((event) => {
            return AppointmentsApiActions.createAppointmentSuccess({
              appointment: event
            });
          }),
          catchError((error) => {
            return of(
              AppointmentsApiActions.createAppointmentFail({
                error
              })
            );
          })
        );
      })
    );
  });

  updateAppointment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppointmentModalActions.updateAppointment),
      switchMap((action) => {
        return this.appointmentsService.patch(action.id, action.changes).pipe(
          map((appointment) => {
            return AppointmentsApiActions.updateAppointmentSuccess({
              appointment
            });
          }),
          catchError((error) => {
            return of(
              AppointmentsApiActions.updateAppointmentFail({
                error
              })
            );
          })
        );
      })
    );
  });

  deleteAppointment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsListActions.deleteAppointment),
        exhaustMap((action) => {
          return this.appointmentsService.delete(action.id).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Appointment deleted successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                AppointmentsApiActions.deleteAppointmentFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteAppointmentFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppointmentsApiActions.deleteAppointmentFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
