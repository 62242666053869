import { sortByDatetime } from '$/app/utils';
import { IMedicationDestructionItem } from '$shared/services/medication-destruction-items.schema';
import { createEntityAdapter } from '@ngrx/entity';

export const medicationDestructionItemsAdapter =
  createEntityAdapter<IMedicationDestructionItem>({
    sortComparer: sortByDatetime('createdAt', true)
  });

export const initialState = medicationDestructionItemsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
