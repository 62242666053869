import {
  EffectHelpersService,
  MedicationInventoryMovementsApiService
} from '$/app/services';
import { MedicationInventoriesApiActions } from '$/app/store/medication-inventories';
import { MedicationInventoryItemsApiActions } from '$/app/store/medication-inventory-items';
import {
  MedicationInventory,
  MedicationInventoryMovement,
  Paginated
} from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, switchMap, tap } from 'rxjs/operators';
import { stringify } from 'safe-stable-stringify';
import {
  MedicationInventoryMovementDetailActions,
  MedicationInventoryMovementFormActions,
  MedicationInventoryMovementsApiActions,
  MedicationInventoryMovementsListActions
} from './actions';

@Injectable()
export class MedicationInventoryMovementsEffects {
  private readonly actions$ = inject(Actions);
  private readonly medicationInventoryMovementsApiService = inject(
    MedicationInventoryMovementsApiService
  );
  private readonly effectHelper = inject(EffectHelpersService);

  loadMedicationInventoryMovements$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryMovementsListActions.loadMedicationInventoryMovements
        ),
        switchMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  getMedicationInventoryMovements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationInventoryMovementsListActions.getMedicationInventoryMovements
      ),
      switchMap((action) => {
        return this.medicationInventoryMovementsApiService
          .getAll(action.params)
          .pipe(
            switchMap(
              (
                medicationInventoryMovements: Paginated<MedicationInventoryMovement>
              ) => {
                let medicationInventories: MedicationInventory[] = [];
                let medicationInventoryItems: IMedicationInventoryItem[] = [];

                medicationInventoryMovements.data.forEach((mmi) => {
                  const {
                    medicationInventory,
                    medicationInventoryItem,
                    ...rest
                  } = mmi as MedicationInventoryMovement & {
                    medicationInventory: MedicationInventory;
                    medicationInventoryItem: IMedicationInventoryItem;
                  };

                  if (medicationInventory) {
                    medicationInventories.push(medicationInventory);
                  }

                  if (medicationInventoryItem) {
                    medicationInventoryItems.push(medicationInventoryItem);
                  }

                  mmi = rest;
                });

                //remove duplicates
                medicationInventoryItems = medicationInventoryItems.filter(
                  (currentValue, currentIndex, arrayObj) => {
                    return (
                      arrayObj.findIndex(
                        (valueArray) =>
                          stringify(valueArray) === stringify(currentValue)
                      ) === currentIndex
                    );
                  }
                );

                //remove duplicates
                medicationInventories = medicationInventories.filter(
                  (currentValue, currentIndex, arrayObj) => {
                    return (
                      arrayObj.findIndex(
                        (valueArray) =>
                          stringify(valueArray) === stringify(currentValue)
                      ) === currentIndex
                    );
                  }
                );

                return [
                  MedicationInventoryMovementsApiActions.getMedicationInventoryMovementsSuccess(
                    { medicationInventoryMovements }
                  ),
                  MedicationInventoryItemsApiActions.addInventoryItem_DashboardRoute(
                    { medicationInventoryItem: medicationInventoryItems }
                  ),
                  MedicationInventoriesApiActions.addMedicationInventories_dashboardRoute(
                    { medicationInventory: medicationInventories }
                  )
                ];
              }
            ),
            catchError((error) => {
              return of(
                MedicationInventoryMovementsApiActions.getMedicationInventoryMovementsFail(
                  { error }
                )
              );
            })
          );
      })
    );
  });

  fetchMedicationInventoryMovement$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryMovementDetailActions.fetchMedicationInventoryMovement
        ),
        switchMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  createMedicationInventoryMovement$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryMovementFormActions.createMedicationInventoryMovement
        ),
        exhaustMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  createMedicationInventoryMovementFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryMovementsApiActions.createMedicationInventoryMovementFail
        ),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateMedicationInventoryMovement$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryMovementDetailActions.updateMedicationInventoryMovement
        ),
        switchMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  updateMedicationInventoryMovementFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryMovementsApiActions.updateMedicationInventoryMovementFail
        ),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteMedicationInventoryMovement$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryMovementDetailActions.deleteMedicationInventoryMovement
        ),
        exhaustMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  deleteMedicationInventoryMovementFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryMovementsApiActions.deleteMedicationInventoryMovementFail
        ),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
