import { DoseTypeId } from '../lookups/dose-types.lookup';
import { NormalDoseValue } from './doses';

export const LogDependencyRuleActionTypes = ['give', 'hold'] as const;

export type LogDependencyRuleActionType =
  (typeof LogDependencyRuleActionTypes)[number];

export class LogDependencyRuleAction {
  type: LogDependencyRuleActionType;
  doseTypeId?: DoseTypeId;
  dose?: NormalDoseValue;

  constructor(action?: LogDependencyRuleAction) {
    this.type = action?.type ?? 'give';
    if (action?.type === 'give') {
      this.doseTypeId = action?.doseTypeId ?? 'value';
      this.dose = action?.dose;
    }
  }
}
