import { Diagnosis, Update } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DiagnosesApiActions = createActionGroup({
  source: 'Diagnoses API',
  events: {
    'Load Diagnoses Success': props<{ diagnoses: Diagnosis[] }>(),
    'Create Diagnosis Success': props<{ diagnosis: Diagnosis }>(),
    'Update Diagnosis Success': props<{ diagnosis: Update<Diagnosis> }>(),
    'Delete Diagnosis Success': props<{ id: string }>(),

    'Load Diagnoses Fail': props<{ error: Error }>(),
    'Create Diagnosis Fail': props<{ error: Error }>(),
    'Update Diagnosis Fail': props<{ error: Error }>(),
    'Delete Diagnosis Fail': props<{ error: Error }>()
  }
});

export const DiagnosesWsActions = createActionGroup({
  source: 'Diagnoses WS',
  events: {
    'Diagnoses Created': props<{ diagnosis: Diagnosis }>(),
    'Diagnoses Patched': props<{ diagnosis: Diagnosis }>(),
    'Diagnoses Removed': props<{ id: string }>()
  }
});

export const DiagnosesGeneralActions = createActionGroup({
  source: 'Diagnoses General',
  events: {
    'Clear Diagnoses': emptyProps(),
    'Add Diagnoses': props<{
      diagnoses: Diagnosis[];
    }>()
  }
});
