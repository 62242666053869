import * as RouterSelectors from '$/app/store/router/router.selectors';
import { Acknowledgement } from '$/models';
import { Dictionary } from '$shared/types';
import { createSelector } from '@ngrx/store';

import { acknowledgementsFeature } from './acknowledgements.reducer';
import { acknowledgementsAdapter } from './acknowledgements.state';

export const {
  selectAcknowledgementsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = acknowledgementsFeature;

export const { selectAll, selectTotal } = acknowledgementsAdapter.getSelectors(
  selectAcknowledgementsState
);

export const selectAcknowledgementsViaCompoundKey = createSelector(
  selectAll,
  (acknowledgements): Dictionary<Acknowledgement> => {
    const entities = acknowledgements.reduce((acc, curr) => {
      const compoundKey = curr.announcementId + curr.facilityUserId;

      acc[compoundKey] = curr;
      return acc;
    }, {});

    return entities;
  }
);

export const selectAnnouncementAcknowledgements = createSelector(
  selectAll,
  RouterSelectors.selectParam('announcementId'),
  (acknowledgements, announcementId): Acknowledgement[] => {
    if (!announcementId) {
      throw new Error('announcementId was not found');
    }

    return acknowledgements.filter((acknowledgement) => {
      return (acknowledgement.announcementId = announcementId);
    });
  }
);

export const selectAnnouncementAcknowledgementsWithId = (
  announcementId: string
) => {
  return createSelector(selectAll, (acknowledgements): Acknowledgement[] => {
    if (!announcementId) {
      throw new Error('announcementId was not found');
    }

    return acknowledgements.filter((acknowledgement) => {
      return (acknowledgement.announcementId = announcementId);
    });
  });
};
