import { isEmpty, isEqual, isFunction, pickBy } from 'lodash';

/**
 * This only works with simple flat objects at the moment.
 */
export function getChangedValues(
  newValue,
  originalValue,
  cb?: (newValue: any, oldValue: any, key: string) => boolean
): any {
  if (cb && !isFunction(cb)) {
    throw new Error('callback must be a function');
  }

  let changedValues;

  if (cb) {
    changedValues = Object.entries(newValue).reduce((changed, [key, value]) => {
      if (
        originalValue[key] === undefined ||
        cb(value, originalValue[key], key)
      ) {
        changed[key] = value;
      }

      return changed;
    }, {});
  } else {
    changedValues = pickBy(newValue, (value, key) => {
      return !isEqual(value, originalValue[key]);
    });
  }

  return !isEmpty(changedValues) ? changedValues : undefined;
}
