import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FacilityUserGroupsEffects } from './facility-user-groups.effects';
import { facilityUserGroupsFeature } from './facility-user-groups.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(facilityUserGroupsFeature),
    EffectsModule.forFeature([FacilityUserGroupsEffects])
  ],
  providers: [FacilityUserGroupsEffects]
})
export class FacilityUserGroupsStoreModule {}
