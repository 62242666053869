import { Paginated, WaterLog } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Water Logs API]';
const sourceWS = '[Water Logs WS]';

// Load
export const loadWaterLogsSuccess = createAction(
  `${source} Load Water Logs Success`,
  props<{
    waterLogs: Paginated<WaterLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const loadWaterLogsFail = createAction(
  `${source} Load Water Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getWaterLogsSuccess = createAction(
  `${source} Get Water Logs Success`,
  props<{
    waterLogs: Paginated<WaterLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const getWaterLogsFail = createAction(
  `${source} Get Water Logs Fail`,
  props<{ error: Error }>()
);
// Create
export const createWaterLogSuccess = createAction(
  `${source} Create Water Log Success`,
  props<{ waterLog: WaterLog }>()
);
export const createWaterLogFail = createAction(
  `${source} Create Water Log Fail`,
  props<{ error: Error }>()
);

export const updateWaterLogSuccess = createAction(
  `${source} Update Water Log Success`,
  props<{ waterLog: WaterLog }>()
);
export const updateWaterLogFail = createAction(
  `${source} Update Water Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteWaterLogSuccess = createAction(
  `${source} Delete Water Log Success`,
  props<{ id: string }>()
);
export const deleteWaterLogFail = createAction(
  `${source} Delete Water Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Water Logs Created
export const waterLogsCreated = createAction(
  `${sourceWS} Water Logs Created`,
  props<{ waterLog: WaterLog }>()
);
// [WS] Water Logs Patched
export const waterLogsPatched = createAction(
  `${sourceWS} Water Logs Patched`,
  props<{ waterLog: WaterLog }>()
);
// [WS] Water Logs Removed
export const waterLogsRemoved = createAction(
  `${sourceWS} Water Logs Removed`,
  props<{ id: string }>()
);
