import { FeathersService } from '$/app/services/feathers.service';
import { LibraryDocumentTemplatesWsActions } from '$/app/store/library-document-templates';
import { ILibraryDocumentTemplate } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class LibraryDocumentTemplatesApiService extends AbstractApiService<ILibraryDocumentTemplate> {
  constructor(feathers: FeathersService, store: Store) {
    super('library-document-templates', feathers, store, {
      entityName: 'libraryDocumentTemplate',
      created: LibraryDocumentTemplatesWsActions.libraryDocumentTemplateCreated,
      patched: LibraryDocumentTemplatesWsActions.libraryDocumentTemplatePatched,
      removed: LibraryDocumentTemplatesWsActions.libraryDocumentTemplateRemoved
    });
  }
}
