import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';

@Pipe({
  name: 'ordinal',
  standalone: true
})
export class AlcOrdinalPipe implements PipeTransform {
  transform(integer: string) {
    if (isEmpty(integer)) {
      return `{nil}`;
    }

    const int: number = parseInt(integer);
    const ones = +int % 10,
      tens = (+int % 100) - ones;

    return `${int}${
      ['th', 'st', 'nd', 'rd'][tens === 10 || ones > 3 ? 0 : ones]
    }`;
  }
}
