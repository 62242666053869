import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  MedicalProfessionalPhonesApiActions,
  MedicalProfessionalPhonesGeneralActions
} from './actions';
import {
  initialState,
  medicalProfessionalPhonesAdapter
} from './medical-professional-phones.state';

export const medicalProfessionalPhonesFeature = createFeature({
  name: 'medicalProfessionalPhones',
  reducer: createReducer(
    initialState,
    on(
      MedicalProfessionalPhonesApiActions.createMedicalProfessionalPhoneSuccess,
      (state, action) => {
        const medicalProfessionalPhones = castArray(
          action.medicalProfessionalPhone
        );

        return medicalProfessionalPhonesAdapter.addMany(
          medicalProfessionalPhones,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicalProfessionalPhonesApiActions.updateMedicalProfessionalPhoneSuccess,
      (state, action) => {
        return medicalProfessionalPhonesAdapter.updateOne(
          action.medicalProfessionalPhone,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicalProfessionalPhonesApiActions.deleteMedicalProfessionalPhoneSuccess,
      (state, action) => {
        return medicalProfessionalPhonesAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicalProfessionalPhonesApiActions.createMedicalProfessionalPhoneFail,
      MedicalProfessionalPhonesApiActions.updateMedicalProfessionalPhoneFail,
      MedicalProfessionalPhonesApiActions.deleteMedicalProfessionalPhoneFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesCreated,
      MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesPatched,
      (state, action) => {
        return medicalProfessionalPhonesAdapter.upsertOne(
          action.medicalProfessionalPhone,
          {
            ...state
          }
        );
      }
    ),

    on(
      MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesRemoved,
      (state, action) => {
        return medicalProfessionalPhonesAdapter.removeOne(action.id, {
          ...state
        });
      }
    ),

    on(
      MedicalProfessionalPhonesGeneralActions.addMedicalProfessionalPhones,
      (state, action) => {
        return medicalProfessionalPhonesAdapter.upsertMany(
          action.medicalProfessionalPhones,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicalProfessionalPhonesGeneralActions.clearMedicalProfessionalPhones,
      (state) => {
        return medicalProfessionalPhonesAdapter.removeAll({
          ...state,

          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
