import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FacilityUsersEffects } from '$/app/store/facility-users/facility-users.effects';
import { facilityUsersReducer } from '$/app/store/facility-users/facility-users.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('facilityUsers', facilityUsersReducer),
    EffectsModule.forFeature([FacilityUsersEffects])
  ],
  providers: [FacilityUsersEffects]
})
export class FacilityUsersStoreModule {}
