import { Role, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

const source = '[Roles API]';
const sourceWS = '[Roles API WS]';

// 💥 API ACTIONS

// [API] Load Success/Fail
export const loadRolesSuccess = createAction(
  `${source} Load Roles Success`,
  props<{ roles: Role[] }>()
);
export const loadRolesFail = createAction(
  `${source} Load Roles Fail`,
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getRolesSuccess = createAction(
  `${source} Get Roles Success`,
  props<{ roles: Role[] }>()
);
export const getRolesFail = createAction(
  `${source} Get Roles Fail`,
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchRoleSuccess = createAction(
  `${source} Fetch Role Success`,
  props<{ role: Role }>()
);
export const fetchRoleFail = createAction(
  `${source} Fetch Role Fail`,
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createRoleSuccess = createAction(
  `${source} Create Role Success`,
  props<{ role: MaybeArray<Role> }>()
);
export const createRoleFail = createAction(
  `${source} Create Role Fail`,
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateRoleSuccess = createAction(
  `${source} Update Role Success`,
  props<{ role: Update<Role> }>()
);
export const updateRoleFail = createAction(
  `${source} Update Role Fail`,
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteRoleSuccess = createAction(
  `${source} Delete Role Success`,
  props<{ id: string }>()
);
export const deleteRoleFail = createAction(
  `${source} Delete Role Fail`,
  props<{ error: Error }>()
);

// 💥 WS ACTIONS

// [WS] Roles Created
export const rolesCreated = createAction(
  `${sourceWS} Roles Created`,
  props<{ role: Role }>()
);
// [WS] Roles Patched
export const rolesPatched = createAction(
  `${sourceWS} Roles Patched`,
  props<{ role: Role }>()
);
// [WS] Roles Removed
export const rolesRemoved = createAction(
  `${sourceWS} Roles Removed`,
  props<{ id: string }>()
);
