import { IDocumentRelation } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DocumentRelationsWsActions = createActionGroup({
  source: 'Document Relations WS',
  events: {
    'Document Relation Created': props<{
      documentRelation: IDocumentRelation;
    }>(),
    'Document Relation Patched': props<{
      documentRelation: IDocumentRelation;
    }>(),
    'Document Relation Removed': props<{ id: string }>()
  }
});

export const DocumentRelationsGeneralActions = createActionGroup({
  source: 'Document Relations General',
  events: {
    'Clear Document Relations': emptyProps(),
    'Add Document Relations': props<{
      documentRelations: IDocumentRelation[];
    }>()
  }
});
