import { DashboardOverviewPageActions } from '$/app/pages/dashboard/dashboard-overview/dashboard-overview.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks/resident-medication-tasks.actions';
import { RoutineMedicationsPageActions } from '$/app/pages/medications/dashboard/routine-medications/routine-medications.actions';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { MedicationMarsActionModalActions } from '$/app/pages/medications/shared/components/medication-mar-action-modal/medication-mar-action.modal.actions';
import {
  EffectHelpersService,
  MedicationTasksApiService
} from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicationTasksApiActions } from './medication-tasks.actions';

@Injectable()
export class MedicationTasksEffects {
  private readonly actions$ = inject(Actions);
  private readonly medicationTasksService = inject(MedicationTasksApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadMedicationTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        RoutineMarListPageActions.loadMedicationTasks,
        MissedMedicationsPageActions.loadMedicationTasks
      ),
      this.effectHelpers.apiRequest({
        description: 'Loading medication tasks',
        onRequest: (action) =>
          this.medicationTasksService
            .getAll(action.params)
            .pipe(
              getAllPages(this.medicationTasksService, action?.params?.query),
              reducePaginatedResponses()
            ),
        onSuccess: (medicationTasks) => {
          const responseData = new ApiData(
            'medicationTasks',
            medicationTasks,
            MedicationTasksApiActions.loadMedicationTasksSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicationTasksApiActions.loadMedicationTasksFail({ error })
      })
    );
  });

  getMedicationTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        RoutineMedicationsPageActions.getMedicationTasks,
        ResidentMedicationTasksPageActions.getMedicationTasks,
        DashboardOverviewPageActions.getMedicationTasks
      ),
      this.effectHelpers.apiRequest({
        description: 'Getting medication tasks',
        onRequest: (action) =>
          this.medicationTasksService
            .getAll(action.params)
            .pipe(
              getAllPages(this.medicationTasksService, action?.params?.query),
              reducePaginatedResponses()
            ),
        onSuccess: (medicationTasks) => {
          const responseData = new ApiData(
            'medicationTasks',
            medicationTasks,
            MedicationTasksApiActions.getMedicationTasksSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicationTasksApiActions.getMedicationTasksFail({ error })
      })
    );
  });

  getMoreMedicationTasks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoutineMarListPageActions.getMedicationTasks),
      this.effectHelpers.apiRequest({
        description: 'Getting more medication tasks',
        onRequest: (action) =>
          this.medicationTasksService.getAll(action.params),
        onSuccess: (medicationTasks) => {
          const responseData = new ApiData(
            'medicationTasks',
            medicationTasks,
            MedicationTasksApiActions.getMedicationTasksSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicationTasksApiActions.getMedicationTasksFail({ error })
      })
    );
  });

  fetchMedicationTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoutineMarDetailPageActions.fetchMedicationTask),
      this.effectHelpers.apiRequest({
        description: 'Getting more medication tasks',
        onRequest: (action) =>
          this.medicationTasksService.get(action.id, action.params),
        onSuccess: (medicationTasks) => {
          const responseData = new ApiData(
            'medicationTasks',
            medicationTasks,
            MedicationTasksApiActions.fetchMedicationTaskSuccess,
            {
              payloadKey: 'medicationTask'
            }
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicationTasksApiActions.fetchMedicationTaskFail({ error })
      })
    );
  });

  updateMedicationTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentMedicationTasksPageActions.prepMedication,
        ResidentMedicationTasksPageActions.giveMedication,
        ResidentMedicationTasksPageActions.omitMedication,
        ResidentMedicationTasksPageActions.undoPrepMedication,
        ResidentMedicationTasksPageActions.undoGiveMedication,
        ResidentMedicationTasksPageActions.undoOmitMedication,
        MedicationMarsActionModalActions.changeCurrentState,
        MissedMedicationsPageActions.changeMedicationTaskState,
        RoutineMarListPageActions.changeCurrentState,
        RoutineMarDetailPageActions.changeCurrentState
      ),
      this.effectHelpers.apiRequest({
        description: 'updating medication task',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.medicationTasksService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (medicationTask) =>
          MedicationTasksApiActions.updateMedicationTaskSuccess({
            medicationTask
          }),
        onError: (error) =>
          MedicationTasksApiActions.updateMedicationTaskFail({ error })
      })
    );
  });

  updateMultipleMedicationTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentMedicationTasksPageActions.prepAllMedications,
        ResidentMedicationTasksPageActions.giveAllMedications,
        ResidentMedicationTasksPageActions.omitAllMedications,
        ResidentMedicationTasksPageActions.undoPrepAllMedications,
        ResidentMedicationTasksPageActions.undoGiveAllMedications,
        ResidentMedicationTasksPageActions.undoOmitAllMedications
      ),
      this.effectHelpers.apiRequest({
        description: 'updating multiple medication task',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.medicationTasksService.patchMultiple(
            action.changes,
            action.params
          ),
        onSuccess: (medicationTasks) =>
          MedicationTasksApiActions.updateMultipleMedicationTasksSuccess({
            medicationTasks
          }),
        onError: (error) =>
          MedicationTasksApiActions.updateMultipleMedicationTasksFail({ error })
      })
    );
  });
}
