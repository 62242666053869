import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LibraryDocumentTemplatesEffects } from './library-document-templates.effects';
import { libraryDocumentTemplatesFeature } from './library-document-templates.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(libraryDocumentTemplatesFeature),
    EffectsModule.forFeature([LibraryDocumentTemplatesEffects])
  ],
  providers: [LibraryDocumentTemplatesEffects]
})
export class LibraryDocumentTemplatesStoreModule {}
