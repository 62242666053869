import { FacilityUserGroupMember, Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const FacilityUserGroupPickerFormPageActions = createActionGroup({
  source: 'Facility User Group Picker Form Page',
  events: {
    'Create Facility User Group Member': (
      facilityUserGroupMember: FacilityUserGroupMember,
      params: Params = { query: {} }
    ) => ({ facilityUserGroupMember, params }),
    'Delete Facility User Group Member': (
      id: string,
      params: Params = { query: {} }
    ) => ({ id, params })
  }
});
