import { ResidentContactPhoneStoreModule } from './resident-contact-phones-store.module';
import * as ResidentContactPhonesSelectors from './resident-contact-phones.selectors';
import * as ResidentContactPhoneState from './resident-contact-phones.state';

export * from './actions';
export {
  ResidentContactPhoneStoreModule,
  ResidentContactPhonesSelectors,
  ResidentContactPhoneState
};
