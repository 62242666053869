import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IPaginationInfo, ShowerLog } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface ShowerLogPageQueries {
  all: IPaginationInfo;
}

export const showerLogsAdapter = createEntityAdapter<ShowerLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<ShowerLog> {
  pagination: ShowerLogPageQueries;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = showerLogsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
