import { AlcAction } from '$/models';
import { LoginData } from '$shared/services/user';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const AuthenticationActions = createActionGroup({
  source: 'Authentication',
  events: {
    Signup: emptyProps(),
    'Signup Success': AlcAction<{ loginData: LoginData }>(),
    'Signup Fail': AlcAction<{ error: Error }>(),

    Login: emptyProps(),
    'Login Success': AlcAction<{ loginData: LoginData }>(),
    'Login Fail': AlcAction<{ error: Error }>(),

    'Authenticate With Stored Token': emptyProps(),
    'Authenticate With Stored Token Success': AlcAction<{
      loginData: LoginData;
    }>(),
    'Authenticate With Stored Token Fail': AlcAction<{ error: Error }>(),

    'Authenticate Facility Change': emptyProps(),
    'Authenticate Facility Change Success': AlcAction<{
      loginData: LoginData;
    }>(),
    'Authenticate Facility Change Fail': AlcAction<{ error: Error }>(),

    Logout: AlcAction<{ userId: string }>()
  }
});
