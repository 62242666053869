import { MedicationDestructionItemFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-item-form/medication-destruction-item.actions';
import { NarcoticCountListPageActions } from '$/app/pages/medications/dashboard/narcotic-counts/narcotic-count-list/narcotic-count-list.actions';
import { MedicationDetailInfoPageActions } from '$/app/pages/medications/residents/medication-detail/medication-detail-info/medication-detail-info.actions';
import { MedicationFormPageActions } from '$/app/pages/medications/residents/medication-form/medication-form.actions';
import { MedicationListPageActions } from '$/app/pages/medications/residents/medication-list/medication-list.actions';
import {
  initialState,
  medicationsAdapter
} from '$/app/store/medications/medications.state';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import { MedicationsApiActions, MedicationsGeneralActions } from './actions';
import * as MedicationsActions from './medications.actions';

export const medicationsFeature = createFeature({
  name: 'medications',
  reducer: createReducer(
    initialState,

    on(
      MedicationListPageActions.loadResidentMedications,
      MedicationListPageActions.bulkFlagToOrder,
      MedicationListPageActions.bulkUnflagToOrder,
      MedicationFormPageActions.createMedication,
      MedicationFormPageActions.updateMedication,
      MedicationDetailInfoPageActions.discontinueMedication,
      MedicationDetailInfoPageActions.holdMedication,
      MedicationDetailInfoPageActions.activateMedication,
      MedicationDetailInfoPageActions.deleteMedication,
      MedicationDetailInfoPageActions.toggleTrackInventory,
      NarcoticCountListPageActions.getMedications,
      MedicationDestructionItemFormActions.loadMedications,
      (state) => {
        return {
          ...state,
          loading: true
        };
      }
    ),

    on(MedicationsApiActions.loadMedicationsSuccess, (state, action) => {
      return medicationsAdapter.setAll(action.medications.data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(MedicationsApiActions.getMedicationsSuccess, (state, action) => {
      return medicationsAdapter.upsertMany(action.medications.data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(MedicationsApiActions.fetchMedicationSuccess, (state, action) => {
      return medicationsAdapter.upsertOne(action.medication, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      MedicationsActions.addMedication_ResidentMedicationsRoute,
      MedicationsApiActions.addMedication_DashboardListRoute,
      (state, action) => {
        const medications = castArray(action.medication);

        return medicationsAdapter.upsertMany(medications, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(MedicationsApiActions.updateMedicationSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(MedicationsApiActions.deleteMedicationSuccess, (state, action) => {
      return medicationsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(MedicationsApiActions.createMedicationSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(
      MedicationsApiActions.loadMedicationsFail,
      MedicationsApiActions.fetchMedicationFail,
      MedicationsApiActions.createMedicationFail,
      MedicationsApiActions.updateMedicationFail,
      MedicationsApiActions.deleteMedicationFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicationsApiActions.medicationsCreated,
      MedicationsApiActions.medicationsPatched,
      (state, action) => {
        return medicationsAdapter.upsertOne(action.medication, state);
      }
    ),

    on(MedicationsApiActions.medicationsRemoved, (state, action) => {
      return medicationsAdapter.removeOne(action.id, state);
    }),

    on(MedicationsGeneralActions.addMedications, (state, action) => {
      return medicationsAdapter.upsertMany(action.medications, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(MedicationsGeneralActions.clearMedications, (state) => {
      return medicationsAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
