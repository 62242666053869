import { TPrnAmount } from '$shared/medications/prn-amount.schema';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

const source = '[Prn Amounts API]';
const sourceWs = '[Prn Amounts WS]';

export const loadPrnAmountsSuccess = createAction(
  `${source} Load Prn Amounts Success`,
  props<{ prnAmounts: TPrnAmount[] }>()
);

export const loadPrnAmountsFail = createAction(
  `${source} Load Prn Amounts Fail`,
  props<{ error: Error }>()
);

export const getPrnAmountsSuccess = createAction(
  `${source} Get Prn Amounts Success`,
  props<{ prnAmounts: TPrnAmount[] }>()
);
export const getPrnAmountsFail = createAction(
  `${source} Get Prn Amounts Fail`,
  props<{ error: Error }>()
);

export const fetchPrnAmountSuccess = createAction(
  `${source} Fetch Prn Amount Success`,
  props<{ prnAmount: TPrnAmount }>()
);

export const fetchPrnAmountFail = createAction(
  `${source} Fetch Prn Amount Fail`,
  props<{ error: Error }>()
);

export const createPrnAmountSuccess = createAction(
  `${source} Create Prn Amount Success`,
  props<{ prnAmount?: MaybeArray<TPrnAmount> }>()
);

export const createPrnAmountFail = createAction(
  `${source} Create Prn Amount Fail`,
  props<{ error: Error }>()
);

export const updatePrnAmountSuccess = createAction(
  `${source} Update Prn Amount Success`,
  props<{ prnAmount: TPrnAmount }>()
);

export const updatePrnAmountFail = createAction(
  `${source} Update Prn Amount Fail`,
  props<{ error: Error }>()
);

export const deletePrnAmountSuccess = createAction(
  `${source} Delete Prn Amount Success`,
  props<{ id: string }>()
);

export const deletePrnAmountFail = createAction(
  `${source} Delete Prn Amount Fail`,
  props<{ error: Error }>()
);

// [WS] Prn Amounts Created
export const prnAmountsCreated = createAction(
  `${sourceWs} Prn Amounts Created`,
  props<{ prnAmount: TPrnAmount }>()
);
// [WS] Prn Amounts Patched
export const prnAmountsPatched = createAction(
  `${sourceWs} Prn Amounts Patched`,
  props<{ prnAmount: TPrnAmount }>()
);
// [WS] Prn Amounts Removed
export const prnAmountsRemoved = createAction(
  `${sourceWs} Prn Amounts Removed`,
  props<{ id: string }>()
);
