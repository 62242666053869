import { sortByName } from '$/app/utils';
import { Role } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const rolesAdapter = createEntityAdapter<Role>({
  sortComparer: sortByName
});

export interface State extends EntityState<Role> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = rolesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
