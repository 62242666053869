import { EffectHelpersService } from '$/app/services';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { exhaustMap, switchMap, tap } from 'rxjs/operators';
import {
  MedicationInventoriesApiActions,
  MedicationInventoriesListActions,
  MedicationInventoryDetailActions,
  MedicationInventoryFormActions
} from './actions';

@Injectable()
export class MedicationInventoriesEffects {
  private readonly actions$ = inject(Actions);
  private readonly effectHelper = inject(EffectHelpersService);

  loadMedicationInventories$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoriesListActions.loadMedicationInventories),
        switchMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  getMedicationInventories$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoriesListActions.getMedicationInventories),
        switchMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  fetchMedicationInventory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoryDetailActions.fetchMedicationInventory),
        switchMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  createMedicationInventory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoryFormActions.createMedicationInventory),
        exhaustMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  createMedicationInventoryFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoriesApiActions.createMedicationInventoryFail),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateMedicationInventory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoryDetailActions.updateMedicationInventory),
        switchMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  updateMedicationInventoryFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoriesApiActions.updateMedicationInventoryFail),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteMedicationInventory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoryDetailActions.deleteMedicationInventory),
        exhaustMap(() => {
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  deleteMedicationInventoryFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicationInventoriesApiActions.deleteMedicationInventoryFail),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
