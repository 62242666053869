import { Paginated, Update } from '$/models';
import { IResident } from '$shared/residents/residents.interface';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentsApiActions = createActionGroup({
  source: 'Residents API',
  events: {
    'Load Residents Success': props<{ residents: Paginated<IResident> }>(),
    'Load Residents Fail': props<{ error: Error }>(),
    'Get Residents Success': props<{ residents: Paginated<IResident> }>(),
    'Get Residents Fail': props<{ error: Error }>(),
    'Fetch Resident Success': props<{ resident: IResident }>(),
    'Fetch Resident Fail': props<{ error: Error }>(),
    'Create Resident Success': props<{ resident: IResident }>(),
    'Create Resident Fail': props<{ error: Error }>(),
    'Update Resident Success': props<{ resident: Update<IResident> }>(),
    'Update Resident Fail': props<{ error: Error }>(),
    'Delete Resident Success': props<{ id: string }>(),
    'Delete Resident Fail': props<{ error: Error }>()
  }
});

export const ResidentsWebsocketActions = createActionGroup({
  source: 'Residents Websocket',
  events: {
    'Residents Created': props<{ resident: IResident }>(),
    'Residents Patched': props<{ resident: IResident }>(),
    'Residents Removed': props<{ id: string }>()
  }
});
