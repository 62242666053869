import {
  EffectHelpersService,
  OmittedMedicationsApiService
} from '$/app/services';
import { OmittedMedication, Paginated } from '$/models';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import {
  OmittedMedicationDetailActions,
  OmittedMedicationsApiActions,
  OmittedMedicationsListActions
} from './actions';

@Injectable()
export class OmittedMedicationsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly omittedMedicationsService = inject(
    OmittedMedicationsApiService
  );
  private readonly effectHelper = inject(EffectHelpersService);

  loadOmittedMedications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OmittedMedicationsListActions.loadOmittedMedications),
      switchMap((action) => {
        return this.omittedMedicationsService.getAll(action.params).pipe(
          map((omittedMedications: Paginated<OmittedMedication>) => {
            return OmittedMedicationsApiActions.loadOmittedMedicationsSuccess({
              omittedMedications
            });
          }),
          catchError((error) => {
            return of(
              OmittedMedicationsApiActions.loadOmittedMedicationsFail({ error })
            );
          })
        );
      })
    );
  });

  getOmittedMedications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OmittedMedicationsListActions.getOmittedMedications),
      switchMap((action) => {
        return this.omittedMedicationsService.getAll(action.params).pipe(
          map((omittedMedications: Paginated<OmittedMedication>) => {
            return OmittedMedicationsApiActions.getOmittedMedicationsSuccess({
              omittedMedications
            });
          }),
          catchError((error) => {
            return of(
              OmittedMedicationsApiActions.getOmittedMedicationsFail({ error })
            );
          })
        );
      })
    );
  });

  fetchOmittedMedication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OmittedMedicationDetailActions.fetchOmittedMedication),
      switchMap((action) => {
        return this.omittedMedicationsService
          .get(action.id, action.params)
          .pipe(
            map((omittedMedication: OmittedMedication) => {
              return OmittedMedicationsApiActions.fetchOmittedMedicationSuccess(
                {
                  omittedMedication
                }
              );
            }),
            catchError((error) => {
              return of(
                OmittedMedicationsApiActions.fetchOmittedMedicationFail({
                  error
                })
              );
            })
          );
      })
    );
  });

  updateOmittedMedication$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OmittedMedicationDetailActions.updateOmittedMedication),
        switchMap((action) => {
          return this.omittedMedicationsService
            .patch(action.id, action.changes)
            .pipe(
              tap(
                this.effectHelper.onModalFormSubmitSuccess(
                  'OmittedMedication updated successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  OmittedMedicationsApiActions.updateOmittedMedicationFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateOmittedMedicationFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OmittedMedicationsApiActions.updateOmittedMedicationFail),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteOmittedMedication$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OmittedMedicationDetailActions.deleteOmittedMedication),
        exhaustMap((action) => {
          return this.omittedMedicationsService.delete(action.id).pipe(
            tap(
              this.effectHelper.onModalFormSubmitSuccess(
                'OmittedMedication deleted successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                OmittedMedicationsApiActions.deleteOmittedMedicationFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteOmittedMedicationFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OmittedMedicationsApiActions.deleteOmittedMedicationFail),
        tap(this.effectHelper.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
