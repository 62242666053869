import { Params } from '$/models';
import { props } from '@ngrx/store';

import { createActionGroup } from '@ngrx/store';

export const DestructionMethodsListPageActions = createActionGroup({
  source: 'Destruction Methods List Page',
  events: {
    'Delete Destruction Method': props<{ id: string; params?: Params }>()
  }
});
