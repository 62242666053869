<ion-header>
  <ion-toolbar color="dashboard-primary">
    <ion-title>What's New</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="min-h-full bg-surface p-6">
    <div class="mx-auto flex max-w-xl flex-col">
      <alc-release-section
        id="release-1-29"
        title="Release 1.29"
        subtitle="August 27, 2024"
        codename="Copper"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Medication Destruction">
          <alc-release-paragraph>
            Finally! You can now destroy medications in Alcomy. Just like
            Narcotics, this is a beta feature. If you would like to try it out,
            please reach out to Alcomy support to get access and trained.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Dashboard Medication Destruction"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/dashboard-destruction.png"
          />

          <alc-release-paragraph>
            In short, step 1 is to add medications to the Pending Destruction
            list on the new <b>Destruction</b> page located at
            <b>Dashboard > Meds > Destruction</b> (This is kind of like your
            stash of medications that need to be destroyed). You do can this in
            3 ways:
          </alc-release-paragraph>

          <ul class="space-y-2">
            <li>
              By marking an omitted medication to be destroyed on the Reason for
              Omission form.
            </li>
            <li>
              By marking an entire inventory item to be destroyed from the
              medication inventory page in the resident section.
            </li>
            <li>
              By adding a medication to be destroyed directly on the Destruction
              page. This is nice for those random medications you find on the
              floor and you don't know what they are or who they belong to.
            </li>
          </ul>

          <alc-release-paragraph>
            Step 2 comes when you are ready to destroy those items in Pending
            Destruction. Simply select some or all of them, click the Create
            Destruction button, and destroy and witness.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="New Report Builder">
          <alc-release-paragraph>
            When it comes to printed reports there are three things that get
            requested constantly.
          </alc-release-paragraph>

          <ol class="space-y-2">
            <li>
              The ability to print different variations and combinations of
              reports available.
            </li>
            <li>
              The ability to print a report for multiple residents at the same
              time.
            </li>
            <li>The ability to add your own logo and branding to reports.</li>
          </ol>

          <alc-release-paragraph>
            Well, we are happy to report (no pun intended) that in this release
            we've added the first version of our custom report builder that
            allows you to do all three. To get started, head on over to the new
            reports page located at <b>Residents > Reports</b>
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Custom Report Builder"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/custom-reports-setup.gif"
          />

          <alc-release-paragraph>
            When you're there, click the plus button to be presented with the
            report builder. The report builder allows you to toggle on/off the
            various sub-reports you want to include in your report and rearrange
            them however you'd like. Some reports have additional configuration
            options like a date range or checkboxes for showing/hiding certain
            sections within the sub-report.
          </alc-release-paragraph>

          <alc-release-paragraph>
            In desktop, you'll also see a preview of the layout for the report
            to the right of the sub-report options.
          </alc-release-paragraph>

          <alc-release-paragraph>
            When you're finished, give the report a name and optional
            description and voilà! Your custom report is built. Next, it's time
            to generate it.
          </alc-release-paragraph>

          <alc-callout class="my-4" type="success">
            Pro Tip: Create a custom report for the things your licensing rep
            typically asks for. Then, when they show up, you can generate the
            report for the residents they want. The faster you get them what
            they want, the faster you can get them the heck outta there! :)
          </alc-callout>

          <alc-release-image
            class="w-full"
            caption="Generating Custom Reports"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/custom-reports-generation.gif"
          />

          <alc-release-paragraph>
            On the reports page, you will see all your custom reports. Click
            <b>Preview Report</b> on one of your reports. Next, select the
            residents you want to generate the report for, and click Submit.
          </alc-release-paragraph>

          <div class="flex flex-col gap-6">
            <alc-callout type="success">
              Pro Tip: To save on costs, consider downloading your report as a
              PDF rather than printing them. Several of our customers have
              reported saving $250+ a month just on paper and ink costs by
              employing this technique. Remember, you can always print the PDF
              later if you need to.
            </alc-callout>

            <alc-callout type="warning">
              <p>
                One caveat to be aware of is that the more sub-reports you add
                to a report, and the more residents you generate a report for,
                the longer it will take to generate due to the sheer amount of
                data that needs to be collected. We are working on ways to
                possibly run this process in the background, but we're not quite
                there yet.
              </p>
            </alc-callout>

            <alc-callout type="info">
              For now the old reports still exists where they are, but will be
              removed in a future release.
            </alc-callout>
          </div>
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Merge Pharmacies and Medical Professionals"
        >
          <alc-release-paragraph>
            Do you have some pharmacies or medical professionals that were
            created as duplicates? Well, now you can merge them together so that
            you only have one.
          </alc-release-paragraph>
          <alc-release-paragraph>
            To do this, first go to <b>Configuration > Contacts</b>. Once there,
            either long press on a pharmacy (or medical professional) or choose
            <b>Select Item</b> from the item's action menu (3 dots). This will
            turn on multi-select mode.
          </alc-release-paragraph>
          <alc-release-image
            class="w-full"
            caption="Custom Report Builder"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/pharmacy-merge.gif"
          />
          <alc-release-image
            class="w-full"
            caption="Custom Report Builder"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/medical-professional-merge.gif"
          />
          <alc-release-paragraph>
            Next, select all the items that are duplicates and click the
            multi-select action menu (6 dots) and choose <b>Merge Records</b>.
            You will be presented with the merge form.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Basically, you need to select which pharmacy (or medical
            professional) should be the primary one. This is typically the one
            that has the most information filled in. Alcomy will only fill in
            missing fields, and will not overwrite ones that already have data.
          </alc-release-paragraph>
          <alc-release-paragraph>
            All that's left to do is click the submit button and watch the
            duplicates disappear.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="improvement">
          Added eye color and height to the Basic Information section on the
          Facesheet.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added mortuary and mortuary phone number to the Medical Services
          section on the Facesheet.
        </alc-release-item>
        <alc-release-item type="improvement">
          Added OTC label to inventory item when medication is OTC
        </alc-release-item>

        <alc-release-item type="improvement">
          You can now upload a PDF for the medication scripts.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added the user that last updated a medication, as well as the date and
          time of the update, to the Medication Detail page
        </alc-release-item>

        <alc-release-item type="improvement">
          Added a new field on medications called "Originally Prescribed On" to
          record the date the medication was first prescribed.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added a prompt when undoing a medication pass, in order to prevent
          accidental undos.
        </alc-release-item>
        <alc-release-item type="improvement">
          Added the ability to update the pharmacy from the pharmacy information
          modal.
        </alc-release-item>

        <alc-release-item type="change">
          Updated the link for the help library button in the main menu which
          now sends you to the new help library.
        </alc-release-item>

        <alc-release-item type="bugfix">
          After correcting an email address for a user, the system wouldn't send
          signup emails to the new address.
        </alc-release-item>

        <alc-release-item type="bugfix">
          The ability to order medications was incorrectly tied to the "update
          medications" permissions. There is now a dedicated permission for
          flagging medications to be ordered.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Setting the end date on a medication schedule after tasks have already
          been created, doesn't remove tasks past the end date.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Creating a document template and setting it to auto-create for
          everyone didn't actually create documents for everyone
        </alc-release-item>

        <alc-release-footer
          href="https://doc.clickup.com/2369496/d/h/289yr-2467/df5d518c915ba17"
        />
      </alc-release-section>

      <alc-release-section
        id="release-1-28"
        title="Release 1.28"
        subtitle="June 21, 2024"
        codename="Nickel"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-paragraph>
          In this release we've made a number of improvements to the ordering
          experience.
        </alc-release-paragraph>

        <alc-release-highlight
          type="feature"
          title="New Order Review Experience"
        >
          <alc-release-paragraph>
            Firstly, the Pending orders page has been redesigned and renamed to
            just <b>Orders</b>. The page now shows a list of orders instead of a
            list of order items (which previously could have spanned multiple
            orders). If the medications being ordered are to different
            pharmacies, a separate order for each pharmacy will be created.
          </alc-release-paragraph>

          <alc-release-paragraph>
            You'll also see some new status indicators so you can quickly see
            which orders were submitted successfully, which ones were received
            by you, which ones you cancelled, and which ones had failed and why.
            You can also filter the orders down by status and date range
            allowing you to go back and review your previously placed orders.
          </alc-release-paragraph>

          <alc-release-image
            caption="Medication Orders Page"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/medication-orders-list-desktop.png"
          />

          <alc-release-paragraph>
            Clicking on an order takes you to the new <b>Order Details</b>
            page, where you'll see the individual medications that were part of
            that order, grouped by resident (similar to what you saw
            previously).
          </alc-release-paragraph>

          <alc-release-image
            caption="Medication Order Details Page"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/medication-order-details-desktop.png"
          />

          <alc-release-paragraph>
            If you have eagle eyes, you may have noticed from the screenshots
            that you can now delete orders and order items. Finally! You can
            also view the actual order PDF Alcomy generates and sends to the
            pharmacy, and (as we hinted earlier) you can mark an order (or order
            item) as cancelled.
          </alc-release-paragraph>

          <alc-callout type="warning">
            This is just a status change for now. Alcomy doesn't actually send
            the cancel request to the pharmacy. You'll have to notify them
            manually until we can add this functionality.
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="Changing Ordering Method">
          <alc-release-paragraph>
            For pharmacies that allow and prefer orders to be sent via email,
            this option is now available. This can be done by setting the new
            <b>Preferred Ordering Method</b> field on the pharmacy form to
            <b>Email</b> instead of <b>Fax</b> and entering an email address for
            the pharmacy. That's it! Orders to that pharmacy will now be sent
            via email.
          </alc-release-paragraph>

          <alc-release-image
            class="mx-auto w-full sm:w-3/4"
            caption="Preferred Ordering Method Field"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/ordering-method-field.png"
          />

          <alc-callout type="error">
            <p>
              Sending PHI information through email is kind of a touchy subject
              with a lot of opinions and gray area due to conflicting
              information. Some will say, "email is insecure", or "email is not
              HIPAA compliant". And while both of those statements CAN be true,
              it is equally true that email CAN be made secure and HIPAA
              compliant. Read on to see what HHS says and what Alcomy does to
              make sure our emails are both secure and HIPAA compliant.
            </p>

            @if (expandEmailDisclaimer) {
              <div @expansion>
                <p>
                  If you would like to first know what HHS says about this
                  topic, here are a couple of links.
                </p>
                <ul class="space-y-2">
                  <li>
                    <a
                      href="https://www.hhs.gov/hipaa/for-professionals/faq/2006/does-the-security-rule-allow-for-sending-electronic-phi-in-an-email/index.html"
                    >
                      Does the Security Rule allow for sending electronic PHI
                      (e-PHI) in an email or over the Internet?
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.hhs.gov/hipaa/for-professionals/faq/570/does-hipaa-permit-health-care-providers-to-use-email-to-discuss-health-issues-with-patients/index.html"
                    >
                      Does the HIPAA Privacy Rule permit health care providers
                      to use e-mail to discuss health issues and treatment with
                      their patients?
                    </a>
                  </li>
                </ul>
                <p>
                  To transmit securely, Alcomy uses a third party email provider
                  that encrypts our emails as they travel to the recipient's
                  email server. However, this is not enough. The recipient's
                  email service needs to also store the email in an encrypted
                  state. Some do, and some don't. It's really outside of our
                  control.
                </p>
                <p>
                  This is why we don't actually send PHI information directly in
                  the email in the first place. Instead, we send a secure link
                  to the recipient which allows them to view and download (in
                  the case of files) the PHI information which is encrypted and
                  stored on our secure servers.
                </p>
                <p>
                  In this way, the link acts as a type of authentication token
                  that you grant to the email's recipient. As an extra bit of
                  protection, we put an expiration on the link making it only
                  good for a limited time.
                </p>
                <p>
                  You still have to be careful that you are not granting an
                  unauthorized individual access to PHI information through
                  Alcomy, but this is true no matter what system (paper or
                  digital) you are using. When it comes to ordering medications;
                  though, we hope you can trust your pharmacist :). At the end
                  of the day it's up to you, them, and the resident's power of
                  attorney to determine how PHI is being shared.
                </p>
              </div>
            }

            <div class="flex justify-end">
              <ion-button
                fill="clear"
                shape="round"
                color="danger"
                (tap)="expandEmailDisclaimer = !expandEmailDisclaimer"
                [style.touch-action]="'auto'"
              >
                @if (!expandEmailDisclaimer) {
                  Read More
                } @else {
                  Close
                }
              </ion-button>
            </div>
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="Bulk Order Flagging">
          <alc-release-paragraph>
            On the resident's medication list page, you can now select multiple
            medications and flag them to be ordered. We'll be adding more bulk
            actions in the coming days.
          </alc-release-paragraph>

          <alc-release-image
            class="mx-auto w-full sm:w-3/4"
            caption="Bulk Order Flagging"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/medication-list-multi-select.png"
          />

          <alc-release-paragraph />
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Group Care Tasks By Resident (Beta)"
        >
          <alc-release-paragraph>
            Many of you have asked for the ability to group tasks by resident
            instead of by time. In this release we've added this new view to
            care tasks as a beta feature.
          </alc-release-paragraph>
          <alc-release-paragraph>
            As a reminder, beta features are turned on for those who are
            interested in trying out early release software in the "proof of
            concept" phase, and can give feedback to make it better. If that
            sounds like something you'd like to do, please reach out, and we'll
            turn it on for your account. Here's a look at the new view.
          </alc-release-paragraph>

          <alc-release-image
            class="mx-auto w-full sm:w-3/4"
            caption="Care Tasks Grouped by Resident"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/care-tasks-by-resident.png"
          />

          <alc-release-paragraph />
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Move a Resident to Another Facility"
        >
          <alc-release-paragraph>
            We added the ability for you to move a resident from one facility to
            another. Alcomy support used to have to do this manually for you,
            but not anymore. You now have the power to do this yourself at your
            convenience. Simply go to the resident's facesheet and open the
            action menu for <b>Facility Information</b>. You will see a new
            action called <b>Move To Different Facility</b>.
          </alc-release-paragraph>

          <alc-release-image
            class="mx-auto w-full sm:w-3/4"
            caption="Move Resident Action"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/move-resident-option.png"
          />

          <alc-release-paragraph>
            In the Move Resident form you will be presented with the following
            steps.
          </alc-release-paragraph>

          <ol class="space-y-2">
            <li>Select the facility you want to move the resident to</li>
            <li>
              Choose the date and time you want this move to take place (this is
              important as we'll see later).
            </li>
            <li>
              Confirm the move. This action cannot be undone, so we require you
              to explicitly confirm it.
            </li>
          </ol>

          <alc-release-image
            caption="Move Resident Form"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/move-resident-form.png"
          />

          <alc-release-paragraph>
            When you move a resident, Alcomy automatically performs the
            following actions:
          </alc-release-paragraph>

          <ol class="space-y-2">
            <li>
              Sets the resident in the original facility to <b>Discharged</b>
            </li>
            <li>
              Removes any medication or care tasks scheduled after the move
              date/time.
            </li>
            <li>
              Removes logs and appointments recorded or scheduled after the move
              date/time.
            </li>
            <li>
              Copies the resident profile to the target facility, including all
              facesheet information.
            </li>
            <li>
              Copies the resident's medications and care plan items to the
              target facility, but sets the start date for any schedules to the
              move date/time..
            </li>
            <li>
              Copies any non-empty centrally stored inventory to the target
              facility.
            </li>
            <li>
              Generates medication and care tasks based on their respective
              schedules.
            </li>
          </ol>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          Added Nebulizer/Nebulization as a new medication form.
        </alc-release-item>

        <alc-release-item type="feature">
          Added Prescription Undelivered as a new omit reason.
        </alc-release-item>

        <alc-release-item type="feature">
          You can now tell Alcomy to create multiple all-day tasks when creating
          a care task.
        </alc-release-item>

        <alc-release-item type="feature">
          It is now possible to mark an inventory item as not empty.
        </alc-release-item>

        <alc-release-item type="improvement">
          Better prompts when a user is outside the facility's geofence.
        </alc-release-item>

        <alc-release-item type="improvement">
          Dramatically improved the loading performance of care tasks.
        </alc-release-item>

        <alc-release-item type="improvement">
          Made the security keyword shorter when deleting a medication. It's not
          as obnoxious as it used to be.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug where observation notes were not displaying.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a typo in the title for the simplified MAR report.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug that didn't allow you to create a standalone document from
          scratch.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug that did not remove discontinued PRNs from the Manual Med
          Sheet.
        </alc-release-item>

        <alc-release-footer />
      </alc-release-section>

      <alc-release-section
        id="release-1-27"
        title="Release 1.27"
        subtitle="March 13, 2024"
        codename="Cobalt"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Narcotic Counts (Beta)">
          <alc-release-paragraph>
            Today we are releasing the much anticipated
            <b>Narcotic Counts</b> feature as a <b>beta</b> feature. Beta
            features should be fairly stable, but may have bugs or may need to
            be redesigned based on feedback and real world usage. We will be
            rolling out beta features, like Narcotic Counts, to a limited group
            of customers that are part of our beta program. Our goal is to get
            as much feedback as we can, test things in the "real world", and
            make changes where needed before releasing to everyone.
          </alc-release-paragraph>

          <alc-release-paragraph>
            If you are chomping at the bit to try out Narcotic Counts and would
            like to be a beta tester, please reach out to Jake or Alcomy support
            to get access and trained.
          </alc-release-paragraph>

          <alc-release-image
            caption="Narcotic Counts on the Dashboard"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/narcotic-counts.png"
          />
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Better Omit All Experience"
        >
          <alc-release-paragraph>
            We made the omit all experience a lot better. Previously, when you
            did an Omit All, Alcomy would display a separate modal for every
            medication being omitted. This forced users to enter the same omit
            reason and notes over and over again.
          </alc-release-paragraph>

          <alc-release-paragraph>
            Now, we present you with the omit medication modal once and only
            require you to enter the reason and notes once. Below the reason
            information we list out each medication and the amount fields that
            need to be filled out for each. However, we're not leaving you
            completely without help here.
          </alc-release-paragraph>

          <alc-release-image
            caption="Omit All Modal"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/bulk-omit.png"
          />

          <alc-release-paragraph>
            For medications whose dose is a simple value, Alcomy can determine
            the correct quantity and strength and fill them in for you. It's
            only when we are dealing with dose ranges or dose options (which
            describe a spectrum of "possible" doses) that Alcomy cannot make
            this determination without user involvement.
          </alc-release-paragraph>

          <alc-release-paragraph>
            The good news is, dose ranges and dose options are far less common
            than the traditional single dose instruction, so the chances of this
            being a cumbersome process should be pretty low. Of course all
            milage may vary.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight type="improvement" title="Compact Appointments">
          <alc-release-paragraph>
            The Appointments widget on the Dashboard Overview page has been
            given a slight redesign to make it more compact. Specifically, we
            made each appointment item expandable and hid away the
            description/notes information within the expansion panel.
          </alc-release-paragraph>

          <alc-release-paragraph>
            When it becomes relevant to see those additional details you can
            simply expand the item to see them.
          </alc-release-paragraph>

          <alc-release-image
            caption="Compact Appointments on Dashboard"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/condensed-appointments.png"
          />

          <alc-release-paragraph>
            We also changed how many appointments get displayed initially. Any
            appointments after the third appointment will be hidden, with the
            ability to 'Show All' if desired.
          </alc-release-paragraph>
          <alc-release-paragraph>
            We made these changes to make scrolling the Overview page more
            manageable. We found that for facilities that have a lot of
            appointments with long descriptions, a lot of scrolling was needed
            to see the rest of the page.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Medication Details Facelift"
        >
          <alc-release-paragraph>
            First off, the Inventory card on the <b>Medication Details</b> page
            is no more! Nuked! Gone! We have replaced it with it's own dedicated
            <b>Inventory</b> sub tab. This tab displays a typical list view of
            all your centrally stored inventory items with the ability to drill
            down into a dedicated details page for each item. We feel like this
            is a much better and consistent experience than the card switching
            behavior employed previously. Let us known your thoughts.
          </alc-release-paragraph>

          <alc-release-image
            caption="Medication Inventory Sub Tab"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/medication-details-inventory.png"
          />

          <alc-release-image
            caption="Inventory Item Details Page"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/inventory-item-details.png"
          />

          <alc-release-paragraph>
            The rest of the medication information that you are used to seeing
            is now in the <b>Info</b> sub tab but with a few tweaks to the
            design. We've combined the <b>Additional Information</b> card into
            the main medication card and improved the look and layout of the
            information displayed within. The Schedules card remains but with
            the new layout applied.
          </alc-release-paragraph>

          <alc-release-image
            caption="Medication Info Sub Tab"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/medication-details-info.png"
          />
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Consistent Amount Recording Experience"
        >
          <alc-release-paragraph>
            The experience of recording the amount of medication given or
            omitted has been improved across the app. You currently do this in
            places like giving a PRN, omitting a medication task, and giving a
            medication where the dose is not a fixed amount (e.g. When dose is
            of type range or option). Another time you are required to give an
            amount is when marking a medication prepped, given, or omitted from
            the digital MAR page or Missed Medication Tasks page.
          </alc-release-paragraph>

          <alc-release-paragraph>
            Previously, the experience wasn't consistent. For example, when
            giving a PRN, you could record multiple amounts. In contrast, when
            marking a medication task as Given from the Missed Medication Tasks
            page, multiple amounts wasn't supported. Additionally, recording an
            amount for a medication with a ranged dose or option dose yielded a
            different experience altogether.
          </alc-release-paragraph>

          <alc-release-image
            caption="Unified Amounts Control"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/amounts-improvements.png"
          />

          <alc-release-paragraph>
            In this release we've made considerable strides to make this "amount
            recording" experience consistent and unified across the app. Some of
            the underlining capabilities of this effort include:
          </alc-release-paragraph>

          <ul class="my-3 space-y-2 text-dark">
            <li>
              Indicating which inventory item you are drawing from. This is a
              new capability that brings us closer to being able to offer full
              inventory tracking capabilities (a highly requested feature).
            </li>
            <li>
              The ability to record multiple amounts. This is important when you
              need to draw from multiple inventory items with differing unit
              strengths in order to satisfy the required dose.
            </li>
            <li>
              Robust validation logic. This ensures that the amount recorded is
              permitted by the configured dose.
            </li>
          </ul>
        </alc-release-highlight>

        <alc-release-highlight type="bugfix" title="Log Dependencies for PRNs">
          <alc-release-paragraph>
            PRNs that are configured with a log dependency will now require the
            log to be recorded before a PRN can be given in Alcomy. Ironically,
            it has always been possible to setup a PRN medication to require a
            log; however, from some crazy reason we just didn't prompt you to
            record it when giving a PRN.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Not sure how that slipped by the radar, but we have corrected that
            injustice by adding a Record Log button to the Give PRN form. You
            will need to record the log in order to get dosing information and
            finally submit the PRN form.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          You can now add, view, and delete a medication script image directly
          from the medication detail page.
        </alc-release-item>

        <alc-release-item type="feature">
          Added facility type and regional office to facility info card
        </alc-release-item>

        <alc-release-item type="feature">
          When creating a behavior, you can now check options to require an
          antecedent and consequence when recording the log.
        </alc-release-item>

        <alc-release-item type="feature">
          For our ARF customers, we added a new field to the facility form for
          specifying your regional office.
        </alc-release-item>

        <alc-release-item type="feature">
          Added eye drops as a new medication form.
        </alc-release-item>

        <alc-release-item type="improvement">
          We added dedicated icons to represent each medication form and placed
          them next to the medication name across the app.
        </alc-release-item>

        <alc-release-item type="improvement">
          We added a help guide for medication schedules within the
          <b>Routine Schedule Information</b> form divider in the Medication
          Form. Tap the form divider to expand the help guide.
        </alc-release-item>

        <alc-release-item type="improvement">
          Alcomy now calculates and displays the quantity and strength
          representation of the dose on the medication task card (or the
          medication task table if you are on desktop).
        </alc-release-item>

        <alc-release-item type="change">
          When holding or discontinuing a medication, it is now required to
          include a reason.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a problem in the authentication system that was causing users to
          get logged out randomly.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed an issue where iOS devices where showing blue text instead of
          black.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Reloading the facility roles page no longer results in an empty
          screen.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Sending an announcement to yourself no longer results in an error.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a number of layout and metric issues within the resident's Log
          Overview page.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed an issue where trying to upload gigantic images resulted in an
          error.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed an issue where California document templates were showing for
          non-California facilities
        </alc-release-item>

        <alc-release-footer />
      </alc-release-section>

      <alc-release-section
        id="release-1-26"
        title="Release 1.26"
        subtitle="February 14, 2024"
        codename="Iron"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Resident Document Packets">
          <alc-release-paragraph>
            Prior to this release, packets were only created at the document
            template level. In this release, we bring packets to the resident's
            documents tab. This allows you to organize documents by adding them
            to a packet.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Furthermore, you can now set an auto-create rule on a template
            packet which tells Alcomy who should have a packet automatically
            created for them. By default this rule is set to "Nobody
            (Optional)", which means don't create this packet automatically for
            anyone. You can manually create a packet from a template from within
            the resident's "Docs" tab.
          </alc-release-paragraph>
          <alc-release-paragraph>
            If you want Alcomy to automatically create a packet for all
            residents you can set "Auto-create for" to "Everyone." If you have
            any questions about this feature, or would like to learn more,
            please reach out to your customer success manager at Alcomy to get
            trained.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Printed MAR Improvements"
        >
          <alc-release-paragraph>
            We added a simplified MAR report for those who want a more condensed
            format when printing the MAR. This report can be printed in the same
            place as the regular MAR report.
          </alc-release-paragraph>

          <alc-release-paragraph>
            We also added the Rx number for the currently selected inventory
            item to the MAR reports for those who need that information.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Urine Log Improvements"
        >
          <alc-release-paragraph>
            We added greater flexibility to the urine log. You can now record
            whether a resident urinated or not and whether a catheter was used.
            When specifying a catheter, the amount information and color are
            required.
          </alc-release-paragraph>

          <alc-release-paragraph>
            When simply indicating that the resident urinated without a
            catheter, amount information is optional. Lastly, if you indicate
            that the resident did not urinate, the amount fields will be
            disabled.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="bugfix"
          title="Editing a medication no longer resets medication task statuses"
        >
          <alc-release-paragraph>
            We've had a number of reports where editing a medication
            (specifically "Correcting a Mistake") would reset the status on
            medication tasks. We have fixed the underlying issue and have
            restored any records that may have had their statuses affected by
            this bug.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          Added a Conservator checkbox to the resident's contact form and
          Conservatorship designation to the facesheet.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added the resident's image and date of birth to the Resident
          Medication Tasks page on the Dashboard.
        </alc-release-item>

        <alc-release-item type="improvement">
          Add father and mother as resident contact relationship options
        </alc-release-item>

        <alc-release-item type="improvement">
          You can now set the resident's admission date into the future.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added "Memory" as medication type for medications that treat memory
          related illnesses.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added specific icons for distinguishing between medication forms in
          the medication list.
        </alc-release-item>

        <alc-release-item type="improvement">
          You can now choose between DNR or POLST as the primary end of life
          order.
        </alc-release-item>

        <alc-release-item type="improvement">
          Add phone number to the resident's insurance information.
        </alc-release-item>

        <alc-release-item type="improvement">
          Various performance and stability improvements
        </alc-release-item>

        <alc-release-item type="bugfix">
          It is no longer possible to create a medication schedule with no
          times. Previously this would remove all tasks for that schedule.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Paragraphs for resident contact notes and care task descriptions now
          display correctly
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bunch of issues where dates are off by a day
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug in the Medical Professional Info modal where residents
          were not being displayed.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Adding, removing, or editing resident contact phone numbers now
          updates in the UI without a page refresh.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Saving an incident report when no email address is configured for
          sending used to cause an error. This is now fixed.
        </alc-release-item>

        <alc-release-item type="bugfix">
          A variety of other small bug fixes and improvements
        </alc-release-item>

        <alc-release-footer />
      </alc-release-section>

      <alc-release-section
        id="release-1-25"
        title="Release 1.25"
        subtitle="December 14, 2023"
        codename="Manganese"
      >
        <h2 class="mt-10 text-center font-bold">
          Getting Ready for Documents 2.0
        </h2>

        <div class="text-dark">
          <p>
            Today's release is all about incremental updates before we launch
            Documents 2.0, which will bring digital forms and signing to
            licensing docs (starting with California). Yay! Before releasing
            that behemoth of a feature, we wanted to get some smaller features
            and stability improvements out the door.
          </p>

          <h4 class="font-medium">Our Development Process</h4>

          <p>
            The features in this release may seem pretty basic to many of you,
            with some functionality that is clearly missing. This doesn't mean
            we don't have plans to improve it and make it better. Far from it.
            It's just due to our "agile" and "iterative" development process
            that many tech companies use today, like Google and Facebook. i.e.
            Rather than waiting until everything is perfect before releasing a
            feature, we release it as soon as we believe it can start adding
            value. This provides 2 major benefits:
          </p>

          <ol class="space-y-2">
            <li>
              It allows us to get features into your hands a lot sooner, so that
              you can start getting value from them, no matter how small.
            </li>
            <li>
              It allows us to get feedback from users much earlier in the
              development process, which in turn, allows us to iterate on our
              design and functionality before getting too far in the weeds.
            </li>
          </ol>

          <p>
            A good analogy to help illustrate this is: it's easier to turn a
            canoe than it is to turn a battleship. We hope you will see these
            features as just the start of much larger feature initiatives and a
            way to start a dialog around them. Please reach out to us with
            feedback and ideas as they come to you.
          </p>

          <h4 class="font-medium">Personal Message</h4>

          <p>
            As we approach the end of the year, we express our love and
            gratitude to all of you for the exceptional work that you do in
            caring for your residents. We feel very blessed to be your premiere
            partner in that endeavor. A lot of great things are in the works for
            2024, and we can't wait to share them with you. Until then, we wish
            you and your families a Merry Christmas and a Happy New Year!
          </p>
          <p class="mt-12">Sincerely,</p>
          <p>The Alcomy Team</p>
        </div>

        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Resident Groups">
          <alc-release-paragraph>
            We've added the ability to organize residents into groups. When you
            go into the Residents section, you'll notice a new Groups tab, where
            you can create new groups and assign residents to them. We've also
            added a Groups card to the resident's facesheet, similar to the
            Groups card in the user's profile page. From there, you can manage
            which groups the resident is a member of.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Continuing on, we've also added a resident group filter to the Meds,
            Care and Notebook tabs on the Dashboard, which allows you to focus
            on tasks and logs just for that group. Filter changes persist across
            pages, so whatever you set on one page will be applied to other
            pages when you visit them.
          </alc-release-paragraph>
          <alc-release-paragraph>
            We'd love to hear your feedback on how you will use resident groups
            in your facility, and what things you would like to see added.
          </alc-release-paragraph>
          <div class="my-6 grid grid-cols-2 gap-6">
            <alc-release-image
              class="col-span-2 sm:col-span-1"
              src="https://storage.googleapis.com/alcomy-files/release-notes/1.25/resident_groups_page.jpg"
              caption="Resident Groups Page"
            />
            <alc-release-image
              class="col-span-2 sm:col-span-1"
              src="https://storage.googleapis.com/alcomy-files/release-notes/1.25/resident_group_filter.jpg"
              caption="Resident Group Filter"
            />
          </div>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="Resident Appointments">
          <alc-release-paragraph>
            We've started work on calendaring functionality within Alcomy, and
            today we are releasing a small feature within that greater
            initiative: resident appointments.
          </alc-release-paragraph>

          <alc-release-paragraph>
            You can create a new resident appointment in a couple of place: on
            the <b>Resident Profile > Notebook > Events</b> page via the +
            button, or on the <b>Dashboard Overview</b> page via the + button on
            the new <b>Appointments</b> widget
          </alc-release-paragraph>

          <alc-release-image
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.25/todays_appointments.jpg"
            caption="Today's Appointment Widget"
          />

          <alc-release-paragraph>
            The new widget does pretty much what you would expect: it shows you
            any resident appointments for the selected date range (Today by
            default). In future releases we'll add a dedicated calendar page and
            other functionality.
          </alc-release-paragraph>

          <alc-release-paragraph>
            There's a lot that goes into calendaring. We'd love to hear from you
            about what you'd like to see and the problems you need solved.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          Added a biography field for general notes about the resident on the
          facesheet.
        </alc-release-item>

        <alc-release-item type="feature">
          Added resident's preferred pharmacy to the facesheet.
        </alc-release-item>

        <alc-release-item type="feature">
          Added residents primary language to the facesheet.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added a new loading indicator for when the application first boots up
          or the webpage is refreshed.
        </alc-release-item>

        <alc-release-item type="improvement">
          Made a number of UI improvements to make things stand out.
        </alc-release-item>

        <alc-release-item type="change">
          Clicking on a resident contact now opens the contact's info in a modal
          rather than an accordion.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Resident documents now appear as expected when a resident is marked
          deceased.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Printed CAR now shows correct times
        </alc-release-item>

        <alc-release-item type="bugfix">
          When omitting a medication task, the omitted amount now auto populates
          with the correct amount.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Deleting a medication was not working for some users. That is now
          fixed.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Adding an inventory item was not working for some users. That is now
          now fixed.
        </alc-release-item>

        <alc-release-footer />
      </alc-release-section>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button
        shape="round"
        fill="solid"
        color="dashboard-primary"
        (click)="markRead()"
      >
        <ion-label>Got It!</ion-label>
      </ion-button>
      <ion-button
        shape="round"
        fill="outline"
        color="dashboard-primary"
        *ngIf="trigger === 'automatic'"
        (click)="dismiss()"
      >
        <ion-label>Show Me Later</ion-label>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
