import { AlcNewInventoryFormComponent } from '$/app/pages/medications/shared/components/new-inventory-form/inventory-item-form.modal';
import { NewInventoryFormActions } from '$/app/pages/medications/shared/components/new-inventory-form/inventory-item-form.modal.actions';
import { OverlayService } from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, tap } from 'rxjs/operators';
import { InventoryItemDetailPageActions } from '../../inventory-item-detail/inventory-item-detail.actions';
import { MedicationDetailInventoryPageActions } from './medication-detail-inventory.actions';

@Injectable()
export class MedicationDetailInventoryEffects {
  private readonly actions$ = inject(Actions);
  private readonly overlay = inject(OverlayService);

  openNewInventoryItemForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationDetailInventoryPageActions.openNewInventoryItemForm,
        InventoryItemDetailPageActions.openNewInventoryItemForm
      ),
      tap(async (action) => {
        await this.overlay.showModal({
          component: AlcNewInventoryFormComponent,
          componentProps: {
            resident: action.resident,
            medication: action.medication,
            medicationOrderItem: action.medicationOrderItem,
            medicationInventoryItem: action.medicationInventoryItem
          }
        });
      }),
      mergeMap((action) => {
        return [
          NewInventoryFormActions.loadPharmacies(),
          NewInventoryFormActions.getResidentMedicalProfessionals({
            residentId: action.resident.id
          })
        ];
      })
    );
  });
}
