import { MedicalProfessionalGraph, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MedicalProfessionalFormActions = createActionGroup({
  source: 'Medical Professional Form',
  events: {
    'Create Medical Professional': props<{
      medicalProfessional: MedicalProfessionalGraph & { residentId?: string };
      params?: Params;
    }>(),

    'Update Medical Professional': props<Update<MedicalProfessionalGraph>>()
  }
});
