export function isDivisibleBySmallest(amounts: string[], divisor = 1): boolean {
  const numbers = amounts.map((amount) => +amount);

  const smallestNumber = Math.min(...numbers);

  const operand = smallestNumber / divisor;

  const isDivisible = numbers.every((number) => number % operand === 0);

  return isDivisible;
}
