import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'alc-release-paragraph',
  standalone: true,
  imports: [CommonModule],
  template: `<p class="my-3 text-dark"><ng-content /></p> `,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcReleaseParagraphComponent {}
