import { sortByFields } from '$/app/utils';
import { OmittedMedication } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const omittedMedicationsAdapter = createEntityAdapter<OmittedMedication>(
  { sortComparer: sortByFields('createdAt') }
);

export interface State extends EntityState<OmittedMedication> {
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = omittedMedicationsAdapter.getInitialState({
  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
