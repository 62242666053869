import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { SleepLogsPageActions } from '$/app/pages/notebook/shared/logs/sleep-logs/sleep-logs.actions';
import { EffectHelpersService, SleepLogsApiService } from '$/app/services';
import { SleepLogFormPageActions } from '$/app/shared/pages/forms/log-forms/sleep-log-form/sleep-log-form.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { SleepLog } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { SleepLogsApiActions } from './actions';

@Injectable()
export class SleepLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly sleepLogsService = inject(SleepLogsApiService);

  loadSleepLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SleepLogsPageActions.loadSleepLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.sleepLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('sleepLogs', logs);
            responseData.setPrimaryAction(
              SleepLogsApiActions.loadSleepLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getSleepLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SleepLogsPageActions.getSleepLogs,
        LogsPageActions.getSleepLogs,
        GivePrnFormActions.getSleepLogs
      ),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.sleepLogsService.getAll(action.params).pipe(
          getAllPages(this.sleepLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('sleepLogs', logs);
            responseData.setPrimaryAction(
              SleepLogsApiActions.getSleepLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createSleepLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SleepLogFormPageActions.createSleepLog),
      exhaustMap((action) => {
        return this.sleepLogsService.create(action.sleepLog).pipe(
          tap((data: SleepLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((sleepLog: SleepLog) =>
            SleepLogsApiActions.createSleepLogSuccess({
              sleepLog
            })
          ),
          catchError((error) =>
            of(
              SleepLogsApiActions.createSleepLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateSleepLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SleepLogFormPageActions.updateSleepLog),
      switchMap((action) => {
        return this.sleepLogsService.patch(action.id, action.changes).pipe(
          tap((data: SleepLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((sleepLog: SleepLog) => {
            return SleepLogsApiActions.updateSleepLogSuccess({
              sleepLog
            });
          }),
          catchError((error) => {
            return of(SleepLogsApiActions.updateSleepLogFail({ error }));
          })
        );
      })
    );
  });

  deleteSleepLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SleepLogsPageActions.deleteSleepLog,
        ResidentMedicationTasksPageActions.deleteSleepLog,
        MissedMedicationsPageActions.deleteSleepLog,
        RoutineMarDetailPageActions.deleteSleepLog,
        RoutineMarListPageActions.deleteSleepLog,
        GivePrnFormActions.deleteSleepLog,
        CompleteCareTaskModalActions.deleteSleepLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete sleep log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.sleepLogsService.delete(action.id, action.params);
        },
        onSuccess: (sleepLog: SleepLog) =>
          SleepLogsApiActions.deleteSleepLogSuccess({
            id: sleepLog.id
          }),
        onError: (error) => SleepLogsApiActions.deleteSleepLogFail({ error })
      })
    );
  });
}
