import { Params } from '$/models';
import { createAction } from '@ngrx/store';

export const loadMedicationInventoryMovements = createAction(
  '[MedicationInventoryMovements Page] Load MedicationInventoryMovements',
  (params: Params = { query: {} }) => ({ params })
);

export const getMedicationInventoryMovements = createAction(
  '[MedicationInventoryMovements Page] Get MedicationInventoryMovements',
  (params: Params = { query: {} }) => ({ params })
);
