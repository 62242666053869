import { ResidentContactPhonesApiService } from '$/app/services/api/resident-contact-phones.service';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()
export class ResidentContactPhonesEffects {
  constructor(
    private actions$: Actions,
    private residentContactPhonesService: ResidentContactPhonesApiService
  ) {}
}
