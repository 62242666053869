import { Table } from '$/models/data/base';
import { MedicationMarStateId } from '$shared/lookups/medication-mar-states.lookup';

export class MedicationMar extends Table {
  orgId?: string;
  facilityId?: string;
  facilityUserId?: string;
  medicationTaskId?: string;
  omittedMedicationId?: string;
  inventoryMovementId?: string;
  inventoryItemId?: string;
  state?: MedicationMarStateId;
  medicationBatchId?: string;
  medicationId?: string;
  performedBy?: string;
  performedAt?: string;
}
