import { MedicationListFilters } from '$/app/pages/medications/residents/medication-list/medication-list.vmodel';

export const defaultFilters: MedicationListFilters = {
  statuses: ['active']
};

export interface State {
  filters: MedicationListFilters;
}

export const initialState: State = {
  filters: defaultFilters
};
