import { NormalizedSchema } from 'normalizr';

import { NormalizrEntities } from '$shared';

export const getFieldsToRemove = (
  normalizedGraph: NormalizedSchema<any, any>
) => {
  const fieldsToRemove = Object.keys(normalizedGraph.entities).reduce(
    (fieldsToRemove, entity) => {
      if (NormalizrEntities[entity]?.relations) {
        fieldsToRemove[entity] = Object.keys(
          NormalizrEntities[entity].relations
        );
      }

      return fieldsToRemove;
    },
    {}
  );

  return fieldsToRemove;
};
