import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralizeMedForm',
  standalone: true
})
export class AlcPluralizeMedFormPipe implements PipeTransform {
  transform(form: string): any {
    switch (form) {
      case 'Tablet':
        return 'Tablet(s)';
      case 'Capsule':
        return 'Capsule(s)';
      case 'Liquid':
        return 'x';
      case 'Cream':
        return 'x';
      case 'Lotion':
        return 'x';
      case 'Ointment':
        return 'x';
      case 'Gel':
        return 'x';
      case 'Drops':
        return 'Drops(s)';
      case 'Spray':
        return 'Spray(s)';
      case 'Suppository':
        return 'x';
      case 'Packet':
        return 'x';
      case 'Chewable':
        return 'x';
      case 'Gummy':
        return 'x';
      default:
        return 'x';
    }
  }
}
