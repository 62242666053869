import { FeathersService } from '$/app/services/feathers.service';
import { FoodLogsApiActions } from '$/app/store/food-logs/actions';
import { ApiData } from '$/app/utils';
import { Paginated, Params } from '$/models';
import { IFoodLog } from '$shared/services/food-log';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FoodLogsApiService {
  private foodLogsService: Service<IFoodLog>;

  constructor(store: Store, feathers: FeathersService) {
    this.foodLogsService = feathers.client.service('food-logs');

    this.foodLogsService.on('created', (foodLog) => {
      const responseData = new ApiData('foodLogs', foodLog);
      responseData.setPrimaryAction(FoodLogsApiActions.foodLogsCreated, {
        payloadKey: 'foodLog'
      });
      responseData.getActions().forEach((action) => {
        store.dispatch(action);
      });
    });

    this.foodLogsService.on('patched', (foodLog) => {
      store.dispatch(
        FoodLogsApiActions.foodLogsPatched({
          foodLog
        })
      );
    });

    this.foodLogsService.on('removed', (foodLog) => {
      store.dispatch(
        FoodLogsApiActions.foodLogsRemoved({
          id: foodLog.id
        })
      );
    });
  }

  getAll(params: Params) {
    const result = this.foodLogsService.find(params) as Promise<
      Paginated<IFoodLog>
    >;

    return from(result);
  }

  create(foodLog: IFoodLog): Observable<IFoodLog> {
    return from(this.foodLogsService.create(foodLog));
  }

  patch(
    id: string,
    foodLog: Partial<IFoodLog>,
    params: Params = { query: {} }
  ): Observable<IFoodLog> {
    const promise = this.foodLogsService.patch(id, foodLog, params);

    return from(promise);
  }

  delete(id: string, params: Params = { query: {} }): Observable<IFoodLog> {
    return from(this.foodLogsService.remove(id, params));
  }
}
