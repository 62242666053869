import { OverlayService } from '$/app/services';
import { expansionAnimation } from '$/app/shared/animations/animations';
import { SharedModule } from '$/app/shared/shared.module';
import { AlcomyValidators } from '$/app/shared/validators';
import { validateForm } from '$/app/utils';
import {
  AlcomyTheme,
  MedicalProfessional,
  MedicalProfessionalPhone
} from '$/models';
import { Logger } from '$shared/logger';
import { lookups } from '$shared/lookups';
import { Component, Input, OnInit, inject } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AlertController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { trim } from 'lodash';
import { MedicalProfessionalFormActions } from './medical-professional-form.actions';

@Component({
  selector: 'alc-medical-professional-form',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './medical-professional-form.component.html',
  animations: [expansionAnimation]
})
export class AlcMedicalProfessionalFormComponent implements OnInit {
  private readonly fb = inject(UntypedFormBuilder);
  private readonly overlay = inject(OverlayService);
  private readonly alert = inject(AlertController);
  private readonly store = inject(Store);

  protected form: UntypedFormGroup;

  @Input({ required: true }) theme: AlcomyTheme;
  @Input() residentId?: string;
  @Input() medicalProfessional: MedicalProfessional & {
    phones: MedicalProfessionalPhone[];
  };

  protected readonly lookups = lookups;

  protected get formPhones() {
    return this.form.get('phones') as UntypedFormArray;
  }

  ngOnInit() {
    this.createForm();
  }

  protected async close() {
    await this.overlay.dismissModal();
  }

  protected addPhone(ev: Event) {
    this.formPhones.push(this.newPhone());
    setTimeout(() => {
      ev.target['scrollIntoView']({
        behavior: 'smooth',
        block: 'center'
      });
    }, 100);
  }

  protected removePhone(index: number) {
    this.formPhones.removeAt(index);
  }

  protected async submit() {
    try {
      const medicalProfessional = validateForm<MedicalProfessional>(this.form);

      if (!trim(medicalProfessional.email)) {
        medicalProfessional.email = null;
      }

      if (!this.medicalProfessional) {
        this.store.dispatch(
          MedicalProfessionalFormActions.createMedicalProfessional({
            medicalProfessional: {
              ...medicalProfessional,
              residentId: this.residentId
            },
            params: {
              loading: 'Creating medical professional',
              toast: 'Medical professional created successfully!',
              onSuccess: () => this.close()
            }
          })
        );
      } else {
        this.store.dispatch(
          MedicalProfessionalFormActions.updateMedicalProfessional({
            id: this.medicalProfessional.id,
            changes: medicalProfessional,
            params: {
              loading: 'Updating medical professional',
              toast: 'Medical professional updated successfully!',
              onSuccess: () => this.close()
            }
          })
        );
      }
    } catch (error) {
      Logger.error('Error validating medical professional form', { error });

      this.overlay.showAlert(
        'Please check the highlighted fields and make sure they are filled in properly.',
        'Incorrect values'
      );
    }
  }

  private createForm() {
    this.form = this.fb.group({
      typeId: [this.medicalProfessional?.typeId || null, Validators.required],
      firstName: [this.medicalProfessional?.firstName || null],
      lastName: [
        this.medicalProfessional?.lastName || null,
        Validators.required
      ],
      email: [this.medicalProfessional?.email || null, AlcomyValidators.email],
      specialty: [this.medicalProfessional?.specialty || null],
      address1: [this.medicalProfessional?.address1 || null],
      address2: [this.medicalProfessional?.address2 || null],
      city: [this.medicalProfessional?.city || null],
      state: [this.medicalProfessional?.state || null],
      postalCode: [this.medicalProfessional?.postalCode || null],
      country: [
        { value: this.medicalProfessional?.country || 'US', disabled: true }
      ],
      phones: this.fb.array(
        (this.medicalProfessional?.phones || []).map((phone) =>
          this.newPhone(phone)
        )
      ),
      notes: this.medicalProfessional?.notes || null
    });
  }

  private newPhone(phone?: MedicalProfessionalPhone) {
    return this.fb.group({
      label: [phone?.label ?? null],
      number: [
        phone?.number ?? null,
        [Validators.required, Validators.minLength(10)]
      ],
      type: [phone?.type ?? 'mobile', Validators.required],
      isPrimary: [phone?.isPrimary ?? false]
    });
  }
}
