import { FeathersService } from '$/app/services/feathers.service';
import { PositionLogsApiActions } from '$/app/store/position-logs';
import { IPositionLog } from '$shared/services/position-log';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class PositionLogsApiService extends AbstractApiService<IPositionLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('position-logs', feathers, store, {
      entityName: 'positionLog',
      created: PositionLogsApiActions.positionLogsCreated,
      patched: PositionLogsApiActions.positionLogsPatched,
      removed: PositionLogsApiActions.positionLogsRemoved
    });
  }
}
