import { Medication, MedicationOrder, Params, Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { IResident } from '$shared/residents/residents.interface';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationDetailInfoPageActions = createActionGroup({
  source: 'Medication Detail Info Page',
  events: {
    'Delete Medication Inventory Item': props<{ id: string }>(),
    'Mark Inventory Empty': props<Update<IMedicationInventoryItem>>(),

    'Recalculate Inventory Item Remaining': (
      id: string,
      params: Params = { query: {} }
    ) => ({ id, params }),

    'Open New Inventory Item Form': props<{
      resident: IResident;
      medication: Medication;
      order?: MedicationOrder;
      medicationInventoryItem?: IMedicationInventoryItem;
    }>(),

    'Fetch Medication Detail Page Info': (params: Params = { query: {} }) => ({
      params
    }),

    'Fetch Resident': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),

    'Open Confirm Empty Inventory': props<{
      medicationInventoryId: string;
    }>(),

    'Delete Medication': props<{ id: string; params: Params }>(),

    'Flag Medication For Ordering': (
      id: string,
      params: Params = { query: {} }
    ) => {
      return {
        id,
        medication: { needsOrdering: true },
        params
      };
    },

    'Unflag Medication For Ordering': (
      id: string,
      params: Params = { query: {} }
    ) => {
      return {
        id,
        medication: { needsOrdering: false },
        params
      };
    },

    'Toggle Track Inventory': (
      id: string,
      medication: Partial<Medication>,
      params: Params = { query: {} }
    ) => ({
      id,
      medication,
      params
    }),

    'Discontinue Medication': (
      id: string,
      medication: Pick<Medication, 'discontinuedAt'>,
      params?: Params
    ) => ({
      id,
      medication,
      params
    }),

    'Hold Medication': (
      id: string,
      medication: Pick<Medication, 'heldAt' | 'holdTill'>,
      params?: Params
    ) => ({
      id,
      medication,
      params
    }),

    'Activate Medication': (
      id: string,
      medication: Medication,
      params: Params
    ) => ({
      id,
      medication,
      params
    }),

    'Upload Script Image': (
      id: string,
      imageUrl: string,
      params: Params = { query: {} }
    ) => {
      const $actions = [
        {
          uploadPrescriptionPicture: {
            imageUrl
          }
        }
      ];
      return {
        id,
        medication: {},
        params: {
          ...params,
          query: {
            ...params.query,
            $actions
          }
        }
      };
    },
    'Delete Script Image': (
      id: string,
      fileId: string,
      params: Params = { query: {} }
    ) => {
      const $actions = [
        {
          removeFile: {
            fileId
          }
        }
      ];
      return {
        id,
        medication: { scriptImageFileId: null },
        params: {
          ...params,
          query: {
            ...params.query,
            $actions
          }
        }
      };
    }
  }
});
