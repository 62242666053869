import { FeathersService } from '$/app/services/feathers.service';
import { CarePlanItemSchedulesWsActions } from '$/app/store/care-plan-item-schedules/care-plan-item-schedules.actions';
import { CarePlanItemSchedule, Paginated, Params } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CarePlanItemSchedulesApiService {
  private carePlanItemSchedulesService: Service<CarePlanItemSchedule>;

  constructor(feathers: FeathersService, store: Store) {
    this.carePlanItemSchedulesService = feathers.client.service(
      'care-plan-item-schedules'
    );

    this.carePlanItemSchedulesService.on('created', (carePlanItemSchedule) => {
      store.dispatch(
        CarePlanItemSchedulesWsActions.carePlanItemSchedulesCreated({
          carePlanItemSchedule
        })
      );
    });

    this.carePlanItemSchedulesService.on('patched', (carePlanItemSchedule) => {
      store.dispatch(
        CarePlanItemSchedulesWsActions.carePlanItemSchedulesPatched({
          carePlanItemSchedule
        })
      );
    });

    this.carePlanItemSchedulesService.on('removed', (carePlanItemSchedule) => {
      store.dispatch(
        CarePlanItemSchedulesWsActions.carePlanItemSchedulesRemoved({
          id: carePlanItemSchedule.id
        })
      );
    });
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<CarePlanItemSchedule>> {
    const promise = this.carePlanItemSchedulesService.find(params) as Promise<
      Paginated<CarePlanItemSchedule>
    >;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<CarePlanItemSchedule> {
    const promise = this.carePlanItemSchedulesService.get(id, params);
    return from(promise);
  }
}
