import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { IncidentReportResidentsEffects } from './incident-report-residents.effects';
import { incidentReportResidentsFeature } from './incident-report-residents.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(incidentReportResidentsFeature),
    EffectsModule.forFeature([IncidentReportResidentsEffects])
  ],
  providers: [IncidentReportResidentsEffects]
})
export class IncidentReportResidentsStoreModule {}
