import { createSelector } from '@ngrx/store';
import { medicationTasksFeature } from './medication-tasks.reducer';
import { State, medicationTasksAdapter } from './medication-tasks.state';

export const {
  selectMedicationTasksState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError,
  selectSkip
} = medicationTasksFeature;

export const { selectAll, selectTotal } = medicationTasksAdapter.getSelectors(
  selectMedicationTasksState
);

export const selectTotalRecords = createSelector(
  selectMedicationTasksState,
  (state: State): number => state.total
);
