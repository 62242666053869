import { AppInfo } from '$/app/services';
import { environment } from '$/environments/environment';
import { ILoggerTransportPayload, Logger } from '$shared/logger';
import { LoggerConfig } from '$shared/logger/logger-config';
import { TransportBase } from '$shared/logger/transport-base';
import { backgroundTask } from '$shared/utils';
import { Logtail } from '@logtail/browser';

const LOG_TAIL_TOKENS = {
  develop: 'wsvGnTwWp68AepowjnJZatKs',
  staging: 'KKyznT6KQzrSp5pF9u41xyBs',
  production: 'mVqdaJ6mapxfgaKDKeLKPeM2'
};

const SENTINEL = 'LOGTAIL_TRANSPORT_SENTINEL';

export class LogtailTransport extends TransportBase {
  private readonly logtail = this.createLogtail();

  log(level: string, message: string, payload: ILoggerTransportPayload): void {
    if (!this.logtail) {
      return;
    }

    const currentUrl = document?.location?.href;

    backgroundTask('Sending to Logtail', async () => {
      try {
        await this.logtail.log(message, level, {
          ...payload,
          currentUrl,
          appInfo: AppInfo
        });
      } catch (error) {
        if (LoggerConfig.activeEnvironment === 'develop') {
          return;
        }

        if (
          error?.message === 'Failed to fetch' ||
          payload?.metadata?.SENTINEL === SENTINEL
        ) {
          return;
        }

        Logger.error('Failed to log to Logtail', {
          error,
          level,
          message,
          SENTINEL
        });
      }
    });
  }

  private createLogtail() {
    const token = LOG_TAIL_TOKENS[environment.name || 'develop'];

    return token ? new Logtail(token, { throwExceptions: true }) : undefined;
  }
}
