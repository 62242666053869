import { DiagnosisFormPageActions } from '$/app/pages/residents/resident-detail/diagnosis-form/diagnosis-form.actions';
import {
  diagnosesAdapter,
  initialState
} from '$/app/store/diagnoses/diagnoses.state';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DiagnosesApiActions,
  DiagnosesGeneralActions,
  DiagnosesWsActions
} from './diagnoses.actions';

export const diagnosesFeature = createFeature({
  name: 'diagnoses',
  reducer: createReducer(
    initialState,

    on(
      DiagnosisFormPageActions.createDiagnosis,
      DiagnosisFormPageActions.updateDiagnosis,
      (state) => {
        return {
          ...state,
          loading: true
        };
      }
    ),

    on(DiagnosesApiActions.loadDiagnosesSuccess, (state, action) => {
      return diagnosesAdapter.setAll(action.diagnoses, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(DiagnosesApiActions.createDiagnosisSuccess, (state, action) => {
      return diagnosesAdapter.addOne(action.diagnosis, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(DiagnosesApiActions.updateDiagnosisSuccess, (state, action) => {
      return diagnosesAdapter.updateOne(action.diagnosis, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(DiagnosesApiActions.deleteDiagnosisSuccess, (state, action) => {
      return diagnosesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      DiagnosesApiActions.loadDiagnosesFail,
      DiagnosesApiActions.createDiagnosisFail,
      DiagnosesApiActions.updateDiagnosisFail,
      DiagnosesApiActions.deleteDiagnosisFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      DiagnosesWsActions.diagnosesCreated,
      DiagnosesWsActions.diagnosesPatched,
      (state, action) => {
        return diagnosesAdapter.upsertOne(action.diagnosis, state);
      }
    ),

    on(DiagnosesWsActions.diagnosesRemoved, (state, action) => {
      return diagnosesAdapter.removeOne(action.id, state);
    }),

    on(DiagnosesGeneralActions.clearDiagnoses, (state) => {
      return diagnosesAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }),

    on(DiagnosesGeneralActions.addDiagnoses, (state, action) => {
      return diagnosesAdapter.upsertMany(action.diagnoses, {
        ...state,
        loading: false,
        error: null
      });
    })
  )
});
