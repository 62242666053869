import { Action, createReducer, on } from '@ngrx/store';
import { GeolocationActions } from './geolocation.actions';
import { State, initialState } from './geolocation.state';

const reducer = createReducer(
  initialState,

  on(GeolocationActions.saveGeolocation, (state, action) => {
    return {
      ...state,
      geolocation: action.geolocation
    };
  })
);

export function geolocationReducer(
  state: State = initialState,
  action: Action
): State {
  return reducer(state, action);
}
