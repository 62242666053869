import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SeizureLogSymptomsEffects } from './seizure-log-symptoms.effects';
import { seizureLogSymptomsFeature } from './seizure-log-symptoms.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(seizureLogSymptomsFeature),
    EffectsModule.forFeature([SeizureLogSymptomsEffects])
  ],
  providers: [SeizureLogSymptomsEffects]
})
export class SeizureLogSymptomsStoreModule {}
