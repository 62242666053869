import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const FacilityUserPhonesListActions = createActionGroup({
  source: 'Facility User Phones Page',
  events: {
    'Load Facility User Phones': (params: Params = { query: {} }) => ({
      params
    }),

    'Get Facility User Phones': (params: Params = { query: {} }) => ({ params })
  }
});
