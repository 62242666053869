import { Diagnosis, Params } from '$/models';
import { AtLeast } from '$shared/types/utility-types';
import { createActionGroup, props } from '@ngrx/store';

export const DiagnosisFormPageActions = createActionGroup({
  source: 'Diagnosis Form Page',
  events: {
    'Create Diagnosis': props<{
      diagnosis: Diagnosis;
      params: Params;
    }>(),
    'Update Diagnosis': props<{
      id: string;
      changes: AtLeast<Diagnosis, 'levelId' | 'name'>;
      params: Params;
    }>()
  }
});
