import { ILibraryDocumentTemplatePacket, Paginated } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const LibraryDocumentTemplatePacketsApiActions = createActionGroup({
  source: 'Library Document Template Packets API',
  events: {
    // Success Actions
    'Load Library Document Template Packets Success': props<{
      libraryDocumentTemplatePackets: Paginated<ILibraryDocumentTemplatePacket>;
    }>(),
    'Get Library Document Template Packets Success': props<{
      libraryDocumentTemplatePackets: Paginated<ILibraryDocumentTemplatePacket>;
    }>(),
    'Fetch Library Document Template Packet Success': props<{
      libraryDocumentTemplatePacket: ILibraryDocumentTemplatePacket;
    }>(),
    'Create Library Document Template Packet Success': props<{
      libraryDocumentTemplatePacket: ILibraryDocumentTemplatePacket;
    }>(),
    'Update Library Document Template Packet Success': props<{
      libraryDocumentTemplatePacket: ILibraryDocumentTemplatePacket;
    }>(),
    'Delete Library Document Template Packet Success': props<{ id: string }>(),
    // Fail Actions
    'Load Library Document Template Packets Fail': props<{ error: Error }>(),
    'Get Library Document Template Packets Fail': props<{ error: Error }>(),
    'Fetch Library Document Template Packet Fail': props<{ error: Error }>(),
    'Create Library Document Template Packet Fail': props<{ error: Error }>(),
    'Update Library Document Template Packet Fail': props<{ error: Error }>(),
    'Delete Library Document Template Packet Fail': props<{ error: Error }>()
  }
});

export const LibraryDocumentTemplatePacketsWsActions = createActionGroup({
  source: 'Library Document Template Packets WS',
  events: {
    'Library Document Template Packet Created': props<{
      libraryDocumentTemplatePacket: ILibraryDocumentTemplatePacket;
    }>(),
    'Library Document Template Packet Patched': props<{
      libraryDocumentTemplatePacket: ILibraryDocumentTemplatePacket;
    }>(),
    'Library Document Template Packet Removed': props<{ id: string }>()
  }
});
