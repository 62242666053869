import { Params, Update } from '$/models';
import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentGroupDetailActions = createActionGroup({
  source: 'Resident Group Detail Page',
  events: {
    'Fetch Resident Group': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Update Resident Group': props<Update<ResidentGroup>>(),
    'Delete Resident Group': props<{ id: string }>()
  }
});
