import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CareTasksEffects } from './care-tasks.effects';
import { careTasksFeature } from './care-tasks.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(careTasksFeature),
    EffectsModule.forFeature([CareTasksEffects])
  ],
  providers: [CareTasksEffects]
})
export class CareTasksStoreModule {}
