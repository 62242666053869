<ion-header class="ion-no-border">
  <ion-toolbar color="transparent">
    <div class="mb-3">
      <h1
        class="mb-3 !whitespace-normal px-5 text-xl"
        [ngClass]="danger ? 'text-danger' : 'text-very-dark'"
      >
        {{ title }}
      </h1>
      @if (subtitle) {
        <h2
          class="mb-3 !whitespace-normal px-5 text-base"
          [ngClass]="danger ? 'text-danger' : 'text-dark'"
        >
          {{ subtitle }}
        </h2>
      }
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="flex flex-col gap-6 px-5 pb-6 pt-0">
    <ng-content>
      <div class="text-medium-dark" [innerHTML]="message | html"></div>
    </ng-content>

    @if (type === 'confirm-keyword') {
      <p class="m-0 text-medium-dark">
        If you wish to proceed, for your safety, type
        <ion-text
          class="font-bold"
          [color]="danger ? 'danger' : ('accent' | alcTheme)"
        >
          {{ keyword }}
        </ion-text>
        in the input field below, then press the
        <b>
          {{ primaryButtonText }}
        </b>
        button. Otherwise, press <b>{{ secondaryButtonText }}</b
        >.
      </p>
      <mat-form-field>
        <input
          matInput
          type="text"
          placeholder="Type the keyword here"
          [formControl]="keywordControl"
        />
        @if (keywordControl.invalid && keywordControl.touched) {
          <mat-error [alcError]="keywordControl" />
        }
      </mat-form-field>
    }
  </div>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button
        shape="round"
        fill="solid"
        [color]="danger ? 'danger' : ('primary' | alcTheme)"
        (click)="submit()"
      >
        <ion-label>{{ primaryButtonText }}</ion-label>
      </ion-button>
      @if (['confirm', 'confirm-keyword'].includes(type)) {
        <ion-button
          shape="round"
          fill="outline"
          [color]="danger ? 'danger' : ('primary' | alcTheme)"
          (click)="close()"
        >
          <ion-label>{{ secondaryButtonText }}</ion-label>
        </ion-button>
      }
    </div>
  </ion-toolbar>
</ion-footer>
