import { Table } from '$/models/data/base';
import { PhoneTypeId } from '$shared/lookups/phone-types.lookup';

export class MedicalProfessionalPhone extends Table {
  orgId: string;
  facilityId: string;
  medicalProfessionalId: string;
  label: string;
  number: string;
  type: PhoneTypeId;
  isPrimary: boolean;
}
