import { AlcErrorComponent } from '$/app/shared/components/alc-error.component';
import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  MatButtonToggleChange,
  MatButtonToggleModule
} from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface AlcButtonToggleGroupOption<T> {
  value: T;
  label: string;
  testId?: string;
}
export interface AlcButtonToggleGroupChange<T> {
  value: T;
}

@Component({
  selector: 'alc-button-toggle-group',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    AlcErrorComponent
  ],
  templateUrl: './button-toggle-group.component.html'
})
export class AlcButtonToggleGroupComponent<T> implements ControlValueAccessor {
  label = input<string>();
  options = input.required<AlcButtonToggleGroupOption<T>[]>();

  alcChange = output<AlcButtonToggleGroupChange<T>>();

  protected value: T;
  protected isDisabled: boolean;

  constructor(private control: NgControl) {
    this.control.valueAccessor = this;
  }

  protected get hasRequiredError(): boolean {
    return this.control.touched && this.control.errors?.required;
  }

  protected onButtonToggleChange(event: MatButtonToggleChange): void {
    this.value = event.value;
    this.onChange(event.value);
    this.alcChange.emit({
      value: event.value
    });
  }

  protected onChange: (value: T) => void = () => {};
  protected onTouch: () => void = () => {};

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
