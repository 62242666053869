import { Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { createActionGroup, props } from '@ngrx/store';

export const InventoryPickerPageActions = createActionGroup({
  source: 'Inventory Picker Page',
  events: {
    'Change Inventory Item': props<Update<IMedicationInventoryItem>>()
  }
});
