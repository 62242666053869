import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { MedicationOrdersListFilters } from '../medication-orders-list.vmodel';

export const MedicationOrdersListFilterPageActions = createActionGroup({
  source: 'Medication Orders List Filter Page',
  events: {
    'Filter Changed': (
      filters: MedicationOrdersListFilters
    ): {
      filters: MedicationOrdersListFilters;
      queryParams: Record<string, any>;
    } => {
      return {
        filters,
        queryParams: {
          statuses: filters.statuses,
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
