export function readFileAsDataUrl(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      // Strip off the data: url prefix to get just the base64-encoded bytes
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function readFileAsText(file: File | Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export function stripDataUrlPrefix(dataUrl: string): string {
  if (!dataUrl.startsWith('data:')) {
    throw new Error('Invalid data url');
  }

  return dataUrl.split(',')[1];
}
