import { FeathersService } from '$/app/services/feathers.service';
import { OxygenLogsApiActions } from '$/app/store/oxygen-logs';
import { IOxygenLog } from '$shared/services/oxygen-log';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class OxygenLogsApiService extends AbstractApiService<IOxygenLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('oxygen-logs', feathers, store, {
      entityName: 'oxygenLog',
      created: OxygenLogsApiActions.oxygenLogsCreated,
      patched: OxygenLogsApiActions.oxygenLogsPatched,
      removed: OxygenLogsApiActions.oxygenLogsRemoved
    });
  }
}
