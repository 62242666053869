import { sortByDatetime } from '$/app/utils';
import { TMedicationTaskAmount } from '$shared/medications/medication-task-amount.schema';
import { createEntityAdapter } from '@ngrx/entity';

export const medicationTaskAmountsAdapter =
  createEntityAdapter<TMedicationTaskAmount>({
    sortComparer: sortByDatetime('createdAt')
  });

export const initialState = medicationTaskAmountsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
