import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IPaginationInfo } from '$/models';
import { IBehaviorLog } from '$shared/services/behavior-log';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface BehaviorLogPageQueries {
  all: IPaginationInfo;
}

export const behaviorLogsAdapter = createEntityAdapter<IBehaviorLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<IBehaviorLog> {
  pagination: BehaviorLogPageQueries;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = behaviorLogsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
