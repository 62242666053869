import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MedicationsEffects } from '$/app/store/medications/medications.effects';
import { medicationsFeature } from '$/app/store/medications/medications.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(medicationsFeature),
    EffectsModule.forFeature([MedicationsEffects])
  ],
  providers: [MedicationsEffects]
})
export class MedicationsStoreModule {}
