import { OmittedMedication, Params, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥👈 USER ACTIONS
//

// Fetch OmittedMedication
export const fetchOmittedMedication = createAction(
  '[OmittedMedication Detail Page] Fetch OmittedMedication',
  (id: string, params: Params = { query: {} }) => ({ id, params })
);

// Update OmittedMedication
export const updateOmittedMedication = createAction(
  '[OmittedMedication Detail Page] Update OmittedMedication',
  props<Update<OmittedMedication>>()
);

// Delete OmittedMedication
export const deleteOmittedMedication = createAction(
  '[OmittedMedication Detail Page] Delete OmittedMedication',
  props<{ id: string }>()
);
