import { ResidentContactPhone } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearResidentContactPhones = createAction(
  'Clear Resident Contact Phones'
);

export const addResidentContactPhones = createAction(
  'Add Resident Contact Phones',
  props<{ residentContactPhones: ResidentContactPhone[] }>()
);
