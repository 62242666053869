import { FacilityUserGroupMember, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserGroupMemberPickerActions = createActionGroup({
  source: 'Facility User Group Member Picker',
  events: {
    'Get Facility User Group Members': props<{ params: Params }>(),

    'Create Facility User Group Member': (
      facilityUserGroupMember: FacilityUserGroupMember,
      params: Params = { query: {} }
    ) => ({ facilityUserGroupMember, params }),

    'Delete Facility User Group Member': props<{ id: string }>()
  }
});
