import { Logger } from '$shared/logger';
import { backgroundTask } from '$shared/utils';
import { OverlayService } from './index';

export function dismissAll(this: OverlayService) {
  backgroundTask('dismissAll', async () => {
    await this.dismissOverlay(
      () => this.modalController.getTop(),
      () => this.modalController.dismiss()
    );

    await this.dismissOverlay(
      () => this.alertController.getTop(),
      () => this.alertController.dismiss()
    );

    await this.dismissOverlay(
      () => this.toastController.getTop(),
      () => this.toastController.dismiss()
    );

    await this.dismissOverlay(
      () => this.menuController.getOpen(),
      () => this.menuController.close()
    );

    await this.dismissOverlay(
      () => this.popController.getTop(),
      () => this.popController.dismiss()
    );

    await this.dismissOverlay(
      () => this.loadingController.getTop(),
      () => this.loadingController.dismiss()
    );

    await this.dismissOverlay(
      () => this.actionSheetController.getTop(),
      () => this.actionSheetController.dismiss()
    );
  });
}

export async function dismissOverlay(
  this: OverlayService,
  getTop: () => any,
  close: () => Promise<boolean>
) {
  try {
    let top = await getTop();

    while (top) {
      const closeResult = await close();
      Logger.assert(
        closeResult,
        'Dismissing the topmost overlay returned a falsy result',
        { top }
      );

      top = await getTop();
    }
  } catch (error) {
    Logger.error('Error dismissing overlay', error);
  }
}
