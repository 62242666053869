import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { createAction, props } from '@ngrx/store';

export const clearMedicationInventoryItems = createAction(
  '[MedicationInventoryItems] Clear MedicationInventoryItems'
);

export const addMedicationInventoryItems = createAction(
  'Add Medication Inventory Items',
  props<{ medicationInventoryItems: IMedicationInventoryItem[] }>()
);
