import { OmittedMedication } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearOmittedMedications = createAction(
  '[OmittedMedications] Clear OmittedMedications'
);

export const addOmittedMedications = createAction(
  'Add OmittedMedications',
  props<{ omittedMedications: OmittedMedication[] }>()
);
