import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { MedicationOrderItem } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MedicationOrderItemsWSActions } from './medication-order-items.actions';

@Injectable({ providedIn: 'root' })
export class MedicationOrderItemsApiService extends AbstractApiService<MedicationOrderItem> {
  constructor(feathers: FeathersService, store: Store) {
    super('medication-order-items', feathers, store, {
      entityName: 'medicationOrderItem',
      created: MedicationOrderItemsWSActions.medicationOrderItemsCreated,
      patched: MedicationOrderItemsWSActions.medicationOrderItemsPatched,
      removed: MedicationOrderItemsWSActions.medicationOrderItemsRemoved
    });
  }
}
