import { FeathersService } from '$/app/services/feathers.service';
import { DocumentTemplatePacketTemplatesWsActions } from '$/app/store/document-template-packet-templates/document-template-packet-templates.actions';
import { IDocumentTemplatePacketTemplate } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class DocumentTemplatePacketTemplatesApiService extends AbstractApiService<IDocumentTemplatePacketTemplate> {
  constructor(feathers: FeathersService, store: Store) {
    super('document-template-packet-templates', feathers, store, {
      entityName: 'documentTemplatePacketTemplate',
      created:
        DocumentTemplatePacketTemplatesWsActions.documentTemplatePacketTemplateCreated,
      patched:
        DocumentTemplatePacketTemplatesWsActions.documentTemplatePacketTemplatePatched,
      removed:
        DocumentTemplatePacketTemplatesWsActions.documentTemplatePacketTemplateRemoved
    });
  }
}
