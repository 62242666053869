import { AlcCommonModule } from '$/app/shared/alc-common.module';
import {
  AlcMenuButtonComponent,
  AlcMenuButtonOption
} from '$/app/shared/components/menu-button/menu-button.component';
import { Component, booleanAttribute, input, output } from '@angular/core';

@Component({
  standalone: true,
  selector: 'alc-general-list-item',
  templateUrl: './general-list-item.component.html',
  imports: [AlcCommonModule, AlcMenuButtonComponent],
  host: {
    class: 'block'
  }
})
export class AlcGeneralListItemComponent<T extends string> {
  public readonly title = input.required<string>();
  public readonly subtitle = input<string>();
  public readonly inCard = input(true);
  public readonly menuButtonTestId = input<string>();
  public readonly menuOptions = input<AlcMenuButtonOption<T>[]>();
  public readonly lines = input<'full' | 'inset' | 'none' | undefined>('full');

  public readonly removePaddingStart = input(false, {
    transform: booleanAttribute
  });

  public readonly removePaddingEnd = input(false, {
    transform: booleanAttribute
  });

  public readonly menuOptionSelected = output<T>();
}
