import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { SharedModule } from '$/app/shared/shared.module';
import { AlcModal } from '$/lib/ui/alcomy-modal';
import { AlcomyTheme } from '$/models';
import { Component, Input, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'alc-date-range-picker-page',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './date-range-picker.page.html',
  styleUrls: ['./date-range-picker.page.scss']
})
export class DateRangePickerPage extends AlcModal implements OnInit {
  private readonly fb = inject(UntypedFormBuilder);

  @Input() theme: AlcomyTheme = 'residents';
  @Input() btnLabel = 'Submit';
  @Input() dateRange: IDateRangePickerValue;

  protected control: UntypedFormControl;

  ngOnInit() {
    this.control = this.fb.control(this.dateRange);
  }

  submit() {
    if (!this.control.errors) {
      this.close({ ...this.control.value });
    }
  }
}
