import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import { PrnAmountsApiActions, PrnAmountsGeneralActions } from './actions';
import { initialState, prnAmountsAdapter } from './prn-amounts.state';

export const prnAmountsFeature = createFeature({
  name: 'prnAmounts',
  reducer: createReducer(
    initialState,

    on(PrnAmountsApiActions.loadPrnAmountsSuccess, (state, action) => {
      return prnAmountsAdapter.setAll(action.prnAmounts, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(PrnAmountsApiActions.getPrnAmountsSuccess, (state, action) => {
      return prnAmountsAdapter.upsertMany(action.prnAmounts, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(PrnAmountsApiActions.fetchPrnAmountSuccess, (state, action) => {
      return prnAmountsAdapter.upsertOne(action.prnAmount, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(PrnAmountsApiActions.createPrnAmountSuccess, (state, action) => {
      const prnAmounts = castArray(action.prnAmount);

      return prnAmountsAdapter.addMany(prnAmounts, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(PrnAmountsApiActions.updatePrnAmountSuccess, (state, action) => {
      return prnAmountsAdapter.upsertOne(action.prnAmount, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(PrnAmountsApiActions.deletePrnAmountSuccess, (state, action) => {
      return prnAmountsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      PrnAmountsApiActions.loadPrnAmountsFail,
      PrnAmountsApiActions.getPrnAmountsFail,
      PrnAmountsApiActions.fetchPrnAmountFail,
      PrnAmountsApiActions.createPrnAmountFail,
      PrnAmountsApiActions.updatePrnAmountFail,
      PrnAmountsApiActions.deletePrnAmountFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      PrnAmountsApiActions.prnAmountsCreated,
      PrnAmountsApiActions.prnAmountsPatched,
      (state, action) => {
        return prnAmountsAdapter.upsertOne(action.prnAmount, {
          ...state
        });
      }
    ),

    on(PrnAmountsApiActions.prnAmountsRemoved, (state, action) => {
      return prnAmountsAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(PrnAmountsGeneralActions.addPrnAmounts, (state, action) => {
      return prnAmountsAdapter.upsertMany(action.prnAmounts, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(PrnAmountsGeneralActions.clearPrnAmounts, (state) => {
      return prnAmountsAdapter.removeAll({
        ...state,
        total: 0,
        skip: 0,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
