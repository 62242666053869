import { IPaginationInfo } from '$/models';
import { Action, ActionCreator } from '@ngrx/store';
import { forOwn, isEmpty } from 'lodash';

export interface NormalActionOptions {
  payloadKey?: string;
  paginated?: IPaginationInfo;
  dispatchOnEmpty?: boolean;
  singleRecord?: boolean;
  hasPayload?: boolean;
  metadata?: Record<string, any>;
}

/**
 * NormalizedAction is a wrapper around an action creator that allows for
 * additional options to be passed in.
 */
export class NormalizedAction {
  action: ActionCreator;
  payloadKey?: string;
  paginated?: IPaginationInfo;
  dispatchOnEmpty?: boolean;
  singleRecord?: boolean;
  hasPayload?: boolean;
  metadata?: Record<string, any>;

  constructor(action: ActionCreator, options?: NormalActionOptions) {
    this.action = action;
    this.payloadKey = options?.payloadKey;
    this.paginated = options?.paginated;
    this.dispatchOnEmpty = options?.dispatchOnEmpty ?? false;
    this.singleRecord = options?.singleRecord ?? false;
    this.hasPayload = options?.hasPayload ?? true;
    this.metadata = options?.metadata;
  }
}

/**
 * Loops though the normalized data
 */
export const createActionsFromNormalizedData = (
  normalizedData: { [key: string]: any[] },
  actionMap: Record<string, ActionCreator<any> | NormalizedAction>
): Action[] => {
  const actions = [];

  forOwn(actionMap, (action, key) => {
    let normalizedAction: NormalizedAction;
    // Convert action to NormalizedAction with defaults
    if (!(action instanceof NormalizedAction)) {
      normalizedAction = new NormalizedAction(action, {
        payloadKey: key
      });
    } else {
      normalizedAction = action;
      // Set defaults if not set in normalizedAction
      normalizedAction.payloadKey = normalizedAction.payloadKey || key;
    }

    // dispatchOnEmpty is meant to allow an action to be dispatched even if
    // the resulting data is empty
    if (
      (!isEmpty(normalizedData[key]) || normalizedAction.dispatchOnEmpty) &&
      normalizedAction.hasPayload
    ) {
      let data;

      // By default the payload will contain an array of entities. However, if the
      // original request data contains a paginated object we may want that info in
      // the store. The paginated option allows us to make that pagination info
      // available in the action payload as a pagination object
      if (!normalizedAction.singleRecord && normalizedAction.paginated) {
        data = {
          ...normalizedAction.paginated,
          data: normalizedData[normalizedAction.payloadKey] || []
        };
      } else {
        // Each entity in the normalized data is represented as an array.
        // However, sometimes the payload of an action needs to be represented
        // as a single object. For example, a fetch call should return 1 record.
        // However, that record, when normalized, is represented as an array.
        // The action that handles a successful fetch api call needs to return a
        // single record to the reducer. The singleRecord option allows us to do that
        if (normalizedAction.singleRecord) {
          data = normalizedData[key][0] || {};
        } else {
          data = normalizedData[key] || [];
        }
      }

      // Metadata are additional properties we want to make available in the action payload
      // This metadata may be useful for the reducer/other effects to do further processing.
      // The following merges the metadata into payload if it exists
      const metadata = normalizedAction.metadata
        ? { metadata: normalizedAction.metadata }
        : {};

      const payload = Object.assign(
        {},
        { [normalizedAction.payloadKey]: data },
        metadata
      );

      actions.push(normalizedAction.action(payload));
    } else if (!normalizedAction.hasPayload) {
      actions.push(normalizedAction.action({}));
    }
  });

  return actions;
};
