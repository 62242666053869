import { FeathersService } from '$/app/services/feathers.service';
import { RolesApiActions } from '$/app/store/roles/actions';
import { toUpdated } from '$/app/utils';
import { Params, Role, Update } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RolesApiService {
  private rolesService: Service<Role>;

  constructor(feathers: FeathersService, store: Store) {
    this.rolesService = feathers.client.service('roles');

    this.rolesService.on('created', (role) => {
      store.dispatch(RolesApiActions.rolesCreated({ role }));
    });

    this.rolesService.on('patched', (role) => {
      store.dispatch(RolesApiActions.rolesPatched({ role }));
    });

    this.rolesService.on('removed', (role) => {
      store.dispatch(RolesApiActions.rolesRemoved({ id: role.id }));
    });
  }

  getAll(params: Params = { query: {} }): Observable<Role[]> {
    const promise = this.rolesService.find(params) as Promise<Role[]>;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<Role> {
    const promise = this.rolesService.get(id, params);
    return from(promise);
  }

  create(role: Role, params: Params = { query: {} }): Observable<Role> {
    const promise = this.rolesService.create(role, params);
    return from(promise);
  }

  patch(id: string, role: Partial<Role>): Observable<Update<Role>> {
    const promise = this.rolesService.patch(id, role);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string): Observable<Role> {
    const promise = this.rolesService.remove(id);
    return from(promise);
  }
}
