import { Paginated } from '$/models';
import { IBloodPressureLog } from '$shared/services/blood-pressure-log';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BloodPressureLogsApiActions = createActionGroup({
  source: 'Blood Pressure Logs API',
  events: {
    'Load Blood Pressure Logs Success': props<{
      bloodPressureLogs: Paginated<IBloodPressureLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Blood Pressure Logs Success': props<{
      bloodPressureLogs: Paginated<IBloodPressureLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Create Blood Pressure Log Success': props<{
      bloodPressureLog: IBloodPressureLog;
    }>(),
    'Update Blood Pressure Log Success': props<{
      bloodPressureLog: IBloodPressureLog;
    }>(),
    'Delete Blood Pressure Log Success': props<{ id: string }>(),

    'Load Blood Pressure Logs Fail': props<{ error: Error }>(),
    'Get Blood Pressure Logs Fail': props<{ error: Error }>(),
    'Create Blood Pressure Log Fail': props<{ error: Error }>(),
    'Update Blood Pressure Log Fail': props<{ error: Error }>(),
    'Delete Blood Pressure Log Fail': props<{ error: Error }>()
  }
});

export const BloodPressureLogsWsActions = createActionGroup({
  source: 'Blood Pressure Logs WS',
  events: {
    'Blood Pressure Logs Created': props<{
      bloodPressureLog: IBloodPressureLog;
    }>(),
    'Blood Pressure Logs Patched': props<{
      bloodPressureLog: IBloodPressureLog;
    }>(),
    'Blood Pressure Logs Removed': props<{ id: string }>()
  }
});

export const BloodPressureLogsGeneralActions = createActionGroup({
  source: 'Blood Pressure Logs General',
  events: {
    'Clear Blood Pressure Logs': emptyProps(),
    'Add Blood Pressure Logs': props<{
      bloodPressureLogs: IBloodPressureLog[];
    }>()
  }
});
