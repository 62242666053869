import { createFeature, createReducer, on } from '@ngrx/store';

import { ShiftNotesFilterPageActions } from '../shift-notes-filter/shift-notes-filter.actions';
import { initialState } from './shift-notes.state';

export const uiShiftNotesPageFeature = createFeature({
  name: 'uiShiftNotesPage',
  reducer: createReducer(
    initialState,
    on(ShiftNotesFilterPageActions.filterChanged, (state, action) => {
      return {
        ...state,
        filters: action.filters
      };
    })
  )
});
