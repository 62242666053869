import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const WeightLogsPageActions = createActionGroup({
  source: 'Weight Log Page',
  events: {
    'Load Weight Logs': (params: Params) => ({ params }),
    'Get Weight Logs': (params: Params) => ({ params }),
    'Delete Weight Log': props<{ id: string; params?: Params }>()
  }
});
