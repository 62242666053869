import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { InstalledLibraryCollectionsEffects } from './installed-library-collections.effects';
import { installedLibraryCollectionsFeature } from './installed-library-collections.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(installedLibraryCollectionsFeature),
    EffectsModule.forFeature([InstalledLibraryCollectionsEffects])
  ],
  providers: [InstalledLibraryCollectionsEffects]
})
export class InstalledLibraryCollectionsStoreModule {}
