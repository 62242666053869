import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { BloodPressureLogsPageActions } from '$/app/pages/notebook/shared/logs/blood-pressure-log/blood-pressure-log.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  BloodPressureLogsApiActions,
  BloodPressureLogsGeneralActions,
  BloodPressureLogsWsActions
} from './blood-pressure-logs.actions';
import {
  BloodPressureLogPageQueries,
  bloodPressureLogsAdapter,
  initialState
} from './blood-pressure-logs.state';
import { BloodPressureLogFormPageActions } from '$/app/shared/pages/forms/log-forms/blood-pressure-log-form/blood-pressure-log-form.actions';

export const bloodPressureLogsFeature = createFeature({
  name: 'bloodPressureLogs',
  reducer: createReducer(
    initialState,

    on(
      BloodPressureLogsPageActions.loadBloodPressureLogs,
      BloodPressureLogsPageActions.getBloodPressureLogs,
      BloodPressureLogFormPageActions.createBloodPressureLog,
      BloodPressureLogFormPageActions.updateBloodPressureLog,
      BloodPressureLogsPageActions.deleteBloodPressureLog,
      ResidentMedicationTasksPageActions.deleteBloodPressureLog,
      MissedMedicationsPageActions.deleteBloodPressureLog,
      RoutineMarDetailPageActions.deleteBloodPressureLog,
      RoutineMarListPageActions.deleteBloodPressureLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      BloodPressureLogsApiActions.loadBloodPressureLogsSuccess,
      (state, action) => {
        const queryType = action.metadata?.pageQueryType;
        const pagination = getPaginationData<BloodPressureLogPageQueries>(
          state,
          action.bloodPressureLogs,
          queryType
        );
        const data = getRecords(action.bloodPressureLogs);

        return bloodPressureLogsAdapter.setAll(data, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      BloodPressureLogsApiActions.getBloodPressureLogsSuccess,
      (state, action) => {
        const queryType = action.metadata?.pageQueryType;
        const pagination = getPaginationData<BloodPressureLogPageQueries>(
          state,
          action.bloodPressureLogs,
          queryType
        );
        const data = getRecords(action.bloodPressureLogs);

        return bloodPressureLogsAdapter.upsertMany(data, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(BloodPressureLogsApiActions.createBloodPressureLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(BloodPressureLogsApiActions.updateBloodPressureLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(
      BloodPressureLogsApiActions.deleteBloodPressureLogSuccess,
      (state, action) =>
        bloodPressureLogsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      BloodPressureLogsApiActions.loadBloodPressureLogsFail,
      BloodPressureLogsApiActions.getBloodPressureLogsFail,
      BloodPressureLogsApiActions.createBloodPressureLogFail,
      BloodPressureLogsApiActions.updateBloodPressureLogFail,
      BloodPressureLogsApiActions.deleteBloodPressureLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      BloodPressureLogsWsActions.bloodPressureLogsCreated,
      BloodPressureLogsWsActions.bloodPressureLogsPatched,
      (state, action) => {
        return bloodPressureLogsAdapter.upsertOne(
          action.bloodPressureLog,
          state
        );
      }
    ),

    on(BloodPressureLogsWsActions.bloodPressureLogsRemoved, (state, action) => {
      return bloodPressureLogsAdapter.removeOne(action.id, state);
    }),

    on(
      BloodPressureLogsGeneralActions.addBloodPressureLogs,
      (state, action) => {
        return bloodPressureLogsAdapter.upsertMany(action.bloodPressureLogs, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(BloodPressureLogsGeneralActions.clearBloodPressureLogs, () => {
      return bloodPressureLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
