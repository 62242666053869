import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { WeightLogsPageActions } from '$/app/pages/notebook/shared/logs/weight-log/weight-log.actions';
import { EffectHelpersService } from '$/app/services';
import { WeightLogsApiService } from '$/app/services/api/weight-log.service';
import { WeightLogFormPageActions } from '$/app/shared/pages/forms/log-forms/weight-log-form/weight-log-form.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { WeightLog } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { WeightLogsApiActions } from './actions';

@Injectable()
export class WeightLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly weightLogsService = inject(WeightLogsApiService);

  loadWeightLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WeightLogsPageActions.loadWeightLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.weightLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('weightLogs', logs);
            responseData.setPrimaryAction(
              WeightLogsApiActions.loadWeightLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getWeightLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WeightLogsPageActions.getWeightLogs,
        LogsPageActions.getWeightLogs,
        GivePrnFormActions.getBehaviorLogs
      ),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.weightLogsService.getAll(action.params).pipe(
          getAllPages(this.weightLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('weightLogs', logs);
            responseData.setPrimaryAction(
              WeightLogsApiActions.getWeightLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createWeightLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WeightLogFormPageActions.createWeightLog),
      exhaustMap((action) => {
        return this.weightLogsService.create(action.weightLog).pipe(
          tap((data: WeightLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((weightLog: WeightLog) =>
            WeightLogsApiActions.createWeightLogSuccess({
              weightLog
            })
          ),
          catchError((error) =>
            of(
              WeightLogsApiActions.createWeightLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateWeightLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WeightLogFormPageActions.updateWeightLog),
      switchMap((action) => {
        return this.weightLogsService.patch(action.id, action.changes).pipe(
          tap((data: WeightLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((weightLog: WeightLog) => {
            return WeightLogsApiActions.updateWeightLogSuccess({
              weightLog
            });
          }),
          catchError((error) => {
            return of(WeightLogsApiActions.updateWeightLogFail({ error }));
          })
        );
      })
    );
  });

  deleteWeightLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WeightLogsPageActions.deleteWeightLog,
        ResidentMedicationTasksPageActions.deleteWeightLog,
        MissedMedicationsPageActions.deleteWeightLog,
        RoutineMarDetailPageActions.deleteWeightLog,
        RoutineMarListPageActions.deleteWeightLog,
        GivePrnFormActions.deleteWeightLog,
        CompleteCareTaskModalActions.deleteWeightLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete weight log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.weightLogsService.delete(action.id, action.params);
        },
        onSuccess: (weightLog: WeightLog) =>
          WeightLogsApiActions.deleteWeightLogSuccess({
            id: weightLog.id
          }),
        onError: (error) => WeightLogsApiActions.deleteWeightLogFail({ error })
      })
    );
  });
}
