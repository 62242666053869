import { MedicationTask, Paginated, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MedicationTasksApiActions = createActionGroup({
  source: 'Medication Tasks API',
  events: {
    'Load Medication Tasks Success': props<{
      medicationTasks: Paginated<MedicationTask>;
    }>(),
    'Get Medication Tasks Success': props<{
      medicationTasks: Paginated<MedicationTask>;
    }>(),
    'Fetch Medication Task Success': props<{
      medicationTask: MedicationTask;
    }>(),
    'Create Medication Task Success': props<{
      medicationTask: MaybeArray<MedicationTask>;
    }>(),
    'Update Medication Task Success': props<{
      medicationTask: Update<MedicationTask>;
    }>(),
    'Update Multiple Medication Tasks Success': props<{
      medicationTasks: Update<MedicationTask>[];
    }>(),
    'Delete Medication Task Success': props<{ id: string }>(),

    'Load Medication Tasks Fail': props<{ error: Error }>(),
    'Get Medication Tasks Fail': props<{ error: Error }>(),
    'Fetch Medication Task Fail': props<{ error: Error }>(),
    'Create Medication Task Fail': props<{ error: Error }>(),
    'Update Medication Task Fail': props<{ error: Error }>(),
    'Update Multiple Medication Tasks Fail': props<{ error: Error }>(),
    'Delete Medication Task Fail': props<{ error: Error }>()
  }
});

export const MedicationTasksWsActions = createActionGroup({
  source: 'Medication Tasks WS',
  events: {
    'Medication Tasks Created': props<{ medicationTask: MedicationTask }>(),
    'Medication Tasks Patched': props<{ medicationTask: MedicationTask }>(),
    'Medication Tasks Removed': props<{ id: string }>(),

    'Medication Tasks Batch Created': props<{
      medicationTasks: MedicationTask[];
    }>(),
    'Medication Tasks Batch Patched': props<{
      medicationTasks: MedicationTask[];
    }>(),
    'Medication Tasks Batch Removed': props<{ ids: string[] }>()
  }
});

export const MedicationTasksGeneralActions = createActionGroup({
  source: 'Medication Tasks General',
  events: {
    'Clear Medication Tasks': emptyProps(),
    'Add Medication Tasks': props<{ medicationTasks: MedicationTask[] }>()
  }
});
