import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IBloodPressureLog } from '$shared/services/blood-pressure-log';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { bloodPressureLogsFeature } from './blood-pressure-logs.reducer';
import { bloodPressureLogsAdapter } from './blood-pressure-logs.state';

export const {
  selectBloodPressureLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = bloodPressureLogsFeature;

export const { selectAll, selectTotal } = bloodPressureLogsAdapter.getSelectors(
  selectBloodPressureLogsState
);

export const selectPagination = (query) => {
  return createSelector(selectBloodPressureLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentBloodPressureLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (bpLogs, residentId): IBloodPressureLog[] => {
    if (isEmpty(bpLogs) || !residentId) {
      return [];
    }

    return bpLogs.filter((bpLog) => {
      return bpLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<IBloodPressureLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IBloodPressureLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IBloodPressureLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const computedMetrics = logs.reduce(
      (acc, log) => {
        acc.count += 1;
        acc.sumSys += log.systolic;
        acc.sumDia += log.diastolic;
        acc.sumPulse += log.pulse;
        acc.avgSys = Math.round(acc.sumSys / acc.count);
        acc.avgDia = Math.round(acc.sumDia / acc.count);
        acc.avgPulse = Math.round(acc.sumPulse / acc.count);

        return acc;
      },
      {
        count: 0,
        sumSys: 0,
        sumDia: 0,
        sumPulse: 0,
        avgSys: 0,
        avgDia: 0,
        avgPulse: 0
      }
    );

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: computedMetrics?.count || 0
      },
      {
        type: 'value',
        label: 'Avg. Systolic',
        data: (computedMetrics?.avgSys || 0).toFixed(0)
      },
      {
        type: 'value',
        label: 'Avg. Diastolic',
        data: (computedMetrics?.avgDia || 0).toFixed(0)
      },
      {
        type: 'value',
        label: 'Avg. Pulse',
        data: (computedMetrics?.avgPulse || 0).toFixed(0)
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
