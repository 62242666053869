import { createStoreActions } from '$/lib/create-store-actions';
import { IMedicationDestructionItem } from '$shared/services/medication-destruction-items.schema';
import { props } from '@ngrx/store';

export const {
  MedicationDestructionItemsApiActions,
  MedicationDestructionItemsWsActions,
  MedicationDestructionItemsGeneralActions
} = createStoreActions(
  'Medication Destruction Item',
  'Medication Destruction Items',
  props<{ medicationDestructionItem: IMedicationDestructionItem }>(),
  props<{ medicationDestructionItems: IMedicationDestructionItem[] }>()
);
