import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullSymbol',
  standalone: true
})
export class AlcNullSymbolPipe implements PipeTransform {
  transform(value: string | number, symbol: string): string | number {
    return value || symbol;
  }
}
