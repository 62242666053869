import { FeathersService } from '$/app/services/feathers.service';
import { ResidentGroupMembersWsActions } from '$/app/store/resident-group-members/actions/resident-group-members-api.actions';
import { ResidentGroupMember } from '$/models/data/entities/residents/resident-group-member.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class ResidentGroupMembersApiService extends AbstractApiService<ResidentGroupMember> {
  constructor(feathers: FeathersService, store: Store) {
    super('resident-group-members', feathers, store, {
      entityName: 'residentGroupMember',
      created: ResidentGroupMembersWsActions.residentGroupMembersCreated,
      patched: ResidentGroupMembersWsActions.residentGroupMembersPatched,
      removed: ResidentGroupMembersWsActions.residentGroupMembersRemoved
    });
  }
}
