import { IPushNotificationData } from '$shared/notifications';
import { createActionGroup, props } from '@ngrx/store';

export const NotificationsPushActions = createActionGroup({
  source: 'Notification Push',
  events: {
    'Notification Received': props<{
      data: IPushNotificationData;
    }>()
  }
});
