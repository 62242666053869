import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { stringify } from 'safe-stable-stringify';
import { EventListFilter } from './event-list-filter.page';

export const EventListFilterPageActions = createActionGroup({
  source: 'Event List Filter Page',
  events: {
    'Filter Changed': (
      filters: EventListFilter
    ): { filters: EventListFilter; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          medicalEvents: stringify(filters.medicalEvents),
          appointments: stringify(filters.appointments),
          incidentReports: stringify(filters.incidentReports),
          medicalEventTypes: stringify(filters.medicalEventTypes),
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
