import { Logger } from '$shared/logger';

export function sortByDesc(...keys: string[]) {
  return function (a, b) {
    for (const key of keys) {
      if (a[key]) {
        if (typeof a[key] === 'string') {
          return b[key].localeCompare(a[key], 'en', { sensitivity: 'base' });
        }
        if (typeof a[key] === 'number') {
          const compare = b[key] - a[key];
          return compare > 0 ? 1 : compare < 0 ? -1 : 0;
        }
      }
    }

    Logger.throw(
      'sortByDesc: Some properties were undefined or their values were not a string or a number',
      { keys }
    );
  };
}
