import { FacilityPhone, Paginated } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityPhonesApiActions = createActionGroup({
  source: 'Facility Phones API',
  events: {
    'Load Facility Phones Success': props<{
      facilityPhones: Paginated<FacilityPhone>;
    }>(),
    'Get Facility Phones Success': props<{
      facilityPhones: Paginated<FacilityPhone>;
    }>(),
    'Fetch Facility Phone Success': props<{ facilityPhone: FacilityPhone }>(),
    'Create Facility Phone Success': props<{
      facilityPhone: MaybeArray<FacilityPhone>;
    }>(),
    'Update Facility Phone Success': props<{ facilityPhone: FacilityPhone }>(),
    'Delete Facility Phone Success': props<{ id: string }>(),

    'Load Facility Phones Fail': props<{ error: Error }>(),
    'Get Facility Phones Fail': props<{ error: Error }>(),
    'Fetch Facility Phone Fail': props<{ error: Error }>(),
    'Create Facility Phone Fail': props<{ error: Error }>(),
    'Update Facility Phone Fail': props<{ error: Error }>(),
    'Delete Facility Phone Fail': props<{ error: Error }>()
  }
});

export const FacilityPhonesWsActions = createActionGroup({
  source: 'Facility Phones WS',
  events: {
    'Facility Phones Created': props<{ facilityPhone: FacilityPhone }>(),
    'Facility Phones Patched': props<{ facilityPhone: FacilityPhone }>(),
    'Facility Phones Removed': props<{ id: string }>()
  }
});
