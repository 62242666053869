import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResidentGroupMembersEffects } from './resident-group-members.effects';
import { residentGroupMembersFeature } from './resident-group-members.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(residentGroupMembersFeature),
    EffectsModule.forFeature([ResidentGroupMembersEffects])
  ],
  providers: [ResidentGroupMembersEffects]
})
export class ResidentGroupMembersStoreModule {}
