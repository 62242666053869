import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dosePlaceholder', standalone: true })
export class AlcDosePlaceholderPipe implements PipeTransform {
  transform(measurementId: string): any {
    const complexMilliliters = ['mgml', 'unitml', 'gml', 'meql'];

    if (complexMilliliters.includes(measurementId)) {
      return '100/1';
    } else {
      return '25, 5/325, 5-325, ';
    }
  }
}
