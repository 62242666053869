import { allergiesAdapter } from '$/app/store/allergies/allergies.state';
import { selectRouterState } from '$/app/store/router/router.selectors';
import { createSelector } from '@ngrx/store';
import { filter } from 'lodash';
import { allergiesFeature } from './allergies.reducer';

export const {
  selectAllergiesState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = allergiesFeature;

export const { selectAll, selectTotal } =
  allergiesAdapter.getSelectors(selectAllergiesState);

export const selectResidentAllergies = createSelector(
  selectAll,
  selectRouterState,
  (allergies, routerState) =>
    filter(allergies, { residentId: routerState?.params?.residentId })
);
