import { Params } from '$/models';
import { ResidentGroupMember } from '$/models/data/entities/residents/resident-group-member.model';
import { createActionGroup } from '@ngrx/store';

export const ResidentGroupPickerFormPageActions = createActionGroup({
  source: 'Resident Group Picker Form Page',
  events: {
    'Get Resident Groups': (params: Params = { query: {} }) => ({ params }),
    'Create Resident Group Member': (
      residentGroupMember: ResidentGroupMember,
      params: Params = { query: {} }
    ) => ({ residentGroupMember, params }),
    'Delete Resident Group Member': (
      id: string,
      params: Params = { query: {} }
    ) => ({ id, params })
  }
});
