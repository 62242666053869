import { IIncidentReportResident, IPaginationInfo } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const incidentReportResidentsAdapter =
  createEntityAdapter<IIncidentReportResident>();

export interface State extends EntityState<IIncidentReportResident> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  incidentReportResidentsAdapter.getInitialState({
    pagination: {},
    loading: false,
    loaded: false,
    error: null
  });
