import { Table } from '$/models/data/base';

export class ShiftNote extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  careTaskId?: string;
  notes: string;
  recordAt: string;
  recordedBy: string;
}
