import { CarePlanItemSchedule, Paginated } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CarePlanItemSchedulesApiActions = createActionGroup({
  source: 'Care Plan Item Schedules API',
  events: {
    'Load Care Plan Item Schedules Success': props<{
      carePlanItemSchedules: Paginated<CarePlanItemSchedule>;
    }>(),
    'Get Care Plan Item Schedules Success': props<{
      carePlanItemSchedules: Paginated<CarePlanItemSchedule>;
    }>(),
    'Fetch Care Plan Item Schedule Success': props<{
      carePlanItemSchedule: CarePlanItemSchedule;
    }>(),

    'Load Care Plan Item Schedules Fail': props<{ error: Error }>(),
    'Get Care Plan Item Schedules Fail': props<{ error: Error }>(),
    'Fetch Care Plan Item Schedule Fail': props<{ error: Error }>()
  }
});

export const CarePlanItemSchedulesWsActions = createActionGroup({
  source: 'Care Plan Item Schedules WS',
  events: {
    'Care Plan Item Schedules Created': props<{
      carePlanItemSchedule: CarePlanItemSchedule;
    }>(),
    'Care Plan Item Schedules Patched': props<{
      carePlanItemSchedule: CarePlanItemSchedule;
    }>(),
    'Care Plan Item Schedules Removed': props<{ id: string }>()
  }
});

export const CarePlanItemSchedulesGeneralActions = createActionGroup({
  source: 'Care Plan Item Schedules General',
  events: {
    'Clear Care Plan Item Schedules': emptyProps(),
    'Add Care Plan Item Schedules': props<{
      carePlanItemSchedules: CarePlanItemSchedule[];
    }>()
  }
});
