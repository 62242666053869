import { createFeatureSelector, createSelector } from '@ngrx/store';

import { omittedMedicationsAdapter, State } from './omitted-medications.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;
export const getTotalRecords = (state: State): number => state.total;
export const getSkip = (state: State): number => state.skip;

// OmittedMedications State Selector
export const selectOmittedMedicationsState =
  createFeatureSelector<State>('omittedMedications');

const entitySelectors = omittedMedicationsAdapter.getSelectors(
  selectOmittedMedicationsState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectOmittedMedicationsState,
  getLoading
);
export const selectLoaded = createSelector(
  selectOmittedMedicationsState,
  getLoaded
);
export const selectError = createSelector(
  selectOmittedMedicationsState,
  getError
);
export const selectTotalRecords = createSelector(
  selectOmittedMedicationsState,
  getTotalRecords
);
export const selectSkip = createSelector(
  selectOmittedMedicationsState,
  getSkip
);

// export const selectOmittedMedications = createSelector(
//   selectAll,
//   selectRouterState,
//   (omittedMedications, routerState) => {
//     if (routerState) {
//       return omittedMedications.filter(omittedMedication => {
//         return omittedMedication.residentId === routerState.params.residentId;
//       });
//     }
//     return [];
//   }
// );

// VIEW MODEL SELECTORS
