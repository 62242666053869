import { Room } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const roomsAdapter = createEntityAdapter<Room>({
  sortComparer: (a: Room, b: Room) =>
    a.identifier.localeCompare(b.identifier, undefined, {
      numeric: true,
      sensitivity: 'base'
    })
});

export interface State extends EntityState<Room> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = roomsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
