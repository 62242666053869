import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { some } from 'lodash';

export function weekdaysRequired(form: UntypedFormGroup): ValidationErrors {
  const frequencyTypeId = form.get('frequencyTypeId')?.value;

  if (frequencyTypeId !== 'weekdays') {
    return null;
  }

  const WEEKDAYS = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'] as const;

  if (some(WEEKDAYS, (day) => form.get(day).value)) {
    return null;
  }

  return { weekdaysRequired: true };
}
