import { MeasurementId } from '../lookups/measurements.lookup';
import { calculateAmount } from './calculate-amount';

export function convertToQty(
  amount: string,
  unitStrength: string | null | undefined,
  measurementId: MeasurementId | undefined | null
): number | undefined {
  if (!amount) {
    return;
  }

  if (!unitStrength) {
    return 0;
  }

  const calculatedAmount = calculateAmount(amount, measurementId);
  const calculatedStrength = calculateAmount(unitStrength, measurementId);

  return calculatedAmount && calculatedStrength
    ? calculatedAmount / calculatedStrength
    : 0;
}
