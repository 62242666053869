import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IPositionLog } from '$shared/services/position-log';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { positionLogsFeature } from './position-logs.reducer';
import { positionLogsAdapter } from './position-logs.state';

export const {
  selectPositionLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = positionLogsFeature;

export const { selectAll, selectTotal } = positionLogsAdapter.getSelectors(
  selectPositionLogsState
);

export const selectResidentPositionLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (bpLogs, residentId): IPositionLog[] => {
    if (isEmpty(bpLogs) || !residentId) {
      return [];
    }

    return bpLogs.filter((bpLog) => {
      return bpLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<IPositionLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IPositionLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IPositionLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => ({
    metrics: [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length ?? 0
      }
    ],
    logs
  }));
};
