import { Params, Update } from '$/models';
import { TMedicationInventoryItemCount } from '$shared/medications/medication-inventory-item-count.schema';
import { createActionGroup, props } from '@ngrx/store';

export const InventoryItemCountModalActions = createActionGroup({
  source: 'Inventory Item Count Modal',
  events: {
    'Create Medication Inventory Item Count': props<{
      medicationInventoryItemCount: Partial<TMedicationInventoryItemCount>;
      params?: Params;
    }>(),
    'Update Medication Inventory Item Count':
      props<Update<TMedicationInventoryItemCount>>()
  }
});
