import { Injectable } from '@angular/core';

// QUESTION(2024-04-01): @brane53 - this isn't used anywhere, but I think it's what you want for validating strength inputs?
@Injectable({ providedIn: 'root' })
export class MaskOptionsService {
  getStrengthOptions(measurementId: string) {
    switch (measurementId) {
      case 'mgml':
        return {
          mask: 'NNNmg{/}DDDmL',
          lazy: false,
          blocks: {
            NNN: {
              mask: '0[00000][.00000000]',
              definitions: {
                '.': /\./
              },
              lazy: false,
              placeholderChar: '\uFF3F'
            },
            DDD: {
              mask: '0[00000][.00000000]',
              definitions: {
                '.': /\./
              },
              lazy: false,
              placeholderChar: '\uFF3F'
            }
          }
        };

      case 'unitml':
        return {
          mask: 'NNN units {/} DDD mL',
          lazy: false,
          blocks: {
            NNN: {
              mask: '0[00000][.00000000][_0][00000][.00000000]',
              definitions: {
                '.': /\./,
                _: /\/|-/
              },
              lazy: false,
              placeholderChar: '\uFF3F'
            },
            DDD: {
              mask: '0[00000][.00000000]',
              definitions: {
                '.': /\./
              },
              lazy: false,
              placeholderChar: '\uFF3F'
            }
          }
        };

      default:
        return {
          mask: '0[00000][.00000000][_0][00000][.00000000]',
          lazy: true,
          definitions: {
            '.': /\./,
            _: /\/|-/
          }
        };
    }
  }
}
