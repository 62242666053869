import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ShowerLogsPageActions = createActionGroup({
  source: 'Shower Log Page',
  events: {
    'Load Shower Logs': (params: Params) => ({ params }),
    'Get Shower Logs': (params: Params) => ({ params }),
    'Delete Shower Log': props<{ id: string; params?: Params }>()
  }
});
