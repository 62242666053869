import { FeathersService } from '$/app/services/feathers.service';
import { MedicationTasksWsActions } from '$/app/store/medication-tasks/medication-tasks.actions';
import { toUpdated } from '$/app/utils';
import { MedicationTask, Paginated, Params, Update } from '$/models';
import { MedicationTaskSubmission } from '$shared/medication-tasks';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { BulkResult } from '../../../../../shared/src/types/bulk-updates';

@Injectable({ providedIn: 'root' })
export class MedicationTasksApiService {
  private medicationTasksService;

  constructor(feathers: FeathersService, store: Store) {
    this.medicationTasksService = feathers.client.service('medication-tasks');

    this.medicationTasksService.on('created', (medicationTask) => {
      store.dispatch(
        MedicationTasksWsActions.medicationTasksCreated({ medicationTask })
      );
    });

    this.medicationTasksService.on('patched', (medicationTask) => {
      if ('bulkRecords' in medicationTask) {
        store.dispatch(
          MedicationTasksWsActions.medicationTasksBatchPatched({
            medicationTasks: medicationTask.bulkRecords
          })
        );
      } else {
        store.dispatch(
          MedicationTasksWsActions.medicationTasksPatched({ medicationTask })
        );
      }
    });

    this.medicationTasksService.on(
      'batchUpdate',
      ({ createdTasks, patchedTasks, deletedTaskIds }) => {
        store.dispatch(
          MedicationTasksWsActions.medicationTasksBatchCreated({
            medicationTasks: createdTasks
          })
        );
        store.dispatch(
          MedicationTasksWsActions.medicationTasksBatchPatched({
            medicationTasks: patchedTasks
          })
        );
        store.dispatch(
          MedicationTasksWsActions.medicationTasksBatchRemoved({
            ids: deletedTaskIds
          })
        );
      }
    );

    this.medicationTasksService.on('removed', (medicationTask) => {
      store.dispatch(
        MedicationTasksWsActions.medicationTasksRemoved({
          id: medicationTask.id
        })
      );
    });
  }

  getAll(params: Params = { query: {} }) {
    return from(
      this.medicationTasksService.find(params) as Promise<
        Paginated<MedicationTask>
      >
    );
  }

  get(id: string, params: Params = { query: {} }) {
    return from(this.medicationTasksService.get(id, params));
  }

  patch(
    id: string,
    changes: Partial<MedicationTaskSubmission>,
    params: Params = { query: {} }
  ): Observable<Update<MedicationTask>> {
    const promise = this.medicationTasksService.patch(id, changes, params);

    return from(promise).pipe(map(toUpdated));
  }
  patchMultiple(
    changes: Partial<MedicationTaskSubmission>[],
    params: Params = { query: {} }
  ): Observable<Update<MedicationTask>[]> {
    const promise = this.medicationTasksService.patch(
      null,
      changes,
      params
    ) as Promise<MedicationTask[] | BulkResult<MedicationTask>>;

    return from(promise).pipe(
      map((result) => {
        if ('bulkRecords' in result) {
          return result.bulkRecords.map(toUpdated);
        }

        return result.map(toUpdated);
      })
    );
  }

  delete(id: string) {
    return from(this.medicationTasksService.remove(id));
  }
}
