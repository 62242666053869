import { Update } from '$/models';
import { IFacilitySetting } from '$shared/facility-settings/types';
import { createAction, props } from '@ngrx/store';

export const updateFacilitySettingSuccess = createAction(
  '[FacilitySettings API] Update FacilitySetting Success',
  props<{ facilitySetting: Update<IFacilitySetting> }>()
);

export const updateFacilitySettingFail = createAction(
  '[FacilitySettings API] Update FacilitySetting Fail',
  props<{ error: Error }>()
);
