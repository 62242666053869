import { IDocumentPacket, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DocumentPacketFormPageActions = createActionGroup({
  source: 'Document Packet Form Page',
  events: {
    'Create Document Packet': props<{
      documentPacket: IDocumentPacket & {
        documentIds: string[];
      };
      params: Params;
    }>(),
    'Update Document Packet':
      props<Update<IDocumentPacket & { documentIds: string[] }>>()
  }
});
