import { Amount } from '../doses/amount.class';
import { amountTypeIds } from '../lookups/amount-types.lookup';
import { MeasurementId } from '../lookups/measurements.lookup';
import { MedicationFormId } from '../lookups/medication-forms.lookup';
import { TypeBox } from '../type-box';

export const omittedAmountSchema = TypeBox.TableEntity(
  {
    omittedMedicationId: TypeBox.Id(),
    medicationInventoryItemId: TypeBox.OptionalNullable(TypeBox.Id()),
    amountTypeId: TypeBox.Enum(amountTypeIds),
    amount: TypeBox.String(),
    strength: TypeBox.OptionalNullable(TypeBox.String())
  },
  { title: 'Omitted Amount' }
);

export type TOmittedAmount = TypeBox.Infer<typeof omittedAmountSchema>;

// QUESTION(2024-03-01): We may remove omitted amounts altogether now that
// medication tasks supports multiple amounts. Is there a compelling reason to
// keep it?
export class OmittedAmount extends Amount implements TOmittedAmount {
  id!: string;
  orgId!: string;
  facilityId!: string;
  omittedMedicationId!: string;
  createdBy!: string;
  createdAt!: string;
  updatedBy?: string | null | undefined;
  updatedAt?: string | null | undefined;
  deletedBy?: string | null | undefined;
  deletedAt?: string | null | undefined;

  constructor(
    amount: TOmittedAmount,
    public measurementId?: MeasurementId,
    public medicationFormId?: MedicationFormId
  ) {
    super(amount, measurementId, medicationFormId);
  }

  toJSON(): TOmittedAmount {
    const { measurementId, medicationFormId, ...rest } = this;
    return rest;
  }
}
