import { BehaviorModalActions } from '$/app/pages/notebook/residents/behavior-modal/behavior.modal.actions';
import { BehaviorsPageActions } from '$/app/pages/notebook/residents/behaviors/behaviors.actions';
import { getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  BehaviorsApiActions,
  BehaviorsGeneralActions,
  BehaviorsWsActions
} from './behaviors.actions';
import { behaviorsAdapter, initialState } from './behaviors.state';

export const behaviorsFeature = createFeature({
  name: 'behaviors',
  reducer: createReducer(
    initialState,
    on(
      BehaviorModalActions.createBehavior,
      BehaviorModalActions.updateBehavior,
      BehaviorsPageActions.deleteBehavior,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),
    on(BehaviorsApiActions.getBehaviorsSuccess, (state, action) => {
      const data = getRecords(action.behaviors);

      return behaviorsAdapter.upsertMany(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(BehaviorsApiActions.getBehaviorMetricsSuccess, (state, action) => {
      return {
        ...state,
        metrics: action.metrics,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(BehaviorsApiActions.createBehaviorSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(BehaviorsApiActions.createBehaviorSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(BehaviorsApiActions.deleteBehaviorSuccess, (state, action) =>
      behaviorsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),
    on(
      BehaviorsApiActions.getBehaviorsFail,
      BehaviorsApiActions.getBehaviorMetricsFail,
      BehaviorsApiActions.createBehaviorFail,
      BehaviorsApiActions.updateBehaviorFail,
      BehaviorsApiActions.deleteBehaviorFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),
    on(
      BehaviorsWsActions.behaviorsCreated,
      BehaviorsWsActions.behaviorsPatched,
      (state, action) => {
        return behaviorsAdapter.upsertOne(action.behavior, state);
      }
    ),
    on(BehaviorsWsActions.behaviorsRemoved, (state, action) => {
      return behaviorsAdapter.removeOne(action.id, state);
    }),
    on(BehaviorsGeneralActions.addBehaviors, (state, action) => {
      return behaviorsAdapter.upsertMany(action.behaviors, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(BehaviorsGeneralActions.clearBehaviors, () => {
      return behaviorsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
