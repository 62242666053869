import { Table } from '$/models/data/base';
import { DoseValue } from '$shared/doses';
import { DoseTypeId } from '$shared/lookups/dose-types.lookup';
import { MeasurementId } from '$shared/lookups/measurements.lookup';
import { MedicationFormId } from '$shared/lookups/medication-forms.lookup';
import { MedicationStatusId } from '$shared/lookups/medication-statuses.lookup';

export class Medication extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  parentId?: string;
  medicalProfessionalId?: string;
  pharmacyId?: string;

  active?: boolean;
  batchId?: string;
  brandName?: string;
  changeReason?: string | null;
  discontinuedAt?: string;
  dose?: DoseValue;
  doseIntervalHours?: number;
  doseMeasurementId?: MeasurementId;
  doseTypeId?: DoseTypeId;
  foodRequirementId?: string;
  formId?: MedicationFormId;
  genericName?: string;
  hasBlackBoxWarning?: boolean;
  heldAt?: string;
  holdTill?: string;
  instructions?: string;
  isCrush?: boolean;
  isCycle?: boolean;
  isNarcotic?: boolean;
  isPrn?: boolean;
  maxDose?: string;
  maxDosePeriodHours?: number;
  measurementId?: MeasurementId;
  needsOrdering?: boolean;
  refills?: number;
  routeId?: string;
  scriptImageFileId?: string;
  sideEffects?: string;
  startDate?: string | null;
  statusId?: MedicationStatusId;
  strength?: string;
  trackInventory?: boolean;
  typeId?: string;
  version?: number;

  constructor(medication: Medication = {}) {
    super();
    Object.assign(this, medication);
  }
}
