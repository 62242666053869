export const AlcBreakpointIds = ['sm', 'md', 'lg', 'xl'] as const;

export type AlcBreakpointId = (typeof AlcBreakpointIds)[number];

export const AlcBreakpoints: Record<AlcBreakpointId, string> = {
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)'
} as const;
