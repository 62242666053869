import { Facility } from '$/models/data/entities/facilities/facility.model';
import { Pharmacy } from '$/models/data/entities/facilities/pharmacy.model';
import { Room } from '$/models/data/entities/facilities/room.model';
import { IResident } from '$shared/residents/residents.interface';
import { calculateAge, getAmbulatoryStatus } from '$shared/residents/utils';

interface GetResidentVMParams {
  resident: IResident;
  facility?: Facility;
  room?: Room;
  preferredPharmacy?: Pharmacy;
}

export function getResidentVM({
  resident,
  facility,
  room,
  preferredPharmacy
}: GetResidentVMParams) {
  const facilityName = facility?.name;
  const roomIdentifier = room?.identifier ?? 'unassigned';
  const preferredPharmacyName = preferredPharmacy?.name;
  const age = calculateAge(resident);
  const ambulatoryStatus = getAmbulatoryStatus(resident);

  return Object.assign({}, resident, {
    ...ambulatoryStatus,
    age,
    facilityName,
    preferredPharmacyName,
    roomIdentifier
  });
}

export type ResidentVM = ReturnType<typeof getResidentVM>;
