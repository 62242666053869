import {
  Directive,
  DoCheck,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  forwardRef
} from '@angular/core';
import { castArray } from 'lodash';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ErrorOptions } from './ngxerrors';
import { NgxErrorsDirective } from './ngxerrors.directive';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngxError]' })
export class NgxErrorDirective implements OnInit, OnDestroy, DoCheck {
  @Input()
  set ngxError(value: ErrorOptions) {
    this.errorNames = castArray(value);
  }

  @Input()
  set when(value: ErrorOptions) {
    this.rules = castArray(value);
  }

  @HostBinding('hidden')
  hidden: boolean = true;

  rules: string[] = [];

  errorNames: string[] = [];

  subscription: Subscription;

  _states: Subject<string[]>;

  states$: Observable<string[]>;

  constructor(
    @Inject(forwardRef(() => NgxErrorsDirective))
    private ngxErrors: NgxErrorsDirective
  ) {}

  ngOnInit() {
    this._states = new Subject<string[]>();
    this.states$ = this._states.asObservable().pipe(distinctUntilChanged());

    const formErrors = this.ngxErrors.subject.pipe(
      filter(Boolean),
      filter((obj: any) => !!~this.errorNames.indexOf(obj.errorName))
    );

    const formStates = this.states$.pipe(
      map((states: any) => this.rules.every((rule) => !!~states.indexOf(rule)))
    );

    this.subscription = combineLatest([formStates, formErrors]).subscribe(
      ([states, errors]) => {
        this.hidden = !(states && errors.control.hasError(errors.errorName));
      }
    );
  }

  ngDoCheck() {
    this._states.next(
      this.rules.filter((rule) => (this.ngxErrors.control as any)[rule])
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
