import { createFeature, createReducer, on } from '@ngrx/store';
import {
  LibraryDocumentTemplatesApiActions,
  LibraryDocumentTemplatesGeneralActions,
  LibraryDocumentTemplatesWsActions
} from './actions';
import {
  initialState,
  libraryDocumentTemplatesAdapter
} from './library-document-templates.state';

export const libraryDocumentTemplatesFeature = createFeature({
  name: 'libraryDocumentTemplates',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      LibraryDocumentTemplatesApiActions.loadLibraryDocumentTemplatesSuccess,
      (state, action) => {
        const {
          libraryDocumentTemplates: { data: records, ...pagination }
        } = action;

        return libraryDocumentTemplatesAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryDocumentTemplatesApiActions.getLibraryDocumentTemplatesSuccess,
      (state, action) => {
        const {
          libraryDocumentTemplates: { data: records, ...pagination }
        } = action;

        return libraryDocumentTemplatesAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryDocumentTemplatesApiActions.fetchLibraryDocumentTemplateSuccess,
      (state, action) => {
        return libraryDocumentTemplatesAdapter.upsertOne(
          action.libraryDocumentTemplate,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatesApiActions.createLibraryDocumentTemplateSuccess,
      (state, action) => {
        return libraryDocumentTemplatesAdapter.addOne(
          action.libraryDocumentTemplate,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatesApiActions.updateLibraryDocumentTemplateSuccess,
      (state, action) => {
        return libraryDocumentTemplatesAdapter.upsertOne(
          action.libraryDocumentTemplate,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatesApiActions.deleteLibraryDocumentTemplateSuccess,
      (state, action) =>
        libraryDocumentTemplatesAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      LibraryDocumentTemplatesApiActions.loadLibraryDocumentTemplatesFail,
      LibraryDocumentTemplatesApiActions.getLibraryDocumentTemplatesFail,
      LibraryDocumentTemplatesApiActions.createLibraryDocumentTemplateFail,
      LibraryDocumentTemplatesApiActions.updateLibraryDocumentTemplateFail,
      LibraryDocumentTemplatesApiActions.deleteLibraryDocumentTemplateFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      LibraryDocumentTemplatesWsActions.libraryDocumentTemplateCreated,
      LibraryDocumentTemplatesWsActions.libraryDocumentTemplatePatched,
      (state, action) => {
        return libraryDocumentTemplatesAdapter.upsertOne(
          action.libraryDocumentTemplate,
          state
        );
      }
    ),

    on(
      LibraryDocumentTemplatesWsActions.libraryDocumentTemplateRemoved,
      (state, action) => {
        return libraryDocumentTemplatesAdapter.removeOne(action.id, state);
      }
    ),

    on(
      LibraryDocumentTemplatesGeneralActions.addLibraryDocumentTemplates,
      (state, action) => {
        return libraryDocumentTemplatesAdapter.upsertMany(
          action.libraryDocumentTemplates,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatesGeneralActions.clearLibraryDocumentTemplates,
      () => {
        return libraryDocumentTemplatesAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
