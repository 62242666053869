import { CarePlanItemSchedulesApiService } from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()
export class CarePlanItemSchedulesEffects {
  private readonly actions$ = inject(Actions);
  private readonly carePlanItemSchedulesService = inject(
    CarePlanItemSchedulesApiService
  );
}
