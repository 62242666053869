import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { BodyCheckLogsPageActions } from '$/app/pages/notebook/shared/logs/body-check-logs/body-check-log.actions';
import { BodyCheckLogsApiService, EffectHelpersService } from '$/app/services';
import { BodyCheckLogFormPageActions } from '$/app/shared/pages/forms/log-forms/body-check-log-form/body-check-log-form.actions';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { IBodyCheckLog } from '$shared/services/body-check-log';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { BodyCheckLogsApiActions } from './body-check-logs.actions';

@Injectable()
export class BodyCheckLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly bodyCheckLogsService = inject(BodyCheckLogsApiService);

  loadBodyCheckLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BodyCheckLogsPageActions.loadBodyCheckLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.bodyCheckLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('bodyCheckLogs', logs);
            responseData.setPrimaryAction(
              BodyCheckLogsApiActions.loadBodyCheckLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getBodyCheckLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BodyCheckLogsPageActions.getBodyCheckLogs,
        LogsPageActions.getBodyCheckLogs,
        GivePrnFormActions.getBodyCheckLogs
      ),
      switchMap((action) => {
        return this.bodyCheckLogsService.getAll(action.params).pipe(
          getAllPages(this.bodyCheckLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('bodyCheckLogs', logs);

            responseData.setPrimaryAction(
              BodyCheckLogsApiActions.getBodyCheckLogsSuccess
            );

            return responseData.getActions();
          })
        );
      })
    );
  });

  createBodyCheckLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BodyCheckLogFormPageActions.createBodyCheckLog),
      exhaustMap((action) => {
        return this.bodyCheckLogsService.create(action.bodyCheckLog).pipe(
          tap((data: IBodyCheckLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((bodyCheckLog: IBodyCheckLog) =>
            BodyCheckLogsApiActions.createBodyCheckLogSuccess({
              bodyCheckLog
            })
          ),
          catchError((error) =>
            of(
              BodyCheckLogsApiActions.createBodyCheckLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateBodyCheckLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BodyCheckLogFormPageActions.updateBodyCheckLog),
      switchMap((action) => {
        return this.bodyCheckLogsService.patch(action.id, action.changes).pipe(
          tap((data: IBodyCheckLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((bodyCheckLog: IBodyCheckLog) => {
            return BodyCheckLogsApiActions.updateBodyCheckLogSuccess({
              bodyCheckLog
            });
          }),
          catchError((error) => {
            return of(
              BodyCheckLogsApiActions.updateBodyCheckLogFail({
                error
              })
            );
          })
        );
      })
    );
  });

  deleteBodyCheckLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BodyCheckLogsPageActions.deleteBodyCheckLog,
        ResidentMedicationTasksPageActions.deleteBodyCheckLog,
        MissedMedicationsPageActions.deleteBodyCheckLog,
        RoutineMarDetailPageActions.deleteBodyCheckLog,
        RoutineMarListPageActions.deleteBodyCheckLog,
        GivePrnFormActions.deleteBodyCheckLog,
        CompleteCareTaskModalActions.deleteBodyCheckLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete body check log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.bodyCheckLogsService.delete(action.id, action.params);
        },
        onSuccess: (bodyCheckLog: IBodyCheckLog) =>
          BodyCheckLogsApiActions.deleteBodyCheckLogSuccess({
            id: bodyCheckLog.id
          }),
        onError: (error) =>
          BodyCheckLogsApiActions.deleteBodyCheckLogFail({ error })
      })
    );
  });
}
