import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MedicationOrdersEffects } from './medication-orders.effects';
import { medicationOrdersFeature } from './medication-orders.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(medicationOrdersFeature),
    EffectsModule.forFeature([MedicationOrdersEffects])
  ],
  providers: [MedicationOrdersEffects]
})
export class MedicationOrdersStoreModule {}
