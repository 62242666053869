import { FacilityUserPhone } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const facilityUserPhonesAdapter =
  createEntityAdapter<FacilityUserPhone>();

export interface State extends EntityState<FacilityUserPhone> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = facilityUserPhonesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
