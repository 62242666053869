<div
  class="flex items-center justify-start rounded-2xl border border-solid px-6 py-4"
  [ngClass]="{
    default: color === 'default',
    info: color === 'primary',
    warning: color === 'warning',
    error: color === 'danger',
    success: color === 'success'
  }"
>
  @if (icon) {
    <div class="mr-4 flex items-start">
      <ion-icon class="text-2xl" [src]="icon" />
    </div>
  }

  <div
    [ngClass]="{
      info: color === 'primary',
      warning: color === 'warning',
      error: color === 'danger',
      success: color === 'success'
    }"
  >
    <ng-content />
  </div>
</div>
