import { IDocument, Params, Update } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const DocumentsPortalFormShellPageActions = createActionGroup({
  source: 'Documents Portal Form Shell Page',
  events: {
    'Fetch Document': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Update Document Form': (
      id: string,
      formData: Record<string, any> | undefined,
      params: Params
    ): Update<IDocument> => ({
      id,
      changes: {
        formData
      },
      params
    })
  }
});
