import { FeathersService } from '$/app/services/feathers.service';
import { BloodSugarLogsWsActions } from '$/app/store/blood-sugar-logs';
import { ApiData } from '$/app/utils/api-data';
import { Paginated, Params } from '$/models';
import { IBloodSugarLog } from '$shared/services/blood-sugar-log';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BloodSugarLogsApiService {
  private bloodSugarLogsService: Service<IBloodSugarLog>;

  constructor(store: Store, feathers: FeathersService) {
    this.bloodSugarLogsService = feathers.client.service('blood-sugar-logs');

    this.bloodSugarLogsService.on('created', (bloodSugarLog) => {
      const responseData = new ApiData('bloodSugarLogs', bloodSugarLog);
      responseData.setPrimaryAction(
        BloodSugarLogsWsActions.bloodSugarLogsCreated,
        {
          payloadKey: 'bloodSugarLog'
        }
      );
      responseData.getActions().forEach((action) => {
        store.dispatch(action);
      });
    });

    this.bloodSugarLogsService.on('patched', (bloodSugarLog) => {
      store.dispatch(
        BloodSugarLogsWsActions.bloodSugarLogsPatched({
          bloodSugarLog
        })
      );
    });

    this.bloodSugarLogsService.on('removed', (bloodSugarLog) => {
      store.dispatch(
        BloodSugarLogsWsActions.bloodSugarLogsRemoved({
          id: bloodSugarLog.id
        })
      );
    });
  }

  getAll(params: Params) {
    const result = this.bloodSugarLogsService.find(params) as Promise<
      Paginated<IBloodSugarLog>
    >;

    return from(result);
  }

  create(bloodSugar: IBloodSugarLog): Observable<IBloodSugarLog> {
    return from(this.bloodSugarLogsService.create(bloodSugar));
  }

  patch(
    id: string,
    bloodSugarLog: Partial<IBloodSugarLog>,
    params: Params = { query: {} }
  ): Observable<IBloodSugarLog> {
    const promise = this.bloodSugarLogsService.patch(id, bloodSugarLog, params);

    return from(promise);
  }

  delete(
    id: string,
    params: Params = { query: {} }
  ): Observable<IBloodSugarLog> {
    return from(this.bloodSugarLogsService.remove(id, params));
  }
}
