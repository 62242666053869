import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OrganizationsEffects } from './organizations.effects';
import { organizationsFeature } from './organizations.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(organizationsFeature),
    EffectsModule.forFeature([OrganizationsEffects])
  ],
  providers: [OrganizationsEffects]
})
export class OrganizationsStoreModule {}
