<ion-header>
  <ion-toolbar [color]="'primary' | alcTheme">
    <ion-title>{{ file?.path | extractFileNameFromPath }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="close()">
        <ion-icon slot="icon-only" src="assets/icon/material/close.svg" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-buttons slot="end">
      @if (canPrint) {
        <ion-button (click)="print()" title="Print">
          <ion-icon slot="icon-only" src="assets/icon/material/print.svg" />
        </ion-button>
      }
      @if (canShare) {
        <ion-button (click)="share()" title="{{ shareTooltip }}">
          <ion-icon slot="icon-only" src="{{ shareIcon }}" />
        </ion-button>
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div
    class="min-h-full bg-dark"
    [ngClass]="{ 'p-4': isImage }"
    *ngrxLet="file?.id | fileIdToUrl | async as fileUrl"
  >
    @if (file?.contentType === 'application/pdf') {
      <alc-pdf-viewer
        #pdfViewer
        [url]="fileUrl"
        [filename]="file?.path | extractFileNameFromPath"
      />
    }
    @if (file && isImage) {
      <img class="size-full object-contain" [src]="fileUrl" />
    }
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button
        shape="round"
        fill="outline"
        [color]="'primary' | alcTheme"
        (click)="close()"
      >
        <ion-label>Close</ion-label>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
