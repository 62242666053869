import { IDocumentRelation } from '$/models';
import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import { documentRelationsFeature } from './document-relations.reducer';
import { documentRelationsAdapter } from './document-relations.state';

export const {
  selectDocumentRelationsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = documentRelationsFeature;

export const { selectAll, selectTotal } = documentRelationsAdapter.getSelectors(
  selectDocumentRelationsState
);

export const selectAllGroupedByRelationId = createSelector(
  selectAll,
  (documentRelations): Record<string, IDocumentRelation[]> => {
    return groupBy(documentRelations, 'relationId');
  }
);
