import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PharmacyPhonesEffects } from './pharmacy-phones.effects';
import { pharmacyPhonesReducer } from './pharmacy-phones.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('pharmacyPhones', pharmacyPhonesReducer),
    EffectsModule.forFeature([PharmacyPhonesEffects])
  ],
  providers: [PharmacyPhonesEffects]
})
export class PharmacyPhonesStoreModule {}
