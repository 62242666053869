import { sortByName } from '$/app/utils';
import { MedicalProfessional } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicalProfessionalsAdapter =
  createEntityAdapter<MedicalProfessional>({
    sortComparer: sortByName
  });

export interface State extends EntityState<MedicalProfessional> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicalProfessionalsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
