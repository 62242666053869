import { AlcomyErrorCodes } from '$/config';
import { AlcomyError, AlcomyErrorMessage } from './alcomy-error.class';

/**
 * Thrown when a log is not found
 */
export class NoLogFound extends AlcomyError {
  constructor(message: AlcomyErrorMessage, data?: any) {
    super(message, `${NoLogFound.name}`, AlcomyErrorCodes.NO_LOG_FOUND, data);
  }
}
