import { DocumentParticipantManagerPageActions } from '$/app/pages/documents/shared/components/document-participant-manager/document-participant-manager.actions';
import {
  DocumentParticipantsApiService,
  EffectHelpersService
} from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DocumentParticipantsApiActions } from './document-participants.actions';

@Injectable()
export class DocumentParticipantsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly documentParticipantsService = inject(
    DocumentParticipantsApiService
  );

  updateDocumentParticipant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentParticipantManagerPageActions.updateDocumentParticipants),
      this.effectHelpers.apiRequest({
        description: 'Update Document Participant',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.documentParticipantsService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (documentParticipant) =>
          DocumentParticipantsApiActions.updateDocumentParticipantSuccess({
            documentParticipant
          }),
        onError: (error) =>
          DocumentParticipantsApiActions.updateDocumentParticipantFail({
            error
          })
      })
    );
  });
}
