import { Paginated, WeightLog } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Weight Logs API]';
const sourceWS = '[Weight Logs WS]';

// Load
export const loadWeightLogsSuccess = createAction(
  `${source} Load Weight Logs Success`,
  props<{
    weightLogs: Paginated<WeightLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const loadWeightLogsFail = createAction(
  `${source} Load Weight Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getWeightLogsSuccess = createAction(
  `${source} Get Weight Logs Success`,
  props<{
    weightLogs: Paginated<WeightLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const getWeightLogsFail = createAction(
  `${source} Get Weight Logs Fail`,
  props<{ error: Error }>()
);
// Create
export const createWeightLogSuccess = createAction(
  `${source} Create Weight Log Success`,
  props<{ weightLog: WeightLog }>()
);
export const createWeightLogFail = createAction(
  `${source} Create Weight Log Fail`,
  props<{ error: Error }>()
);

export const updateWeightLogSuccess = createAction(
  `${source} Update Weight Log Success`,
  props<{ weightLog: WeightLog }>()
);
export const updateWeightLogFail = createAction(
  `${source} Update Weight Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteWeightLogSuccess = createAction(
  `${source} Delete Weight Log Success`,
  props<{ id: string }>()
);
export const deleteWeightLogFail = createAction(
  `${source} Delete Weight Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Weight Logs Created
export const weightLogsCreated = createAction(
  `${sourceWS} Weight Logs Created`,
  props<{ weightLog: WeightLog }>()
);
// [WS] Weight Logs Patched
export const weightLogsPatched = createAction(
  `${sourceWS} Weight Logs Patched`,
  props<{ weightLog: WeightLog }>()
);
// [WS] Weight Logs Removed
export const weightLogsRemoved = createAction(
  `${sourceWS} Weight Logs Removed`,
  props<{ id: string }>()
);
