import { sortByDatetime } from '$/app/utils';
import { AnnouncementReply } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const announcementRepliesAdapter =
  createEntityAdapter<AnnouncementReply>({
    sortComparer: sortByDatetime('createdAt', false)
  });

export interface State extends EntityState<AnnouncementReply> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = announcementRepliesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
