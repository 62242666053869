import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { uiRoutineMarListPageFeature } from './routine-mar-list-page.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(uiRoutineMarListPageFeature)]
})
export class UiRoutineMarListPageStoreModule {}
