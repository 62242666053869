import { createFeature, createReducer, on } from '@ngrx/store';
import {
  CarePlanItemSchedulesApiActions,
  CarePlanItemSchedulesGeneralActions,
  CarePlanItemSchedulesWsActions
} from './care-plan-item-schedules.actions';
import {
  carePlanItemSchedulesAdapter,
  initialState
} from './care-plan-item-schedules.state';

export const carePlanItemSchedulesFeature = createFeature({
  name: 'carePlanItemSchedules',
  reducer: createReducer(
    initialState,

    on(
      CarePlanItemSchedulesApiActions.loadCarePlanItemSchedulesSuccess,
      (state, action) => {
        return carePlanItemSchedulesAdapter.setAll(
          action.carePlanItemSchedules.data,
          {
            ...state,
            total: action.carePlanItemSchedules.total,
            skip: action.carePlanItemSchedules.skip,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      CarePlanItemSchedulesApiActions.getCarePlanItemSchedulesSuccess,
      (state, action) => {
        return carePlanItemSchedulesAdapter.upsertMany(
          action.carePlanItemSchedules.data,
          {
            ...state,
            total: action.carePlanItemSchedules.total,
            skip: action.carePlanItemSchedules.skip,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      CarePlanItemSchedulesApiActions.fetchCarePlanItemScheduleSuccess,
      (state, action) => {
        return carePlanItemSchedulesAdapter.upsertOne(
          action.carePlanItemSchedule,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      CarePlanItemSchedulesApiActions.loadCarePlanItemSchedulesFail,
      CarePlanItemSchedulesApiActions.getCarePlanItemSchedulesFail,
      CarePlanItemSchedulesApiActions.fetchCarePlanItemScheduleFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      CarePlanItemSchedulesWsActions.carePlanItemSchedulesCreated,
      CarePlanItemSchedulesWsActions.carePlanItemSchedulesPatched,
      (state, action) => {
        return carePlanItemSchedulesAdapter.upsertOne(
          action.carePlanItemSchedule,
          {
            ...state
          }
        );
      }
    ),

    on(
      CarePlanItemSchedulesWsActions.carePlanItemSchedulesRemoved,
      (state, action) => {
        return carePlanItemSchedulesAdapter.removeOne(action.id, {
          ...state
        });
      }
    ),

    on(
      CarePlanItemSchedulesGeneralActions.addCarePlanItemSchedules,
      (state, action) => {
        return carePlanItemSchedulesAdapter.upsertMany(
          action.carePlanItemSchedules,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      CarePlanItemSchedulesGeneralActions.clearCarePlanItemSchedules,
      (state) => {
        return carePlanItemSchedulesAdapter.removeAll({
          ...state,
          total: 0,
          skip: 0,
          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
