import { FeathersService } from '$/app/services/feathers.service';
import { PharmaciesApiActions } from '$/app/store/pharmacies/actions';
import { toUpdated } from '$/app/utils';
import { Paginated, Params, Pharmacy, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PharmaciesApiService {
  private pharmaciesService: Service<Pharmacy>;

  constructor(feathers: FeathersService, store: Store) {
    this.pharmaciesService = feathers.client.service('pharmacies');

    this.pharmaciesService.on('created', (pharmacy) => {
      store.dispatch(PharmaciesApiActions.pharmaciesCreated({ pharmacy }));
    });

    this.pharmaciesService.on('patched', (pharmacy) => {
      store.dispatch(PharmaciesApiActions.pharmaciesPatched({ pharmacy }));
    });

    this.pharmaciesService.on('removed', (pharmacy) => {
      store.dispatch(
        PharmaciesApiActions.pharmaciesRemoved({ id: pharmacy.id })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<Paginated<Pharmacy>> {
    const promise = this.pharmaciesService.find(params) as Promise<
      Paginated<Pharmacy>
    >;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<Pharmacy> {
    const promise = this.pharmaciesService.get(id, params);
    return from(promise);
  }

  create(pharmacy: MaybeArray<Pharmacy>) {
    const promise = this.pharmaciesService.create(pharmacy);
    return from(promise);
  }

  patch(
    id: string,
    pharmacy: Partial<Pharmacy>,
    params: Params = { query: {} }
  ): Observable<Update<Pharmacy>> {
    const promise = this.pharmaciesService.patch(id, pharmacy, params);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string, params: Params = { query: {} }): Observable<Pharmacy> {
    const promise = this.pharmaciesService.remove(id, params);
    return from(promise);
  }
}
