import { DashboardGeneralNotesPageActions } from '$/app/pages/notebook/dashboard/dashboard-general-notes/dashboard-general-notes.page.actions';
import { ShiftNotesListPageActions } from '$/app/pages/notebook/shared/shift-notes/shift-notes-list/shift-notes-list.actions';
import { getPaginationData } from '$/app/utils';
import { getRecords } from '$/app/utils/reducers/get-records';
import { IPaginationInfo } from '$/models';
import { Action, createReducer, on } from '@ngrx/store';
import { ShiftNotesApiActions, ShiftNotesGeneralActions } from './actions';
import { State, initialState, shiftNotesAdapter } from './shift-notes.state';

const reducer = createReducer(
  initialState,

  on(
    ShiftNotesListPageActions.loadShiftNotes,
    ShiftNotesListPageActions.refreshShiftNotes,
    ShiftNotesListPageActions.createShiftNote,
    ShiftNotesListPageActions.deleteShiftNote,
    DashboardGeneralNotesPageActions.loadShiftNotes,
    DashboardGeneralNotesPageActions.refreshShiftNotes,
    DashboardGeneralNotesPageActions.getShiftNotes,
    DashboardGeneralNotesPageActions.createShiftNote,
    DashboardGeneralNotesPageActions.updateShiftNote,
    DashboardGeneralNotesPageActions.deleteShiftNote,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(ShiftNotesApiActions.loadShiftNotesSuccess, (state, action) => {
    const pagination = getPaginationData<IPaginationInfo>(
      state,
      action.shiftNotes
    );
    const data = getRecords(action.shiftNotes);
    return shiftNotesAdapter.setAll(data, {
      ...state,
      pagination,
      loading: false,
      loaded: false,
      error: null
    });
  }),

  on(ShiftNotesApiActions.getShiftNotesSuccess, (state, action) => {
    const pagination = getPaginationData<IPaginationInfo>(
      state,
      action.shiftNotes
    );
    const data = getRecords(action.shiftNotes);

    return shiftNotesAdapter.upsertMany(data, {
      ...state,
      pagination,
      loading: false,
      loaded: false,
      error: null
    });
  }),

  on(ShiftNotesApiActions.createShiftNoteSuccess, (state, action) => {
    return shiftNotesAdapter.addOne(action.shiftNote, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(ShiftNotesApiActions.updateShiftNoteSuccess, (state, action) => {
    return shiftNotesAdapter.upsertOne(action.shiftNote, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(ShiftNotesApiActions.deleteShiftNoteSuccess, (state, action) => {
    return shiftNotesAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    ShiftNotesApiActions.loadShiftNotesFail,
    ShiftNotesApiActions.getShiftNotesFail,
    ShiftNotesApiActions.createShiftNoteFail,
    ShiftNotesApiActions.updateShiftNoteFail,
    ShiftNotesApiActions.deleteShiftNoteFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    }
  ),

  on(
    ShiftNotesApiActions.shiftNotesCreated,
    ShiftNotesApiActions.shiftNotesPatched,
    (state, action) => {
      return shiftNotesAdapter.upsertOne(action.shiftNote, state);
    }
  ),

  on(ShiftNotesApiActions.shiftNotesRemoved, (state, action) => {
    return shiftNotesAdapter.removeOne(action.id, state);
  }),

  on(ShiftNotesGeneralActions.addShiftNotes, (state, action) => {
    return shiftNotesAdapter.upsertMany(action.shiftNotes, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(ShiftNotesGeneralActions.clearShiftNotes, () => {
    return shiftNotesAdapter.removeAll({
      ...initialState
    });
  })
);

export function shiftNotesReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
