import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ResidentLogTypesApiActions,
  ResidentLogTypesGeneralActions
} from './actions';
import {
  initialState,
  residentLogTypesAdapter
} from './resident-log-types.state';

export const residentLogTypesFeature = createFeature({
  name: 'residentLogTypes',
  reducer: createReducer(
    initialState,

    on(
      LogsPageActions.getResidentLogTypes,
      LogsPageActions.turnOnLog,
      LogsPageActions.turnOffLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      ResidentLogTypesApiActions.loadResidentLogTypesSuccess,
      (state, action) => {
        return residentLogTypesAdapter.setAll(action.residentLogTypes.data, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      ResidentLogTypesApiActions.getResidentLogTypesSuccess,
      (state, action) => {
        return residentLogTypesAdapter.upsertMany(
          action.residentLogTypes.data,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(ResidentLogTypesApiActions.createResidentLogTypeSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(ResidentLogTypesApiActions.deleteResidentLogTypeSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(
      ResidentLogTypesApiActions.loadResidentLogTypesFail,
      ResidentLogTypesApiActions.getResidentLogTypesFail,
      ResidentLogTypesApiActions.createResidentLogTypeFail,
      ResidentLogTypesApiActions.deleteResidentLogTypeFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      ResidentLogTypesApiActions.residentLogTypesCreated,
      ResidentLogTypesApiActions.residentLogTypesPatched,
      (state, action) => {
        return residentLogTypesAdapter.upsertOne(action.residentLogType, {
          ...state
        });
      }
    ),

    on(ResidentLogTypesApiActions.residentLogTypesRemoved, (state, action) => {
      return residentLogTypesAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(ResidentLogTypesGeneralActions.addResidentLogTypes, (state, action) => {
      return residentLogTypesAdapter.upsertMany(action.residentLogTypes, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(ResidentLogTypesGeneralActions.clearResidentLogTypes, (state) => {
      return residentLogTypesAdapter.removeAll({
        ...state,

        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
