import { createFeature, createReducer, on } from '@ngrx/store';
import { EventListFilterPageActions } from '../event-list-filter.action';
import { initialState } from './event-list-filter.state';

export const uiEventListPageFeature = createFeature({
  name: 'uiEventListPage',
  reducer: createReducer(
    initialState,
    on(EventListFilterPageActions.filterChanged, (state, action) => {
      return {
        ...state,
        filters: action.filters
      };
    })
  )
});
