import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppointmentsEffects } from './appointments.effects';
import { appointmentsFeature } from './appointments.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(appointmentsFeature),
    EffectsModule.forFeature([AppointmentsEffects])
  ],
  providers: [AppointmentsEffects]
})
export class AppointmentsStoreModule {}
