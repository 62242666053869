import { FeathersService } from '$/app/services/feathers.service';
import { SleepLogsApiActions } from '$/app/store/sleep-logs';
import { SleepLog } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class SleepLogsApiService extends AbstractApiService<SleepLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('sleep-logs', feathers, store, {
      entityName: 'sleepLog',
      created: SleepLogsApiActions.sleepLogsCreated,
      patched: SleepLogsApiActions.sleepLogsPatched,
      removed: SleepLogsApiActions.sleepLogsRemoved
    });
  }
}
