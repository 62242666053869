import { UserSelectors } from '$/app/store';
import { Logger } from '$shared/logger/logger';
import { PermissionId } from '$shared/permissions';
import { MaybeArray } from '$shared/types/utility-types';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { checkPermission } from '../../services/utils/authorization.service';

@Pipe({ name: 'hasPermission', standalone: true, pure: false })
export class AlcHasPermissionPipe implements PipeTransform {
  private readonly store = inject(Store);

  private readonly permissions = this.store.selectSignal(
    UserSelectors.selectPermissions
  );

  transform(permissionId: MaybeArray<PermissionId>) {
    if (!permissionId) {
      Logger.throw('A valid permissionId is required');
    }

    return checkPermission(this.permissions(), permissionId);
  }
}
