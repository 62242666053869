import { backgroundTask } from '$shared/utils/background-task';
import { castArray, isUndefined, omitBy } from 'lodash';
import { OverlayService } from './index';
import { AlcModalOptions } from './types';

export async function showModal<TComponent>(
  this: OverlayService,
  opts?: AlcModalOptions<TComponent>
) {
  const classes = opts?.cssClass ? castArray(opts?.cssClass) : [];
  const modal = await this.modalController.create({
    backdropDismiss: false,
    ...opts,
    // NOTE: If you explicitly pass undefined to a component input, the default
    // value of that input will not be set. It only gets set if the input is not
    // present. In light of this, we have to explicitly remove any undefined
    // properties.
    componentProps: omitBy(opts?.componentProps, isUndefined),
    cssClass: opts?.size
      ? [...classes, `${opts.size}-modal`]
      : classes.some((c) => c?.includes('-modal'))
        ? classes
        : [...classes, 'md-modal']
  });

  await modal.present();

  return modal;
}

export function showModal_sync<TComponent>(
  this: OverlayService,
  opts?: AlcModalOptions<TComponent>
) {
  backgroundTask('showModal_sync', () => this._showModal(opts));
}

export function showModalSingleton<TComponent>(
  this: OverlayService,
  opts?: AlcModalOptions<TComponent>
) {
  backgroundTask('showModalSingleton', async () => {
    const top = await this.modalController.getTop();

    if (top?.component !== opts.component) {
      this._showModal(opts);
    }
  });
}

export async function getModalResult<Result = any, TComponent = any>(
  this: OverlayService,
  opts?: AlcModalOptions<TComponent>
): Promise<Result> {
  const modal = await this._showModal(opts);

  const result = await modal.onDidDismiss();

  return result?.data;
}

export function dismissModal(
  this: OverlayService,
  data?: any
): Promise<boolean> {
  return this.modalController.dismiss(data);
}

export function getTopModal(this: OverlayService) {
  return this.modalController.getTop();
}
