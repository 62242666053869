import { ResidentMedicalProfessional } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥🔄 BACKEND WS ACTIONS
//

// [WS] ResidentMedicalProfessionals Created
export const residentMedicalProfessionalsCreated = createAction(
  '[ResidentMedicalProfessionals WS] ResidentMedicalProfessionals Created',
  props<{ residentMedicalProfessional: ResidentMedicalProfessional }>()
);
// [WS] ResidentMedicalProfessionals Patched
export const residentMedicalProfessionalsPatched = createAction(
  '[ResidentMedicalProfessionals WS] ResidentMedicalProfessionals Patched',
  props<{ residentMedicalProfessional: ResidentMedicalProfessional }>()
);
// [WS] ResidentMedicalProfessionals Removed
export const residentMedicalProfessionalsRemoved = createAction(
  '[ResidentMedicalProfessionals WS] ResidentMedicalProfessionals Removed',
  props<{ id: string }>()
);
