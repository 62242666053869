import { CareTask, Paginated, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CareTasksApiActions = createActionGroup({
  source: 'Care Tasks API',
  events: {
    'Load Care Tasks Success': props<{ careTasks: Paginated<CareTask> }>(),
    'Get Care Tasks Success': props<{ careTasks: Paginated<CareTask> }>(),
    'Fetch Care Task Success': props<{ careTask: CareTask }>(),
    'Create Care Task Success': props<{ careTask: MaybeArray<CareTask> }>(),
    'Update Care Task Success': props<{ careTask: Update<CareTask> }>(),
    'Delete Care Task Success': props<{ id: string }>(),

    'Load Care Tasks Fail': props<{ error: Error }>(),
    'Get Care Tasks Fail': props<{ error: Error }>(),
    'Fetch Care Task Fail': props<{ error: Error }>(),
    'Create Care Task Fail': props<{ error: Error }>(),
    'Update Care Task Fail': props<{ error: Error }>(),
    'Delete Care Task Fail': props<{ error: Error }>()
  }
});

export const CareTasksWebsocketActions = createActionGroup({
  source: 'Care Tasks WS',
  events: {
    'Care Tasks Created': props<{ careTask: CareTask }>(),
    'Care Tasks Patched': props<{ careTask: CareTask }>(),
    'Care Tasks Removed': props<{ id: string }>(),

    'Care Tasks Batch Created': props<{ careTasks: CareTask[] }>(),
    'Care Tasks Batch Patched': props<{ careTasks: CareTask[] }>(),
    'Care Tasks Batch Removed': props<{ ids: string[] }>()
  }
});

export const CareTasksGeneralActions = createActionGroup({
  source: 'Care Task General',
  events: {
    'Clear Care Tasks': emptyProps(),
    'Add Care Tasks': props<{ careTasks: CareTask[] }>()
  }
});
