import { libraryDocumentTemplatesFeature } from './library-document-templates.reducer';
import { libraryDocumentTemplatesAdapter } from './library-document-templates.state';

export const {
  selectLibraryDocumentTemplatesState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = libraryDocumentTemplatesFeature;

export const { selectAll, selectTotal } =
  libraryDocumentTemplatesAdapter.getSelectors(
    selectLibraryDocumentTemplatesState
  );
