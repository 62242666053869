import { EffectHelpersService } from '$/app/services';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResidentReportConfigService } from './resident-report-configs.service';
import { ResidentReportsPageActions } from '$/app/pages/residents/residents-reports/resident-reports.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { ResidentReportConfigsApiActions } from './resident-report-configs.actions';

@Injectable()
export class ResidentReportConfigsEffects {
  private readonly residentReportConfigsService = inject(
    ResidentReportConfigService
  );
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadResidentReportConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentReportsPageActions.loadResidentReportConfigs),
      this.effectHelpers.apiRequest({
        description: 'Load Resident Report Configs',
        useMapOperator: 'switchMap',
        onRequest: (action) =>
          this.residentReportConfigsService
            .getAll(action.params)
            .pipe(
              getAllPages(
                this.residentReportConfigsService,
                action?.params?.query
              ),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residentReportConfigs',
            response,
            ResidentReportConfigsApiActions.loadResidentReportConfigsSuccess
          );
          return responseData.getActions();
        },
        onError: (error) =>
          ResidentReportConfigsApiActions.loadResidentReportConfigsFail({
            error
          })
      })
    );
  });

  createResidentReportConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentReportsPageActions.createResidentReportConfig),
      this.effectHelpers.apiRequest({
        description: 'Create Resident Report Config',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.residentReportConfigsService.create(action.data, action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residentReportConfigs',
            response,
            ResidentReportConfigsApiActions.createResidentReportConfigSuccess,
            {
              payloadKey: 'residentReportConfig'
            }
          );
          return responseData.getActions();
        },
        onError: (error) =>
          ResidentReportConfigsApiActions.createResidentReportConfigFail({
            error
          })
      })
    );
  });

  deleteResidentReportConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentReportsPageActions.deleteReportConfig),
      this.effectHelpers.apiRequest({
        description: 'Delete Resident Report Config',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.residentReportConfigsService.delete(action.id, action.params),
        onSuccess: (response) =>
          ResidentReportConfigsApiActions.deleteResidentReportConfigSuccess({
            id: response.id
          }),
        onError: (error) =>
          ResidentReportConfigsApiActions.deleteResidentReportConfigFail({
            error
          })
      })
    );
  });

  updateResidentReportConfigs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentReportsPageActions.updateReportConfig),
      this.effectHelpers.apiRequest({
        description: 'Update Resident Report Config',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.residentReportConfigsService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (response) =>
          ResidentReportConfigsApiActions.updateResidentReportConfigSuccess({
            residentReportConfig: response
          }),
        onError: (error) =>
          ResidentReportConfigsApiActions.updateResidentReportConfigFail({
            error
          })
      })
    );
  });
}
