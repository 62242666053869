import { AlertModal } from '$/app/shared/pages/popovers/alert/alert.modal';
import { OverlayService } from './index';

export type AlcAlertOptions = {
  title?: string;
  subtitle?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  type?: 'info' | 'confirm' | 'confirm-keyword';
  keyword?: string;
  danger?: boolean;
  cssClass?: string;
  backdropDismiss?: boolean;
};

export async function showAlert(
  this: OverlayService,
  message,
  titleOrOptions?: string | AlcAlertOptions
) {
  const options: AlcAlertOptions =
    typeof titleOrOptions === 'string'
      ? { title: titleOrOptions }
      : titleOrOptions || {};

  const alert = await this.popController.create({
    component: AlertModal,
    componentProps: {
      message,
      title: options.title,
      subtitle: options.subtitle,
      primaryButtonText: options.primaryButtonText,
      secondaryButtonText: options.secondaryButtonText,
      type: options.type,
      keyword: options.keyword,
      danger: options.danger
    },
    cssClass: options.cssClass || 'alert-popover',
    backdropDismiss: options.backdropDismiss || true
  });

  await alert.present();

  const result = await alert.onWillDismiss();

  return result.data;
}
