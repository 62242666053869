import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const ViewReportPageActions = createActionGroup({
  source: 'View Report Page',
  events: {
    'Fetch Resident': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    })
  }
});
