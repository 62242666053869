import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResidentLogTypesEffects } from './resident-log-types.effects';
import { residentLogTypesFeature } from './resident-log-types.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(residentLogTypesFeature),
    EffectsModule.forFeature([ResidentLogTypesEffects])
  ],
  providers: [ResidentLogTypesEffects]
})
export class ResidentLogTypesStoreModule {}
