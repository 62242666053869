import { CareItemInfoPageActions } from '$/app/pages/care/residents/care-item-info/care-item-info.actions';
import { CarePlanItemFormActions } from '$/app/pages/care/residents/care-item-modal/care-item.modal.actions';
import { CarePlanPageActions } from '$/app/pages/care/residents/careplan/careplan.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  CarePlanItemsApiActions,
  CarePlanItemsGeneralActions,
  CarePlanItemsWsActions
} from './care-plan-items.actions';
import { carePlanItemsAdapter, initialState } from './care-plan-items.state';

export const carePlanItemsFeature = createFeature({
  name: 'carePlanItems',
  reducer: createReducer(
    initialState,

    on(
      CarePlanPageActions.loadCarePlanItems,
      CarePlanPageActions.getCarePlanItems,
      CarePlanItemFormActions.createCarePlanItem,
      CarePlanItemFormActions.updateCarePlanItem,
      CareItemInfoPageActions.fetchCarePlanItem,
      CareItemInfoPageActions.deleteCarePlanItem,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(CarePlanItemsApiActions.loadCarePlanItemsSuccess, (state, action) => {
      return carePlanItemsAdapter.setAll(action.carePlanItems.data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CarePlanItemsApiActions.getCarePlanItemsSuccess, (state, action) => {
      return carePlanItemsAdapter.upsertMany(action.carePlanItems.data, {
        ...state,

        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CarePlanItemsApiActions.fetchCarePlanItemSuccess, (state, action) => {
      return carePlanItemsAdapter.upsertOne(action.carePlanItem, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CarePlanItemsApiActions.createCarePlanItemSuccess, (state, action) => {
      const carePlanItems = castArray(action.carePlanItem);

      return carePlanItemsAdapter.addMany(carePlanItems, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CarePlanItemsApiActions.updateCarePlanItemSuccess, (state, action) => {
      return carePlanItemsAdapter.upsertOne(action.carePlanItem, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CarePlanItemsApiActions.deleteCarePlanItemSuccess, (state, action) => {
      return carePlanItemsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      CarePlanItemsApiActions.loadCarePlanItemsFail,
      CarePlanItemsApiActions.getCarePlanItemsFail,
      CarePlanItemsApiActions.fetchCarePlanItemFail,
      CarePlanItemsApiActions.createCarePlanItemFail,
      CarePlanItemsApiActions.updateCarePlanItemFail,
      CarePlanItemsApiActions.deleteCarePlanItemFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      CarePlanItemsWsActions.carePlanItemsCreated,
      CarePlanItemsWsActions.carePlanItemsPatched,
      (state, action) => {
        if (action.carePlanItem['changes']) {
          action.carePlanItem = action.carePlanItem['changes'];
        }
        return carePlanItemsAdapter.upsertOne(action.carePlanItem, {
          ...state
        });
      }
    ),

    on(CarePlanItemsWsActions.carePlanItemsRemoved, (state, action) => {
      return carePlanItemsAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(CarePlanItemsGeneralActions.addCarePlanItems, (state, action) => {
      return carePlanItemsAdapter.upsertMany(action.carePlanItems, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(CarePlanItemsGeneralActions.clearCarePlanItems, (state) => {
      return carePlanItemsAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
