import { FeathersService } from '$/app/services/feathers.service';
import { MedicationInventoryMovement, Paginated, Params } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MedicationInventoryMovementsApiService {
  private medicationInventoryMovementsService: Service<MedicationInventoryMovement>;

  constructor(feathers: FeathersService) {
    this.medicationInventoryMovementsService = feathers.client.service(
      'medication-inventory-movements'
    );
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<MedicationInventoryMovement>> {
    const promise = this.medicationInventoryMovementsService.find(
      params
    ) as Promise<Paginated<MedicationInventoryMovement>>;
    return from(promise);
  }
}
