import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const PharmaciesListActions = createActionGroup({
  source: 'Pharmacies Page',
  events: {
    'Load Pharmacies': (params: Params = { query: {} }) => ({ params }),
    'Get Pharmacies': (params: Params = { query: {} }) => ({ params })
  }
});
