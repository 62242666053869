import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MedicationOrderItemsEffects } from './medication-order-items.effects';
import { medicationOrderItemsReducer } from './medication-order-items.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('medicationOrderItems', medicationOrderItemsReducer),
    EffectsModule.forFeature([MedicationOrderItemsEffects])
  ],
  providers: [MedicationOrderItemsEffects]
})
export class MedicationOrderItemsStoreModule {}
