<ion-toolbar class="!pt-0">
  <div class="max-w[800px] flex items-center justify-between">
    <ion-button
      fill="clear"
      color="medium"
      slot="start"
      (click)="previousDay()"
    >
      <ion-icon slot="icon-only" src="/assets/icon/material/chevron_left.svg" />
    </ion-button>
    <div
      class="date-picker-container flex items-center justify-center"
      (click)="dateInput.datePicker.open()"
    >
      <ion-button fill="clear" [color]="'primary' | alcTheme">
        {{ currentDate.toFormat(format) }}
      </ion-button>
      <alc-date-input
        #dateInput
        class="invisible absolute left-[50%] ml-[-95px] text-center"
        (dateChange)="changeDate($event)"
        [(ngModel)]="currentDate"
      />
    </div>
    <ion-button fill="clear" color="medium" slot="end" (click)="nextDay()">
      <ion-icon
        slot="icon-only"
        src="/assets/icon/material/chevron_right.svg"
      />
    </ion-button>
  </div>
</ion-toolbar>
