import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { IncidentReportsEffects } from './incident-reports.effects';
import { incidentReportsFeature } from './incident-reports.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(incidentReportsFeature),
    EffectsModule.forFeature([IncidentReportsEffects])
  ],
  providers: [IncidentReportsEffects]
})
export class IncidentReportsStoreModule {}
