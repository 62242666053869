import { Paginated, Room } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const RoomsApiActions = createActionGroup({
  source: 'Rooms API',
  events: {
    'Load Rooms Success': props<{ rooms: Paginated<Room> }>(),
    'Load Rooms Fail': props<{ error: Error }>(),
    'Fetch Room Success': props<{ room: Room }>(),
    'Fetch Room Fail': props<{ error: Error }>(),
    'Create Room Success': props<{ room: Room }>(),
    'Create Room Fail': props<{ error: Error }>(),
    'Update Room Success': props<{ room: Room }>(),
    'Update Room Fail': props<{ error: Error }>(),
    'Delete Room Success': props<{ id: string }>(),
    'Delete Room Fail': props<{ error: Error }>()
  }
});

export const RoomsWsActions = createActionGroup({
  source: 'Rooms WS',
  events: {
    'Rooms Created': props<{ room: Room }>(),
    'Rooms Patched': props<{ room: Room }>(),
    'Rooms Removed': props<{ id: string }>()
  }
});

export const RoomsGeneralActions = createActionGroup({
  source: 'Rooms General',
  events: {
    'Clear Rooms': emptyProps(),

    'Add Rooms': props<{ rooms: Room[] }>()
  }
});
