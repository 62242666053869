<ion-header>
  <ion-toolbar color="danger">
    <h2 class="ion-text-wrap mx-0 my-4 px-4 text-lg">{{ title }}</h2>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div>
    @if (message) {
      <div
        class="text-dark"
        [ngClass]="{ 'whitespace-pre-line': !isHTML }"
        [innerHTML]="safeMessage"
      ></div>
    }
    @if (serverMessage) {
      <div class="mt-8 text-dark">
        <h3 class="m-0 mb-1 text-sm font-medium">Server Message</h3>
        <p class="m-0 whitespace-pre-line">
          {{ serverMessage }}
        </p>
      </div>
    }

    <p class="mt-8">
      <alc-contact-support />
    </p>

    @if (error?.data?.requestId) {
      <div class="text-dark">
        <h3 class="m-0 mb-1 text-sm font-medium">Request ID</h3>

        <p class="m-0 flex items-center justify-between">
          <small>{{ error.data.requestId }}</small>

          <button mat-icon-button (click)="copy()">
            <mat-icon>content_copy</mat-icon>
          </button>
        </p>
      </div>
    }
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button shape="round" fill="solid" color="danger" (click)="close()">
        Ok
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
