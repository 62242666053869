import { MedicalEvent } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearMedicalEvents = createAction(
  '[MedicalEvents] Clear MedicalEvents'
);

export const addMedicalEvents = createAction(
  'Add Medical Events',
  props<{ medicalEvents: MedicalEvent[] }>()
);
