import { getOrReport } from '$shared/utils/get-or-report';
import { ToastOptions } from '@ionic/core';
import { OverlayService } from './index';

type ToastType = 'success' | 'warning' | 'failure' | 'info';

const colorsByType = {
  success: 'dark',
  warning: 'warning',
  failure: 'danger',
  info: 'dark'
} as const;

export async function showToast(
  this: OverlayService,
  type: ToastType,
  message: string,
  options: ToastOptions = {}
) {
  const toast = await this.toastController.create({
    message,
    duration: 2000,
    position: 'bottom',
    color: getOrReport(colorsByType, type, 'Invalid toast type'),
    buttons: [
      {
        icon: 'close',
        role: 'cancel'
      }
    ],
    ...options
  });

  await toast.present();
}
