import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { foodLogsReducer } from './food-logs.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FoodLogsEffects } from './food-logs.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('food-log', foodLogsReducer),
    EffectsModule.forFeature([FoodLogsEffects])
  ],
  providers: [FoodLogsEffects]
})
export class FoodLogStoreModule {}
