import { AmountSegment } from '../types';
import { parseAmountString } from './parse-amount-string';
import { AmountDisplayOptions, stringifyAmount } from './stringify-amount';

export function multiplyAmountString(
  qty: number,
  amount: string,
  options?: AmountDisplayOptions
): string | undefined {
  if (!amount) {
    return;
  }

  const amountSegments = parseAmountString(amount);

  const multipliedSegments = amountSegments.map((segment): AmountSegment => {
    if (typeof segment === 'string') {
      return segment;
    }

    return {
      amount: (+segment.amount * qty).toString(),
      measurement: segment.measurement
    };
  });

  return stringifyAmount(multipliedSegments, options);
}
