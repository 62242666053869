import {
  flatMap,
  forEach,
  has,
  isArray,
  isNaN,
  isNil,
  last,
  partial
} from 'lodash';

const ifTrueBlock = Symbol('ifTrueBlock');
const ifFalseBlock = Symbol('ifFalseBlock');
const elseIfTrueBlock = Symbol('elseIfTrueBlock');
const elseIfFalseBlock = Symbol('elseIfFalseBlock');
const elseBlock = Symbol('elseBlock');
const endIfBlock = Symbol('endIfBlock');
const ignoreBlock = Symbol('ignoreBlock');

export class TemplateResult {
  constructor(private value: string) {}

  public toString(): string {
    return this.value;
  }
}

interface Context {
  parts: string[];
  nestedBlocks: any[];
}

export function template(
  strings: TemplateStringsArray,
  ...values: any[]
): TemplateResult {
  const context: Context = {
    parts: [],
    nestedBlocks: []
  };

  const segments = flatMap(strings, (value, index) => {
    return [
      new TemplateResult(value),
      has(values, index) && values[index]
    ].filter(Boolean);
  });

  forEach(segments, partial(processValue, context));

  return new TemplateResult(context.parts.join(''));
}

template.if = (condition: any) => (condition && ifTrueBlock) || ifFalseBlock;
template.elseIf = (condition: any) =>
  (condition && elseIfTrueBlock) || elseIfFalseBlock;
template.else = () => elseBlock;
template.endIf = () => endIfBlock;

function processValue(context: Context, value: any): void {
  const { nestedBlocks, parts } = context;

  const topBlock = last(nestedBlocks);

  switch (true) {
    case value === ifTrueBlock:
      nestedBlocks.push(value);
      break;

    case value === ifFalseBlock:
      nestedBlocks.push(ignoreBlock);
      break;

    case value === elseIfTrueBlock:
    case value === elseBlock:
      nestedBlocks.pop();
      nestedBlocks.push(topBlock === ignoreBlock ? value : ignoreBlock);
      break;

    case value === elseIfFalseBlock:
      nestedBlocks.pop();
      nestedBlocks.push(ignoreBlock);
      break;

    case value === endIfBlock:
      nestedBlocks.pop();
      break;

    case nestedBlocks.includes(ignoreBlock):
      break;

    case value instanceof TemplateResult:
      parts.push(value.toString());
      break;

    case isArray(value):
      forEach(value, partial(processValue, context));
      break;

    case isNil(value) || isNaN(value):
      // Don't display crazy values
      break;

    default:
      parts.push(
        String(value)
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
      );
      break;
  }
}
