import { EntityActionMap } from '$/config/entity-action-map';
import { IPaginationInfo } from '$/models';
import { NormalizrEntities } from '$shared';
import { Logger } from '$shared/logger';
import { Action, ActionCreator } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { normalize, schema } from 'normalizr';
import {
  NormalActionOptions,
  NormalizedAction,
  createActionsFromNormalizedData
} from './create-actions-from-normalized-data';
import { getFieldsToRemove } from './normalization/get-fields-to-remove';
import { normalizeToArrayAll } from './normalization/normalize-to-array-all';
import { determinePagination } from './pagination/determine-pagination';

/**
 * Primary action default config options:
 * - dispatchOnEmpty = true
 * - paginated = paginated data - data property (when data type is paginated)
 * - singleRecord = true (when data type is object)
 */
export class ApiData {
  private _entityName: string;
  private _rawData;
  private _normalizedData: Record<string, Record<string, any>[]>;
  private _dataType: 'array' | 'paginated' | 'object' | null;
  private _pagination: IPaginationInfo;
  private _primaryAction: ActionCreator;
  private actionMap: Record<string, ActionCreator | NormalizedAction>;

  get entityName(): string {
    return this._entityName;
  }

  get data() {
    if (this.dataType === 'paginated') {
      return this._rawData.data;
    }
    return this._rawData;
  }

  get normalizedData(): Record<string, Record<string, any>[]> {
    return this._normalizedData;
  }

  get pagination(): IPaginationInfo {
    return this._pagination;
  }

  get dataType(): 'array' | 'paginated' | 'object' | null {
    return this._dataType;
  }

  get primaryAction(): ActionCreator {
    return this._primaryAction;
  }

  constructor(
    entityName: string,
    responseData,
    primaryAction: ActionCreator = null,
    actionConfig?: NormalActionOptions
  ) {
    this._entityName = entityName;
    this._rawData = responseData;
    this.setDataType();
    this.normalizeData();
    this.createDefaultActionMap();
    if (primaryAction) {
      this.setPrimaryAction(primaryAction, actionConfig);
    }
  }

  setPrimaryAction(action: ActionCreator, config: NormalActionOptions = {}) {
    if (!action) {
      throw new Error('You must provide an action to set the primary action.');
    }

    config.dispatchOnEmpty = config.dispatchOnEmpty ?? true;

    config.paginated = config.paginated ?? this.pagination;

    // Set default singleRecord config option when data type is object
    if (config.singleRecord === undefined && this.dataType === 'object') {
      if (config.payloadKey === undefined && config.hasPayload) {
        Logger.warn(`
          The response data is a single record, but payloadKey is not defined. 

          This will cause an action to be dispatched where the payload key is the entity
          name in its plural form, which in most cases is not desireable due to the way
          actions and reducers are set up. It would be better to provide a payload key 
          that is the entity name in its singular form to match the reducer's handling
          of actions with single records.`);
      }

      config.singleRecord = true;
    }

    this.setAction(action, config);
  }

  /**
   * Allow us to override actions defined in the default action map.
   *
   */
  setAction(action: ActionCreator, config?: NormalActionOptions) {
    if (!this.actionMap[this.entityName] && !isEmpty(this.actionMap)) {
      Logger.warn('entity in actionMap does not exist', {
        entityName: this.entityName,
        actionMap: this.actionMap
      });
    }

    if (config?.singleRecord && !config.payloadKey) {
      Logger.warn(`
        Entity ${this.entityName} is configured as a single record but payloadKey is 
        not defined. This will cause an action to be dispatched where the payload key is 
        the entity name in its plural form, which in most cases is not desireable due to 
        the way actions and reducers are set up. It would be better to provide a payload 
        key that is the entity name in its singular form to match the reducer's handling
        of actions with single records.`);
    }

    this.actionMap[this.entityName] = new NormalizedAction(action, config);
  }

  /**
   * Generates actions that contain their respective payloads from the normalized data.
   *
   */
  getActions(): Action[] {
    return createActionsFromNormalizedData(this.normalizedData, this.actionMap);
  }

  /**
   * Determines the type of data returned by the response.
   * This can be an array, a paginated data object, or an object.
   *
   */
  private setDataType() {
    if (Array.isArray(this._rawData)) {
      this._dataType = 'array';
    } else if (typeof this._rawData === 'object') {
      const isPaginated = determinePagination(this._rawData);

      if (isPaginated) {
        this._dataType = 'paginated';

        this._pagination = {
          total: this._rawData.total,
          limit: this._rawData.limit,
          skip: this._rawData.skip
        };
      } else {
        this._dataType = 'object';
      }
    } else {
      this._dataType = null;
    }
  }

  /**
   * Takes a graph response and normalizes it to a dictionary
   * where the keys are the entity names (pluralized) and the
   * values are arrays of records for that entity. This is needed
   * because NgRx Entity adapter functions expect an array of records
   * for multi record operations.
   *
   */
  private normalizeData() {
    let schema: schema.Entity | schema.Entity[] =
      NormalizrEntities[this.entityName]?.schema;

    if (!schema) {
      throw new Error(
        `Entity '${this.entityName}' does not have a schema is NormalizrEntities`
      );
    }

    if (this.dataType === 'array' || this.dataType === 'paginated') {
      schema = [schema];
    }

    const normalizedGraph = normalize(this.data, schema);

    const fieldsToRemove = getFieldsToRemove(normalizedGraph);

    this._normalizedData = normalizeToArrayAll(
      normalizedGraph.entities,
      fieldsToRemove
    );
  }

  /**
   * Creates a dictionary of actions for the entities that where normalized during
   * the data normalization process. Entity names map to actions that will be dispatched.
   *
   */
  private createDefaultActionMap() {
    if (!this.normalizedData) {
      throw new Error(
        `'normalizedData' is not defined for entity '${this.entityName}'`
      );
    }

    this.actionMap = Object.keys(this.normalizedData)
      // Make primary entity the first action fired. This makes it easier to identify it
      // in the action stream in redux dev tools
      .sort((a) => (a === this.entityName ? -1 : 1))
      .reduce(
        (
          actionMap: Record<string, ActionCreator>,
          entityName
        ): Record<string, ActionCreator> => {
          actionMap[entityName] = EntityActionMap[entityName];

          return actionMap;
        },
        {}
      );
  }
}
