import { Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, isObservable } from 'rxjs';

type SignalOrObservable<T> = Signal<T> | Observable<T>;

export function computedWith<T, Z>(
  t: SignalOrObservable<T>,
  transform: (t: Signal<T>) => Z
): Signal<Z>;

export function computedWith<T, U, Z>(
  t: SignalOrObservable<T>,
  u: SignalOrObservable<U>,
  transform: (t: Signal<T>, u: Signal<U>) => Z
): Signal<Z>;

export function computedWith<T, U, V, Z>(
  t: SignalOrObservable<T>,
  u: SignalOrObservable<U>,
  v: SignalOrObservable<V>,
  transform: (t: Signal<T>, u: Signal<U>, v: Signal<V>) => Z
): Signal<Z>;

export function computedWith<T, U, V, W, Z>(
  t: SignalOrObservable<T>,
  u: SignalOrObservable<U>,
  v: SignalOrObservable<V>,
  w: SignalOrObservable<W>,
  transform: (t: Signal<T>, u: Signal<U>, v: Signal<V>, w: Signal<W>) => Z
): Signal<Z>;

export function computedWith<T, U, V, W, X, Z>(
  t: SignalOrObservable<T>,
  u: SignalOrObservable<U>,
  v: SignalOrObservable<V>,
  w: SignalOrObservable<W>,
  x: SignalOrObservable<X>,
  transform: (
    t: Signal<T>,
    u: Signal<U>,
    v: Signal<V>,
    w: Signal<W>,
    x: Signal<X>
  ) => Z
): Signal<Z>;

export function computedWith<T, U, V, W, X, Y, Z>(
  t: SignalOrObservable<T>,
  u: SignalOrObservable<U>,
  v: SignalOrObservable<V>,
  w: SignalOrObservable<W>,
  x: SignalOrObservable<X>,
  y: SignalOrObservable<Y>,
  transform: (
    t: Signal<T>,
    u: Signal<U>,
    v: Signal<V>,
    w: Signal<W>,
    x: Signal<X>,
    y: Signal<Y>
  ) => Z
): Signal<Z>;

export function computedWith<T, U, V, W, X, Y, A, Z>(
  t: SignalOrObservable<T>,
  u: SignalOrObservable<U>,
  v: SignalOrObservable<V>,
  w: SignalOrObservable<W>,
  x: SignalOrObservable<X>,
  y: SignalOrObservable<Y>,
  a: SignalOrObservable<A>,
  transform: (
    t: Signal<T>,
    u: Signal<U>,
    v: Signal<V>,
    w: Signal<W>,
    x: Signal<X>,
    y: Signal<Y>,
    a: Signal<A>
  ) => Z
): Signal<Z>;

export function computedWith<T, U, V, W, X, Y, A, B, Z>(
  t: SignalOrObservable<T>,
  u: SignalOrObservable<U>,
  v: SignalOrObservable<V>,
  w: SignalOrObservable<W>,
  x: SignalOrObservable<X>,
  y: SignalOrObservable<Y>,
  a: SignalOrObservable<A>,
  b: SignalOrObservable<B>,
  transform: (
    t: Signal<T>,
    u: Signal<U>,
    v: Signal<V>,
    w: Signal<W>,
    x: Signal<X>,
    y: Signal<Y>,
    a: Signal<A>,
    b: Signal<B>
  ) => Z
): Signal<Z>;

/**
 * Allows the creation of a computed signal that depends on other signals,
 * without requiring that the "source" signals be given their own variables.
 *
 * The "sources" can be signals or Observables. If they are Observables,
 * they will be converted to signals when passed to the transform function.
 */
export function computedWith(...args: any[]): Signal<any> {
  const transform = args.pop();
  const signals: Signal<any>[] = args.map((arg) =>
    isObservable(arg) ? toSignal(arg) : arg
  );

  return computed(() => transform(...signals));
}
