import { Paginated } from '$/models';
import { IBehaviorLog } from '$shared/services/behavior-log';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BehaviorLogsApiActions = createActionGroup({
  source: 'Behavior Logs API',
  events: {
    'Load Behavior Logs Success': props<{
      behaviorLogs: Paginated<IBehaviorLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Behavior Logs Success': props<{
      behaviorLogs: Paginated<IBehaviorLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Create Behavior Log Success': props<{ behaviorLog: IBehaviorLog }>(),
    'Update Behavior Log Success': props<{ behaviorLog: IBehaviorLog }>(),
    'Delete Behavior Log Success': props<{ id: string }>(),

    'Load Behavior Logs Fail': props<{ error: Error }>(),
    'Get Behavior Logs Fail': props<{ error: Error }>(),
    'Create Behavior Log Fail': props<{ error: Error }>(),
    'Update Behavior Log Fail': props<{ error: Error }>(),
    'Delete Behavior Log Fail': props<{ error: Error }>()
  }
});

export const BehaviorLogsWsActions = createActionGroup({
  source: 'Behavior Logs WS',
  events: {
    'Behavior Logs Created': props<{ behaviorLog: IBehaviorLog }>(),
    'Behavior Logs Patched': props<{ behaviorLog: IBehaviorLog }>(),
    'Behavior Logs Removed': props<{ id: string }>()
  }
});

export const BehaviorLogsGeneralActions = createActionGroup({
  source: 'Behavior Logs General',
  events: {
    'Clear Behavior Logs': emptyProps(),
    'Add Behavior Logs': props<{
      behaviorLogs: IBehaviorLog[];
    }>()
  }
});
