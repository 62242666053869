import { AllergyFormPageActions } from '$/app/pages/residents/resident-detail/allergy-form/allergy-form.actions';
import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { AllergiesApiService } from '$/app/services';
import { EffectHelpersService } from '$/app/services/utils/effect-helpers.service';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AllergiesApiActions } from './allergies.actions';

@Injectable()
export class AllergiesEffects {
  private readonly actions$ = inject(Actions);
  private readonly allergiesService = inject(AllergiesApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  createAllergy$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AllergyFormPageActions.createAllergy),
        this.effectHelpers.apiRequest({
          description: 'creating allergy',
          onRequest: (action) => this.allergiesService.create(action.allergy),
          useMapOperator: 'exhaustMap',
          onSuccess: (allergy) =>
            AllergiesApiActions.createAllergySuccess({ allergy }),
          onError: (error) => AllergiesApiActions.createAllergyFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  createAllergySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AllergiesApiActions.createAllergySuccess),
        tap(
          this.effectHelpers.onModalFormSubmitSuccess(
            'Allergy created successfully'
          )
        )
      );
    },
    { dispatch: false }
  );

  createAllergyFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AllergiesApiActions.createAllergyFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateAllergy$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AllergyFormPageActions.updateAllergy),
        this.effectHelpers.apiRequest({
          description: 'Updating allergy',
          onRequest: (action) =>
            this.allergiesService.patch(action.id, action.changes),
          onSuccess: (allergy) =>
            AllergiesApiActions.updateAllergySuccess({ allergy }),
          onError: (error) => AllergiesApiActions.updateAllergyFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  updateAllergySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AllergiesApiActions.updateAllergySuccess),
        tap(
          this.effectHelpers.onModalFormSubmitSuccess(
            'Allergy updated successfully!'
          )
        )
      );
    },
    { dispatch: false }
  );

  updateAllergyFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AllergiesApiActions.updateAllergyFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteAllergy$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentFacesheetPageActions.deleteResidentAllergy),
        this.effectHelpers.apiRequest({
          description: 'Deleting allergy',
          onRequest: (action) => this.allergiesService.delete(action.id),
          useMapOperator: 'exhaustMap',
          onSuccess: (allergy) =>
            AllergiesApiActions.deleteAllergySuccess({ id: allergy.id }),
          onError: (error) => AllergiesApiActions.deleteAllergyFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  deleteAllergyFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AllergiesApiActions.deleteAllergyFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
