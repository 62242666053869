import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { SeizureLogsPageActions } from '$/app/pages/notebook/shared/logs/seizure-log/seizure-log.actions';
import { EffectHelpersService, SeizureLogsApiService } from '$/app/services';
import { SeizureLogFormPageActions } from '$/app/shared/pages/forms/log-forms/seizure-log-form/seizure-log-form.actions';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { SeizureLog } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { SeizureLogsApiActions } from './actions';

@Injectable()
export class SeizureLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly seizureLogsService = inject(SeizureLogsApiService);

  loadSeizureLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SeizureLogsPageActions.loadSeizureLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.seizureLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('seizureLogs', logs);
            responseData.setPrimaryAction(
              SeizureLogsApiActions.loadSeizureLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getSeizureLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SeizureLogsPageActions.getSeizureLogs,
        LogsPageActions.getSeizureLogs,
        GivePrnFormActions.getSeizureLogs
      ),
      switchMap((action) => {
        return this.seizureLogsService.getAll(action.params).pipe(
          getAllPages(this.seizureLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('seizureLogs', logs);
            responseData.setPrimaryAction(
              SeizureLogsApiActions.getSeizureLogsSuccess
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createSeizureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SeizureLogFormPageActions.createSeizureLog),
      exhaustMap((action) => {
        return this.seizureLogsService.create(action.seizureLog).pipe(
          tap((data: SeizureLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((seizureLog: SeizureLog) =>
            SeizureLogsApiActions.createSeizureLogSuccess({
              seizureLog
            })
          ),
          catchError((error) =>
            of(
              SeizureLogsApiActions.createSeizureLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateSeizureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SeizureLogFormPageActions.updateSeizureLog),
      switchMap((action) => {
        return this.seizureLogsService.patch(action.id, action.changes).pipe(
          tap((data: SeizureLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((seizureLog: SeizureLog) => {
            return SeizureLogsApiActions.updateSeizureLogSuccess({
              seizureLog
            });
          }),
          catchError((error) => {
            return of(
              SeizureLogsApiActions.updateSeizureLogFail({
                error
              })
            );
          })
        );
      })
    );
  });

  deleteSeizureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SeizureLogsPageActions.deleteSeizureLog,
        ResidentMedicationTasksPageActions.deleteSeizureLog,
        MissedMedicationsPageActions.deleteSeizureLog,
        RoutineMarDetailPageActions.deleteSeizureLog,
        RoutineMarListPageActions.deleteSeizureLog,
        GivePrnFormActions.deleteSeizureLog,
        CompleteCareTaskModalActions.deleteSeizureLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete seizure log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.seizureLogsService.delete(action.id, action.params);
        },
        onSuccess: (seizureLog: SeizureLog) =>
          SeizureLogsApiActions.deleteSeizureLogSuccess({
            id: seizureLog.id
          }),
        onError: (error) =>
          SeizureLogsApiActions.deleteSeizureLogFail({ error })
      })
    );
  });
}
