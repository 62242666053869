import { Update } from '$/models';

interface EntityId {
  id?: string;
}

export function toUpdated<T extends EntityId>(data: T): Update<T> {
  return {
    id: data.id,
    changes: data
  };
}
