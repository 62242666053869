import { FacilityUserPhone } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥🔄 BACKEND WS ACTIONS
//

// [WS] FacilityUserPhones Created
export const facilityUserPhonesCreated = createAction(
  '[FacilityUserPhones WS] FacilityUserPhones Created',
  props<{ facilityUserPhone: FacilityUserPhone }>()
);
// [WS] FacilityUserPhones Patched
export const facilityUserPhonesPatched = createAction(
  '[FacilityUserPhones WS] FacilityUserPhones Patched',
  props<{ facilityUserPhone: FacilityUserPhone }>()
);
// [WS] FacilityUserPhones Removed
export const facilityUserPhonesRemoved = createAction(
  '[FacilityUserPhones WS] FacilityUserPhones Removed',
  props<{ id: string }>()
);
