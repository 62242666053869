import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IBloodSugarLog } from '$shared/services/blood-sugar-log';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { bloodSugarLogsFeature } from './blood-sugar-logs.reducer';
import { bloodSugarLogsAdapter } from './blood-sugar-logs.state';

export const {
  selectBloodSugarLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = bloodSugarLogsFeature;

export const { selectAll, selectTotal } = bloodSugarLogsAdapter.getSelectors(
  selectBloodSugarLogsState
);

export const selectPagination = (query) => {
  return createSelector(selectBloodSugarLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentBloodSugarLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (bpLogs, residentId): IBloodSugarLog[] => {
    if (isEmpty(bpLogs) || !residentId) {
      return [];
    }

    return bpLogs.filter((bpLog) => {
      return bpLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<IBloodSugarLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IBloodSugarLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IBloodSugarLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const computedMetrics = logs.reduce(
      (acc, log) => {
        acc.count += 1;
        acc.sum += log.level;
        acc.avg = Math.round(acc.sum / acc.count);

        return acc;
      },
      {
        count: 0,
        sum: 0,
        avg: 0
      }
    );

    const min = logs.length > 0 && Math.min(...logs.map((log) => log.level));
    const max = logs.length > 0 && Math.max(...logs.map((log) => log.level));

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length || 0
      },
      {
        type: 'value',
        label: 'Avg.',
        data: (computedMetrics.avg || 0).toFixed(0)
      },
      {
        type: 'value',
        label: 'Low',
        data: min || 0
      },
      {
        type: 'value',
        label: 'High',
        data: max || 0
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
