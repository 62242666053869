import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const CarePlanPageActions = createActionGroup({
  source: 'Care Plan Page',
  events: {
    'Load Care Plan Items': (params: Params = { query: {} }) => ({ params }),
    'Get Care Plan Items': (params: Params = { query: {} }) => ({ params })
  }
});
