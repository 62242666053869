import { NarcoticCountListPageActions } from '$/app/pages/medications/dashboard/narcotic-counts/narcotic-count-list/narcotic-count-list.actions';
import { InventoryItemDetailPageActions } from '$/app/pages/medications/residents/inventory-item-detail/inventory-item-detail.actions';
import { InventoryItemCountModalActions } from '$/app/pages/medications/shared/components/inventory-item-count-modal/inventory-item-count.modal.actions';
import { EffectHelpersService } from '$/app/services';
import { TMedicationInventoryItemCount } from '$shared/medications/medication-inventory-item-count.schema';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicationInventoryItemCountsApiActions } from './medication-inventory-item-counts.actions';
import { MedicationInventoryItemCountsApiService } from './medication-inventory-item-counts.service';

@Injectable()
export class MedicationInventoryItemCountsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly apiService = inject(MedicationInventoryItemCountsApiService);

  createMedicationInventoryItemCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryItemCountModalActions.createMedicationInventoryItemCount),
      this.effectHelpers.apiRequest({
        description: 'Create Medication Inventory Item Count',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.apiService.create(
            action.medicationInventoryItemCount,
            action.params
          );
        },
        onSuccess: (medicationInventoryItemCount) =>
          MedicationInventoryItemCountsApiActions.createMedicationInventoryItemCountSuccess(
            {
              medicationInventoryItemCount
            }
          ),
        onError: (error) =>
          MedicationInventoryItemCountsApiActions.createMedicationInventoryItemCountFail(
            { error }
          )
      })
    );
  });

  updateMedicationInventoryItemCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryItemCountModalActions.updateMedicationInventoryItemCount),
      this.effectHelpers.apiRequest({
        description: 'Update Medication Inventory Item Count',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.apiService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (medicationInventoryItemCount) =>
          MedicationInventoryItemCountsApiActions.updateMedicationInventoryItemCountSuccess(
            {
              medicationInventoryItemCount
            }
          ),
        onError: (error) =>
          MedicationInventoryItemCountsApiActions.updateMedicationInventoryItemCountFail(
            { error }
          )
      })
    );
  });

  deleteMedicationInventoryItemCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        NarcoticCountListPageActions.deleteMedicationInventoryItemCount,
        InventoryItemDetailPageActions.deleteMedicationInventoryItemCount
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete Medication Inventory Item Count',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.apiService.delete(action.id, action.params);
        },
        onSuccess: (
          medicationInventoryItemCount: TMedicationInventoryItemCount
        ) =>
          MedicationInventoryItemCountsApiActions.deleteMedicationInventoryItemCountSuccess(
            { id: medicationInventoryItemCount.id }
          ),
        onError: (error) =>
          MedicationInventoryItemCountsApiActions.deleteMedicationInventoryItemCountFail(
            { error }
          )
      })
    );
  });
}
