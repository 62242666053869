import { MedicationInventoryMovement } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearMedicationInventoryMovements = createAction(
  '[MedicationInventoryMovements] Clear MedicationInventoryMovements'
);

export const addMedicationInventoryMovements = createAction(
  'Add MedicationInventoryMovements',
  props<{ medicationInventoryMovements: MedicationInventoryMovement[] }>()
);
