import * as PrnsListActions from './prns-list.actions';
import * as PrnFormActions from './prn-form.actions';
import * as PrnsApiActions from './prns-api.actions';
import * as PrnsWsActions from './prns-ws.actions';
import * as PrnsGeneralActions from './prns-gen.actions';

export {
  PrnsListActions,
  PrnFormActions,
  PrnsApiActions,
  PrnsWsActions,
  PrnsGeneralActions
};
