import { Params, Update } from '$/models';
import { IDestructionMethod } from '$shared/services/destruction-method.schema';

import { createActionGroup, props } from '@ngrx/store';

export const DestructionMethodModalActions = createActionGroup({
  source: 'Destruction Method Form Page',
  events: {
    'Create Destruction Method': props<{
      destructionMethod: IDestructionMethod;
      params: Params;
    }>(),
    'Update Destruction Method': props<Update<IDestructionMethod>>()
  }
});
