import { ObjectOptions, TObject, TProperties, Type } from '@sinclair/typebox';
import { BanProperties } from '../../types';

export function StrictObject<T extends TProperties>(
  properties: T,
  options?: BanProperties<ObjectOptions, 'additionalProperties'>
): TObject<T> {
  return Type.Object(properties, {
    ...options,
    additionalProperties: false
  });
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function LooseObject<T extends TProperties = {}>(
  properties?: T,
  options?: BanProperties<ObjectOptions, 'additionalProperties'>
): TObject<T> {
  return Type.Object(properties ?? {}, {
    ...options,
    additionalProperties: true
  }) as any;
}
