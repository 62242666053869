import { sortByDatetime } from '$/app/utils';
import { IResidentReportConfig } from '$shared/services/resident-report-configs';
import { createEntityAdapter } from '@ngrx/entity';

export const residentReportConfigsAdapter =
  createEntityAdapter<IResidentReportConfig>({
    sortComparer: sortByDatetime('createdAt', true)
  });

export const initialState = residentReportConfigsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
