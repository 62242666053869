import { MedicationInventory } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥👈 USER ACTIONS
//

// Create MedicationInventory
export const createMedicationInventory = createAction(
  '[MedicationInventory Form] Create MedicationInventory',
  props<{ medicationInventory: MedicationInventory }>()
);
