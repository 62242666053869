import { Organization } from '$/models';
import { createEntityAdapter } from '@ngrx/entity';

export const organizationsAdapter = createEntityAdapter<Organization>();

export const initialState = organizationsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
