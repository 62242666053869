import { MedicationSchedulesApiService } from '$/app/services';
import { MedicationSchedule, Paginated } from '$/models';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  MedicationScheduleDetailActions,
  MedicationSchedulesApiActions,
  MedicationSchedulesListActions
} from './actions';

@Injectable()
export class MedicationSchedulesEffects {
  constructor(
    private actions$: Actions,
    private medicationSchedulesService: MedicationSchedulesApiService
  ) {}

  loadMedicationSchedules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationSchedulesListActions.loadMedicationSchedules),
      switchMap((action) => {
        return this.medicationSchedulesService.getAll(action.params).pipe(
          map((medicationSchedules: Paginated<MedicationSchedule>) => {
            return MedicationSchedulesApiActions.loadMedicationSchedulesSuccess(
              {
                medicationSchedules
              }
            );
          }),
          catchError((error) => {
            return of(
              MedicationSchedulesApiActions.loadMedicationSchedulesFail({
                error
              })
            );
          })
        );
      })
    );
  });

  getMedicationSchedules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationSchedulesListActions.getMedicationSchedules),
      switchMap((action) => {
        return this.medicationSchedulesService.getAll(action.params).pipe(
          map((medicationSchedules: Paginated<MedicationSchedule>) => {
            return MedicationSchedulesApiActions.getMedicationSchedulesSuccess({
              medicationSchedules
            });
          }),
          catchError((error) => {
            return of(
              MedicationSchedulesApiActions.getMedicationSchedulesFail({
                error
              })
            );
          })
        );
      })
    );
  });

  fetchMedicationSchedule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationScheduleDetailActions.fetchMedicationSchedule),
      switchMap((action) => {
        return this.medicationSchedulesService
          .get(action.id, action.params)
          .pipe(
            map((medicationSchedule: MedicationSchedule) => {
              return MedicationSchedulesApiActions.fetchMedicationScheduleSuccess(
                {
                  medicationSchedule
                }
              );
            }),
            catchError((error) => {
              return of(
                MedicationSchedulesApiActions.fetchMedicationScheduleFail({
                  error
                })
              );
            })
          );
      })
    );
  });
}
