import { IFacilityUser } from '$shared/services/facility-user.schema';
import { Medication } from '../data';

export class PrnRecord {
  id?: string;
  medication: Medication;
  reasonGiven: string;
  givenAt: string | Date;
  givenBy: IFacilityUser;
  followUpDateTime: string;
  response?: string;
  responseNotes?: string;
}
