import { Params } from '$/models';
import { ResidentGroupMember } from '$/models/data/entities/residents/resident-group-member.model';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentGroupMemberPickerActions = createActionGroup({
  source: 'Resident Group Member Picker',
  events: {
    'Get Resident Group Members': props<{ params: Params }>(),

    'Create Resident Group Member': (
      residentGroupMember: ResidentGroupMember,
      params: Params = { query: {} }
    ) => ({ residentGroupMember, params }),

    'Delete Resident Group Member': props<{ id: string }>()
  }
});
