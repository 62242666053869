import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { BodyCheckLogsPageActions } from '$/app/pages/notebook/shared/logs/body-check-logs/body-check-log.actions';
import { getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  BodyCheckLogsApiActions,
  BodyCheckLogsGeneralActions,
  BodyCheckLogsWsActions
} from './body-check-logs.actions';
import { bodyCheckLogsAdapter, initialState } from './body-check-logs.state';
import { BodyCheckLogFormPageActions } from '$/app/shared/pages/forms/log-forms/body-check-log-form/body-check-log-form.actions';

export const bodyCheckLogsFeature = createFeature({
  name: 'bodyCheckLogs',
  reducer: createReducer(
    initialState,
    on(
      BodyCheckLogsPageActions.loadBodyCheckLogs,
      BodyCheckLogsPageActions.getBodyCheckLogs,
      BodyCheckLogFormPageActions.createBodyCheckLog,
      BodyCheckLogFormPageActions.updateBodyCheckLog,
      BodyCheckLogsPageActions.deleteBodyCheckLog,
      ResidentMedicationTasksPageActions.deleteBodyCheckLog,
      MissedMedicationsPageActions.deleteBodyCheckLog,
      RoutineMarListPageActions.deleteBodyCheckLog,
      RoutineMarDetailPageActions.deleteBodyCheckLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),
    on(BodyCheckLogsApiActions.loadBodyCheckLogsSuccess, (state, action) => {
      const data = getRecords(action.bodyCheckLogs);

      return bodyCheckLogsAdapter.setAll(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(BodyCheckLogsApiActions.getBodyCheckLogsSuccess, (state, action) => {
      const data = getRecords(action.bodyCheckLogs);

      return bodyCheckLogsAdapter.upsertMany(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(BodyCheckLogsApiActions.createBodyCheckLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(BodyCheckLogsApiActions.updateBodyCheckLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(BodyCheckLogsApiActions.deleteBodyCheckLogSuccess, (state, action) =>
      bodyCheckLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),
    on(
      BodyCheckLogsApiActions.loadBodyCheckLogsFail,
      BodyCheckLogsApiActions.getBodyCheckLogsFail,
      BodyCheckLogsApiActions.createBodyCheckLogFail,
      BodyCheckLogsApiActions.updateBodyCheckLogFail,
      BodyCheckLogsApiActions.deleteBodyCheckLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),
    on(
      BodyCheckLogsWsActions.bodyCheckLogsCreated,
      BodyCheckLogsWsActions.bodyCheckLogsPatched,
      (state, action) => {
        return bodyCheckLogsAdapter.upsertOne(action.bodyCheckLog, state);
      }
    ),
    on(BodyCheckLogsWsActions.bodyCheckLogsRemoved, (state, action) => {
      return bodyCheckLogsAdapter.removeOne(action.id, state);
    }),
    on(BodyCheckLogsGeneralActions.addBodyCheckLogs, (state, action) => {
      return bodyCheckLogsAdapter.upsertMany(action.bodyCheckLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(BodyCheckLogsGeneralActions.clearBodyCheckLogs, () => {
      return bodyCheckLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
