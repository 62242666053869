import {
  entries,
  identity,
  isArray,
  isEmpty,
  isFunction,
  map,
  sortBy
} from 'lodash';
import { Logger } from './logger';
import { chainFlow } from './utils';

const DEFAULT_OPTIONS = { concurrency: 10 };

interface IOptions {
  concurrency?: number;
}

type AsyncFunc = () => Promise<any>;

type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;

type PromisifyObjectResults<T extends Record<string, AsyncFunc>> = {
  [K in keyof T]: UnwrapPromise<ReturnType<T[K]>>;
};

export async function parallelMap<T, U = T>(
  items: T[],
  processor?: (item: T, index: number) => Promise<U>,
  options?: IOptions
): Promise<U[]>;

export async function parallelMap<T, U = T>(
  items: Record<string, T>,
  processor: (item: T, key: string) => Promise<U>,
  options?: IOptions
): Promise<Record<string, U>>;

export async function parallelMap<T extends Record<string, AsyncFunc>>(
  items: T,
  options?: IOptions
): Promise<PromisifyObjectResults<T>>;

export async function parallelMap<T>(...args: any[]): Promise<any> {
  const {
    items,
    processor,
    options: { concurrency }
  } = decodeArgs(args);

  const resultsByItemKey: Record<string, T> = {};
  const promisesByItemKey: Record<string, Promise<string | number>> = {};

  const processItem = async (
    item: T,
    key: string | number
  ): Promise<string | number> => {
    resultsByItemKey[key] = await processor(item, key);
    return key;
  };

  for (const [keyOrIndex, item] of entries(items)) {
    // If it's an array, convert the key to a number (index)
    const key = isArray(items) ? Number(keyOrIndex) : keyOrIndex;

    // Wait for a slot to open up
    const promises = Object.values(promisesByItemKey);
    if (promises.length === concurrency) {
      delete promisesByItemKey[await Promise.race(promises)];
    }

    // Start processing the item
    promisesByItemKey[key] = processItem(item as T, key);
  }

  // Wait for everything to finish
  await Promise.all(Object.values(promisesByItemKey));

  return isArray(items)
    ? chainFlow(
        resultsByItemKey,
        (items) => map(items, (item, key) => ({ key: Number(key), item })),
        (items) => sortBy(items, 'key'),
        (items) => map(items, 'item')
      )
    : resultsByItemKey;
}

function decodeArgs<T>(args: any[]): {
  items: T[];
  processor: (...args: any[]) => any;
  options: IOptions;
} {
  Logger.assert(args.length >= 1 && args.length <= 3, 'Invalid arguments', {
    args
  });

  const items = args[0];

  if (isEmpty(items)) {
    return { items: [], processor: identity, options: DEFAULT_OPTIONS };
  }

  if (isArray(items)) {
    switch (args.length) {
      case 1:
        // parallelMap(items: T[])
        return { items, processor: identity, options: DEFAULT_OPTIONS };

      case 2:
        // parallelMap(
        //   items: T[],
        //   processor: (item: T) => Promise<U>)
        return { items, processor: args[1], options: DEFAULT_OPTIONS };

      case 3:
        // parallelMap(
        //   items: T[],
        //   processor: (item: T) => Promise<U>,
        //   options: IOptions)
        return {
          items,
          processor: args[1],
          options: { ...DEFAULT_OPTIONS, ...(args[2] ?? {}) }
        };
    }
  }

  // parallelMap(
  //   items: Record<string, T>,
  //   processor: (item: T) => Promise<U>)
  //   options?: IOptions)
  if (isFunction(args[1])) {
    return {
      items,
      processor: args[1],
      options: { ...DEFAULT_OPTIONS, ...(args[2] ?? {}) }
    };
  }

  // parallelMap(
  //   items: Record<string, AsyncFunc>,
  //   options?: IOptions)
  return {
    items,
    processor: (item: () => any) => item(),
    options: { ...DEFAULT_OPTIONS, ...(args[2] ?? {}) }
  };
}
