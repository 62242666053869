import { MedicationOrderItem, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationOrderItemsApiActions = createActionGroup({
  source: 'MedicationOrderItems API',
  events: {
    'Get Medication Order Item Success': props<{
      medicationOrderItem: MedicationOrderItem[];
    }>(),
    'Get Medication Order Items Fail': props<{ error: Error }>(),

    'Update Medication Order Item Success': props<{
      medicationOrderItem: Update<MedicationOrderItem>;
    }>(),
    'Update Medication Order Item Fail': props<{ error: Error }>(),

    'Delete Medication Order Item Success': props<{ id: string }>(),
    'Delete Medication Order Item Fail': props<{ error: Error }>()
  }
});

export const MedicationOrderItemsWSActions = createActionGroup({
  source: 'MedicationOrderItems WS',
  events: {
    'Medication Order Items Created': props<{
      medicationOrderItem: MedicationOrderItem;
    }>(),

    'Medication Order Items Patched': props<{
      medicationOrderItem: MedicationOrderItem;
    }>(),

    'Medication Order Items Removed': props<{ id: string }>()
  }
});

export const MedicationOrderItemsGeneralActions = createActionGroup({
  source: 'MedicationOrderItems General',
  events: {
    'Add Medication Order Items': props<{
      medicationOrderItems: MedicationOrderItem[];
    }>()
  }
});
