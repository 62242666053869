import { FeathersService } from '$/app/services/feathers.service';
import { ResidentInsurancesWsActions } from '$/app/store/resident-insurances/resident-insurances.actions';
import { ResidentInsurance } from '$/models/data/entities/residents/residentInsurance.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class ResidentInsurancesApiService extends AbstractApiService<ResidentInsurance> {
  constructor(feathers: FeathersService, store: Store) {
    super('resident-insurances', feathers, store, {
      entityName: 'residentInsurance',
      created: ResidentInsurancesWsActions.residentInsurancesCreated,
      patched: ResidentInsurancesWsActions.residentInsurancesPatched,
      removed: ResidentInsurancesWsActions.residentInsurancesRemoved
    });
  }
}
