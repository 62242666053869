import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const BodyCheckLogsPageActions = createActionGroup({
  source: 'Resident Body Check Log Page',
  events: {
    'Load Body Check Logs': (params: Params) => ({ params }),
    'Get Body Check Logs': (params: Params) => ({ params }),
    'Delete Body Check Log': props<{ id: string; params?: Params }>()
  }
});
