import { createSelector } from '@ngrx/store';
import { filesFeature } from './files.reducer';
import { filesAdapter } from './files.state';

export const {
  selectFilesState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = filesFeature;

export const { selectAll, selectTotal } =
  filesAdapter.getSelectors(selectFilesState);

export const selectFile = (fileId: string) =>
  createSelector(selectEntities, (files) => files[fileId]);
