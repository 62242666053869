import { sortByFields } from '$/app/utils';
import { Pharmacy } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const pharmaciesAdapter = createEntityAdapter<Pharmacy>({
  sortComparer: sortByFields('id')
});

export interface State extends EntityState<Pharmacy> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = pharmaciesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
