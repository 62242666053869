import { ResidentGroupFormActions } from '$/app/pages/residents/resident-group-form/resident-group-form.actions';
import { ResidentGroupPickerFormPageActions } from '$/app/pages/residents/resident-group-picker-form/resident-group-picker-form.actions';
import { ResidentGroupActions } from '$/app/pages/residents/resident-groups/resident-groups-list.actions';
import { ResidentGroupPageRouteActions } from '$/app/pages/residents/residents-routing.actions';
import { EffectHelpersService } from '$/app/services';
import { ResidentGroupsApiService } from '$/app/services/api/resident-groups.service';
import {
  createActionsFromNormalizedData,
  normalizeAndRemove,
  NormalizedAction
} from '$/app/utils';
import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { residentGroupSchema } from '$shared';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { ResidentGroupMembersGeneralActions } from '../resident-group-members';
import {
  ResidentGroupDetailActions,
  ResidentGroupsApiActions,
  ResidentGroupsGeneralActions
} from './actions';

@Injectable()
export class ResidentGroupsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly residentGroupsService = inject(ResidentGroupsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadResidentGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentGroupActions.loadResidentGroups,
        ResidentGroupPageRouteActions.loadResidentGroups
      ),
      switchMap((action) => {
        return this.residentGroupsService.getAll(action.params).pipe(
          mergeMap((residentGroups: ResidentGroup[]) => {
            const normalizedData = normalizeAndRemove(
              residentGroups,
              [residentGroupSchema],
              { residentGroups: ['members'] }
            );

            return createActionsFromNormalizedData(normalizedData, {
              residentGroups: new NormalizedAction(
                ResidentGroupsGeneralActions.addResidentGroups,
                { dispatchOnEmpty: true }
              ),
              residentGroupMembers: new NormalizedAction(
                ResidentGroupMembersGeneralActions.addResidentGroupMembers,
                { dispatchOnEmpty: true }
              )
            });
          }),
          catchError((error) => {
            return of(
              ResidentGroupsApiActions.loadResidentGroupsFail({
                error
              })
            );
          })
        );
      })
    );
  });

  getResidentGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentGroupActions.getResidentGroups,
        ResidentGroupPickerFormPageActions.getResidentGroups
      ),
      switchMap((action) => {
        return this.residentGroupsService.getAll(action.params).pipe(
          map((residentGroups: ResidentGroup[]) => {
            return ResidentGroupsApiActions.getResidentGroupsSuccess({
              residentGroups
            });
          }),
          catchError((error) => {
            return of(
              ResidentGroupsApiActions.getResidentGroupsFail({
                error
              })
            );
          })
        );
      })
    );
  });

  fetchResidentGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentGroupDetailActions.fetchResidentGroup),
      switchMap((action) => {
        return this.residentGroupsService.get(action.id, action.params).pipe(
          map((residentGroup: ResidentGroup) => {
            return ResidentGroupsApiActions.fetchResidentGroupSuccess({
              residentGroup
            });
          }),
          catchError((error) => {
            return of(
              ResidentGroupsApiActions.fetchResidentGroupFail({
                error
              })
            );
          })
        );
      })
    );
  });

  createResidentGroup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentGroupFormActions.createResidentGroup),
        exhaustMap((action) => {
          return this.residentGroupsService.create(action.residentGroup).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Created successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                ResidentGroupsApiActions.createResidentGroupFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  updateResidentGroup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentGroupDetailActions.updateResidentGroup),
        switchMap((action) => {
          return this.residentGroupsService
            .patch(action.id, action.changes)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'Updated successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  ResidentGroupsApiActions.updateResidentGroupFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  deleteResidentGroup$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentGroupDetailActions.deleteResidentGroup,
          ResidentGroupActions.deleteResidentGroup
        ),
        exhaustMap((action) => {
          return this.residentGroupsService.delete(action.id).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Deleted successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                ResidentGroupsApiActions.deleteResidentGroupFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  residentGroupsFailMessages$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentGroupsApiActions.createResidentGroupFail,
          ResidentGroupsApiActions.updateResidentGroupFail,
          ResidentGroupsApiActions.deleteResidentGroupFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
