import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OmittedAmountsEffects } from './omitted-amounts.effects';
import { omittedAmountsFeature } from './omitted-amounts.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(omittedAmountsFeature),
    EffectsModule.forFeature([OmittedAmountsEffects])
  ],
  providers: [OmittedAmountsEffects]
})
export class OmittedAmountsStoreModule {}
