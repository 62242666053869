import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DocumentPacketDocumentsEffects } from './document-packet-documents.effects';
import { documentPacketDocumentsFeature } from './document-packet-documents.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(documentPacketDocumentsFeature),
    EffectsModule.forFeature([DocumentPacketDocumentsEffects])
  ],
  providers: [DocumentPacketDocumentsEffects]
})
export class DocumentPacketDocumentsStoreModule {}
