import { NgForOf } from '@angular/common';
import { Directive, Host } from '@angular/core';

// For this directive to work, it must have this very specific selector
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngForTrackByIndex]', standalone: true })
export class AlcTrackByIndexDirective {
  constructor(@Host() ngFor: NgForOf<any>) {
    ngFor.ngForTrackBy = (index) => index;
  }
}
