import * as RouterSelectors from '$/app/store/router/router.selectors';
import { Prn } from '$/models';
import { Dictionary } from '$shared/types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, prnsAdapter } from './prns.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;
export const getTotalRecords = (state: State): number => state.total;
export const getSkip = (state: State): number => state.skip;

// Feature Selector
export const selectPrnsState = createFeatureSelector<State>('prns');
const entitySelectors = prnsAdapter.getSelectors(selectPrnsState);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extra Selectors
export const selectLoading = createSelector(selectPrnsState, getLoading);
export const selectLoaded = createSelector(selectPrnsState, getLoaded);
export const selectError = createSelector(selectPrnsState, getError);
export const selectTotalRecords = createSelector(
  selectPrnsState,
  getTotalRecords
);

export const selectSkip = createSelector(selectPrnsState, getSkip);

// Select the PRN with the prnId param
export const selectPrn = createSelector(
  selectEntities,
  RouterSelectors.selectParam('prnId'),
  (prns: Dictionary<Prn>, prnId) => {
    return prns[prnId];
  }
);
