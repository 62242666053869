import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FilesEffects } from './files.effects';
import { filesFeature } from './files.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(filesFeature),
    EffectsModule.forFeature([FilesEffects])
  ],
  providers: [FilesEffects]
})
export class FilesStoreModule {}
