import { FilesApiService, OverlayService } from '$/app/services';
import { PDFCapabilitiesService } from '$/app/services/utils/pdf-capabilities.service';
import { AlcPdfViewerComponent } from '$/app/shared/components/pdf-viewer/pdf-viewer.component';
import { AlcExtractFileNameFromPathPipe } from '$/app/shared/pipes';
import { SharedModule } from '$/app/shared/shared.module';
import { readFileAsDataUrl, stripDataUrlPrefix } from '$/app/utils';
import { Logger } from '$shared/logger';
import { IFile } from '$shared/services/file.schema';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { FileSharer } from '@byteowls/capacitor-filesharer';
import { Store } from '@ngrx/store';
import { Subject, from } from 'rxjs';

@Component({
  selector: 'alc-file-viewer-modal',
  standalone: true,
  imports: [
    SharedModule,
    AlcPdfViewerComponent,
    AlcExtractFileNameFromPathPipe
  ],
  templateUrl: './file-viewer-modal.page.html',
  host: {
    class: 'ion-page'
  }
})
export class AlcFileViewerModalComponent implements OnInit, OnDestroy {
  private readonly fileService = inject(FilesApiService);
  private readonly overlay = inject(OverlayService);
  private readonly store = inject(Store);
  private readonly pdfCapabilitiesService = inject(PDFCapabilitiesService);

  isImage: boolean;
  canShare: boolean;
  canPrint: boolean;

  shareTooltip: string;
  shareIcon: string;

  @Input() fileId?: string;
  @Input() file?: IFile;

  @ViewChild(AlcPdfViewerComponent)
  pdfViewer: AlcPdfViewerComponent;

  private destroyed$ = new Subject<void>();

  ngOnInit() {
    Logger.assert(
      this.fileId || this.file,
      'Either fileId or file is required'
    );

    if (!this.file) {
      from(this.fileService.getFileWithSignedUrl(this.fileId)).subscribe(
        (file) => {
          this.updateFileInfo(file);
        }
      );
    } else {
      this.updateFileInfo(this.file);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  close() {
    this.overlay.dismissModal();
  }

  print() {
    if (this.pdfViewer) {
      return this.pdfViewer.print();
    }
  }

  async share() {
    if (this.pdfViewer) {
      return this.pdfViewer.share();
    }

    const url = await this.fileService.getUrl(this.file.id);
    const response = await fetch(url);

    await FileSharer.share({
      filename: this.file.path.split('/').pop(),
      contentType: this.file.contentType,
      base64Data: stripDataUrlPrefix(
        await readFileAsDataUrl(await response.blob())
      )
    });
  }

  private updateFileInfo(file) {
    this.file = file;
    this.isImage = false;

    const fileExists = Boolean(this.file);

    switch (file?.contentType) {
      case 'application/pdf':
        this.canPrint = this.pdfCapabilitiesService.canPrint;
        this.canShare = true;
        this.shareTooltip = this.pdfCapabilitiesService.shareTooltip;
        this.shareIcon = this.pdfCapabilitiesService.shareIcon;
        break;

      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/webp':
        this.isImage = true;
        this.canPrint = false;
        this.canShare = true;
        this.shareTooltip = 'Download image';
        this.shareIcon = 'assets/icon/material/download.svg';
        break;

      default:
        this.canPrint = false;
        this.canShare = fileExists;
        this.shareTooltip = 'Download file';
        this.shareIcon = 'assets/icon/material/download.svg';
    }
  }
}
