import { FacilityUserRole } from '$/models';
import { createEntityAdapter } from '@ngrx/entity';

export const facilityUserRolesAdapter = createEntityAdapter<FacilityUserRole>();

export const initialState = facilityUserRolesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
