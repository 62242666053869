import { MedicationInventory } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearMedicationInventories = createAction(
  '[MedicationInventories] Clear MedicationInventories'
);

export const addMedicationInventories = createAction(
  'Add MedicationInventories',
  props<{ medicationInventories: MedicationInventory[] }>()
);
