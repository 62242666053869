import { FeathersService } from '$/app/services/feathers.service';
import { LibraryDocumentTemplatePacketsWsActions } from '$/app/store/library-document-template-packets';
import { ILibraryDocumentTemplatePacket } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class LibraryDocumentTemplatePacketsApiService extends AbstractApiService<ILibraryDocumentTemplatePacket> {
  constructor(feathers: FeathersService, store: Store) {
    super('library-document-template-packets', feathers, store, {
      entityName: 'libraryDocumentTemplatePacket',
      created:
        LibraryDocumentTemplatePacketsWsActions.libraryDocumentTemplatePacketCreated,
      patched:
        LibraryDocumentTemplatePacketsWsActions.libraryDocumentTemplatePacketPatched,
      removed:
        LibraryDocumentTemplatePacketsWsActions.libraryDocumentTemplatePacketRemoved
    });
  }
}
