import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const MedicationListPageActions = createActionGroup({
  source: 'Medication List Page',
  events: {
    'Load Resident Medications': (params: Params = { query: {} }) => ({
      params
    }),

    'Fetch Resident': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Bulk Flag To Order': (ids: string[], params: Params = { query: {} }) => ({
      id: null,
      medication: {
        needsOrdering: true
      },
      params: {
        ...params,
        query: {
          ...(params?.query ?? {}),
          id: {
            $in: ids
          }
        }
      }
    }),
    'Bulk Unflag To Order': (
      ids: string[],
      params: Params = { query: {} }
    ) => ({
      id: null,
      medication: {
        needsOrdering: false
      },
      params: {
        ...params,
        query: {
          ...(params?.query ?? {}),
          id: {
            $in: ids
          }
        }
      }
    })
  }
});
