import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DocumentPacketsEffects } from './document-packets.effects';
import { documentPacketsFeature } from './document-packets.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(documentPacketsFeature),
    EffectsModule.forFeature([DocumentPacketsEffects])
  ],
  providers: [DocumentPacketsEffects]
})
export class DocumentPacketsStoreModule {}
