import { Table } from '$/models/data/base';

export class Announcement extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  subject?: string;
  body?: string;
  totalAcknowledgements?: number;
  readAcknowledgements?: number;
  totalReplies?: number;
}

export interface AnnouncementMetrics {
  totalAcknowledgements: number;
  readAcknowledgements: number;
  totalReplies: number;
}
