import { NotificationsSelectors } from '$/app/store/notifications';
import { computedWith } from '$/lib/signals/computed-with';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AlcCommonModule } from '../../alc-common.module';

@Component({
  selector: 'alc-notification-button',
  standalone: true,
  imports: [AlcCommonModule],
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcNotificationButtonComponent {
  private readonly store = inject(Store);

  protected readonly count = computedWith(
    this.store.selectSignal(NotificationsSelectors.selectUnreadCount),
    (count) => (count() > 99 ? '99+' : count().toString())
  );
}
