import { FeathersService } from '$/app/services/feathers.service';
import { BmLogsWsActions } from '$/app/store/bowel-movement-logs/bowel-movement-logs.actions';
import { ApiData } from '$/app/utils';
import { Paginated, Params } from '$/models';
import { IBmLog } from '$shared/services/bm-log';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BmLogsApiService {
  private bmLogsService: Service<IBmLog>;

  constructor(feathers: FeathersService, store: Store) {
    this.bmLogsService = feathers.client.service('bm-logs');

    this.bmLogsService.on('created', (bmLog) => {
      const responseData = new ApiData('bmLogs', bmLog);
      responseData.setPrimaryAction(BmLogsWsActions.bmLogsCreated, {
        payloadKey: 'bmLog'
      });
      responseData.getActions().forEach((action) => {
        store.dispatch(action);
      });
    });

    this.bmLogsService.on('patched', (bmLog) => {
      store.dispatch(
        BmLogsWsActions.bmLogsPatched({
          bmLog
        })
      );
    });

    this.bmLogsService.on('removed', (bmLog) => {
      store.dispatch(
        BmLogsWsActions.bmLogsRemoved({
          id: bmLog.id
        })
      );
    });
  }

  getAll(params: Params) {
    const result = this.bmLogsService.find(params) as Promise<
      Paginated<IBmLog>
    >;

    return from(result);
  }

  create(bmLog: IBmLog): Observable<IBmLog> {
    return from(this.bmLogsService.create(bmLog));
  }

  patch(
    id: string,
    bmLog: Partial<IBmLog>,
    params: Params = { query: {} }
  ): Observable<IBmLog> {
    const promise = this.bmLogsService.patch(id, bmLog, params);

    return from(promise);
  }

  delete(id: string, params: Params = { query: {} }): Observable<IBmLog> {
    return from(this.bmLogsService.remove(id, params));
  }
}
