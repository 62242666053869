import { IFacilitySetting } from '$shared/facility-settings/types';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const facilitySettingsAdapter = createEntityAdapter<IFacilitySetting>();

export interface State extends EntityState<IFacilitySetting> {
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = facilitySettingsAdapter.getInitialState({
  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
