import { Paginated, TemperatureLog } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TemperatureLogsApiActions = createActionGroup({
  source: 'Temperature Logs API',
  events: {
    'Load Temperature Logs Success': props<{
      temperatureLogs: Paginated<TemperatureLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Temperature Logs Success': props<{
      temperatureLogs: Paginated<TemperatureLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Create Temperature Log Success': props<{
      temperatureLog: TemperatureLog;
    }>(),
    'Update Temperature Log Success': props<{
      temperatureLog: TemperatureLog;
    }>(),
    'Delete Temperature Log Success': props<{ id: string }>(),

    'Load Temperature Logs Fail': props<{ error: Error }>(),
    'Get Temperature Logs Fail': props<{ error: Error }>(),
    'Create Temperature Log Fail': props<{ error: Error }>(),
    'Update Temperature Log Fail': props<{ error: Error }>(),
    'Delete Temperature Log Fail': props<{ error: Error }>()
  }
});

export const TemperatureLogsWsActions = createActionGroup({
  source: 'Temperature Logs WS',
  events: {
    'Temperature Logs Created': props<{ temperatureLog: TemperatureLog }>(),
    'Temperature Logs Patched': props<{ temperatureLog: TemperatureLog }>(),
    'Temperature Logs Removed': props<{ id: string }>()
  }
});

export const TemperatureLogsGeneralActions = createActionGroup({
  source: 'App Component',
  events: {
    'Clear Temperature Logs': emptyProps,
    'Add Temperature Logs': props<{ temperatureLogs: TemperatureLog[] }>()
  }
});
