import { IIncidentReport } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const IncidentReportsGeneralActions = createActionGroup({
  source: 'Incident Reports General',
  events: {
    'Clear Incident Reports': emptyProps(),
    'Add Incident Reports': props<{ incidentReports: IIncidentReport[] }>()
  }
});
