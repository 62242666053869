import { sortByName } from '$/app/utils';
import { ResidentInsurance } from '$/models/data/entities/residents/residentInsurance.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const residentInsurancesAdapter = createEntityAdapter<ResidentInsurance>(
  { sortComparer: sortByName }
);

export interface State extends EntityState<ResidentInsurance> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = residentInsurancesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
