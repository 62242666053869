import { Announcement } from '../data';

export class AnnouncementCard extends Announcement {
  date?: string;
  isRead?: boolean;
  canConfirm?: boolean;
  metrics?;
  replyCount?: number;
  createdBy?: string;
}
