import { isRegExp, zipWith } from 'lodash';

const MIME_TYPE = /^[a-zA-Z]+\/[a-zA-Z]+$/;

/**
 * Matches the beginning of a base64 string
 *
 * For performance reasons, this regex is not exhaustive. It only validates the beginning of the string.
 *
 * A prior version of this regex attempted to validate the entire string, but it had terrible peformance, and could cause out-of-memory errors.
 */
const BASE64_STRING = /^[A-Za-z\d+/_-]{1,100}/;

const ISO_TIMESTAMP =
  /^([+-]?\d{4}(?!\d{2}\b))(-(0[1-9]|1[0-2])(-(0[1-9]|[12]\d|3[01]))?|W(0[1-9]|[1-4]\d|5[0-2])(-[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))(T([01]\d|2[0-3]):[0-5]\d:[0-5]\d(.[0-9]+)?|(24:00)(.[0-9]+)?)(Z|[+-][01]\d:[0-5]\d)?$/;

const ISO_DATE =
  /^([+-]?\d{4}(?!\d{2}\b))(-(0[1-9]|1[0-2])(-(0[1-9]|[12]\d|3[01]))?|W(0[1-9]|[1-4]\d|5[0-2])(-[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))((T([01]\d|2[0-3]):[0-5]\d:[0-5]\d(.[0-9]+)?|(24:00)(.[0-9]+)?)(Z|[+-][01]\d:[0-5]\d)?)?$/;

const UUID4 =
  /^(?:[a-fA-F\d]{8}-[a-fA-F\d]{4}-4[a-fA-F\d]{3}-[89abAB][a-fA-F\d]{3}-[a-fA-F\d]{12})|(?:00000000-0000-0000-0000-000000000000)$/;

const PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/;

const WEB_URL =
  /^(?:https?:\/\/)?(?:(?:[a-zA-Z\d](?:[a-zA-Z\d-]*[a-zA-Z\d])*(?:\.[a-zA-Z\d](?:[a-zA-Z\d-]*[a-zA-Z\d])*)*)|(?:(?:\d{1,3}\.){3}\d{1,3}))(?::\d+)?(?:\/[-a-zA-Z\d%_.~+]*)*(?:\?[;&a-zA-Z\d%_.~+=-]*)?(?:#[-a-zA-Z\d_]*)?$/;

/**
 * Matches the beginning of a well-formed, base64-encoded data url
 *
 * For performance reasons, this regex is not exhaustive. It only validates the beginning of the string.
 *
 * A prior version of this regex attempted to validate the entire string, but it had terrible peformance, and could cause out-of-memory errors.
 */
const DATA_URL = regex`^data:${MIME_TYPE};base64,${BASE64_STRING}`;

const EMAIL =
  /^[a-zA-Z\d.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?(?:\.[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?)+$/;

const PHONE_NUMBER = /^(\+1[-.]?)?\d{3}[-.]?\d{3}[-.]?\d{4}$/;

const SCHEDULE_TIME = /^(0?[\d]|1[\d]|2[0-3]):[0-5][\d]$/;

const CONTAINS_UPPERCASE = /[A-Z]/;

const CONTAINS_LOWERCASE = /[a-z]/;

const CONTAINS_NUMBER = /\d/;

const INTEGER = /^\d+$/;
const FLOAT_1 = /^\d*\.\d+$/;
const FLOAT_2 = /^\d+\.\d*$/;
const SLASH = /^\/$/;
const NUMBER = regex`(${FLOAT_1})|(${FLOAT_2})|(${INTEGER})`;
const RANGE = regex`(${NUMBER})\-(${NUMBER})`;
const RATIO = regex`(${NUMBER})\:(${NUMBER})`;
const NUMBER_TYPE = regex`((${RANGE})|(${RATIO})|(${NUMBER}))`;

/**
 * matches valid medication strengths and doses
 *
 * Examples:
 *
 * integers
 * 1, 10
 *
 * floats:
 * 1.0, .1
 *
 * ranges:
 * 1-2, 1.0-2.0, .1-3
 *
 * ratios:
 * 1:2, 1.0:2.0, .1:3
 *
 * any combination of the above separated by slashes:
 * 1/2, 1.0/2.0, .1/3, 1-2/3-4, 1:2/3:4
 *
 * */
const MEDICATION_STRENGTH = regex`^(${NUMBER_TYPE})(${SLASH}${NUMBER_TYPE})*$`;

export const REGEX = {
  MIME_TYPE,

  /**
   * Matches the beginning of a base64 string
   *
   * For performance reasons, this regex is not exhaustive. It only validates the beginning of the string.
   *
   * A prior version of this regex attempted to validate the entire string, but it had terrible peformance, and could cause out-of-memory errors.
   */
  BASE64_STRING,

  ISO_TIMESTAMP,
  ISO_DATE,
  UUID4,
  PASSWORD,
  WEB_URL,

  /**
   * Matches the beginning of a well-formed, base64-encoded data url
   *
   * For performance reasons, this regex is not exhaustive. It only validates the beginning of the string.
   *
   * A prior version of this regex attempted to validate the entire string, but it had terrible peformance, and could cause out-of-memory errors.
   */
  DATA_URL,
  EMAIL,
  PHONE_NUMBER,
  SCHEDULE_TIME,
  CONTAINS_UPPERCASE,
  CONTAINS_LOWERCASE,
  CONTAINS_NUMBER,
  MEDICATION_STRENGTH
};

export function regex(strings: TemplateStringsArray, ...values: any[]): RegExp {
  const pattern = zipWith(strings, values, (str, value) => {
    if (isRegExp(value)) {
      value = value.source.replace(/^\^|\$$/g, '');
    }

    return str + (value ?? '');
  }).join('');

  return new RegExp(pattern);
}
