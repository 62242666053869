import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DocumentRelationsGeneralActions,
  DocumentRelationsWsActions
} from './document-relations.actions';
import {
  documentRelationsAdapter,
  initialState
} from './document-relations.state';

export const documentRelationsFeature = createFeature({
  name: 'documentRelations',
  reducer: createReducer(
    initialState,

    on(
      DocumentRelationsWsActions.documentRelationCreated,
      DocumentRelationsWsActions.documentRelationPatched,
      (state, action) => {
        return documentRelationsAdapter.upsertOne(
          action.documentRelation,
          state
        );
      }
    ),

    on(DocumentRelationsWsActions.documentRelationRemoved, (state, action) => {
      return documentRelationsAdapter.removeOne(action.id, state);
    }),

    on(
      DocumentRelationsGeneralActions.addDocumentRelations,
      (state, action) => {
        return documentRelationsAdapter.upsertMany(action.documentRelations, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(DocumentRelationsGeneralActions.clearDocumentRelations, () => {
      return documentRelationsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
