import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BehaviorLogsEffects } from './behavior-logs.effects';
import { behaviorLogsFeature } from './behavior-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(behaviorLogsFeature),
    EffectsModule.forFeature([BehaviorLogsEffects])
  ],
  providers: [BehaviorLogsEffects]
})
export class BehaviorLogsStoreModule {}
