import { FeathersService } from '$/app/services/feathers.service';
import { CareTasksWebsocketActions } from '$/app/store/care-tasks/care-tasks.actions';
import { toUpdated } from '$/app/utils';
import { CareTask, Paginated, Params, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CareTasksApiService {
  private careTasksService: Service<CareTask>;

  constructor(feathers: FeathersService, store: Store) {
    this.careTasksService = feathers.client.service('care-tasks');

    this.careTasksService.on('created', (careTask) => {
      store.dispatch(CareTasksWebsocketActions.careTasksCreated({ careTask }));
    });

    this.careTasksService.on('patched', (careTask) => {
      careTask = toUpdated(careTask);

      store.dispatch(CareTasksWebsocketActions.careTasksPatched({ careTask }));
    });

    this.careTasksService.on(
      'batchUpdate',
      ({ createdTasks, patchedTasks, deletedTaskIds }) => {
        store.dispatch(
          CareTasksWebsocketActions.careTasksBatchCreated({
            careTasks: createdTasks
          })
        );
        store.dispatch(
          CareTasksWebsocketActions.careTasksBatchPatched({
            careTasks: patchedTasks
          })
        );
        store.dispatch(
          CareTasksWebsocketActions.careTasksBatchRemoved({
            ids: deletedTaskIds
          })
        );
      }
    );

    this.careTasksService.on('removed', (careTask) => {
      store.dispatch(
        CareTasksWebsocketActions.careTasksRemoved({ id: careTask.id })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<Paginated<CareTask>> {
    const promise = this.careTasksService.find(params) as Promise<
      Paginated<CareTask>
    >;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<CareTask> {
    const promise = this.careTasksService.get(id, params);
    return from(promise);
  }

  create(careTask: MaybeArray<CareTask>, params: Params = { query: {} }) {
    const promise = this.careTasksService.create(careTask, params);
    return from(promise);
  }

  patch(
    id: string,
    careTask: Partial<CareTask>,
    params: Params = { query: {} }
  ): Observable<Update<CareTask>> {
    const promise = this.careTasksService.patch(id, careTask, params);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string, params: Params = { query: {} }): Observable<CareTask> {
    const promise = this.careTasksService.remove(id, params);
    return from(promise);
  }
}
