import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  State,
  residentContactPhonesAdapter
} from './resident-contact-phones.state';

export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;

// Resident Contact Phones State Selectors
export const selectResidentContactPhonesState = createFeatureSelector<State>(
  'resident-contact-phones'
);

const entitySelectors = residentContactPhonesAdapter.getSelectors(
  selectResidentContactPhonesState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectResidentContactPhonesState,
  getLoading
);
export const selectLoaded = createSelector(
  selectResidentContactPhonesState,
  getLoaded
);
export const selectError = createSelector(
  selectResidentContactPhonesState,
  getError
);
