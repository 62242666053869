import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export type SizeInputValue = 'xs' | 'sm' | 'md' | 'lg' | '2xl' | 'full';

@Component({
  standalone: true,
  selector: 'alc-responsive-content',
  templateUrl: './responsive-content.component.html',
  styles: `
    :host {
      @apply flex flex-col items-center;
    }
  `,
  host: {
    class: 'md:pt-12 pb-20 md:pt-12'
  },
  imports: [CommonModule]
})
export class AlcResponsiveContentComponent {
  @Input({ transform: coerceBooleanProperty }) padding: boolean = false;
  @Input() size: SizeInputValue = '2xl';
}
