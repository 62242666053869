import { Params, ShiftNote, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DashboardGeneralNotesPageActions = createActionGroup({
  source: 'Dashboard General Notes Page',
  events: {
    'Load Shift Notes': (params: Params = { query: {} }) => ({ params }),
    'Refresh Shift Notes': (params: Params = { query: {} }) => ({ params }),
    'Get Shift Notes': (params: Params = { query: {} }) => ({ params }),
    'Create Shift Note': props<{ shiftNote: ShiftNote; params?: Params }>(),
    'Update Shift Note': props<Update<ShiftNote>>(),
    'Delete Shift Note': props<{ id: string; params?: Params }>()
  }
});
