import { FacilityUserGroupMember } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const facilityUserGroupMembersAdapter =
  createEntityAdapter<FacilityUserGroupMember>();

export interface State extends EntityState<FacilityUserGroupMember> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  facilityUserGroupMembersAdapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
  });
