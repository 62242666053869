import { FeathersService } from '$/app/services/feathers.service';
import { ResidentGroupsWsActions } from '$/app/store/resident-groups/actions';
import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class ResidentGroupsApiService extends AbstractApiService<ResidentGroup> {
  constructor(feathers: FeathersService, store: Store) {
    super('resident-groups', feathers, store, {
      entityName: 'residentGroup',
      created: ResidentGroupsWsActions.residentGroupsCreated,
      patched: ResidentGroupsWsActions.residentGroupsPatched,
      removed: ResidentGroupsWsActions.residentGroupsRemoved
    });
  }
}
