import { Medication, MedicationOrderItem, Params, Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { IResident } from '$shared/residents';
import { createActionGroup, props } from '@ngrx/store';

export const InventoryItemDetailPageActions = createActionGroup({
  source: 'Inventory Item Detail Page',
  events: {
    'Fetch Medication Inventory Item': (
      id: string,
      params: Params = { query: {} }
    ) => ({ id, params }),

    'Open New Inventory Item Form': props<{
      resident: IResident;
      medication: Medication;
      medicationOrderItem?: MedicationOrderItem;
      medicationInventoryItem?: IMedicationInventoryItem;
    }>(),

    'Delete Medication Inventory Item': props<{
      id: string;
      params?: Params;
    }>(),

    'Mark Inventory Empty': props<Update<IMedicationInventoryItem>>(),
    'Mark Inventory Not Empty': props<Update<IMedicationInventoryItem>>(),

    'Change Current Inventory': props<Update<IMedicationInventoryItem>>(),

    'Delete Medication Inventory Item Count': props<{
      id: string;
      params?: Params;
    }>()
  }
});
