import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { PositionLogsPageActions } from '$/app/pages/notebook/shared/logs/position-log/position-log.actions';
import { getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import { PositionLogsApiActions, PositionLogsGeneralActions } from './actions';
import { initialState, positionLogsAdapter } from './position-logs.state';
import { PositionLogFormPageActions } from '$/app/shared/pages/forms/log-forms/position-log-form/position-log-form.actions';

export const positionLogsFeature = createFeature({
  name: 'positionLogs',
  reducer: createReducer(
    initialState,

    on(
      PositionLogsPageActions.loadPositionLogs,
      PositionLogsPageActions.getPositionLogs,
      PositionLogFormPageActions.createPositionLog,
      PositionLogFormPageActions.updatePositionLog,
      PositionLogsPageActions.deletePositionLog,
      ResidentMedicationTasksPageActions.deletePositionLog,
      MissedMedicationsPageActions.deletePositionLog,
      RoutineMarListPageActions.deletePositionLog,
      RoutineMarDetailPageActions.deletePositionLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(PositionLogsApiActions.loadPositionLogsSuccess, (state, action) => {
      const data = getRecords(action.positionLogs);

      return positionLogsAdapter.setAll(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(PositionLogsApiActions.getPositionLogsSuccess, (state, action) => {
      const data = getRecords(action.positionLogs);

      return positionLogsAdapter.upsertMany(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(PositionLogsApiActions.createPositionLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(PositionLogsApiActions.updatePositionLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(PositionLogsApiActions.deletePositionLogSuccess, (state, action) =>
      positionLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      PositionLogsApiActions.loadPositionLogsFail,
      PositionLogsApiActions.getPositionLogsFail,
      PositionLogsApiActions.createPositionLogFail,
      PositionLogsApiActions.updatePositionLogFail,
      PositionLogsApiActions.deletePositionLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      PositionLogsApiActions.positionLogsCreated,
      PositionLogsApiActions.positionLogsPatched,
      (state, action) => {
        return positionLogsAdapter.upsertOne(action.positionLog, state);
      }
    ),

    on(PositionLogsApiActions.positionLogsRemoved, (state, action) => {
      return positionLogsAdapter.removeOne(action.id, state);
    }),

    on(PositionLogsGeneralActions.addPositionLogs, (state, action) => {
      return positionLogsAdapter.upsertMany(action.positionLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(PositionLogsGeneralActions.clearPositionLogs, () => {
      return positionLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
