import { Action, createReducer, on } from '@ngrx/store';
import {
  ResidentContactPhonesApiActions,
  ResidentContactPhonesGeneralActions
} from './actions';
import {
  State,
  initialState,
  residentContactPhonesAdapter
} from './resident-contact-phones.state';

const reducer = createReducer(
  initialState,

  on(
    ResidentContactPhonesApiActions.residentContactPhonesCreated,
    ResidentContactPhonesApiActions.residentContactPhonesPatched,
    (state, action) => {
      return residentContactPhonesAdapter.upsertOne(
        action.residentContactPhone,
        state
      );
    }
  ),

  on(
    ResidentContactPhonesApiActions.residentContactPhonesRemoved,
    (state, action) => {
      return residentContactPhonesAdapter.removeOne(action.id, state);
    }
  ),

  on(
    ResidentContactPhonesGeneralActions.addResidentContactPhones,
    (state, action) => {
      return residentContactPhonesAdapter.upsertMany(
        action.residentContactPhones,
        {
          ...state,
          loading: false,
          error: null
        }
      );
    }
  ),

  on(ResidentContactPhonesGeneralActions.clearResidentContactPhones, () => {
    return residentContactPhonesAdapter.removeAll({
      ...initialState
    });
  })
);

export function residentContactPhonesReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
