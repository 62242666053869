import { AmountTypeId } from '../../lookups/amount-types.lookup';

export interface AmountWithMeasurement {
  amount: string;
  measurement?: string;
}

export type AmountSegment = '/' | '-' | AmountWithMeasurement;

export interface IAmount {
  amountTypeId?: AmountTypeId;
  amount?: string;
  strength?: string;
}

export function isAmountSegmentArray(
  amount: unknown
): amount is AmountSegment[] {
  return (
    !!amount && Array.isArray(amount) && amount.some(isAmountWithMeasurement)
  );
}

export function isAmountWithMeasurement(
  segment: unknown
): segment is AmountWithMeasurement {
  return (
    !!segment &&
    typeof segment === 'object' &&
    'amount' in segment &&
    typeof segment.amount === 'string'
  );
}

export function allAreAmountWithMeasurement(
  segments: unknown[]
): segments is AmountWithMeasurement[] {
  return segments.every(isAmountWithMeasurement);
}
