<div class="flex shrink-0 items-center justify-center">
  @if (gender || !name) {
    <div
      class="rounded-full"
      [ngStyle]="{ 'height.px': size, 'width.px': size }"
    >
      <img
        class="rounded-full"
        [ngStyle]="{ 'height.px': size, 'width.px': size }"
        [src]="src"
        (error)="url = null"
      />
    </div>
  } @else {
    <div
      class="flex items-center justify-center rounded-full @container"
      [ngStyle]="{
        'height.px': size,
        'width.px': size,
        'background-color': hslColor
      }"
    >
      <span class="inline-block text-[60cqw] font-bold text-white">
        {{ initial }}
      </span>
    </div>
  }
</div>
