import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { geolocationReducer } from './geolocation.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('geolocation', geolocationReducer)
  ]
})
export class GeolocationStoreModule {}
