import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ShowerLogsEffects } from './shower-logs.effects';
import { showerLogsFeature } from './shower-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(showerLogsFeature),
    EffectsModule.forFeature([ShowerLogsEffects])
  ],
  providers: [ShowerLogsEffects]
})
export class ShowerLogsStoreModule {}
