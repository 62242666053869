import { AppInfo } from '$/app/services/utils/app-info';
import { environment } from '$/environments/environment';
import { Logger } from '$shared/logger';
import { LoginData } from '$shared/services/user';
import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { v4 as uuid } from 'uuid';

@Injectable({ providedIn: 'root' })
export class MixpanelService {
  constructor() {
    if (!environment.mixpanelProjectToken) {
      return;
    }

    mixpanel.init(environment.mixpanelProjectToken);
  }

  /**
   * Registers the user's identity with mixpanel.
   * Also sets the user's email as an alias.
   */
  identify({ user }: LoginData) {
    if (!environment.mixpanelProjectToken) {
      return;
    }

    mixpanel.identify(user.id);

    mixpanel.people.set({
      $name: `${user.firstName} ${user.lastName}`,
      $email: user.email
    });
  }

  registerSuperProperties(props: Record<string, any>) {
    if (!environment.mixpanelProjectToken) {
      return;
    }

    mixpanel.register(props);
  }

  reset() {
    if (!environment.mixpanelProjectToken) {
      return;
    }

    mixpanel.reset();
  }

  track(eventName: string, eventProps: Record<string, any> = {}) {
    if (!environment.mixpanelProjectToken) {
      return;
    }

    const payload = {
      ...eventProps,
      eventName,
      mixpanelLoggerId: uuid(),
      appInfo: AppInfo
    };

    Logger.debug(`Mixpanel Event - ${eventName}`, payload, { noConsole: true });
    mixpanel.track(eventName, payload);
  }
}
