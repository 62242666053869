import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function divisibleBy(parts: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const controlValue = +control.value;
    const modulus = 1 / parts;
    const fraction = `1/${parts}`;

    if (controlValue !== 0 && controlValue % modulus === 0) {
      return null;
    }

    return {
      divisibleBy: {
        parts,
        decimal: modulus,
        fraction
      }
    };
  };
}
