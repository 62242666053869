import { PrnAmountsApiService } from '$/app/services';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Injectable()
export class PrnAmountsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private prnAmountsService: PrnAmountsApiService
  ) {}
}
