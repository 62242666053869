import { FeathersService } from '$/app/services/feathers.service';
import { ShowerLogsApiActions } from '$/app/store/shower-logs';
import { ShowerLog } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class ShowerLogsApiService extends AbstractApiService<ShowerLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('shower-logs', feathers, store, {
      entityName: 'showerLog',
      created: ShowerLogsApiActions.showerLogsCreated,
      patched: ShowerLogsApiActions.showerLogsPatched,
      removed: ShowerLogsApiActions.showerLogsRemoved
    });
  }
}
