import { FeathersService } from '$/app/services/feathers.service';
import { MedicationInventoryItemsApiActions } from '$/app/store/medication-inventory-items/actions';
import { toUpdated } from '$/app/utils';
import { Paginated, Params, Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MedicationInventoryItemsApiService {
  private medicationInventoryItemsService: Service<IMedicationInventoryItem>;

  constructor(feathers: FeathersService, store: Store) {
    this.medicationInventoryItemsService = feathers.client.service(
      'medication-inventory-items'
    );

    this.medicationInventoryItemsService.on(
      'created',
      (medicationInventoryItem) => {
        store.dispatch(
          MedicationInventoryItemsApiActions.medicationInventoryItemsCreated({
            medicationInventoryItem
          })
        );
      }
    );

    this.medicationInventoryItemsService.on(
      'patched',
      (medicationInventoryItem) => {
        store.dispatch(
          MedicationInventoryItemsApiActions.medicationInventoryItemsPatched({
            medicationInventoryItem
          })
        );
      }
    );

    this.medicationInventoryItemsService.on(
      'removed',
      (medicationInventoryItem) => {
        store.dispatch(
          MedicationInventoryItemsApiActions.medicationInventoryItemsRemoved({
            id: medicationInventoryItem.id
          })
        );
      }
    );
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<IMedicationInventoryItem>> {
    const promise = this.medicationInventoryItemsService.find(
      params
    ) as Promise<Paginated<IMedicationInventoryItem>>;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<IMedicationInventoryItem> {
    const promise = this.medicationInventoryItemsService.get(id, params);
    return from(promise);
  }

  create(medicationInventoryItem: MaybeArray<IMedicationInventoryItem>) {
    const promise = this.medicationInventoryItemsService.create(
      medicationInventoryItem
    );
    return from(promise);
  }

  patch(
    id: string,
    medicationInventoryItem: Partial<IMedicationInventoryItem>,
    params: Params = { query: {} }
  ): Observable<Update<IMedicationInventoryItem>> {
    const promise = this.medicationInventoryItemsService.patch(
      id,
      medicationInventoryItem,
      params
    );

    return from(promise).pipe(map(toUpdated));
  }

  delete(
    id: string,
    params: Params = { query: {} }
  ): Observable<IMedicationInventoryItem> {
    const promise = this.medicationInventoryItemsService.remove(id, params);
    return from(promise);
  }
}
