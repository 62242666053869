import { ComparisonOperator } from '../utils';
import { LogFields } from './log-fields';

export interface ILogDependencyRuleCondition {
  field: LogFields;
  operator: ComparisonOperator;
  value: string | number | boolean;
}

export class LogDependencyRuleCondition implements ILogDependencyRuleCondition {
  field: LogFields;
  operator: ComparisonOperator;
  value: string | number | boolean;

  constructor(condition: ILogDependencyRuleCondition) {
    this.field = condition?.field;
    this.operator = condition?.operator || '=';
    this.value = condition?.value;
  }
}
