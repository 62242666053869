import { FeathersService } from '$/app/services/feathers.service';
import { AppointmentsWsActions } from '$/app/store/appointments/actions/appointments-api.actions';
import { IAppointment } from '$shared/appointments/appointments.interface';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class AppointmentsApiService extends AbstractApiService<IAppointment> {
  constructor(feathers: FeathersService, store: Store) {
    super('appointments', feathers, store, {
      entityName: 'appointment',
      created: AppointmentsWsActions.appointmentsCreated,
      patched: AppointmentsWsActions.appointmentsPatched,
      removed: AppointmentsWsActions.appointmentsRemoved
    });
  }
}
