import { SchemaOptions, Type } from '@sinclair/typebox';
import { StrictObject } from './object';

const Days = StrictObject({
  days: Type.Number({
    minimum: 0,
    maximum: 365
  })
});

const Hours = StrictObject({
  hours: Type.Number({
    minimum: 0,
    maximum: 24
  })
});

const Minutes = StrictObject({
  minutes: Type.Number({
    minimum: 0,
    maximum: 60
  })
});

const Seconds = StrictObject({
  seconds: Type.Number({
    minimum: 0,
    maximum: 60
  })
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function Duration(options?: SchemaOptions) {
  return Type.Union(
    [
      Type.Partial(Days),
      Type.Partial(Hours),
      Type.Partial(Minutes),
      Type.Partial(Seconds)
    ],
    options
  );
}
