import { DestructionMethodsListPageActions } from '$/app/pages/facilities/facility-detail/destruction-methods-list/destruction-methods-list.actions';
import { DestructionMethodModalActions } from '$/app/shared/pages/forms/destruction-method-form/destruction-method-form.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DestructionMethodsApiActions,
  DestructionMethodsGeneralActions,
  DestructionMethodsWsActions
} from './destruction-methods.actions';
import {
  destructionMethodsAdapter,
  initialState
} from './destruction-methods.state';

export const destructionMethodsFeature = createFeature({
  name: 'destructionMethods',
  reducer: createReducer(
    initialState,
    on(
      DestructionMethodModalActions.createDestructionMethod,
      DestructionMethodModalActions.updateDestructionMethod,
      DestructionMethodsListPageActions.deleteDestructionMethod,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),
    on(DestructionMethodsApiActions.createDestructionMethodSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(DestructionMethodsApiActions.createDestructionMethodSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),
    on(
      DestructionMethodsApiActions.deleteDestructionMethodSuccess,
      (state, action) =>
        destructionMethodsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),
    on(
      DestructionMethodsApiActions.createDestructionMethodFail,
      DestructionMethodsApiActions.updateDestructionMethodFail,
      DestructionMethodsApiActions.deleteDestructionMethodFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),
    on(
      DestructionMethodsWsActions.destructionMethodsCreated,
      DestructionMethodsWsActions.destructionMethodsPatched,
      (state, action) => {
        return destructionMethodsAdapter.upsertOne(
          action.destructionMethod,
          state
        );
      }
    ),
    on(
      DestructionMethodsWsActions.destructionMethodsRemoved,
      (state, action) => {
        return destructionMethodsAdapter.removeOne(action.id, state);
      }
    ),
    on(
      DestructionMethodsGeneralActions.addDestructionMethods,
      (state, action) => {
        return destructionMethodsAdapter.upsertMany(action.destructionMethods, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(DestructionMethodsGeneralActions.clearDestructionMethods, () => {
      return destructionMethodsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
