import { sortByDesc } from '$/app/utils';
import { IPaginationInfo, TemperatureLog } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface TemperatureLogPageQueries {
  all: IPaginationInfo;
}

export const temperatureLogsAdapter = createEntityAdapter<TemperatureLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<TemperatureLog> {
  pagination: TemperatureLogPageQueries;

  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = temperatureLogsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },

  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
