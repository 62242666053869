import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LibraryCollectionDocumentTemplatePacketsEffects } from './library-collection-document-template-packets.effects';
import { libraryCollectionDocumentTemplatePacketsFeature } from './library-collection-document-template-packets.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(libraryCollectionDocumentTemplatePacketsFeature),
    EffectsModule.forFeature([LibraryCollectionDocumentTemplatePacketsEffects])
  ],
  providers: [LibraryCollectionDocumentTemplatePacketsEffects]
})
export class LibraryCollectionDocumentTemplatePacketsStoreModule {}
