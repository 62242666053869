import { UserSelectors } from '$/app/store/user/user.selectors';
import {
  GeneralSettingsEnum,
  SettingCodes,
  TimezoneValue
} from '$shared/facility-settings/constants';
import {
  IFacilitySetting,
  SettingCodeValueLookup
} from '$shared/facility-settings/types';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { State, facilitySettingsAdapter } from './facility-settings.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;
export const getTotalRecords = (state: State): number => state.total;
export const getSkip = (state: State): number => state.skip;

// Feature Selector
export const selectFacilitySettingsState =
  createFeatureSelector<State>('facilitySettings');
const entitySelectors = facilitySettingsAdapter.getSelectors(
  selectFacilitySettingsState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extra Selectors
export const selectLoading = createSelector(
  selectFacilitySettingsState,
  getLoading
);
export const selectLoaded = createSelector(
  selectFacilitySettingsState,
  getLoaded
);
export const selectError = createSelector(
  selectFacilitySettingsState,
  getError
);
export const selectTotalRecords = createSelector(
  selectFacilitySettingsState,
  getTotalRecords
);
export const selectSkip = createSelector(selectFacilitySettingsState, getSkip);

// VIEW MODEL SELECTORS
export const selectCurrentFacilitySettingValueByCode = <
  TCode extends SettingCodes
>(
  code: TCode
): MemoizedSelector<
  object,
  IFacilitySetting<TCode>['jsonValue'] | undefined
> => {
  return createSelector(
    selectAll,
    UserSelectors.selectFacilityId,
    (facilitySettings, facilityId) => {
      const setting = facilitySettings.find(
        (facilitySetting) =>
          facilitySetting.code === code &&
          facilitySetting.facilityId === facilityId
      ) as IFacilitySetting<TCode>;

      return setting?.jsonValue;
    }
  );
};

export const selectCurrentFacilitySettingsMap = createSelector(
  selectAll,
  UserSelectors.selectFacilityId,
  (facilitySettings, facilityId): SettingCodeValueLookup => {
    const currentFacilitySettings = facilitySettings.filter(
      (setting) => setting.facilityId === facilityId
    );

    return currentFacilitySettings.reduce((acc, setting) => {
      acc[setting.code] = setting.jsonValue as any;
      return acc;
    }, {} as SettingCodeValueLookup);
  }
);

export const selectFacilityTime = createSelector(
  selectCurrentFacilitySettingsMap,
  (facilitySettings): FacilityTime =>
    new FacilityTime(
      facilitySettings[GeneralSettingsEnum.LOCATION_TIMEZONE]?.value ??
        (Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneValue)
    )
);
