import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IPaginationInfo } from '$/models';
import { IMoodLog } from '$shared/services/mood-log';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface MoodLogPageQueries {
  all: IPaginationInfo;
}

export const moodLogsAdapter = createEntityAdapter<IMoodLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<IMoodLog> {
  pagination: MoodLogPageQueries;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = moodLogsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
