import { sortByDatetime } from '$/app/utils';
import { TPrnAmount } from '$shared/medications/prn-amount.schema';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const prnAmountsAdapter = createEntityAdapter<TPrnAmount>({
  sortComparer: sortByDatetime('createdAt')
});

export interface State extends EntityState<TPrnAmount> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = prnAmountsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
