import { Table } from '$/models/data/base';
import { DiagnosisLevelId } from '$shared/lookups/diagnosis-levels.lookup';

export class Diagnosis extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  name: string;
  levelId: DiagnosisLevelId;
  notes?: string;
}
