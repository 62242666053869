import { MedicationDestructionItemFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-item-form/medication-destruction-item.actions';
import { NarcoticCountListPageActions } from '$/app/pages/medications/dashboard/narcotic-counts/narcotic-count-list/narcotic-count-list.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { MedicationDetailInventoryPageActions } from '$/app/pages/medications/residents/medication-detail/medication-detail-inventory/medication-detail-inventory.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { NewInventoryFormActions } from '$/app/pages/medications/shared/components/new-inventory-form/inventory-item-form.modal.actions';
import {
  EffectHelpersService,
  MedicationInventoryItemsApiService,
  OverlayService
} from '$/app/services';
import { InventoryPickerPageActions } from '$/app/shared/components/inventory-picker/inventory-picker.actions';
import { ApiData } from '$/app/utils';
import { Paginated } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { InventoryItemDetailPageActions } from '../../pages/medications/residents/inventory-item-detail/inventory-item-detail.actions';
import { MedicationInventoryItemsApiActions } from './actions';

@Injectable()
export class MedicationInventoryItemsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly overlay = inject(OverlayService);
  private readonly medicationInventoryItemsService = inject(
    MedicationInventoryItemsApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  loadMedicationInventoryItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        RoutineMarListPageActions.getMedicationInventoryItems,
        MedicationDestructionItemFormActions.loadInventoryItems
      ),
      switchMap((action) => {
        return this.medicationInventoryItemsService.getAll(action.params).pipe(
          map(
            (medicationInventoryItems: Paginated<IMedicationInventoryItem>) => {
              return MedicationInventoryItemsApiActions.loadMedicationInventoryItemsSuccess(
                { medicationInventoryItems }
              );
            }
          ),
          catchError((error) => {
            return of(
              MedicationInventoryItemsApiActions.loadMedicationInventoryItemsFail(
                { error }
              )
            );
          })
        );
      })
    );
  });

  getMedicationInventoryItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewInventoryFormActions.getLastMedicationInventory),
      switchMap((action) => {
        return this.medicationInventoryItemsService.getAll(action.params).pipe(
          map(
            (medicationInventoryItems: Paginated<IMedicationInventoryItem>) => {
              return MedicationInventoryItemsApiActions.getMedicationInventoryItemsSuccess(
                { medicationInventoryItems }
              );
            }
          ),
          catchError((error) => {
            return of(
              MedicationInventoryItemsApiActions.getMedicationInventoryItemsFail(
                { error }
              )
            );
          })
        );
      })
    );
  });

  fetchMedicationInventoryItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryItemDetailPageActions.fetchMedicationInventoryItem),
      this.effectHelpers.apiRequest({
        description: 'Fetch ',
        onRequest: (action) =>
          this.medicationInventoryItemsService.get(action.id, action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medicationInventoryItems',
            response,
            MedicationInventoryItemsApiActions.fetchMedicationInventoryItemSuccess,
            {
              payloadKey: 'medicationInventoryItem'
            }
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicationInventoryItemsApiActions.fetchMedicationInventoryItemFail({
            error
          })
      })
    );
  });

  createMedicationInventoryItem$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NewInventoryFormActions.createMedicationInventoryItem),
        exhaustMap((action) => {
          return this.medicationInventoryItemsService
            .create(action.medicationInventoryItem)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'Medication Inventory Item created successfully!'
                )
              ),
              tap(async () => {
                const modal = await this.overlay.getTopModal();
                if (modal) {
                  await modal.dismiss();
                }
              }),
              catchError((error) => {
                this.store.dispatch(
                  MedicationInventoryItemsApiActions.createMedicationInventoryItemFail(
                    { error }
                  )
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  createMedicationInventoryItemFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryItemsApiActions.createMedicationInventoryItemFail
        ),
        tap(this.effectHelpers.onFormSubmitFail('Create Inventory Item Failed'))
      );
    },
    { dispatch: false }
  );

  updateMedicationInventoryItem$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          NewInventoryFormActions.updateMedicationInventoryItem,
          InventoryPickerPageActions.changeInventoryItem
        ),
        switchMap((action) => {
          return this.medicationInventoryItemsService
            .patch(action.id, action.changes)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'Medication Inventory Item updated successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  MedicationInventoryItemsApiActions.updateMedicationInventoryItemFail(
                    { error }
                  )
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateMedicationInventoryItemNoToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationDetailInventoryPageActions.changeCurrentInventory,
          InventoryItemDetailPageActions.changeCurrentInventory
        ),
        switchMap((action) => {
          return this.medicationInventoryItemsService
            .patch(action.id, action.changes)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  MedicationInventoryItemsApiActions.updateMedicationInventoryItemFail(
                    { error }
                  )
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  adjustMedicationInventoryItem$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationDetailInventoryPageActions.markInventoryEmpty,
          MedicationDetailInventoryPageActions.markInventoryNotEmpty,
          ResidentMedicationTasksPageActions.markInventoryEmpty,
          NarcoticCountListPageActions.markInventoryEmpty,
          InventoryItemDetailPageActions.markInventoryEmpty,
          InventoryItemDetailPageActions.markInventoryNotEmpty
        ),
        switchMap((action) => {
          return this.medicationInventoryItemsService
            .patch(action.id, action.changes, {
              query: {
                id: action.id
              }
            })
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  MedicationInventoryItemsApiActions.updateMedicationInventoryItemFail(
                    { error }
                  )
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateMedicationInventoryItemFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicationInventoryItemsApiActions.updateMedicationInventoryItemFail
        ),
        tap(this.effectHelpers.onFormSubmitFail('Update Inventory Item Failed'))
      );
    },
    { dispatch: false }
  );

  deleteMedicationInventoryItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationDetailInventoryPageActions.deleteMedicationInventoryItem,
        InventoryItemDetailPageActions.deleteMedicationInventoryItem
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete Medication Inventory Item',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.medicationInventoryItemsService.delete(
            action.id,
            action.params
          );
        },
        onSuccess: (entity: IMedicationInventoryItem) =>
          MedicationInventoryItemsApiActions.deleteMedicationInventoryItemSuccess(
            {
              id: entity.id
            }
          ),
        onError: (error) =>
          MedicationInventoryItemsApiActions.deleteMedicationInventoryItemFail({
            error
          })
      })
    );
  });
}
