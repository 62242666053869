import { AlcAction, Update } from '$/models';
import { LoginDataUser } from '$shared/services/user';
import { createActionGroup, props } from '@ngrx/store';

export const UserProfilePageActions = createActionGroup({
  source: 'User Profile Page',
  events: {
    'Update User': props<Update<LoginDataUser>>(),

    'Register Push Notifications': AlcAction<{ userId: string }>(),

    'Unregister Push Notifications': AlcAction<{ userId: string }>()
  }
});
