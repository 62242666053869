import { Announcement, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const AnnouncementFormPageActions = createActionGroup({
  source: 'Announcement Form',
  events: {
    'Create Announcement': props<{
      announcement: Announcement;
      params?: Params;
    }>(),
    'Edit Announcement': props<{
      id: string;
      changes: Partial<Announcement>;
      params?: Params;
    }>(),
    'Load Facility User Group Members': (params: Params = { query: {} }) => ({
      params
    })
  }
});
