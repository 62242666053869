import { logDetails } from '$shared/lookups/log-details.lookup';
import { OpenStringUnion } from '$shared/types';
import { map } from 'lodash';

export const alcomyThemes = [
  'dashboard',
  'residents',
  'staff',
  'communication',
  'documents',
  'triggers',
  'organization'
] as const;

export type AlcomyTheme = (typeof alcomyThemes)[number];

export const primaryThemeColors = alcomyThemes.map(
  (theme) => `${theme}-primary` as const
);

export type PrimaryThemeColor = (typeof primaryThemeColors)[number];

export const accentThemeColors = alcomyThemes.map(
  (theme) => `${theme}-accent` as const
);

export type AccentThemeColor = (typeof accentThemeColors)[number];

export const themeColors = [...primaryThemeColors, ...accentThemeColors];

export type ThemeColor = (typeof themeColors)[number];

export const neutralColors = [
  'black',
  'dark',
  'medium-dark',
  'medium',
  'medium-light',
  'light',
  'white',
  'surface'
] as const;

export type NeutralColor = (typeof neutralColors)[number];

export type GeneralColor = 'success' | 'warning' | 'danger';

export type AlcomyColor = OpenStringUnion<
  PrimaryThemeColor | AccentThemeColor | NeutralColor | GeneralColor
>;

export const backgroundThemeColors = themeColors.map(
  (color) => `bg-${color}` as const
);

export const foregroundThemeColors = themeColors.map(
  (color) => `text-${color}` as const
);

export type backgroundColor =
  | 'bg-dashboard-primary'
  | 'bg-residents-primary'
  | 'bg-staff-primary'
  | 'bg-communication-primary'
  | 'bg-documents-primary'
  | 'bg-triggers-primary'
  | 'bg-organization-primary';

export const backgroundColors: Record<AlcomyTheme, backgroundColor> = {
  dashboard: 'bg-dashboard-primary',
  residents: 'bg-residents-primary',
  staff: 'bg-staff-primary',
  communication: 'bg-communication-primary',
  documents: 'bg-documents-primary',
  triggers: 'bg-triggers-primary',
  organization: 'bg-organization-primary'
};

export const logColors = map(logDetails, 'color');

export const backgroundLogColors = map(
  logColors,
  (color) => `bg-${color}` as const
);

export type LogBackgroundColor = (typeof backgroundLogColors)[number];

export const foregroundLogColors = map(
  logColors,
  (color) => `text-${color}` as const
);

export type LogForegroundColor = (typeof foregroundLogColors)[number];
