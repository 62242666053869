import { ToLuxonParam, ToLuxonParamOrCallable, toLuxon } from '$shared/utils';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDateTime(min: ToLuxonParamOrCallable): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const minVal = min instanceof Function ? min() : min;
    if (!minVal) {
      return null;
    }

    const minDateTime = toLuxon(minVal);
    const value = control.value as ToLuxonParam;

    if (!value) {
      return null;
    }

    const valueDateTime = toLuxon(value);

    return valueDateTime >= minDateTime
      ? null
      : { minDateTime: 'Earlier than minimum datetime' };
  };
}
