import { AppInfo } from '$/app/services/utils/app-info';
import { Logger } from '$shared/logger';
import { IAppUpdate } from '$shared/services/app-update';
import { ApplicationRef, inject, Injectable } from '@angular/core';
import {
  SwUpdate,
  UnrecoverableStateEvent,
  VersionEvent
} from '@angular/service-worker';
import { App } from '@capacitor/app';
import {
  AppUpdate,
  AppUpdateAvailability,
  AppUpdateInfo
} from '@capawesome/capacitor-app-update';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import { AlertController } from '@ionic/angular/standalone';
import { AlertButton } from '@ionic/core';
import { DateTime } from 'luxon';
import semver from 'semver';
import { FeathersService } from '../feathers.service';
import { OverlayService } from '../ui';

interface AlertParams {
  title: string;
  message: string;
  buttons?: AlertButton[];
  allowDismiss?: boolean;
}

const CHECK_INTERVAL_SECONDS = 60;

@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  private readonly feathers = inject(FeathersService);
  private readonly alert = inject(AlertController);
  private readonly swUpdate = inject(SwUpdate);
  private readonly overlay = inject(OverlayService);
  private readonly appRef = inject(ApplicationRef);

  private readonly appUpdateService =
    this.feathers.client.service('app-updates');

  private reloadOnAppDeactivate = false;
  private alertIsDisplayed = false;
  private lastCheckTimestamp = DateTime.fromISO('2020-01-01');

  updateReady = false;

  async init() {
    if (AppInfo.deviceInfo.platform !== 'web') {
      CapacitorUpdater.notifyAppReady();

      await this.checkForUpdates();

      App.addListener('appStateChange', async ({ isActive }) => {
        if (isActive) {
          await this.checkForUpdates();
        } else if (this.reloadOnAppDeactivate) {
          window.location.reload();
          return;
        }
      });
    } else if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            Logger.info('Service worker update detected', { event });
            this.overlay.showAlert(
              "A new version of the app is being downloaded. We'll let you know when it's ready to install.",
              'Downloading Update'
            );
            break;
          case 'VERSION_READY':
            Logger.info('Service worker update detected', { event });
            this.handleServiceWorkerUpdate();
            break;
          case 'VERSION_INSTALLATION_FAILED':
            Logger.warn('Service worker update failed', { event });
            break;
        }
      });

      this.swUpdate.unrecoverable.subscribe(
        (event: UnrecoverableStateEvent) => {
          Logger.error('Service worker unrecoverable error', { event });
          this.presentAlert({
            title: 'App error',
            message:
              'An error occurred that we cannot recover from:\n' +
              event.reason +
              '\n\nPlease reload the page.',
            buttons: [
              {
                text: 'Reload',
                handler: () => {
                  window.location.reload();
                }
              }
            ],
            allowDismiss: false
          });
        }
      );

      // Note that setting up this interval will cause appRef.isStable to never be true
      // However we currently don't typically reach the isStable state anyways
      // TEMPORARY: Uncomment
      // interval(6 * 60 * 60 * 1000).subscribe(this.checkServiceWorkerUpdate);
    }
  }

  reloadApp() {
    window.location.reload();
  }

  private async checkServiceWorkerUpdate() {
    try {
      const updateFound = await this.swUpdate.checkForUpdate();
      if (updateFound) {
        Logger.info('Service worker update checked and found update');
        this.handleServiceWorkerUpdate();
      }
    } catch (err) {
      Logger.error('Failed to check for updates', err);
    }
  }

  private handleServiceWorkerUpdate() {
    this.presentAlert({
      title: 'Update available',
      message:
        'A new version of the app was downloaded. Reload the app to apply the update.',
      buttons: [
        {
          text: 'Reload',
          handler: () => {
            window.location.reload();
          }
        },
        {
          text: 'Later',
          role: 'cancel',
          handler: () => {
            this.updateReady = true;
          }
        }
      ],
      allowDismiss: false
    });
  }

  private async checkForUpdates() {
    const now = DateTime.now();
    if (
      this.lastCheckTimestamp.plus({ seconds: CHECK_INTERVAL_SECONDS }) > now
    ) {
      return;
    }

    this.lastCheckTimestamp = now;

    const updates = await Promise.all([
      this.checkServerUpdates(),
      this.checkAppStore()
    ]);

    // Grab the first update that is not undefined/null
    const update = updates.filter(Boolean)[0];

    // If there is an update, present the alert
    if (update) {
      const { button, ...rest } = update;

      this.presentAlert({
        ...rest,
        buttons: button ? [button] : []
      });
    }
  }

  private async checkServerUpdates() {
    try {
      const update: IAppUpdate = await this.appUpdateService.get(null);

      return this.processServerUpdate(update);
    } catch (error) {
      this.logError('Error while checking for server app updates', { error });
    }
  }

  private async processServerUpdate(update: IAppUpdate) {
    switch (update?.type) {
      case 'live-update':
        const capacitorUpdate = await CapacitorUpdater.download({
          version: update.version,
          url: update.url
        });

        await this.applyLiveUpdates(capacitorUpdate);

        return undefined;

      case 'maintenance':
        this.reloadOnAppDeactivate = true;
        return {
          title: update.title,
          message: update.message,
          allowDismiss: false
        };

      case 'native':
        return {
          title: update.title,
          message: update.message,
          button: {
            text: 'Update',
            handler: () => {
              AppUpdate.openAppStore();
              return false;
            }
          },
          allowDismiss: false
        };

      case 'no-update':
        return undefined;

      default:
        return undefined;
    }
  }

  private async checkAppStore() {
    try {
      const versionInfo = await this.getVersionInfo();

      if (!versionInfo) {
        return;
      }

      if (
        semver.lte(versionInfo.availableVersion, versionInfo.currentVersion)
      ) {
        Logger.warn('AppUpdate found update but version is not newer', {
          versionInfo
        });

        return;
      }

      return {
        title: 'Update available',
        message: 'A new version of the app is available.',
        button: {
          text: 'Update',
          handler: () => {
            AppUpdate.openAppStore();
            return false;
          }
        }
      };
    } catch (error) {
      this.logError('Error while checking for app store updates', { error });
    }
  }

  private async getVersionInfo(): Promise<
    | {
        updateInfo: AppUpdateInfo;
        availableVersion: string;
        currentVersion: string;
      }
    | undefined
  > {
    if (AppInfo.deviceInfo.platform === 'web') {
      return;
    }

    const updateInfo = await AppUpdate.getAppUpdateInfo();

    const updateAvailable =
      updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;

    if (!updateAvailable) {
      return;
    }

    return {
      updateInfo,
      availableVersion:
        AppInfo.deviceInfo.platform === 'ios'
          ? updateInfo.availableVersionName || ''
          : updateInfo.availableVersionCode || '',
      currentVersion: updateInfo.currentVersionName || ''
    };
  }

  private logError(
    message: string,
    payload: { error: any; [key: string]: any }
  ) {
    const errorMessage = payload.error.message || payload.error;

    // When running in the simulator, this error is thrown
    if (errorMessage === 'Failed to bind to the service') {
      return;
    }

    if (payload.error?.className === 'timeout') {
      Logger.warn(message, payload);
      return;
    }

    Logger.error(message, payload);
  }

  private async applyLiveUpdates(capacitorUpdate: BundleInfo) {
    if (!capacitorUpdate?.version) {
      return;
    }

    try {
      await CapacitorUpdater.set(capacitorUpdate);
    } catch (err) {
      Logger.error('CapacitorUpdater failed', { capacitorUpdate, err });
    }
  }

  private async presentAlert({
    title,
    message,
    buttons,
    allowDismiss = true
  }: AlertParams) {
    if (this.alertIsDisplayed) {
      return;
    }

    if (allowDismiss) {
      buttons.push({
        text: buttons.length === 0 ? 'Got it!' : 'Later',
        role: 'cancel'
      });
    }

    const alert = await this.alert.create({
      header: title,
      message,
      backdropDismiss: allowDismiss,
      buttons
    });

    try {
      this.alertIsDisplayed = true;
      await alert.present();
      await alert.onDidDismiss();
    } finally {
      this.alertIsDisplayed = false;
    }
  }
}
