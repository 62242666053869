import { ILibraryDocumentTemplate } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LibraryDocumentTemplatesGeneralActions = createActionGroup({
  source: 'Library Document Templates General',
  events: {
    'Clear Library Document Templates': emptyProps(),
    'Add Library Document Templates': props<{
      libraryDocumentTemplates: ILibraryDocumentTemplate[];
    }>()
  }
});
