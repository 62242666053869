import { IDocumentTemplatePacket, Paginated } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DocumentTemplatePacketsApiActions = createActionGroup({
  source: 'Document Template Packets API',
  events: {
    // Success Actions
    'Load Document Template Packets Success': props<{
      documentTemplatePackets: Paginated<IDocumentTemplatePacket>;
    }>(),
    'Get Document Template Packets Success': props<{
      documentTemplatePackets: Paginated<IDocumentTemplatePacket>;
    }>(),
    'Fetch Document Template Packet Success': props<{
      documentTemplatePacket: IDocumentTemplatePacket;
    }>(),
    'Create Document Template Packet Success': props<{
      documentTemplatePacket: IDocumentTemplatePacket;
    }>(),
    'Update Document Template Packet Success': props<{
      documentTemplatePacket: IDocumentTemplatePacket;
    }>(),
    'Delete Document Template Packet Success': props<{ id: string }>(),
    // Fail Actions
    'Load Document Template Packets Fail': props<{ error: Error }>(),
    'Get Document Template Packets Fail': props<{ error: Error }>(),
    'Fetch Document Template Packet Fail': props<{ error: Error }>(),
    'Create Document Template Packet Fail': props<{ error: Error }>(),
    'Update Document Template Packet Fail': props<{ error: Error }>(),
    'Delete Document Template Packet Fail': props<{ error: Error }>()
  }
});

export const DocumentTemplatePacketsWsActions = createActionGroup({
  source: 'Document Template Packets WS',
  events: {
    'Document Template Packet Created': props<{
      documentTemplatePacket: IDocumentTemplatePacket;
    }>(),
    'Document Template Packet Patched': props<{
      documentTemplatePacket: IDocumentTemplatePacket;
    }>(),
    'Document Template Packet Removed': props<{ id: string }>()
  }
});

export const DocumentTemplatePacketsGeneralActions = createActionGroup({
  source: 'Document Template Packets General',
  events: {
    'Clear Document Template Packets': emptyProps(),
    'Add Document Template Packets': props<{
      documentTemplatePackets: IDocumentTemplatePacket[];
    }>()
  }
});
