import { createFeature, createReducer, on } from '@ngrx/store';
import {
  LibraryCollectionDocumentTemplatesApiActions,
  LibraryCollectionDocumentTemplatesGeneralActions,
  LibraryCollectionDocumentTemplatesWsActions
} from './actions';
import {
  initialState,
  libraryCollectionDocumentTemplatesAdapter
} from './library-collection-document-templates.state';

export const libraryCollectionDocumentTemplatesFeature = createFeature({
  name: 'libraryCollectionDocumentTemplates',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      LibraryCollectionDocumentTemplatesApiActions.loadLibraryCollectionDocumentTemplatesSuccess,
      (state, action) => {
        const {
          libraryCollectionDocumentTemplates: { data: records, ...pagination }
        } = action;

        return libraryCollectionDocumentTemplatesAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryCollectionDocumentTemplatesApiActions.getLibraryCollectionDocumentTemplatesSuccess,
      (state, action) => {
        const {
          libraryCollectionDocumentTemplates: { data: records, ...pagination }
        } = action;

        return libraryCollectionDocumentTemplatesAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryCollectionDocumentTemplatesApiActions.fetchLibraryCollectionDocumentTemplateSuccess,
      (state, action) => {
        return libraryCollectionDocumentTemplatesAdapter.upsertOne(
          action.libraryCollectionDocumentTemplate,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatesApiActions.createLibraryCollectionDocumentTemplateSuccess,
      (state, action) => {
        return libraryCollectionDocumentTemplatesAdapter.addOne(
          action.libraryCollectionDocumentTemplate,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatesApiActions.updateLibraryCollectionDocumentTemplateSuccess,
      (state, action) => {
        return libraryCollectionDocumentTemplatesAdapter.upsertOne(
          action.libraryCollectionDocumentTemplate,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatesApiActions.deleteLibraryCollectionDocumentTemplateSuccess,
      (state, action) =>
        libraryCollectionDocumentTemplatesAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      LibraryCollectionDocumentTemplatesApiActions.loadLibraryCollectionDocumentTemplatesFail,
      LibraryCollectionDocumentTemplatesApiActions.getLibraryCollectionDocumentTemplatesFail,
      LibraryCollectionDocumentTemplatesApiActions.createLibraryCollectionDocumentTemplateFail,
      LibraryCollectionDocumentTemplatesApiActions.updateLibraryCollectionDocumentTemplateFail,
      LibraryCollectionDocumentTemplatesApiActions.deleteLibraryCollectionDocumentTemplateFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      LibraryCollectionDocumentTemplatesWsActions.libraryCollectionDocumentTemplateCreated,
      LibraryCollectionDocumentTemplatesWsActions.libraryCollectionDocumentTemplatePatched,
      (state, action) => {
        return libraryCollectionDocumentTemplatesAdapter.upsertOne(
          action.libraryCollectionDocumentTemplate,
          state
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatesWsActions.libraryCollectionDocumentTemplateRemoved,
      (state, action) => {
        return libraryCollectionDocumentTemplatesAdapter.removeOne(
          action.id,
          state
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatesGeneralActions.addLibraryCollectionDocumentTemplates,
      (state, action) => {
        return libraryCollectionDocumentTemplatesAdapter.upsertMany(
          action.libraryCollectionDocumentTemplates,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryCollectionDocumentTemplatesGeneralActions.clearLibraryCollectionDocumentTemplates,
      () => {
        return libraryCollectionDocumentTemplatesAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
