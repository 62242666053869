import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { createNewSortInstance, IComparer } from 'fast-sort';

const comparer: IComparer = (
  a: IMedicationInventoryItem,
  b: IMedicationInventoryItem,
  order
) => {
  if (a.isCurrent && order > 0) {
    return -1;
  }
  if (b.isCurrent && order > 0) {
    return 1;
  }
  if (a.isCurrent && order < 0) {
    return 1;
  }
  if (b.isCurrent && order < 0) {
    return -1;
  }

  return a.itemNumber - b.itemNumber;
};

/**
 * Sorts inventory items by putting the current inventory item first
 * and all following inventory items sorted by the item number.
 *
 * For descending sort the current inventory item will be last and the
 * preceding items are sorted by the item number in descending order.
 */
export const inventorySorter = createNewSortInstance({
  comparer
});
