import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { BehaviorLogsPageActions } from '$/app/pages/notebook/shared/logs/behavior-logs/behavior-log.actions';
import { BehaviorLogsApiService, EffectHelpersService } from '$/app/services';
import { BehaviorLogModalActions } from '$/app/shared/pages/forms/log-forms/behavior-log-modal/behavior-log.modal.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { IBehaviorLog } from '$shared/services/behavior-log';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { BehaviorLogsApiActions } from './behavior-logs.actions';

@Injectable()
export class BehaviorLogsEffects {
  private readonly actions$ = inject(Actions);
  private readonly behaviorLogsService = inject(BehaviorLogsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadBehaviorLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BehaviorLogsPageActions.loadBehaviorLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.behaviorLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('behaviorLogs', logs);
            responseData.setPrimaryAction(
              BehaviorLogsApiActions.loadBehaviorLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getBehaviorLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BehaviorLogsPageActions.getBehaviorLogs,
        LogsPageActions.getBehaviorLogs,
        GivePrnFormActions.getBehaviorLogs
      ),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.behaviorLogsService.getAll(action.params).pipe(
          getAllPages(this.behaviorLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('behaviorLogs', logs);
            responseData.setPrimaryAction(
              BehaviorLogsApiActions.getBehaviorLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createBehaviorLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BehaviorLogModalActions.createBehaviorLog),
      exhaustMap((action) => {
        return this.behaviorLogsService.create(action.behaviorLog).pipe(
          tap(() => {
            this.effectHelpers.dismissModal({ data: action.behaviorLog });
          }),
          map((behaviorLog: IBehaviorLog) =>
            BehaviorLogsApiActions.createBehaviorLogSuccess({
              behaviorLog
            })
          ),
          catchError((error) =>
            of(
              BehaviorLogsApiActions.createBehaviorLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateBehaviorLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BehaviorLogModalActions.updateBehaviorLog),
      switchMap((action) => {
        return this.behaviorLogsService.patch(action.id, action.changes).pipe(
          map((behaviorLog: IBehaviorLog) => {
            return BehaviorLogsApiActions.updateBehaviorLogSuccess({
              behaviorLog
            });
          }),
          catchError((error) => {
            return of(BehaviorLogsApiActions.updateBehaviorLogFail({ error }));
          })
        );
      })
    );
  });

  deleteBehaviorLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BehaviorLogsPageActions.deleteBehaviorLog,
        ResidentMedicationTasksPageActions.deleteBehaviorLog,
        MissedMedicationsPageActions.deleteBehaviorLog,
        RoutineMarDetailPageActions.deleteBehaviorLog,
        RoutineMarListPageActions.deleteBehaviorLog,
        GivePrnFormActions.deleteBehaviorLog,
        CompleteCareTaskModalActions.deleteBehaviorLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete behavior log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.behaviorLogsService.delete(action.id, action.params);
        },
        onSuccess: (behaviorLog: IBehaviorLog) =>
          BehaviorLogsApiActions.deleteBehaviorLogSuccess({
            id: behaviorLog.id
          }),
        onError: (error) =>
          BehaviorLogsApiActions.deleteBehaviorLogFail({ error })
      })
    );
  });
}
