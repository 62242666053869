import { Table } from '$/models/data/base';
import { OmittedMedicationReasonId } from '$shared/lookups/omitted-medication-reasons.lookup';

export class OmittedMedication extends Table {
  orgId?: string;
  facilityId?: string;
  medicationId?: string;
  medicationTaskId?: string;
  reasonId?: OmittedMedicationReasonId;
  location?: string;
  qty?: number;
  strength?: string;
  notes?: string;
  amountNotes?: string | null;
  medicationBatchId?: string;
  notifiedDoctor?: boolean;
  medicationInventoryItemId?: string;
  performedBy?: string;
  performedAt?: string;
}
