import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { MoodLogsPageActions } from '$/app/pages/notebook/shared/logs/mood-log/mood-log.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { Action, createReducer, on } from '@ngrx/store';
import { MoodLogsGeneralActions } from './actions';
import * as MoodLogsApiActions from './actions/mood-logs-api.actions';
import {
  MoodLogPageQueries,
  State,
  initialState,
  moodLogsAdapter
} from './mood-logs.state';
import { MoodLogFormPageActions } from '$/app/shared/pages/forms/log-forms/mood-log-form/mood-log-form.actions';

const reducer = createReducer(
  initialState,

  on(
    MoodLogsPageActions.loadMoodLogs,
    MoodLogsPageActions.getMoodLogs,
    MoodLogFormPageActions.createMoodLog,
    MoodLogFormPageActions.updateMoodLog,
    MoodLogsPageActions.deleteMoodLog,
    ResidentMedicationTasksPageActions.deleteMoodLog,
    MissedMedicationsPageActions.deleteMoodLog,
    RoutineMarListPageActions.deleteMoodLog,
    RoutineMarDetailPageActions.deleteMoodLog,

    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(MoodLogsApiActions.loadMoodLogsSuccess, (state, action) => {
    const queryType = action.metadata?.pageQueryType;
    const pagination = getPaginationData<MoodLogPageQueries>(
      state,
      action.moodLogs,
      queryType
    );
    const data = getRecords(action.moodLogs);

    return moodLogsAdapter.setAll(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(MoodLogsApiActions.getMoodLogsSuccess, (state, action) => {
    const queryType = action.metadata?.pageQueryType;
    const pagination = getPaginationData<MoodLogPageQueries>(
      state,
      action.moodLogs,
      queryType
    );
    const data = getRecords(action.moodLogs);

    return moodLogsAdapter.upsertMany(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(MoodLogsApiActions.createMoodLogSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(MoodLogsApiActions.updateMoodLogSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(MoodLogsApiActions.deleteMoodLogSuccess, (state, action) =>
    moodLogsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      error: null
    })
  ),

  on(
    MoodLogsApiActions.loadMoodLogsFail,
    MoodLogsApiActions.getMoodLogsFail,
    MoodLogsApiActions.createMoodLogFail,
    MoodLogsApiActions.updateMoodLogFail,
    MoodLogsApiActions.deleteMoodLogFail,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error
    })
  ),

  on(
    MoodLogsApiActions.moodLogsCreated,
    MoodLogsApiActions.moodLogsPatched,
    (state, action) => {
      return moodLogsAdapter.upsertOne(action.moodLog, state);
    }
  ),

  on(MoodLogsApiActions.moodLogsRemoved, (state, action) => {
    return moodLogsAdapter.removeOne(action.id, state);
  }),

  on(MoodLogsGeneralActions.addMoodLogs, (state, action) => {
    return moodLogsAdapter.upsertMany(action.moodLogs, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(MoodLogsGeneralActions.clearMoodLogs, () => {
    return moodLogsAdapter.removeAll({
      ...initialState
    });
  })
);

export function moodLogsReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
