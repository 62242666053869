import { TMedicationTaskAmount } from '$shared/medications/medication-task-amount.schema';
import { MaybeArray } from '$shared/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MedicationTaskAmountsApiActions = createActionGroup({
  source: 'Medication Task Amounts API',
  events: {
    'Load Medication Task Amounts Success': props<{
      medicationTaskAmounts: TMedicationTaskAmount[];
    }>(),
    'Get Medication Task Amounts Success': props<{
      medicationTaskAmounts: TMedicationTaskAmount[];
    }>(),
    'Fetch Medication Task Amount Success': props<{
      medicationTaskAmount: TMedicationTaskAmount;
    }>(),
    'Create Medication Task Amount Success': props<{
      medicationTaskAmount: MaybeArray<TMedicationTaskAmount>;
    }>(),
    'Update Medication Task Amount Success': props<{
      medicationTaskAmount: TMedicationTaskAmount;
    }>(),
    'Delete Medication Task Amount Success': props<{ id: string }>(),
    'Load Medication Task Amounts Fail': props<{ error: Error }>(),
    'Get Medication Task Amounts Fail': props<{ error: Error }>(),
    'Fetch Medication Task Amount Fail': props<{ error: Error }>(),
    'Create Medication Task Amount Fail': props<{ error: Error }>(),
    'Update Medication Task Amount Fail': props<{ error: Error }>(),
    'Delete Medication Task Amount Fail': props<{ error: Error }>()
  }
});

export const MedicationTaskAmountsWsActions = createActionGroup({
  source: 'Medication Task Amounts WS',
  events: {
    'Medication Task Amount Created': props<{
      medicationTaskAmount: TMedicationTaskAmount;
    }>(),
    'Medication Task Amount Patched': props<{
      medicationTaskAmount: TMedicationTaskAmount;
    }>(),
    'Medication Task Amount Removed': props<{ id: string }>()
  }
});

export const MedicationTaskAmountsGeneralActions = createActionGroup({
  source: 'Medication Task Amounts General',
  events: {
    'Clear Medication Task Amounts': emptyProps(),
    'Add Medication Task Amounts': props<{
      medicationTaskAmounts: TMedicationTaskAmount[];
    }>()
  }
});
