import { Paginated, ResidentMedicalProfessional, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadResidentMedicalProfessionalsSuccess = createAction(
  '[Resident Medical Professionals API] Load Resident Medical Professionals Success',
  props<{
    residentMedicalProfessionals: Paginated<ResidentMedicalProfessional>;
  }>()
);
export const loadResidentMedicalProfessionalsFail = createAction(
  '[Resident Medical Professionals API] Load Resident Medical Professionals Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getResidentMedicalProfessionalsSuccess = createAction(
  '[Resident Medical Professionals API] Get Resident Medical Professionals Success',
  props<{
    residentMedicalProfessionals: Paginated<ResidentMedicalProfessional>;
  }>()
);
export const getResidentMedicalProfessionalsFail = createAction(
  '[Resident Medical Professionals API] Get Resident Medical Professionals Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchResidentMedicalProfessionalSuccess = createAction(
  '[Resident Medical Professionals API] Fetch Resident Medical Professional Success',
  props<{ residentMedicalProfessional: ResidentMedicalProfessional }>()
);
export const fetchResidentMedicalProfessionalFail = createAction(
  '[Resident Medical Professionals API] Fetch Resident Medical Professional Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createResidentMedicalProfessionalSuccess = createAction(
  '[Resident Medical Professionals API] Add Resident Medical Professional Success',
  props<{
    residentMedicalProfessional:
      | ResidentMedicalProfessional
      | ResidentMedicalProfessional[];
  }>()
);
export const createResidentMedicalProfessionalFail = createAction(
  '[Resident Medical Professionals API] Add Resident Medical Professional Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateResidentMedicalProfessionalSuccess = createAction(
  '[Resident Medical Professionals API] Update Resident Medical Professional Success',
  props<{ residentMedicalProfessional: Update<ResidentMedicalProfessional> }>()
);
export const updateResidentMedicalProfessionalFail = createAction(
  '[Resident Medical Professionals API] Update Resident Medical Professional Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteResidentMedicalProfessionalSuccess = createAction(
  '[Resident Medical Professionals API] Delete Resident Medical Professional Success',
  props<{ id: string }>()
);
export const deleteResidentMedicalProfessionalFail = createAction(
  '[Resident Medical Professionals API] Delete Resident Medical Professional Fail',
  props<{ error: Error }>()
);
