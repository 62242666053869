import { environment } from '$/environments/environment';
import { Acknowledgement, Params, Update } from '$/models';
import { createAction } from '@ngrx/store';

const source = '[Announcements List Page]';

export const getAnnouncementsPageInfo = createAction(
  '[Announcements List Page Route] Get Announcements Page Info',
  (params: Params) => ({ params })
);
// Load
export const loadAnnouncements = createAction(
  `${source} Load Announcements`,
  (params: Params) => ({ params })
);

export const getAnnouncements = createAction(
  `${source} Get Announcements`,
  (params: Params = { query: { $limit: environment.pageLimit } }) => ({
    params
  })
);

export const acknowledgeAnnouncement = createAction(
  `${source} Acknowledge Announcement`,
  (announcementId: string): Update<Acknowledgement> => {
    return {
      id: null,
      changes: {
        isRead: true
      },
      params: {
        query: {
          announcementId
        }
      }
    };
  }
);

export const deleteAnnouncement = createAction(
  `${source} Delete Announcements`,
  (id: string, params?: Params) => ({
    id,
    params
  })
);

export const AnnouncementsListPageActions = {
  getAnnouncementsPageInfo,
  loadAnnouncements,
  getAnnouncements,
  acknowledgeAnnouncement,
  deleteAnnouncement
};
