import { getDateRange } from '$/app/utils';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { MedicationOrdersListFilters } from '../medication-orders-list.vmodel';

export const defaultFilters = (
  ft: FacilityTime
): MedicationOrdersListFilters => {
  return {
    statuses: ['pending', 'ordered', 'received', 'cancelled', 'failed'],
    dateRange: getDateRange({ preset: 'past60Days' }, ft)
  };
};

export interface State {
  filters: MedicationOrdersListFilters;
}

export const initialState: State = {
  filters: null
};
