import { Params } from '$/models';
import { createAction } from '@ngrx/store';

export const loadResidentMedicalProfessionals = createAction(
  '[ResidentMedicalProfessionals Page] Load ResidentMedicalProfessionals',
  (params: Params = { query: {} }) => ({ params })
);

export const getResidentMedicalProfessionals = createAction(
  '[ResidentMedicalProfessionals Page] Get ResidentMedicalProfessionals',
  (params: Params = { query: {} }) => ({ params })
);
