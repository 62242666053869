import { ResidentLogType } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearResidentLogTypes = createAction(
  '[ResidentLogTypes] Clear ResidentLogTypes'
);

export const addResidentLogTypes = createAction(
  'Add Resident Log Types',
  props<{ residentLogTypes: ResidentLogType[] }>()
);
