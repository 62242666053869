import { FacilityUserGroup, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserGroupFormActions = createActionGroup({
  source: 'Facility User Group Picker',
  events: {
    'Get Facility User Group Members': props<{ params: Params }>(),

    'Create Facility User Group': props<{
      facilityUserGroup: FacilityUserGroup;
    }>(),

    'Load Facility User Group Members': (params: Params = { query: {} }) => ({
      params
    })
  }
});
