import { Static, TSchema } from '@sinclair/typebox';
import {
  TypeCheck,
  TypeCompiler,
  ValueError
} from '@sinclair/typebox/compiler';
import { Logger, LoggerPayload } from '../logger';

const validators = new Map<TSchema, TypeCheck<TSchema>>();

export function assertValid<T extends TSchema>(
  schema: T,
  value: any,
  loggerPayload?: LoggerPayload
): Static<T> {
  return validate(schema, value, loggerPayload, (msg, payload) =>
    Logger.throw(msg, payload)
  );
}

type SimpleLogFunc = (msg: string, payload?: LoggerPayload) => void;

export function validate<T extends TSchema>(
  schema: T,
  value: unknown,
  loggerPayload?: LoggerPayload,
  log: SimpleLogFunc = (msg, payload) => Logger.error(msg, payload)
): Static<T> | undefined {
  try {
    const errors = getValidationErrors(schema, value);

    if (errors) {
      log(`Value does not match schema`, {
        ...loggerPayload,
        errors,
        value
      });

      return undefined;
    }

    return value;
  } catch (error: any) {
    if (!error?.isLogged) {
      log(`Error while validating value`, {
        ...loggerPayload,
        error
      });
    }

    throw error;
  }
}

export function getValidationErrors(
  schema: TSchema,
  value: unknown
): ValueError[] | undefined {
  const validator = getValidator(schema);
  const result = validator.Check(value);

  return result ? undefined : Array.from(validator.Errors(value));
}

export function getValidator(schema: TSchema): TypeCheck<TSchema> {
  if (!validators.has(schema)) {
    validators.set(schema, TypeCompiler.Compile(schema));
  }

  return validators.get(schema)!;
}
