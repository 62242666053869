import { sortByName } from '$/app/utils';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const facilityUsersAdapter = createEntityAdapter<IFacilityUser>({
  sortComparer: sortByName
});

export interface State extends EntityState<IFacilityUser> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = facilityUsersAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
