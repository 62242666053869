import { FacilityUserDetailPageActions } from '$/app/pages/staff/facility-user-detail/facility-user-detail.actions';
import {
  EffectHelpersService,
  FacilityUserPhonesApiService
} from '$/app/services';
import { FacilityUserPhone, Paginated } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import {
  FacilityUserPhoneDetailActions,
  FacilityUserPhoneFormActions,
  FacilityUserPhonesApiActions,
  FacilityUserPhonesListActions
} from './actions';

@Injectable()
export class FacilityUserPhonesEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly facilityUserPhonesService = inject(
    FacilityUserPhonesApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  loadFacilityUserPhones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityUserPhonesListActions.loadFacilityUserPhones),
      switchMap((action) => {
        return this.facilityUserPhonesService.getAll(action.params).pipe(
          map((facilityUserPhones: Paginated<FacilityUserPhone>) => {
            return FacilityUserPhonesApiActions.loadFacilityUserPhonesSuccess({
              facilityUserPhones
            });
          }),
          catchError((error) => {
            return of(
              FacilityUserPhonesApiActions.loadFacilityUserPhonesFail({ error })
            );
          })
        );
      })
    );
  });

  getFacilityUserPhones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityUserPhonesListActions.getFacilityUserPhones),
      switchMap((action) => {
        return this.facilityUserPhonesService.getAll(action.params).pipe(
          map((facilityUserPhones: Paginated<FacilityUserPhone>) => {
            return FacilityUserPhonesApiActions.getFacilityUserPhonesSuccess({
              facilityUserPhones
            });
          }),
          catchError((error) => {
            return of(
              FacilityUserPhonesApiActions.getFacilityUserPhonesFail({ error })
            );
          })
        );
      })
    );
  });

  fetchFacilityUserPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityUserPhoneDetailActions.fetchFacilityUserPhone),
      switchMap((action) => {
        return this.facilityUserPhonesService
          .get(action.id, action.params)
          .pipe(
            map((facilityUserPhone: FacilityUserPhone) => {
              return FacilityUserPhonesApiActions.fetchFacilityUserPhoneSuccess(
                { facilityUserPhone }
              );
            }),
            catchError((error) => {
              return of(
                FacilityUserPhonesApiActions.fetchFacilityUserPhoneFail({
                  error
                })
              );
            })
          );
      })
    );
  });

  addFacilityUserPhone$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserPhoneFormActions.addFacilityUserPhone),
        exhaustMap((action) => {
          return this.facilityUserPhonesService
            .create(action.facilityUserPhone)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'FacilityUserPhone created successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  FacilityUserPhonesApiActions.addFacilityUserPhoneFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  addFacilityUserPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserPhonesApiActions.addFacilityUserPhoneFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateFacilityUserPhone$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserPhoneDetailActions.updateFacilityUserPhone),
        switchMap((action) => {
          return this.facilityUserPhonesService
            .patch(action.id, action.changes)
            .pipe(
              tap(
                this.effectHelpers.onModalFormSubmitSuccess(
                  'FacilityUserPhone updated successfully!'
                )
              ),
              catchError((error) => {
                this.store.dispatch(
                  FacilityUserPhonesApiActions.updateFacilityUserPhoneFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateFacilityUserPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserPhonesApiActions.updateFacilityUserPhoneFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteFacilityUserPhone$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserDetailPageActions.deleteFacilityUserPhone),
        exhaustMap((action) => {
          return this.facilityUserPhonesService.delete(action.id).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'FacilityUserPhone deleted successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                FacilityUserPhonesApiActions.deleteFacilityUserPhoneFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteFacilityUserPhoneFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserPhonesApiActions.deleteFacilityUserPhoneFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
