import { Dictionary } from '$shared/types';
import { RouterStateSnapshot } from '@angular/router';
import {
  BaseRouterStoreState,
  RouterStateSerializer
} from '@ngrx/router-store';
import { castArray } from 'lodash';

export interface AlcomyRouterState<
  TParams extends Dictionary<string> = Dictionary<string>,
  TQueryParams extends Dictionary<string> = Dictionary<string>
> extends BaseRouterStoreState {
  url: string;
  title: string;

  params: TParams;
  queryParams: TQueryParams;

  routeConfigPath: string;
  data: Dictionary<any>;
}

export class AlcomyCustomRouteSerializer
  implements RouterStateSerializer<AlcomyRouterState>
{
  serialize(routerStateSnapshot: RouterStateSnapshot): AlcomyRouterState {
    const params = {};
    const paths: string[] = [];

    let route = routerStateSnapshot.root;

    let title = route.title;

    // Traverse the tree of activated routes (starting with the root) and store
    // each route param in the params object and construct the routeConfigPath
    // along the way.
    while (route.firstChild) {
      route = route.firstChild;

      title = route.title || title;

      if (route.routeConfig.path !== '') {
        paths.push(route.routeConfig.path);
      }

      // NOTE: This only works if there is one route segment per child route
      route.paramMap.keys.forEach((key) => {
        params[key] = route.paramMap.get(key);
      });
    }

    const queryParams = { ...route.queryParams };

    // HACK: On some occurrences, for example on a page refresh, the query params
    // don't get decoded. This performs that decoding process manually. It also parses
    // any objects that were JSON.stringified in the query params.
    Object.entries(queryParams).forEach(([key, value]) => {
      const decoded = castArray(value).map((v) => {
        const decodedValue = decodeURI(v);

        return decodedValue.startsWith('{') || decodedValue.startsWith('[')
          ? JSON.parse(decodedValue)
          : decodedValue;
      });

      queryParams[key] = Array.isArray(value) ? decoded : decoded[0];
    });

    return {
      url: routerStateSnapshot.url,
      title,
      params,
      queryParams,
      routeConfigPath: `/${paths.join('/')}`,
      data: {
        // We only want the data from the last route
        ...route.data
      }
    };
  }
}
