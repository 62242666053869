import { Params } from '$/models';
import { createAction } from '@ngrx/store';

const source = '[Omit Medication Form Page]';

export const loadResidents = createAction(
  `${source} Load Residents`,
  (params: Params = { query: {} }) => ({ params })
);

export const loadResidentMedications = createAction(
  `${source} Load Resident Medications`,
  (params: Params = { query: {} }) => ({ params })
);

export const OmitMedicationFormPageActions = {
  loadResidents,
  loadResidentMedications
};
