import { isString } from 'lodash';

export const toBoolean = (value: any): boolean => {
  if (!isString(value)) {
    return !!value;
  }

  if (value.toLowerCase() === 'true') {
    return true;
  }

  if (value.toLowerCase() === 'false') {
    return false;
  }
};
