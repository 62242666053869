import { FacilityTimeService } from '$/app/services/utils/facility-time.service';
import { extractTouchedChanges } from '$/app/utils';
import { AlcomyLuxonDateAdapter } from '$/lib/alcomy-luxon-date-adapter';
import { ToLuxonParam } from '$shared/utils';
import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS,
  MatLuxonDateModule
} from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
  MatDatepickerModule
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonButton, IonIcon } from '@ionic/angular/standalone';
import { DateTime } from 'luxon';
import { filter } from 'rxjs/operators';
import { AlcPipesModule } from '../../pipes';

export type AlcDateInputEvent = MatDatepickerInputEvent<DateTime>;

@Component({
  selector: 'alc-date-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatLuxonDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    IonButton,
    IonIcon,
    AlcPipesModule
  ],
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: AlcomyLuxonDateAdapter, //using this custom adapter fixes frontend e2e tests
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS }
  ]
})
export class AlcDateInputComponent implements ControlValueAccessor, OnInit {
  private readonly ngControl = inject(NgControl);
  private readonly destroyRef = inject(DestroyRef);
  private readonly ft = inject(FacilityTimeService);

  protected readonly date = new FormControl<string>(null);

  @Input() public label: string = 'Date';
  @Input() public min: DateTime;
  @Input() public max: DateTime;
  @Input() public showClear: boolean = false;
  @Input() public hint: string;
  @ViewChild('datePicker') public datePicker: MatDatepicker<DateTime>;

  @Input()
  public get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  public set required(value: boolean | string | undefined | null) {
    this._required = !!value;
  }
  public _required: boolean | undefined;

  @Output() public dateChange = new EventEmitter<AlcDateInputEvent>();

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit() {
    if (this.required) {
      this.date.setValidators(Validators.required);
    }

    this.date.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        // console.log(`Date ${this.counter}`, typeof value, value);
        // if (!value) {
        //   this.date.setValue(null, { emitEvent: false });
        //   this.date.updateValueAndValidity({ emitEvent: false });
        // }

        this.onChange(
          value ? this.ft.convertDateTimeKeepingLocalTime(value) : null
        );
      });

    extractTouchedChanges(this.date)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((touched) => touched)
      )
      .subscribe(() => {
        this.onTouch();

        if (this.ngControl.invalid && this.ngControl.touched) {
          this.date.setErrors(this.ngControl.errors);
        }
      });
  }

  private get invalid(): boolean {
    return this.ngControl?.control?.invalid ?? this.date.invalid ?? false;
  }

  protected get errors(): ValidationErrors | null {
    if (!this.ngControl?.control?.errors && !this.date.errors) {
      return null;
    }

    return {
      ...(this.ngControl?.control?.errors || {}),
      ...(this.date.errors || {})
    };
  }

  protected get shouldShowError(): boolean {
    if (!this.date) {
      return false;
    }

    const control = this.ngControl.control || this.date;

    const { dirty, touched } = control;

    if (touched) {
      this.date.markAsTouched();

      if (this.errors) {
        this.date.setErrors(this.errors);
      }
    }

    if (this.invalid || this.errors) {
      return dirty || touched;
    }

    return false;
  }

  protected clearControl() {
    this.date.setValue(null);
    this.onTouch();
  }

  /*  Control Value Accessor Implementation */

  public onChange: (value: any) => any = () => {};
  public onTouch: () => any = () => {};

  public writeValue(value: ToLuxonParam) {
    const dateValue = value && this.ft.convertDateTime(value);
    const localZone = DateTime.local().zoneName;
    const dateValueInLocalTime = dateValue?.setZone(localZone, {
      keepLocalTime: true
    });
    this.date.setValue(dateValueInLocalTime?.toISO() || null, {
      emitEvent: false
    });
  }

  public registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.date.disable();
    } else {
      this.date.enable();
    }
  }
}
