import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { SeizureLogsPageActions } from '$/app/pages/notebook/shared/logs/seizure-log/seizure-log.actions';
import { getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import { SeizureLogsApiActions, SeizureLogsGeneralActions } from './actions';
import { initialState, seizureLogsAdapter } from './seizure-logs.state';
import { SeizureLogFormPageActions } from '$/app/shared/pages/forms/log-forms/seizure-log-form/seizure-log-form.actions';

export const seizureLogsFeature = createFeature({
  name: 'seizureLogs',
  reducer: createReducer(
    initialState,

    on(
      SeizureLogsPageActions.loadSeizureLogs,
      SeizureLogsPageActions.getSeizureLogs,
      SeizureLogFormPageActions.createSeizureLog,
      SeizureLogFormPageActions.updateSeizureLog,
      SeizureLogsPageActions.deleteSeizureLog,
      ResidentMedicationTasksPageActions.deleteSeizureLog,
      MissedMedicationsPageActions.deleteSeizureLog,
      RoutineMarListPageActions.deleteSeizureLog,
      RoutineMarDetailPageActions.deleteSeizureLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(SeizureLogsApiActions.loadSeizureLogsSuccess, (state, action) => {
      const data = getRecords(action.seizureLogs);

      return seizureLogsAdapter.setAll(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(SeizureLogsApiActions.getSeizureLogsSuccess, (state, action) => {
      const data = getRecords(action.seizureLogs);

      return seizureLogsAdapter.upsertMany(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(SeizureLogsApiActions.createSeizureLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(SeizureLogsApiActions.updateSeizureLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(SeizureLogsApiActions.deleteSeizureLogSuccess, (state, action) =>
      seizureLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      SeizureLogsApiActions.loadSeizureLogsFail,
      SeizureLogsApiActions.getSeizureLogsFail,
      SeizureLogsApiActions.createSeizureLogFail,
      SeizureLogsApiActions.updateSeizureLogFail,
      SeizureLogsApiActions.deleteSeizureLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      SeizureLogsApiActions.seizureLogsCreated,
      SeizureLogsApiActions.seizureLogsPatched,
      (state, action) => {
        return seizureLogsAdapter.upsertOne(action.seizureLog, state);
      }
    ),

    on(SeizureLogsApiActions.seizureLogsRemoved, (state, action) => {
      return seizureLogsAdapter.removeOne(action.id, state);
    }),

    on(SeizureLogsGeneralActions.addSeizureLogs, (state, action) => {
      return seizureLogsAdapter.upsertMany(action.seizureLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(SeizureLogsGeneralActions.clearSeizureLogs, () => {
      return seizureLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
