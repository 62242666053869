import { FacilityTimeService } from '$/app/services';
import {
  RelativeTimeUntilOptions,
  ToLuxonParam,
  relativeTimeUntil
} from '$shared/utils';
import { Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({
  name: 'relativeTime',
  standalone: true
})
export class AlcRelativeTimePipe implements PipeTransform {
  private readonly ft = inject(FacilityTimeService);

  transform(
    value: ToLuxonParam,
    options?: Partial<RelativeTimeUntilOptions>
  ): any {
    if (!value) {
      return value;
    }

    return relativeTimeUntil(value, { days: 7 }, { ...options, ft: this.ft });
  }
}
