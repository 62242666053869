import { IPaginatedResponse } from '$/models';
import { reduce, scan } from 'rxjs';

// This will wait until all pages have been fetched until it emits the accumulated value
export function reducePaginatedResponses<T = any>() {
  return reduce(
    (acc: IPaginatedResponse<T>, page: IPaginatedResponse<T>) => {
      acc = {
        ...acc,
        total: page.total,
        skip: page.skip,
        limit: page.limit,
        data: [...acc.data, ...page.data]
      };

      return acc;
    },
    { total: 0, skip: 0, limit: 0, data: [] }
  );
}

// This will emit the accumulated value on the go
export function scanPaginatedResponses<T = any>() {
  return scan(
    (acc: IPaginatedResponse<T>, page: IPaginatedResponse<T>) => {
      acc = {
        ...acc,
        total: page.total,
        skip: page.skip,
        limit: page.limit,
        data: [...acc.data, ...page.data]
      };

      return acc;
    },
    { total: 0, skip: 0, limit: 0, data: [] }
  );
}
