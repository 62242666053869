import { AnnouncementDetailPageActions } from '$/app/pages/communication/announcement-detail/announcement-detail.actions';
import * as AnnouncementListPageActions from '$/app/pages/communication/announcements-list/announcements-list.actions';
import { AnnouncementFormPageActions } from '$/app/shared/pages/forms/announcement-form/announcement-form.actions';
import { extractFieldsToDict } from '$/app/utils/serialization/extract-fields-to-dictionary';
import { AnnouncementMetrics } from '$/models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  AnnouncementsApiActions,
  AnnouncementsGeneralActions,
  AnnouncementsWsActions
} from './announcements.actions';
import { announcementsAdapter, initialState } from './announcements.state';

export const announcementsFeature = createFeature({
  name: 'announcements',
  reducer: createReducer(
    initialState,

    on(
      AnnouncementListPageActions.loadAnnouncements,
      AnnouncementListPageActions.getAnnouncements,
      AnnouncementListPageActions.deleteAnnouncement,
      AnnouncementDetailPageActions.fetchAnnouncementDetailPageInfo,
      AnnouncementFormPageActions.createAnnouncement,
      AnnouncementDetailPageActions.updateAnnouncement,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(AnnouncementsApiActions.loadAnnouncementsSuccess, (state, action) => {
      const data = action.announcements.data;
      const metrics = action.metadata.metrics;
      return announcementsAdapter.setAll(data, {
        ...state,
        metrics: {
          ...state.metrics,
          ...metrics
        },
        total: action.announcements.total,
        skip: action.announcements.skip,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(AnnouncementsApiActions.getAnnouncementsSuccess, (state, action) => {
      return announcementsAdapter.upsertMany(action.announcements.data, {
        ...state,
        total: action.announcements.total,
        skip: action.announcements.skip,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(AnnouncementsApiActions.fetchAnnouncementSuccess, (state, action) => {
      const metrics = action.metadata.metrics;
      return announcementsAdapter.upsertOne(action.announcement, {
        ...state,
        metrics: {
          ...state.metrics,
          ...metrics
        },
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(AnnouncementsApiActions.createAnnouncementSuccess, (state, action) => {
      const announcements = castArray(action.announcement);

      return announcementsAdapter.addMany(announcements, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(AnnouncementsApiActions.updateAnnouncementSuccess, (state, action) => {
      return announcementsAdapter.updateOne(action.announcement, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(AnnouncementsApiActions.deleteAnnouncementSuccess, (state, action) => {
      return announcementsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      AnnouncementsApiActions.loadAnnouncementsFail,
      AnnouncementsApiActions.getAnnouncementsFail,
      AnnouncementsApiActions.fetchAnnouncementFail,
      AnnouncementsApiActions.createAnnouncementFail,
      AnnouncementsApiActions.updateAnnouncementFail,
      AnnouncementsApiActions.deleteAnnouncementFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(AnnouncementsWsActions.announcementsCreated, (state, action) => {
      const metrics = extractFieldsToDict<AnnouncementMetrics>(
        action.announcement,
        ['totalAcknowledgements', 'readAcknowledgements', 'totalReplies']
      );
      return announcementsAdapter.upsertOne(action.announcement, {
        ...state,
        metrics: {
          ...state.metrics,
          ...metrics
        }
      });
    }),

    on(AnnouncementsWsActions.announcementsPatched, (state, action) => {
      return announcementsAdapter.updateOne(
        {
          id: action.announcement.id,
          changes: action.announcement
        },
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }),

    on(AnnouncementsWsActions.announcementsRemoved, (state, action) => {
      return announcementsAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(AnnouncementsGeneralActions.addAnnouncements, (state, action) => {
      const data = action.announcements;
      // Must come first since fields must be removed from data before adapter call
      const metrics = extractFieldsToDict<AnnouncementMetrics>(data, [
        'totalAcknowledgements',
        'readAcknowledgements',
        'totalReplies'
      ]);

      return announcementsAdapter.upsertMany(data, {
        ...state,
        metrics: {
          ...state.metrics,
          ...metrics
        }
      });
    }),

    on(AnnouncementsGeneralActions.clearAnnouncements, (state) => {
      return announcementsAdapter.removeAll({
        ...state,
        total: 0,
        skip: 0,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
