import { MedicationDestructionStatusId } from '$shared/lookups/medication-destruction-statuses.lookup';
import { IMedicationDestruction } from '$shared/services/medication-destructions.schema';
import { Params } from '@angular/router';
import { createActionGroup, props } from '@ngrx/store';

export const DashboardMedicationDestructionPageActions = createActionGroup({
  source: 'Dashboard Medication Destruction Page',
  events: {
    'Load Medication Destruction Items': (params: Params) => ({
      params
    }),
    'Load Medication Destructions': (params: Params) => ({
      params
    }),
    'Delete Medication Destruction Item': props<{
      id: string;
      params?: Params;
    }>(),
    'Delete Medication Destruction': props<{
      id: string;
      params?: Params;
    }>(),
    'Update Medication Destruction': props<{
      id: string;
      changes: Partial<IMedicationDestruction>;
      params?: Params;
    }>(),
    'Create Medication Destruction': props<{
      medicationDestruction: {
        items: string[];
        status: MedicationDestructionStatusId;
        method?: string;
      };
      params?: Params;
    }>()
  }
});
