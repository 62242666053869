import { Create } from '$/models';
import { IMedicationDestructionItem } from '$shared/services/medication-destruction-items.schema';
import { createActionGroup, props } from '@ngrx/store';

export const InventoryItemDestructionModalActions = createActionGroup({
  source: 'Inventory Item Destruction Modal',
  events: {
    'Create Medication Destruction Items':
      props<Create<Partial<IMedicationDestructionItem>>>()
  }
});
