import { MedicationOrderDetailsPageActions } from '$/app/pages/medications/dashboard/medication-orders/medication-order-details/medication-order-details.actions';
import { MedicationOrdersListPageActions } from '$/app/pages/medications/dashboard/medication-orders/medication-orders-list/medication-orders-list.actions';
import { MedicationsToOrderListPageActions } from '$/app/pages/medications/dashboard/medication-orders/medications-to-order-list/medications-to-order-list.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  MedicationOrdersApiActions,
  MedicationOrdersGeneralActions,
  MedicationOrdersWsActions
} from './medication-orders.actions';
import {
  initialState,
  medicationOrdersAdapter
} from './medication-orders.state';

export const medicationOrdersFeature = createFeature({
  name: 'medicationOrders',
  reducer: createReducer(
    initialState,

    on(
      MedicationOrdersListPageActions.loadMedicationOrders,
      MedicationOrderDetailsPageActions.fetchMedicationOrder,
      MedicationOrdersListPageActions.cancelMedicationOrder,
      MedicationOrderDetailsPageActions.cancelMedicationOrder,
      MedicationOrdersListPageActions.deleteMedicationOrder,
      MedicationOrderDetailsPageActions.deleteMedicationOrder,
      MedicationsToOrderListPageActions.orderQueuedMedications,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      MedicationOrdersApiActions.loadMedicationOrdersSuccess,
      (state, action) => {
        return medicationOrdersAdapter.setAll(action.medicationOrders.data, {
          ...state,
          total: action.medicationOrders.total,
          skip: action.medicationOrders.skip,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),
    on(
      MedicationOrdersApiActions.getMedicationOrdersSuccess,
      (state, action) => {
        return medicationOrdersAdapter.upsertMany(
          action.medicationOrders.data,
          {
            ...state,
            total: action.medicationOrders.total,
            skip: action.medicationOrders.skip,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationOrdersApiActions.fetchMedicationOrderSuccess,
      (state, action) => {
        return medicationOrdersAdapter.upsertOne(action.medicationOrder, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(MedicationOrdersApiActions.createMedicationOrderSuccess, (state) => {
      return {
        ...state,
        loading: false,
        error: null
      };
    }),

    on(
      MedicationOrdersApiActions.updateMedicationOrderSuccess,
      (state, action) => {
        return medicationOrdersAdapter.updateOne(action.medicationOrder, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationOrdersApiActions.deleteMedicationOrderSuccess,
      (state, action) => {
        return medicationOrdersAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationOrdersApiActions.loadMedicationOrdersFail,
      MedicationOrdersApiActions.getMedicationOrdersFail,
      MedicationOrdersApiActions.fetchMedicationOrderFail,
      MedicationOrdersApiActions.createMedicationOrderFail,
      MedicationOrdersApiActions.updateMedicationOrderFail,
      MedicationOrdersApiActions.deleteMedicationOrderFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicationOrdersWsActions.medicationOrdersCreated,
      MedicationOrdersWsActions.medicationOrdersPatched,
      (state, action) => {
        return medicationOrdersAdapter.upsertOne(action.medicationOrder, {
          ...state
        });
      }
    ),

    on(MedicationOrdersWsActions.medicationOrdersRemoved, (state, action) => {
      return medicationOrdersAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(MedicationOrdersGeneralActions.addMedicationOrders, (state, action) => {
      return medicationOrdersAdapter.upsertMany(action.medicationOrders, {
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }),

    on(MedicationOrdersGeneralActions.clearMedicationOrders, (state) => {
      return medicationOrdersAdapter.removeAll({
        ...state,
        total: 0,
        skip: 0,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
