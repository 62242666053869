import { FeathersService } from '$/app/services/feathers.service';
import { FilesWsActions } from '$/app/store/files';
import { IFile } from '$/models';
import { GetFileUrlResult } from '$shared/files/get-file-url-result';
import { IFileWithSignedUrl } from '$shared/services/file.schema';
import { Cache, toLuxon } from '$shared/utils';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { AbstractApiService } from './abstract-api-service.service';

const urlCache = new Cache<string, string>();
const fileCache = new Cache<string, IFileWithSignedUrl>();
const urlPromiseCache = new Cache<string, Promise<GetFileUrlResult>>();
const filePromiseCache = new Cache<string, Promise<IFileWithSignedUrl>>();

@Injectable({ providedIn: 'root' })
export class FilesApiService extends AbstractApiService<IFile> {
  constructor(feathers: FeathersService, store: Store) {
    super('files', feathers, store, {
      entityName: 'file',
      created: FilesWsActions.fileCreated,
      patched: FilesWsActions.filePatched,
      removed: FilesWsActions.fileRemoved
    });
  }

  async getUrl(id: string): Promise<string | undefined> {
    if (!id) {
      return undefined;
    }

    const url = urlCache.get(id);

    if (url) {
      return url;
    }

    let promise = urlPromiseCache.get(id);

    if (!promise) {
      promise = this.service.get(id, {
        query: { $actions: [{ getUrl: true }] }
      }) as Promise<GetFileUrlResult>;

      urlPromiseCache.set(id, promise, DateTime.now().plus({ minute: 1 }));
    }

    try {
      const result = await promise;

      urlCache.set(id, result.url, toLuxon(result.expiresAt));
      return result.url;
    } finally {
      urlPromiseCache.delete(id);
    }
  }

  // Use this when you need the file metadata as well as the signed URL. e.g.
  // you need the content type in order to display the file in the right
  // component
  async getFileWithSignedUrl(
    id: string
  ): Promise<IFileWithSignedUrl | undefined> {
    if (!id) {
      return undefined;
    }

    const file = fileCache.get(id);

    if (file) {
      return file;
    }

    let promise = filePromiseCache.get(id);

    if (!promise) {
      promise = this.service.get(id, {
        query: { $actions: [{ getFileWithSignedUrl: true }] }
      }) as Promise<IFileWithSignedUrl>;

      filePromiseCache.set(id, promise, DateTime.now().plus({ minute: 1 }));
    }

    try {
      const result = await promise;

      const expiresAt = toLuxon(result.expiresAt);

      fileCache.set(id, result, expiresAt);
      urlCache.set(id, result.url, expiresAt);
      return result;
    } finally {
      filePromiseCache.delete(id);
    }
  }
}
