import { FacilitiesSelectors } from '$/app/store/facilities';
import { FeatureFlag } from '$shared/constants/feature-flags';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { Store } from '@ngrx/store';

@Pipe({ name: 'hasFeatureFlag', standalone: true, pure: false })
export class AlcHasFeatureFlagPipe implements PipeTransform {
  private readonly store = inject(Store);

  private readonly featureFlags = this.store.selectSignal(
    FacilitiesSelectors.selectFeatureFlags
  );

  transform(featureFlag: FeatureFlag) {
    return !featureFlag || this.featureFlags()[featureFlag];
  }
}
