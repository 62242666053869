import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BloodSugarLogsEffects } from './blood-sugar-logs.effects';
import { bloodSugarLogsFeature } from './blood-sugar-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(bloodSugarLogsFeature),
    EffectsModule.forFeature([BloodSugarLogsEffects])
  ],
  providers: [BloodSugarLogsEffects]
})
export class BloodSugarLogsStoreModule {}
