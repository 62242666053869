import { Params } from '$/models';
import { createAction } from '@ngrx/store';

const source = '[Prn Form Page]';

export const getPrnMedications = createAction(
  `${source} Get Prn Medications`,
  (params: Params = { query: {} }) => ({
    params
  })
);

export const getResidentsAndPrns = createAction(
  `${source} Get Residents and PRNs`,
  (params: Params = { query: {} }) => ({
    params
  })
);

export const PrnFormActions = {
  getPrnMedications,
  getResidentsAndPrns
};
