import { Paginated } from '$/models';
import { IOxygenLog } from '$shared/services/oxygen-log';
import { createAction, props } from '@ngrx/store';

const source = '[Oxygen Logs API]';
const sourceWS = '[Oxygen Logs WS]';

// Load
export const loadOxygenLogsSuccess = createAction(
  `${source} Load Oxygen Logs Success`,
  props<{ oxygenLogs: Paginated<IOxygenLog> }>()
);
export const loadOxygenLogsFail = createAction(
  `${source} Load Oxygen Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getOxygenLogsSuccess = createAction(
  `${source} Get Oxygen Logs Success`,
  props<{ oxygenLogs: Paginated<IOxygenLog> }>()
);
export const getOxygenLogsFail = createAction(
  `${source} Get Oxygen Logs Fail`,
  props<{ error: Error }>()
);
// Create
export const createOxygenLogSuccess = createAction(
  `${source} Add Oxygen Logs Success`,
  props<{ oxygenLog: IOxygenLog }>()
);
export const createOxygenLogFail = createAction(
  `${source} Add Oxygen Logs Fail`,
  props<{ error: Error }>()
);
// Update
export const updateOxygenLogSuccess = createAction(
  `${source} Update Oxygen Log Success`,
  props<{ oxygenLog: IOxygenLog }>()
);
export const updateOxygenLogFail = createAction(
  `${source} Update Oxygen Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteOxygenLogSuccess = createAction(
  `${source} Delete Oxygen Log Success`,
  props<{ id: string }>()
);
export const deleteOxygenLogFail = createAction(
  `${source} Delete Oxygen Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Oxygen Logs Created
export const oxygenLogsCreated = createAction(
  `${sourceWS} Oxygen Logs Created`,
  props<{ oxygenLog: IOxygenLog }>()
);
// [WS] Oxygen Logs Patched
export const oxygenLogsPatched = createAction(
  `${sourceWS} Oxygen Logs Patched`,
  props<{ oxygenLog: IOxygenLog }>()
);
// [WS] Oxygen Logs Removed
export const oxygenLogsRemoved = createAction(
  `${sourceWS} Oxygen Logs Removed`,
  props<{ id: string }>()
);
