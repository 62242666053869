import { DoseValue, stringifyDose } from '$shared/doses';
import { MeasurementId } from '$shared/lookups/measurements.lookup';
import { MedicationFormId } from '$shared/lookups/medication-forms.lookup';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'medMeasurement', standalone: true })
export class AlcMedicationMeasurementPipe implements PipeTransform {
  transform(
    value: string | DoseValue,
    measurementId?: MeasurementId | null,
    formId?: MedicationFormId | null
  ): string {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      value = {
        value
      };
    }

    return stringifyDose(value, measurementId, formId);
  }
}
