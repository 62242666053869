import { ILibraryCollectionDocumentTemplate, Paginated } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const LibraryCollectionDocumentTemplatesApiActions = createActionGroup({
  source: 'Library Collection Document Templates API',
  events: {
    // Success Actions
    'Load Library Collection Document Templates Success': props<{
      libraryCollectionDocumentTemplates: Paginated<ILibraryCollectionDocumentTemplate>;
    }>(),
    'Get Library Collection Document Templates Success': props<{
      libraryCollectionDocumentTemplates: Paginated<ILibraryCollectionDocumentTemplate>;
    }>(),
    'Fetch Library Collection Document Template Success': props<{
      libraryCollectionDocumentTemplate: ILibraryCollectionDocumentTemplate;
    }>(),
    'Create Library Collection Document Template Success': props<{
      libraryCollectionDocumentTemplate: ILibraryCollectionDocumentTemplate;
    }>(),
    'Update Library Collection Document Template Success': props<{
      libraryCollectionDocumentTemplate: ILibraryCollectionDocumentTemplate;
    }>(),
    'Delete Library Collection Document Template Success': props<{
      id: string;
    }>(),
    // Fail Actions
    'Load Library Collection Document Templates Fail': props<{
      error: Error;
    }>(),
    'Get Library Collection Document Templates Fail': props<{ error: Error }>(),
    'Fetch Library Collection Document Template Fail': props<{
      error: Error;
    }>(),
    'Create Library Collection Document Template Fail': props<{
      error: Error;
    }>(),
    'Update Library Collection Document Template Fail': props<{
      error: Error;
    }>(),
    'Delete Library Collection Document Template Fail': props<{
      error: Error;
    }>()
  }
});

export const LibraryCollectionDocumentTemplatesWsActions = createActionGroup({
  source: 'Library Collection Document Templates WS',
  events: {
    'Library Collection Document Template Created': props<{
      libraryCollectionDocumentTemplate: ILibraryCollectionDocumentTemplate;
    }>(),
    'Library Collection Document Template Patched': props<{
      libraryCollectionDocumentTemplate: ILibraryCollectionDocumentTemplate;
    }>(),
    'Library Collection Document Template Removed': props<{ id: string }>()
  }
});
