import { BodyCheckObservationsSelectors } from '$/app/store/body-check-observations';
import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { lookupValue } from '$shared/lookups/lookup-value';
import { IBodyCheckLog } from '$shared/services/body-check-log';
import { IBodyCheckObservation } from '$shared/services/body-check-observation';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { bodyCheckLogsFeature } from './body-check-logs.reducer';
import { bodyCheckLogsAdapter } from './body-check-logs.state';

type BodyCheckLogGraph = IBodyCheckLog & {
  observations?: IBodyCheckObservation[];
};

export const {
  selectBodyCheckLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = bodyCheckLogsFeature;

export const { selectAll, selectTotal } = bodyCheckLogsAdapter.getSelectors(
  selectBodyCheckLogsState
);

export const selectAllWithRelations = createSelector(
  selectAll,
  BodyCheckObservationsSelectors.selectAllByBodyCheckLogId,
  (logs, observations): BodyCheckLogGraph[] => {
    return logs.map((log) => ({ ...log, observations: observations[log.id] }));
  }
);

export const selectResidentBodyCheckLogs = createSelector(
  selectAllWithRelations,
  RouterSelectors.selectParam('residentId'),
  (bodyCheckLogs, residentId): BodyCheckLogGraph[] => {
    if (isEmpty(bodyCheckLogs) || !residentId) {
      return [];
    }

    return bodyCheckLogs.filter((bodyCheckLog) => {
      return bodyCheckLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask = createLogsByMedicationTaskSelector(
  selectAllWithRelations
);

export const selectLogsByCareTask = createLogsByCareTaskSelector(
  selectAllWithRelations
);

export const selectLogsByPrn = createLogsByPrnSelector(selectAllWithRelations);

export const selectLogsByQuery = (query: Query<IBodyCheckLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IBodyCheckLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IBodyCheckLog>) => {
  return createSelector(
    selectLogsByQuery(query),
    BodyCheckObservationsSelectors.selectAll,
    (logs, observations) => {
      const filteredObservations = observations.filter((observation) => {
        return logs.some((log) => log.id === observation.bodyCheckLogId);
      });

      const metrics = [
        {
          type: 'value',
          label: 'Records',
          data: logs?.length ?? 0
        },
        {
          type: 'value',
          label: 'Observes',
          data: filteredObservations?.length
        },
        {
          type: 'value',
          label: 'New',
          data: filteredObservations?.filter((o) => o.isNew).length
        },
        {
          type: 'value',
          label: 'Moderate+',
          data: filteredObservations?.filter(
            (o) =>
              lookupValue('severityLevels', o?.severityLevelId, 'rating') > 1
          ).length
        }
      ];

      return {
        metrics,
        logs
      };
    }
  );
};
