import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { TemperatureLogsPageActions } from '$/app/pages/notebook/shared/logs/temperature-log/temperature-log.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  TemperatureLogsApiActions,
  TemperatureLogsGeneralActions,
  TemperatureLogsWsActions
} from './temperature-logs.actions';
import {
  TemperatureLogPageQueries,
  initialState,
  temperatureLogsAdapter
} from './temperature-logs.state';
import { TemperatureLogFormPageActions } from '$/app/shared/pages/forms/log-forms/temp-log-form/temp-log-form.actions';

export const temperatureLogsFeature = createFeature({
  name: 'temperatureLogs',
  reducer: createReducer(
    initialState,

    on(
      TemperatureLogsPageActions.loadTemperatureLogs,
      TemperatureLogsPageActions.getTemperatureLogs,
      TemperatureLogFormPageActions.createTemperatureLog,
      TemperatureLogFormPageActions.updateTemperatureLog,
      TemperatureLogsPageActions.deleteTemperatureLog,
      ResidentMedicationTasksPageActions.deleteTemperatureLog,
      MissedMedicationsPageActions.deleteTemperatureLog,
      RoutineMarListPageActions.deleteTemperatureLog,
      RoutineMarDetailPageActions.deleteTemperatureLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      TemperatureLogsApiActions.loadTemperatureLogsSuccess,
      (state, action) => {
        const queryType = action.metadata?.pageQueryType;
        const pagination = getPaginationData<TemperatureLogPageQueries>(
          state,
          action.temperatureLogs,
          queryType
        );
        const data = getRecords(action.temperatureLogs);

        return temperatureLogsAdapter.setAll(data, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(TemperatureLogsApiActions.getTemperatureLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<TemperatureLogPageQueries>(
        state,
        action.temperatureLogs,
        queryType
      );
      const data = getRecords(action.temperatureLogs);

      return temperatureLogsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(TemperatureLogsApiActions.createTemperatureLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(TemperatureLogsApiActions.updateTemperatureLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(TemperatureLogsApiActions.deleteTemperatureLogSuccess, (state, action) =>
      temperatureLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      TemperatureLogsApiActions.loadTemperatureLogsFail,
      TemperatureLogsApiActions.getTemperatureLogsFail,
      TemperatureLogsApiActions.createTemperatureLogFail,
      TemperatureLogsApiActions.updateTemperatureLogFail,
      TemperatureLogsApiActions.deleteTemperatureLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      TemperatureLogsWsActions.temperatureLogsCreated,
      TemperatureLogsWsActions.temperatureLogsPatched,
      (state, action) => {
        return temperatureLogsAdapter.upsertOne(action.temperatureLog, state);
      }
    ),

    on(TemperatureLogsWsActions.temperatureLogsRemoved, (state, action) => {
      return temperatureLogsAdapter.removeOne(action.id, state);
    }),

    on(TemperatureLogsGeneralActions.addTemperatureLogs, (state, action) => {
      return temperatureLogsAdapter.upsertMany(action.temperatureLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(TemperatureLogsGeneralActions.clearTemperatureLogs, () => {
      return temperatureLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
