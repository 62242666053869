import { FacilityUserPhone, Paginated, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadFacilityUserPhonesSuccess = createAction(
  '[FacilityUserPhones API] Load FacilityUserPhones Success',
  props<{ facilityUserPhones: Paginated<FacilityUserPhone> }>()
);
export const loadFacilityUserPhonesFail = createAction(
  '[FacilityUserPhones API] Load FacilityUserPhones Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getFacilityUserPhonesSuccess = createAction(
  '[FacilityUserPhones API] Get FacilityUserPhones Success',
  props<{ facilityUserPhones: Paginated<FacilityUserPhone> }>()
);
export const getFacilityUserPhonesFail = createAction(
  '[FacilityUserPhones API] Get FacilityUserPhones Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchFacilityUserPhoneSuccess = createAction(
  '[FacilityUserPhone API] Fetch FacilityUserPhone Success',
  props<{ facilityUserPhone: FacilityUserPhone }>()
);
export const fetchFacilityUserPhoneFail = createAction(
  '[FacilityUserPhone API] Fetch FacilityUserPhone Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const addFacilityUserPhoneSuccess = createAction(
  '[FacilityUserPhones API] Add FacilityUserPhone Success',
  props<{ facilityUserPhone: MaybeArray<FacilityUserPhone> }>()
);
export const addFacilityUserPhoneFail = createAction(
  '[FacilityUserPhones API] Add FacilityUserPhone Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateFacilityUserPhoneSuccess = createAction(
  '[FacilityUserPhones API] Update FacilityUserPhone Success',
  props<{ facilityUserPhone: Update<FacilityUserPhone> }>()
);
export const updateFacilityUserPhoneFail = createAction(
  '[FacilityUserPhones API] Update FacilityUserPhone Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteFacilityUserPhoneSuccess = createAction(
  '[FacilityUserPhones API] Delete FacilityUserPhone Success',
  props<{ id: string }>()
);
export const deleteFacilityUserPhoneFail = createAction(
  '[FacilityUserPhones API] Delete FacilityUserPhone Fail',
  props<{ error: Error }>()
);
