import { FacilityUserGroupFormActions } from '$/app/pages/staff/facility-user-group-form/facility-user-group-form.actions';
import { FacilityUserGroupActions } from '$/app/pages/staff/staff-groups/facility-user-groups-list.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  FacilityUserGroupDetailActions,
  FacilityUserGroupsApiActions,
  FacilityUserGroupsGeneralActions,
  FacilityUserGroupsWsActions
} from './actions';
import {
  facilityUserGroupsAdapter,
  initialState
} from './facility-user-groups.state';

const reducer = createReducer(
  initialState,

  on(
    FacilityUserGroupActions.loadFacilityUserGroups,
    FacilityUserGroupActions.getFacilityUserGroups,
    FacilityUserGroupDetailActions.fetchFacilityUserGroup,
    FacilityUserGroupFormActions.createFacilityUserGroup,
    FacilityUserGroupDetailActions.updateFacilityUserGroup,
    FacilityUserGroupDetailActions.deleteFacilityUserGroup,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    FacilityUserGroupsApiActions.loadFacilityUserGroupsSuccess,
    (state, action) => {
      return facilityUserGroupsAdapter.setAll(action.facilityUserGroups, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupsApiActions.getFacilityUserGroupsSuccess,
    FacilityUserGroupsGeneralActions.addFacilityUserGroups,
    (state, action) => {
      return facilityUserGroupsAdapter.upsertMany(action.facilityUserGroups, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupsApiActions.fetchFacilityUserGroupSuccess,
    (state, action) => {
      return facilityUserGroupsAdapter.upsertOne(action.facilityUserGroup, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupsApiActions.createFacilityUserGroupSuccess,
    (state, action) => {
      const facilityUserGroups = castArray(action.facilityUserGroup);

      return facilityUserGroupsAdapter.addMany(facilityUserGroups, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupsApiActions.updateFacilityUserGroupSuccess,
    (state, action) => {
      return facilityUserGroupsAdapter.updateOne(action.facilityUserGroup, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupsApiActions.deleteFacilityUserGroupSuccess,
    (state, action) => {
      return facilityUserGroupsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserGroupsApiActions.loadFacilityUserGroupsFail,
    FacilityUserGroupsApiActions.getFacilityUserGroupsFail,
    FacilityUserGroupsApiActions.fetchFacilityUserGroupFail,
    FacilityUserGroupsApiActions.createFacilityUserGroupFail,
    FacilityUserGroupsApiActions.updateFacilityUserGroupFail,
    FacilityUserGroupsApiActions.deleteFacilityUserGroupFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    FacilityUserGroupsWsActions.facilityUserGroupsCreated,
    FacilityUserGroupsWsActions.facilityUserGroupsPatched,
    (state, action) => {
      return facilityUserGroupsAdapter.upsertOne(action.facilityUserGroup, {
        ...state
      });
    }
  ),

  on(FacilityUserGroupsWsActions.facilityUserGroupsRemoved, (state, action) => {
    return facilityUserGroupsAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(FacilityUserGroupsGeneralActions.clearFacilityUserGroups, (state) => {
    return facilityUserGroupsAdapter.removeAll({
      ...state,
      loading: false,
      loaded: false,
      error: null
    });
  })
);

export const facilityUserGroupsFeature = createFeature({
  name: 'facilityUserGroups',
  reducer
});
