import { Create, Params } from '$/models';
import { IMedicationDestructionItem } from '$shared/services/medication-destruction-items.schema';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationDestructionItemFormActions = createActionGroup({
  source: 'Medication Destruction Item From',
  events: {
    'Load Residents': props<{ params?: Params }>(),
    'Load Medications': props<{ params?: Params }>(),
    'Load Inventory Items': props<{ params?: Params }>(),
    'Create Medication Destruction Items':
      props<Create<Partial<IMedicationDestructionItem>>>(),
    'Update Medication Destruction Item': props<{
      id: string;
      changes: Partial<IMedicationDestructionItem>;
      params?: Params;
    }>()
  }
});
