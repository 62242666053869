import { sortByFields } from '$/app/utils';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationInventoryItemsAdapter =
  createEntityAdapter<IMedicationInventoryItem>({
    sortComparer: sortByFields('itemNumber')
  });

export interface State extends EntityState<IMedicationInventoryItem> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  medicationInventoryItemsAdapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
  });
