import { MedicalEvent, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Medical Events API]';
const sourceWs = '[Medical Events WS]';

export const loadMedicalEventsSuccess = createAction(
  `${source} Load Medical Event Success`,
  props<{ medicalEvents: MedicalEvent[] }>()
);
export const loadMedicalEventsFail = createAction(
  `${source} Load Medical Events Fail`,
  props<{ error: Error }>()
);

export const createMedicalEventSuccess = createAction(
  `[MedicalEvents API] Create MedicalEvent Success`,
  props<{ medicalEvent: MedicalEvent }>()
);

export const createMedicalEventFail = createAction(
  `${source} Create MedicalEvent Fail`,
  props<{ error: Error }>()
);

export const updateMedicalEventSuccess = createAction(
  `${source} Update MedicalEvent Success`,
  props<{ medicalEvent: Update<MedicalEvent> }>()
);

export const updateMedicalEventFail = createAction(
  `${source} Update MedicalEvent Fail`,
  props<{ error: Error }>()
);

export const deleteMedicalEventSuccess = createAction(
  `${source} Delete MedicalEvent Success`,
  props<{ id: string }>()
);

export const deleteMedicalEventFail = createAction(
  `${source} Delete MedicalEvent Fail`,
  props<{ error: Error }>()
);

// [WS] Medical Events Created
export const medicalEventsCreated = createAction(
  `${sourceWs} Medical Events Created`,
  props<{ medicalEvent: MedicalEvent }>()
);
// [WS] Medical Events Patched
export const medicalEventsPatched = createAction(
  `${sourceWs} Medical Events Patched`,
  props<{ medicalEvent: MedicalEvent }>()
);
// [WS] Medical Events Removed
export const medicalEventsRemoved = createAction(
  `${sourceWs} Medical Events Removed`,
  props<{ id: string }>()
);
