import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'html', standalone: true })
export class AlcHtmlPipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);

  transform(value: string): SafeHtml {
    if (!value) {
      return value;
    }

    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
