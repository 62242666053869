import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DocumentTemplatePacketTemplatesApiActions,
  DocumentTemplatePacketTemplatesGeneralActions,
  DocumentTemplatePacketTemplatesWsActions
} from './document-template-packet-templates.actions';
import {
  documentTemplatePacketTemplatesAdapter,
  initialState
} from './document-template-packet-templates.state';

export const documentTemplatePacketTemplatesFeature = createFeature({
  name: 'documentTemplatePacketTemplates',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      DocumentTemplatePacketTemplatesApiActions.loadDocumentTemplatePacketTemplatesSuccess,
      (state, action) => {
        const {
          documentTemplatePacketTemplates: { data: records, ...pagination }
        } = action;

        return documentTemplatePacketTemplatesAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatePacketTemplatesApiActions.getDocumentTemplatePacketTemplatesSuccess,
      (state, action) => {
        const {
          documentTemplatePacketTemplates: { data: records, ...pagination }
        } = action;

        return documentTemplatePacketTemplatesAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatePacketTemplatesApiActions.fetchDocumentTemplatePacketTemplateSuccess,
      (state, action) => {
        return documentTemplatePacketTemplatesAdapter.upsertOne(
          action.documentTemplatePacketTemplate,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentTemplatePacketTemplatesApiActions.createDocumentTemplatePacketTemplateSuccess,
      (state, action) => {
        return documentTemplatePacketTemplatesAdapter.addOne(
          action.documentTemplatePacketTemplate,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentTemplatePacketTemplatesApiActions.updateDocumentTemplatePacketTemplateSuccess,
      (state, action) => {
        return documentTemplatePacketTemplatesAdapter.upsertOne(
          action.documentTemplatePacketTemplate,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentTemplatePacketTemplatesApiActions.deleteDocumentTemplatePacketTemplateSuccess,
      (state, action) =>
        documentTemplatePacketTemplatesAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      DocumentTemplatePacketTemplatesApiActions.loadDocumentTemplatePacketTemplatesFail,
      DocumentTemplatePacketTemplatesApiActions.getDocumentTemplatePacketTemplatesFail,
      DocumentTemplatePacketTemplatesApiActions.createDocumentTemplatePacketTemplateFail,
      DocumentTemplatePacketTemplatesApiActions.updateDocumentTemplatePacketTemplateFail,
      DocumentTemplatePacketTemplatesApiActions.deleteDocumentTemplatePacketTemplateFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      DocumentTemplatePacketTemplatesWsActions.documentTemplatePacketTemplateCreated,
      DocumentTemplatePacketTemplatesWsActions.documentTemplatePacketTemplatePatched,
      (state, action) => {
        return documentTemplatePacketTemplatesAdapter.upsertOne(
          action.documentTemplatePacketTemplate,
          state
        );
      }
    ),

    on(
      DocumentTemplatePacketTemplatesWsActions.documentTemplatePacketTemplateRemoved,
      (state, action) => {
        return documentTemplatePacketTemplatesAdapter.removeOne(
          action.id,
          state
        );
      }
    ),

    on(
      DocumentTemplatePacketTemplatesGeneralActions.addDocumentTemplatePacketTemplates,
      (state, action) => {
        return documentTemplatePacketTemplatesAdapter.upsertMany(
          action.documentTemplatePacketTemplates,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentTemplatePacketTemplatesGeneralActions.clearDocumentTemplatePacketTemplates,
      () => {
        return documentTemplatePacketTemplatesAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
