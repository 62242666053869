import {
  DateTimeFormatName,
  DateTimeFormats
} from '$shared/constants/datetime-formats';
import { toLuxon, ToLuxonParam } from '$shared/utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'utcDate', standalone: true })
export class AlcUtcDatePipe implements PipeTransform {
  transform(date: ToLuxonParam, format: DateTimeFormatName = 'DATE'): any {
    if (!date) {
      return date;
    }

    return toLuxon(date).setZone('UTC').toFormat(DateTimeFormats[format]);
  }
}
