import { createFeature, createReducer, on } from '@ngrx/store';
import {
  LibraryDocumentTemplatePacketsApiActions,
  LibraryDocumentTemplatePacketsGeneralActions,
  LibraryDocumentTemplatePacketsWsActions
} from './actions';
import {
  initialState,
  libraryDocumentTemplatePacketsAdapter
} from './library-document-template-packets.state';

export const libraryDocumentTemplatePacketsFeature = createFeature({
  name: 'libraryDocumentTemplatePackets',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      LibraryDocumentTemplatePacketsApiActions.loadLibraryDocumentTemplatePacketsSuccess,
      (state, action) => {
        const {
          libraryDocumentTemplatePackets: { data: records, ...pagination }
        } = action;

        return libraryDocumentTemplatePacketsAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryDocumentTemplatePacketsApiActions.getLibraryDocumentTemplatePacketsSuccess,
      (state, action) => {
        const {
          libraryDocumentTemplatePackets: { data: records, ...pagination }
        } = action;

        return libraryDocumentTemplatePacketsAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      LibraryDocumentTemplatePacketsApiActions.fetchLibraryDocumentTemplatePacketSuccess,
      (state, action) => {
        return libraryDocumentTemplatePacketsAdapter.upsertOne(
          action.libraryDocumentTemplatePacket,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketsApiActions.createLibraryDocumentTemplatePacketSuccess,
      (state, action) => {
        return libraryDocumentTemplatePacketsAdapter.addOne(
          action.libraryDocumentTemplatePacket,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketsApiActions.updateLibraryDocumentTemplatePacketSuccess,
      (state, action) => {
        return libraryDocumentTemplatePacketsAdapter.upsertOne(
          action.libraryDocumentTemplatePacket,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketsApiActions.deleteLibraryDocumentTemplatePacketSuccess,
      (state, action) =>
        libraryDocumentTemplatePacketsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      LibraryDocumentTemplatePacketsApiActions.loadLibraryDocumentTemplatePacketsFail,
      LibraryDocumentTemplatePacketsApiActions.getLibraryDocumentTemplatePacketsFail,
      LibraryDocumentTemplatePacketsApiActions.createLibraryDocumentTemplatePacketFail,
      LibraryDocumentTemplatePacketsApiActions.updateLibraryDocumentTemplatePacketFail,
      LibraryDocumentTemplatePacketsApiActions.deleteLibraryDocumentTemplatePacketFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      LibraryDocumentTemplatePacketsWsActions.libraryDocumentTemplatePacketCreated,
      LibraryDocumentTemplatePacketsWsActions.libraryDocumentTemplatePacketPatched,
      (state, action) => {
        return libraryDocumentTemplatePacketsAdapter.upsertOne(
          action.libraryDocumentTemplatePacket,
          state
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketsWsActions.libraryDocumentTemplatePacketRemoved,
      (state, action) => {
        return libraryDocumentTemplatePacketsAdapter.removeOne(
          action.id,
          state
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketsGeneralActions.addLibraryDocumentTemplatePackets,
      (state, action) => {
        return libraryDocumentTemplatePacketsAdapter.upsertMany(
          action.libraryDocumentTemplatePackets,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      LibraryDocumentTemplatePacketsGeneralActions.clearLibraryDocumentTemplatePackets,
      () => {
        return libraryDocumentTemplatePacketsAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
