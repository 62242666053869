import { FacilityTimeService } from '$/app/services';
import { extractTouchedChanges } from '$/app/utils';
import { AlcomyColor } from '$/models';
import { DateTimeFormats } from '$shared/constants/datetime-formats';
import { Logger } from '$shared/logger';
import { ToLuxonParam } from '$shared/utils';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  ValidationErrors
} from '@angular/forms';
import { DateTime } from 'luxon';
import { catchError, filter, map } from 'rxjs/operators';
import { AlcFormMetadataComponent } from '../../components/form-metadata/form-metadata.component';
import { AlcDateInputComponent } from '../date-input/date-input.component';
import { AlcTimeInputComponent } from '../time-input/time-input.component';

interface DateTimeFormValue {
  date: DateTime | null;
  time: string | null;
}

@Component({
  selector: 'alc-datetime-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AlcDateInputComponent,
    AlcTimeInputComponent,
    AlcFormMetadataComponent
  ],
  templateUrl: './datetime-input.component.html'
})
export class AlcDatetimeInputComponent implements ControlValueAccessor, OnInit {
  private readonly fb = inject(UntypedFormBuilder);
  private readonly ngControl = inject(NgControl);
  private readonly ft = inject(FacilityTimeService);
  private readonly destroyRef = inject(DestroyRef);

  protected datetime: FormGroup<{
    date: FormControl<DateTime | null>;
    time: FormControl<string | null>;
  }> = this.fb.group({
    date: [null],
    time: [null]
  });

  protected minDate: DateTime;
  protected maxDate: DateTime;
  protected minTime: string;
  protected maxTime: string;

  @Input() required? = false;
  @Input() color: AlcomyColor = 'dashboard-primary';
  @Input() dateLabel = 'Date';
  @Input() timeLabel = 'Time';
  @Input()
  get min(): DateTime {
    return this._min;
  }
  set min(value: ToLuxonParam) {
    value = this.ft.createDateTime(value);
    this.minDate = value;
    this._min = value;

    const date = this.datetime.get('date').value;
    this.setMinTime(date, this.min);
  }
  private _min: DateTime;

  @Input()
  get max(): DateTime {
    return this._max;
  }
  set max(value: ToLuxonParam) {
    value = this.ft.createDateTime(value);
    this.maxDate = value;
    this._max = value;

    const date = this.datetime.get('date').value;
    this.setMaxTime(date, this.max);
  }
  protected _max: DateTime;

  @Input() showClear: 'none' | 'date' | 'time' | 'date-and-time';

  @Input() autoSetTime:
    | 'off'
    | 'startOfDay'
    | 'endOfDay'
    | 'currentTime'
    | 'specificTime' = 'off';

  @Input() autoSetTimeValue: string;

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const date = this.datetime.get('date').value;
    this.setMaxTime(date, this.max);
    this.setMinTime(date, this.min);

    this.datetime.valueChanges
      .pipe(
        map((datetime: DateTimeFormValue) => {
          if (!datetime.date) {
            this.datetime.get('time').setValue(null, { emitEvent: false });
          }

          this._autoSetTime(datetime);
          this.datetime
            .get('time')
            .setValue(datetime.time, { emitEvent: false });

          const date: DateTime = datetime.date;
          if (!date) {
            return null;
          }

          let time = this.setMaxTime(date, this.max, datetime.time);
          time = this.setMinTime(date, this.min, time);

          return time ? this.ft.parseTime(time, date) : date.startOf('day');
        }),
        catchError((err, caught) => {
          Logger.error('AlcDatetimeInputComponent:registerOnChange', {
            err,
            caught
          });
          return caught;
        })
      )
      .subscribe((value) => this.onChange(value));

    extractTouchedChanges(this.datetime.get('date'))
      .pipe(
        filter((isTouched) => isTouched),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.onTouch();

        if (this.ngControl.invalid && this.ngControl.touched) {
          this.datetime.get('date').setErrors(this.ngControl.errors);
        }
      });
  }

  private get invalid(): boolean {
    return this.ngControl?.control?.invalid ?? this.datetime.invalid ?? false;
  }

  private get errors(): ValidationErrors | null {
    if (this.ngControl.invalid && this.ngControl.touched) {
      this.datetime.get('date').setErrors(this.ngControl.errors);
      return this.ngControl.errors;
    } else {
      return null;
    }
  }

  protected get showError(): boolean {
    if (!this.datetime) {
      return false;
    }

    const control = this.ngControl.control || this.datetime;

    const { dirty, touched } = control;

    if (touched) {
      this.datetime.markAllAsTouched();

      if (this.errors) {
        this.datetime.get('date').setErrors(this.errors);
      }
    }

    return this.invalid ? dirty || touched : false;
  }

  /*  Control Value Accessor Implementation */

  onChange: (value: any) => any = () => {};
  onTouch: () => any = () => {};

  writeValue(value: ToLuxonParam) {
    const dateTimeValue = value && this.ft.convertDateTime(value);
    const time = dateTimeValue?.toFormat('hh:mm a');
    const date = dateTimeValue?.startOf('day');

    this.datetime.get('date').setValue(date || null, { emitEvent: false });
    this.datetime.get('time').setValue(time || null, { emitEvent: false });
  }

  registerOnChange(fn: (value: DateTime) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.datetime.disable();
    } else {
      this.datetime.enable();
    }
  }

  private setMaxTime(date: ToLuxonParam, max: DateTime, currentTime?: string) {
    date = date && this.ft.createDateTime(date);
    currentTime = currentTime ?? this.datetime.get('time').value;

    if (!max || !date || date.startOf('day') < max.startOf('day')) {
      this.maxTime = null;
      return currentTime;
    }

    this.maxTime = max.toFormat(DateTimeFormats.TIME_12);

    if (date.startOf('day') > max.startOf('day')) {
      return currentTime;
    }

    if (currentTime) {
      const currentDateTime = this.ft.parseTime(currentTime, date);

      if (currentDateTime > max) {
        currentTime = this.maxTime;
        this.datetime.get('time').setValue(currentTime);
      }
    }

    return currentTime;
  }

  private setMinTime(date: ToLuxonParam, min: DateTime, currentTime?: string) {
    date = date && this.ft.createDateTime(date);
    currentTime = currentTime ?? this.datetime.get('time').value;

    if (!min || !date || date.startOf('day') > min.startOf('day')) {
      this.minTime = null;
      return currentTime;
    }

    this.minTime = min.toFormat(DateTimeFormats.TIME_12);

    if (date.startOf('day') < min.startOf('day')) {
      return currentTime;
    }

    if (currentTime) {
      const currentDateTime = this.ft.parseTime(currentTime, date);

      if (currentDateTime < min) {
        currentTime = this.minTime;
        this.datetime.get('time').setValue(currentTime);
      }
    }

    return currentTime;
  }

  private _autoSetTime(datetime: DateTimeFormValue) {
    if (datetime?.time) {
      return;
    }

    let time: string | null = null;

    switch (this.autoSetTime) {
      case 'specificTime':
        time = this.autoSetTimeValue;
        break;

      case 'currentTime':
        time = this.ft
          .createDateTime()
          .set({
            year: datetime?.date?.year,
            month: datetime?.date?.month,
            day: datetime?.date?.day
          })
          .toFormat('hh:mm a');
        break;

      case 'startOfDay':
        time = this.ft
          .createDateTime(datetime?.date)
          .startOf('day')
          .toFormat('hh:mm a');
        break;

      case 'endOfDay':
        time = this.ft
          .createDateTime(datetime?.date)
          .endOf('day')
          .toFormat('hh:mm a');
        break;
    }

    datetime.time = time;
  }
}
