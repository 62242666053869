import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { TemperatureLogsWsActions } from '$/app/store/temperature-logs';
import { TemperatureLog } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class TemperatureLogsApiService extends AbstractApiService<TemperatureLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('temperature-logs', feathers, store, {
      entityName: 'temperatureLog',
      created: TemperatureLogsWsActions.temperatureLogsCreated,
      patched: TemperatureLogsWsActions.temperatureLogsPatched,
      removed: TemperatureLogsWsActions.temperatureLogsRemoved
    });
  }
}
