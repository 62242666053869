import { ResidentGroupMember } from '$/models/data/entities/residents/resident-group-member.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ResidentGroupMembersGeneralActions = createActionGroup({
  source: 'Resident Group Members General',
  events: {
    'Clear Resident Group Members': emptyProps(),
    'Add Resident Group Members': props<{
      residentGroupMembers: ResidentGroupMember[];
    }>()
  }
});
