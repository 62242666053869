import { createFeature, createReducer, on } from '@ngrx/store';

import { PrnMarListFilterPageActions } from '../prn-mar-list-filter/prn-mar-list-filter.actions';
import { initialState } from './prn-mar-list-page.state';

export const uiPrnMarListPageFeature = createFeature({
  name: 'uiPrnMarListPage',
  reducer: createReducer(
    initialState,
    on(PrnMarListFilterPageActions.filterChanged, (state, action) => {
      return {
        ...state,
        filters: action.filters
      };
    })
  )
});
