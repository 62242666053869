import { IIncidentReport, IPaginatedResponse } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const IncidentReportsApiActions = createActionGroup({
  source: 'Incident Reports API',
  events: {
    // Success Actions
    'Load Incident Reports Success': props<{
      incidentReports: IPaginatedResponse<IIncidentReport>;
    }>(),
    'Get Incident Reports Success': props<{
      incidentReports: IPaginatedResponse<IIncidentReport>;
    }>(),
    'Fetch Incident Report Success': props<{
      incidentReport: IIncidentReport;
    }>(),
    'Create Incident Report Success': props<{
      incidentReport: IIncidentReport;
    }>(),
    'Update Incident Report Success': props<{
      incidentReport: IIncidentReport;
    }>(),
    'Delete Incident Report Success': props<{ id: string }>(),
    // Fail Actions
    'Load Incident Reports Fail': props<{ error: Error }>(),
    'Get Incident Reports Fail': props<{ error: Error }>(),
    'Fetch Incident Report Fail': props<{ error: Error }>(),
    'Create Incident Report Fail': props<{ error: Error }>(),
    'Update Incident Report Fail': props<{ error: Error }>(),
    'Delete Incident Report Fail': props<{ error: Error }>()
  }
});

export const IncidentReportsWsActions = createActionGroup({
  source: 'Incident Reports WS',
  events: {
    'Incident Report Created': props<{ incidentReport: IIncidentReport }>(),
    'Incident Report Patched': props<{ incidentReport: IIncidentReport }>(),
    'Incident Report Removed': props<{ id: string }>()
  }
});
