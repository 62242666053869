import { map, zip } from 'lodash';
import { Logger } from '../logger/logger';
import { MeasurementId } from '../lookups/measurements.lookup';
import { MedicationFormId } from '../lookups/medication-forms.lookup';
import { allAreAmountWithMeasurement } from '../types';
import { formatAmount } from './format-amount';
import { isDivisibleBySmallest } from './is-divisible-by-smallest';
import { parseAmountString } from './parse-amount-string';
import { AmountDisplayOptions } from './stringify-amount';
import { sumAmountSegments } from './sum-amount-segments';
import { LoggerPayload } from '../logger';

export function sumAmountStrings(
  amounts: string[],
  measurementId?: MeasurementId,
  formId?: MedicationFormId,
  options?: AmountDisplayOptions
): string | undefined {
  const loggerContext: LoggerPayload = {
    amounts,
    measurementId,
    formId,
    options
  };

  try {
    const groupedAmountSegments = amounts.map(parseAmountString);
    loggerContext.groupedAmountSegments = groupedAmountSegments;

    const allSameLength = groupedAmountSegments.every(
      (segmentedAmount) =>
        segmentedAmount.length === groupedAmountSegments[0].length
    );

    // TODO(2024-02-01): Make sure we validate that the user can't do this
    Logger.assert(
      allSameLength,
      'Combination drugs must have the same drug composition. i.e. The number of segments divided by the "/" or "-" must be the same.',
      loggerContext
    );

    const zippedSegments = zip(...groupedAmountSegments);
    loggerContext.zippedSegments = zippedSegments;

    // When there are more than 1 zipped segment, i.e. more than 1 array within the zipped array
    // it means we have a combination drug.
    const isCombinationDrug = zippedSegments.length > 1;
    loggerContext.isCombinationDrug = isCombinationDrug;

    const summedSegments = zippedSegments.map((segments) => {
      const segmentsValid = allAreAmountWithMeasurement(segments);

      Logger.assert(
        isCombinationDrug || segmentsValid,
        'For non combination drugs, each amount should be converted to a valid segment',
        loggerContext
      );

      if (!segmentsValid) {
        // FIX(2024-04-02): This seems wrong - what if there's more than one segment?  Also, we're not verifying that the first segment is one of these characters.
        return segments[0] as '/' | '-';
      }

      // QUESTION(2024-02-01): Should combination drugs be divisible by 4? Can't see a reason why not.
      const consistent = isDivisibleBySmallest(map(segments, 'amount'), 4);

      Logger.assert(
        !isCombinationDrug || consistent,
        'Combination drugs must be divisible by the smallest amount. i.e. 10/20mg and 20/40mg are valid, but 10/20mg and 20/45mg are not.',
        loggerContext
      );

      return sumAmountSegments(segments).toString();
    });

    return formatAmount(
      summedSegments.join(''),
      measurementId,
      formId,
      options
    );
  } catch (error) {
    Logger.warn('Error in sumAmountStrings', { ...loggerContext, error });

    return undefined;
  }
}
