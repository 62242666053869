import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DocumentPacketDocumentsApiActions,
  DocumentPacketDocumentsGeneralActions,
  DocumentPacketDocumentsWsActions
} from './document-packet-documents.actions';
import {
  documentPacketDocumentsAdapter,
  initialState
} from './document-packet-documents.state';

export const documentPacketDocumentsFeature = createFeature({
  name: 'documentPacketDocuments',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      DocumentPacketDocumentsApiActions.loadDocumentPacketDocumentsSuccess,
      (state, action) => {
        const {
          documentPacketDocuments: { data: records, ...pagination }
        } = action;

        return documentPacketDocumentsAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      DocumentPacketDocumentsApiActions.getDocumentPacketDocumentsSuccess,
      (state, action) => {
        const {
          documentPacketDocuments: { data: records, ...pagination }
        } = action;

        return documentPacketDocumentsAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      DocumentPacketDocumentsApiActions.fetchDocumentPacketDocumentSuccess,
      (state, action) => {
        return documentPacketDocumentsAdapter.upsertOne(
          action.documentPacketDocument,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentPacketDocumentsApiActions.createDocumentPacketDocumentSuccess,
      (state, action) => {
        return documentPacketDocumentsAdapter.addOne(
          action.documentPacketDocument,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentPacketDocumentsApiActions.updateDocumentPacketDocumentSuccess,
      (state, action) => {
        return documentPacketDocumentsAdapter.upsertOne(
          action.documentPacketDocument,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentPacketDocumentsApiActions.deleteDocumentPacketDocumentSuccess,
      (state, action) =>
        documentPacketDocumentsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      DocumentPacketDocumentsApiActions.loadDocumentPacketDocumentsFail,
      DocumentPacketDocumentsApiActions.getDocumentPacketDocumentsFail,
      DocumentPacketDocumentsApiActions.createDocumentPacketDocumentFail,
      DocumentPacketDocumentsApiActions.updateDocumentPacketDocumentFail,
      DocumentPacketDocumentsApiActions.deleteDocumentPacketDocumentFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      DocumentPacketDocumentsWsActions.documentPacketDocumentCreated,
      DocumentPacketDocumentsWsActions.documentPacketDocumentPatched,
      (state, action) => {
        return documentPacketDocumentsAdapter.upsertOne(
          action.documentPacketDocument,
          state
        );
      }
    ),

    on(
      DocumentPacketDocumentsWsActions.documentPacketDocumentRemoved,
      (state, action) => {
        return documentPacketDocumentsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      DocumentPacketDocumentsGeneralActions.addDocumentPacketDocuments,
      (state, action) => {
        return documentPacketDocumentsAdapter.upsertMany(
          action.documentPacketDocuments,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      DocumentPacketDocumentsGeneralActions.clearDocumentPacketDocuments,
      () => {
        return documentPacketDocumentsAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
