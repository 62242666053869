import { createFeature, createReducer, on } from '@ngrx/store';
import {
  MedicationDestructionItemsApiActions,
  MedicationDestructionItemsGeneralActions,
  MedicationDestructionItemsWsActions
} from './medication-destruction-items.actions';
import {
  initialState,
  medicationDestructionItemsAdapter
} from './medication-destruction-items.state';
import { getPaginatedData } from '$/app/utils';

export const medicationDestructionItemsFeature = createFeature({
  name: 'medicationDestructionItems',
  reducer: createReducer(
    initialState,

    on(
      MedicationDestructionItemsApiActions.loadMedicationDestructionItemsSuccess,
      (state, action) => {
        return medicationDestructionItemsAdapter.setAll(
          getPaginatedData(action.medicationDestructionItems),
          {
            ...state,
            loading: false,
            loaded: true
          }
        );
      }
    ),

    on(
      MedicationDestructionItemsApiActions.createMedicationDestructionItemSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: null
        };
      }
    ),

    on(
      MedicationDestructionItemsApiActions.updateMedicationDestructionItemSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: null
        };
      }
    ),

    on(
      MedicationDestructionItemsApiActions.deleteMedicationDestructionItemSuccess,
      (state, action) => {
        return medicationDestructionItemsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationDestructionItemsApiActions.loadMedicationDestructionItemsFail,
      MedicationDestructionItemsApiActions.createMedicationDestructionItemFail,
      MedicationDestructionItemsApiActions.updateMedicationDestructionItemFail,
      MedicationDestructionItemsApiActions.deleteMedicationDestructionItemFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicationDestructionItemsWsActions.medicationDestructionItemsCreated,
      MedicationDestructionItemsWsActions.medicationDestructionItemsPatched,
      (state, action) => {
        return medicationDestructionItemsAdapter.upsertOne(
          action.medicationDestructionItem,
          state
        );
      }
    ),

    on(
      MedicationDestructionItemsWsActions.medicationDestructionItemsRemoved,
      (state, action) => {
        return medicationDestructionItemsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      MedicationDestructionItemsGeneralActions.addMedicationDestructionItems,
      (state, action) => {
        return medicationDestructionItemsAdapter.upsertMany(
          action.medicationDestructionItems,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicationDestructionItemsGeneralActions.clearMedicationDestructionItems,
      (state) => {
        return medicationDestructionItemsAdapter.removeAll({
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
