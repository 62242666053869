import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { WeightLogsPageActions } from '$/app/pages/notebook/shared/logs/weight-log/weight-log.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { Action, createReducer, on } from '@ngrx/store';
import { WeightLogsApiActions, WeightLogsGeneralActions } from './actions';
import {
  State,
  WeightLogPageQueries,
  initialState,
  weightLogsAdapter
} from './weight-logs.state';
import { WeightLogFormPageActions } from '$/app/shared/pages/forms/log-forms/weight-log-form/weight-log-form.actions';

const reducer = createReducer(
  initialState,

  on(
    WeightLogsPageActions.loadWeightLogs,
    WeightLogsPageActions.getWeightLogs,
    WeightLogFormPageActions.createWeightLog,
    WeightLogFormPageActions.updateWeightLog,
    WeightLogsPageActions.deleteWeightLog,
    ResidentMedicationTasksPageActions.deleteWeightLog,
    MissedMedicationsPageActions.deleteWeightLog,
    RoutineMarListPageActions.deleteWeightLog,
    RoutineMarDetailPageActions.deleteWeightLog,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(WeightLogsApiActions.loadWeightLogsSuccess, (state, action) => {
    const queryType = action.metadata?.pageQueryType;
    const pagination = getPaginationData<WeightLogPageQueries>(
      state,
      action.weightLogs,
      queryType
    );
    const data = getRecords(action.weightLogs);

    return weightLogsAdapter.setAll(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(WeightLogsApiActions.getWeightLogsSuccess, (state, action) => {
    const queryType = action.metadata?.pageQueryType;
    const pagination = getPaginationData<WeightLogPageQueries>(
      state,
      action.weightLogs,
      queryType
    );
    const data = getRecords(action.weightLogs);

    return weightLogsAdapter.upsertMany(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(WeightLogsApiActions.createWeightLogSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(WeightLogsApiActions.updateWeightLogSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(WeightLogsApiActions.deleteWeightLogSuccess, (state, action) =>
    weightLogsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      error: null
    })
  ),

  on(
    WeightLogsApiActions.loadWeightLogsFail,
    WeightLogsApiActions.getWeightLogsFail,
    WeightLogsApiActions.createWeightLogFail,
    WeightLogsApiActions.updateWeightLogFail,
    WeightLogsApiActions.deleteWeightLogFail,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error
    })
  ),

  on(
    WeightLogsApiActions.weightLogsCreated,
    WeightLogsApiActions.weightLogsPatched,
    (state, action) => {
      return weightLogsAdapter.upsertOne(action.weightLog, state);
    }
  ),

  on(WeightLogsApiActions.weightLogsRemoved, (state, action) => {
    return weightLogsAdapter.removeOne(action.id, state);
  }),

  on(WeightLogsGeneralActions.addWeightLogs, (state, action) => {
    return weightLogsAdapter.upsertMany(action.weightLogs, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(WeightLogsGeneralActions.clearWeightLogs, () => {
    return weightLogsAdapter.removeAll({
      ...initialState
    });
  })
);

export function weightLogsReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
