import { Paginated, Update } from '$/models';
import { ResidentGroupMember } from '$/models/data/entities/residents/resident-group-member.model';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentGroupMembersApiActions = createActionGroup({
  source: 'Resident Group Members API',
  events: {
    'Load Resident Group Members Success': props<{
      residentGroupMembers: ResidentGroupMember[];
    }>(),
    'Get Resident Group Members Success': props<{
      residentGroupMembers: Paginated<ResidentGroupMember>;
    }>(),
    'Fetch Resident Group Member Success': props<{
      residentGroupMember: ResidentGroupMember;
    }>(),
    'Create Resident Group Member Success': props<{
      residentGroupMember: MaybeArray<ResidentGroupMember>;
    }>(),
    'Update Resident Group Member Success': props<{
      residentGroupMember: Update<ResidentGroupMember>;
    }>(),
    'Delete Resident Group Member Success': props<{ id: string }>(),

    'Load Resident Group Members Fail': props<{ error: Error }>(),
    'Get Resident Group Members Fail': props<{ error: Error }>(),
    'Fetch Resident Group Member Fail': props<{ error: Error }>(),
    'Create Resident Group Member Fail': props<{ error: Error }>(),
    'Update Resident Group Member Fail': props<{ error: Error }>(),
    'Delete Resident Group Member Fail': props<{ error: Error }>()
  }
});

export const ResidentGroupMembersWsActions = createActionGroup({
  source: 'Resident Group Members WS',
  events: {
    'Resident Group Members Created': props<{
      residentGroupMember: ResidentGroupMember;
    }>(),
    'Resident Group Members Patched': props<{
      residentGroupMember: ResidentGroupMember;
    }>(),
    'Resident Group Members Removed': props<{ id: string }>()
  }
});
