<div class="flex h-full items-center">
  <mat-form-field class="form-input-stretch h-full">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [matDatepicker]="datePicker"
      (dateChange)="dateChange.emit($event)"
      [formControl]="date"
      [min]="min"
      [max]="max"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="datePicker"
      class="text-medium-dark"
    />
    <mat-datepicker #datePicker />
    @if (hint) {
      <mat-hint>
        {{ hint }}
      </mat-hint>
    }
    @if (shouldShowError) {
      <mat-error>
        @if (errors.required) {
          <span>Required</span>
        } @else if (errors.invalidDateRange) {
          <span>Invalid date range</span>
        } @else if (errors.minDateTime) {
          {{ errors.minDateTime }}
        } @else if (errors.matDatepickerMin) {
          <span>Later than minimum datetime</span>
        } @else if (errors.maxDateTime) {
          {{ errors.maxDateTime }}
        } @else if (errors.matDatepickerMax) {
          <span>Later than maximum datetime</span>
        } @else {
          <span>Invalid date</span>
        }
      </mat-error>
    }
  </mat-form-field>

  @if (showClear) {
    <ion-button
      class="mb-6 ml-2"
      fill="clear"
      [color]="'accent' | alcTheme"
      (click)="clearControl()"
    >
      <ion-icon
        slot="icon-only"
        src="assets/icon/material/cancel_outline.svg"
      />
    </ion-button>
  }
</div>
