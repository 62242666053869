import { FeathersService } from '$/app/services/feathers.service';
import { FacilityUsersWsActions } from '$/app/store/facility-users/actions';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class FacilityUsersApiService extends AbstractApiService<IFacilityUser> {
  constructor(store: Store, feathers: FeathersService) {
    super('facility-users', feathers, store, {
      entityName: 'facilityUser',
      created: FacilityUsersWsActions.facilityUsersCreated,
      patched: FacilityUsersWsActions.facilityUsersPatched,
      removed: FacilityUsersWsActions.facilityUsersRemoved
    });
  }
}
