import {
  PharmacyPhonesApiActions,
  PharmacyPhonesGeneralActions
} from '$/app/store/pharmacy-phones/actions';
import { PharmacyPhonesStoreModule } from '$/app/store/pharmacy-phones/pharmacy-phones-store.module';
import * as PharmacyPhonesSelectors from '$/app/store/pharmacy-phones/pharmacy-phones.selectors';
import * as PharmacyPhonesState from '$/app/store/pharmacy-phones/pharmacy-phones.state';

export {
  PharmacyPhonesApiActions,
  PharmacyPhonesGeneralActions,
  PharmacyPhonesSelectors,
  PharmacyPhonesState,
  PharmacyPhonesStoreModule
};
