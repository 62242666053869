import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DocumentTemplatesEffects } from './document-templates.effects';
import { documentTemplatesFeature } from './document-templates.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(documentTemplatesFeature),
    EffectsModule.forFeature([DocumentTemplatesEffects])
  ],
  providers: [DocumentTemplatesEffects]
})
export class DocumentTemplatesStoreModule {}
