import { Organization } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const OrganizationsApiActions = createActionGroup({
  source: 'Organizations API',
  events: {
    'Load Organizations Success': props<{ organizations: Organization[] }>(),
    'Fetch Organization Success': props<{ organization: Organization }>(),
    'Create Organization Success': props<{ organization: Organization }>(),
    'Update Organization Success': props<{ organization: Organization }>(),
    'Delete Organization Success': props<{ id: string }>(),

    'Load Organizations Fail': props<{ error: Error }>(),
    'Fetch Organization Fail': props<{ error: Error }>(),
    'Create Organization Fail': props<{ error: Error }>(),
    'Update Organization Fail': props<{ error: Error }>(),
    'Delete Organization Fail': props<{ error: Error }>()
  }
});

export const OrganizationsGeneralActions = createActionGroup({
  source: 'Organizations General',
  events: {
    'Clear Organizations': emptyProps(),
    'Add Organizations': props<{ organizations: Organization[] }>()
  }
});
