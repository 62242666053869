import { documentPacketsFeature } from './document-packets.reducer';
import { documentPacketsAdapter } from './document-packets.state';

export const {
  selectDocumentPacketsState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = documentPacketsFeature;

export const { selectAll, selectTotal } = documentPacketsAdapter.getSelectors(
  selectDocumentPacketsState
);
