import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parenthesize',
  standalone: true
})
export class AlcParenthesizePipe implements PipeTransform {
  transform(
    value: any,
    { prefix = '', suffix = '' }: { prefix?: string; suffix?: string } = {}
  ) {
    return value ? `(${prefix}${value}${suffix})` : value;
  }
}
