import { IPaginatedResponse } from '$/models';
import { Logger } from '$shared/logger';
import { isPaginated } from '$shared/utils';
import { isObjectLike } from 'lodash';

/**
 * Get a list of records from a paginated object or if the input
 * is an array, simply return the array. Log an error and return empty
 * array if input is not a paginated object or an array.
 */
export function getRecords<T = any>(
  data: IPaginatedResponse<T> | T[] | T
): T[] {
  if (Array.isArray(data)) {
    return data;
  } else if (isPaginated(data)) {
    return data.data;
  } else if (isObjectLike(data)) {
    return [data] as T[];
  } else {
    Logger.error(
      "Parameter 'data' should be of type Ojbect, Paginated or Array",
      {
        data
      }
    );
    return [];
  }
}
