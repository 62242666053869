import { AlcomyErrorCodes } from '$/config';
import { AlcomyError, AlcomyErrorMessage } from './alcomy-error.class';

/**
 * Thrown when trying to give a medication that does not have
 * enough inventory to satisfy the dose. Only applicable when
 * inventory tracking is turned on.
 */
export class InsufficientInventory extends AlcomyError {
  constructor(message: AlcomyErrorMessage, data?: any) {
    super(
      message,
      `${InsufficientInventory.name}`,
      AlcomyErrorCodes.INSUFFICIENT_INVENTORY,
      data
    );
  }
}
