import { Update } from '$/models';
import { ResidentInsurance } from '$/models/data/entities/residents/residentInsurance.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ResidentInsurancesApiActions = createActionGroup({
  source: 'Resident Insurances API',
  events: {
    'Create Resident Insurance Success': props<{
      residentInsurance: ResidentInsurance;
    }>(),
    'Update Resident Insurance Success': props<{
      residentInsurance: Update<ResidentInsurance>;
    }>(),
    'Delete Resident Insurance Success': props<{ id: string }>(),

    'Create Resident Insurance Fail': props<{ error: Error }>(),
    'Update Resident Insurance Fail': props<{ error: Error }>(),
    'Delete Resident Insurance Fail': props<{ error: Error }>()
  }
});

export const ResidentInsurancesWsActions = createActionGroup({
  source: 'Resident Insurances WS',
  events: {
    'Resident Insurances Created': props<{
      residentInsurance: ResidentInsurance;
    }>(),
    'Resident Insurances Patched': props<{
      residentInsurance: ResidentInsurance;
    }>(),
    'Resident Insurances Removed': props<{ id: string }>()
  }
});

export const ResidentInsurancesGeneralActions = createActionGroup({
  source: 'Resident Insurances General',
  events: {
    'Clear Resident Insurances': emptyProps(),
    'Add Resident Insurances': props<{
      residentInsurances: ResidentInsurance[];
    }>()
  }
});
