import { AlcAction, Symptom, Update } from '$/models';
import { createAction } from '@ngrx/store';

const source = '[Symptom Form Page]';

export const createSymptom = createAction(
  `${source} Create Symptom`,
  AlcAction<{ symptom: Symptom }>()
);

export const updateSymptom = createAction(
  `${source} Update Symptom`,
  AlcAction<Update<Symptom>>()
);

export const SymptomFormPageActions = {
  createSymptom,
  updateSymptom
};
