import { Table } from '$/models/data/base';

export class MedicationInventoryMovement extends Table {
  orgId?: string;
  facilityId?: string;
  medicationId?: string;
  movementTypeId?: string;
  quantity?: number;
  medicationInventoryId?: string;
  medicationInventoryItemId?: string;
  measurementId?: string;
  medicationBatchId?: string;
  entityType?: string;
  entityId?: string;
}
