import {
  State,
  residentContactsAdapter
} from '$/app/store/resident-contacts/resident-contacts.state';
import { ResidentsSelectors } from '$/app/store/residents/residents.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';

// Selector Helpers
export const getError = (state: State) => state.error;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;

// Resident Contacts State Selectors
export const selectResidentContactsState =
  createFeatureSelector<State>('residentContacts');

const entitySelectors = residentContactsAdapter.getSelectors(
  selectResidentContactsState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectResidentContactEntities = entitySelectors.selectEntities;
export const selectResidentContactIds = entitySelectors.selectIds;
export const selectResidentContactTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectResidentContactsState,
  getLoading
);
export const selectLoaded = createSelector(
  selectResidentContactsState,
  getLoaded
);
export const selectError = createSelector(
  selectResidentContactsState,
  getError
);

export const selectCurrentResidentContacts = createSelector(
  selectAll,
  ResidentsSelectors.selectResident,
  (residentContacts, resident) => {
    if (isEmpty(resident) || isEmpty(residentContacts)) {
      return [];
    }

    return residentContacts.filter((x) => x.residentId === resident.id);
  }
);
