import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const TemperatureLogsPageActions = createActionGroup({
  source: 'Resident Temperature Log Page',
  events: {
    'Load Temperature Logs': (params: Params) => ({ params }),
    'Get Temperature Logs': (params: Params) => ({ params }),
    'Delete Temperature Log': props<{ id: string; params?: Params }>()
  }
});
