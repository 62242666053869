import { FeathersService } from '$/app/services/feathers.service';
import { IDocument } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

export interface IForm extends IDocument {
  formInfo: Record<string, any>;
}

@Injectable({ providedIn: 'root' })
export class FormsApiService extends AbstractApiService<IForm> {
  constructor(feathers: FeathersService, store: Store) {
    super('forms', feathers, store, {
      entityName: 'form',
      created: null,
      patched: null,
      removed: null
    });
  }
}
