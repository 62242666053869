import { CdkVirtualForOf } from '@angular/cdk/scrolling';
import { NgForOf } from '@angular/common';
import { Directive, Host, Input, NgIterable } from '@angular/core';
import { get } from 'lodash';

// For this directive to work, it must have this very specific selector
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngForTrackByProperty]', standalone: true })
export class AlcTrackByPropertyDirective<
  T,
  U extends string & keyof T,
  V extends string & keyof T[U],
  W extends string & keyof T[U][V],
  X extends string & keyof T[U][V][W],
  Y extends string & keyof T[U][V][W][X],
  Z extends string & keyof T[U][V][W][X][Y]
> {
  @Input() ngForOf!: NgIterable<T>;
  @Input('ngForTrackByProperty') propertyPath!:
    | U
    | `${U}.${V}`
    | `${U}.${V}.${W}`
    | `${U}.${V}.${W}.${X}`
    | `${U}.${V}.${W}.${X}.${Y}`
    | `${U}.${V}.${W}.${X}.${Y}.${Z}`;

  constructor(@Host() ngFor: NgForOf<T>) {
    ngFor.ngForTrackBy = trackByProperty.bind(this);
  }
}

@Directive({
  // For this directive to work, it must have this very specific selector
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[cdkVirtualForTrackByProperty]',
  standalone: true
})
export class AlcCdkVirtualForTrackByPropertyDirective<
  T,
  U extends string & keyof T,
  V extends string & keyof T[U],
  W extends string & keyof T[U][V],
  X extends string & keyof T[U][V][W],
  Y extends string & keyof T[U][V][W][X],
  Z extends string & keyof T[U][V][W][X][Y]
> {
  @Input() cdkVirtualForOf!: NgIterable<T>;
  @Input('cdkVirtualForTrackByProperty') propertyPath!:
    | U
    | `${U}.${V}`
    | `${U}.${V}.${W}`
    | `${U}.${V}.${W}.${X}`
    | `${U}.${V}.${W}.${X}.${Y}`
    | `${U}.${V}.${W}.${X}.${Y}.${Z}`;

  constructor(@Host() ngFor: CdkVirtualForOf<T>) {
    ngFor.cdkVirtualForTrackBy = trackByProperty.bind(this);
  }
}

function trackByProperty(_index: number, item: any) {
  if (!item) {
    return null;
  }

  const value = get(item, this.propertyPath) ?? null;

  return value;
}
