import {
  ResidentMedicalProfessionalDetailActions,
  ResidentMedicalProfessionalFormActions,
  ResidentMedicalProfessionalsApiActions,
  ResidentMedicalProfessionalsGeneralActions,
  ResidentMedicalProfessionalsListActions,
  ResidentMedicalProfessionalsWsActions
} from '$/app/store/resident-medical-professionals/actions';
import { ResidentMedicalProfessionalsStoreModule } from '$/app/store/resident-medical-professionals/resident-medical-professionals-store.module';
import * as ResidentMedicalProfessionalsSelectors from '$/app/store/resident-medical-professionals/resident-medical-professionals.selectors';
import * as ResidentMedicalProfessionalsState from '$/app/store/resident-medical-professionals/resident-medical-professionals.state';

export {
  ResidentMedicalProfessionalDetailActions,
  ResidentMedicalProfessionalFormActions,
  ResidentMedicalProfessionalsApiActions,
  ResidentMedicalProfessionalsGeneralActions,
  ResidentMedicalProfessionalsListActions,
  ResidentMedicalProfessionalsSelectors,
  ResidentMedicalProfessionalsState,
  ResidentMedicalProfessionalsStoreModule,
  ResidentMedicalProfessionalsWsActions
};
