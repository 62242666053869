import { FeathersService } from '$/app/services/feathers.service';
import { WaterLogsApiActions } from '$/app/store/water-logs/actions';
import { ApiData } from '$/app/utils';
import { Paginated, Params, WaterLog } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WaterLogsApiService {
  private waterLogsService: Service<WaterLog>;

  constructor(store: Store, feathers: FeathersService) {
    this.waterLogsService = feathers.client.service('water-logs');

    this.waterLogsService.on('created', (waterLog) => {
      const responseData = new ApiData('waterLogs', waterLog);
      responseData.setPrimaryAction(WaterLogsApiActions.waterLogsCreated, {
        payloadKey: 'waterLog'
      });
      responseData.getActions().forEach((action) => {
        store.dispatch(action);
      });
    });

    this.waterLogsService.on('patched', (waterLog) => {
      store.dispatch(
        WaterLogsApiActions.waterLogsPatched({
          waterLog
        })
      );
    });

    this.waterLogsService.on('removed', (waterLog) => {
      store.dispatch(
        WaterLogsApiActions.waterLogsRemoved({
          id: waterLog.id
        })
      );
    });
  }

  getAll(params: Params) {
    const result = this.waterLogsService.find(params) as Promise<
      Paginated<WaterLog>
    >;

    return from(result);
  }

  create(waterLog: WaterLog): Observable<WaterLog> {
    return from(this.waterLogsService.create(waterLog));
  }

  patch(
    id: string,
    waterLog: Partial<WaterLog>,
    params: Params = { query: {} }
  ): Observable<WaterLog> {
    const promise = this.waterLogsService.patch(id, waterLog, params);

    return from(promise);
  }

  delete(id: string, params: Params = { query: {} }): Observable<WaterLog> {
    return from(this.waterLogsService.remove(id, params));
  }
}
