import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const BloodSugarLogsPageActions = createActionGroup({
  source: 'Blood Pressure Log Page',
  events: {
    'Load Blood Sugar Logs': (params: Params) => ({ params }),
    'Get Blood Sugar Logs': (params: Params) => ({ params }),
    'Delete Blood Sugar Log': props<{ id: string; params?: Params }>()
  }
});
