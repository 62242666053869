import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { OxygenLogsPageActions } from '$/app/pages/notebook/shared/logs/oxygen-log/oxygen-log.actions';
import { EffectHelpersService, OxygenLogsApiService } from '$/app/services';
import { OxygenLogFormPageActions } from '$/app/shared/pages/forms/log-forms/oxygen-log-form/oxygen-log-form.actions';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { IOxygenLog } from '$shared/services/oxygen-log';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { OxygenLogsApiActions } from './actions';

@Injectable()
export class OxygenLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly oxygenLogsService = inject(OxygenLogsApiService);

  loadOxygenLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OxygenLogsPageActions.loadOxygenLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.oxygenLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('oxygenLogs', logs);
            responseData.setPrimaryAction(
              OxygenLogsApiActions.loadOxygenLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getOxygenLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        OxygenLogsPageActions.getOxygenLogs,
        LogsPageActions.getOxygenLogs,
        GivePrnFormActions.getOxygenLogs
      ),
      switchMap((action) => {
        return this.oxygenLogsService.getAll(action.params).pipe(
          getAllPages(this.oxygenLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('oxygenLogs', logs);
            responseData.setPrimaryAction(
              OxygenLogsApiActions.getOxygenLogsSuccess
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createOxygenLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OxygenLogFormPageActions.createOxygenLog),
      exhaustMap((action) => {
        return this.oxygenLogsService.create(action.oxygenLog).pipe(
          tap((data: IOxygenLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((oxygenLog: IOxygenLog) =>
            OxygenLogsApiActions.createOxygenLogSuccess({
              oxygenLog
            })
          ),
          catchError((error) =>
            of(
              OxygenLogsApiActions.createOxygenLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateOxygenLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OxygenLogFormPageActions.updateOxygenLog),
      switchMap((action) => {
        return this.oxygenLogsService.patch(action.id, action.changes).pipe(
          tap((data: IOxygenLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((oxygenLog: IOxygenLog) => {
            return OxygenLogsApiActions.updateOxygenLogSuccess({
              oxygenLog
            });
          }),
          catchError((error) => {
            return of(
              OxygenLogsApiActions.updateOxygenLogFail({
                error
              })
            );
          })
        );
      })
    );
  });

  deleteOxygenLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        OxygenLogsPageActions.deleteOxygenLog,
        ResidentMedicationTasksPageActions.deleteOxygenLog,
        MissedMedicationsPageActions.deleteOxygenLog,
        RoutineMarDetailPageActions.deleteOxygenLog,
        RoutineMarListPageActions.deleteOxygenLog,
        GivePrnFormActions.deleteOxygenLog,
        CompleteCareTaskModalActions.deleteOxygenLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete oxygen log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.oxygenLogsService.delete(action.id, action.params);
        },
        onSuccess: (oxygenLog: IOxygenLog) =>
          OxygenLogsApiActions.deleteOxygenLogSuccess({
            id: oxygenLog.id
          }),
        onError: (error) => OxygenLogsApiActions.deleteOxygenLogFail({ error })
      })
    );
  });
}
