import { Static, TSchema, Type } from '@sinclair/typebox';
import { DateTime } from 'luxon';
import { AlcomyStringOptions } from './#types';
import { applyDefaults } from './apply-defaults';
import { Duration } from './types/duration';
import { Enum } from './types/enum';
import { setRequired } from './types/modifiers';
import { Nullable, OptionalNullable } from './types/nullable';
import { LooseObject, StrictObject } from './types/object';
import {
  Base64String,
  DateTimeString,
  Id,
  IntegerString,
  NumberString,
  OpaqueToken,
  PatternString,
  PhoneNumber,
  Url
} from './types/strings';
import { TableEntity } from './types/table-entity';
import { assertValid, validate } from './validation';

const TypeBoxExtensions = {
  String: (options?: AlcomyStringOptions) => Type.String(options),

  /** A UUID */
  Id,

  /** A date-time string, in ISO 8601 format */
  DateTimeString,

  DateTime: () => {
    return Type.Unsafe<DateTime>(
      Type.Object({ isLuxonDateTime: Type.Literal(true) })
    );
  },

  /** A URL, with optional protocol */
  Url,

  /** A phone number, with optional "+1" at the front, and optional dashes or periods */
  PhoneNumber,

  /** A string that can only contain base64 characters */
  Base64String,

  /** An integer, as a string */
  IntegerString,

  /** A number, as a string */
  NumberString,

  /** A string that matches the given pattern (RegExp) */
  PatternString,

  /** An opaque token, used for signingToken, signupToken, etc */
  OpaqueToken,

  /** A Luxon-like time-duration object */
  Duration,

  /** Defines a strict object, with no additional properties */
  StrictObject,

  /** Defines a loose object, with additional properties allowed */
  LooseObject,

  /**
   * An entity from a table in the database
   *
   * Provides all the standard fields for a table entity, but
   * allows for overriding their type/presence.
   */
  TableEntity,

  /**
   * TypeBox's built-in Enum is not useful, but this is.
   * A field that can only be one of the values in the enum.
   */
  Enum,

  /** A field that can be null */
  Nullable,

  /** A field that can be null or undefined */
  OptionalNullable,

  /** Make a set of fields the only required fields */
  setRequired,

  /**
   * Asserts that a value conforms to the given schema
   *
   * @returns The value, as the type inferred from the schema, if it is valid
   */
  assertValid,

  /**
   * Validates that a value conforms to the given schema
   *
   * Logs an error if the value is invalid.
   *
   * @returns The value, as the type inferred from the schema, if it is valid, or undefined if it is not
   */
  validate,

  /**
   * Applies default values to a value, if they are not already present
   */
  applyDefaults
} as const;

export const TypeBox = {
  ...Type,
  ...TypeBoxExtensions
} as Omit<typeof Type, 'Enum' | 'String' | 'Object' | 'Composite'> &
  typeof TypeBoxExtensions;

declare module './index' {
  namespace TypeBox {
    export type Schema = TSchema;
    export type Infer<T extends Schema> = Static<T>;
  }
}
