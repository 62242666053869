import { Paginated, SeizureLog } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Seizure Logs API]';
const sourceWS = '[Seizure Logs WS]';

// Load
export const loadSeizureLogsSuccess = createAction(
  `${source} Load Seizure Logs Success`,
  props<{ seizureLogs: Paginated<SeizureLog> }>()
);
export const loadSeizureLogsFail = createAction(
  `${source} Load Seizure Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getSeizureLogsSuccess = createAction(
  `${source} Get Seizure Logs Success`,
  props<{ seizureLogs: Paginated<SeizureLog> }>()
);
export const getSeizureLogsFail = createAction(
  `${source} Get Seizure Logs Fail`,
  props<{ error: Error }>()
);
// Create
export const createSeizureLogSuccess = createAction(
  `${source} Add Seizure Logs Success`,
  props<{ seizureLog: SeizureLog }>()
);
export const createSeizureLogFail = createAction(
  `${source} Add Seizure Logs Fail`,
  props<{ error: Error }>()
);
// Update
export const updateSeizureLogSuccess = createAction(
  `${source} Update Seizure Log Success`,
  props<{ seizureLog: SeizureLog }>()
);
export const updateSeizureLogFail = createAction(
  `${source} Update Seizure Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteSeizureLogSuccess = createAction(
  `${source} Delete Seizure Log Success`,
  props<{ id: string }>()
);
export const deleteSeizureLogFail = createAction(
  `${source} Delete Seizure Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Seizure Logs Created
export const seizureLogsCreated = createAction(
  `${sourceWS} Seizure Logs Created`,
  props<{ seizureLog: SeizureLog }>()
);
// [WS] Seizure Logs Patched
export const seizureLogsPatched = createAction(
  `${sourceWS} Seizure Logs Patched`,
  props<{ seizureLog: SeizureLog }>()
);
// [WS] Seizure Logs Removed
export const seizureLogsRemoved = createAction(
  `${sourceWS} Seizure Logs Removed`,
  props<{ id: string }>()
);
