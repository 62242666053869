import { State, roomsAdapter } from '$/app/store/rooms/rooms.state';
import { selectRouterState } from '$/app/store/router/router.selectors';
import { generateEntitySelectors } from '$/app/store/shared/selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter, isEmpty } from 'lodash';

const selectRoomsState = createFeatureSelector<State>('rooms');
const selectors = generateEntitySelectors(selectRoomsState, roomsAdapter);

export const RoomsSelectors = {
  ...selectors,

  selectFacilityRooms: createSelector(
    selectors.selectAll,
    selectRouterState,
    (rooms, routerState) => {
      return isEmpty(rooms) || isEmpty(routerState?.params)
        ? []
        : filter(rooms, { facilityId: routerState?.params?.facilityId });
    }
  )
};
