import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  FacilityPhonesApiActions,
  FacilityPhonesGeneralActions,
  FacilityPhonesWsActions
} from './actions';
import { facilityPhonesAdapter, initialState } from './facility-phones.state';

export const facilityPhonesFeature = createFeature({
  name: 'facilityPhones',
  reducer: createReducer(
    initialState,

    on(FacilityPhonesApiActions.loadFacilityPhonesSuccess, (state, action) => {
      return facilityPhonesAdapter.setAll(action.facilityPhones.data, {
        ...state,

        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilityPhonesApiActions.getFacilityPhonesSuccess, (state, action) => {
      return facilityPhonesAdapter.upsertMany(action.facilityPhones.data, {
        ...state,

        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilityPhonesApiActions.fetchFacilityPhoneSuccess, (state, action) => {
      return facilityPhonesAdapter.upsertOne(action.facilityPhone, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilityPhonesApiActions.createFacilityPhoneSuccess, (state, action) => {
      const facilityPhones = castArray(action.facilityPhone);

      return facilityPhonesAdapter.addMany(facilityPhones, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilityPhonesApiActions.updateFacilityPhoneSuccess, (state, action) => {
      return facilityPhonesAdapter.upsertOne(action.facilityPhone, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilityPhonesApiActions.deleteFacilityPhoneSuccess, (state, action) => {
      return facilityPhonesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      FacilityPhonesApiActions.loadFacilityPhonesFail,
      FacilityPhonesApiActions.getFacilityPhonesFail,
      FacilityPhonesApiActions.fetchFacilityPhoneFail,
      FacilityPhonesApiActions.createFacilityPhoneFail,
      FacilityPhonesApiActions.updateFacilityPhoneFail,
      FacilityPhonesApiActions.deleteFacilityPhoneFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      FacilityPhonesWsActions.facilityPhonesCreated,
      FacilityPhonesWsActions.facilityPhonesPatched,
      (state, action) => {
        return facilityPhonesAdapter.upsertOne(action.facilityPhone, {
          ...state
        });
      }
    ),

    on(FacilityPhonesWsActions.facilityPhonesRemoved, (state, action) => {
      return facilityPhonesAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(FacilityPhonesGeneralActions.addFacilityPhones, (state, action) => {
      return facilityPhonesAdapter.upsertMany(action.facilityPhones, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(FacilityPhonesGeneralActions.clearFacilityPhones, (state) => {
      return facilityPhonesAdapter.removeAll({
        ...state,

        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
