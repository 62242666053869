import { getDocumentName } from '$shared/documents';
import { Pipe, PipeTransform } from '@angular/core';

interface HasNames {
  code?: string;
  name?: string;
}

@Pipe({
  name: 'documentName',
  standalone: true
})
export class AlcDocumentNamePipe implements PipeTransform {
  transform(value: HasNames): string {
    return getDocumentName(value);
  }
}
