import { ofRoute } from '$/app/store/lib/ofRoute';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { FacilityUserGroupPageRouteActions } from '../staff/staff-routing.actions';
import { FacilityUserDetailPageActions } from './facility-user-detail/facility-user-detail.actions';
import { FacilityUserListPageActions } from './facility-user-list/facility-user-list.actions';

@Injectable()
export class StaffRoutingEffects {
  private readonly actions$ = inject(Actions);

  facilityUserList$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute('/staff/list'),
      map(() =>
        FacilityUserListPageActions.loadFacilityUsers({
          query: { $eager: { roles: { facilityRole: true } } }
        })
      )
    )
  );

  facilityUserDetailRouteEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute('/staff/:facilityUserId/profile'),
      mergeMap((action) => [
        FacilityUserDetailPageActions.fetchFacilityUser(
          action.payload.routerState.params.facilityUserId,
          {
            query: {
              $eager: {
                facilityUserPhones: true,
                facilityUserGroupMembers: { facilityUserGroup: true },
                roles: { facilityRole: true }
              }
            }
          }
        )
      ])
    )
  );

  staffGroupsRouteEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute('/staff/groups'),
      map(() =>
        FacilityUserGroupPageRouteActions.loadFacilityUserGroups({
          query: { $eager: { members: true } }
        })
      )
    )
  );
}
