import { FacilityUserPhone, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserPhoneDetailActions = createActionGroup({
  source: 'Facility User Phone Detail Page',
  events: {
    'Fetch Facility User Phone': (
      id: string,
      params: Params = { query: {} }
    ) => ({ id, params }),

    'Update Facility User Phone': props<Update<FacilityUserPhone>>(),

    'Delete Facility User Phone': props<{ id: string }>()
  }
});
