import { getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  BodyCheckObservationsApiActions,
  BodyCheckObservationsGeneralActions,
  BodyCheckObservationsWsActions
} from './body-check-observations.actions';
import {
  bodyCheckObservationsAdapter,
  initialState
} from './body-check-observations.state';

export const bodyCheckObservationsFeature = createFeature({
  name: 'bodyCheckObservations',
  reducer: createReducer(
    initialState,
    on(
      BodyCheckObservationsApiActions.loadBodyCheckObservationsSuccess,
      (state, action) => {
        const data = getRecords(action.bodyCheckObservations);

        return bodyCheckObservationsAdapter.setAll(data, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),
    on(
      BodyCheckObservationsApiActions.getBodyCheckObservationsSuccess,
      (state, action) => {
        const data = getRecords(action.bodyCheckObservations);

        return bodyCheckObservationsAdapter.upsertMany(data, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),
    on(
      BodyCheckObservationsApiActions.createBodyCheckObservationSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: null
        };
      }
    ),

    on(
      BodyCheckObservationsApiActions.updateBodyCheckObservationSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: null
        };
      }
    ),
    on(
      BodyCheckObservationsApiActions.deleteBodyCheckObservationSuccess,
      (state, action) =>
        bodyCheckObservationsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),
    on(
      BodyCheckObservationsApiActions.loadBodyCheckObservationsFail,
      BodyCheckObservationsApiActions.getBodyCheckObservationsFail,
      BodyCheckObservationsApiActions.createBodyCheckObservationFail,
      BodyCheckObservationsApiActions.updateBodyCheckObservationFail,
      BodyCheckObservationsApiActions.deleteBodyCheckObservationFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),
    on(
      BodyCheckObservationsWsActions.bodyCheckObservationsCreated,
      BodyCheckObservationsWsActions.bodyCheckObservationsPatched,
      (state, action) => {
        return bodyCheckObservationsAdapter.upsertOne(
          action.bodyCheckObservation,
          state
        );
      }
    ),

    on(
      BodyCheckObservationsWsActions.bodyCheckObservationsRemoved,
      (state, action) => {
        return bodyCheckObservationsAdapter.removeOne(action.id, state);
      }
    ),
    on(
      BodyCheckObservationsGeneralActions.addBodyCheckObservations,
      (state, action) => {
        return bodyCheckObservationsAdapter.upsertMany(
          action.bodyCheckObservations,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(BodyCheckObservationsGeneralActions.clearBodyCheckObservations, () => {
      return bodyCheckObservationsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
