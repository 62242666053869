import { Paginated, Symptom } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Symptoms API]';
const sourceWS = '[Symptoms WS]';

// Load
export const loadSymptomsSuccess = createAction(
  `${source} Load Symptoms Success`,
  props<{ symptoms: Paginated<Symptom> }>()
);
export const loadSymptomsFail = createAction(
  `${source} Load Symptoms Fail`,
  props<{ error: Error }>()
);
// Get
export const getSymptomsSuccess = createAction(
  `${source} Get Symptoms Success`,
  props<{ symptoms: Paginated<Symptom> }>()
);
export const getSymptomsFail = createAction(
  `${source} Get Symptoms Fail`,
  props<{ error: Error }>()
);
// Create
export const createSymptomSuccess = createAction(
  `${source} Add Symptoms Success`,
  props<{ symptom: Symptom }>()
);
export const createSymptomFail = createAction(
  `${source} Add Symptoms Fail`,
  props<{ error: Error }>()
);
// Update
export const updateSymptomSuccess = createAction(
  `${source} Update Symptom Success`,
  props<{ symptom: Symptom }>()
);
export const updateSymptomFail = createAction(
  `${source} Update Symptom Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteSymptomSuccess = createAction(
  `${source} Delete Symptom Success`,
  props<{ id: string }>()
);
export const deleteSymptomFail = createAction(
  `${source} Delete Symptoms Fail`,
  props<{ error: Error }>()
);

// [WS] Symptoms Created
export const symptomsCreated = createAction(
  `${sourceWS} Symptoms Created`,
  props<{ symptom: Symptom }>()
);
// [WS] Symptoms Patched
export const symptomsPatched = createAction(
  `${sourceWS} Symptoms Patched`,
  props<{ symptom: Symptom }>()
);
// [WS] Symptoms Removed
export const symptomsRemoved = createAction(
  `${sourceWS} Symptoms Removed`,
  props<{ id: string }>()
);
