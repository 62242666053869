import { FeathersService } from '$/app/services/feathers.service';
import { SeizureLogsApiActions } from '$/app/store/seizure-logs';
import { SeizureLog } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class SeizureLogsApiService extends AbstractApiService<SeizureLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('seizure-logs', feathers, store, {
      entityName: 'seizureLog',
      created: SeizureLogsApiActions.seizureLogsCreated,
      patched: SeizureLogsApiActions.seizureLogsPatched,
      removed: SeizureLogsApiActions.seizureLogsRemoved
    });
  }
}
