import { notificationsAdapter } from '$/app/store/notifications/notifications.state';
import { createSelector } from '@ngrx/store';
import { UserSelectors } from '../user';
import { notificationsFeature } from './notifications.reducer';

export const {
  selectNotificationsState,
  selectIds,
  selectEntities,
  selectLoading,
  selectLoaded,
  selectError
} = notificationsFeature;

const { selectAll } = notificationsAdapter.getSelectors(
  selectNotificationsState
);

export const selectNotifications = createSelector(
  selectAll,
  UserSelectors.selectFacilityUserId,
  UserSelectors.selectFacilityId,
  (notifications, facilityUserId, facilityId) => {
    return notifications.filter(
      (notification) =>
        notification.facilityUserId === facilityUserId &&
        notification.facilityId === facilityId
    );
  }
);

export const selectUnArchived = createSelector(
  selectNotifications,
  (notifications) => {
    return notifications.filter(
      (notification) => notification.status !== 'archived'
    );
  }
);

export const selectUnreadCount = createSelector(
  selectNotifications,
  (notifications): number => {
    if (!notifications?.length) {
      return 0;
    }

    return notifications.filter(
      (notification) => notification.status === 'unread'
    ).length;
  }
);
