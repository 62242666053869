import { ResidentsFilterVM } from '../residents-filter.vmodel';

export const initialFilters: ResidentsFilterVM = {
  status: 'active',
  residentGroupId: 'any'
};

export interface State {
  filters: ResidentsFilterVM;
}

export const initialState: State = {
  filters: initialFilters
};
