import { CarePlanItem, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const CareItemInfoPageActions = createActionGroup({
  source: 'Care Item Info Page',
  events: {
    'Fetch Care Plan Item': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),

    'Discontinue Care Plan': props<{
      id: string;
      changes: Partial<CarePlanItem>;
      params?: Params;
    }>(),

    'Delete Care Plan Item': props<{ id: string; params: Params }>()
  }
});
