import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  medicationInventoryMovementsAdapter,
  State
} from './medication-inventory-movements.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;

// MedicationInventoryMovements State Selector
export const selectMedicationInventoryMovementsState =
  createFeatureSelector<State>('medicationInventoryMovements');

const entitySelectors = medicationInventoryMovementsAdapter.getSelectors(
  selectMedicationInventoryMovementsState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectMedicationInventoryMovementsState,
  getLoading
);
export const selectLoaded = createSelector(
  selectMedicationInventoryMovementsState,
  getLoaded
);
export const selectError = createSelector(
  selectMedicationInventoryMovementsState,
  getError
);

// export const selectMedicationInventoryMovements = createSelector(
//   selectAll,
//   selectRouterState,
//   (medicationInventoryMovements, routerState) => {
//     if (routerState) {
//       return medicationInventoryMovements.filter(medicationInventoryMovement => {
//         return medicationInventoryMovement.residentId === routerState.params.residentId;
//       });
//     }
//     return [];
//   }
// );

// VIEW MODEL SELECTORS
