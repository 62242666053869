import { createFeature, createReducer, on } from '@ngrx/store';
import {
  OrganizationsApiActions,
  OrganizationsGeneralActions
} from './organizations.actions';
import { initialState, organizationsAdapter } from './organizations.state';

export const organizationsFeature = createFeature({
  name: 'organizations',
  reducer: createReducer(
    initialState,

    on(OrganizationsApiActions.loadOrganizationsSuccess, (state, action) => {
      return organizationsAdapter.setAll(action.organizations, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OrganizationsApiActions.createOrganizationSuccess, (state, action) => {
      return organizationsAdapter.addOne(action.organization, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OrganizationsApiActions.updateOrganizationSuccess, (state, action) => {
      return organizationsAdapter.upsertOne(action.organization, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OrganizationsApiActions.deleteOrganizationSuccess, (state, action) => {
      return organizationsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      OrganizationsApiActions.loadOrganizationsFail,
      OrganizationsApiActions.createOrganizationFail,
      OrganizationsApiActions.updateOrganizationFail,
      OrganizationsApiActions.deleteOrganizationFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(OrganizationsGeneralActions.addOrganizations, (state, action) => {
      return organizationsAdapter.upsertMany(action.organizations, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OrganizationsGeneralActions.clearOrganizations, (state) => {
      return organizationsAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
