import { FeathersService } from '$/app/services/feathers.service';
import { ShiftNotesApiActions } from '$/app/store/shift-notes/actions';
import { ApiData } from '$/app/utils/api-data';
import { Paginated, Params, ShiftNote } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShiftNotesApiService {
  private shiftNotesService: Service<ShiftNote>;

  constructor(store: Store, feathers: FeathersService) {
    this.shiftNotesService = feathers.client.service('shift-notes');

    this.shiftNotesService.on('created', (shiftNote) => {
      const responseData = new ApiData('shiftNotes', shiftNote);

      responseData.setPrimaryAction(ShiftNotesApiActions.shiftNotesCreated, {
        payloadKey: 'shiftNote'
      });

      responseData.getActions().forEach((action) => {
        store.dispatch(action);
      });

      store.dispatch(
        ShiftNotesApiActions.shiftNotesCreated({
          shiftNote
        })
      );
    });

    this.shiftNotesService.on('patched', (shiftNote) => {
      store.dispatch(
        ShiftNotesApiActions.shiftNotesPatched({
          shiftNote
        })
      );
    });

    this.shiftNotesService.on('removed', (shiftNote) => {
      store.dispatch(
        ShiftNotesApiActions.shiftNotesRemoved({
          id: shiftNote.id
        })
      );
    });
  }

  getAll(params: Params = { query: {} }) {
    const result = this.shiftNotesService.find(params) as Promise<
      Paginated<ShiftNote>
    >;

    return from(result);
  }

  create(
    shiftNote: ShiftNote,
    params: Params = { query: {} }
  ): Observable<ShiftNote> {
    return from(this.shiftNotesService.create(shiftNote, params));
  }

  patch(
    id: string,
    shiftNote: Partial<ShiftNote>,
    params: Params = { query: {} }
  ): Observable<ShiftNote> {
    const promise = this.shiftNotesService.patch(id, shiftNote, params);

    return from(promise);
  }

  delete(id: string, params: Params = { query: {} }): Observable<ShiftNote> {
    return from(this.shiftNotesService.remove(id, params));
  }
}
