import { createSelector } from '@ngrx/store';
import { documentsFeature } from './documents.reducer';
import { documentsAdapter } from './documents.state';

export const {
  selectDocumentsState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = documentsFeature;

export const { selectAll, selectTotal } =
  documentsAdapter.getSelectors(selectDocumentsState);

export const selectAllCurrent = createSelector(selectAll, (documents) =>
  documents.filter((document) => document.isCurrentVersion)
);
