import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { WaterLogsPageActions } from '$/app/pages/notebook/shared/logs/water-log/water-log.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { Action, createReducer, on } from '@ngrx/store';
import { WaterLogsApiActions, WaterLogsGeneralActions } from './actions';
import {
  State,
  WaterLogPageQueries,
  initialState,
  waterLogsAdapter
} from './water-logs.state';
import { WaterLogFormPageActions } from '$/app/shared/pages/forms/log-forms/water-log-form/water-log-form.actions';

const reducer = createReducer(
  initialState,

  on(
    WaterLogsPageActions.loadWaterLogs,
    WaterLogsPageActions.getWaterLogs,
    WaterLogFormPageActions.createWaterLog,
    WaterLogFormPageActions.updateWaterLog,
    WaterLogsPageActions.deleteWaterLog,
    ResidentMedicationTasksPageActions.deleteWaterLog,
    MissedMedicationsPageActions.deleteWaterLog,
    RoutineMarListPageActions.deleteWaterLog,
    RoutineMarDetailPageActions.deleteWaterLog,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(WaterLogsApiActions.loadWaterLogsSuccess, (state, action) => {
    const queryType = action.metadata?.pageQueryType;
    const pagination = getPaginationData<WaterLogPageQueries>(
      state,
      action.waterLogs,
      queryType
    );
    const data = getRecords(action.waterLogs);

    return waterLogsAdapter.setAll(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(WaterLogsApiActions.getWaterLogsSuccess, (state, action) => {
    const queryType = action.metadata?.pageQueryType;
    const pagination = getPaginationData<WaterLogPageQueries>(
      state,
      action.waterLogs,
      queryType
    );
    const data = getRecords(action.waterLogs);

    return waterLogsAdapter.upsertMany(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(WaterLogsApiActions.createWaterLogSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(WaterLogsApiActions.updateWaterLogSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(WaterLogsApiActions.deleteWaterLogSuccess, (state, action) =>
    waterLogsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      error: null
    })
  ),

  on(
    WaterLogsApiActions.loadWaterLogsFail,
    WaterLogsApiActions.getWaterLogsFail,
    WaterLogsApiActions.createWaterLogFail,
    WaterLogsApiActions.updateWaterLogFail,
    WaterLogsApiActions.deleteWaterLogFail,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error
    })
  ),

  on(
    WaterLogsApiActions.waterLogsCreated,
    WaterLogsApiActions.waterLogsPatched,
    (state, action) => {
      return waterLogsAdapter.upsertOne(action.waterLog, state);
    }
  ),

  on(WaterLogsApiActions.waterLogsRemoved, (state, action) => {
    return waterLogsAdapter.removeOne(action.id, state);
  }),

  on(WaterLogsGeneralActions.addWaterLogs, (state, action) => {
    return waterLogsAdapter.upsertMany(action.waterLogs, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(WaterLogsGeneralActions.clearWaterLogs, () => {
    return waterLogsAdapter.removeAll({
      ...initialState
    });
  })
);

export function waterLogsReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
