import { AmountSegment } from '../types';
import { normalizeMeasurement } from './normalize-measurement';

/**
 * Takes a string amount and creates structured segments.
 *
 * ***
 * e.g.
 * '10mg/5ml' =>
 * [
 *    {amount: '10', measurement: 'mg'},
 *    '/',
 *    {amount: '5', measurement: 'ml'}
 * ]
 */
export function parseAmountString(amount: string): AmountSegment[] {
  if (!amount) {
    return [];
  }

  const segments = amount
    .toString()
    .toLowerCase()
    .replace(/\s*/g, '')
    .split(/(-|\/)/g);

  const convertedSegments = segments.map((a) => {
    if (a === '-' || a === '/') {
      return a;
    }

    const amountTuple = a.split(/([.\d]+\.*\d*)/g)?.filter(Boolean);

    const amount =
      amountTuple[0]?.charAt(0) !== '.' ? amountTuple[0] : `0${amountTuple[0]}`;

    const measurement = normalizeMeasurement(amountTuple[1]);

    return {
      amount,
      measurement
    };
  });

  while (typeof convertedSegments[convertedSegments.length - 1] === 'string') {
    convertedSegments.pop();
  }

  return convertedSegments;
}
