import { DateRangePreset } from '$/models';
import { Dictionary } from '$shared/types';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { DateTime, Info, Interval } from 'luxon';

/**
 * Returns a dictionary of preset date ranges for the given year
 */
export const getPresetDictionary = (
  year: number,
  ft: FacilityTime
): Dictionary<DateRangePreset> => {
  const now = ft.createDateTime();
  year = year || ft.createDate().year;
  const months = Info.months('long', { locale: 'en-US' });

  const presetsDictionary = {
    next7Days: {
      id: 'next7Days',
      name: 'Next 7 Days',
      range: getFutureDayRange(ft, 7)
    },

    tomorrow: {
      id: 'tomorrow',
      name: 'Tomorrow',
      range: getFutureDayRange(ft, 1)
    },

    today: {
      id: 'today',
      name: 'Today',
      range: getPastDayRange(ft, 1)
    },

    yesterday: {
      id: 'yesterday',
      name: 'Yesterday',
      range: getPastDayRange(ft, 1, 1)
    },

    thisMonth: {
      id: 'thisMonth',
      name: 'This Month',
      range: getMonthRange(ft, now.monthLong, now.year)
    },

    nextMonth: {
      id: 'nextMonth',
      name: 'Next Month',
      range: getMonthRange(
        ft,
        now.plus({ months: 1 }).monthLong,
        now.plus({ months: 1 }).year
      )
    },

    past7Days: {
      id: 'past7Days',
      name: 'Past 7 Days',
      range: getPastDayRange(ft, 7)
    },

    past30Days: {
      id: 'past30Days',
      name: 'Past 30 Days',
      range: getPastDayRange(ft, 30)
    },

    past60Days: {
      id: 'past60Days',
      name: 'Past 60 Days',
      range: getPastDayRange(ft, 60)
    },

    past90Days: {
      id: 'past90Days',
      name: 'Past 90 Days',
      range: getPastDayRange(ft, 90)
    },

    q1: {
      id: 'q1',
      name: 'Quarter 1',
      range: getQuarterRange(ft, 1, year)
    },

    q2: {
      id: 'q2',
      name: 'Quarter 2',
      range: getQuarterRange(ft, 2, year)
    },

    q3: {
      id: 'q3',
      name: 'Quarter 3',
      range: getQuarterRange(ft, 3, year)
    },

    q4: {
      id: 'q4',
      name: 'Quarter 4',
      range: getQuarterRange(ft, 4, year)
    }
  };

  months.forEach((month) => {
    const monthLowerCase = month.toLowerCase();

    presetsDictionary[monthLowerCase] = {
      id: `${monthLowerCase}`,
      name: `${month}`,
      range: getMonthRange(ft, month, year)
    };
  });

  return presetsDictionary;
};

function getQuarterRange(
  ft: FacilityTime,
  quarter: 1 | 2 | 3 | 4,
  year?: number
): Interval {
  year = year || ft.createDate().year;

  const startDate = DateTime.fromFormat(`${year} ${quarter}`, 'yyyy q', {
    zone: ft.timezone
  });
  const endDate = startDate.endOf('quarter');

  return Interval.fromDateTimes(startDate, endDate);
}

function getMonthRange(
  ft: FacilityTime,
  month: string,
  year?: number
): Interval {
  year = year || ft.createDate().year;

  const startDate = DateTime.fromFormat(`${year} ${month}`, 'yyyy MMMM', {
    zone: ft.timezone
  });
  const endDate = startDate.endOf('month');

  return Interval.fromDateTimes(startDate, endDate);
}

function getPastDayRange(
  ft: FacilityTime,
  numDays: number,
  startDaysBack: number = numDays - 1
): Interval {
  const now = ft.createDateTime();
  const start = now.minus({ days: startDaysBack }).startOf('day');
  const end = start.plus({ days: numDays - 1 }).endOf('day');

  return Interval.fromDateTimes(start, end);
}

function getFutureDayRange(ft: FacilityTime, numDays: number): Interval {
  const now = ft.createDateTime();
  const start = now.plus({ days: 1 }).startOf('day');
  const end = start.plus({ days: numDays - 1 }).endOf('day');

  return Interval.fromDateTimes(start, end);
}
