import { MedicationPendingOrderItemComponentActions } from '$/app/pages/medications/dashboard/medication-orders/medication-pending-order-item/medication-pending-order-item.actions';
import {
  EffectHelpersService,
  MedicationOrderItemsApiService
} from '$/app/services';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicationOrderItemsApiActions } from './medication-order-items.actions';

@Injectable()
export class MedicationOrderItemsEffects {
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly medicationOrderItemsService = inject(
    MedicationOrderItemsApiService
  );

  updateMedicationOrderItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationPendingOrderItemComponentActions.cancelMedicationOrderItem
      ),
      this.effectHelpers.apiRequest({
        description: 'Update Medication Order Item',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.medicationOrderItemsService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (medicationOrderItem) =>
          MedicationOrderItemsApiActions.updateMedicationOrderItemSuccess({
            medicationOrderItem: {
              id: medicationOrderItem.id,
              changes: medicationOrderItem
            }
          }),
        onError: (error) =>
          MedicationOrderItemsApiActions.updateMedicationOrderItemFail({
            error
          })
      })
    );
  });

  deleteOrderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MedicationPendingOrderItemComponentActions.deleteMedicationOrderItem
      ),
      this.effectHelpers.apiRequest({
        description: 'delete medication order item',
        onRequest: (action) =>
          this.medicationOrderItemsService.delete(action.id),
        onSuccess: (medicationOrderItem) =>
          MedicationOrderItemsApiActions.deleteMedicationOrderItemSuccess({
            id: medicationOrderItem.id
          }),
        onError: (error) =>
          MedicationOrderItemsApiActions.deleteMedicationOrderItemFail({
            error
          })
      })
    )
  );
}
