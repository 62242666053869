import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { IMedicationDestruction } from '$shared/services/medication-destructions.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MedicationDestructionsWsActions } from './medication-destructions.actions';

@Injectable({ providedIn: 'root' })
export class MedicationDestructionsService extends AbstractApiService<IMedicationDestruction> {
  constructor(feathers: FeathersService, store: Store) {
    super('medication-destructions', feathers, store, {
      entityName: 'medicationDestruction',
      created: MedicationDestructionsWsActions.medicationDestructionsCreated,
      patched: MedicationDestructionsWsActions.medicationDestructionsPatched,
      removed: MedicationDestructionsWsActions.medicationDestructionsRemoved
    });
  }
}
