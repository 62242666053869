import { Update } from '$/models';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { createActionGroup, props } from '@ngrx/store';

export const EditFacilityUserAddressFormActions = createActionGroup({
  source: 'Edit Facility User Address Form',
  events: {
    'Update Facility User': props<Update<IFacilityUser>>()
  }
});
