import * as Colors from '$/config/colors';
import { AlcomyColor } from '$/models';

/**
 * Returns the color hex for the theme's color
 */
export function getThemeColor(theme?: AlcomyColor) {
  switch (theme) {
    case 'dashboard-primary':
      return Colors.DASHBOARD_PRIMARY;
    case 'dashboard-accent':
      return Colors.DASHBOARD_ACCENT;
    case 'residents-primary':
      return Colors.RESIDENTS_PRIMARY;
    case 'residents-accent':
      return Colors.RESIDENTS_ACCENT;
    case 'staff-primary':
      return Colors.STAFF_PRIMARY;
    case 'staff-accent':
      return Colors.STAFF_ACCENT;
    case 'communication-primary':
      return Colors.COMMUNICATION_PRIMARY;
    case 'communication-accent':
      return Colors.COMMUNICATION_ACCENT;
    case 'organization-primary':
      return Colors.ORGANIZATION_PRIMARY;
    case 'organization-accent':
      return Colors.ORGANIZATION_ACCENT;
    default:
      const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
      const isHex = hexRegex.test(theme);
      const colorHex = isHex ? theme : '#03a9f4';

      return colorHex;
  }
}
