import { IPaginatedResponse, Paginated } from '$/models';

export function getPaginatedData<T = any>(
  paginatedEntity: IPaginatedResponse<T> | Paginated<T> | T[]
): T[] {
  if (Array.isArray(paginatedEntity)) {
    return paginatedEntity;
  }
  return paginatedEntity.data;
}
