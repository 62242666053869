import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ResidentReportConfigsEffects } from './resident-report-configs.effects';
import { residentReportConfigsFeature } from './resident-report-configs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(residentReportConfigsFeature),
    EffectsModule.forFeature([ResidentReportConfigsEffects])
  ],
  providers: [ResidentReportConfigsEffects]
})
export class ResidentReportConfigsStoreModule {}
