import { Paginated } from '$/models';
import { IMoodLog } from '$shared/services/mood-log';
import { createAction, props } from '@ngrx/store';

const source = '[Mood Logs API]';
const sourceWS = '[Mood Logs WS]';

// Load
export const loadMoodLogsSuccess = createAction(
  `${source} Load Mood Logs Success`,
  props<{
    moodLogs: Paginated<IMoodLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const loadMoodLogsFail = createAction(
  `${source} Load Mood Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getMoodLogsSuccess = createAction(
  `${source} Get Mood Logs Success`,
  props<{
    moodLogs: Paginated<IMoodLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const getMoodLogsFail = createAction(
  `${source} Get Mood Logs Fail`,
  props<{ error: Error }>()
);

export const updateMoodLogSuccess = createAction(
  `${source} Update Mood Log Success`,
  props<{ moodLog: IMoodLog }>()
);
export const updateMoodLogFail = createAction(
  `${source} Update Mood Log Fail`,
  props<{ error: Error }>()
);
// Create
export const createMoodLogSuccess = createAction(
  `${source} Create Mood Log Success`,
  props<{ moodLog: IMoodLog }>()
);
export const createMoodLogFail = createAction(
  `${source} Create Mood Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteMoodLogSuccess = createAction(
  `${source} Delete Mood Log Success`,
  props<{ id: string }>()
);
export const deleteMoodLogFail = createAction(
  `${source} Delete Mood Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Mood Logs Created
export const moodLogsCreated = createAction(
  `${sourceWS} Mood Logs Created`,
  props<{ moodLog: IMoodLog }>()
);
// [WS] Mood Logs Patched
export const moodLogsPatched = createAction(
  `${sourceWS} Mood Logs Patched`,
  props<{ moodLog: IMoodLog }>()
);
// [WS] Mood Logs Removed
export const moodLogsRemoved = createAction(
  `${sourceWS} Mood Logs Removed`,
  props<{ id: string }>()
);
