import { isString, last, reject } from 'lodash';
import { AmountSegment, AmountWithMeasurement } from '../types';
import { chainFlow } from '../utils';

/**
 * Returns the last measurement found in an array of amount segments.
 */
export function getMeasurementFromSegments(
  segments: AmountSegment[] | undefined
): string | null {
  segments = segments ?? [];

  return chainFlow(
    reject(segments, isString) as AmountWithMeasurement[],
    last,
    (segment) => segment?.measurement ?? null
  );
}
