import { DestructionMethodsListPageActions } from '$/app/pages/facilities/facility-detail/destruction-methods-list/destruction-methods-list.actions';
import { EffectHelpersService } from '$/app/services';
import { DestructionMethodModalActions } from '$/app/shared/pages/forms/destruction-method-form/destruction-method-form.actions';
import { IDestructionMethod } from '$shared/services/destruction-method.schema';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { DestructionMethodsApiActions } from './destruction-methods.actions';
import { DestructionMethodsApiService } from './destruction-methods.service';

@Injectable()
export class DestructionMethodsEffects {
  private readonly actions$ = inject(Actions);
  private readonly destructionMethodsService = inject(
    DestructionMethodsApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  createDestructionMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DestructionMethodModalActions.createDestructionMethod),
      exhaustMap((action) => {
        return this.destructionMethodsService
          .create(action.destructionMethod)
          .pipe(
            tap((data: IDestructionMethod) => {
              this.effectHelpers.dismissModal({
                data
              });
            }),
            map((destructionMethod: IDestructionMethod) =>
              DestructionMethodsApiActions.createDestructionMethodSuccess({
                destructionMethod
              })
            ),
            catchError((error) => {
              return of(
                DestructionMethodsApiActions.createDestructionMethodFail({
                  error
                })
              );
            })
          );
      })
    );
  });

  updateDestructionMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DestructionMethodModalActions.updateDestructionMethod),
      switchMap((action) => {
        return this.destructionMethodsService
          .patch(action.id, action.changes)
          .pipe(
            tap((data: IDestructionMethod) => {
              this.effectHelpers.dismissModal({
                data
              });
            }),
            map((destructionMethod: IDestructionMethod) =>
              DestructionMethodsApiActions.updateDestructionMethodSuccess({
                destructionMethod
              })
            ),
            catchError((error) => {
              return of(
                DestructionMethodsApiActions.updateDestructionMethodFail({
                  error
                })
              );
            })
          );
      })
    );
  });

  deleteDestructionMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DestructionMethodsListPageActions.deleteDestructionMethod),
      this.effectHelpers.apiRequest({
        description: 'deleting destructionMethod',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.destructionMethodsService.delete(action.id, action.params),
        onSuccess: (destructionMethod: IDestructionMethod) =>
          DestructionMethodsApiActions.createDestructionMethodSuccess({
            destructionMethod
          })
      })
    );
  });
}
