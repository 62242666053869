import { sortByFields } from '$/app/utils';
import { Prn } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const prnsAdapter = createEntityAdapter<Prn>({
  sortComparer: sortByFields('givenAt')
});

export interface State extends EntityState<Prn> {
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = prnsAdapter.getInitialState({
  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
