import { AlcAction, Params, Update } from '$/models';
import { IBehavior } from '$shared/services/behavior';
import { createActionGroup, props } from '@ngrx/store';

export const BehaviorsPageActions = createActionGroup({
  source: 'Resident Behaviors Page',
  events: {
    getBehaviors: (params: Params) => ({
      params
    }),
    getBehaviorMetrics: (query: {
      residentId: string;
      startDate: string;
      endDate: string;
    }) => ({
      params: {
        query: {
          $modify: {
            metrics: [query.residentId, query.startDate, query.endDate]
          }
        }
      }
    }),
    archiveBehavior: props<Update<IBehavior>>(),
    unarchiveBehavior: props<Update<IBehavior>>(),
    deleteBehavior: AlcAction<{ id: string }>()
  }
});
