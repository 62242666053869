import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  inject
} from '@angular/core';

@Directive({ selector: '[alcRemoveIonPadding]', standalone: true })
export class AlcRemoveIonPaddingDirective implements OnInit {
  private readonly element = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  @Input() alcRemoveIonPadding: 'start' | 'end' | '' = '';

  ngOnInit() {
    if (!this.alcRemoveIonPadding || this.alcRemoveIonPadding === 'start') {
      this.renderer.setStyle(
        this.element.nativeElement,
        '--padding-start',
        '0px',
        2
      );
    }
    if (!this.alcRemoveIonPadding || this.alcRemoveIonPadding === 'end') {
      this.renderer.setStyle(
        this.element.nativeElement,
        '--padding-end',
        '0px',
        2
      );
    }
  }
}
