import { Logger } from '$shared/logger';

export function sortByFields(...keys: string[]) {
  return function (a: any, b: any) {
    for (const key of keys) {
      if (a[key] !== undefined) {
        if (typeof a[key] === 'string') {
          return a[key].localeCompare(b[key], 'en', { sensitivity: 'base' });
        }

        if (typeof a[key] === 'number') {
          const compare = a[key] - b[key];
          return compare > 0 ? 1 : compare < 0 ? -1 : 0;
        }
      }
    }

    Logger.throw(
      'sortByFields: Some properties were undefined or their values were not a string or a number',
      { keys }
    );
  };
}
