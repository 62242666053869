import { BehaviorLogsSelectors } from '$/app/store/behavior-logs';
import { BloodPressureLogsSelectors } from '$/app/store/blood-pressure-logs';
import { BloodSugarLogsSelectors } from '$/app/store/blood-sugar-logs';
import { BodyCheckLogsSelectors } from '$/app/store/body-check-logs';
import { BmLogsSelectors } from '$/app/store/bowel-movement-logs';
import { FoodLogsSelectors } from '$/app/store/food-logs';
import { MoodLogsSelectors } from '$/app/store/mood-logs';
import { OxygenLogsSelectors } from '$/app/store/oxygen-logs';
import { PositionLogsSelectors } from '$/app/store/position-logs';
import { SeizureLogsSelectors } from '$/app/store/seizure-logs';
import { ShiftNotesSelectors } from '$/app/store/shift-notes';
import { ShowerLogsSelectors } from '$/app/store/shower-logs';
import { SleepLogsSelectors } from '$/app/store/sleep-logs';
import { TemperatureLogsSelectors } from '$/app/store/temperature-logs';
import { UrineLogsSelectors } from '$/app/store/urine-logs/urine-logs.selectors';
import { WaterLogsSelectors } from '$/app/store/water-logs';
import { WeightLogsSelectors } from '$/app/store/weight-logs';
import { createSelector } from '@ngrx/store';

export const selectCareTaskLogs = createSelector(
  BehaviorLogsSelectors.selectLogsByCareTask,
  BloodPressureLogsSelectors.selectLogsByCareTask,
  BloodSugarLogsSelectors.selectLogsByCareTask,
  BodyCheckLogsSelectors.selectLogsByCareTask,
  BmLogsSelectors.selectLogsByCareTask,
  FoodLogsSelectors.selectLogsByCareTask,
  MoodLogsSelectors.selectLogsByCareTask,
  OxygenLogsSelectors.selectLogsByCareTask,
  PositionLogsSelectors.selectLogsByCareTask,
  SeizureLogsSelectors.selectLogsByCareTask,
  ShowerLogsSelectors.selectLogsByCareTask,
  SleepLogsSelectors.selectLogsByCareTask,
  TemperatureLogsSelectors.selectLogsByCareTask,
  UrineLogsSelectors.selectLogsByCareTask,
  WaterLogsSelectors.selectLogsByCareTask,
  WeightLogsSelectors.selectLogsByCareTask,
  ShiftNotesSelectors.selectShiftNotesByCareTask,
  (
    behaviorLogs,
    bloodPressureLogs,
    bloodSugarLogs,
    bodyCheckLogs,
    bmLogs,
    foodLogs,
    moodLogs,
    oxygenLogs,
    positionLogs,
    seizureLogs,
    showerLogs,
    sleepLogs,
    temperatureLogs,
    urineLogs,
    waterLogs,
    weightLogs,
    shiftNotes
  ) => {
    const logs = {
      ...behaviorLogs,
      ...bloodPressureLogs,
      ...bloodSugarLogs,
      ...bodyCheckLogs,
      ...bmLogs,
      ...foodLogs,
      ...moodLogs,
      ...oxygenLogs,
      ...positionLogs,
      ...seizureLogs,
      ...showerLogs,
      ...sleepLogs,
      ...temperatureLogs,
      ...urineLogs,
      ...waterLogs,
      ...weightLogs,
      ...shiftNotes
    };

    return logs;
  }
);

export const DerivedCareTasksSelectors = {
  selectCareTaskLogs
};
