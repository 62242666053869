import { Update } from '$/models';
import { IBehaviorLog } from '$shared/services/behavior-log';
import { createActionGroup, props } from '@ngrx/store';

export const BehaviorLogModalActions = createActionGroup({
  source: 'Behavior Log Modal',
  events: {
    'Create Behavior Log': props<{ behaviorLog: IBehaviorLog }>(),
    'Update Behavior Log': props<Update<IBehaviorLog>>()
  }
});
