interface HasMedicationNames {
  brandName?: string;
  genericName?: string;
}

const collator = new Intl.Collator('en', { numeric: true });

export function sortMedicationsByName<T extends HasMedicationNames>(
  medications: T[]
): T[] {
  return medications.sort((a, b) =>
    collator.compare(a.brandName ?? a.genericName, b.brandName ?? b.genericName)
  );
}
