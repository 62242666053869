import { EditFacilityUserAddressFormActions } from '$/app/pages/staff/edit-facility-user-address-form/edit-facility-user-address-form.actions';
import { EditFacilityUserFormActions } from '$/app/pages/staff/edit-facility-user-basic-form/edit-facility-user-basic-form.actions';
import { FacilityUserDetailPageActions } from '$/app/pages/staff/facility-user-detail/facility-user-detail.actions';
import { FacilityUserListPageActions } from '$/app/pages/staff/facility-user-list/facility-user-list.actions';
import { NewFacilityUserModalActions } from '$/app/pages/staff/new-facility-user-modal/new-facility-user.modal.actions';
import {
  State,
  facilityUsersAdapter,
  initialState
} from '$/app/store/facility-users/facility-users.state';
import { getPaginatedData } from '$/app/utils';
import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  FacilityUsersApiActions,
  FacilityUsersGeneralActions,
  FacilityUsersWsActions
} from './actions';

const reducer = createReducer(
  initialState,

  on(
    FacilityUserListPageActions.loadFacilityUsers,
    FacilityUserDetailPageActions.fetchFacilityUser,
    NewFacilityUserModalActions.createFacilityUser,
    EditFacilityUserFormActions.updateFacilityUser,
    EditFacilityUserAddressFormActions.updateFacilityUser,
    FacilityUserDetailPageActions.updateFacilityUser,
    FacilityUserDetailPageActions.deleteFacilityUser,
    FacilityUserDetailPageActions.updateFacilityUserRoles,
    (state) => {
      return {
        ...state,
        loading: true
      };
    }
  ),

  on(FacilityUsersApiActions.loadFacilityUsersSuccess, (state, action) => {
    return facilityUsersAdapter.setAll(getPaginatedData(action.facilityUsers), {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(FacilityUsersApiActions.getFacilityUsersSuccess, (state, action) => {
    return facilityUsersAdapter.upsertMany(
      getPaginatedData(action.facilityUsers),
      {
        ...state,
        loading: false,
        loaded: true,
        error: null
      }
    );
  }),

  on(FacilityUsersApiActions.fetchFacilityUserSuccess, (state, action) => {
    return facilityUsersAdapter.upsertOne(action.facilityUser, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(FacilityUsersApiActions.createFacilityUserSuccess, (state, action) => {
    const facilityUsers = castArray(action.facilityUser);
    return facilityUsersAdapter.upsertMany(facilityUsers, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(FacilityUsersApiActions.updateFacilityUserSuccess, (state, action) => {
    return facilityUsersAdapter.upsertOne(action.facilityUser, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(FacilityUsersApiActions.deleteFacilityUserSuccess, (state, action) => {
    return facilityUsersAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    FacilityUsersApiActions.loadFacilityUsersFail,
    FacilityUsersApiActions.getFacilityUsersFail,
    FacilityUsersApiActions.fetchFacilityUserFail,
    FacilityUsersApiActions.createFacilityUserFail,
    FacilityUsersApiActions.updateFacilityUserFail,
    FacilityUsersApiActions.deleteFacilityUserFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    FacilityUsersWsActions.facilityUsersCreated,
    FacilityUsersWsActions.facilityUsersPatched,
    (state, action) => {
      return facilityUsersAdapter.upsertOne(action.facilityUser, state);
    }
  ),

  on(FacilityUsersWsActions.facilityUsersRemoved, (state, action) => {
    return facilityUsersAdapter.removeOne(action.id, state);
  }),

  on(FacilityUsersGeneralActions.clearFacilityUsers, (state) => {
    return facilityUsersAdapter.removeAll({
      ...state,
      loading: false,
      loaded: false,
      error: null
    });
  }),

  on(FacilityUsersGeneralActions.addFacilityUsers, (state, action) => {
    return facilityUsersAdapter.upsertMany(action.facilityUsers, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  })
);

export function facilityUsersReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
