import { createSelector } from '@ngrx/store';

import * as RouterSelectors from '$/app/store/router/router.selectors';
import { FacilityUserGroup, FacilityUserGroupMember } from '$/models';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import * as FacilityUserSelectors from '../facility-users/facility-users.selectors';
import { facilityUserGroupMembersFeature } from './facility-user-group-members.reducer';
import { facilityUserGroupMembersAdapter } from './facility-user-group-members.state';

export const {
  selectFacilityUserGroupMembersState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = facilityUserGroupMembersFeature;

export const { selectAll, selectTotal } =
  facilityUserGroupMembersAdapter.getSelectors(
    selectFacilityUserGroupMembersState
  );

export const selectFacilityUserGroupMembers = createSelector(
  selectAll,
  RouterSelectors.selectParam('facilityUserId'),
  (groupMembers, facUserId) => {
    if (!facUserId) {
      return [];
    }

    return groupMembers.filter((groupMember) => {
      return groupMember.facilityUserId === facUserId;
    });
  }
);

export const selectFacilityUsersFromFacilityUserGroupMembers = (
  facilityUserGroup: FacilityUserGroup
) => {
  return createSelector(
    selectAll,
    FacilityUserSelectors.selectAll,
    (facilityUserGroupMembers, facilityUsers): IFacilityUser[] => {
      facilityUserGroupMembers = facilityUserGroupMembers.filter(
        (x) => x.facilityUserGroupId === facilityUserGroup.id
      );
      return facilityUserGroupMembers.map((group) => {
        return facilityUsers.find((x) => x.id === group.facilityUserId);
      });
    }
  );
};

export const selectFacilityUserGroupMembersWithGroupId = (
  facilityUserGroupId: string
) => {
  return createSelector(
    selectAll,
    (facilityUserGroupMembers): FacilityUserGroupMember[] => {
      return facilityUserGroupMembers.filter(
        (x) => x.facilityUserGroupId === facilityUserGroupId
      );
    }
  );
};
