import { FeathersService } from '$/app/services/feathers.service';
import { SeizureLogSymptomsApiActions } from '$/app/store/seizure-log-symptoms';
import { SeizureLogSymptom } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class SeizureLogSymptomsApiService extends AbstractApiService<SeizureLogSymptom> {
  constructor(feathers: FeathersService, store: Store) {
    super('seizure-log-symptoms', feathers, store, {
      entityName: 'seizureLogSymptom',
      created: SeizureLogSymptomsApiActions.seizureLogSymptomsCreated,
      patched: SeizureLogSymptomsApiActions.seizureLogSymptomsPatched,
      removed: SeizureLogSymptomsApiActions.seizureLogSymptomsRemoved
    });
  }
}
