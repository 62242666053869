import { GivePrnFormData } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.vmodel';
import { FeathersService } from '$/app/services/feathers.service';
import { PrnsWsActions } from '$/app/store/prns/actions';
import { toUpdated } from '$/app/utils';
import { Paginated, Params, Prn, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PrnsApiService {
  private prnsService: Service<Prn>;

  constructor(feathers: FeathersService, store: Store) {
    this.prnsService = feathers.client.service('prns');

    this.prnsService.on('created', (prn) => {
      store.dispatch(PrnsWsActions.prnsCreated({ prn }));
    });

    this.prnsService.on('patched', (prn) => {
      store.dispatch(PrnsWsActions.prnsPatched({ prn }));
    });

    this.prnsService.on('removed', (prn) => {
      store.dispatch(PrnsWsActions.prnsRemoved({ id: prn.id }));
    });
  }

  getAll(params: Params = { query: {} }): Observable<Paginated<Prn>> {
    const promise = this.prnsService.find(params) as Promise<Paginated<Prn>>;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<Prn> {
    const promise = this.prnsService.get(id, params);
    return from(promise);
  }

  create(
    prn: MaybeArray<Prn> | GivePrnFormData,
    params: Params = { query: {} }
  ) {
    const promise = this.prnsService.create(prn, params) as Promise<Prn>;
    return from(promise);
  }

  patch(
    id: string,
    prn: Partial<Prn | GivePrnFormData>,
    params: Params = { query: {} }
  ): Observable<Update<Prn>> {
    const promise = this.prnsService.patch(id, prn, params);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string, params: Params = { query: {} }): Observable<Prn> {
    const promise = this.prnsService.remove(id, params);
    return from(promise);
  }
}
