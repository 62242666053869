import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { ShowerLog } from '$/models';
import { LogMetric } from '$/models/notebook/metrics.model';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { showerLogsFeature } from './shower-logs.reducer';
import { showerLogsAdapter } from './shower-logs.state';

export const {
  selectShowerLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = showerLogsFeature;

export const { selectAll, selectTotal } = showerLogsAdapter.getSelectors(
  selectShowerLogsState
);

export const selectPagination = (query) => {
  return createSelector(selectShowerLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentShowerLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (showerLogs, residentId): ShowerLog[] => {
    if (isEmpty(showerLogs) || !residentId) {
      return [];
    }

    return showerLogs.filter((showerLog) => {
      return showerLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByQuery = (query: Query<ShowerLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<ShowerLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<ShowerLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const computedMetrics = logs.reduce(
      (acc, log) => {
        acc.count += 1;

        acc.givenCount += log.wasGiven ? 1 : 0;
        acc.notGivenCount += !log.wasGiven ? 1 : 0;

        return acc;
      },
      {
        count: 0,
        givenCount: 0,
        notGivenCount: 0
      }
    );

    const metrics: LogMetric[] = [
      {
        type: 'value',
        label: 'Records',
        data: computedMetrics.count || 0
      },
      {
        type: 'value',
        label: 'Given',
        unit: '',
        data: computedMetrics.givenCount || 0
      },
      {
        type: 'value',
        label: 'Not Given',
        unit: '',
        data: computedMetrics.notGivenCount || 0
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
