import { Params } from '$/models';
import { createAction } from '@ngrx/store';

const source = '[PRN Response Form]';
export const loadFacilityUsers = createAction(
  `${source} Load Facility Users`,
  (params: Params = { query: {} }) => ({ params })
);

export const PrnResponseFormActions = {
  loadFacilityUsers
};
