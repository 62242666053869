import { AlcAction, Params, Prn, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Prn Mar Detail Page]';

export const fetchPrn = createAction(
  `${source} Fetch Prn`,
  (id: string, params: Params = { query: {} }) => ({ id, params })
);

export const updatePrn = createAction(
  `${source} Update PRN`,
  props<Update<Prn>>()
);

export const deletePrn = createAction(
  `${source} Delete PRN`,
  AlcAction<{ id: string }>()
);

export const PrnMarDetailPageActions = {
  fetchPrn,
  updatePrn,
  deletePrn
};
