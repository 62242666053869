import { FacilityTimeService } from '$/app/services/utils/facility-time.service';
import { inject } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateTime } from 'luxon';

export class AlcomyLuxonDateAdapter extends LuxonDateAdapter {
  private readonly ft = inject(FacilityTimeService);

  override createDate(year: number, month: number, date: number): DateTime {
    return this.ft.createDate(super.createDate(year, month, date));
  }

  override format(date: DateTime, displayFormat: string): string {
    return super.format(
      this.ft.convertDateTimeKeepingLocalTime(date),
      displayFormat
    );
  }

  override parse(value: any, parseFormat: string | string[]): DateTime | null {
    if (!value) {
      return null;
    }

    const parseFormats = Array.isArray(parseFormat)
      ? parseFormat
      : [parseFormat];

    try {
      return this.ft.convertDateTimeKeepingLocalTime(value, { parseFormats });
    } catch (e) {
      // ignore
    }

    return null;
  }

  override isDateInstance(obj: any): boolean {
    return DateTime.isDateTime(obj) || super.isDateInstance(obj);
  }
}
