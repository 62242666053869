import { FeathersService } from '$/app/services/feathers.service';
import { InstalledLibraryCollectionsWsActions } from '$/app/store/installed-library-collections/installed-library-collections.actions';
import { Params } from '$/models';
import { IInstalledLibraryCollection } from '$shared/services/installed-library-collection';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class InstalledLibraryCollectionsApiService extends AbstractApiService<
  IInstalledLibraryCollection | { collectionIds: string[]; level: string }
> {
  constructor(feathers: FeathersService, store: Store) {
    super('installed-library-collections', feathers, store, {
      entityName: 'installedLibraryCollection',
      created:
        InstalledLibraryCollectionsWsActions.installedLibraryCollectionCreated,
      patched:
        InstalledLibraryCollectionsWsActions.installedLibraryCollectionPatched,
      removed:
        InstalledLibraryCollectionsWsActions.installedLibraryCollectionRemoved
    });
  }

  installCollections(
    data: { level: string; collectionIds: string[] },
    params: Params = { query: {} }
  ): Observable<IInstalledLibraryCollection> {
    return from(
      this.service.create(data, params) as Promise<IInstalledLibraryCollection>
    );
  }
}
