export class IPaginationInfo {
  total?: number;
  skip?: number;
  limit?: number;
}

export interface IPaginatedResponse<T = any> extends IPaginationInfo {
  data: T[];
}

export class Paginated<T = any> extends IPaginationInfo {
  data: T[];

  constructor(paginated) {
    super();
    this.total = paginated?.total;
    this.limit = paginated?.limit;
    this.skip = paginated?.skip;
    this.data = paginated?.data;
  }

  isPaginated() {
    if (
      typeof this.total !== 'undefined' &&
      typeof this.limit !== 'undefined' &&
      typeof this.skip !== 'undefined' &&
      typeof this.data !== 'undefined'
    ) {
      return true;
    }

    return false;
  }
}
