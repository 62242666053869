import {
  $and,
  $exists,
  $gt,
  $gte,
  $in,
  $lt,
  $lte,
  $ne,
  $nin,
  $not,
  $options,
  $or,
  $regex,
  Query,
  createQueryTester
} from 'sift';

export type Options = Parameters<typeof createQueryTester>[1];

export function sifter<T = any>(query: Query<T>, options: Options = {}) {
  return createQueryTester(query, {
    ...options,
    operations: {
      ...options?.operations,
      $in,
      $nin,
      $ne,
      $exists,
      $not,
      $gt,
      $gte,
      $lt,
      $lte,
      $and,
      $or,
      $regex,
      $options
    }
  });
}
