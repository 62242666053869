import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const FacilityUserGroupPageRouteActions = createActionGroup({
  source: 'Facility User Groups Page Route',
  events: {
    'Load Facility User Groups': (params: Params = { query: {} }) => ({
      params
    })
  }
});
