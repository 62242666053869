import { Medication, MedicationGraph, Paginated } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

export const fetchMedicationDetailPageInfoSuccess = createAction(
  '[Medications API] Fetch Medication Detail Page Info Success',
  props<{ medication: MedicationGraph }>()
);

export const loadMedicationsSuccess = createAction(
  '[Medications API] Load Medications Success',
  props<{ medications: Paginated<Medication> }>()
);

export const loadMedicationsFail = createAction(
  '[Medications API] Load Medications Fail',
  props<{ error: Error }>()
);

export const getMedicationsSuccess = createAction(
  '[Medications API] Get Medications Success',
  props<{ medications: Paginated<MedicationGraph> }>()
);
export const getMedicationsFail = createAction(
  '[Medication API] Get Medications Fail',
  props<{ error: Error }>()
);

export const fetchMedicationSuccess = createAction(
  '[Medication API] Fetch Medication Success',
  props<{ medication: Medication }>()
);

export const fetchMedicationFail = createAction(
  '[Medication API] Fetch Medication Fail',
  props<{ error: Error }>()
);

export const createMedicationSuccess = createAction(
  '[Medications API] Create Medication Success',
  props<{ medication?: MaybeArray<Medication> }>()
);

export const createMedicationFail = createAction(
  '[Medications API] Create Medication Fail',
  props<{ error: Error }>()
);

export const updateMedicationSuccess = createAction(
  '[Medications API] Update Medication Success',
  props<{ medication: Medication }>()
);

export const updateMedicationFail = createAction(
  '[Medications API] Update Medication Fail',
  props<{ error: Error }>()
);

export const deleteMedicationSuccess = createAction(
  '[Medications API] Delete Medication Success',
  props<{ id: string }>()
);

export const deleteMedicationFail = createAction(
  '[Medications API] Delete Medication Fail',
  props<{ error: Error }>()
);

// [WS] Medications Created
export const medicationsCreated = createAction(
  '[Medications API WS] Medications Created',
  props<{ medication: Medication }>()
);
// [WS] Medications Patched
export const medicationsPatched = createAction(
  '[Medications API WS] Medications Patched',
  props<{ medication: Medication }>()
);
// [WS] Medications Removed
export const medicationsRemoved = createAction(
  '[Medications API WS] Medications Removed',
  props<{ id: string }>()
);

export const addMedication_DashboardListRoute = createAction(
  '[Medications API List Dashboard Effect] Add Medication',
  props<{ medication: MaybeArray<Medication> }>()
);
