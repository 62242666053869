import { MedicationSchedule } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearMedicationSchedules = createAction(
  '[MedicationSchedules] Clear MedicationSchedules'
);

export const addMedicationSchedules = createAction(
  'Add Medication Schedules',
  props<{ medicationSchedules: MedicationSchedule[] }>()
);
