import { Paginated } from '$/models';
import { IBodyCheckLog } from '$shared/services/body-check-log';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BodyCheckLogsApiActions = createActionGroup({
  source: 'Body Check Logs API',
  events: {
    'Load Body Check Logs Success': props<{
      bodyCheckLogs: Paginated<IBodyCheckLog>;
    }>(),
    'Get Body Check Logs Success': props<{
      bodyCheckLogs: Paginated<IBodyCheckLog>;
    }>(),
    'Create Body Check Log Success': props<{ bodyCheckLog: IBodyCheckLog }>(),
    'Update Body Check Log Success': props<{ bodyCheckLog: IBodyCheckLog }>(),
    'Delete Body Check Log Success': props<{ id: string }>(),

    'Load Body Check Logs Fail': props<{ error: Error }>(),
    'Get Body Check Logs Fail': props<{ error: Error }>(),
    'Create Body Check Log Fail': props<{ error: Error }>(),
    'Update Body Check Log Fail': props<{ error: Error }>(),
    'Delete Body Check Log Fail': props<{ error: Error }>()
  }
});

export const BodyCheckLogsWsActions = createActionGroup({
  source: 'Body Check Logs WS',
  events: {
    'Body Check Logs Created': props<{ bodyCheckLog: IBodyCheckLog }>(),
    'Body Check Logs Patched': props<{ bodyCheckLog: IBodyCheckLog }>(),
    'Body Check Logs Removed': props<{ id: string }>()
  }
});

export const BodyCheckLogsGeneralActions = createActionGroup({
  source: 'Body Check Logs General',
  events: {
    'Clear Body Check Logs': emptyProps(),
    'Add Body Check Logs': props<{
      bodyCheckLogs: IBodyCheckLog[];
    }>()
  }
});
