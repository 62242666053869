import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { ShowerLogsPageActions } from '$/app/pages/notebook/shared/logs/shower-log/shower-log.actions';
import { ShowerLogFormPageActions } from '$/app/shared/pages/forms/log-forms/shower-log-form/shower-log-form.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { ShowerLog } from '$/models/data/entities/logbook/shower-log.model';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { createFeature, createReducer, on } from '@ngrx/store';
import { ShowerLogsApiActions, ShowerLogsGeneralActions } from './actions';
import {
  ShowerLogPageQueries,
  initialState,
  showerLogsAdapter
} from './shower-logs.state';

export const showerLogsFeature = createFeature({
  name: 'showerLogs',
  reducer: createReducer(
    initialState,

    on(
      ShowerLogsPageActions.loadShowerLogs,
      ShowerLogsPageActions.getShowerLogs,
      ShowerLogFormPageActions.createShowerLog,
      ShowerLogFormPageActions.updateShowerLog,
      ShowerLogsPageActions.deleteShowerLog,
      ResidentMedicationTasksPageActions.deleteShowerLog,
      MissedMedicationsPageActions.deleteShowerLog,
      RoutineMarListPageActions.deleteShowerLog,
      RoutineMarDetailPageActions.deleteShowerLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(ShowerLogsApiActions.loadShowerLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<ShowerLogPageQueries>(
        state,
        action.showerLogs,
        queryType
      );
      const data = getRecords(action.showerLogs);

      return showerLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ShowerLogsApiActions.getShowerLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<ShowerLogPageQueries>(
        state,
        action.showerLogs,
        queryType
      );
      const data = getRecords(action.showerLogs);

      return showerLogsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ShowerLogsApiActions.createShowerLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(ShowerLogsApiActions.updateShowerLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(ShowerLogsApiActions.deleteShowerLogSuccess, (state, action) =>
      showerLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      ShowerLogsApiActions.loadShowerLogsFail,
      ShowerLogsApiActions.getShowerLogsFail,
      ShowerLogsApiActions.createShowerLogFail,
      ShowerLogsApiActions.updateShowerLogFail,
      ShowerLogsApiActions.deleteShowerLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      ShowerLogsApiActions.showerLogsCreated,
      ShowerLogsApiActions.showerLogsPatched,
      (state, action) => {
        const { facilityUser, ...showerLog } = action.showerLog as ShowerLog & {
          facilityUser?: IFacilityUser;
        };

        return showerLogsAdapter.upsertOne(showerLog, state);
      }
    ),

    on(ShowerLogsApiActions.showerLogsRemoved, (state, action) => {
      return showerLogsAdapter.removeOne(action.id, state);
    }),

    on(ShowerLogsGeneralActions.addShowerLogs, (state, action) => {
      return showerLogsAdapter.upsertMany(action.showerLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(ShowerLogsGeneralActions.clearShowerLogs, () => {
      return showerLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
