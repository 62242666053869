import { LoginDataUser } from '$shared/services/user';
import { createActionGroup, props } from '@ngrx/store';

export const UserApiActions = createActionGroup({
  source: 'User API',
  events: {
    'Fetch User Success': props<{ user: LoginDataUser }>(),
    'Fetch User Fail': props<{ error: Error }>(),

    'Update User Success': props<{ user: LoginDataUser }>(),
    'Update User Fail': props<{ error: Error }>(),

    'Save FCM Token': props<{ userId: string; fcmToken: string }>()
  }
});

export const UserGeneralActions = createActionGroup({
  source: 'User General',
  events: {
    'Add Users': props<{ users: LoginDataUser[] }>()
  }
});
