import { createFeature, createReducer, on } from '@ngrx/store';
import {
  FilesApiActions,
  FilesGeneralActions,
  FilesWsActions
} from './actions';
import { filesAdapter, initialState } from './files.state';

export const filesFeature = createFeature({
  name: 'files',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(FilesApiActions.loadFilesSuccess, (state, action) => {
      const {
        files: { data: records, ...pagination }
      } = action;

      return filesAdapter.setAll(records, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FilesApiActions.getFilesSuccess, (state, action) => {
      const {
        files: { data: records, ...pagination }
      } = action;

      return filesAdapter.upsertMany(records, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FilesApiActions.fetchFileSuccess, (state, action) => {
      return filesAdapter.upsertOne(action.file, {
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }),

    on(FilesApiActions.createFileSuccess, (state, action) => {
      return filesAdapter.addOne(action.file, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(FilesApiActions.updateFileSuccess, (state, action) => {
      return filesAdapter.upsertOne(action.file, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(FilesApiActions.deleteFileSuccess, (state, action) =>
      filesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      FilesApiActions.loadFilesFail,
      FilesApiActions.getFilesFail,
      FilesApiActions.createFileFail,
      FilesApiActions.updateFileFail,
      FilesApiActions.deleteFileFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      FilesWsActions.fileCreated,
      FilesWsActions.filePatched,
      (state, action) => {
        return filesAdapter.upsertOne(action.file, state);
      }
    ),

    on(FilesWsActions.fileRemoved, (state, action) => {
      return filesAdapter.removeOne(action.id, state);
    }),

    on(FilesGeneralActions.addFiles, (state, action) => {
      return filesAdapter.upsertMany(action.files, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(FilesGeneralActions.clearFiles, () => {
      return filesAdapter.removeAll({
        ...initialState
      });
    })
  )
});
