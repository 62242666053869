import { IInstalledLibraryCollection } from '$shared/services/installed-library-collection';
import { Paginated } from '$shared/types/pagination';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const InstalledLibraryCollectionsApiActions = createActionGroup({
  source: 'Installed Library Collections API',
  events: {
    // Success Actions
    'Load Installed Library Collections Success': props<{
      installedLibraryCollections: Paginated<IInstalledLibraryCollection>;
    }>(),
    'Get Installed Library Collections Success': props<{
      installedLibraryCollections: Paginated<IInstalledLibraryCollection>;
    }>(),
    'Fetch Installed Library Collection Success': props<{
      installedLibraryCollection: IInstalledLibraryCollection;
    }>(),
    'Create Installed Library Collection Success': props<{
      installedLibraryCollection: IInstalledLibraryCollection;
    }>(),
    'Update Installed Library Collection Success': props<{
      installedLibraryCollection: IInstalledLibraryCollection;
    }>(),
    'Delete Installed Library Collection Success': props<{ id: string }>(),
    // Fail Actions
    'Load Installed Library Collections Fail': props<{ error: Error }>(),
    'Get Installed Library Collections Fail': props<{ error: Error }>(),
    'Fetch Installed Library Collection Fail': props<{ error: Error }>(),
    'Create Installed Library Collection Fail': props<{ error: Error }>(),
    'Update Installed Library Collection Fail': props<{ error: Error }>(),
    'Delete Installed Library Collection Fail': props<{ error: Error }>()
  }
});

export const InstalledLibraryCollectionsWsActions = createActionGroup({
  source: 'Installed Library Collections WS',
  events: {
    'Installed Library Collection Created': props<{
      installedLibraryCollection: IInstalledLibraryCollection;
    }>(),
    'Installed Library Collection Patched': props<{
      installedLibraryCollection: IInstalledLibraryCollection;
    }>(),
    'Installed Library Collection Removed': props<{ id: string }>()
  }
});

export const InstalledLibraryCollectionsGeneralActions = createActionGroup({
  source: 'Installed Library Collections General',
  events: {
    'Clear Installed Library Collections': emptyProps(),
    'Add Installed Library Collections': props<{
      installedLibraryCollections: IInstalledLibraryCollection[];
    }>()
  }
});
