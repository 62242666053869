<div class="my-6">
  <p class="m-0 text-xs text-medium-dark">{{ subtitle }}</p>
  <div class="flex items-center gap-4">
    <h1 class="m-0 font-bold">{{ title }}</h1>
    <div
      class="rounded-full bg-dashboard-accent px-4 py-1 font-bold text-white"
    >
      {{ codename }}
    </div>
  </div>
</div>
<ng-content />
