import { MedicalProfessional } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearMedicalProfessionals = createAction(
  '[MedicalProfessionals] Clear Medical Professionals'
);

export const addMedicalProfessionals = createAction(
  'Add Medical Professionals',
  props<{ medicalProfessionals: MedicalProfessional[] }>()
);
