import { Directive, HostListener } from '@angular/core';

@Directive({ selector: '[alcClickStopPropagation]', standalone: true })
export class AlcClickStopPropagationDirective {
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
