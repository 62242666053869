import { ofRoute } from '$/app/store/lib/ofRoute';
import * as RouterSelectors from '$/app/store/router/router.selectors';
import { UserSelectors } from '$/app/store/user/user.selectors';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { AnnouncementDetailPageActions } from './announcement-detail/announcement-detail.actions';
import * as AnnouncementsListPageActions from './announcements-list/announcements-list.actions';

@Injectable()
export class CommunicationRoutingEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);

  announcementListRouteEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute('/communication/announcements'),
      withLatestFrom(this.store.select(UserSelectors.selectFacilityUserId)),
      map(() =>
        AnnouncementsListPageActions.getAnnouncementsPageInfo({
          query: {
            $modify: ['acknowledgementCount'],
            $eager: {
              acknowledgements: true,
              facilityUser: true,
              replies: true
            },
            $sort: {
              createdAt: -1
            }
          }
        })
      )
    )
  );

  announcementDetailsRouteEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute('/communication/announcements/:announcementId'),
      withLatestFrom(
        this.store.select(UserSelectors.selectFacilityUserId),
        this.store.select(RouterSelectors.selectParam('announcementId'))
      ),
      map(([, facilityUserId, announcementId]) =>
        AnnouncementDetailPageActions.fetchAnnouncementDetailPageInfo(
          announcementId,
          {
            query: {
              $modify: ['acknowledgementCount'],
              $eager: {
                acknowledgements: true,
                replies: {
                  facilityUser: true
                },
                facilityUser: true
              },
              $modifyEager: {
                acknowledgements: {
                  facilityUserId
                }
              }
            }
          }
        )
      )
    )
  );
}
