import { IPaginatedResponse } from '$/models';
import { EMPTY, expand, Observable } from 'rxjs';
import { getNextPage } from '../get-next-page';

/**
 * Returns the expand operator whose callback is configured to
 * make additional requests for remaining pages of data.
 */
export function getAllPages<T = any>(service, query: object) {
  return expand(
    (page: IPaginatedResponse<T>): Observable<IPaginatedResponse<T>> => {
      const nextPage = getNextPage(page);

      if (nextPage) {
        const params = {
          query: {
            ...query,
            ...nextPage
          }
        };

        return service.getAll(params);
      } else {
        return EMPTY;
      }
    }
  );
}
