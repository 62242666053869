import { createSelector } from '@ngrx/store';
import { groupBy, keyBy } from 'lodash';

import { documentTemplatePacketTemplatesFeature } from './document-template-packet-templates.reducer';
import { documentTemplatePacketTemplatesAdapter } from './document-template-packet-templates.state';

export const {
  selectDocumentTemplatePacketTemplatesState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = documentTemplatePacketTemplatesFeature;

export const { selectAll, selectTotal } =
  documentTemplatePacketTemplatesAdapter.getSelectors(
    selectDocumentTemplatePacketTemplatesState
  );

export const selectByPacketIdAndTemplateId = createSelector(
  selectAll,
  (packetTemplates) => {
    return keyBy(
      packetTemplates,
      (packetTemplate) =>
        `${packetTemplate.documentTemplatePacketId}-${packetTemplate.documentTemplateId}`
    );
  }
);

export const selectAllGroupedByTemplatePacketId = createSelector(
  selectAll,
  (templatePacketTemplates) => {
    return groupBy(templatePacketTemplates, 'documentTemplatePacketId');
  }
);

export const selectPacketCountByTemplateId = createSelector(
  selectAll,
  (packetTemplates) => {
    return packetTemplates.reduce(
      (acc: Record<string, number>, packetTemplate) => {
        const templateId = packetTemplate.documentTemplateId;
        acc[templateId] = acc[templateId] ? acc[templateId] + 1 : 1;

        return acc;
      },
      {}
    );
  }
);
