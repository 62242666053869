import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  medicalHistoriesAdapter,
  State
} from '$/app/store/medical-histories/medical-histories.state';

// Selector Helpers
export const getError = (state: State) => state.error;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;

// Medical Histories State Selector
export const selectMedicalHistoriesState =
  createFeatureSelector<State>('medicalHistories');

const entitySelectors = medicalHistoriesAdapter.getSelectors(
  selectMedicalHistoriesState
);

// Entity Selectors
export const selectAllMedicalHistories = entitySelectors.selectAll;
export const selectMedicalHistoryEntities = entitySelectors.selectEntities;
export const selectMedicalHistoryIds = entitySelectors.selectIds;
export const selectMedicalHistoryTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectMedicalHistoriesState,
  getLoading
);
export const selectLoaded = createSelector(
  selectMedicalHistoriesState,
  getLoaded
);
export const selectError = createSelector(
  selectMedicalHistoriesState,
  getError
);
