import { CareTask, Params, Update } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const OmitCareTaskModalActions = createActionGroup({
  source: 'Omit Care Task Form Page',
  events: {
    'Care Task Omitted': (
      id: string,
      { careTaskOmittedReasonId, omittedNotes }: CareTask,
      params: Params
    ): Update<CareTask> => ({
      id,
      changes: {
        currentState: 'omitted',
        careTaskOmittedReasonId,
        omittedNotes
      },
      params
    })
  }
});
