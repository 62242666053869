import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { WaterLog } from '$/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { Query } from 'sift';
import { State, waterLogsAdapter } from './water-logs.state';

// Selector Helpers
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;

// Water Logs State Selector
export const selectWaterLogsState = createFeatureSelector<State>('waterLogs');

const entitySelectors = waterLogsAdapter.getSelectors(selectWaterLogsState);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(selectWaterLogsState, getLoading);
export const selectLoaded = createSelector(selectWaterLogsState, getLoaded);
export const selectError = createSelector(selectWaterLogsState, getError);

export const selectPagination = (query) => {
  return createSelector(selectWaterLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentWaterLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (waterLogs, residentId): WaterLog[] => {
    if (isEmpty(waterLogs) || !residentId) {
      return [];
    }

    return waterLogs.filter((waterLog) => {
      return waterLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<WaterLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<WaterLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<WaterLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const amountPerDay = logs.reduce((days, log) => {
      const date = DateTime.fromISO(log.recordAt).toFormat('yyyy-MM-dd');
      days[date] = days[date] || 0;
      days[date] += log.amount;

      return days;
    }, {});

    const avgPerDay =
      Object.entries(amountPerDay).reduce(
        (sum, [, amount]) => sum + amount,
        0
      ) / Object.keys(amountPerDay).length;

    const roundedAvg = Math.round(avgPerDay);

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length || 0
      },
      {
        type: 'value',
        label: 'Avg. Daily',
        unit: 'oz',
        data: roundedAvg || 0
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
