import { map, zipObject } from 'lodash';

export function boolDictionaryToArray<
  T extends Record<string, boolean> = Record<string, boolean>
>(dict: T): Array<keyof T> {
  return Object.keys(dict).filter((key) => dict[key]) as Array<keyof T>;
}

export function arrayToBooleanDictionary<T extends string>(
  values: T[]
): Record<T, boolean> {
  return zipObject(
    values,
    map(values, () => true)
  ) as Record<T, boolean>;
}
