import { Paginated } from '$/models';
import { IFoodLog } from '$shared/services/food-log';
import { createAction, props } from '@ngrx/store';

const source = '[Food Logs API]';
const sourceWS = '[Food Logs WS]';

// Load
export const loadFoodLogsSuccess = createAction(
  `${source} Load Food Logs Success`,
  props<{
    foodLogs: Paginated<IFoodLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const loadFoodLogsFail = createAction(
  `${source} Load Food Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getFoodLogsSuccess = createAction(
  `${source} Get Food Logs Success`,
  props<{
    foodLogs: Paginated<IFoodLog>;
    metadata: { pageQueryType: string };
  }>()
);
export const getFoodLogsFail = createAction(
  `${source} Get Food Logs Fail`,
  props<{ error: Error }>()
);
// Create
export const createFoodLogSuccess = createAction(
  `${source} Create Food Log Success`,
  props<{ foodLog: IFoodLog }>()
);
export const createFoodLogFail = createAction(
  `${source} Create Food Log Fail`,
  props<{ error: Error }>()
);

export const updateFoodLogSuccess = createAction(
  `${source} Update Food Log Success`,
  props<{ foodLog: IFoodLog }>()
);
export const updateFoodLogFail = createAction(
  `${source} Update Food Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteFoodLogSuccess = createAction(
  `${source} Delete Food Log Success`,
  props<{ id: string }>()
);
export const deleteFoodLogFail = createAction(
  `${source} Delete Food Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Food Logs Created
export const foodLogsCreated = createAction(
  `${sourceWS} Food Logs Created`,
  props<{ foodLog: IFoodLog }>()
);
// [WS] Food Logs Patched
export const foodLogsPatched = createAction(
  `${sourceWS} Food Logs Patched`,
  props<{ foodLog: IFoodLog }>()
);
// [WS] Food Logs Removed
export const foodLogsRemoved = createAction(
  `${sourceWS} Food Logs Removed`,
  props<{ id: string }>()
);
