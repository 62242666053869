import { FacilityRoleDetailPageActions } from '$/app/pages/facilities/facility-detail/facility-role-detail/facility-role-detail.actions';
import { FacilityRolesPageActions } from '$/app/pages/facilities/facility-detail/facility-roles/facility-roles.actions';
import { Action, createReducer, on } from '@ngrx/store';

import { RolesApiActions, RolesGeneralActions } from './actions';
import { State, initialState, rolesAdapter } from './roles.state';

const reducer = createReducer(
  initialState,

  on(
    FacilityRolesPageActions.loadRoles,
    FacilityRolesPageActions.getRoles,
    FacilityRolesPageActions.deleteRole,
    FacilityRoleDetailPageActions.fetchRole,
    FacilityRoleDetailPageActions.updateRole,
    FacilityRoleDetailPageActions.createRolePermission,
    FacilityRoleDetailPageActions.deleteRolePermission,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(RolesApiActions.loadRolesSuccess, (state, action) => {
    return rolesAdapter.setAll(action.roles, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(RolesApiActions.getRolesSuccess, (state, action) => {
    return rolesAdapter.upsertMany(action.roles, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(RolesApiActions.fetchRoleSuccess, (state, action) => {
    return rolesAdapter.upsertOne(action.role, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(RolesApiActions.createRoleSuccess, (state) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: null
    };
  }),

  on(RolesApiActions.updateRoleSuccess, (state, action) => {
    return rolesAdapter.updateOne(action.role, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(RolesApiActions.deleteRoleSuccess, (state, action) => {
    return rolesAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    RolesApiActions.loadRolesFail,
    RolesApiActions.getRolesFail,
    RolesApiActions.fetchRoleFail,
    RolesApiActions.createRoleFail,
    RolesApiActions.updateRoleFail,
    RolesApiActions.deleteRoleFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    RolesApiActions.rolesCreated,
    RolesApiActions.rolesPatched,
    (state, action) => {
      return rolesAdapter.upsertOne(action.role, {
        ...state
      });
    }
  ),

  on(RolesApiActions.rolesRemoved, (state, action) => {
    return rolesAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(RolesGeneralActions.addRoles, (state, action) => {
    return rolesAdapter.upsertMany(action.roles, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(RolesGeneralActions.clearRoles, () => {
    return rolesAdapter.removeAll({
      ...initialState
    });
  })
);

export function rolesReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
