import { MedicalHistoryFormPageActions } from '$/app/pages/residents/resident-detail/medical-history-form/medical-history-form.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  MedicalHistoriesApiActions,
  MedicalHistoriesGeneralActions
} from './actions';
import {
  State,
  initialState,
  medicalHistoriesAdapter
} from './medical-histories.state';

const reducer = createReducer(
  initialState,

  on(
    MedicalHistoryFormPageActions.createMedicalHistory,
    MedicalHistoryFormPageActions.updateMedicalHistory,
    (state) => {
      return {
        ...state,
        loading: true
      };
    }
  ),

  on(
    MedicalHistoriesApiActions.createMedicalHistorySuccess,
    (state, action) => {
      const medicalHistories = castArray(action.medicalHistory);
      return medicalHistoriesAdapter.addMany(medicalHistories, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicalHistoriesApiActions.updateMedicalHistorySuccess,
    (state, action) => {
      return medicalHistoriesAdapter.updateOne(action.medicalHistory, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicalHistoriesApiActions.deleteMedicalHistorySuccess,
    (state, action) => {
      return medicalHistoriesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicalHistoriesApiActions.createMedicalHistoryFail,
    MedicalHistoriesApiActions.updateMedicalHistoryFail,
    MedicalHistoriesApiActions.deleteMedicalHistoryFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    MedicalHistoriesApiActions.medicalHistoriesCreated,
    MedicalHistoriesApiActions.medicalHistoriesPatched,
    (state, action) => {
      return medicalHistoriesAdapter.upsertOne(action.medicalHistory, state);
    }
  ),

  on(MedicalHistoriesApiActions.medicalHistoriesRemoved, (state, action) => {
    return medicalHistoriesAdapter.removeOne(action.id, state);
  }),

  on(MedicalHistoriesGeneralActions.addMedicalHistories, (state, action) => {
    return medicalHistoriesAdapter.upsertMany(action.medicalHistories, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(MedicalHistoriesGeneralActions.clearMedicalHistories, (state) => {
    return medicalHistoriesAdapter.removeAll({
      ...state,
      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function medicalHistoriesReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
