import { Paginated } from '$/models';
import { IBehavior } from '$shared/services/behavior';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BehaviorMetricsDict } from './behaviors.state';

export const BehaviorsApiActions = createActionGroup({
  source: 'Behaviors API',
  events: {
    'Load Behaviors Success': props<{
      behaviors: Paginated<IBehavior>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Behaviors Success': props<{
      behaviors: Paginated<IBehavior>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Behavior Metrics Success': props<{
      metrics: BehaviorMetricsDict;
    }>(),
    'Create Behavior Success': props<{ behavior: IBehavior }>(),
    'Update Behavior Success': props<{ behavior: IBehavior }>(),
    'Delete Behavior Success': props<{ id: string }>(),

    'Load Behaviors Fail': props<{ error: Error }>(),
    'Get Behaviors Fail': props<{ error: Error }>(),
    'Get Behavior Metrics Fail': props<{ error: Error }>(),
    'Create Behavior Fail': props<{ error: Error }>(),
    'Update Behavior Fail': props<{ error: Error }>(),
    'Delete Behavior Fail': props<{ error: Error }>()
  }
});

export const BehaviorsWsActions = createActionGroup({
  source: 'Behaviors WS',
  events: {
    'Behaviors Created': props<{ behavior: IBehavior }>(),
    'Behaviors Patched': props<{ behavior: IBehavior }>(),
    'Behaviors Removed': props<{ id: string }>()
  }
});

export const BehaviorsGeneralActions = createActionGroup({
  source: 'Behaviors General',
  events: {
    'Clear Behaviors': emptyProps(),
    'Add Behaviors': props<{
      behaviors: IBehavior[];
    }>()
  }
});
