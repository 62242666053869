import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { WaterLogsPageActions } from '$/app/pages/notebook/shared/logs/water-log/water-log.actions';
import { EffectHelpersService } from '$/app/services';
import { WaterLogsApiService } from '$/app/services/api/water-log.service';
import { WaterLogFormPageActions } from '$/app/shared/pages/forms/log-forms/water-log-form/water-log-form.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { WaterLog } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { WaterLogsApiActions } from './actions';

@Injectable()
export class WaterLogsEffects {
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly waterLogsService = inject(WaterLogsApiService);

  loadWaterLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaterLogsPageActions.loadWaterLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.waterLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('waterLogs', logs);
            responseData.setPrimaryAction(
              WaterLogsApiActions.loadWaterLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getWaterLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WaterLogsPageActions.getWaterLogs,
        LogsPageActions.getWaterLogs,
        GivePrnFormActions.getWaterLogs
      ),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.waterLogsService.getAll(action.params).pipe(
          getAllPages(this.waterLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('waterLogs', logs);
            responseData.setPrimaryAction(
              WaterLogsApiActions.getWaterLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createWaterLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaterLogFormPageActions.createWaterLog),
      exhaustMap((action) => {
        return this.waterLogsService.create(action.waterLog).pipe(
          tap((data: WaterLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((waterLog: WaterLog) =>
            WaterLogsApiActions.createWaterLogSuccess({
              waterLog
            })
          ),
          catchError((error) =>
            of(
              WaterLogsApiActions.createWaterLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateWaterLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaterLogFormPageActions.updateWaterLog),
      switchMap((action) => {
        return this.waterLogsService.patch(action.id, action.changes).pipe(
          tap((data: WaterLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((waterLog: WaterLog) => {
            return WaterLogsApiActions.updateWaterLogSuccess({
              waterLog
            });
          }),
          catchError((error) => {
            return of(WaterLogsApiActions.updateWaterLogFail({ error }));
          })
        );
      })
    );
  });

  deleteWaterLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WaterLogsPageActions.deleteWaterLog,
        ResidentMedicationTasksPageActions.deleteWaterLog,
        MissedMedicationsPageActions.deleteWaterLog,
        RoutineMarDetailPageActions.deleteWaterLog,
        RoutineMarListPageActions.deleteWaterLog,
        GivePrnFormActions.deleteWaterLog,
        CompleteCareTaskModalActions.deleteWaterLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete water log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.waterLogsService.delete(action.id, action.params);
        },
        onSuccess: (waterLog: WaterLog) =>
          WaterLogsApiActions.deleteWaterLogSuccess({
            id: waterLog.id
          }),
        onError: (error) => WaterLogsApiActions.deleteWaterLogFail({ error })
      })
    );
  });
}
