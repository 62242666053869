import { AlcomyTheme, alcomyThemes } from '$/models';
import { Memoize, isType } from '$shared/utils';
import { Location } from '@angular/common';
import { Injectable, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { find } from 'lodash';
import { filter, map, startWith, tap } from 'rxjs';

export interface Tab<Name extends string> {
  name: Name;
  component: any;
}

@Injectable({ providedIn: 'root' })
export class RouterUtilityService {
  private readonly router = inject(Router);
  private readonly location = inject(Location);

  history: string[] = [];

  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  /**
   * This is a wrapper around Angular's router.navigate method. It simply saves the current
   * url into the history array before navigating.
   */
  navigate(commands: any[], extras?: NavigationExtras) {
    this.router.navigate(commands, extras);
  }

  back(defaultUrl: string) {
    this.popUrl();
    this.popUrl() || defaultUrl;
    this.location.back();
  }

  getUrl() {
    return this.router.url;
  }

  getQueryParams() {
    return this.router.routerState.snapshot.root.queryParams;
  }

  getQueryParam(key: string) {
    return this.getQueryParams()[key];
  }

  getTheme() {
    return this.#memoizedTheme(this.router.url);
  }

  // url parameter is used in memoization, do not remove
  #memoizedTheme = Memoize((_url: string) => {
    let route = this.router.routerState.snapshot.root;
    let theme: AlcomyTheme = 'dashboard';
    while (route.firstChild) {
      route = route.firstChild;
      if (
        isType<AlcomyTheme>(route.data?.theme, (v) => alcomyThemes.includes(v))
      ) {
        theme = route.data.theme;
      }
    }
    return theme;
  });

  getPath(): string {
    return this.router.url.split('?')[0];
  }

  getLastPathSegment(): string {
    const path = this.getPath();
    const segments = path.split('/');
    return segments?.length ? segments[segments.length - 1] : '';
  }

  popUrl() {
    return this.history.pop();
  }

  getPreviousRoute(numberOfRoutes: number = 1): string {
    const url = this.router.url;
    const segments = url.split('/').slice(1);
    segments.splice(numberOfRoutes * -1, numberOfRoutes);
    return '/' + segments.join('/');
  }

  #getActiveTab<TabName extends string>(
    tabs: readonly Tab<TabName>[],
    targetComponent: any
  ): Tab<TabName> {
    let activeRoute = this.router.routerState.root;
    while (
      activeRoute.firstChild &&
      !(activeRoute?.component instanceof targetComponent)
    ) {
      activeRoute = activeRoute.firstChild;
    }

    return find(tabs, { component: activeRoute?.component });
  }

  watchRouteChanges<TabName extends string>(
    parentComponent: any,
    tabs: readonly Tab<TabName>[]
  ): Signal<Tab<TabName>> {
    const tab = this.#getActiveTab(tabs, parentComponent) ?? tabs[0];

    return toSignal(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.#getActiveTab(tabs, parentComponent)),
        startWith(tab),
        filter(Boolean)
      )
    );
  }

  routeChangesFrom(url: string) {
    let currentUrl = this.router.url;

    return this.router.events.pipe(
      filter(
        (event) =>
          event instanceof NavigationEnd &&
          currentUrl === url &&
          event.url !== url
      ),
      tap((event: NavigationEnd) => {
        currentUrl = event.url;
      })
    );
  }
}
