import { DashboardCarePageActions } from '$/app/pages/care/dashboard/dashboard-care.actions';
import { MissedCareTasksPageActions } from '$/app/pages/care/dashboard/missed-care-tasks/missed-care-tasks.actions';
import { CarListPageActions } from '$/app/pages/care/residents/car-list/car-list.actions';
import { CareTaskDetailPageActions } from '$/app/pages/care/residents/care-task-detail/care-task-detail.actions';
import { CareTaskInfoPageActions } from '$/app/pages/care/shared/components/care-task-info/care-task-into.actions';
import { DashboardOverviewPageActions } from '$/app/pages/dashboard/dashboard-overview/dashboard-overview.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  CareTasksApiActions,
  CareTasksGeneralActions,
  CareTasksWebsocketActions
} from './care-tasks.actions';
import { careTasksAdapter, initialState } from './care-tasks.state';

export const careTasksFeature = createFeature({
  name: 'careTasks',
  reducer: createReducer(
    initialState,

    on(
      CarListPageActions.loadCareTasks,
      MissedCareTasksPageActions.loadCareTasks,
      DashboardCarePageActions.getCareTasks,
      DashboardOverviewPageActions.getCareTasks,
      CareTaskDetailPageActions.fetchCareTask,
      CareTaskInfoPageActions.fetchCareTask,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(CareTasksApiActions.loadCareTasksSuccess, (state, action) => {
      return careTasksAdapter.setAll(action.careTasks.data, {
        ...state,

        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CareTasksApiActions.getCareTasksSuccess, (state, action) => {
      return careTasksAdapter.upsertMany(action.careTasks.data, {
        ...state,

        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CareTasksApiActions.fetchCareTaskSuccess, (state, action) => {
      return careTasksAdapter.upsertOne(action.careTask, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CareTasksApiActions.createCareTaskSuccess, (state, action) => {
      const careTasks = castArray(action.careTask);

      return careTasksAdapter.addMany(careTasks, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CareTasksApiActions.updateCareTaskSuccess, (state, action) => {
      return careTasksAdapter.updateOne(action.careTask, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(CareTasksApiActions.deleteCareTaskSuccess, (state, action) => {
      return careTasksAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      CareTasksApiActions.loadCareTasksFail,
      CareTasksApiActions.getCareTasksFail,
      CareTasksApiActions.fetchCareTaskFail,
      CareTasksApiActions.createCareTaskFail,
      CareTasksApiActions.updateCareTaskFail,
      CareTasksApiActions.deleteCareTaskFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      CareTasksWebsocketActions.careTasksCreated,
      CareTasksWebsocketActions.careTasksPatched,
      (state, action) => {
        if (action.careTask['changes']) {
          action.careTask = action.careTask['changes'];
        }
        return careTasksAdapter.upsertOne(action.careTask, {
          ...state
        });
      }
    ),

    on(
      CareTasksWebsocketActions.careTasksBatchCreated,
      CareTasksWebsocketActions.careTasksBatchPatched,
      (state, action) => {
        return careTasksAdapter.upsertMany(action.careTasks, {
          ...state
        });
      }
    ),

    on(CareTasksWebsocketActions.careTasksRemoved, (state, action) => {
      return careTasksAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(CareTasksWebsocketActions.careTasksBatchRemoved, (state, action) => {
      return careTasksAdapter.removeMany(action.ids, {
        ...state
      });
    }),

    on(CareTasksGeneralActions.addCareTasks, (state, action) => {
      return careTasksAdapter.upsertMany(action.careTasks, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(CareTasksGeneralActions.clearCareTasks, (state) => {
      return careTasksAdapter.removeAll({
        ...state,

        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
