import { Paginated, Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadMedicationInventoryItemsSuccess = createAction(
  '[MedicationInventoryItems API] Load MedicationInventoryItems Success',
  props<{ medicationInventoryItems: Paginated<IMedicationInventoryItem> }>()
);
export const loadMedicationInventoryItemsFail = createAction(
  '[MedicationInventoryItems API] Load MedicationInventoryItems Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getMedicationInventoryItemsSuccess = createAction(
  '[MedicationInventoryItems API] Get MedicationInventoryItems Success',
  props<{ medicationInventoryItems: Paginated<IMedicationInventoryItem> }>()
);
export const getMedicationInventoryItemsFail = createAction(
  '[MedicationInventoryItems API] Get MedicationInventoryItems Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchMedicationInventoryItemSuccess = createAction(
  '[MedicationInventoryItem API] Fetch MedicationInventoryItem Success',
  props<{ medicationInventoryItem: IMedicationInventoryItem }>()
);
export const fetchMedicationInventoryItemFail = createAction(
  '[MedicationInventoryItem API] Fetch MedicationInventoryItem Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createMedicationInventoryItemSuccess = createAction(
  '[MedicationInventoryItems API] Add MedicationInventoryItem Success',
  props<{
    medicationInventoryItem:
      | IMedicationInventoryItem
      | IMedicationInventoryItem[];
  }>()
);
export const createMedicationInventoryItemFail = createAction(
  '[MedicationInventoryItems API] Add MedicationInventoryItem Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateMedicationInventoryItemSuccess = createAction(
  '[MedicationInventoryItems API] Update MedicationInventoryItem Success',
  props<{ medicationInventoryItem: Update<IMedicationInventoryItem> }>()
);
export const updateMedicationInventoryItemFail = createAction(
  '[MedicationInventoryItems API] Update MedicationInventoryItem Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteMedicationInventoryItemSuccess = createAction(
  '[MedicationInventoryItems API] Delete MedicationInventoryItem Success',
  props<{ id: string }>()
);
export const deleteMedicationInventoryItemFail = createAction(
  '[MedicationInventoryItems API] Delete MedicationInventoryItem Fail',
  props<{ error: Error }>()
);

//
// 💥🔄 BACKEND WS ACTIONS
//

// [WS] MedicationInventoryItems Created
export const medicationInventoryItemsCreated = createAction(
  '[Medication Inventory Items API WS] MedicationInventoryItems Created',
  props<{ medicationInventoryItem: IMedicationInventoryItem }>()
);
// [WS] MedicationInventoryItems Patched
export const medicationInventoryItemsPatched = createAction(
  '[Medication Inventory Items API WS] MedicationInventoryItems Patched',
  props<{ medicationInventoryItem: IMedicationInventoryItem }>()
);
// [WS] MedicationInventoryItems Removed
export const medicationInventoryItemsRemoved = createAction(
  '[Medication Inventory Items API WS] MedicationInventoryItems Removed',
  props<{ id: string }>()
);

export const addInventoryItem_DashboardRoute = createAction(
  'Medication Inventory Items API Dashboard Route Effect] Add MedicationInventoryItem',
  props<{
    medicationInventoryItem:
      | IMedicationInventoryItem
      | IMedicationInventoryItem[];
  }>()
);
