import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const CommunityLibraryModalActions = createActionGroup({
  source: 'Community Library Modal',
  events: {
    'Load Library Collections': (params: Params) => ({ params }),
    'Load Installed Library Collections': (params: Params) => ({ params })
  }
});
