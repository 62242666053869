import { announcementRepliesFeature } from './announcement-replies.reducer';
import { announcementRepliesAdapter } from './announcement-replies.state';

export const {
  selectAnnouncementRepliesState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = announcementRepliesFeature;

export const { selectAll, selectTotal } =
  announcementRepliesAdapter.getSelectors(selectAnnouncementRepliesState);
