import { CarePlanItem, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const CarePlanItemFormActions = createActionGroup({
  source: 'Care Item Form',
  events: {
    'Create Care Plan Item': props<{
      carePlanItem: CarePlanItem;
      params?: Params;
    }>(),
    'Update Care Plan Item': props<{
      id: string;
      changes: Partial<CarePlanItem>;
      params?: Params;
    }>()
  }
});
