import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const EventsListActions = createActionGroup({
  source: 'Events List',
  events: {
    'Load Medical Events': (params: Params) => ({ params }),
    'Load Appointments': (params: Params) => ({ params }),
    'Load Incident Reports': (params: Params) => ({ params }),

    'Generate Incident Report Pdf': props<{ id: string; params: Params }>(),

    'Delete Medical Event': props<{ id: string }>(),
    'Delete Appointment': props<{ id: string }>(),
    'Delete Incident Report': props<{ id: string; params?: Params }>()
  }
});
