import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlcomyRouterState } from './custom-route-serializer';

const selectRouter =
  createFeatureSelector<RouterReducerState<AlcomyRouterState>>('router');

export const selectRouterState = createSelector(
  selectRouter,
  (router) => router && router.state
);

// Route Params Selectors
export const selectParams = createSelector(
  selectRouterState,
  (state) => state?.params
);
export const selectParam = <T extends string = string>(paramName: string) =>
  createSelector(
    selectParams,
    (params): T => params && (params[paramName] as T)
  );

// Query Params Selectors
export const selectQueryParams = createSelector(selectRouterState, (state) => {
  return state?.queryParams || {};
});
export const selectQueryParam = <T = string>(paramName: string) =>
  createSelector(
    selectQueryParams,
    (queryParams) => queryParams[paramName] as T
  );

// Url Selector
export const selectUrl = createSelector(
  selectRouterState,
  (state) => state?.url
);

// Router Config Path Selector
export const selectRouteConfigPath = createSelector(
  selectRouterState,
  (state) => state?.routeConfigPath
);

export const selectData = createSelector(
  selectRouterState,
  (state) => state?.data
);
