import { environment } from '$/environments/environment';
import { Logger } from '$shared/logger';
import { ErrorHandler } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular-ivy';

export class AlcSentryErrorHandler
  extends SentryErrorHandler
  implements ErrorHandler
{
  private errorHandler = new ErrorHandler();

  handleError(error: any): void {
    this.errorHandler.handleError(error);

    Logger.when(!error?.rejection?.isLogged).error(
      'ErrorHandler caught an error',
      {
        error,
        originalError: this.getOriginalError(error)
      }
    );

    super.handleError(error);
  }

  private getOriginalError(error: any) {
    let originalError = error?.['ngOriginalError'];

    while (originalError?.['ngOriginalError']) {
      originalError = originalError['ngOriginalError'];
    }

    return originalError;
  }
}

export function errorHandlerFactory() {
  if (environment.name !== 'develop') {
    return new AlcSentryErrorHandler({
      showDialog: false
    });
  }
  return new ErrorHandler();
}
