import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentGroupActions = createActionGroup({
  source: 'Resident Groups Page',
  events: {
    'Load Resident Groups': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Resident Groups': (params: Params = { query: {} }) => ({
      params
    }),
    'Delete Resident Group': props<{ id: string }>()
  }
});
