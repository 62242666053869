import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { TOmittedAmount } from '$shared/medications/omitted-amount.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OmittedAmountsWsActions } from './omitted-amounts.actions';

@Injectable({ providedIn: 'root' })
export class OmittedAmountsApiService extends AbstractApiService<TOmittedAmount> {
  constructor(feathers: FeathersService, store: Store) {
    super('omitted-amounts', feathers, store, {
      entityName: 'omittedAmount',
      created: OmittedAmountsWsActions.omittedAmountsCreated,
      patched: OmittedAmountsWsActions.omittedAmountsPatched,
      removed: OmittedAmountsWsActions.omittedAmountsRemoved
    });
  }
}
