import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { SleepLog } from '$/models';
import { LogMetric } from '$/models/notebook/metrics.model';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { sleepLogsFeature } from './sleep-logs.reducer';
import { sleepLogsAdapter } from './sleep-logs.state';

export const {
  selectSleepLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = sleepLogsFeature;

export const { selectAll, selectTotal } =
  sleepLogsAdapter.getSelectors(selectSleepLogsState);

export const selectPagination = (query) => {
  return createSelector(selectSleepLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentSleepLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (sleepLogs, residentId): SleepLog[] => {
    if (isEmpty(sleepLogs) || !residentId) {
      return [];
    }

    return sleepLogs.filter((sleepLog) => {
      return sleepLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<SleepLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<SleepLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<SleepLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const computedMetrics = logs.reduce(
      (acc, log) => {
        acc.count += 1;

        acc.countAwake += log.hoursAwake !== null ? 1 : 0;
        acc.countAsleep += log.hoursAsleep !== null ? 1 : 0;
        acc.countUp += log.timesUp !== null ? 1 : 0;

        acc.sumAwake += log?.hoursAwake || 0;
        acc.sumAsleep += log?.hoursAsleep || 0;
        acc.sumUp += log?.timesUp || 0;

        acc.avgAwake = Math.round((acc.sumAwake / acc.countAwake) * 10) / 10;
        acc.avgAsleep = Math.round((acc.sumAsleep / acc.countAsleep) * 10) / 10;
        acc.avgUp = Math.round(acc.sumUp / acc.countUp);

        return acc;
      },
      {
        count: 0,
        countAwake: 0,
        countAsleep: 0,
        countUp: 0,
        sumAwake: 0,
        sumAsleep: 0,
        sumUp: 0,
        avgAwake: 0,
        avgAsleep: 0,
        avgUp: 0
      }
    );

    const metrics: LogMetric[] = [
      {
        type: 'value',
        label: 'Records',
        data: computedMetrics.count || 0
      },
      {
        type: 'value',
        label: 'Avg. Asleep',
        unit: 'hr',
        data: (computedMetrics.avgAsleep || 0).toFixed(1)
      },
      {
        type: 'value',
        label: 'Avg. Awake',
        unit: 'hr',
        data: (computedMetrics.avgAwake || 0).toFixed(1)
      },
      {
        type: 'value',
        label: 'Avg. Times Up',
        data: (computedMetrics.avgUp || 0).toFixed(1)
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
