import { AnnouncementDetailPageActions } from '$/app/pages/communication/announcement-detail/announcement-detail.actions';
import { AnnouncementsListPageActions } from '$/app/pages/communication/announcements-list/announcements-list.actions';
import {
  AnnouncementsApiService,
  EffectHelpersService,
  OverlayService
} from '$/app/services';
import { AnnouncementFormPageActions } from '$/app/shared/pages/forms/announcement-form/announcement-form.actions';
import { UnreadAnnouncementSelectors } from '$/app/shared/pages/modals/unread-announcements/unread-announcement.selectors';
import { AlcUnreadAnnouncementsComponent } from '$/app/shared/pages/modals/unread-announcements/unread-announcements.component';
import { ApiData, extractFieldsToDict } from '$/app/utils';
import { Announcement, AnnouncementMetrics, Paginated } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, filter, of, take } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  AnnouncementsApiActions,
  AnnouncementsWsActions
} from './announcements.actions';

@Injectable()
export class AnnouncementsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly announcementsService = inject(AnnouncementsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly overlay = inject(OverlayService);

  loadAnnouncements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnouncementsListPageActions.loadAnnouncements),
      switchMap((action) => {
        return this.announcementsService.getAll(action.params).pipe(
          map((announcements: Paginated<Announcement>) =>
            AnnouncementsApiActions.loadAnnouncementsSuccess({
              announcements
            })
          ),
          catchError((error) =>
            of(AnnouncementsApiActions.loadAnnouncementsFail({ error }))
          )
        );
      })
    );
  });

  getAnnouncements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnouncementsListPageActions.getAnnouncements),
      switchMap((action) => {
        return this.announcementsService.getAll(action.params).pipe(
          map((announcements: Paginated<Announcement>) =>
            AnnouncementsApiActions.getAnnouncementsSuccess({
              announcements
            })
          ),
          catchError((error) =>
            of(AnnouncementsApiActions.getAnnouncementsFail({ error }))
          )
        );
      })
    );
  });

  createAnnouncement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnouncementFormPageActions.createAnnouncement),
      exhaustMap((action) => {
        return this.announcementsService
          .create(action.announcement, action.params)
          .pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Announcement created successfully!'
              )
            ),
            map((announcement) =>
              AnnouncementsApiActions.createAnnouncementSuccess({
                announcement
              })
            ),
            catchError((error) => {
              this.store.dispatch(
                AnnouncementsApiActions.createAnnouncementFail({ error })
              );
              return EMPTY;
            })
          );
      })
    );
  });

  createAnnouncementFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AnnouncementsApiActions.createAnnouncementFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateAnnouncement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnouncementDetailPageActions.updateAnnouncement),
      switchMap((action) => {
        return this.announcementsService.patch(action.id, action.changes).pipe(
          tap(
            this.effectHelpers.onModalFormSubmitSuccess(
              'Announcement updated successfully!'
            )
          ),
          map((announcement) =>
            AnnouncementsApiActions.updateAnnouncementSuccess({
              announcement
            })
          ),
          catchError((error) => {
            this.store.dispatch(
              AnnouncementsApiActions.updateAnnouncementFail({ error })
            );
            return EMPTY;
          })
        );
      })
    );
  });

  updateAnnouncementFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AnnouncementsApiActions.updateAnnouncementFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteAnnouncement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnouncementsListPageActions.deleteAnnouncement),
      exhaustMap((action) => {
        return this.announcementsService.delete(action.id, action.params).pipe(
          tap(
            this.effectHelpers.onModalFormSubmitSuccess(
              'Announcement deleted successfully!'
            )
          ),
          map(({ id }) =>
            AnnouncementsApiActions.deleteAnnouncementSuccess({ id })
          ),
          catchError((error) => {
            this.store.dispatch(
              AnnouncementsApiActions.deleteAnnouncementFail({ error })
            );
            return EMPTY;
          })
        );
      })
    );
  });

  deleteAnnouncementFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AnnouncementsApiActions.deleteAnnouncementFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  getAnnouncementsPageInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnouncementsListPageActions.getAnnouncementsPageInfo),
      switchMap((action) => {
        return this.announcementsService.getAll(action.params).pipe(
          mergeMap((announcements) => {
            const responseData = new ApiData('announcements', announcements);
            const metrics = extractFieldsToDict<AnnouncementMetrics>(
              responseData.normalizedData.announcements ?? [],
              ['totalAcknowledgements', 'readAcknowledgements', 'totalReplies']
            );
            responseData.setPrimaryAction(
              AnnouncementsApiActions.loadAnnouncementsSuccess,
              {
                metadata: {
                  metrics
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  fetchAnnouncementDetailPageInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnnouncementDetailPageActions.fetchAnnouncementDetailPageInfo),
      switchMap((action) => {
        return this.announcementsService.get(action.id, action.params).pipe(
          mergeMap((announcement) => {
            const responseData = new ApiData('announcements', announcement);
            const metrics = extractFieldsToDict<AnnouncementMetrics>(
              responseData.normalizedData.announcements,
              ['totalAcknowledgements', 'readAcknowledgements', 'totalReplies']
            );
            responseData.setPrimaryAction(
              AnnouncementsApiActions.fetchAnnouncementSuccess,
              {
                metadata: {
                  metrics
                },
                payloadKey: 'announcement'
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  announcementCreated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AnnouncementsWsActions.announcementsCreated),
        switchMap(() =>
          this.store.select(UnreadAnnouncementSelectors.selectUnread).pipe(
            take(1),
            filter((announcements) => announcements.length > 0),
            tap(() =>
              this.overlay.showModalSingleton({
                component: AlcUnreadAnnouncementsComponent,
                cssClass: 'stacked'
              })
            )
          )
        )
      );
    },
    { dispatch: false }
  );
}
