import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PrnsEffects } from './prns.effects';
import { prnsReducer } from './prns.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('prns', prnsReducer),
    EffectsModule.forFeature([PrnsEffects])
  ],
  providers: [PrnsEffects]
})
export class PrnsStoreModule {}
