import { FeathersService } from '$/app/services/feathers.service';
import { ResidentMedicalProfessionalsWsActions } from '$/app/store/resident-medical-professionals/actions';
import { toUpdated } from '$/app/utils';
import {
  Paginated,
  Params,
  ResidentMedicalProfessional,
  Update
} from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ResidentMedicalProfessionalsApiService {
  private residentMedicalProfessionalsService: Service<ResidentMedicalProfessional>;

  constructor(feathers: FeathersService, store: Store) {
    this.residentMedicalProfessionalsService = feathers.client.service(
      'resident-medical-professionals'
    );

    this.residentMedicalProfessionalsService.on(
      'created',
      (residentMedicalProfessional) => {
        store.dispatch(
          ResidentMedicalProfessionalsWsActions.residentMedicalProfessionalsCreated(
            { residentMedicalProfessional }
          )
        );
      }
    );

    this.residentMedicalProfessionalsService.on(
      'patched',
      (residentMedicalProfessional) => {
        store.dispatch(
          ResidentMedicalProfessionalsWsActions.residentMedicalProfessionalsPatched(
            { residentMedicalProfessional }
          )
        );
      }
    );

    this.residentMedicalProfessionalsService.on(
      'removed',
      (residentMedicalProfessional) => {
        store.dispatch(
          ResidentMedicalProfessionalsWsActions.residentMedicalProfessionalsRemoved(
            { id: residentMedicalProfessional.id }
          )
        );
      }
    );
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<ResidentMedicalProfessional>> {
    const promise = this.residentMedicalProfessionalsService.find(
      params
    ) as Promise<Paginated<ResidentMedicalProfessional>>;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<ResidentMedicalProfessional> {
    const promise = this.residentMedicalProfessionalsService.get(id, params);
    return from(promise);
  }

  create(
    residentMedicalProfessional:
      | ResidentMedicalProfessional
      | ResidentMedicalProfessional[]
  ) {
    const promise = this.residentMedicalProfessionalsService.create(
      residentMedicalProfessional
    ) as Promise<ResidentMedicalProfessional>;
    return from(promise);
  }

  patch(
    id: string,
    residentMedicalProfessional: Partial<ResidentMedicalProfessional>
  ): Observable<Update<ResidentMedicalProfessional>> {
    const promise = this.residentMedicalProfessionalsService.patch(
      id,
      residentMedicalProfessional
    );

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string, params: Params): Observable<ResidentMedicalProfessional> {
    const promise = this.residentMedicalProfessionalsService.remove(id, params);
    return from(promise);
  }
}
