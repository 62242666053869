import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { TemperatureLogsPageActions } from '$/app/pages/notebook/shared/logs/temperature-log/temperature-log.actions';
import {
  EffectHelpersService,
  TemperatureLogsApiService
} from '$/app/services';
import { TemperatureLogFormPageActions } from '$/app/shared/pages/forms/log-forms/temp-log-form/temp-log-form.actions';
import { getAllPages, reducePaginatedResponses } from '$/app/utils';
import { ApiData } from '$/app/utils/api-data';
import { TemperatureLog } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { TemperatureLogsApiActions } from './temperature-logs.actions';

@Injectable()
export class TemperatureLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly temperatureLogsService = inject(TemperatureLogsApiService);

  loadTemperatureLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemperatureLogsPageActions.loadTemperatureLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.temperatureLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('temperatureLogs', logs);
            responseData.setPrimaryAction(
              TemperatureLogsApiActions.loadTemperatureLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getTemperatureLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TemperatureLogsPageActions.getTemperatureLogs,
        LogsPageActions.getTemperatureLogs,
        GivePrnFormActions.getTemperatureLogs
      ),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.temperatureLogsService.getAll(action.params).pipe(
          getAllPages(this.temperatureLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('temperatureLogs', logs);
            responseData.setPrimaryAction(
              TemperatureLogsApiActions.getTemperatureLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createTemperatureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemperatureLogFormPageActions.createTemperatureLog),
      exhaustMap((action) => {
        return this.temperatureLogsService.create(action.temperatureLog).pipe(
          tap((data) => {
            this.effectHelpers.dismissModal({ data });
          }),
          map((temperatureLog) =>
            TemperatureLogsApiActions.createTemperatureLogSuccess({
              temperatureLog
            })
          ),
          catchError((error) =>
            of(
              TemperatureLogsApiActions.createTemperatureLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateTemperatureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemperatureLogFormPageActions.updateTemperatureLog),
      switchMap((action) => {
        return this.temperatureLogsService
          .patch(action.id, action.changes)
          .pipe(
            tap((data) => {
              this.effectHelpers.dismissModal({ data });
            }),
            map((temperatureLog) => {
              return TemperatureLogsApiActions.updateTemperatureLogSuccess({
                temperatureLog
              });
            }),
            catchError((error) => {
              return of(
                TemperatureLogsApiActions.updateTemperatureLogFail({ error })
              );
            })
          );
      })
    );
  });

  deleteTemperatureLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TemperatureLogsPageActions.deleteTemperatureLog,
        ResidentMedicationTasksPageActions.deleteTemperatureLog,
        MissedMedicationsPageActions.deleteTemperatureLog,
        RoutineMarDetailPageActions.deleteTemperatureLog,
        RoutineMarListPageActions.deleteTemperatureLog,
        GivePrnFormActions.deleteTemperatureLog,
        CompleteCareTaskModalActions.deleteTemperatureLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete temperature log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.temperatureLogsService.delete(action.id, action.params);
        },
        onSuccess: (temperatureLog: TemperatureLog) =>
          TemperatureLogsApiActions.deleteTemperatureLogSuccess({
            id: temperatureLog.id
          }),
        onError: (error) =>
          TemperatureLogsApiActions.deleteTemperatureLogFail({ error })
      })
    );
  });
}
