import * as CarePlanItemSelectors from '$/app/store/care-plan-items/care-plan-items.selectors';
import { createSelector } from '@ngrx/store';

import { carePlanItemSchedulesFeature } from './care-plan-item-schedules.reducer';
import { carePlanItemSchedulesAdapter } from './care-plan-item-schedules.state';

export const {
  selectCarePlanItemSchedulesState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError,
  selectSkip,
  selectTotal: selectTotalRecords
} = carePlanItemSchedulesFeature;

export const { selectAll, selectTotal } =
  carePlanItemSchedulesAdapter.getSelectors(selectCarePlanItemSchedulesState);

export const selectFilteredSchedules = createSelector(
  selectAll,
  CarePlanItemSelectors.selectCarePlanItem,
  (carePlanItemSchedules, carePlanItem) => {
    if (!carePlanItem) {
      return [];
    }

    return carePlanItemSchedules.filter(
      (x) => x.carePlanItemId === carePlanItem.id && x.isActive === true
    );
  }
);
