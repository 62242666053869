import { Acknowledgement, Update } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const UnreadAnnouncementPageActions = createActionGroup({
  source: 'Unread Announcement Modal',
  events: {
    'Acknowledge Announcement': (
      announcementId: string
    ): Update<Acknowledgement> => {
      return {
        id: null,
        changes: {
          isRead: true
        },
        params: {
          query: {
            announcementId
          }
        }
      };
    }
  }
});
