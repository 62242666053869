import { medicationDestructionFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-form/medication-destruction-form.actions';
import { DashboardMedicationDestructionPageActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction.actions';
import { EffectHelpersService } from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicationDestructionsApiActions } from './medication-destructions.actions';
import { MedicationDestructionsService } from './medication-destructions.service';

@Injectable()
export class MedicationDestructionsEffects {
  private readonly medicationDestructionsService = inject(
    MedicationDestructionsService
  );
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadMedicationDestructions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardMedicationDestructionPageActions.loadMedicationDestructions
      ),
      this.effectHelpers.apiRequest({
        description: 'Load Medication Destruction',
        useMapOperator: 'switchMap',
        onRequest: (action) =>
          this.medicationDestructionsService
            .getAll(action.params)
            .pipe(
              getAllPages(
                this.medicationDestructionsService,
                action?.params?.query
              ),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medicationDestructions',
            response,
            MedicationDestructionsApiActions.loadMedicationDestructionsSuccess
          );
          return responseData.getActions();
        },
        onError: (error) =>
          MedicationDestructionsApiActions.loadMedicationDestructionsFail({
            error
          })
      })
    );
  });

  createMedicationDestruction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardMedicationDestructionPageActions.createMedicationDestruction
      ),
      this.effectHelpers.apiRequest({
        description: 'Create Medication Destruction',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.medicationDestructionsService.create(
            action.medicationDestruction,
            action.params
          ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'medicationDestructions',
            response,
            MedicationDestructionsApiActions.createMedicationDestructionSuccess,
            {
              payloadKey: 'medicationDestruction'
            }
          );
          return responseData.getActions();
        },
        onError: (error) =>
          MedicationDestructionsApiActions.createMedicationDestructionFail({
            error
          })
      })
    );
  });

  deleteMedicationDestruction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardMedicationDestructionPageActions.deleteMedicationDestruction
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete medication destruction',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.medicationDestructionsService.delete(
            action.id,
            action.params
          );
        },
        onSuccess: (document) =>
          MedicationDestructionsApiActions.deleteMedicationDestructionSuccess({
            id: document.id
          }),
        onError: (error) =>
          MedicationDestructionsApiActions.deleteMedicationDestructionFail({
            error
          })
      })
    );
  });

  updateMedicationDestruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DashboardMedicationDestructionPageActions.updateMedicationDestruction,
        medicationDestructionFormActions.updateMedicationDestruction
      ),
      this.effectHelpers.apiRequest({
        description: 'Updating Medication Destruction',
        onRequest: (action) =>
          this.medicationDestructionsService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (medicationDestruction) =>
          MedicationDestructionsApiActions.updateMedicationDestructionSuccess({
            medicationDestruction
          }),
        onError: (error) =>
          MedicationDestructionsApiActions.updateMedicationDestructionFail({
            error
          })
      })
    )
  );
}
