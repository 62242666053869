import { diagnosesAdapter } from '$/app/store/diagnoses/diagnoses.state';

import { diagnosesFeature } from './diagnoses.reducer';

export const {
  selectDiagnosesState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = diagnosesFeature;

export const { selectAll, selectTotal } =
  diagnosesAdapter.getSelectors(selectDiagnosesState);
