import {
  CarePlanItem,
  CarePlanItemSchedule,
  CareTask,
  ResidentRelations
} from '$/models';
import { IResident } from '$shared/residents/residents.interface';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { Dictionary } from '$shared/types';
import { DateTime } from 'luxon';

type ResidentWithRoom = IResident & Pick<ResidentRelations, 'room'>;

export class CareTaskItemVM extends CareTask {
  carePlanItem: CarePlanItem;
  schedule: CarePlanItemSchedule;
  resident: ResidentWithRoom;
  completor: IFacilityUser;
  isMissed: boolean;
  log?: any;

  constructor(
    careTask: CareTask,
    carePlanItems: Dictionary<CarePlanItem>,
    schedules: Dictionary<CarePlanItemSchedule>,
    residents: Dictionary<ResidentWithRoom>,
    facilityUsers: Dictionary<IFacilityUser>,
    logs?: Dictionary<any>
  ) {
    super(careTask);

    this.carePlanItem = carePlanItems[this.carePlanItemId];
    this.schedule = schedules[this.carePlanItemScheduleId];
    this.resident = residents[this.residentId];
    this.completor = facilityUsers[this.completedBy];

    this.isMissed =
      ['scheduled'].includes(this.currentState) &&
      this.scheduledFor < DateTime.utc().toISO();

    this.log = logs?.[this.id];
  }

  setLog(log: any) {
    this.log = log;
  }
}
