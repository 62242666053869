import { FacilityUserRole } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FacilityUserRolesApiActions = createActionGroup({
  source: 'FacilityUserRoles API',
  events: {
    'Create Facility User Role Success': props<{
      facilityUserRole: MaybeArray<FacilityUserRole>;
    }>(),
    'Delete FacilityUserRole Success': props<{ id: string }>(),

    'Create Facility User Role Fail': props<{ error: Error }>(),
    'Delete FacilityUserRole Fail': props<{ error: Error }>()
  }
});

export const FacilityUserRolesWsActions = createActionGroup({
  source: 'FacilityUserRoles WS',
  events: {
    'Facility User Roles Created': props<{
      facilityUserRole: FacilityUserRole;
    }>(),
    'FacilityUserRoles Removed': props<{ id: string }>()
  }
});

export const FacilityUserRolesGeneralActions = createActionGroup({
  source: 'FacilityUserRoles WS',
  events: {
    'Clear Facility User Roles': emptyProps(),
    'Add Facility User Roles': props<{
      facilityUserRoles: FacilityUserRole[];
    }>()
  }
});
