import { createSelector } from '@ngrx/store';
import { keyBy } from 'lodash';

import { libraryCollectionDocumentTemplatesFeature } from './library-collection-document-templates.reducer';
import { libraryCollectionDocumentTemplatesAdapter } from './library-collection-document-templates.state';

export const {
  selectLibraryCollectionDocumentTemplatesState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = libraryCollectionDocumentTemplatesFeature;

export const { selectAll, selectTotal } =
  libraryCollectionDocumentTemplatesAdapter.getSelectors(
    selectLibraryCollectionDocumentTemplatesState
  );

export const selectByCollectionIdAndTemplateId = createSelector(
  selectAll,
  (collectionPackets) => {
    return keyBy(
      collectionPackets,
      (collectionPacket) =>
        `${collectionPacket.libraryCollectionId}-${collectionPacket.libraryDocumentTemplateId}`
    );
  }
);
