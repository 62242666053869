import { AlcAction, Params, Symptom } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Symptoms List Page]';

export const getSymptoms = createAction(
  `${source} Get Symptoms`,
  (params: Params = { query: {} }) => ({ params })
);

export const createSymptom = createAction(
  `${source} Create Symptom`,
  AlcAction<{ symptom: Symptom }>()
);

export const deleteSymptom = createAction(
  `${source} Delete Symptom`,
  props<{ id: string }>()
);

export const SymptomsListPageActions = {
  getSymptoms,
  createSymptom,
  deleteSymptom
};
