import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IOxygenLog } from '$shared/services/oxygen-log';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { oxygenLogsFeature } from './oxygen-logs.reducer';
import { oxygenLogsAdapter } from './oxygen-logs.state';

export const {
  selectOxygenLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = oxygenLogsFeature;

export const { selectAll, selectTotal } = oxygenLogsAdapter.getSelectors(
  selectOxygenLogsState
);

export const selectResidentOxygenLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (bpLogs, residentId): IOxygenLog[] => {
    if (isEmpty(bpLogs) || !residentId) {
      return [];
    }

    return bpLogs.filter((bpLog) => {
      return bpLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<IOxygenLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IOxygenLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IOxygenLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const computedMetrics = logs.reduce(
      (acc, log) => {
        acc.count += 1;
        acc.sumLevel += log.level;
        acc.sumPulse += log.pulse;
        acc.avgLevel = Math.round(acc.sumLevel / acc.count);
        acc.avgPulse = Math.round(acc.sumPulse / acc.count);

        return acc;
      },
      {
        count: 0,
        sumLevel: 0,
        sumPulse: 0,
        avgLevel: 0,
        avgPulse: 0
      }
    );

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: computedMetrics?.count || 0
      },
      {
        type: 'value',
        label: 'Avg. Level',
        data: (computedMetrics?.avgLevel || 0).toFixed(1)
      },
      {
        type: 'value',
        label: 'Avg. Pulse',
        data: (computedMetrics?.avgPulse || 0).toFixed(0)
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
