import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const MedicalProfessionalInfoModalActions = createActionGroup({
  source: 'Medical Professional Info Modal',
  events: {
    'Fetch Medical Professional': (
      id: string,
      params: Params = { query: {} }
    ) => ({
      id,
      params
    })
  }
});
