import { FeathersService } from '$/app/services/feathers.service';
import { UrineLogsWsActions } from '$/app/store/urine-logs/urine-logs.actions';
import { IUrineLog } from '$shared/services/urine-log';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from '../../services/api/abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class UrineLogsApiService extends AbstractApiService<IUrineLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('urine-logs', feathers, store, {
      entityName: 'urineLog',
      created: UrineLogsWsActions.urineLogsCreated,
      patched: UrineLogsWsActions.urineLogsPatched,
      removed: UrineLogsWsActions.urineLogsRemoved
    });
  }
}
