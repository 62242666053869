import { Params, Pharmacy, PharmacyGraph } from '$/models';
import { createAction, props } from '@ngrx/store';

export const createPharmacy = createAction(
  '[Pharmacy Form Page] Create Pharmacy',
  props<{ pharmacy: PharmacyGraph }>()
);

export const updatePharmacy = createAction(
  '[Pharmacy Form Page] Update Pharmacy',
  (id: string, changes: Partial<Pharmacy>, params: Params = { query: {} }) => ({
    id,
    changes,
    params
  })
);
