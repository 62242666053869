import { OverlayService } from '$/app/services';
import { AlcContactSupportComponent } from '$/app/shared/components/contact-support.component';
import { SharedModule } from '$/app/shared/shared.module';
import { AlcModal } from '$/lib/ui/alcomy-modal';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Component, inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'alc-error-alert-page',
  standalone: true,
  imports: [SharedModule, ClipboardModule, AlcContactSupportComponent],
  templateUrl: './error-alert.modal.html',
  styles: `
    ion-content {
      --padding-start: 16px;
      --padding-end: 16px;
      --padding-top: 16px;
      --padding-bottom: 16px;
      --background: var(--ion-color-white);
    }
  `
})
export class ErrorAlertModal extends AlcModal implements OnInit {
  private readonly overlay = inject(OverlayService);
  private readonly sanitizer = inject(DomSanitizer);

  @Input() title: string;
  @Input() message: string;
  @Input() error: { message: string; code: number; data: any; errors: any[] };

  protected serverMessage: string | undefined;
  protected safeMessage: SafeHtml;
  protected isHTML = false;

  ngOnInit() {
    this.message ||= this.error?.message;
    if (this.message) {
      this.isHTML = this.message.includes('<');
      this.safeMessage = this.sanitizer.bypassSecurityTrustHtml(this.message);
    }

    this.serverMessage = this.error?.data?.serverMessage;

    if (this.error?.code === 403) {
      this.title = "You don't have permission";
    }

    this.title ||= 'Error';
  }

  protected async copy() {
    await Clipboard.write({
      string: this.error?.data?.requestId
    });

    await this.overlay.showToast('success', 'Copied!');
  }
}
