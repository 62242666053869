import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IBmLog } from '$shared/services/bm-log';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { bmLogsFeature } from './bowel-movement-logs.reducer';
import { bmLogsAdapter } from './bowel-movement-logs.state';

export const {
  selectBmLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = bmLogsFeature;

export const { selectAll, selectTotal } =
  bmLogsAdapter.getSelectors(selectBmLogsState);

export const selectPagination = (query) => {
  return createSelector(selectBmLogsState, (state) => {
    return state.pagination[query];
  });
};
export const selectResidentBmLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (bmLogs, residentId): IBmLog[] => {
    if (isEmpty(bmLogs) || !residentId) {
      return [];
    }

    return bmLogs.filter((bmLog) => {
      return bmLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<IBmLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IBmLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IBmLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const computedMetrics = logs.reduce(
      (acc, log) => {
        acc.count += 1;
        acc.diaCount +=
          log.bmTextureId === 'diarrhea' || log.bmTextureId === 'liquid'
            ? 1
            : 0;

        return acc;
      },
      {
        count: 0,
        diaCount: 0
      }
    );

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length || 0
      },
      {
        type: 'value',
        label: 'Diarrhea',
        data: computedMetrics.diaCount
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
