import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const OxygenLogsPageActions = createActionGroup({
  source: 'Oxygen Log Page',
  events: {
    'Load Oxygen Logs': (params: Params) => ({ params }),
    'Get Oxygen Logs': (params: Params) => ({ params }),
    'Delete Oxygen Log': props<{ id: string; params?: Params }>()
  }
});
