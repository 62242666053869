import { ScheduleTime, Table } from '$/models/data/base';
import { CareTaskFrequencyTypeId } from '$shared/lookups/care-task-frequency-types.lookup';

export class CarePlanItemSchedule extends Table {
  carePlanItemId?: string;
  isActive?: boolean;
  dtstart?: string;
  until?: string;
  typeId?: string;
  frequencyTypeId?: CareTaskFrequencyTypeId;
  su?: boolean;
  mo?: boolean;
  tu?: boolean;
  we?: boolean;
  th?: boolean;
  fr?: boolean;
  sa?: boolean;
  reminder?: string;
  interval?: number;
  byMonthDay?: string;

  times?: ScheduleTime[];

  constructor(carePlanItemSchedule: CarePlanItemSchedule) {
    super();
    Object.assign(this, carePlanItemSchedule);
  }
}
