import { Params } from '$/models';
import { createAction } from '@ngrx/store';

const source = '[Role Picker Form Page]';

export const loadRoles = createAction(
  `${source} Load Roles`,
  (params: Params = { query: {} }) => ({ params })
);

export const RolePickerFormPageActions = {
  loadRoles
};
