import { createFeature, createReducer, on } from '@ngrx/store';
import {
  IncidentReportResidentsGeneralActions,
  IncidentReportResidentsWsActions
} from './actions';
import {
  incidentReportResidentsAdapter,
  initialState
} from './incident-report-residents.state';

export const incidentReportResidentsFeature = createFeature({
  name: 'incidentReportResidents',
  reducer: createReducer(
    initialState,

    on(
      IncidentReportResidentsWsActions.incidentReportResidentCreated,
      IncidentReportResidentsWsActions.incidentReportResidentPatched,
      (state, action) => {
        return incidentReportResidentsAdapter.upsertOne(
          action.incidentReportResident,
          state
        );
      }
    ),

    on(
      IncidentReportResidentsWsActions.incidentReportResidentRemoved,
      (state, action) => {
        return incidentReportResidentsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      IncidentReportResidentsGeneralActions.addIncidentReportResidents,
      (state, action) => {
        return incidentReportResidentsAdapter.upsertMany(
          action.incidentReportResidents,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      IncidentReportResidentsGeneralActions.clearIncidentReportResidents,
      () => {
        return incidentReportResidentsAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
