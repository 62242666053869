import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { IDestructionMethod } from '$shared/services/destruction-method.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DestructionMethodsWsActions } from './destruction-methods.actions';

@Injectable({ providedIn: 'root' })
export class DestructionMethodsApiService extends AbstractApiService<IDestructionMethod> {
  constructor(feathers: FeathersService, store: Store) {
    super('destruction-methods', feathers, store, {
      entityName: 'destructionMethod',
      created: DestructionMethodsWsActions.destructionMethodsCreated,
      patched: DestructionMethodsWsActions.destructionMethodsPatched,
      removed: DestructionMethodsWsActions.destructionMethodsRemoved
    });
  }
}
