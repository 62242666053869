import { ILibraryDocumentTemplatePacketTemplate } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LibraryDocumentTemplatePacketTemplatesGeneralActions =
  createActionGroup({
    source: 'Library Document Template Packet Templates General',
    events: {
      'Clear Library Document Template Packet Templates': emptyProps(),
      'Add Library Document Template Packet Templates': props<{
        libraryDocumentTemplatePacketTemplates: ILibraryDocumentTemplatePacketTemplate[];
      }>()
    }
  });
