import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { MedicationDestructionItemsWsActions } from '$/app/store/medication-destruction-items/medication-destruction-items.actions';
import { IMedicationDestructionItem } from '$shared/services/medication-destruction-items.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class MedicationDestructionItemsService extends AbstractApiService<IMedicationDestructionItem> {
  constructor(feathers: FeathersService, store: Store) {
    super('medication-destruction-items', feathers, store, {
      entityName: 'medicationDestructionItem',
      created:
        MedicationDestructionItemsWsActions.medicationDestructionItemsCreated,
      patched:
        MedicationDestructionItemsWsActions.medicationDestructionItemsPatched,
      removed:
        MedicationDestructionItemsWsActions.medicationDestructionItemsRemoved
    });
  }
}
