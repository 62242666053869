import { Table } from '$/models/data/base';

export class MedicationOrderItem extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  medicationId?: string;
  medicationBatchId?: string;
  medicationOrderId?: string;
  pharmacyId?: string;
  isRefill?: boolean;
  status?: string;
  receivedBy?: string;
  receivedAt?: string;
}
