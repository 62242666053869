import { DocumentParticipantManagerPageActions } from '$/app/pages/documents/shared/components/document-participant-manager/document-participant-manager.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DocumentParticipantsApiActions,
  DocumentParticipantsGeneralActions,
  DocumentParticipantsWsActions
} from './document-participants.actions';
import {
  documentParticipantsAdapter,
  initialState
} from './document-participants.state';

export const documentParticipantsFeature = createFeature({
  name: 'documentParticipants',
  reducer: createReducer(
    initialState,

    on(
      DocumentParticipantManagerPageActions.updateDocumentParticipants,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      DocumentParticipantsApiActions.updateDocumentParticipantSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          error: null
        };
      }
    ),

    on(
      DocumentParticipantsApiActions.updateDocumentParticipantFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      DocumentParticipantsWsActions.documentParticipantCreated,
      DocumentParticipantsWsActions.documentParticipantPatched,
      (state, action) => {
        return documentParticipantsAdapter.upsertOne(
          action.documentParticipant,
          state
        );
      }
    ),

    on(
      DocumentParticipantsWsActions.documentParticipantRemoved,
      (state, action) => {
        return documentParticipantsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      DocumentParticipantsGeneralActions.addDocumentParticipants,
      (state, action) => {
        return documentParticipantsAdapter.upsertMany(
          action.documentParticipants,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(DocumentParticipantsGeneralActions.clearDocumentParticipants, () => {
      return documentParticipantsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
