import { Update } from '$/models';
import { IBmLog } from '$shared/services/bm-log';
import { createActionGroup, props } from '@ngrx/store';

export const BmLogFormPageActions = createActionGroup({
  source: 'BM Log Form Page',
  events: {
    'Create Bowel Movement Log': props<{ bmLog: IBmLog }>(),
    'Update Bowel Movement Log': props<Update<IBmLog>>()
  }
});
