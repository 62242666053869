import { PharmacyPhone } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearPharmacyPhones = createAction(
  '[PharmacyPhones] Clear PharmacyPhones'
);

export const addPharmacyPhones = createAction(
  'Add Pharmacy Phones',
  props<{ pharmacyPhones: PharmacyPhone[] }>()
);
