import { ValidationError } from '$/models';
import { Logger } from '$shared/logger';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { cloneDeep } from 'lodash';

export function validateForm<T = any>(
  form: UntypedFormGroup | UntypedFormArray | UntypedFormControl
): T {
  if (form.status === 'VALID') {
    if (form instanceof UntypedFormGroup || form instanceof UntypedFormArray) {
      return cloneDeep(form.getRawValue());
    }

    return form.getRawValue();
  }

  form.markAllAsTouched();

  Logger.info('Form invalid', { form });

  throw new ValidationError(
    'The form could not be submitted because some fields are invalid. Please fix the invalid fields (typically displayed in red) and try again.'
  );
}
