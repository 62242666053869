import { getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import { SymptomsApiActions, SymptomsGeneralActions } from './actions';
import { initialState, symptomsAdapter } from './symptoms.state';

export const symptomsFeature = createFeature({
  name: 'symptoms',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(SymptomsApiActions.loadSymptomsSuccess, (state, action) => {
      const data = getRecords(action.symptoms);

      return symptomsAdapter.setAll(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(SymptomsApiActions.getSymptomsSuccess, (state, action) => {
      const data = getRecords(action.symptoms);

      return symptomsAdapter.upsertMany(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(SymptomsApiActions.createSymptomSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(SymptomsApiActions.updateSymptomSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(SymptomsApiActions.deleteSymptomSuccess, (state, action) =>
      symptomsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      SymptomsApiActions.loadSymptomsFail,
      SymptomsApiActions.getSymptomsFail,
      SymptomsApiActions.createSymptomFail,
      SymptomsApiActions.updateSymptomFail,
      SymptomsApiActions.deleteSymptomFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      SymptomsApiActions.symptomsCreated,
      SymptomsApiActions.symptomsPatched,
      (state, action) => {
        return symptomsAdapter.upsertOne(action.symptom, state);
      }
    ),

    on(SymptomsApiActions.symptomsRemoved, (state, action) => {
      return symptomsAdapter.removeOne(action.id, state);
    }),

    on(SymptomsGeneralActions.addSymptoms, (state, action) => {
      return symptomsAdapter.upsertMany(action.symptoms, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(SymptomsGeneralActions.clearSymptoms, () => {
      return symptomsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
