import {
  EffectHelpersService,
  IncidentReportResidentsApiService
} from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()
export class IncidentReportResidentsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly incidentReportResidentsService = inject(
    IncidentReportResidentsApiService
  );
}
