import { OverlayService } from '$/app/services';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  inject
} from '@angular/core';
import { Router } from '@angular/router';

@Directive({ selector: '[alcResidentLink]', standalone: true })
export class AlcResidentLinkDirective {
  private readonly router = inject(Router);
  private readonly overlayService = inject(OverlayService);

  @Input() alcResidentLink: string;

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.cursor = 'pointer';
  }

  @HostListener('click') onClick() {
    if (this.alcResidentLink) {
      this.overlayService.dismissAll();
      this.router.navigate(['/residents', this.alcResidentLink, 'facesheet']);
    }
  }
}
