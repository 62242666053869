@for (item of items(); track item) {
  <ion-item [lines]="lines()">
    @switch (start()) {
      @case ('avatar') {
        <ion-skeleton-text animated slot="start" class="size-10 rounded-full" />
      }
      @case ('image') {
        <ion-skeleton-text animated slot="start" class="size-10 rounded" />
      }
      @case ('icon') {
        <ion-skeleton-text animated slot="start" class="size-6 rounded" />
      }
    }
    <ion-label class="@container">
      <div class="flex max-w-[90%] flex-col @sm:max-w-[75%] @md:max-w-[66%]">
        <h2>
          <ion-skeleton-text animated class="rounded-full" />
        </h2>
        <p
          class="mt-1"
          [ngClass]="{
            hidden: rows() < 2
          }"
        >
          <ion-skeleton-text animated class="w-2/3 rounded-full" />
        </p>
        <p
          class="mt-1"
          [ngClass]="{
            hidden: rows() < 3
          }"
        >
          <ion-skeleton-text animated class="w-1/2 rounded-full" />
        </p>
      </div>
    </ion-label>
    @switch (end()) {
      @case ('checkbox') {
        <ion-skeleton-text animated slot="end" class="size-6 rounded" />
      }
      @case ('radio') {
        <ion-skeleton-text animated slot="end" class="size-6 rounded-full" />
      }
      @case ('date') {
        <ion-skeleton-text animated slot="end" class="w-16 rounded" />
      }
    }
  </ion-item>
}
