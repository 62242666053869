import { createNewSortInstance } from 'fast-sort';

export const tagSorter = <T = any>(items: T[], tags: string[]) => {
  return createNewSortInstance({
    comparer: (a, b) => {
      if (typeof a === 'object' || typeof b === 'object') {
        throw new Error('tagSorter: Cannot sort objects');
      }

      return tags.indexOf(a) - tags.indexOf(b);
    }
  })(items);
};
