<ion-header>
  <ion-toolbar color="black">
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="flex h-full flex-col justify-center bg-black pb-6">
    <ion-img class="h-full" [src]="imgUrl" />
  </div>
</ion-content>
