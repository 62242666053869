import { ILibraryCollectionDocumentTemplate } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LibraryCollectionDocumentTemplatesGeneralActions =
  createActionGroup({
    source: 'Library Collection Document Templates General',
    events: {
      'Clear Library Collection Document Templates': emptyProps(),
      'Add Library Collection Document Templates': props<{
        libraryCollectionDocumentTemplates: ILibraryCollectionDocumentTemplate[];
      }>()
    }
  });
