import { ResidentGroupFormActions } from '$/app/pages/residents/resident-group-form/resident-group-form.actions';
import { ResidentGroupActions } from '$/app/pages/residents/resident-groups/resident-groups-list.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  ResidentGroupDetailActions,
  ResidentGroupsApiActions,
  ResidentGroupsGeneralActions,
  ResidentGroupsWsActions
} from './actions';
import { initialState, residentGroupsAdapter } from './resident-groups.state';

const reducer = createReducer(
  initialState,

  on(
    ResidentGroupActions.loadResidentGroups,
    ResidentGroupActions.getResidentGroups,
    ResidentGroupDetailActions.fetchResidentGroup,
    ResidentGroupFormActions.createResidentGroup,
    ResidentGroupDetailActions.updateResidentGroup,
    ResidentGroupDetailActions.deleteResidentGroup,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(ResidentGroupsApiActions.loadResidentGroupsSuccess, (state, action) => {
    return residentGroupsAdapter.setAll(action.residentGroups, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    ResidentGroupsApiActions.getResidentGroupsSuccess,
    ResidentGroupsGeneralActions.addResidentGroups,
    (state, action) => {
      return residentGroupsAdapter.upsertMany(action.residentGroups, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(ResidentGroupsApiActions.fetchResidentGroupSuccess, (state, action) => {
    return residentGroupsAdapter.upsertOne(action.residentGroup, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(ResidentGroupsApiActions.createResidentGroupSuccess, (state, action) => {
    const residentGroups = castArray(action.residentGroup);

    return residentGroupsAdapter.addMany(residentGroups, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(ResidentGroupsApiActions.updateResidentGroupSuccess, (state, action) => {
    return residentGroupsAdapter.updateOne(action.residentGroup, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(ResidentGroupsApiActions.deleteResidentGroupSuccess, (state, action) => {
    return residentGroupsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    ResidentGroupsApiActions.loadResidentGroupsFail,
    ResidentGroupsApiActions.getResidentGroupsFail,
    ResidentGroupsApiActions.fetchResidentGroupFail,
    ResidentGroupsApiActions.createResidentGroupFail,
    ResidentGroupsApiActions.updateResidentGroupFail,
    ResidentGroupsApiActions.deleteResidentGroupFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    ResidentGroupsWsActions.residentGroupsCreated,
    ResidentGroupsWsActions.residentGroupsPatched,
    (state, action) => {
      return residentGroupsAdapter.upsertOne(action.residentGroup, {
        ...state
      });
    }
  ),

  on(ResidentGroupsWsActions.residentGroupsRemoved, (state, action) => {
    return residentGroupsAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(ResidentGroupsGeneralActions.clearResidentGroups, (state) => {
    return residentGroupsAdapter.removeAll({
      ...state,
      loading: false,
      loaded: false,
      error: null
    });
  })
);

export const residentGroupsFeature = createFeature({
  name: 'residentGroups',
  reducer
});
