import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { weightLogsReducer } from './weight-logs.reducer';
import { EffectsModule } from '@ngrx/effects';
import { WeightLogsEffects } from './weight-logs.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('weightLogs', weightLogsReducer),
    EffectsModule.forFeature([WeightLogsEffects])
  ],
  providers: [WeightLogsEffects]
})
export class WeightLogStoreModule {}
