import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlcClickStopPropagationDirective } from './click-stop-propagation.directive';
import { AlcColorDirective } from './color.directive';
import { AlcDebugDirective } from './debug.directive';
import { AlcDynamicComponentDirective } from './dynamic-component.directive';
import { AlcFeatureFlagDirective } from './feature-flag.directive';
import { AlcHighlightDirective } from './highlight.directive';
import { AlcMenuButtonToggleDirective } from './menu-button-toggle.directive';
import { AlcPermissionDirective } from './permission.directive';
import { AlcPhoneDirective } from './phone.directive';
import { AlcRemoveIonPaddingDirective } from './remove-item-padding.directive';
import { AlcResidentLinkDirective } from './resident-link.directive';
import { AlcScrollIntoViewDirective } from './scroll-into-view.directive';
import { AlcSettingDirective } from './setting.directive';
import { AlcTrackByIndexDirective } from './track-by-index.directive';
import {
  AlcCdkVirtualForTrackByPropertyDirective,
  AlcTrackByPropertyDirective
} from './track-by-property.directive';
import { AlcWhenWindowDirective } from './when-window.directive';

@NgModule({
  imports: [
    CommonModule,
    AlcCdkVirtualForTrackByPropertyDirective,
    AlcColorDirective,
    AlcFeatureFlagDirective,
    AlcMenuButtonToggleDirective,
    AlcPermissionDirective,
    AlcScrollIntoViewDirective,
    AlcTrackByIndexDirective,
    AlcTrackByPropertyDirective,
    AlcRemoveIonPaddingDirective,
    AlcPhoneDirective,
    AlcResidentLinkDirective,
    AlcDynamicComponentDirective,
    AlcClickStopPropagationDirective,
    AlcHighlightDirective,
    AlcDebugDirective,
    AlcWhenWindowDirective,
    AlcSettingDirective
  ],
  exports: [
    AlcCdkVirtualForTrackByPropertyDirective,
    AlcWhenWindowDirective,
    AlcDynamicComponentDirective,
    AlcColorDirective,
    AlcFeatureFlagDirective,
    AlcMenuButtonToggleDirective,
    AlcPermissionDirective,
    AlcScrollIntoViewDirective,
    AlcTrackByIndexDirective,
    AlcTrackByPropertyDirective,
    AlcRemoveIonPaddingDirective,
    AlcPhoneDirective,
    AlcResidentLinkDirective,
    AlcClickStopPropagationDirective,
    AlcHighlightDirective,
    AlcDebugDirective,
    AlcSettingDirective
  ],
  providers: []
})
export class AlcDirectivesModule {}
