import { Table } from '$/models/data/base';

export class BaseLog extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  notes?: string;
  recordAt?: string;
  recordedBy?: string;
  medicationTaskId?: string;
  careTaskId?: string;
  prnId?: string;
}
