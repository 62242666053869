import * as FacilitiesSelectors from '$/app/store/facilities/facilities.selectors';
import { selectRouterState } from '$/app/store/router/router.selectors';
import { Pharmacy, PharmacyPhone } from '$/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import * as PharmacyPhonesSelectors from '../pharmacy-phones/pharmacy-phones.selectors';
import { State, pharmaciesAdapter } from './pharmacies.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;

// Pharmacies State Selector
export const selectPharmaciesState = createFeatureSelector<State>('pharmacies');

const entitySelectors = pharmaciesAdapter.getSelectors(selectPharmaciesState);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(selectPharmaciesState, getLoading);
export const selectLoaded = createSelector(selectPharmaciesState, getLoaded);
export const selectError = createSelector(selectPharmaciesState, getError);

export const selectFacilityPharmacies = createSelector(
  FacilitiesSelectors.selectFacility,
  selectAll,
  (facility, pharmacies): Pharmacy[] => {
    if (isEmpty(facility) || isEmpty(pharmacies)) {
      return [];
    }
    return pharmacies.filter((pharmacy) => {
      return pharmacy.facilityId === facility.id;
    });
  }
);
export const selectCurrentFacilityPharmacies = createSelector(
  FacilitiesSelectors.selectCurrentFacility,
  selectAll,
  (facility, pharmacies): Pharmacy[] => {
    if (isEmpty(facility) || isEmpty(pharmacies)) {
      return [];
    }
    return pharmacies.filter((pharmacy) => {
      return pharmacy.facilityId === facility.id;
    });
  }
);

export const selectPharmacy = (pharmacyId?: string) =>
  createSelector(
    selectRouterState,
    selectEntities,
    (router, pharmacyEntities): Pharmacy => {
      const id = router?.params?.pharmacyId ?? pharmacyId;
      return pharmacyEntities?.[id];
    }
  );

export const selectPharmacyPhones = (pharmacyId?: string) =>
  createSelector(
    selectPharmacy(pharmacyId),
    PharmacyPhonesSelectors.selectAll,
    (pharmacy, pharmacyPhones): PharmacyPhone[] => {
      if (isEmpty(pharmacy) || isEmpty(pharmacyPhones)) {
        return [];
      }

      return pharmacyPhones.filter((phone) => {
        return phone.pharmacyId === pharmacy.id;
      });
    }
  );
