import { MedicalHistory, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MedicalHistoryFormPageActions = createActionGroup({
  source: 'Medical History Form Page',
  events: {
    'Create Medical History': props<{
      medicalHistory: MedicalHistory;
      params: Params;
    }>(),
    'Update Medical History': props<Update<MedicalHistory>>()
  }
});
