import { documentTemplatesFeature } from './document-templates.reducer';
import { documentTemplatesAdapter } from './document-templates.state';

export const {
  selectDocumentTemplatesState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = documentTemplatesFeature;

export const { selectAll, selectTotal } = documentTemplatesAdapter.getSelectors(
  selectDocumentTemplatesState
);
