<ion-header>
  <ion-toolbar color="communication-primary">
    <ion-title><span>New/Unread Announcements</span></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" fill="clear">
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="(announcements$ | ngrxPush).length > 0; else emptyState">
    <ion-card *ngFor="let announcement of announcements$ | ngrxPush">
      <ion-item lines="full">
        <ion-icon
          src="/assets/icon/material/announcement.svg"
          color="communication-primary"
          slot="start"
        />
        <ion-label>
          <h2>{{ announcement?.subject }}</h2>
          <p>
            {{ announcement?.createdBy }} ({{
              announcement?.date | facilityDateTime | relativeTime
            }})
          </p>
        </ion-label>
      </ion-item>
      <ion-card-content>
        <p class="m-0 whitespace-pre-line">{{ announcement?.body }}</p>
      </ion-card-content>
      <ion-item>
        <ion-button
          *ngIf="!announcement?.isRead"
          shape="round"
          fill="none"
          slot="end"
          (click)="confirm(announcement?.id)"
        >
          <ion-text color="communication-primary">CONFIRM</ion-text>
        </ion-button>
        <ion-button
          *ngIf="announcement?.isRead"
          shape="round"
          fill="none"
          slot="end"
          disabled
        >
          <ion-icon src="/assets/icon/material/check.svg" />
          CONFIRMED
        </ion-button>
      </ion-item>
    </ion-card>
  </ng-container>
  <ng-template #emptyState>
    <alc-empty-placeholder size="150px">
      <alc-icon-container icon-size="100px" empty-image>
        <ion-icon src="assets/icon/material/announcement.svg" />
      </alc-icon-container>
      <h5 empty-title>Great job! You've read all your announcements!</h5>
      <h6 empty-subtitle>
        Tap the close button to get back to what you were doing.
      </h6>
    </alc-empty-placeholder>
  </ng-template>
</ion-content>
