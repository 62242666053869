import { DiagnosisFormPageActions } from '$/app/pages/residents/resident-detail/diagnosis-form/diagnosis-form.actions';
import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { DiagnosesApiService, EffectHelpersService } from '$/app/services';
import { Diagnosis } from '$/models';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { DiagnosesApiActions } from './diagnoses.actions';

@Injectable()
export class DiagnosesEffects {
  private readonly actions$ = inject(Actions);
  private readonly diagnosesService = inject(DiagnosesApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadDiagnoses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentFacesheetPageActions.loadDiagnoses),
      switchMap((action) => {
        return this.diagnosesService.getAll(action.params).pipe(
          map((diagnoses: Diagnosis[]) => {
            return DiagnosesApiActions.loadDiagnosesSuccess({
              diagnoses
            });
          }),
          catchError((err) => {
            return of(DiagnosesApiActions.loadDiagnosesFail({ error: err }));
          })
        );
      })
    );
  });

  createDiagnosis$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DiagnosisFormPageActions.createDiagnosis),
        this.effectHelpers.apiRequest({
          description: 'creating diagnosis',
          onRequest: (action) => this.diagnosesService.create(action.diagnosis),
          useMapOperator: 'exhaustMap',
          onSuccess: (diagnosis) =>
            DiagnosesApiActions.createDiagnosisSuccess({ diagnosis }),
          onError: (error) => DiagnosesApiActions.createDiagnosisFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  createDiagnosisFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DiagnosesApiActions.createDiagnosisFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateDiagnosis$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DiagnosisFormPageActions.updateDiagnosis),
        this.effectHelpers.apiRequest({
          description: 'updating diagnosis',
          onRequest: (action) =>
            this.diagnosesService.patch(action.id, action.changes),
          onSuccess: (diagnosis) =>
            DiagnosesApiActions.updateDiagnosisSuccess({ diagnosis }),
          onError: (error) => DiagnosesApiActions.updateDiagnosisFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  updateDiagnosisSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DiagnosesApiActions.updateDiagnosisSuccess)
      );
    },
    { dispatch: false }
  );

  updateDiagnosisFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DiagnosesApiActions.updateDiagnosisFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteDiagnosis$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResidentFacesheetPageActions.deleteResidentDiagnosis),
        this.effectHelpers.apiRequest({
          description: 'deleting diagnosis',
          onRequest: (action) => this.diagnosesService.delete(action.id),
          useMapOperator: 'exhaustMap',
          onSuccess: (diagnosis) =>
            DiagnosesApiActions.deleteDiagnosisSuccess({ id: diagnosis.id }),
          onError: (error) => DiagnosesApiActions.deleteDiagnosisFail({ error })
        })
      );
    },
    { dispatch: false }
  );

  deleteDiagnosisFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DiagnosesApiActions.deleteDiagnosisFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
