import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { Organization } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class OrganizationsApiService extends AbstractApiService<Organization> {
  constructor(feathers: FeathersService, store: Store) {
    super('organizations', feathers, store);
  }
}
