import { sortByFields } from '$/app/utils';
import { IBehavior } from '$shared/services/behavior';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface BehaviorMetricsDict {
  [key: string]: {
    count: number;
    occurrences: number;
    averageDuration: number;
    averageSeverityRating: number;
  };
}

export const behaviorsAdapter = createEntityAdapter<IBehavior>({
  sortComparer: sortByFields('name')
});

export interface State extends EntityState<IBehavior> {
  metrics: BehaviorMetricsDict;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = behaviorsAdapter.getInitialState({
  metrics: {},
  loading: false,
  loaded: false,
  error: null
});
