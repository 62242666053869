import { MeasurementId } from '../lookups/measurements.lookup';
import { AmountSegment, isAmountSegmentArray } from '../types';
import { CONCENTRATION_MEASUREMENTS } from './constants';
import { parseAmountString } from './parse-amount-string';
import { sumAmountSegments } from './sum-amount-segments';

/**
 * Converts the string value for dose, strength, or amount to a number
 * that can be used for calculation purposes. Measurement is required
 * to determine Supported formats include
 *
 * Combination Formats: 5/300, 5-300;
 * Percent Format: 6%
 */

export function calculateAmount(
  amount: string,
  measurementId: MeasurementId | undefined | null
): number;

export function calculateAmount(
  amount: AmountSegment[],
  measurementId: MeasurementId | undefined | null
): number;

export function calculateAmount(
  amount: null,
  measurementId: MeasurementId | undefined | null
): null;

export function calculateAmount(
  amount: string | AmountSegment[] | null,
  measurementId: MeasurementId | undefined | null
): number | null {
  if (!amount) {
    return null;
  }

  let amountSegments: AmountSegment[];

  if (!isAmountSegmentArray(amount)) {
    amountSegments = parseAmountString(amount);
  } else {
    amountSegments = amount;
  }

  const isConcentration =
    measurementId && CONCENTRATION_MEASUREMENTS.includes(measurementId);

  if (!isConcentration) {
    return sumAmountSegments(amountSegments);
  }

  const lastSegment = amountSegments.at(-1);

  if (typeof lastSegment === 'object') {
    return Number.parseFloat(lastSegment.amount);
  }

  return null;
}
