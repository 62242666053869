import { Update } from '$/models';
import { IOxygenLog } from '$shared/services/oxygen-log';
import { createActionGroup, props } from '@ngrx/store';

export const OxygenLogFormPageActions = createActionGroup({
  source: 'Oxygen Log Form Page',
  events: {
    'Create Oxygen Log': props<{ oxygenLog: IOxygenLog }>(),
    'Update Oxygen Log': props<Update<IOxygenLog>>()
  }
});
