import { OverlayService } from './index';
import { AlcPromptOptions } from './types';

export async function prompt(
  this: OverlayService,
  { title, message, config, submitText, size = 'sm' }: AlcPromptOptions
) {
  // This was added to prevent a circular dependency error as the prompt modal
  // imports the overlay service and this method from the overlay service
  // imports the prompt modal.
  const AlcPromptModal = await import(
    '$/app/shared/pages/modals/prompt/prompt.modal'
  ).then((m) => m.AlcPromptModal);

  const result = await this.getModalResult({
    component: AlcPromptModal,
    componentProps: {
      title,
      message,
      config,
      submitText
    },
    size,
    cssClass: 'stacked'
  });

  if (result) {
    return result.value;
  }
}
