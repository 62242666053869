import {
  animate,
  query,
  stagger,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AnimationTimings } from './animation-timings';

export const expansionAnimation = trigger('expansion', [
  state('closed', style({ height: '0px', opacity: 0 })),
  state('opened', style({ height: '*', opacity: 1 })),
  transition(
    ':enter',
    [
      style({ height: '{{startHeight}}px', opacity: 0 }),
      animate(
        AnimationTimings.EXPANSION_PANEL_ANIMATION_TIMING,
        style({ height: '*', opacity: 1 })
      )
    ],
    { params: { startHeight: 0 } }
  ),
  transition(
    ':leave',
    animate(
      AnimationTimings.EXPANSION_PANEL_ANIMATION_TIMING,
      style({ height: '0px', opacity: 0 })
    )
  )
]);

export const addRemoveListItemsAnimation = trigger('addRemoveListItems', [
  transition('* <=> *', [
    query(':self', [style({ 'box-shadow': 'none' })]),
    query(
      ':enter',
      [
        style({ opacity: 0, scale: 0.7 }),
        stagger(50, [animate('225ms ease-in', style({ opacity: 1, scale: 1 }))])
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ opacity: 1, scale: 1 }),
        stagger(-50, [
          animate('225ms ease-in', style({ opacity: 0, scale: 0.7 }))
        ])
      ],
      { optional: true }
    ),
    query(':self', [style({ 'box-shadow': '*' })])
  ])
]);

export const indicatorRotateAnimation = (deg: number) => {
  return trigger('indicatorRotate', [
    state('false', style({ transform: 'rotate(0deg)' })),
    state('true', style({ transform: `rotate(${deg}deg)` })),
    transition(
      'false <=> true',
      animate(AnimationTimings.EXPANSION_PANEL_ANIMATION_TIMING)
    )
  ]);
};

export const expandInOutAnimation = ({
  name = 'expandInOut',
  side = 'top'
}: {
  name?: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
} = {}) => {
  const dimension = side === 'top' || side === 'bottom' ? 'height' : 'width';

  return trigger(name, [
    transition(':enter', [
      style({ [dimension]: '0px', opacity: 0 }),
      animate(
        AnimationTimings.EXPANSION_PANEL_ANIMATION_TIMING,
        style({ [dimension]: '*', opacity: 1 })
      )
    ]),
    transition(':leave', [
      style({ [dimension]: '*', opacity: 1 }),
      animate(
        AnimationTimings.EXPANSION_PANEL_ANIMATION_TIMING,
        style({ [dimension]: '0px', opacity: 0 })
      )
    ])
  ]);
};

export const collapseAnimation = trigger('collapse', [
  state('opened', style({ height: '*', opacity: 1 })),
  transition(
    ':leave',
    animate(
      AnimationTimings.EXPANSION_PANEL_ANIMATION_TIMING,
      style({ height: '0px', opacity: 0 })
    )
  )
]);

export const fadeAnimation = trigger('fade', [
  state('hide', style({ opacity: 0 })),
  state('show', style({ opacity: 1 })),
  transition('show <=> hide', [animate(AnimationTimings.FADE_ANIMATION_TIMING)])
]);
