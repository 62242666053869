import { IFacilityUser } from '$shared/services/facility-user.schema';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FacilityUsersGeneralActions = createActionGroup({
  source: 'Facility Users General',
  events: {
    'Clear Facility Users': emptyProps(),
    'Add Facility Users': props<{ facilityUsers: IFacilityUser[] }>()
  }
});
