import { FeathersService } from '$/app/services/feathers.service';
import { FacilitySettingsWsActions } from '$/app/store/facility-settings/actions';
import { toUpdated } from '$/app/utils';
import { Params, Update } from '$/models';
import { IFacilitySetting } from '$shared/facility-settings/types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FacilitySettingsApiService {
  private facilitySettingsService: Service<IFacilitySetting>;

  constructor(feathers: FeathersService, store: Store) {
    this.facilitySettingsService = feathers.client.service('facility-settings');

    this.facilitySettingsService.on('patched', (facilitySetting) => {
      facilitySetting = toUpdated(facilitySetting);
      store.dispatch(
        FacilitySettingsWsActions.facilitySettingsPatched({
          facilitySetting: facilitySetting?.changes
            ? facilitySetting.changes
            : facilitySetting
        })
      );
    });
  }

  patch(
    id: string,
    facilitySetting: Partial<IFacilitySetting>,
    params: Params = { query: {} }
  ): Observable<Update<IFacilitySetting>> {
    const promise = this.facilitySettingsService.patch(
      id,
      facilitySetting,
      params
    );

    return from(promise).pipe(map(toUpdated));
  }
}
