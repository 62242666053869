import { Params } from '$/models';
import { createAction } from '@ngrx/store';

const source = '[Facility User Select Component]';

export const loadFacilityUsers = createAction(
  `${source} Load Facility Users`,
  (params: Params = { query: {} }) => ({ params })
);

export const FacilityUserSelectComponentActions = {
  loadFacilityUsers
};
