import { Pipe, PipeTransform } from '@angular/core';
import { isString, upperFirst } from 'lodash';

interface HasNames {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

interface IOptions {
  includeMiddle?: boolean;
  nullSymbol?: string;
}

@Pipe({
  name: 'personName',
  standalone: true
})
export class AlcPersonNamePipe implements PipeTransform {
  transform(
    value: HasNames,
    { includeMiddle, nullSymbol }: IOptions = {}
  ): string {
    if (!nullSymbol && !value) {
      return '';
    }

    return [
      value?.firstName ?? nullSymbol,
      includeMiddle ? (value?.middleName ?? nullSymbol) : null,
      value?.lastName ?? nullSymbol
    ]
      .filter(isString)
      .map((name) => upperFirst(name.trim()))
      .join(' ');
  }
}
