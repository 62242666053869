import { ActivatedRouteSnapshot } from '@angular/router';

/**
 * Constructs an array containing all url path segments starting from
 * the root route and traversing down to the activated route.
 *
 * This array can then be used in the router.navigate() method
 */
export function getUrlSegmentsFromRoot(
  routeSnapshot: ActivatedRouteSnapshot
): string[] {
  return routeSnapshot.pathFromRoot.reduce((acc, curr) => {
    curr.url.forEach((url) => {
      acc.push(url.path);
    });

    return acc;
  }, []);
}

export function getAllRouteParams(parentRoute: ActivatedRouteSnapshot): any {
  const params = {};

  while (parentRoute.firstChild) {
    parentRoute = parentRoute.firstChild;
    Object.assign(params, parentRoute.params);
  }

  return params;
}
