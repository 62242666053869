import { getDateRange } from '$/app/utils';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { MarSummaryFilters } from '../mar-summary.vmodel';

export const defaultFilters = (ft: FacilityTime): MarSummaryFilters => ({
  statuses: ['active'],
  dateRange: getDateRange(null, ft, 'past30Days')
});

export interface State {
  filters: MarSummaryFilters;
}

export const initialState: State = {
  filters: null
};
