import { Table } from '$/models/data/base';
import { PhoneTypeId } from '$shared/lookups/phone-types.lookup';

export class FacilityUserPhone extends Table {
  orgId?: string;
  facilityId?: string;
  facilityUserId: string;
  label: string;
  number: string;
  type: PhoneTypeId;
  isPrimary: boolean;
}
