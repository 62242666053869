import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { UrineLogsPageActions } from '$/app/pages/notebook/shared/logs/urine-log/urine-log.actions';
import { EffectHelpersService } from '$/app/services';
import { UrineLogFormPageActions } from '$/app/shared/pages/forms/log-forms/urine-log-form/urine-log-form.actions';
import { UrineLogsApiActions } from '$/app/store/urine-logs/urine-logs.actions';
import { UrineLogsApiService } from '$/app/store/urine-logs/urine-logs.service';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { IUrineLog } from '$shared/services/urine-log';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';

@Injectable()
export class UrineLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly urineLogsService = inject(UrineLogsApiService);

  loadUrineLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UrineLogsPageActions.loadUrineLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.urineLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('urineLogs', logs);
            responseData.setPrimaryAction(
              UrineLogsApiActions.loadUrineLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  getUrineLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UrineLogsPageActions.getUrineLogs,
        LogsPageActions.getUrineLogs,
        GivePrnFormActions.getUrineLogs
      ),
      switchMap((action) => {
        return this.urineLogsService.getAll(action.params).pipe(
          getAllPages(this.urineLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('urineLogs', logs);
            responseData.setPrimaryAction(
              UrineLogsApiActions.getUrineLogsSuccess
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createUrineLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UrineLogFormPageActions.createUrineLog),
      exhaustMap((action) => {
        return this.urineLogsService.create(action.urineLog).pipe(
          tap((data: IUrineLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((urineLog: IUrineLog) =>
            UrineLogsApiActions.createUrineLogSuccess({
              urineLog
            })
          ),
          catchError((error) =>
            of(
              UrineLogsApiActions.createUrineLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateUrineLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UrineLogFormPageActions.updateUrineLog),
      switchMap((action) => {
        return this.urineLogsService.patch(action.id, action.changes).pipe(
          tap((data: IUrineLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((urineLog: IUrineLog) => {
            return UrineLogsApiActions.updateUrineLogSuccess({
              urineLog
            });
          }),
          catchError((error) => {
            return of(
              UrineLogsApiActions.updateUrineLogFail({
                error
              })
            );
          })
        );
      })
    );
  });

  deleteUrineLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UrineLogsPageActions.deleteUrineLog,
        ResidentMedicationTasksPageActions.deleteUrineLog,
        MissedMedicationsPageActions.deleteUrineLog,
        RoutineMarDetailPageActions.deleteUrineLog,
        RoutineMarListPageActions.deleteUrineLog,
        GivePrnFormActions.deleteUrineLog,
        CompleteCareTaskModalActions.deleteUrineLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete urine log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.urineLogsService.delete(action.id, action.params);
        },
        onSuccess: (urineLog: IUrineLog) =>
          UrineLogsApiActions.deleteUrineLogSuccess({
            id: urineLog.id
          }),
        onError: (error) => UrineLogsApiActions.deleteUrineLogFail({ error })
      })
    );
  });
}
