import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserListPageActions = createActionGroup({
  source: 'Facility User List Page',
  events: {
    'Load Facility Users': (params: Params = { query: {} }) => ({ params }),
    'Resend User Invite': props<{ id: string; params: Params }>()
  }
});
