import * as AcknowledgementsSelectors from '$/app/store/acknowledgements/acknowledgements.selectors';
import * as AnnouncementsSelectors from '$/app/store/announcements/announcements.selectors';
import * as FacilityUsersSelectors from '$/app/store/facility-users/facility-users.selectors';
import { UserSelectors } from '$/app/store/user/user.selectors';
import { Announcement, AnnouncementCard } from '$/models';
import { createSelector } from '@ngrx/store';
import { filter, map, orderBy } from 'lodash';

export const selectUnread = createSelector(
  AnnouncementsSelectors.selectAll,
  AcknowledgementsSelectors.selectAcknowledgementsViaCompoundKey,
  UserSelectors.selectFacilityUserId,
  (announcements, ackEntities, facUserId): Announcement[] => {
    const unreadAnnouncements = filter(announcements || [], (announcement) => {
      const compoundKey = announcement.id + facUserId;
      const userAcknowledgement = ackEntities[compoundKey];

      const isRead = userAcknowledgement?.isRead || false;
      const canConfirm = userAcknowledgement ? true : false;

      return !isRead && canConfirm && announcement.createdBy !== facUserId;
    });

    return orderBy(unreadAnnouncements, 'createdAt', 'desc');
  }
);

export const selectAnnouncementsCards = createSelector(
  selectUnread,
  FacilityUsersSelectors.selectEntities,
  (announcements, facilityUsers): AnnouncementCard[] =>
    map(announcements || [], (announcement) => {
      const author = facilityUsers[announcement.createdBy];

      return {
        id: announcement.id,
        subject: announcement.subject,
        body: announcement.body,
        date: announcement.createdAt,
        createdBy: `${author.firstName} ${author.lastName}`,
        isRead: false,
        canConfirm: true
      };
    })
);

export const UnreadAnnouncementSelectors = {
  selectUnread,
  selectAnnouncementsCards
};
