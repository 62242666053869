export function convertCentimetersToInches(
  centimeters: string | number
): number {
  centimeters =
    typeof centimeters === 'string' ? parseFloat(centimeters) : centimeters;

  const CENTIMETERS_IN_INCH = 2.54;

  const inches = centimeters / CENTIMETERS_IN_INCH;
  return inches;
}
