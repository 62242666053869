import { Table } from '$/models/data/base';
import { LogTypeId } from '$shared/lookups/log-details.lookup';

export class CarePlanItem extends Table {
  facilityId?: string;
  orgId?: string;
  residentId?: string;
  residentContactId?: string;
  medicalProfessionalId?: string;
  carePlanIssuerId?: string;

  name?: string;
  instructions?: string;
  isActive?: boolean;
  logDependency?: LogTypeId;

  constructor(carePlanItem: CarePlanItem) {
    super();
    Object.assign(this, carePlanItem);
  }
}
