import { Update } from '$/models';
import { IFoodLog } from '$shared/services/food-log';
import { createActionGroup, props } from '@ngrx/store';

export const FoodLogFormPageActions = createActionGroup({
  source: 'Food Log Form Page',
  events: {
    'Create Food Log': props<{ foodLog: IFoodLog }>(),
    'Update Food Log': props<Update<IFoodLog>>()
  }
});
