import { sortByFields } from '$/app/utils';
import { ILibraryDocumentTemplatePacket, IPaginationInfo } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const libraryDocumentTemplatePacketsAdapter =
  createEntityAdapter<ILibraryDocumentTemplatePacket>({
    sortComparer: sortByFields('name')
  });

export interface State extends EntityState<ILibraryDocumentTemplatePacket> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  libraryDocumentTemplatePacketsAdapter.getInitialState({
    pagination: {},
    loading: false,
    loaded: false,
    error: null
  });
