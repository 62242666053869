import { FeathersService } from '$/app/services/feathers.service';
import { DocumentPacketDocumentsWsActions } from '$/app/store/document-packet-documents/document-packet-documents.actions';
import { IDocumentPacketDocument } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class DocumentPacketDocumentsApiService extends AbstractApiService<IDocumentPacketDocument> {
  constructor(feathers: FeathersService, store: Store) {
    super('document-packet-documents', feathers, store, {
      entityName: 'documentPacketDocument',
      created: DocumentPacketDocumentsWsActions.documentPacketDocumentCreated,
      patched: DocumentPacketDocumentsWsActions.documentPacketDocumentPatched,
      removed: DocumentPacketDocumentsWsActions.documentPacketDocumentRemoved
    });
  }
}
