import { FacilityUserPhone } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearFacilityUserPhones = createAction(
  '[FacilityUserPhones] Clear FacilityUserPhones'
);

export const addFacilityUserPhones = createAction(
  '[FacilityUser Phones General Action ] Add FacilityUser Phones',
  props<{ facilityUserPhones: FacilityUserPhone[] }>()
);
