import { createStoreActions } from '$/lib/create-store-actions';
import { IMedicationDestruction } from '$shared/services/medication-destructions.schema';
import { props } from '@ngrx/store';

export const {
  MedicationDestructionsApiActions,
  MedicationDestructionsWsActions,
  MedicationDestructionsGeneralActions
} = createStoreActions(
  'Medication Destruction',
  'Medication Destructions',
  props<{ medicationDestruction: IMedicationDestruction }>(),
  props<{ medicationDestructions: IMedicationDestruction[] }>()
);
