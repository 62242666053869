import { FacilitiesEffects } from '$/app/store/facilities/facilities.effects';
import { facilitiesFeature } from '$/app/store/facilities/facilities.reducer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(facilitiesFeature),
    EffectsModule.forFeature([FacilitiesEffects])
  ],
  providers: [FacilitiesEffects]
})
export class FacilitiesStoreModule {}
