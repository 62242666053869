import { Symptom } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const symptomsAdapter = createEntityAdapter<Symptom>();

export interface State extends EntityState<Symptom> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = symptomsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
