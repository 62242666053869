import { FeathersService } from '$/app/services/feathers.service';
import { FacilitiesWsActions } from '$/app/store';
import { getPaginatedData } from '$/app/utils';
import { Facility, Paginated, Params } from '$/models';
import { inject, Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FacilitiesApiService {
  private readonly store = inject(Store);
  private readonly feathers = inject(FeathersService);

  name: string;
  facilitiesService: Service<Facility>;

  constructor() {
    this.facilitiesService = this.feathers.client.service('facilities');

    this.facilitiesService.on('created', (facility) => {
      this.store.dispatch(FacilitiesWsActions.facilitiesCreated({ facility }));
    });

    this.facilitiesService.on('patched', (facility) => {
      this.store.dispatch(FacilitiesWsActions.facilitiesPatched({ facility }));
    });

    this.facilitiesService.on('removed', (facility) => {
      this.store.dispatch(
        FacilitiesWsActions.facilitiesRemoved({ id: facility.id })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<Facility[]> {
    const promise = this.facilitiesService.find(params) as Promise<
      Paginated<Facility>
    >;

    return from(promise).pipe(map(getPaginatedData));
  }

  get(id: string, params: Params = { query: {} }) {
    const promise = this.facilitiesService.get(id, params);
    return from(promise);
  }

  patch(
    id: string,
    facility: Partial<Facility>,
    params: Params = { query: {} }
  ): Observable<Facility> {
    const promise = this.facilitiesService.patch(id, facility, params);
    return from(promise);
  }
}
