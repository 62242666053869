import { SeizureLogSymptom } from '$/models';
import { Dictionary } from '$shared/types';
import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import { seizureLogSymptomsFeature } from './seizure-log-symptoms.reducer';
import { seizureLogSymptomsAdapter } from './seizure-log-symptoms.state';

export const {
  selectSeizureLogSymptomsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = seizureLogSymptomsFeature;

export const { selectAll, selectTotal } =
  seizureLogSymptomsAdapter.getSelectors(selectSeizureLogSymptomsState);

export const selectSymptomsBySeizureLogId = createSelector(
  selectAll,
  (seizureLogSymptoms): Dictionary<SeizureLogSymptom[]> => {
    return groupBy(seizureLogSymptoms, 'seizureLogId');
  }
);
