import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SleepLogsEffects } from './sleep-logs.effects';
import { sleepLogsFeature } from './sleep-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(sleepLogsFeature),
    EffectsModule.forFeature([SleepLogsEffects])
  ],
  providers: [SleepLogsEffects]
})
export class SleepLogsStoreModule {}
