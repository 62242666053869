import { BodyCheckObservationsApiService } from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

@Injectable()
export class BodyCheckObservationsEffects {
  private readonly actions$ = inject(Actions);
  private readonly bodyCheckObservationsService = inject(
    BodyCheckObservationsApiService
  );
}
