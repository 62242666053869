import { AlcomyErrorCodes } from '$/config';
import { AlcomyError, AlcomyErrorMessage } from './alcomy-error.class';

/**
 * When a log rule can't be found to satisfy a log's
 * field values
 */
export class NoValidLogRule extends AlcomyError {
  constructor(message: AlcomyErrorMessage, data?: any) {
    super(
      message,
      `${NoValidLogRule.name}`,
      AlcomyErrorCodes.NO_VALID_LOG_RULE,
      data
    );
  }
}
