import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DocumentTemplatePacketTemplatesEffects } from './document-template-packet-templates.effects';
import { documentTemplatePacketTemplatesFeature } from './document-template-packet-templates.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(documentTemplatePacketTemplatesFeature),
    EffectsModule.forFeature([DocumentTemplatePacketTemplatesEffects])
  ],
  providers: [DocumentTemplatePacketTemplatesEffects]
})
export class DocumentTemplatePacketTemplatesStoreModule {}
