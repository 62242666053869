import { FeathersService } from '$/app/services/feathers.service';
import { LibraryCollectionDocumentTemplatesWsActions } from '$/app/store/library-collection-document-templates';
import { ILibraryCollectionDocumentTemplate } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class LibraryCollectionDocumentTemplatesApiService extends AbstractApiService<ILibraryCollectionDocumentTemplate> {
  constructor(feathers: FeathersService, store: Store) {
    super('library-collection-document-templates', feathers, store, {
      entityName: 'libraryCollectionDocumentTemplate',
      created:
        LibraryCollectionDocumentTemplatesWsActions.libraryCollectionDocumentTemplateCreated,
      patched:
        LibraryCollectionDocumentTemplatesWsActions.libraryCollectionDocumentTemplatePatched,
      removed:
        LibraryCollectionDocumentTemplatesWsActions.libraryCollectionDocumentTemplateRemoved
    });
  }
}
