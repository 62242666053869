import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const BehaviorLogsPageActions = createActionGroup({
  source: 'Resident Behavior Log Page',
  events: {
    'Load Behavior Logs': (params: Params) => ({ params }),
    'Get Behavior Logs': (params: Params) => ({
      params
    }),
    'Get Behaviors': (params: Params) => ({
      params
    }),
    'Delete Behavior Log': props<{ id: string; params?: Params }>()
  }
});
