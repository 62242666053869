import { FeathersService } from '$/app/services/feathers.service';
import { DocumentRelationsWsActions } from '$/app/store/document-relations/document-relations.actions';
import { IDocumentRelation } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class DocumentRelationsApiService extends AbstractApiService<IDocumentRelation> {
  constructor(feathers: FeathersService, store: Store) {
    super('document-relations', feathers, store, {
      entityName: 'documentRelation',
      created: DocumentRelationsWsActions.documentRelationCreated,
      patched: DocumentRelationsWsActions.documentRelationPatched,
      removed: DocumentRelationsWsActions.documentRelationRemoved
    });
  }
}
