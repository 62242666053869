import { schema } from 'normalizr';
import { Dictionary } from './types';

export const acknowledgementSchema = new schema.Entity('acknowledgements');
export const allergySchema = new schema.Entity('allergies');
export const announcementRecipientSchema = new schema.Entity(
  'announcementRecipients'
);
export const announcementReplySchema = new schema.Entity('announcementReplies');
export const announcementSchema = new schema.Entity('announcements');
export const appointmentSchema = new schema.Entity('appointments');
export const behaviorLogSchema = new schema.Entity('behaviorLogs');
export const behaviorSchema = new schema.Entity('behaviors');
export const bloodPressureLogSchema = new schema.Entity('bloodPressureLogs');
export const bloodSugarLogSchema = new schema.Entity('bloodSugarLogs');
export const bmLogSchema = new schema.Entity('bmLogs');
export const bodyCheckLogSchema = new schema.Entity('bodyCheckLogs');
export const bodyCheckObservationSchema = new schema.Entity(
  'bodyCheckObservations'
);
export const carePlanItemScheduleSchema = new schema.Entity(
  'carePlanItemSchedules'
);
export const carePlanItemSchema = new schema.Entity('carePlanItems');
export const careTaskSchema = new schema.Entity('careTasks');
export const destructionMethodSchema = new schema.Entity('destructionMethods');
export const documentPacketDocumentSchema = new schema.Entity(
  'documentPacketDocuments'
);
export const documentPacketSchema = new schema.Entity('documentPackets');
export const documentTemplatePacketTemplateSchema = new schema.Entity(
  'documentTemplatePacketTemplates'
);
export const documentTemplatePacketSchema = new schema.Entity(
  'documentTemplatePackets'
);
export const documentParticipantSchema = new schema.Entity(
  'documentParticipants'
);
export const documentRelationSchema = new schema.Entity('documentRelations');
export const documentTemplateSchema = new schema.Entity('documentTemplates');
export const documentSchema = new schema.Entity('documents');
export const diagnosisSchema = new schema.Entity('diagnoses');
export const facilityPhoneSchema = new schema.Entity('facilityPhones');
export const facilitySchema = new schema.Entity('facilities');
export const facilitySettingSchema = new schema.Entity('facilitySettings');
export const facilityUserGroupMemberSchema = new schema.Entity(
  'facilityUserGroupMembers'
);
export const facilityUserGroupSchema = new schema.Entity('facilityUserGroups');
export const facilityUserPhoneSchema = new schema.Entity('facilityUserPhones');
export const facilityUserRoleSchema = new schema.Entity('facilityUserRoles');
export const facilityUserSchema = new schema.Entity('facilityUsers');
export const facilityUserSupervisor = new schema.Entity('facilityUsers');
export const faxSchema = new schema.Entity('faxes');
export const foodLogSchema = new schema.Entity('foodLogs');
export const fileSchema = new schema.Entity('files');
export const incidentReportResidentSchema = new schema.Entity(
  'incidentReportResidents'
);
export const incidentReportSchema = new schema.Entity('incidentReports');
export const initializationDataSchema = new schema.Entity('initializationData');
export const installedLibraryCollectionSchema = new schema.Entity(
  'installedLibraryCollections'
);
export const libraryCollectionDocumentTemplatePacketSchema = new schema.Entity(
  'libraryCollectionDocumentTemplatePackets'
);
export const libraryCollectionDocumentTemplateSchema = new schema.Entity(
  'libraryCollectionDocumentTemplates'
);
export const libraryCollectionSchema = new schema.Entity('libraryCollections');
export const libraryDocumentTemplatePacketTemplateSchema = new schema.Entity(
  'libraryDocumentTemplatePacketTemplates'
);
export const libraryDocumentTemplatePacketSchema = new schema.Entity(
  'libraryDocumentTemplatePackets'
);
export const libraryDocumentTemplateSchema = new schema.Entity(
  'libraryDocumentTemplates'
);
export const medicationDestructionItemSchema = new schema.Entity(
  'medicationDestructionItems'
);
export const medicationDestructionSchema = new schema.Entity(
  'medicationDestructions'
);

export const medicalEventSchema = new schema.Entity('medicalEvents');
export const medicalHistorySchema = new schema.Entity('medicalHistories');
export const medicalProfessionalPhoneSchema = new schema.Entity(
  'medicalProfessionalPhones'
);
export const medicalProfessionalSchema = new schema.Entity(
  'medicalProfessionals'
);
export const medicationInventoryItemCountSchema = new schema.Entity(
  'medicationInventoryItemCounts'
);
export const medicationInventoryItemSchema = new schema.Entity(
  'medicationInventoryItems'
);
export const medicationInventoryMovementSchema = new schema.Entity(
  'medicationInventoryMovements'
);
export const medicationInventorySchema = new schema.Entity(
  'medicationInventories'
);
export const medicationMarSchema = new schema.Entity('medicationMars');
export const medicationOrderItemSchema = new schema.Entity(
  'medicationOrderItems'
);
export const medicationOrderSchema = new schema.Entity('medicationOrders');
export const medicationScheduleSchema = new schema.Entity(
  'medicationSchedules'
);
export const medicationSchema = new schema.Entity('medications');
export const medicationTaskAmountSchema = new schema.Entity(
  'medicationTaskAmounts'
);
export const medicationTaskSchema = new schema.Entity('medicationTasks');
export const moodLogSchema = new schema.Entity('moodLogs');
export const narcoticCountInventoryItemSchema = new schema.Entity(
  'narcoticCountInventoryItems'
);
export const narcoticCountSchema = new schema.Entity('narcoticCounts');
export const notificationSchema = new schema.Entity('notifications');
export const omittedAmountSchema = new schema.Entity('omittedAmounts');
export const omittedMedicationSchema = new schema.Entity('omittedMedications');
export const organizationSchema = new schema.Entity('organizations');
export const oxygenLogSchema = new schema.Entity('oxygenLogs');
export const pharmacyPhoneSchema = new schema.Entity('pharmacyPhones');
export const pharmacySchema = new schema.Entity('pharmacies');
export const positionLogSchema = new schema.Entity('positionLogs');
export const prnAmountSchema = new schema.Entity('prnAmounts');
export const prnSchema = new schema.Entity('prns');
export const residentContactPhoneSchema = new schema.Entity(
  'residentContactPhones'
);
export const residentContactSchema = new schema.Entity('residentContacts');
export const residentInsuranceSchema = new schema.Entity('residentInsurances');
export const residentLogTypeSchema = new schema.Entity('residentLogTypes');
export const residentMedicalProfessionalSchema = new schema.Entity(
  'residentMedicalProfessionals'
);
export const residentGroupMemberSchema = new schema.Entity(
  'residentGroupMembers'
);
export const residentGroupSchema = new schema.Entity('residentGroups');
export const residentSchema = new schema.Entity('residents');
export const residentReportConfigSchema = new schema.Entity(
  'residentReportConfigs'
);
export const roleSchema = new schema.Entity('roles');
export const rolePermissionSchema = new schema.Entity('rolePermissions');
export const roomSchema = new schema.Entity('rooms');
export const seizureLogSymptomSchema = new schema.Entity('seizureLogSymptoms');
export const seizureLogSchema = new schema.Entity('seizureLogs');
export const serviceSchema = new schema.Entity('services');
export const shiftNoteSchema = new schema.Entity('shiftNotes');
export const showerLogSchema = new schema.Entity('showerLogs');
export const sleepLogSchema = new schema.Entity('sleepLogs');
export const symptomSchema = new schema.Entity('symptoms');
export const temperatureLogSchema = new schema.Entity('temperatureLogs');
export const urineLogSchema = new schema.Entity('urineLogs');
export const userSchema = new schema.Entity('users');
export const waterLogSchema = new schema.Entity('waterLogs');
export const weightLogSchema = new schema.Entity('weightLogs');

/* Additional Definitions For Circular dependencies */

// RELATIONS

export const acknowledgementRelations = {
  facilityUser: facilityUserSchema,
  announcement: announcementSchema
};
export const announcementRecipientRelations = {
  announcement: announcementSchema,
  facilityUserGroup: facilityUserGroupSchema,
  facilityUser: facilityUserSchema
};
export const announcementRelations = {
  facilityUser: facilityUserSchema,
  recipients: [announcementRecipientSchema],
  groupRecipients: [facilityUserGroupSchema],
  userRecipients: [facilityUserSchema],
  acknowledgements: [acknowledgementSchema],
  replies: [announcementReplySchema]
};
export const announcementReplyRelations = {
  announcement: announcementSchema,
  facilityUser: facilityUserSchema
};
export const appointmentRelations = {
  resident: residentSchema
};
export const behaviorLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema,
  behavior: behaviorSchema
};
export const behaviorRelations = {
  behaviorLogs: [behaviorLogSchema]
};
export const bloodPressureLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const bloodSugarLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const bmLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const bodyCheckLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  observations: [bodyCheckObservationSchema],
  prn: prnSchema
};
export const bodyCheckObservationRelations = {
  bodyCheckLog: bodyCheckLogSchema,
  facilityUser: facilityUserSchema
};
export const carePlanItemRelations = {
  carePlanItemSchedules: [carePlanItemScheduleSchema]
};
export const carePlanItemScheduleRelations = { careTasks: [careTaskSchema] };
export const careTaskRelations = {
  resident: residentSchema,
  carePlanItem: carePlanItemSchema,
  carePlanItemSchedule: carePlanItemScheduleSchema,
  behaviorLog: behaviorLogSchema,
  bloodPressureLog: bloodPressureLogSchema,
  bloodSugarLog: bloodSugarLogSchema,
  bodyCheckLog: bodyCheckLogSchema,
  bmLog: bmLogSchema,
  foodLog: foodLogSchema,
  moodLog: moodLogSchema,
  oxygenLog: oxygenLogSchema,
  positionLog: positionLogSchema,
  seizureLog: seizureLogSchema,
  showerLog: showerLogSchema,
  sleepLog: sleepLogSchema,
  temperatureLog: temperatureLogSchema,
  urineLog: urineLogSchema,
  waterLog: waterLogSchema,
  weightLog: weightLogSchema,
  shiftNote: shiftNoteSchema
};
export const documentPacketDocumentRelations = {
  packet: documentPacketSchema,
  document: documentSchema
};
export const documentPacketRelations = {
  documentTemplatePacket: documentTemplatePacketSchema,
  documentPacketDocuments: [documentPacketDocumentSchema],
  documents: [documentSchema]
};
export const documentTemplatePacketTemplateRelations = {
  packet: documentTemplatePacketSchema,
  template: documentTemplateSchema
};
export const documentTemplatePacketRelations = {
  libraryDocumentTemplatePacket: libraryDocumentTemplatePacketSchema,
  packetTemplates: [documentTemplatePacketTemplateSchema],
  templates: [documentTemplateSchema]
};
export const documentParticipantRelations = {
  document: documentSchema,
  facilityUser: facilityUserSchema,
  facility: facilitySchema
};
export const documentRelationRelations = {
  document: documentSchema,
  incidentReport: incidentReportSchema
};
export const documentTemplateRelations = {
  libraryDocumentTemplate: libraryDocumentTemplateSchema,
  packetTemplates: [documentTemplatePacketTemplateSchema],
  packets: [documentTemplatePacketSchema],
  file: fileSchema,
  documents: [documentSchema]
};
export const documentRelations = {
  facility: facilitySchema,
  resident: residentSchema,
  facilityUser: facilityUserSchema,
  template: documentTemplateSchema,
  file: fileSchema,
  participants: [documentParticipantSchema],
  history: [documentSchema]
};
export const facilityRelations = {
  rooms: [roomSchema],
  residents: [residentSchema],
  pharmacies: [pharmacySchema],
  medicalProfessionals: [medicalProfessionalSchema],
  settings: [facilitySettingSchema],
  phoneNumbers: [facilityPhoneSchema],
  installedLibraryCollections: [installedLibraryCollectionSchema]
};
export const facilitySettingRelations = {};
export const facilityUserGroupMemberRelations = {
  facilityUserGroup: facilityUserGroupSchema,
  facilityUser: facilityUserSchema
};
export const facilityUserGroupRelations = {
  members: [facilityUserGroupMemberSchema]
};
export const facilityUserRoleRelations = {
  facilityRole: roleSchema
};
export const facilityUserRelations = {
  facilityUserPhones: [facilityUserPhoneSchema],
  facilityUserGroupMembers: [facilityUserGroupMemberSchema],
  roles: [facilityUserRoleSchema],
  supervisor: [facilityUserSupervisor],
  announcements: [announcementSchema],
  acknowledgements: [acknowledgementSchema],
  facility: facilitySchema,
  organization: organizationSchema
};
export const faxRelations = {
  medicationOrder: medicationOrderSchema,
  file: fileSchema
};
export const fileRelations = {
  facility: facilitySchema
};
export const foodLogRelations = {
  facilityUser: facilityUserSchema,
  recorder: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const incidentReportResidentRelations = {
  incidentReport: incidentReportSchema,
  resident: residentSchema
};
export const incidentReportRelations = {
  recorder: facilityUserSchema,
  approver: facilityUserSchema,
  facilityUser: facilityUserSchema,
  documentRelations: [documentRelationSchema],
  documents: [documentSchema],
  incidentReportResidents: [incidentReportResidentSchema],
  residents: [residentSchema]
};
export const initializationDataRelations = {
  organizations: [organizationSchema],
  facilities: [facilitySchema],
  facilityUsers: [facilityUserSchema],
  facilityUserGroups: [facilityUserGroupSchema],
  rooms: [roomSchema],
  facilitySettings: [facilitySettingSchema],
  pharmacies: [pharmacySchema],
  residentGroupMembers: [residentGroupMemberSchema],
  symptoms: [symptomSchema],
  destructionMethods: [destructionMethodSchema],
  announcements: [announcementSchema],
  acknowledgements: [acknowledgementSchema],
  notifications: [notificationSchema]
};
export const installedLibraryCollectionRelations = {
  libraryCollection: libraryCollectionSchema,
  facility: facilitySchema,
  organization: organizationSchema
};
export const libraryCollectionDocumentTemplatePacketRelations = {
  collection: libraryCollectionSchema,
  documentTemplatePacket: libraryDocumentTemplatePacketSchema
};
export const libraryCollectionDocumentTemplateRelations = {
  collection: libraryCollectionSchema,
  documentTemplate: libraryDocumentTemplateSchema
};
export const libraryCollectionRelations = {
  libraryDocumentTemplatePackets: [libraryDocumentTemplatePacketSchema],
  collectionDocumentTemplatePackets: [
    libraryCollectionDocumentTemplatePacketSchema
  ],
  libraryDocumentTemplates: [libraryDocumentTemplateSchema],
  collectionDocumentTemplates: [libraryCollectionDocumentTemplateSchema]
};
export const libraryDocumentTemplatePacketTemplateRelations = {
  packet: libraryDocumentTemplatePacketSchema,
  template: libraryDocumentTemplateSchema
};
export const libraryDocumentTemplatePacketRelations = {
  collections: [libraryCollectionSchema],
  collectionDocumentTemplatePackets: [
    libraryCollectionDocumentTemplatePacketSchema
  ],
  packetTemplates: [libraryDocumentTemplatePacketTemplateSchema],
  templates: [libraryDocumentTemplateSchema]
};
export const libraryDocumentTemplateRelations = {
  collections: [libraryCollectionSchema],
  collectionDocumentTemplates: [libraryCollectionDocumentTemplateSchema],
  packetTemplates: [libraryDocumentTemplatePacketTemplateSchema],
  packets: [libraryDocumentTemplatePacketSchema],
  file: fileSchema
};
export const medicationDestructionItemRelations = {
  medicationDestruction: medicationDestructionSchema
};
export const medicationDestructionRelations = {
  medicationDestructionItems: [medicationDestructionItemSchema]
};
export const medicalProfessionalRelations = {
  phones: [medicalProfessionalPhoneSchema],
  residents: [residentSchema],
  residentMedicalProfessionals: [residentMedicalProfessionalSchema]
};
export const medicationInventoryItemCountRelations = {
  resident: residentSchema,
  medication: medicationSchema,
  medicationInventoryItem: medicationInventoryItemSchema,
  counter: facilityUserSchema,
  witness: facilityUserSchema,
  resolver: facilityUserSchema
};
export const medicationInventoryItemRelations = {
  medication: medicationSchema,
  medicationInventories: [medicationInventorySchema],
  pharmacy: pharmacySchema,
  prescriber: medicalProfessionalSchema,
  facilityUser: facilityUserSchema,
  counts: [medicationInventoryItemCountSchema],
  medicationTaskAmounts: [medicationTaskAmountSchema],
  prnAmounts: [prnAmountSchema],
  medicationOrderItem: medicationOrderItemSchema,
  medicationDestructionItems: [medicationDestructionItemSchema]
};
export const medicationInventoryMovementRelations = {
  medicationInventoryItem: medicationInventoryItemSchema,
  medicationInventory: medicationInventorySchema
};
export const medicationInventoryRelations = {
  medicationInventoryItems: [medicationInventoryItemSchema]
};
export const medicationMarRelations = {
  medicationTasks: medicationTaskSchema,
  omittedMedication: omittedMedicationSchema,
  facilityUser: facilityUserSchema,
  performer: facilityUserSchema
};
export const medicationOrderItemRelations = {
  medicationOrder: medicationOrderSchema,
  medication: medicationSchema,
  medicationInventoryItem: medicationInventoryItemSchema
};
export const medicationOrderRelations = {
  medicationOrderItems: [medicationOrderItemSchema],
  facilityUser: facilityUserSchema,
  pharmacy: pharmacySchema,
  faxes: [faxSchema],
  file: fileSchema
};
export const medicationRelations = {
  resident: residentSchema,
  schedules: [medicationScheduleSchema],
  inventoryItems: [medicationInventoryItemSchema],
  batchInventoryItems: [medicationInventoryItemSchema],
  tasks: [medicationTaskSchema],
  batchMedicationTasks: [medicationTaskSchema],
  medicationMars: [medicationMarSchema],
  batchMedicationMars: [medicationMarSchema],
  prns: [prnSchema],
  batchPrns: [prnSchema],
  omittedMedications: [omittedMedicationSchema],
  orderItems: [medicationOrderItemSchema],
  batchOmittedMedications: [omittedMedicationSchema]
};
export const medicationTaskAmountRelations = {
  medicationTask: medicationTaskSchema,
  medicationInventoryItem: medicationInventoryItemSchema
};
export const medicationTaskRelations = {
  amounts: [medicationTaskAmountSchema],
  medication: medicationSchema,
  schedule: medicationScheduleSchema,
  inventoryMovements: [medicationInventoryMovementSchema],
  resident: residentSchema,
  mars: [medicationMarSchema],
  behaviorLog: behaviorLogSchema,
  bloodPressureLog: bloodPressureLogSchema,
  bloodSugarLog: bloodSugarLogSchema,
  bodyCheckLog: bodyCheckLogSchema,
  bmLog: bmLogSchema,
  foodLog: foodLogSchema,
  moodLog: moodLogSchema,
  oxygenLog: oxygenLogSchema,
  positionLog: positionLogSchema,
  seizureLog: seizureLogSchema,
  showerLog: showerLogSchema,
  sleepLog: sleepLogSchema,
  temperatureLog: temperatureLogSchema,
  urineLog: urineLogSchema,
  waterLog: waterLogSchema,
  weightLog: weightLogSchema
};
export const moodLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const narcoticCountInventoryItemRelations = {
  witness1: facilityUserSchema,
  witness2: facilityUserSchema,
  medicationInventoryItem: medicationInventoryItemSchema
};
export const narcoticCountRelations = {
  resident: residentSchema,
  medication: medicationSchema,
  narcoticCountInventoryItems: [narcoticCountInventoryItemSchema]
};
export const omittedAmountRelations = {
  omittedMedication: omittedMedicationSchema,
  medicationInventoryItem: medicationInventoryItemSchema
};
export const omittedMedicationRelations = {
  amounts: [omittedAmountSchema],
  medication: medicationSchema,
  task: medicationTaskSchema,
  inventoryMovements: [medicationInventoryMovementSchema]
};
export const organizationRelations = {
  facilities: [facilitySchema],
  installedLibraryCollections: [installedLibraryCollectionSchema]
};
export const oxygenLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const pharmacyRelations = {
  pharmacyPhones: [pharmacyPhoneSchema],
  medicationOrders: [medicationOrderSchema]
};
export const positionLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const prnAmountRelations = {
  prn: prnSchema,
  medicationInventoryItem: medicationInventoryItemSchema
};
export const prnRelations = {
  amounts: [prnAmountSchema],
  medication: medicationSchema,
  resident: residentSchema,
  giver: facilityUserSchema,
  responder: facilityUserSchema,
  behaviorLog: behaviorLogSchema,
  bloodPressureLog: bloodPressureLogSchema,
  bloodSugarLog: bloodSugarLogSchema,
  bodyCheckLog: bodyCheckLogSchema,
  bmLog: bmLogSchema,
  foodLog: foodLogSchema,
  moodLog: moodLogSchema,
  oxygenLog: oxygenLogSchema,
  positionLog: positionLogSchema,
  seizureLog: seizureLogSchema,
  showerLog: showerLogSchema,
  sleepLog: sleepLogSchema,
  temperatureLog: temperatureLogSchema,
  urineLog: urineLogSchema,
  waterLog: waterLogSchema,
  weightLog: weightLogSchema
};
export const residentGroupMemberRelations = {
  residentGroup: residentGroupSchema,
  resident: residentSchema
};
export const residentGroupRelations = {
  members: [residentGroupMemberSchema]
};
export const residentMedicalProfessionalRelations = {
  resident: residentSchema,
  medicalProfessional: medicalProfessionalSchema
};
export const residentRelations = {
  medications: [medicationSchema],
  medicationTasks: [medicationTaskSchema],
  room: roomSchema,
  allergies: [allergySchema],
  diagnoses: [diagnosisSchema],
  contacts: [residentContactSchema],
  insurances: [residentInsuranceSchema],
  medicalEvents: [medicalEventSchema],
  medicalHistories: [medicalHistorySchema],
  carePlanItems: [carePlanItemSchema],
  residentMedicalProfessionals: [residentMedicalProfessionalSchema],
  medicalProfessionals: [medicalProfessionalSchema],
  preferredPharmacy: [pharmacySchema],
  residentGroupMembers: [residentGroupMemberSchema],
  residentLogTypes: [residentLogTypeSchema]
};
export const residentContactRelations = {
  phones: [residentContactPhoneSchema]
};
export const roleRelations = {
  rolePermissions: [rolePermissionSchema],
  facilityUserRoles: [facilityUserRoleSchema]
};
export const roomRelations = {
  residents: [residentSchema]
};
export const seizureLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema,
  symptoms: [seizureLogSymptomSchema]
};
export const seizureLogSymptomRelations = {
  symptom: symptomSchema
};
export const shiftNoteRelations = { facilityUser: facilityUserSchema };
export const showerLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const sleepLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const temperatureLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const urineLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const userRelations = {
  organizations: [organizationSchema],
  facilities: [facilitySchema],
  facilityUsers: [facilityUserSchema]
};
export const waterLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};
export const weightLogRelations = {
  recorder: facilityUserSchema,
  facilityUser: facilityUserSchema,
  medicationTask: medicationTaskSchema,
  careTask: careTaskSchema,
  prn: prnSchema
};

// DEFINITIONS

acknowledgementSchema.define(acknowledgementRelations);
announcementRecipientSchema.define(announcementRecipientRelations);
announcementReplySchema.define(announcementReplyRelations);
announcementSchema.define(announcementRelations);
appointmentSchema.define(appointmentRelations);
behaviorLogSchema.define(behaviorLogRelations);
behaviorSchema.define(behaviorRelations);
bloodPressureLogSchema.define(bloodPressureLogRelations);
bloodSugarLogSchema.define(bloodSugarLogRelations);
bmLogSchema.define(bmLogRelations);
bodyCheckLogSchema.define(bodyCheckLogRelations);
bodyCheckObservationSchema.define(bodyCheckObservationRelations);
carePlanItemScheduleSchema.define(carePlanItemScheduleRelations);
carePlanItemSchema.define(carePlanItemRelations);
careTaskSchema.define(careTaskRelations);
documentPacketDocumentSchema.define(documentPacketDocumentRelations);
documentPacketSchema.define(documentPacketRelations);
documentTemplatePacketTemplateSchema.define(
  documentTemplatePacketTemplateRelations
);
documentTemplatePacketSchema.define(documentTemplatePacketRelations);
documentParticipantSchema.define(documentParticipantRelations);
documentRelationSchema.define(documentRelationRelations);
documentTemplateSchema.define(documentTemplateRelations);
documentSchema.define(documentRelations);
facilitySchema.define(facilityRelations);
facilitySettingSchema.define(facilitySettingRelations);
facilityUserGroupMemberSchema.define(facilityUserGroupMemberRelations);
facilityUserGroupSchema.define(facilityUserGroupRelations);
facilityUserRoleSchema.define(facilityUserRoleRelations);
facilityUserSchema.define(facilityUserRelations);
faxSchema.define(faxRelations);
fileSchema.define(fileRelations);
foodLogSchema.define(foodLogRelations);
incidentReportResidentSchema.define(incidentReportResidentRelations);
incidentReportSchema.define(incidentReportRelations);
initializationDataSchema.define(initializationDataRelations);
installedLibraryCollectionSchema.define(installedLibraryCollectionRelations);
libraryCollectionDocumentTemplatePacketSchema.define(
  libraryCollectionDocumentTemplatePacketRelations
);
libraryCollectionDocumentTemplateSchema.define(
  libraryCollectionDocumentTemplateRelations
);
libraryCollectionSchema.define(libraryCollectionRelations);
libraryDocumentTemplatePacketTemplateSchema.define(
  libraryDocumentTemplatePacketTemplateRelations
);
libraryDocumentTemplatePacketSchema.define(
  libraryDocumentTemplatePacketRelations
);
libraryDocumentTemplateSchema.define(libraryDocumentTemplateRelations);
medicationDestructionItemSchema.define(medicationDestructionItemRelations);
medicationDestructionSchema.define(medicationDestructionRelations);
medicalProfessionalSchema.define(medicalProfessionalRelations);
medicationInventoryItemCountSchema.define(
  medicationInventoryItemCountRelations
);
medicationInventoryItemSchema.define(medicationInventoryItemRelations);
medicationInventoryMovementSchema.define(medicationInventoryMovementRelations);
medicationInventorySchema.define(medicationInventoryRelations);
medicationMarSchema.define(medicationMarRelations);
medicationOrderItemSchema.define(medicationOrderItemRelations);
medicationOrderSchema.define(medicationOrderRelations);
medicationSchema.define(medicationRelations);
medicationTaskAmountSchema.define(medicationTaskAmountRelations);
medicationTaskSchema.define(medicationTaskRelations);
moodLogSchema.define(moodLogRelations);
narcoticCountInventoryItemSchema.define(narcoticCountInventoryItemRelations);
narcoticCountSchema.define(narcoticCountRelations);
omittedMedicationSchema.define(omittedMedicationRelations);
organizationSchema.define(organizationRelations);
oxygenLogSchema.define(oxygenLogRelations);
pharmacySchema.define(pharmacyRelations);
positionLogSchema.define(positionLogRelations);
prnSchema.define(prnRelations);
residentGroupMemberSchema.define(residentGroupMemberRelations);
residentGroupSchema.define(residentGroupRelations);
residentMedicalProfessionalSchema.define(residentMedicalProfessionalRelations);
residentSchema.define(residentRelations);
residentContactSchema.define(residentContactRelations);
roleSchema.define(roleRelations);
roomSchema.define(roomRelations);
seizureLogSchema.define(seizureLogRelations);
seizureLogSymptomSchema.define(seizureLogSymptomRelations);
shiftNoteSchema.define(shiftNoteRelations);
showerLogSchema.define(showerLogRelations);
sleepLogSchema.define(sleepLogRelations);
temperatureLogSchema.define(temperatureLogRelations);
urineLogSchema.define(urineLogRelations);
userSchema.define(userRelations);
waterLogSchema.define(waterLogRelations);
weightLogSchema.define(weightLogRelations);

export interface INormalizrEntity {
  schema: schema.Entity;
  relations: {
    [key: string]:
      | schema.Entity
      | schema.Entity[]
      | schema.Object
      | schema.Array;
  } | null;
}

export const NormalizrEntities: Dictionary<INormalizrEntity> = {
  acknowledgements: {
    schema: acknowledgementSchema,
    relations: acknowledgementRelations
  },
  allergies: {
    schema: allergySchema,
    relations: null
  },
  announcementRecipients: {
    schema: announcementRecipientSchema,
    relations: announcementRecipientRelations
  },
  announcements: {
    schema: announcementSchema,
    relations: announcementRelations
  },
  announcementReplies: {
    schema: announcementReplySchema,
    relations: announcementReplyRelations
  },
  appointments: {
    schema: appointmentSchema,
    relations: appointmentRelations
  },
  behaviorLogs: {
    schema: behaviorLogSchema,
    relations: behaviorLogRelations
  },
  behaviors: {
    schema: behaviorSchema,
    relations: behaviorRelations
  },
  bloodPressureLogs: {
    schema: bloodPressureLogSchema,
    relations: bloodPressureLogRelations
  },
  bloodSugarLogs: {
    schema: bloodSugarLogSchema,
    relations: bloodSugarLogRelations
  },
  bmLogs: { schema: bmLogSchema, relations: bmLogRelations },
  bodyCheckLogs: {
    schema: bodyCheckLogSchema,
    relations: bodyCheckLogRelations
  },
  bodyCheckObservations: {
    schema: bodyCheckObservationSchema,
    relations: bodyCheckObservationRelations
  },
  carePlanItems: {
    schema: carePlanItemSchema,
    relations: carePlanItemRelations
  },
  carePlanItemSchedules: {
    schema: carePlanItemScheduleSchema,
    relations: carePlanItemScheduleRelations
  },
  careTasks: { schema: careTaskSchema, relations: careTaskRelations },
  destructionMethods: {
    schema: destructionMethodSchema,
    relations: null
  },
  diagnoses: {
    schema: diagnosisSchema,
    relations: null
  },
  documentPacketDocuments: {
    schema: documentPacketDocumentSchema,
    relations: documentPacketDocumentRelations
  },
  documentPackets: {
    schema: documentPacketSchema,
    relations: documentPacketRelations
  },
  documentTemplatePacketTemplates: {
    schema: documentTemplatePacketTemplateSchema,
    relations: documentTemplatePacketTemplateRelations
  },
  documentTemplatePackets: {
    schema: documentTemplatePacketSchema,
    relations: documentTemplatePacketRelations
  },
  documentParticipants: {
    schema: documentParticipantSchema,
    relations: documentParticipantRelations
  },
  documentRelations: {
    schema: documentRelationSchema,
    relations: documentRelationRelations
  },
  documentTemplates: {
    schema: documentTemplateSchema,
    relations: documentTemplateRelations
  },
  documents: {
    schema: documentSchema,
    relations: documentRelations
  },
  facilityPhones: {
    schema: facilityPhoneSchema,
    relations: null
  },
  facilities: { schema: facilitySchema, relations: facilityRelations },
  facilitySettings: {
    schema: facilitySettingSchema,
    relations: facilitySettingRelations
  },
  facilityUserGroupMembers: {
    schema: facilityUserGroupMemberSchema,
    relations: facilityUserGroupMemberRelations
  },
  facilityUserGroups: {
    schema: facilityUserGroupSchema,
    relations: facilityUserGroupRelations
  },
  facilityUserPhones: {
    schema: facilityUserPhoneSchema,
    relations: null
  },
  facilityUserRoles: {
    schema: facilityUserRoleSchema,
    relations: facilityUserRoleRelations
  },
  facilityUsers: {
    schema: facilityUserSchema,
    relations: facilityUserRelations
  },
  faxes: {
    schema: faxSchema,
    relations: faxRelations
  },
  foodLogs: { schema: foodLogSchema, relations: foodLogRelations },
  incidentReportResidents: {
    schema: incidentReportResidentSchema,
    relations: incidentReportResidentRelations
  },
  incidentReports: {
    schema: incidentReportSchema,
    relations: incidentReportRelations
  },
  initializationData: {
    schema: initializationDataSchema,
    relations: initializationDataRelations
  },
  installedLibraryCollections: {
    schema: installedLibraryCollectionSchema,
    relations: installedLibraryCollectionRelations
  },
  libraryCollectionDocumentTemplatePackets: {
    schema: libraryCollectionDocumentTemplatePacketSchema,
    relations: libraryCollectionDocumentTemplatePacketRelations
  },
  libraryCollectionDocumentTemplates: {
    schema: libraryCollectionDocumentTemplateSchema,
    relations: libraryCollectionDocumentTemplateRelations
  },
  libraryCollections: {
    schema: libraryCollectionSchema,
    relations: libraryCollectionRelations
  },
  libraryDocumentTemplatePacketTemplates: {
    schema: libraryDocumentTemplatePacketTemplateSchema,
    relations: libraryDocumentTemplatePacketTemplateRelations
  },
  libraryDocumentTemplatePackets: {
    schema: libraryDocumentTemplatePacketSchema,
    relations: libraryDocumentTemplatePacketRelations
  },
  libraryDocumentTemplates: {
    schema: libraryDocumentTemplateSchema,
    relations: libraryDocumentTemplateRelations
  },
  medicationDestructionItems: {
    schema: medicationDestructionItemSchema,
    relations: medicationDestructionItemRelations
  },
  medicationDestructions: {
    schema: medicationDestructionSchema,
    relations: medicationDestructionRelations
  },
  medicalEvents: {
    schema: medicalEventSchema,
    relations: null
  },
  medicalHistories: {
    schema: medicalHistorySchema,
    relations: null
  },
  medicalProfessionalPhones: {
    schema: medicalProfessionalPhoneSchema,
    relations: null
  },
  medicalProfessionals: {
    schema: medicalProfessionalSchema,
    relations: medicalProfessionalRelations
  },
  medicationInventoryItemCounts: {
    schema: medicationInventoryItemCountSchema,
    relations: medicationInventoryItemCountRelations
  },
  medicationInventoryItems: {
    schema: medicationInventoryItemSchema,
    relations: medicationInventoryItemRelations
  },
  medicationInventoryMovements: {
    schema: medicationInventoryMovementSchema,
    relations: medicationInventoryMovementRelations
  },
  medicationInventories: {
    schema: medicationInventorySchema,
    relations: medicationInventoryRelations
  },
  medicationMars: {
    schema: medicationMarSchema,
    relations: medicationMarRelations
  },
  medicationOrderItems: {
    schema: medicationOrderItemSchema,
    relations: medicationOrderItemRelations
  },
  medicationOrders: {
    schema: medicationOrderSchema,
    relations: medicationOrderRelations
  },
  medicationSchedules: {
    schema: medicationScheduleSchema,
    relations: null
  },
  medications: { schema: medicationSchema, relations: medicationRelations },
  medicationTaskAmounts: {
    schema: medicationTaskAmountSchema,
    relations: medicationTaskAmountRelations
  },
  medicationTasks: {
    schema: medicationTaskSchema,
    relations: medicationTaskRelations
  },
  moodLogs: { schema: moodLogSchema, relations: moodLogRelations },
  narcoticCountInventoryItems: {
    schema: narcoticCountInventoryItemSchema,
    relations: narcoticCountInventoryItemRelations
  },
  narcoticCounts: {
    schema: narcoticCountSchema,
    relations: narcoticCountRelations
  },
  notifications: {
    schema: notificationSchema,
    relations: null
  },
  omittedAmounts: {
    schema: omittedAmountSchema,
    relations: omittedAmountRelations
  },
  omittedMedications: {
    schema: omittedMedicationSchema,
    relations: omittedMedicationRelations
  },
  organizations: {
    schema: organizationSchema,
    relations: organizationRelations
  },
  oxygenLogs: {
    schema: oxygenLogSchema,
    relations: oxygenLogRelations
  },
  pharmacyPhones: {
    schema: pharmacyPhoneSchema,
    relations: null
  },
  pharmacies: { schema: pharmacySchema, relations: pharmacyRelations },
  positionLogs: {
    schema: positionLogSchema,
    relations: positionLogRelations
  },
  prnAmounts: {
    schema: prnAmountSchema,
    relations: prnAmountRelations
  },
  prns: { schema: prnSchema, relations: prnRelations },
  residentContactPhones: {
    schema: residentContactPhoneSchema,
    relations: null
  },
  residentContacts: {
    schema: residentContactSchema,
    relations: residentContactRelations
  },
  residentGroupMembers: {
    schema: residentGroupMemberSchema,
    relations: residentGroupMemberRelations
  },
  residentGroups: {
    schema: residentGroupSchema,
    relations: residentGroupRelations
  },
  residentInsurances: {
    schema: residentInsuranceSchema,
    relations: null
  },
  residentMedicalProfessionals: {
    schema: residentMedicalProfessionalSchema,
    relations: residentMedicalProfessionalRelations
  },
  residents: { schema: residentSchema, relations: residentRelations },
  residentReportConfigs: {
    schema: residentReportConfigSchema,
    relations: null
  },
  roles: {
    schema: roleSchema,
    relations: roleRelations
  },
  rolePermissions: {
    schema: rolePermissionSchema,
    relations: null
  },
  rooms: {
    schema: roomSchema,
    relations: roomRelations
  },
  seizureLogSymptoms: {
    schema: seizureLogSymptomSchema,
    relations: seizureLogSymptomRelations
  },
  seizureLogs: {
    schema: seizureLogSchema,
    relations: seizureLogRelations
  },
  services: {
    schema: serviceSchema,
    relations: null
  },
  shiftNotes: { schema: shiftNoteSchema, relations: shiftNoteRelations },
  showerLogs: { schema: showerLogSchema, relations: showerLogRelations },
  sleepLogs: { schema: sleepLogSchema, relations: sleepLogRelations },
  symptoms: {
    schema: symptomSchema,
    relations: null
  },
  temperatureLogs: {
    schema: temperatureLogSchema,
    relations: temperatureLogRelations
  },
  urineLogs: {
    schema: urineLogSchema,
    relations: urineLogRelations
  },
  users: { schema: userSchema, relations: userRelations },
  waterLogs: { schema: waterLogSchema, relations: waterLogRelations },
  weightLogs: { schema: weightLogSchema, relations: weightLogRelations }
};
