import { MedicationInventory, Paginated, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadMedicationInventoriesSuccess = createAction(
  '[MedicationInventories API] Load MedicationInventories Success',
  props<{ medicationInventories: Paginated<MedicationInventory> }>()
);
export const loadMedicationInventoriesFail = createAction(
  '[MedicationInventories API] Load MedicationInventories Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getMedicationInventoriesSuccess = createAction(
  '[MedicationInventories API] Get MedicationInventories Success',
  props<{ medicationInventories: Paginated<MedicationInventory> }>()
);
export const getMedicationInventoriesFail = createAction(
  '[MedicationInventories API] Get MedicationInventories Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchMedicationInventorySuccess = createAction(
  '[MedicationInventory API] Fetch MedicationInventory Success',
  props<{ medicationInventory: MedicationInventory }>()
);
export const fetchMedicationInventoryFail = createAction(
  '[MedicationInventory API] Fetch MedicationInventory Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createMedicationInventorySuccess = createAction(
  '[MedicationInventories API] Create MedicationInventory Success',
  props<{ medicationInventory: MaybeArray<MedicationInventory> }>()
);
export const createMedicationInventoryFail = createAction(
  '[MedicationInventories API] Create MedicationInventory Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateMedicationInventorySuccess = createAction(
  '[MedicationInventories API] Update MedicationInventory Success',
  props<{ medicationInventory: Update<MedicationInventory> }>()
);
export const updateMedicationInventoryFail = createAction(
  '[MedicationInventories API] Update MedicationInventory Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteMedicationInventorySuccess = createAction(
  '[MedicationInventories API] Delete MedicationInventory Success',
  props<{ id: string }>()
);
export const deleteMedicationInventoryFail = createAction(
  '[MedicationInventories API] Delete MedicationInventory Fail',
  props<{ error: Error }>()
);

// [WS] MedicationInventories Created
export const medicationInventoriesCreated = createAction(
  '[MedicationInventories WS] MedicationInventories Created',
  props<{ medicationInventory: MedicationInventory }>()
);
// [WS] MedicationInventories Patched
export const medicationInventoriesPatched = createAction(
  '[MedicationInventories WS] MedicationInventories Patched',
  props<{ medicationInventory: MedicationInventory }>()
);
// [WS] MedicationInventories Removed
export const medicationInventoriesRemoved = createAction(
  '[MedicationInventories WS] MedicationInventories Removed',
  props<{ id: string }>()
);

export const addMedicationInventories_dashboardRoute = createAction(
  '[MedicationInventories API] add MedicationInventories dashboardRoute Success',
  props<{ medicationInventory: MaybeArray<MedicationInventory> }>()
);
