import { OverlayService } from './index';
import { AlcModalOptions } from './types';

export async function showSheetModal<TComponent>(
  this: OverlayService,
  opts?: AlcModalOptions<TComponent>
) {
  opts.breakpoints ||= [0, 0.25, 0.5, 0.75, 1];
  opts.initialBreakpoint ||= 0.5;

  const modal = await this.modalController.create({
    ...opts,
    backdropDismiss: true
  });

  await modal.present();

  return modal;
}

export async function getSheetModalResult<Result = any, TComponent = any>(
  this: OverlayService,
  opts: AlcModalOptions<TComponent>
): Promise<Result> {
  const sheet = await this._showSheetModal(opts);

  const result = await sheet.onWillDismiss();

  return result?.data;
}
