import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const DashboardOverviewPageActions = createActionGroup({
  source: 'Dashboard Overview Page',
  events: {
    'Load Missed Medication Issues': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Appointments': (params: Params = { query: {} }) => ({ params }),
    'Get Medication Tasks': (params: Params = { query: {} }) => ({ params }),
    'Get Care Tasks': (params: Params = { query: {} }) => ({ params })
  }
});
