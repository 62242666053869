import { OverlayService } from './index';
import { AlcPopoverOptions } from './types';

export async function showPopover<TComponent>(
  this: OverlayService,
  opts: AlcPopoverOptions<TComponent>
) {
  const pop = await this.popController.create({
    ...opts,
    backdropDismiss: true
  });

  await pop.present();

  return pop;
}

export async function getPopoverResult<Result = any, TComponent = any>(
  this: OverlayService,
  opts: AlcPopoverOptions<TComponent>
): Promise<Result> {
  const pop = await this._showPopover(opts);

  const result = await pop.onWillDismiss();

  return result?.data;
}
