import { FeathersService } from '$/app/services/feathers.service';
import { FacilityUserGroupMembersWsActions } from '$/app/store/facility-user-group-members/actions';
import { FacilityUserGroupMember } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class FacilityUserGroupMembersApiService extends AbstractApiService<FacilityUserGroupMember> {
  constructor(feathers: FeathersService, store: Store) {
    super('facility-user-group-members', feathers, store, {
      entityName: 'facilityUserGroupMember',
      created:
        FacilityUserGroupMembersWsActions.facilityUserGroupMembersCreated,
      patched:
        FacilityUserGroupMembersWsActions.facilityUserGroupMembersPatched,
      removed: FacilityUserGroupMembersWsActions.facilityUserGroupMembersRemoved
    });
  }
}
