import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CarePlanItemSchedulesEffects } from './care-plan-item-schedules.effects';
import { carePlanItemSchedulesFeature } from './care-plan-item-schedules.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(carePlanItemSchedulesFeature),
    EffectsModule.forFeature([CarePlanItemSchedulesEffects])
  ],
  providers: [CarePlanItemSchedulesEffects]
})
export class CarePlanItemSchedulesStoreModule {}
