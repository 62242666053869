import { IDocumentTemplate, Paginated } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DocumentTemplatesApiActions = createActionGroup({
  source: 'Document Templates API',
  events: {
    // Success Actions
    'Load Document Templates Success': props<{
      documentTemplates: Paginated<IDocumentTemplate>;
    }>(),
    'Get Document Templates Success': props<{
      documentTemplates: Paginated<IDocumentTemplate>;
    }>(),
    'Fetch Document Template Success': props<{
      documentTemplate: IDocumentTemplate;
    }>(),
    'Create Document Template Success': props<{
      documentTemplate: IDocumentTemplate;
    }>(),
    'Update Document Template Success': props<{
      documentTemplate: IDocumentTemplate;
    }>(),
    'Delete Document Template Success': props<{ id: string }>(),
    // Fail Actions
    'Load Document Templates Fail': props<{ error: Error }>(),
    'Get Document Templates Fail': props<{ error: Error }>(),
    'Fetch Document Template Fail': props<{ error: Error }>(),
    'Create Document Template Fail': props<{ error: Error }>(),
    'Update Document Template Fail': props<{ error: Error }>(),
    'Delete Document Template Fail': props<{ error: Error }>()
  }
});

export const DocumentTemplatesWsActions = createActionGroup({
  source: 'Document Templates WS',
  events: {
    'Document Template Created': props<{
      documentTemplate: IDocumentTemplate;
    }>(),
    'Document Template Patched': props<{
      documentTemplate: IDocumentTemplate;
    }>(),
    'Document Template Removed': props<{ id: string }>()
  }
});

export const DocumentTemplatesGeneralActions = createActionGroup({
  source: 'Document Templates General',
  events: {
    'Clear Document Templates': emptyProps(),
    'Add Document Templates': props<{
      documentTemplates: IDocumentTemplate[];
    }>()
  }
});
