import { Announcement, Paginated, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AnnouncementsApiActions = createActionGroup({
  source: 'Announcements API',
  events: {
    'Load Announcements Success': props<{
      announcements: Paginated<Announcement>;
      metadata?: Record<string, any>;
    }>(),
    'Get Announcements Success': props<{
      announcements: Paginated<Announcement>;
      metadata?: Record<string, any>;
    }>(),
    'Fetch Announcement Success': props<{
      announcement: Announcement;
      metadata?: Record<string, any>;
    }>(),
    'Create Announcement Success': props<{
      announcement: MaybeArray<Announcement>;
      metadata?: Record<string, any>;
    }>(),
    'Update Announcement Success': props<{
      announcement: Update<Announcement>;
      metadata?: Record<string, any>;
    }>(),
    'Delete Announcement Success': props<{
      id: string;
      metadata?: Record<string, any>;
    }>(),

    'Load Announcements Fail': props<{ error: Error }>(),
    'Get Announcements Fail': props<{ error: Error }>(),
    'Fetch Announcement Fail': props<{ error: Error }>(),
    'Create Announcement Fail': props<{ error: Error }>(),
    'Update Announcement Fail': props<{ error: Error }>(),
    'Delete Announcement Fail': props<{ error: Error }>()
  }
});

export const AnnouncementsWsActions = createActionGroup({
  source: 'Announcements WS',
  events: {
    'Announcements Created': props<{ announcement: Announcement }>(),
    'Announcements Patched': props<{ announcement: Announcement }>(),
    'Announcements Removed': props<{ id: string }>()
  }
});

export const AnnouncementsGeneralActions = createActionGroup({
  source: 'Announcements General',
  events: {
    'Clear Announcements': emptyProps(),
    'Add Announcements': props<{
      announcements: Announcement[];
    }>()
  }
});
