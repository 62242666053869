import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { DateRangePreset } from '$/models';
import { Dictionary } from '$shared/types';
import { DateTime, Info } from 'luxon';

export const stringifyDateRange = (
  dateRange: IDateRangePickerValue,
  presets: Dictionary<DateRangePreset>
): string => {
  let dateRangeString;

  if (dateRange.type === 'preset') {
    const yearPresets = [
      'q1',
      'q2',
      'q3',
      'q4',
      ...Info.months('long', { locale: 'en-US' }).map((month) =>
        month.toLowerCase()
      )
    ];

    const presetName = presets[dateRange.preset].name;

    dateRangeString = yearPresets.includes(dateRange.preset)
      ? `${presetName} ${dateRange.year}`
      : presetName;
  } else if (dateRange.type === 'custom') {
    dateRangeString =
      dateRange?.startDate?.toLocaleString(DateTime?.DATE_SHORT) +
      ' \u2014 ' +
      dateRange?.endDate?.toLocaleString(DateTime?.DATE_SHORT);
  } else {
    dateRangeString = '';
  }

  return dateRangeString;
};
