import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const pdfGenerationDemoActions = createActionGroup({
  source: 'pdf Generation Demo Page',
  events: {
    'Fetch Resident': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),

    'Get Residents': (params: Params) => ({ params }),

    'Load Diagnoses': (params: Params = { query: {} }) => ({ params }),

    'Load Resident Insurances': (params: Params = { query: {} }) => ({
      params
    }),

    'Load Medical Events': (params: Params = { query: {} }) => ({ params })
  }
});
