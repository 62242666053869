import { Params, Update } from '$/models';
import { IFacilitySetting } from '$shared/facility-settings/types';
import { createActionGroup, props } from '@ngrx/store';

export const NotificationSettingsPageActions = createActionGroup({
  source: 'Notification Settings Page',
  events: {
    'Load Roles': (params?: Params) => ({ params }),
    'Update Facility Setting': props<Update<IFacilitySetting>>()
  }
});
