import { FeathersService } from '$/app/services/feathers.service';
import { DocumentTemplatePacketsWsActions } from '$/app/store/document-template-packets/document-template-packets.actions';
import { IDocumentTemplatePacket } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class DocumentTemplatePacketsApiService extends AbstractApiService<IDocumentTemplatePacket> {
  constructor(feathers: FeathersService, store: Store) {
    super('document-template-packets', feathers, store, {
      entityName: 'documentTemplatePacket',
      created: DocumentTemplatePacketsWsActions.documentTemplatePacketCreated,
      patched: DocumentTemplatePacketsWsActions.documentTemplatePacketPatched,
      removed: DocumentTemplatePacketsWsActions.documentTemplatePacketRemoved
    });
  }
}
