import { AlcFileViewerModalComponent } from '$/app/shared/components/file-viewer-modal/file-viewer-modal.page';
import { AlcImageViewerPage } from '$/app/shared/pages/popovers/image-viewer/image-viewer';
import { IFile } from '$shared/services/file.schema';
import { Injectable, inject } from '@angular/core';
import { FilesApiService } from '../api';
import { OverlayService } from './overlays';

@Injectable({
  providedIn: 'root'
})
export class FileViewerService {
  private readonly overlay = inject(OverlayService);
  private readonly fileService = inject(FilesApiService);

  async openImageWithId(imageId: string) {
    return await this.overlay.getModalResult({
      component: AlcImageViewerPage,
      componentProps: {
        imgUrl: await this.fileService.getUrl(imageId)
      },
      cssClass: 'fullscreen'
    });
  }

  async openImageWithUrl(imageUrl: string) {
    return await this.overlay.getModalResult({
      component: AlcImageViewerPage,
      componentProps: {
        imgUrl: imageUrl
      },
      cssClass: 'fullscreen'
    });
  }

  async openFile(file: IFile) {
    return await this.overlay.getModalResult({
      component: AlcFileViewerModalComponent,
      componentProps: {
        file
      },
      cssClass: 'xl-modal'
    });
  }

  async openFileWithId(fileId: string) {
    return await this.overlay.getModalResult({
      component: AlcFileViewerModalComponent,
      componentProps: {
        fileId
      },
      cssClass: 'xl-modal'
    });
  }
}
