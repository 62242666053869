import { FacilitiesSelectors } from '$/app/store/facilities';
import { MedicationsSelectors } from '$/app/store/medications/medications.selectors';
import { State, residentsAdapter } from '$/app/store/residents/residents.state';
import { selectRouterState } from '$/app/store/router/router.selectors';
import { ResidentRelations } from '$/models/data/entity-relations/resident-relations.interface';
import { IResident } from '$shared/residents/residents.interface';
import { Dictionary } from '$shared/types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty, keyBy, values } from 'lodash';
import { PharmaciesSelectors } from '../pharmacies';
import { RoomsSelectors } from '../rooms';
import { getResidentVM } from './residents.vm';

// Selector Helpers
const getLoaded = (state: State): boolean => state.loaded;
const getLoading = (state: State): boolean => state.loading;

// Residents State Selector
const selectResidentsState = createFeatureSelector<State>('residents');

const entitySelectors = residentsAdapter.getSelectors(selectResidentsState);

// Entity Selectors
const selectAll = entitySelectors.selectAll;
const selectEntities = entitySelectors.selectEntities;
const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
const selectLoaded = createSelector(selectResidentsState, getLoaded);
const selectLoading = createSelector(selectResidentsState, getLoading);

// Other Selectors
const selectEntitiesWithRelations = createSelector(
  selectEntities,
  RoomsSelectors.selectEntities,
  (
    residents,
    rooms
  ): Dictionary<IResident & Pick<ResidentRelations, 'room'>> => {
    return Object.entries(residents).reduce((acc, [id, resident]) => {
      acc[id] = {
        ...resident,
        room: rooms[resident.roomId]
      };

      return acc;
    }, {});
  }
);

const selectResident = createSelector(
  selectEntities,
  selectRouterState,
  (entities, routerState) => {
    return entities[routerState?.params?.residentId];
  }
);

const selectByQuery = createSelector(
  selectEntities,
  selectRouterState,
  (entities, routerState) => {
    return entities[routerState?.queryParams?.residentId];
  }
);

const selectActive = createSelector(selectAll, (residents) => {
  return residents.filter((x) => x.statusId === 'active');
});

const selectByStatus = (status: string | readonly string[]) => {
  return createSelector(selectAll, (residents): IResident[] => {
    status = typeof status === 'string' ? [status] : status;
    return residents.filter((resident) => status.includes(resident.statusId));
  });
};

const selectActiveEntities = createSelector(
  selectActive,
  (residents): Dictionary<IResident> => {
    return isEmpty(residents) ? {} : keyBy(residents, 'id');
  }
);

const selectActiveWithNarcotics = createSelector(
  selectActiveEntities,
  MedicationsSelectors.selectNarcotics,
  (residents, narcotics): IResident[] => {
    const residentsDict = narcotics.reduce(
      (acc: Dictionary<IResident>, narcotic) => {
        if (!acc[narcotic.residentId] && residents[narcotic.residentId]) {
          acc[narcotic.residentId] = residents[narcotic.residentId];
        }
        return acc;
      },
      {}
    );

    return values(residentsDict);
  }
);

// VIEW MODEL SELECTORS
const selectResidentVMList = createSelector(
  selectAll,
  FacilitiesSelectors.selectEntities,
  RoomsSelectors.selectEntities,
  (residents, facilityEntities, roomEntities) => {
    return residents.map((resident) => {
      const facility = facilityEntities[resident.facilityId];
      const room = roomEntities[resident.roomId];

      return getResidentVM({ resident, facility, room });
    });
  }
);

const selectResidentVM = createSelector(
  selectResident,
  FacilitiesSelectors.selectEntities,
  RoomsSelectors.selectEntities,
  PharmaciesSelectors.selectEntities,
  (resident, facilityEntities, roomEntities, pharmacyEntities) => {
    if (resident) {
      const facility = facilityEntities[resident.facilityId];
      const room = roomEntities[resident.roomId];
      const preferredPharmacy = pharmacyEntities[resident.preferredPharmacyId];

      return getResidentVM({ resident, facility, room, preferredPharmacy });
    }

    return null;
  }
);

const selectNewInventoryInputs = createSelector(
  selectResident,
  MedicationsSelectors.selectMedication,
  (resident, medication) => ({ resident, medication })
);

export const ResidentsSelectors = {
  selectAll,
  selectEntities,
  selectTotal,
  selectLoaded,
  selectLoading,

  selectEntitiesWithRelations,
  selectResident,
  selectByQuery,
  selectActive,
  selectByStatus,
  selectActiveEntities,
  selectActiveWithNarcotics,
  selectResidentVMList,
  selectResidentVM,
  selectNewInventoryInputs
};
