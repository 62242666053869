import { FacilityTimeService } from '$/app/services';
import { AlcDateInputComponent } from '$/app/shared/form-controls/date-input/date-input.component';
import { generateYears, getDateRange } from '$/app/utils';
import { DateRangePreset } from '$/models';
import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  computed,
  inject,
  input,
  model
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  NgControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { find, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TResettableControl } from '../../types/reactive-forms';

export interface IDateRangePickerValue {
  type?: 'custom' | 'preset';
  year?: number;
  preset?: string;
  startDate?: DateTime;
  endDate?: DateTime;
}

@Component({
  selector: 'alc-date-range-picker-control',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    AlcDateInputComponent
  ],
  templateUrl: './date-range-picker-control.component.html',
  styleUrls: ['./date-range-picker-control.component.scss']
})
export class AlcDateRangePickerControlComponent
  implements
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    TResettableControl<IDateRangePickerValue>
{
  private readonly fb = inject(UntypedFormBuilder);
  private readonly ft = inject(FacilityTimeService);
  private readonly ngControl = inject(NgControl);
  private readonly destroyRef = inject(DestroyRef);

  private defaultSet = false;

  protected form = this.fb.group({
    type: ['preset'],
    year: [this.getCurrentYear()],
    preset: ['past30Days'],
    startDate: [null],
    endDate: [null]
  });

  protected presets: DateRangePreset[];
  protected years: number[] = [];

  value = model<IDateRangePickerValue>();
  defaultValue = input(
    {},
    {
      transform: (value: IDateRangePickerValue): IDateRangePickerValue => {
        const dateRange = getDateRange(value, this.ft);
        return dateRange;
      }
    }
  );
  excludePresets = input<string[]>();
  includePresets = input<string[]>();

  formValue = toSignal(this.form.valueChanges);

  public isReset = computed(() => {
    return isEqual(this.formValue(), this.defaultValue());
  });

  private get yearCtrl() {
    return this.form.get('year') as UntypedFormControl;
  }

  private get presetCtrl() {
    return this.form.get('preset') as UntypedFormControl;
  }

  private get startDateCtrl() {
    return this.form.get('startDate') as UntypedFormControl;
  }

  private get endDateCtrl() {
    return this.form.get('endDate') as UntypedFormControl;
  }

  private destroyed$$ = new Subject<void>();

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.endDateCtrl.setErrors(null);
        if (this.endDateCtrl.value < this.startDateCtrl.value) {
          this.endDateCtrl.setErrors({ invalidDateRange: true });
          this.ngControl.control.setErrors({ invalidDateRange: true });
        }
      });

    this.years = generateYears(2019);

    if (this.defaultSet) {
      this.onChange(this.value());
    }
  }

  ngOnDestroy() {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  /*  Control Value Accessor Implementation */
  onChange(_value: IDateRangePickerValue) {}
  onTouch: () => any = () => {};

  writeValue(value: IDateRangePickerValue) {
    this.presets = this.ft.generateDateRangePresets({
      year: value?.year,
      includePresets: this.includePresets(),
      excludePresets: this.excludePresets()
    });

    const dateRange = getDateRange(value, this.ft, 'past30Days');

    this.value.set(dateRange);

    if (!value) {
      this.defaultSet = true;
    }

    this.form.setValue(dateRange);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;

    this.form.valueChanges
      .pipe(
        map((value) => {
          this.value.set({
            ...value,
            endDate: value.endDate?.endOf('day')
          });

          return this.value();
        }),
        takeUntil(this.destroyed$$)
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public reset() {
    this.form.setValue(this.defaultValue());
  }

  protected onTypeChange(ev: MatRadioChange) {
    const type = ev.value;

    if (type === 'preset') {
      const preset = find(this.presets, { id: 'past30Days' });

      this.yearCtrl.setValue(this.getCurrentYear());
      this.presetCtrl.setValue(preset.id);
      this.startDateCtrl.setValue(preset.range.start);
      this.endDateCtrl.setValue(preset.range.end);
    }
  }

  protected onYearChange(ev: MatSelectChange) {
    const year = ev.value;
    const currentYear = this.getCurrentYear();

    this.presets = this.ft.generateDateRangePresets({
      year,
      includePresets: this.includePresets(),
      excludePresets: this.excludePresets()
    });
    let preset = find(this.presets, { id: this.presetCtrl.value });

    if (currentYear !== year && !preset) {
      preset = find(this.presets, { id: 'january' });
      this.presetCtrl.setValue(preset.id);
    }

    this.startDateCtrl.setValue(preset?.range?.start);
    this.endDateCtrl.setValue(preset?.range?.end);
  }

  protected onPresetChange(ev: MatSelectChange) {
    const preset = find(this.presets, { id: ev.value });

    this.form.get('startDate').setValue(preset?.range?.start);
    this.form.get('endDate').setValue(preset?.range?.end);
  }

  private getCurrentYear() {
    return DateTime.local({ zone: this.ft.timezone }).year;
  }
}
