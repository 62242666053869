import { IPaginationInfo, ResidentContactPhone } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface ResidentContactPhonePageQueries {
  all: IPaginationInfo;
}

export const residentContactPhonesAdapter =
  createEntityAdapter<ResidentContactPhone>();

export interface State extends EntityState<ResidentContactPhone> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = residentContactPhonesAdapter.getInitialState(
  {
    loading: false,
    loaded: false,
    error: null
  }
);
