import { AlcAction, IDocumentTemplate, Update } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const DocumentTemplateFormPageActions = createActionGroup({
  source: 'Document Template Form Page',
  events: {
    'Create Document Template': AlcAction<{
      documentTemplate: IDocumentTemplate;
    }>(),

    'Update Document Template': AlcAction<Update<IDocumentTemplate>>()
  }
});
