import { SeizureLogSymptom } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearSeizureLogSymptoms = createAction(
  'Clear Seizure Log Symptom Symptoms'
);

export const addSeizureLogSymptoms = createAction(
  'Add Seizure Log Symptoms',
  props<{ seizureLogSymptoms: SeizureLogSymptom[] }>()
);
