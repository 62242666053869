import { IFacilitySetting } from '$shared/facility-settings/types';
import { createAction, props } from '@ngrx/store';

export const clearFacilitySettings = createAction(
  '[FacilitySettings] Clear FacilitySettings'
);

export const addFacilitySettings = createAction(
  'Add Facility Settings',
  props<{ facilitySettings: IFacilitySetting[] }>()
);
