import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { WeightLog } from '$/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sort } from 'fast-sort';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { State, weightLogsAdapter } from './weight-logs.state';

export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;

// Weight Logs State Selector
export const selectWeightLogsState = createFeatureSelector<State>('weightLogs');

const entitySelectors = weightLogsAdapter.getSelectors(selectWeightLogsState);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(selectWeightLogsState, getLoading);
export const selectLoaded = createSelector(selectWeightLogsState, getLoaded);
export const selectError = createSelector(selectWeightLogsState, getError);

export const selectPagination = (query) => {
  return createSelector(selectWeightLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectResidentWeightLogs = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (weightLogs, residentId): WeightLog[] => {
    if (isEmpty(weightLogs) || !residentId) {
      return [];
    }

    return weightLogs.filter((weightLog) => {
      return weightLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<WeightLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<WeightLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<WeightLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const sorted = sort(logs).asc((log) => log.recordAt);
    const first = sorted[0]?.weight;
    const last = sorted[sorted.length - 1]?.weight;

    let diff;
    let diffLabel;

    if (first && last) {
      diff = last - first;
      if (diff < 0) {
        diffLabel = 'Loss';
      } else if (diff > 0) {
        diffLabel = 'Gain';
      } else {
        diffLabel = 'No Change';
      }
    } else {
      diff = '-';
      diffLabel = 'Diff';
    }

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length || 0
      },
      {
        type: 'value',
        label: diffLabel,
        unit: diff !== '-' ? 'lbs' : '',
        data: typeof diff === 'string' ? diff : Math.abs(diff).toFixed(1)
      },
      {
        type: 'value',
        label: 'First',
        unit: first ? 'lbs' : '',
        data: first ? first : '-'
      },
      {
        type: 'value',
        label: 'Last',
        unit: last ? 'lbs' : '',
        data: last ? last : '-'
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
