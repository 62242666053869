import { CareItemInfoPageActions } from '$/app/pages/care/residents/care-item-info/care-item-info.actions';
import { CarePlanItemFormActions } from '$/app/pages/care/residents/care-item-modal/care-item.modal.actions';
import { CarePlanPageActions } from '$/app/pages/care/residents/careplan/careplan.actions';
import {
  BehaviorsApiService,
  CarePlanItemsApiService,
  EffectHelpersService
} from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { CarePlanItem, CarePlanItemGraph, Paginated } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { CarePlanItemsApiActions } from './care-plan-items.actions';

@Injectable()
export class CarePlanItemsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly carePlanItemsService = inject(CarePlanItemsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly behaviorsService = inject(BehaviorsApiService);

  loadCarePlanItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarePlanPageActions.loadCarePlanItems),
      switchMap((action) => {
        return this.carePlanItemsService.getAll(action.params).pipe(
          getAllPages(this.carePlanItemsService, action?.params?.query),
          reducePaginatedResponses(),
          map((carePlanItems: Paginated<CarePlanItem>) => {
            return CarePlanItemsApiActions.loadCarePlanItemsSuccess({
              carePlanItems
            });
          }),
          catchError((error) => {
            return of(CarePlanItemsApiActions.loadCarePlanItemsFail({ error }));
          })
        );
      })
    );
  });

  getCarePlanItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarePlanPageActions.getCarePlanItems),
      switchMap((action) => {
        return this.carePlanItemsService.getAll(action.params).pipe(
          map((carePlanItems: Paginated<CarePlanItem>) => {
            return CarePlanItemsApiActions.getCarePlanItemsSuccess({
              carePlanItems
            });
          }),
          catchError((error) => {
            return of(CarePlanItemsApiActions.getCarePlanItemsFail({ error }));
          })
        );
      })
    );
  });

  fetchCarePlanItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CareItemInfoPageActions.fetchCarePlanItem),
      switchMap((action) => {
        return this.carePlanItemsService.get(action.id, action.params).pipe(
          mergeMap((carePlanItem: CarePlanItemGraph) => {
            const responseData = new ApiData('carePlanItems', carePlanItem);
            responseData.setPrimaryAction(
              CarePlanItemsApiActions.fetchCarePlanItemSuccess,
              {
                payloadKey: 'carePlanItem'
              }
            );
            return responseData.getActions();
          }),
          catchError((error) => {
            return of(CarePlanItemsApiActions.fetchCarePlanItemFail({ error }));
          })
        );
      })
    );
  });

  createCarePlanItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarePlanItemFormActions.createCarePlanItem),
      this.effectHelpers.apiRequest({
        description: 'Creating care plan item',
        onRequest: (action) =>
          this.carePlanItemsService.create(action.carePlanItem),
        onSuccess: (carePlanItem) =>
          CarePlanItemsApiActions.createCarePlanItemSuccess({ carePlanItem }),
        onError: (error) =>
          CarePlanItemsApiActions.createCarePlanItemFail({ error })
      })
    );
  });

  updateCarePlanItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CarePlanItemFormActions.updateCarePlanItem),
      this.effectHelpers.apiRequest({
        description: 'Updating care plan item',
        onRequest: (action) =>
          this.carePlanItemsService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (carePlanItem) =>
          CarePlanItemsApiActions.updateCarePlanItemSuccess({ carePlanItem }),
        onError: (error) =>
          CarePlanItemsApiActions.updateCarePlanItemFail({ error })
      })
    );
  });

  discontinueCarePlanItem$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CareItemInfoPageActions.discontinueCarePlan),
        exhaustMap((action) =>
          from(this.effectHelpers.handleParamsOnRequest(action.params)).pipe(
            switchMap(() =>
              this.carePlanItemsService
                .patch(action.id, action.changes, action.params)
                .pipe(
                  tap(() =>
                    this.effectHelpers.handleParamsOnSuccess(action.params)
                  ),
                  tap(
                    this.effectHelpers.onModalFormSubmitSuccess(
                      'Care Task discontinued successfully'
                    )
                  ),
                  catchError((error) => {
                    this.effectHelpers.handleParamsOnFail(action.params, {
                      error
                    });

                    this.store.dispatch(
                      CarePlanItemsApiActions.updateCarePlanItemFail({ error })
                    );
                    return EMPTY;
                  })
                )
            )
          )
        )
      );
    },
    { dispatch: false }
  );

  deleteCarePlanItem$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CareItemInfoPageActions.deleteCarePlanItem),
        exhaustMap((action) =>
          from(this.effectHelpers.handleParamsOnRequest(action.params)).pipe(
            switchMap(() =>
              this.carePlanItemsService.delete(action.id).pipe(
                tap(() =>
                  this.effectHelpers.handleParamsOnSuccess(action.params)
                ),
                tap(
                  this.effectHelpers.onModalFormSubmitSuccess(
                    'CarePlanItem deleted successfully!'
                  )
                ),
                catchError((error) => {
                  this.effectHelpers.handleParamsOnFail(action.params, {
                    error
                  });

                  this.store.dispatch(
                    CarePlanItemsApiActions.deleteCarePlanItemFail({ error })
                  );
                  return EMPTY;
                })
              )
            )
          )
        )
      );
    },
    { dispatch: false }
  );
}
