import { AlcContactSupportComponent } from '$/app/shared/components/contact-support.component';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'alc-release-footer',
  standalone: true,
  imports: [CommonModule, AlcContactSupportComponent],
  template: `
    <p class="mb-0 mt-6 text-medium-dark" *ngIf="href">
      Check out our
      <a [href]="href" target="_blank" rel="noopener noreferrer">
        official release notes
      </a>
      for a more comprehensive overview.
    </p>

    <p class="mb-3 mt-6 text-medium-dark">
      <alc-contact-support
        message="For additional information and training on any of the new features listed above, please contact"
      />
    </p>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcReleaseFooterComponent {
  @Input() href: string;
}
