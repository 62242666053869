import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BloodPressureLogsEffects } from './blood-pressure-logs.effects';
import { bloodPressureLogsFeature } from './blood-pressure-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(bloodPressureLogsFeature),
    EffectsModule.forFeature([BloodPressureLogsEffects])
  ],
  providers: [BloodPressureLogsEffects]
})
export class BloodPressureLogsStoreModule {}
