import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResidentGroupsEffects } from './resident-groups.effects';
import { residentGroupsFeature } from './resident-groups.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(residentGroupsFeature),
    EffectsModule.forFeature([ResidentGroupsEffects])
  ],
  providers: [ResidentGroupsEffects]
})
export class ResidentGroupsStoreModule {}
