import { ResidentContact } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

// [API] Load Success/Fail
export const loadResidentContactsSuccess = createAction(
  '[Resident Contacts API] Load Resident Contacts Success',
  props<{ residentContacts: ResidentContact[] }>()
);
export const loadResidentContactsFail = createAction(
  '[Resident Contacts API] Load Resident Contacts Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getResidentContactsSuccess = createAction(
  '[Resident Contacts API] Get Resident Contacts Success',
  props<{ residentContacts: ResidentContact[] }>()
);
export const getResidentContactsFail = createAction(
  '[Resident Contacts API] Get Resident Contacts Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchResidentContactSuccess = createAction(
  '[ResidentContact API] Fetch Resident Contact Success',
  props<{ residentContact: ResidentContact }>()
);
export const fetchResidentContactFail = createAction(
  '[ResidentContact API] Fetch Resident Contact Fail',
  props<{ error: Error }>()
);

// [API] Create Success/Fail
export const createResidentContactSuccess = createAction(
  '[Resident Contacts API] Add Resident Contact Success',
  props<{ residentContact: MaybeArray<ResidentContact> }>()
);
export const createResidentContactFail = createAction(
  '[Resident Contacts API] Add Resident Contact Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateResidentContactSuccess = createAction(
  '[Resident Contacts API] Update Resident Contact Success',
  props<{ residentContact: ResidentContact }>()
);
export const updateResidentContactFail = createAction(
  '[Resident Contacts API] Update Resident Contact Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteResidentContactSuccess = createAction(
  '[Resident Contacts API] Delete Resident Contact Success',
  props<{ id: string }>()
);
export const deleteResidentContactFail = createAction(
  '[Resident Contacts API] Delete Resident Contact Fail',
  props<{ error: Error }>()
);

// WS

// [WS] ResidentContacts Created
export const residentContactsCreated = createAction(
  '[Resident Contacts API WS] ResidentContacts Created',
  props<{ residentContact: ResidentContact }>()
);
// [WS] ResidentContacts Patched
export const residentContactsPatched = createAction(
  '[Resident Contacts API WS] ResidentContacts Patched',
  props<{ residentContact: ResidentContact }>()
);
// [WS] ResidentContacts Removed
export const residentContactsRemoved = createAction(
  '[Resident Contacts API WS] ResidentContacts Removed',
  props<{ id: string }>()
);
