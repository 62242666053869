import { sortByFields } from '$/app/utils';
import { MedicationInventoryMovement } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationInventoryMovementsAdapter =
  createEntityAdapter<MedicationInventoryMovement>({
    sortComparer: sortByFields('createdAt')
  });

export interface State extends EntityState<MedicationInventoryMovement> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  medicationInventoryMovementsAdapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
  });
