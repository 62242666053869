import { Params, Room, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const RoomFormPageActions = createActionGroup({
  source: 'New Room Form Page',
  events: {
    'Create Room': props<{ room: Room; params?: Params }>(),
    'Update Room': props<Update<Room>>()
  }
});
