import { Params } from '$/models';
import { createAction } from '@ngrx/store';

export const loadMedicationSchedules = createAction(
  '[MedicationSchedules Page] Load MedicationSchedules',
  (params: Params = { query: {} }) => ({ params })
);

export const getMedicationSchedules = createAction(
  '[MedicationSchedules Page] Get MedicationSchedules',
  (params: Params = { query: {} }) => ({ params })
);
