import { sumAmounts } from '$/app/utils';
import { calculateAmount } from '$shared/doses';
import { Amount, TAmount } from '$shared/doses/amount.class';
import { Logger } from '$shared/logger';
import { MeasurementId } from '$shared/lookups/measurements.lookup';
import { MedicationFormId } from '$shared/lookups/medication-forms.lookup';
import { MedicationInventoryItem } from '$shared/medication-inventory-items/medication-inventory-item.class';
import { Dictionary } from '$shared/types';
import { chainFlow } from '$shared/utils';
import {
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { every, isNil, map } from 'lodash';

export function maxDoseForPeriod(
  maxDoseForPeriod: string | undefined,
  totalDoseInPeriod: number,
  inventoryItemsById: Dictionary<MedicationInventoryItem>,
  defaultMeasurementId: MeasurementId,
  medicationFormId: MedicationFormId,
  existingAmounts: TAmount[] | undefined
): ValidatorFn {
  return (control: FormArray): ValidationErrors => {
    Logger.assert(
      control instanceof FormArray,
      'This validator must be registered on a FormArray'
    );

    if (
      !control.length ||
      !controlsAreValid(control) ||
      isNil(maxDoseForPeriod) ||
      isNil(totalDoseInPeriod)
    ) {
      return null;
    }

    const totalAmount = chainFlow(
      control.getRawValue(),
      (amounts) =>
        map(amounts, (amount) => {
          const measurementId =
            inventoryItemsById[amount.medicationInventoryItemId]
              ?.amountMeasurementId ?? defaultMeasurementId;

          return new Amount(amount, measurementId, medicationFormId);
        }),
      (amounts) => sumAmounts(amounts, defaultMeasurementId)
    );

    const existingAmount = existingAmounts
      ? sumAmounts(existingAmounts, defaultMeasurementId)
      : 0;

    const isWithinMaxDose =
      totalAmount + totalDoseInPeriod - existingAmount <=
      calculateAmount(maxDoseForPeriod, defaultMeasurementId);

    return isWithinMaxDose
      ? null
      : {
          maxDoseForPeriod: {
            maxDose: maxDoseForPeriod
          }
        };
  };
}

function controlsAreValid(formArray: FormArray<FormGroup>) {
  return every(formArray.controls, (control) =>
    every(
      control.controls,
      (control, key) =>
        ['amountTypeId', 'amount', 'strength'].includes(key) &&
        !isNil(control.value)
    )
  );
}
