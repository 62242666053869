import { Logger } from '../../logger';

export type TFeetInches = {
  feet: number;
  inches: number;
};

export function convertInchesToFeet(inches: string | number): TFeetInches {
  const _inches = parseFloat(inches as string);
  if (isNaN(_inches)) {
    Logger.warn('convertInchesToFeet: inches is not a number', { inches });
    return { feet: 0, inches: 0 };
  }

  const feet = Math.floor(_inches / 12);
  const remainingInches = Math.round((_inches % 12) * 10) / 10;
  return { feet, inches: remainingInches };
}
