import { IFile, IPaginationInfo } from '$/models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const filesAdapter = createEntityAdapter<IFile>();

export interface State extends EntityState<IFile> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = filesAdapter.getInitialState({
  pagination: {},
  loading: false,
  loaded: false,
  error: null
});
