import { AppComponentActions } from '$/app/app.actions';
import { FacilityProfilePageActions } from '$/app/pages/facilities/facility-detail/facility-profile/facility-profile.actions';
import { FacilityModalActions } from '$/app/shared/pages/forms/facility-modal/facility.modal.actions';
import {
  facilitiesAdapter,
  initialState
} from '$/app/store/facilities/facilities.state';
import { createFeature, createReducer, on } from '@ngrx/store';

import {
  FacilitiesApiActions,
  FacilitiesGeneralActions,
  FacilitiesWsActions
} from './facilities.actions';

export const facilitiesFeature = createFeature({
  name: 'facilities',
  reducer: createReducer(
    initialState,

    on(
      AppComponentActions.loadFacilities,
      FacilityProfilePageActions.fetchFacility,
      FacilityModalActions.updateFacility,
      FacilityProfilePageActions.uploadLogoFile,
      (state) => {
        return {
          ...state,
          loading: true
        };
      }
    ),

    on(FacilitiesApiActions.loadFacilitiesSuccess, (state, action) => {
      return facilitiesAdapter.setAll(action.facilities, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilitiesApiActions.fetchFacilitySuccess, (state, action) => {
      return facilitiesAdapter.upsertOne(action.facility, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilitiesApiActions.createFacilitySuccess, (state, action) => {
      return facilitiesAdapter.addOne(action.facility, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilitiesApiActions.updateFacilitySuccess, (state, action) => {
      return facilitiesAdapter.upsertOne(action.facility, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilitiesApiActions.deleteFacilitySuccess, (state, action) => {
      return facilitiesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      FacilitiesApiActions.loadFacilitiesFail,
      FacilitiesApiActions.fetchFacilityFail,
      FacilitiesApiActions.createFacilityFail,
      FacilitiesApiActions.updateFacilityFail,
      FacilitiesApiActions.deleteFacilityFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      FacilitiesWsActions.facilitiesCreated,
      FacilitiesWsActions.facilitiesPatched,
      (state, action) => {
        return facilitiesAdapter.upsertOne(action.facility, state);
      }
    ),

    on(FacilitiesWsActions.facilitiesRemoved, (state, action) => {
      return facilitiesAdapter.removeOne(action.id, state);
    }),

    on(FacilitiesGeneralActions.addFacilities, (state, action) => {
      return facilitiesAdapter.upsertMany(action.facilities, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(FacilitiesGeneralActions.clearFacilities, (state) => {
      return facilitiesAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
