import { FeathersService } from '$/app/services/feathers.service';
import { AnnouncementRepliesWsActions } from '$/app/store/announcement-replies';
import { toUpdated } from '$/app/utils';
import { AnnouncementReply, Paginated, Params, Update } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AnnouncementRepliesApiService {
  private announcementRepliesService: Service<AnnouncementReply>;

  constructor(feathers: FeathersService, store: Store) {
    this.announcementRepliesService = feathers.client.service(
      'announcement-replies'
    );

    this.announcementRepliesService.on('created', (announcementReply) => {
      store.dispatch(
        AnnouncementRepliesWsActions.announcementRepliesCreated({
          announcementReply
        })
      );
    });

    this.announcementRepliesService.on('patched', (announcementReply) => {
      store.dispatch(
        AnnouncementRepliesWsActions.announcementRepliesPatched({
          announcementReply
        })
      );
    });

    this.announcementRepliesService.on('removed', (announcementReply) => {
      store.dispatch(
        AnnouncementRepliesWsActions.announcementRepliesRemoved({
          id: announcementReply.id
        })
      );
    });
  }

  getAll(params: Params): Observable<Paginated<AnnouncementReply>> {
    const promise = this.announcementRepliesService.find(params) as Promise<
      Paginated<AnnouncementReply>
    >;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<AnnouncementReply> {
    const promise = this.announcementRepliesService.get(id, params);
    return from(promise);
  }

  create(announcementReply: AnnouncementReply, params: Params = { query: {} }) {
    const promise = this.announcementRepliesService.create(
      announcementReply,
      params
    );
    return from(promise);
  }

  patch(
    id: string,
    announcementReply: Partial<AnnouncementReply>,
    params: Params = { query: {} }
  ): Observable<Update<AnnouncementReply>> {
    const promise = this.announcementRepliesService.patch(
      id,
      announcementReply,
      params
    );

    return from(promise).pipe(map(toUpdated));
  }

  delete(
    id: string,
    params: Params = { query: {} }
  ): Observable<AnnouncementReply> {
    const promise = this.announcementRepliesService.remove(id, params);
    return from(promise);
  }
}
