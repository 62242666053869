import { MedicationInventoryMovement } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥👈 USER ACTIONS
//

// Create MedicationInventoryMovement
export const createMedicationInventoryMovement = createAction(
  '[MedicationInventoryMovement Form] Create MedicationInventoryMovement',
  props<{ medicationInventoryMovement: MedicationInventoryMovement }>()
);
