import { FacilityProfilePageActions } from '$/app/pages/facilities/facility-detail/facility-profile/facility-profile.actions';
import { ResidentModalActions } from '$/app/pages/residents/resident-modal/resident.modal.actions';
import { RoomFormPageActions } from '$/app/shared/pages/forms/room-form/room-form.page.actions';
import {
  State,
  initialState,
  roomsAdapter
} from '$/app/store/rooms/rooms.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  RoomsApiActions,
  RoomsGeneralActions,
  RoomsWsActions
} from './rooms.actions';

const reducer = createReducer(
  initialState,

  on(
    ResidentModalActions.loadRooms,
    FacilityProfilePageActions.loadRooms,
    RoomFormPageActions.createRoom,
    RoomFormPageActions.updateRoom,
    FacilityProfilePageActions.deleteRoom,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(RoomsApiActions.loadRoomsSuccess, (state, action) => {
    return roomsAdapter.setAll(action.rooms.data, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(RoomsApiActions.createRoomSuccess, (state, action) => {
    return roomsAdapter.upsertOne(action.room, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(RoomsApiActions.updateRoomSuccess, (state, action) => {
    return roomsAdapter.upsertOne(action.room, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(RoomsApiActions.deleteRoomSuccess, (state, action) => {
    return roomsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    RoomsApiActions.loadRoomsFail,
    RoomsApiActions.fetchRoomFail,
    RoomsApiActions.createRoomFail,
    RoomsApiActions.updateRoomFail,
    RoomsApiActions.deleteRoomFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    RoomsWsActions.roomsCreated,
    RoomsWsActions.roomsPatched,
    (state, action) => {
      return roomsAdapter.upsertOne(action.room, state);
    }
  ),

  on(RoomsWsActions.roomsRemoved, (state, action) => {
    return roomsAdapter.removeOne(action.id, state);
  }),

  on(RoomsGeneralActions.addRooms, (state, action) => {
    return roomsAdapter.upsertMany(action.rooms, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(RoomsGeneralActions.clearRooms, (state) => {
    return roomsAdapter.removeAll({
      ...state,
      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function roomsReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
