import { createFeature, createReducer, on } from '@ngrx/store';
import { MarSummaryFilterComponentActions } from '../mar-summary-filter/mar-summary-filter.actions';
import { initialState } from './mar-summary-page.state';

export const uiMarSummaryPageFeature = createFeature({
  name: 'uiMarSummaryPage',
  reducer: createReducer(
    initialState,
    on(MarSummaryFilterComponentActions.filterChanged, (state, action) => ({
      ...state,
      filters: action.filters
    }))
  )
});
