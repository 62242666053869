import { FeathersService } from '$/app/services/feathers.service';
import { BloodPressureLogsWsActions } from '$/app/store/blood-pressure-logs';
import { ApiData } from '$/app/utils/api-data';
import { Paginated, Params } from '$/models';
import { IBloodPressureLog } from '$shared/services/blood-pressure-log';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BloodPressureLogsApiService {
  private bloodPressureLogsService: Service<IBloodPressureLog>;

  constructor(store: Store, feathers: FeathersService) {
    this.bloodPressureLogsService = feathers.client.service(
      'blood-pressure-logs'
    );

    this.bloodPressureLogsService.on('created', (bloodPressureLog) => {
      const responseData = new ApiData('bloodPressureLogs', bloodPressureLog);
      responseData.setPrimaryAction(
        BloodPressureLogsWsActions.bloodPressureLogsCreated,
        {
          payloadKey: 'bloodPressureLog'
        }
      );
      responseData.getActions().forEach((action) => {
        store.dispatch(action);
      });
    });

    this.bloodPressureLogsService.on('patched', (bloodPressureLog) => {
      store.dispatch(
        BloodPressureLogsWsActions.bloodPressureLogsPatched({
          bloodPressureLog
        })
      );
    });

    this.bloodPressureLogsService.on('removed', (bloodPressureLog) => {
      store.dispatch(
        BloodPressureLogsWsActions.bloodPressureLogsRemoved({
          id: bloodPressureLog.id
        })
      );
    });
  }

  getAll(params: Params) {
    const result = this.bloodPressureLogsService.find(params) as Promise<
      Paginated<IBloodPressureLog>
    >;

    return from(result);
  }

  create(bloodPressure: IBloodPressureLog): Observable<IBloodPressureLog> {
    return from(this.bloodPressureLogsService.create(bloodPressure));
  }

  patch(
    id: string,
    bloodPressureLog: Partial<IBloodPressureLog>,
    params: Params = { query: {} }
  ): Observable<IBloodPressureLog> {
    const promise = this.bloodPressureLogsService.patch(
      id,
      bloodPressureLog,
      params
    );

    return from(promise);
  }

  delete(
    id: string,
    params: Params = { query: {} }
  ): Observable<IBloodPressureLog> {
    return from(this.bloodPressureLogsService.remove(id, params));
  }
}
