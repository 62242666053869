import { FacilityLibraryPageActions } from '$/app/pages/documents/facility-document-library/facility-document-library.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  InstalledLibraryCollectionsApiActions,
  InstalledLibraryCollectionsGeneralActions,
  InstalledLibraryCollectionsWsActions
} from './installed-library-collections.actions';
import {
  initialState,
  installedLibraryCollectionsAdapter
} from './installed-library-collections.state';

export const installedLibraryCollectionsFeature = createFeature({
  name: 'installedLibraryCollections',
  reducer: createReducer(
    initialState,

    on(FacilityLibraryPageActions.installLibraryCollections, (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      InstalledLibraryCollectionsApiActions.loadInstalledLibraryCollectionsSuccess,
      (state, action) => {
        const {
          installedLibraryCollections: { data: records, ...pagination }
        } = action;

        return installedLibraryCollectionsAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      InstalledLibraryCollectionsApiActions.getInstalledLibraryCollectionsSuccess,
      (state, action) => {
        const {
          installedLibraryCollections: { data: records, ...pagination }
        } = action;

        return installedLibraryCollectionsAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      InstalledLibraryCollectionsApiActions.fetchInstalledLibraryCollectionSuccess,
      (state, action) => {
        return installedLibraryCollectionsAdapter.upsertOne(
          action.installedLibraryCollection,
          {
            ...state,
            loading: false,
            loaded: false,
            error: null
          }
        );
      }
    ),

    on(
      InstalledLibraryCollectionsApiActions.createInstalledLibraryCollectionSuccess,
      (state) => {
        return {
          ...state,
          loading: false,
          error: null
        };
      }
    ),

    on(
      InstalledLibraryCollectionsApiActions.updateInstalledLibraryCollectionSuccess,
      (state, action) => {
        return installedLibraryCollectionsAdapter.upsertOne(
          action.installedLibraryCollection,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      InstalledLibraryCollectionsApiActions.deleteInstalledLibraryCollectionSuccess,
      (state, action) =>
        installedLibraryCollectionsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      InstalledLibraryCollectionsApiActions.loadInstalledLibraryCollectionsFail,
      InstalledLibraryCollectionsApiActions.getInstalledLibraryCollectionsFail,
      InstalledLibraryCollectionsApiActions.createInstalledLibraryCollectionFail,
      InstalledLibraryCollectionsApiActions.updateInstalledLibraryCollectionFail,
      InstalledLibraryCollectionsApiActions.deleteInstalledLibraryCollectionFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      InstalledLibraryCollectionsWsActions.installedLibraryCollectionCreated,
      InstalledLibraryCollectionsWsActions.installedLibraryCollectionPatched,
      (state, action) => {
        return installedLibraryCollectionsAdapter.upsertOne(
          action.installedLibraryCollection,
          state
        );
      }
    ),

    on(
      InstalledLibraryCollectionsWsActions.installedLibraryCollectionRemoved,
      (state, action) => {
        return installedLibraryCollectionsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      InstalledLibraryCollectionsGeneralActions.addInstalledLibraryCollections,
      (state, action) => {
        return installedLibraryCollectionsAdapter.upsertMany(
          action.installedLibraryCollections,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      InstalledLibraryCollectionsGeneralActions.clearInstalledLibraryCollections,
      () => {
        return installedLibraryCollectionsAdapter.removeAll({
          ...initialState
        });
      }
    )
  )
});
