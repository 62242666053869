import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { ResidentGroupMemberPickerActions } from '$/app/pages/residents/resident-group-member-picker/resident-group-member-picker.actions';
import {
  ResidentGroupMembersApiActions,
  ResidentGroupMembersWsActions
} from '$/app/store/resident-group-members/actions/resident-group-members-api.actions';
import { ResidentGroupMembersGeneralActions } from '$/app/store/resident-group-members/actions/resident-group-members-gen.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  initialState,
  residentGroupMembersAdapter
} from './resident-group-members.state';

const reducer = createReducer(
  initialState,

  on(
    ResidentGroupMemberPickerActions.createResidentGroupMember,
    ResidentFacesheetPageActions.deleteResidentGroupMember,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    ResidentGroupMembersApiActions.getResidentGroupMembersSuccess,
    (state, action) => {
      return residentGroupMembersAdapter.upsertMany(
        action.residentGroupMembers.data,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    ResidentGroupMembersApiActions.createResidentGroupMemberSuccess,
    (state, action) => {
      const residentGroupMembers = castArray(action.residentGroupMember);

      return residentGroupMembersAdapter.addMany(residentGroupMembers, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    ResidentGroupMembersApiActions.deleteResidentGroupMemberSuccess,
    (state, action) => {
      return residentGroupMembersAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    ResidentGroupMembersApiActions.createResidentGroupMemberFail,
    ResidentGroupMembersApiActions.deleteResidentGroupMemberFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    ResidentGroupMembersWsActions.residentGroupMembersCreated,
    ResidentGroupMembersWsActions.residentGroupMembersPatched,
    (state, action) => {
      return residentGroupMembersAdapter.upsertOne(action.residentGroupMember, {
        ...state
      });
    }
  ),

  on(
    ResidentGroupMembersWsActions.residentGroupMembersRemoved,
    (state, action) => {
      return residentGroupMembersAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(ResidentGroupMembersGeneralActions.clearResidentGroupMembers, (state) => {
    return residentGroupMembersAdapter.removeAll({
      ...state,
      loading: false,
      loaded: false,
      error: null
    });
  }),

  on(
    ResidentGroupMembersGeneralActions.addResidentGroupMembers,
    (state, action) => {
      return residentGroupMembersAdapter.upsertMany(
        action.residentGroupMembers,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  )
);

export const residentGroupMembersFeature = createFeature({
  name: 'residentGroupMembers',
  reducer
});
