import { OmittedMedication, Paginated, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Load Success/Fail
export const loadOmittedMedicationsSuccess = createAction(
  '[OmittedMedications API] Load OmittedMedications Success',
  props<{ omittedMedications: Paginated<OmittedMedication> }>()
);
export const loadOmittedMedicationsFail = createAction(
  '[OmittedMedications API] Load OmittedMedications Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getOmittedMedicationsSuccess = createAction(
  '[OmittedMedications API] Get OmittedMedications Success',
  props<{ omittedMedications: Paginated<OmittedMedication> }>()
);
export const getOmittedMedicationsFail = createAction(
  '[OmittedMedications API] Get OmittedMedications Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchOmittedMedicationSuccess = createAction(
  '[OmittedMedication API] Fetch OmittedMedication Success',
  props<{ omittedMedication: OmittedMedication }>()
);
export const fetchOmittedMedicationFail = createAction(
  '[OmittedMedication API] Fetch OmittedMedication Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateOmittedMedicationSuccess = createAction(
  '[OmittedMedications API] Update OmittedMedication Success',
  props<{ omittedMedication: Update<OmittedMedication> }>()
);
export const updateOmittedMedicationFail = createAction(
  '[OmittedMedications API] Update OmittedMedication Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteOmittedMedicationSuccess = createAction(
  '[OmittedMedications API] Delete OmittedMedication Success',
  props<{ id: string }>()
);
export const deleteOmittedMedicationFail = createAction(
  '[OmittedMedications API] Delete OmittedMedication Fail',
  props<{ error: Error }>()
);

// [WS] OmittedMedications Created
export const omittedMedicationsCreated = createAction(
  '[OmittedMedications WS] OmittedMedications Created',
  props<{ omittedMedication: OmittedMedication }>()
);
// [WS] OmittedMedications Patched
export const omittedMedicationsPatched = createAction(
  '[OmittedMedications WS] OmittedMedications Patched',
  props<{ omittedMedication: OmittedMedication }>()
);
// [WS] OmittedMedications Removed
export const omittedMedicationsRemoved = createAction(
  '[OmittedMedications WS] OmittedMedications Removed',
  props<{ id: string }>()
);

export const addOmittedMedication_DashboardRoute = createAction(
  '[OmittedMedications API Dashboard Route Effect] Add addOmittedMedication_DashboardRoute',
  props<{ omittedMedication: MaybeArray<OmittedMedication> }>()
);
