import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentGroupsWsActions = createActionGroup({
  source: 'Resident Group WS',
  events: {
    'Resident Groups Created': props<{
      residentGroup: ResidentGroup;
    }>(),
    'Resident Groups Patched': props<{
      residentGroup: ResidentGroup;
    }>(),
    'Resident Groups Removed': props<{ id: string }>()
  }
});
