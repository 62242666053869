import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BodyCheckObservationsEffects } from './body-check-observations.effects';
import { bodyCheckObservationsFeature } from './body-check-observations.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(bodyCheckObservationsFeature),
    EffectsModule.forFeature([BodyCheckObservationsEffects])
  ],
  providers: [BodyCheckObservationsEffects]
})
export class BodyCheckObservationsStoreModule {}
