<ion-header>
  <ion-toolbar [color]="'primary' | alcTheme">
    @if (subtitle) {
      <div class="pl-4">
        <h1 class="m-0 text-lg leading-5">{{ title }}</h1>
        <h2 class="m-0 text-sm">{{ subtitle }}</h2>
      </div>
    } @else {
      <ion-title>{{ title }}</ion-title>
    }

    <ion-buttons slot="end">
      @if (!hideClose && cancelClicked.observers.length) {
        <ion-button (click)="cancelClicked.emit()">
          <ion-icon slot="icon-only" name="close" />
        </ion-button>
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="form-content">
  <ng-content />
</ion-content>

@if (!hideSubmit || !hideCancel) {
  <ion-footer>
    <ion-toolbar>
      <div class="form-action-buttons">
        @if (submitClicked.observers.length && !hideSubmit) {
          <ion-button
            data-testid="submit-button"
            shape="round"
            fill="solid"
            [color]="'primary' | alcTheme"
            [disabled]="disableSubmit"
            (click)="submitClicked.emit()"
          >
            <ion-label>{{ submitText }}</ion-label>
          </ion-button>
        }

        @if (cancelClicked.observers.length && !hideCancel) {
          <ion-button
            shape="round"
            fill="outline"
            [color]="'primary' | alcTheme"
            [disabled]="disableCancel"
            (click)="cancelClicked.emit()"
          >
            <ion-label>{{ cancelText }}</ion-label>
          </ion-button>
        }
      </div>
    </ion-toolbar>
  </ion-footer>
}
