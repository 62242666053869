import { Update } from '$/models';
import { IUrineLog } from '$shared/services/urine-log';
import { createActionGroup, props } from '@ngrx/store';

export const UrineLogFormPageActions = createActionGroup({
  source: 'Urine Log Form Page',
  events: {
    'Create Urine Log': props<{ urineLog: IUrineLog }>(),
    'Update Urine Log': props<Update<IUrineLog>>()
  }
});
