import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { OxygenLogsPageActions } from '$/app/pages/notebook/shared/logs/oxygen-log/oxygen-log.actions';
import { getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import { OxygenLogsApiActions, OxygenLogsGeneralActions } from './actions';
import { initialState, oxygenLogsAdapter } from './oxygen-logs.state';
import { OxygenLogFormPageActions } from '$/app/shared/pages/forms/log-forms/oxygen-log-form/oxygen-log-form.actions';

export const oxygenLogsFeature = createFeature({
  name: 'oxygenLogs',
  reducer: createReducer(
    initialState,

    on(
      OxygenLogsPageActions.loadOxygenLogs,
      OxygenLogsPageActions.getOxygenLogs,
      OxygenLogFormPageActions.createOxygenLog,
      OxygenLogFormPageActions.updateOxygenLog,
      OxygenLogsPageActions.deleteOxygenLog,
      ResidentMedicationTasksPageActions.deleteOxygenLog,
      MissedMedicationsPageActions.deleteOxygenLog,
      RoutineMarListPageActions.deleteOxygenLog,
      RoutineMarDetailPageActions.deleteOxygenLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(OxygenLogsApiActions.loadOxygenLogsSuccess, (state, action) => {
      const data = getRecords(action.oxygenLogs);

      return oxygenLogsAdapter.setAll(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OxygenLogsApiActions.getOxygenLogsSuccess, (state, action) => {
      const data = getRecords(action.oxygenLogs);

      return oxygenLogsAdapter.upsertMany(data, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(OxygenLogsApiActions.createOxygenLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(OxygenLogsApiActions.updateOxygenLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(OxygenLogsApiActions.deleteOxygenLogSuccess, (state, action) =>
      oxygenLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      OxygenLogsApiActions.loadOxygenLogsFail,
      OxygenLogsApiActions.getOxygenLogsFail,
      OxygenLogsApiActions.createOxygenLogFail,
      OxygenLogsApiActions.updateOxygenLogFail,
      OxygenLogsApiActions.deleteOxygenLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      OxygenLogsApiActions.oxygenLogsCreated,
      OxygenLogsApiActions.oxygenLogsPatched,
      (state, action) => {
        return oxygenLogsAdapter.upsertOne(action.oxygenLog, state);
      }
    ),

    on(OxygenLogsApiActions.oxygenLogsRemoved, (state, action) => {
      return oxygenLogsAdapter.removeOne(action.id, state);
    }),

    on(OxygenLogsGeneralActions.addOxygenLogs, (state, action) => {
      return oxygenLogsAdapter.upsertMany(action.oxygenLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(OxygenLogsGeneralActions.clearOxygenLogs, () => {
      return oxygenLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
