import { sortByDatetime } from '$/app/utils';
import { Announcement, AnnouncementMetrics } from '$/models';
import { Dictionary } from '$shared/types';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const announcementsAdapter = createEntityAdapter<Announcement>({
  sortComparer: sortByDatetime('createdAt')
});

export interface State extends EntityState<Announcement> {
  metrics: Dictionary<AnnouncementMetrics>;
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = announcementsAdapter.getInitialState({
  metrics: {},
  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
