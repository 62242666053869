import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResidentMedicalProfessionalsEffects } from './resident-medical-professionals.effects';
import { residentMedicalProfessionalsReducer } from './resident-medical-professionals.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'residentMedicalProfessionals',
      residentMedicalProfessionalsReducer
    ),
    EffectsModule.forFeature([ResidentMedicalProfessionalsEffects])
  ],
  providers: [ResidentMedicalProfessionalsEffects]
})
export class ResidentMedicalProfessionalsStoreModule {}
