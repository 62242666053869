import { AlcAction, IDocumentTemplate, Update } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const DocumentTemplateDetailsPageActions = createActionGroup({
  source: 'Document Template Details Page',
  events: {
    'Fetch Document Template': AlcAction<{ id: string }>(),

    'Upload File': AlcAction<{ id: string; file: File }>(),

    'Remove File': (
      templateId: string,
      fileId: string
    ): Update<IDocumentTemplate> => ({
      id: templateId,
      changes: { fileId: null },
      params: {
        query: { $actions: [{ removeFile: { fileId } }] },
        loading: { message: 'Removing file...' },
        toast: { message: 'File removed successfully' }
      }
    }),

    'Delete Document Template': AlcAction<{ id: string }>()
  }
});
