import { TemperatureLog, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const TemperatureLogFormPageActions = createActionGroup({
  source: 'Temperature Log Form Page',
  events: {
    'Create Temperature Log': props<{
      temperatureLog: TemperatureLog;
    }>(),
    'Update Temperature Log': props<Update<TemperatureLog>>()
  }
});
