import { EntityAdapter, EntityState } from '@ngrx/entity';
import { Selector, createSelector } from '@ngrx/store';

interface AlcEntityState<TEntity> extends EntityState<TEntity> {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export function generateEntitySelectors<
  TEntity,
  TState extends AlcEntityState<TEntity>
>(stateSelector: Selector<object, TState>, adapter: EntityAdapter<TEntity>) {
  return {
    ...adapter.getSelectors(stateSelector),

    selectLoading: createSelector(stateSelector, (state) => state.loading),
    selectLoaded: createSelector(stateSelector, (state) => state.loaded),
    selectError: createSelector(stateSelector, (state) => state.error)
  };
}
