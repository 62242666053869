import { FeathersService } from '$/app/services/feathers.service';
import { MedicalHistoriesApiActions } from '$/app/store/medical-histories/actions';
import { getPaginatedData, toUpdated } from '$/app/utils';
import { MedicalHistory, Paginated, Params, Update } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MedicalHistoriesApiService {
  private medicalHistoriesService: Service<MedicalHistory>;

  constructor(feathers: FeathersService, store: Store) {
    this.medicalHistoriesService = feathers.client.service('medical-histories');

    this.medicalHistoriesService.on('created', (medicalHistory) => {
      store.dispatch(
        MedicalHistoriesApiActions.medicalHistoriesCreated({
          medicalHistory
        })
      );
    });

    this.medicalHistoriesService.on('patched', (medicalHistory) => {
      store.dispatch(
        MedicalHistoriesApiActions.medicalHistoriesPatched({ medicalHistory })
      );
    });

    this.medicalHistoriesService.on('removed', (medicalHistory) => {
      store.dispatch(
        MedicalHistoriesApiActions.medicalHistoriesRemoved({
          id: medicalHistory.id
        })
      );
    });
  }

  getAll(params: Params = { query: {} }): Observable<MedicalHistory[]> {
    const promise = this.medicalHistoriesService.find(params) as Promise<
      Paginated<MedicalHistory>
    >;
    return from(promise).pipe(map(getPaginatedData));
  }

  get(id: string, params: Params = { query: {} }): Observable<MedicalHistory> {
    const promise = this.medicalHistoriesService.get(id, params);
    return from(promise);
  }

  create(medicalHistory: MedicalHistory) {
    const promise = this.medicalHistoriesService.create(medicalHistory);
    return from(promise);
  }

  patch(
    id: string,
    medicalHistory: Partial<MedicalHistory>
  ): Observable<Update<MedicalHistory>> {
    const promise = this.medicalHistoriesService.patch(id, medicalHistory);

    return from(promise).pipe(map(toUpdated));
  }

  delete(id: string): Observable<MedicalHistory> {
    const promise = this.medicalHistoriesService.remove(id);
    return from(promise);
  }
}
