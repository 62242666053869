import { REGEX } from '$shared';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export function medicationStrength(control: AbstractControl): ValidationErrors {
  const value = control.value as string;
  if (!value) {
    return null;
  }

  return REGEX.MEDICATION_STRENGTH.test(value)
    ? null
    : { medicationStrength: true };
}
