import { normalizeToArray } from './normalize-to-array';

/**
 * Takes the entities object returned by normalizr's normalize function
 * and loops through each entity calling normalizeToArray which will convert
 * the dictionary for that entity into an array and remove any normalized fields
 * in the process. The end result is a dictionary where each key is the name of
 * the entity and the value is an array of entities for that entity name.
 */
export const normalizeToArrayAll = (
  entities,
  fieldsToRemove: { [key: string]: string[] } = {}
): { [key: string]: any[] } => {
  const normalizeData = {};

  Object.keys(entities).forEach((entity) => {
    normalizeData[entity] = normalizeToArray(
      entities[entity],
      fieldsToRemove[entity]
    );
  });

  return normalizeData;
};
