import { FeathersService } from '$/app/services/feathers.service';
import { WeightLogsApiActions } from '$/app/store/weight-logs/actions';
import { ApiData } from '$/app/utils';
import { Paginated, Params, WeightLog } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WeightLogsApiService {
  private weightLogsService: Service<WeightLog>;

  constructor(store: Store, feathers: FeathersService) {
    this.weightLogsService = feathers.client.service('weight-logs');

    this.weightLogsService.on('created', (weightLog) => {
      const responseData = new ApiData('weightLogs', weightLog);

      responseData.setPrimaryAction(WeightLogsApiActions.weightLogsCreated, {
        payloadKey: 'weightLog'
      });

      responseData.getActions().forEach((action) => {
        store.dispatch(action);
      });
    });

    this.weightLogsService.on('patched', (weightLog) => {
      store.dispatch(
        WeightLogsApiActions.weightLogsPatched({
          weightLog
        })
      );
    });

    this.weightLogsService.on('removed', (weightLog) => {
      store.dispatch(
        WeightLogsApiActions.weightLogsRemoved({
          id: weightLog.id
        })
      );
    });
  }

  getAll(params: Params) {
    const result = this.weightLogsService.find(params) as Promise<
      Paginated<WeightLog>
    >;

    return from(result);
  }

  create(weightLog: WeightLog): Observable<WeightLog> {
    return from(this.weightLogsService.create(weightLog));
  }

  patch(
    id: string,
    weightLog: Partial<WeightLog>,
    params: Params = { query: {} }
  ): Observable<WeightLog> {
    const promise = this.weightLogsService.patch(id, weightLog, params);

    return from(promise);
  }

  delete(id: string, params: Params = { query: {} }): Observable<WeightLog> {
    return from(this.weightLogsService.remove(id, params));
  }
}
