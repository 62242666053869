import { MedicationMar } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationMarsApiActions = createActionGroup({
  source: 'Medication Mars API',
  events: {
    'Patch Medication Mar Success': props<{ medicationMar: MedicationMar }>(),
    'Patch Medication Mar Fail': props<{ error: Error }>()
  }
});

export const MedicationMarsWsActions = createActionGroup({
  source: 'Medication Mars WS',
  events: {
    'Medication Mars Created': props<{ medicationMar: MedicationMar }>(),
    'Medication Mars Patched': props<{ medicationMar: MedicationMar }>(),
    'Medication Mars Removed': props<{ id: string }>(),

    'Medication Mars Batch Created': props<{
      medicationMars: MedicationMar[];
    }>(),
    'Medication Mars Batch Patched': props<{
      medicationMars: MedicationMar[];
    }>(),
    'Medication Mars Batch Removed': props<{ ids: string[] }>()
  }
});

export const MedicationMarsGeneralActions = createActionGroup({
  source: 'Medication Mars General',
  events: {
    'Add Medication Mars': props<{ medicationMars: MedicationMar[] }>()
  }
});
