import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MedicationDestructionItemsEffects } from './medication-destruction-items.effects';
import { medicationDestructionItemsFeature } from './medication-destruction-items.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(medicationDestructionItemsFeature),
    EffectsModule.forFeature([MedicationDestructionItemsEffects])
  ],
  providers: [MedicationDestructionItemsEffects]
})
export class MedicationDestructionItemsStoreModule {}
