import { Paginated } from '$/models';
import { IBloodSugarLog } from '$shared/services/blood-sugar-log';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BloodSugarLogsApiActions = createActionGroup({
  source: 'Blood Sugar Logs API',
  events: {
    'Load Blood Sugar Logs Success': props<{
      bloodSugarLogs: Paginated<IBloodSugarLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Blood Sugar Logs Success': props<{
      bloodSugarLogs: Paginated<IBloodSugarLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Create Blood Sugar Log Success': props<{
      bloodSugarLog: IBloodSugarLog;
    }>(),
    'Update Blood Sugar Log Success': props<{
      bloodSugarLog: IBloodSugarLog;
    }>(),
    'Delete Blood Sugar Log Success': props<{ id: string }>(),

    'Load Blood Sugar Logs Fail': props<{ error: Error }>(),
    'Get Blood Sugar Logs Fail': props<{ error: Error }>(),
    'Create Blood Sugar Log Fail': props<{ error: Error }>(),
    'Update Blood Sugar Log Fail': props<{ error: Error }>(),
    'Delete Blood Sugar Log Fail': props<{ error: Error }>()
  }
});

export const BloodSugarLogsWsActions = createActionGroup({
  source: 'Blood Sugar Logs WS',
  events: {
    'Blood Sugar Logs Created': props<{
      bloodSugarLog: IBloodSugarLog;
    }>(),
    'Blood Sugar Logs Patched': props<{
      bloodSugarLog: IBloodSugarLog;
    }>(),
    'Blood Sugar Logs Removed': props<{ id: string }>()
  }
});

export const BloodSugarLogsGeneralActions = createActionGroup({
  source: 'Blood Sugar Logs General',
  events: {
    'Clear Blood Sugar Logs': emptyProps(),
    'Add Blood Sugar Logs': props<{
      bloodSugarLogs: IBloodSugarLog[];
    }>()
  }
});
