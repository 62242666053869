import { REGEX } from '$shared';
import { DOMAIN_TYPOS_MAP } from '$shared/constants';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { escapeRegExp, isString, toLower, trim } from 'lodash';

const INVALID_DOMAIN_CHARACTERS = `\`'",|\\/?:;[]{}()~!@#$%^&*_=+`;

const INVALID_DOMAIN_CHARACTERS_REGEXP = new RegExp(
  `[${escapeRegExp(INVALID_DOMAIN_CHARACTERS)}]+`,
  'g'
);

const validTlds = new Set([
  'com',
  'net',
  'org',
  'edu',
  'gov',
  'mil',
  'biz',
  'au',
  'uk',
  'ir',
  'in',
  'ph',
  'ca',
  'us',
  'co'
]);

const MUST_CONTAIN_VALID_EMAIL = () => ({
  email: {
    message: 'Must contain a valid email e.g. john@abc.com'
  }
});

const TYPO = (str: string) => ({
  email: {
    message: `Did you mean ${str}?`
  }
});

const DOMAIN_NOT_RECOGNIZED = (domain: string) => ({
  email: {
    message: `${domain} is not a recognized email host.`
  }
});

export function email(control: AbstractControl<string>): ValidationErrors {
  if (!control.value) {
    return null;
  }
  const email = isString(control.value) ? toLower(trim(control.value)) : '';

  const errors = validateEmail(email);

  if (!errors) {
    // If it's good, we're done
    return null;
  }

  // See if we can fix it
  const fixedEmail = fixEmail(email);
  const errorsWithFix = validateEmail(fixedEmail);

  // If we can't fix it, return the original error
  // If we can fix it, return the fixed email as a suggestion
  return errorsWithFix ? errors : TYPO(fixedEmail);
}

function validateEmail(email: string) {
  const domain = extractDomain(email);

  // We check for common typos first because some domain typos are valid according to the email regex we use.
  const typoDomain = DOMAIN_TYPOS_MAP[domain];
  if (typoDomain) {
    return TYPO(typoDomain);
  }

  const isEmail = isString(email) && REGEX.EMAIL.test(email);

  if (!isEmail) {
    return MUST_CONTAIN_VALID_EMAIL();
  }

  const tld = extractTld(domain);

  if (!validTlds.has(tld)) {
    return DOMAIN_NOT_RECOGNIZED(domain);
  }

  return null;
}

function fixEmail(email: string) {
  const domain = extractDomain(email);

  const domainWithForcedPeriods = domain.replace(
    INVALID_DOMAIN_CHARACTERS_REGEXP,
    '.'
  );

  const typoDomain = DOMAIN_TYPOS_MAP[domainWithForcedPeriods];
  if (typoDomain) {
    return `${extractUsername(email)}@${typoDomain}`;
  }

  return `${extractUsername(email)}@${domainWithForcedPeriods}`;
}

function extractUsername(str: string) {
  const [username] = str.split('@') ?? [];

  return username ?? '';
}

function extractDomain(str: string) {
  const [, domain] = str.split('@') ?? [];

  return domain ?? '';
}

function extractTld(str: string) {
  const [, tld] = str.match(/\.([^.]+)$/) || [];

  return tld ?? '';
}
