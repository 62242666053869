import { Paginated } from '$/models';
import { IBodyCheckObservation } from '$shared/services/body-check-observation';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BodyCheckObservationsApiActions = createActionGroup({
  source: 'Body Check Observations API',
  events: {
    'Load Body Check Observations Success': props<{
      bodyCheckObservations: Paginated<IBodyCheckObservation>;
    }>(),
    'Get Body Check Observations Success': props<{
      bodyCheckObservations: Paginated<IBodyCheckObservation>;
    }>(),
    'Create Body Check Observation Success': props<{
      bodyCheckObservation: IBodyCheckObservation;
    }>(),
    'Update Body Check Observation Success': props<{
      bodyCheckObservation: IBodyCheckObservation;
    }>(),
    'Delete Body Check Observation Success': props<{ id: string }>(),

    'Load Body Check Observations Fail': props<{ error: Error }>(),
    'Get Body Check Observations Fail': props<{ error: Error }>(),
    'Create Body Check Observation Fail': props<{ error: Error }>(),
    'Update Body Check Observation Fail': props<{ error: Error }>(),
    'Delete Body Check Observation Fail': props<{ error: Error }>()
  }
});

export const BodyCheckObservationsWsActions = createActionGroup({
  source: 'Body Check Observations WS',
  events: {
    'Body Check Observations Created': props<{
      bodyCheckObservation: IBodyCheckObservation;
    }>(),
    'Body Check Observations Patched': props<{
      bodyCheckObservation: IBodyCheckObservation;
    }>(),
    'Body Check Observations Removed': props<{ id: string }>()
  }
});

export const BodyCheckObservationsGeneralActions = createActionGroup({
  source: 'Body Check Observations General',
  events: {
    'Clear Body Check Observations': emptyProps(),
    'Add Body Check Observations': props<{
      bodyCheckObservations: IBodyCheckObservation[];
    }>()
  }
});
