import { Params, PharmacyPhone, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

export const createPharmacyPhone = createAction(
  '[Pharmacy Phone Form Page] Create Pharmacy Phone',
  props<{ pharmacyPhone: PharmacyPhone }>()
);

export const updatePharmacyPhone = createAction(
  '[Pharmacy Phone Form Page] Update Pharmacy Phone',
  (pharmacyPhone: Update<PharmacyPhone>, params: Params = { query: {} }) => ({
    pharmacyPhone,
    params
  })
);
