const GMAIL_DOMAIN = 'gmail.com';
const HOTMAIL_DOMAIN = 'hotmail.com';
const OUTLOOK_DOMAIN = 'outlook.com';
const AOL_DOMAIN = 'aol.com';
const ICLOUD_DOMAIN = 'icloud.com';
const YAHOO_DOMAIN = 'yahoo.com';

export const DOMAIN_TYPOS_MAP = {
  // Gmail
  gmailcom: GMAIL_DOMAIN,
  'gmial.com': GMAIL_DOMAIN,
  'gmali.com': GMAIL_DOMAIN,
  'gmaill.com': GMAIL_DOMAIN,
  'gamil.com': GMAIL_DOMAIN,
  'gmai.com': GMAIL_DOMAIN,
  'gmail.co': GMAIL_DOMAIN,
  'gmail.coom': GMAIL_DOMAIN,
  'gamil.co': GMAIL_DOMAIN,
  'gmail.con': GMAIL_DOMAIN,
  'gmal.com': GMAIL_DOMAIN,
  'gmail.cmo': GMAIL_DOMAIN,
  'gmail.vom': GMAIL_DOMAIN,
  'gmail.xom': GMAIL_DOMAIN,
  'gmail.fom': GMAIL_DOMAIN,
  'gmail.dcom': GMAIL_DOMAIN,
  'gmai.co': GMAIL_DOMAIN,
  'gmal.co': GMAIL_DOMAIN,
  'gmaiil.com': GMAIL_DOMAIN,
  'gmaul.com': GMAIL_DOMAIN,
  'gmail.cm': GMAIL_DOMAIN,
  'gmaail.com': GMAIL_DOMAIN,
  'ggmail.com': GMAIL_DOMAIN,
  // Hotmail
  hotmailcom: HOTMAIL_DOMAIN,
  'hotmal.com': HOTMAIL_DOMAIN,
  'hotmail.co': HOTMAIL_DOMAIN,
  'hotmial.com': HOTMAIL_DOMAIN,
  'hotmil.com': HOTMAIL_DOMAIN,
  'hotmaill.com': HOTMAIL_DOMAIN,
  'hotmai.co': HOTMAIL_DOMAIN,
  'hotmaol.com': HOTMAIL_DOMAIN,
  'hhotmail.com': HOTMAIL_DOMAIN,
  'hotmai.coml': HOTMAIL_DOMAIN,
  'hoitmail.com': HOTMAIL_DOMAIN,
  'hotmail.con': HOTMAIL_DOMAIN,
  'hoymail.com': HOTMAIL_DOMAIN,
  'hormail.com': HOTMAIL_DOMAIN,
  'hotail.com': HOTMAIL_DOMAIN,
  'hotmali.com': HOTMAIL_DOMAIN,
  'hotmali.co': HOTMAIL_DOMAIN,
  'hotmai.cm': HOTMAIL_DOMAIN,
  'hotnail.com': HOTMAIL_DOMAIN,
  'hotmai.cmo': HOTMAIL_DOMAIN,
  // Outlook
  outlookcom: OUTLOOK_DOMAIN,
  'outlook.om': OUTLOOK_DOMAIN,
  'ooutlook.com': OUTLOOK_DOMAIN,
  'houtlook.com': OUTLOOK_DOMAIN,
  'outlok.com': OUTLOOK_DOMAIN,
  'outlook.cm': OUTLOOK_DOMAIN,
  'outllok.com': OUTLOOK_DOMAIN,
  'outlook.co': OUTLOOK_DOMAIN,
  'outloo.com': OUTLOOK_DOMAIN,
  'outlook.cmo': OUTLOOK_DOMAIN,
  'outllook.com': OUTLOOK_DOMAIN,
  'outlok.cm': OUTLOOK_DOMAIN,
  'otulook.com': OUTLOOK_DOMAIN,
  'outlook.omc': OUTLOOK_DOMAIN,
  'outlook.vom': OUTLOOK_DOMAIN,
  'outlook.xom': OUTLOOK_DOMAIN,
  'outlook.fom': OUTLOOK_DOMAIN,
  'outlook.dcom': OUTLOOK_DOMAIN,
  'outloo.co': OUTLOOK_DOMAIN,
  'outlok.co': OUTLOOK_DOMAIN,
  'outolok.com': OUTLOOK_DOMAIN,
  'outlook.con': OUTLOOK_DOMAIN,
  // AOL
  aolcom: AOL_DOMAIN,
  'aool.com': AOL_DOMAIN,
  'aol.coml': AOL_DOMAIN,
  'ao.com': AOL_DOMAIN,
  'ail.com': AOL_DOMAIN,
  'aol.co': AOL_DOMAIN,
  'aol.cm': AOL_DOMAIN,
  'aoi.com': AOL_DOMAIN,
  'aop.com': AOL_DOMAIN,
  'aoll.com': AOL_DOMAIN,
  'al.com': AOL_DOMAIN,
  'aok.com': AOL_DOMAIN,
  'aol.cim': AOL_DOMAIN,
  'aol.vom': AOL_DOMAIN,
  'aol.xom': AOL_DOMAIN,
  'aol.fom': AOL_DOMAIN,
  'aol.dcom': AOL_DOMAIN,
  'aool.co': AOL_DOMAIN,
  'aoll.co': AOL_DOMAIN,
  'aol.om': AOL_DOMAIN,
  'aol.co.uk': AOL_DOMAIN,
  // Yahoo
  yahoocom: YAHOO_DOMAIN,
  'yahoomail.com': YAHOO_DOMAIN,
  'yahho.com': YAHOO_DOMAIN,
  'yahoomail.co': YAHOO_DOMAIN,
  'yahoomai.com': YAHOO_DOMAIN,
  'yaho.com': YAHOO_DOMAIN,
  'yaho.co': YAHOO_DOMAIN,
  'yahooo.com': YAHOO_DOMAIN,
  'yahoomail.cmo': YAHOO_DOMAIN,
  'yaho.co.uk': YAHOO_DOMAIN,
  'yaho.om': YAHOO_DOMAIN,
  'yahhoo.com': YAHOO_DOMAIN,
  'yaoo.com': YAHOO_DOMAIN,
  'yaho.con': YAHOO_DOMAIN,
  'yhaoo.com': YAHOO_DOMAIN,
  'yajoo.com': YAHOO_DOMAIN,
  'yagoo.com': YAHOO_DOMAIN,
  'yaboo.com': YAHOO_DOMAIN,
  'yauoo.com': YAHOO_DOMAIN,
  'yahol.com': YAHOO_DOMAIN,
  'yahop.com': YAHOO_DOMAIN,
  'yahoi.com': YAHOO_DOMAIN,
  'yaho0.com': YAHOO_DOMAIN,
  // iCloud
  icloudcom: ICLOUD_DOMAIN,
  'iclod.com': ICLOUD_DOMAIN,
  'icloud.co': ICLOUD_DOMAIN,
  'icloud.con': ICLOUD_DOMAIN,
  'icloud.om': ICLOUD_DOMAIN,
  'iclud.com': ICLOUD_DOMAIN,
  'icloudc.com': ICLOUD_DOMAIN,
  'iclloud.com': ICLOUD_DOMAIN,
  'iicloud.com': ICLOUD_DOMAIN,
  'icoud.com': ICLOUD_DOMAIN,
  'icloud.cmo': ICLOUD_DOMAIN,
  'icloud.cm': ICLOUD_DOMAIN,
  'icloud.ocm': ICLOUD_DOMAIN,
  'icluod.com': ICLOUD_DOMAIN,
  'icloud.vom': ICLOUD_DOMAIN,
  'icloud.xom': ICLOUD_DOMAIN,
  'icloud.fom': ICLOUD_DOMAIN,
  'icloud.dcom': ICLOUD_DOMAIN,
  'icloud.cim': ICLOUD_DOMAIN
};
