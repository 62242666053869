import * as FacilityUsersSelectors from '$/app/store/facility-users/facility-users.selectors';
import * as OmittedMedicationsSelectors from '$/app/store/omitted-medications/omitted-medications.selectors';
import { MarsDict, MedicationMarGraph } from '$/models';
import { OmittedAmount } from '$shared/medications/omitted-amount.schema';
import { Dictionary } from '$shared/types';
import { chainFlow } from '$shared/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy, keyBy, map, mapValues } from 'lodash';
import { MedicationSchedulesSelectors } from '../medication-schedules/medication-schedules.selectors';
import { MedicationTasksSelectors } from '../medication-tasks';
import { MedicationsSelectors } from '../medications/medications.selectors';
import { OmittedAmountsSelectors } from '../omitted-amounts/omitted-amounts.selectors';
import { State, medicationMarsAdapter } from './medication-mars.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;
export const getTotalRecords = (state: State): number => state.total;
export const getSkip = (state: State): number => state.skip;

// Feature Selector
export const selectMedicationMarsState =
  createFeatureSelector<State>('medicationMars');
const entitySelectors = medicationMarsAdapter.getSelectors(
  selectMedicationMarsState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;

/**
 * Returns an dictionary where the keys are the task id and the
 * the values are the mar dictionary object.
 */
export const selectMarsByTaskId = createSelector(
  selectAll,
  OmittedMedicationsSelectors.selectEntities,
  OmittedAmountsSelectors.selectAllByOmittedId,
  FacilityUsersSelectors.selectEntities,
  MedicationsSelectors.selectEntities,
  MedicationTasksSelectors.selectEntities,
  MedicationSchedulesSelectors.selectEntities,
  (
    medicationMars,
    omittedMedicationsEntities,
    amountsByOmittedId,
    facilityUserEntities,
    medicationEntities,
    medicationTaskEntities,
    medicationScheduleEntities
  ): Dictionary<MarsDict> => {
    return chainFlow(
      map(medicationMars, (mar) => {
        const omittedMedication =
          omittedMedicationsEntities[mar.omittedMedicationId];
        const omittedAmounts = amountsByOmittedId?.[mar.omittedMedicationId];

        const medication =
          medicationEntities?.[omittedMedication?.medicationId];
        const medicationTask = medicationTaskEntities?.[mar.medicationTaskId];
        const schedule =
          medicationScheduleEntities?.[medicationTask?.medicationScheduleId];

        return {
          ...mar,
          facilityUser: facilityUserEntities[mar.performedBy],
          omittedMedication: {
            ...omittedMedication,
            amounts:
              omittedAmounts?.map((amount) => {
                return new OmittedAmount(
                  amount,
                  schedule?.doseMeasurementId,
                  medication?.formId
                );
              }) || []
          }
        } satisfies MedicationMarGraph;
      }),
      (mars) => groupBy(mars, 'medicationTaskId'),
      (marsByTaskId) => mapValues(marsByTaskId, (mars) => keyBy(mars, 'state'))
    );
  }
);
