import { FacilityUserDetailPageActions } from '$/app/pages/staff/facility-user-detail/facility-user-detail.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  FacilityUserRolesApiActions,
  FacilityUserRolesGeneralActions,
  FacilityUserRolesWsActions
} from './facility-user-roles-api.actions';
import {
  facilityUserRolesAdapter,
  initialState
} from './facility-user-roles.state';

export const facilityUserRolesFeature = createFeature({
  name: 'FacilityUserRoles',
  reducer: createReducer(
    initialState,

    on(FacilityUserDetailPageActions.updateFacilityUserRoles, (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      FacilityUserRolesApiActions.createFacilityUserRoleSuccess,
      (state, action) => {
        const facilityUserRoles = castArray(action.facilityUserRole);

        return facilityUserRolesAdapter.addMany(facilityUserRoles, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      FacilityUserRolesApiActions.deleteFacilityUserRoleSuccess,
      (state, action) => {
        return facilityUserRolesAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      FacilityUserRolesApiActions.createFacilityUserRoleFail,
      FacilityUserRolesApiActions.deleteFacilityUserRoleFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(FacilityUserRolesWsActions.facilityUserRolesCreated, (state, action) => {
      return facilityUserRolesAdapter.upsertOne(action.facilityUserRole, {
        ...state
      });
    }),

    on(FacilityUserRolesWsActions.facilityUserRolesRemoved, (state, action) => {
      return facilityUserRolesAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(
      FacilityUserRolesGeneralActions.addFacilityUserRoles,
      (state, action) => {
        return facilityUserRolesAdapter.upsertMany(action.facilityUserRoles, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(FacilityUserRolesGeneralActions.clearFacilityUserRoles, (state) => {
      return facilityUserRolesAdapter.removeAll({
        ...state,

        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
