import { libraryDocumentTemplatePacketsFeature } from './library-document-template-packets.reducer';
import { libraryDocumentTemplatePacketsAdapter } from './library-document-template-packets.state';

export const {
  selectLibraryDocumentTemplatePacketsState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = libraryDocumentTemplatePacketsFeature;

export const { selectAll, selectTotal } =
  libraryDocumentTemplatePacketsAdapter.getSelectors(
    selectLibraryDocumentTemplatePacketsState
  );
