import { CareTaskItemVM } from '$/app/pages/care/shared/models/care-task-item.vmodel';
import { ResidentsSelectors } from '$/app/store/residents/residents.selectors';
import { CareTask } from '$/models';
import { Dictionary } from '$shared/types';
import { createSelector } from '@ngrx/store';
import { CarePlanItemSchedulesSelectors } from '../care-plan-item-schedules';
import { CarePlanItemsSelectors } from '../care-plan-items';
import { FacilityUsersSelectors } from '../facility-users';
import { RouterSelectors } from '../router';
import { DerivedCareTasksSelectors } from '../shared/selectors';
import { UserSelectors } from '../user';
import { careTasksFeature } from './care-tasks.reducer';
import { careTasksAdapter } from './care-tasks.state';

export const {
  selectCareTasksState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = careTasksFeature;

export const { selectAll, selectTotal } =
  careTasksAdapter.getSelectors(selectCareTasksState);

export const selectEntitiesWithRelations = createSelector(
  selectEntities,
  CarePlanItemsSelectors.selectEntities,
  CarePlanItemSchedulesSelectors.selectEntities,
  ResidentsSelectors.selectEntitiesWithRelations,
  FacilityUsersSelectors.selectEntities,
  DerivedCareTasksSelectors.selectCareTaskLogs,
  (careTasks, carePlanItems, schedules, residents, facilityUsers, logs) => {
    return Object.entries(careTasks).reduce(
      (acc, [id, careTask]): Dictionary<CareTaskItemVM> => {
        acc[id] = new CareTaskItemVM(
          careTask,
          carePlanItems,
          schedules,
          residents,
          facilityUsers,
          logs
        );

        return acc;
      },
      {}
    );
  }
);

export const selectById = (id: string) =>
  createSelector(
    selectEntitiesWithRelations,
    (careTaskEntities) => careTaskEntities[id] || ({} as CareTaskItemVM)
  );

export const selectCareTasks = createSelector(
  selectAll,
  selectEntitiesWithRelations,
  RouterSelectors.selectParam('careItemId'),
  (careTasks, entitiesWithRelations, careItemId): CareTaskItemVM[] => {
    return (
      careTasks?.filter((careTask) => {
        return careTask.carePlanItemId === careItemId;
      }) || []
    ).map((careTask) => entitiesWithRelations[careTask.id]);
  }
);

export const selectAllActive = createSelector(
  selectAll,
  UserSelectors.selectFacilityId,
  CarePlanItemsSelectors.selectEntities,
  CarePlanItemSchedulesSelectors.selectEntities,
  ResidentsSelectors.selectActiveEntities,
  (
    careTasks,
    facilityId,
    carePlanItems,
    carePlanItemSchedules,
    residents
  ): CareTask[] => {
    return careTasks.filter((careTask) => {
      return (
        careTask.facilityId === facilityId &&
        carePlanItems[careTask.carePlanItemId]?.isActive &&
        carePlanItemSchedules[careTask.carePlanItemScheduleId]?.isActive &&
        residents[careTask.residentId]
      );
    });
  }
);

// VIEW MODEL SELECTORS
export const selectCareTask = createSelector(
  selectEntitiesWithRelations,
  RouterSelectors.selectParam('careTaskId'),
  (entities, careTaskId): CareTaskItemVM => {
    if (!careTaskId) {
      return {} as CareTaskItemVM;
    }

    return entities[careTaskId];
  }
);

export const selectFacilityUserSupervisor = createSelector(
  selectCareTask,
  FacilityUsersSelectors.selectEntities,
  (careTask, facilityuserEntities) => {
    return facilityuserEntities[careTask?.completedBy];
  }
);
