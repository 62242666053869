import { InitializationActions } from '$/app/initialization/initialization.actions';
import { MixpanelService, OverlayService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { AlcUnreadAnnouncementsComponent } from '$/app/shared/pages/modals/unread-announcements/unread-announcements.component';
import { State as UserState } from '$/app/store/user/user.state';
import { ApiData } from '$/app/utils';
import { updateSplashScreenText } from '$/lib/splash-screen';
import {
  Acknowledgement,
  Announcement,
  Facility,
  FacilityUserGroup,
  Organization,
  Pharmacy,
  Room,
  Symptom
} from '$/models';
import { ResidentGroupMember } from '$/models/data/entities/residents/resident-group-member.model';
import { IFacilitySetting } from '$shared/facility-settings/types';
import { Logger } from '$shared/logger/logger';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { find } from 'lodash';

// TODO(2024-03-14): Convert all the types to shared TypeBox-derived types, and share this definition with the backend
export interface InitializationData {
  organizations?: Organization[];
  facilities?: Facility[];
  facilityUsers?: IFacilityUser[];
  facilityUserGroups?: FacilityUserGroup[];
  rooms?: Room[];
  facilitySettings?: IFacilitySetting[];
  pharmacies?: Pharmacy[];
  symptoms?: Symptom[];
  notifications?: Notification[];
  announcements?: Announcement[];
  acknowledgements?: Acknowledgement[];
  residentGroupMembers?: ResidentGroupMember[];
}

@Injectable({ providedIn: 'root' })
export class FacilityInitializationService {
  private readonly overlay = inject(OverlayService);
  private readonly store = inject(Store);
  private readonly feathers = inject(FeathersService);
  private readonly mixpanel = inject(MixpanelService);

  public async initialize(userState: UserState) {
    using _timer = Logger.timer('FacilityInitializationService.initialize');

    updateSplashScreenText('Fetching facility data...');

    const initializationData = await this.feathers.client
      .service('initialization')
      .get(null);

    Logger.debug('Initialization Data', { initializationData });

    this.updateMixpanel(userState, initializationData);
    this.dispatchActions(initializationData);
    this.showUnreadAnnouncements(initializationData.announcements);
  }

  private updateMixpanel(
    userState: UserState,
    { organizations, facilities }: InitializationData
  ) {
    const { orgId, facilityId } = userState;

    this.mixpanel.registerSuperProperties({
      orgId,
      facilityId,
      orgName: find(organizations, { id: orgId })?.name,
      facilityName: find(facilities, { id: facilityId })?.name
    });
  }

  private dispatchActions(initializationData: InitializationData) {
    const apiData = new ApiData(
      'initializationData',
      initializationData,
      InitializationActions.getInitializationDataSuccess,
      {
        // HACK: Adding this to suppress a warning in the console
        // This happens because the data is an object and normally
        // objects (not page objects) are interpreted as a single
        // record by the ApiData class. Since our primary actions
        // doesn't take a payload anyway, this is fine.
        singleRecord: false,
        hasPayload: false,
        dispatchOnEmpty: true
      }
    );

    for (const action of apiData.getActions()) {
      this.store.dispatch(action);
    }
  }

  private showUnreadAnnouncements(announcements?: Announcement[]) {
    if (announcements?.length) {
      this.overlay.showModalSingleton({
        component: AlcUnreadAnnouncementsComponent,
        cssClass: 'stacked'
      });
    }
  }
}
