import { Params, Update } from '$/models';
import { ResidentInsurance } from '$/models/data/entities/residents/residentInsurance.model';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentInsuranceFormPageActions = createActionGroup({
  source: 'Resident Insurance Form Page',
  events: {
    'Create Insurance': props<{
      insurance: ResidentInsurance;
      params: Params;
    }>(),
    'Update Insurance': props<Update<ResidentInsurance>>(),
    'Delete Insurance': props<{ id: string; params: Params }>()
  }
});
