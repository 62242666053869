import { ResidentMedicalProfessional } from '$/models';
import { createAction, props } from '@ngrx/store';

//
// 💥👈 USER ACTIONS
//

// Add ResidentMedicalProfessional
export const createResidentMedicalProfessional = createAction(
  '[ResidentMedicalProfessional Form] Add ResidentMedicalProfessional',
  props<{ residentMedicalProfessional: ResidentMedicalProfessional }>()
);
