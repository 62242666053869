import { Paginated } from '$/models';
import { IPositionLog } from '$shared/services/position-log';
import { createAction, props } from '@ngrx/store';

const source = '[Position Logs API]';
const sourceWS = '[Position Logs WS]';

// Load
export const loadPositionLogsSuccess = createAction(
  `${source} Load Position Logs Success`,
  props<{ positionLogs: Paginated<IPositionLog> }>()
);
export const loadPositionLogsFail = createAction(
  `${source} Load Position Logs Fail`,
  props<{ error: Error }>()
);
// Get
export const getPositionLogsSuccess = createAction(
  `${source} Get Position Logs Success`,
  props<{ positionLogs: Paginated<IPositionLog> }>()
);
export const getPositionLogsFail = createAction(
  `${source} Get Position Logs Fail`,
  props<{ error: Error }>()
);
// Create
export const createPositionLogSuccess = createAction(
  `${source} Add Position Logs Success`,
  props<{ positionLog: IPositionLog }>()
);
export const createPositionLogFail = createAction(
  `${source} Add Position Logs Fail`,
  props<{ error: Error }>()
);
// Update
export const updatePositionLogSuccess = createAction(
  `${source} Update Position Log Success`,
  props<{ positionLog: IPositionLog }>()
);
export const updatePositionLogFail = createAction(
  `${source} Update Position Log Fail`,
  props<{ error: Error }>()
);
// Delete
export const deletePositionLogSuccess = createAction(
  `${source} Delete Position Log Success`,
  props<{ id: string }>()
);
export const deletePositionLogFail = createAction(
  `${source} Delete Position Logs Fail`,
  props<{ error: Error }>()
);

// [WS] Position Logs Created
export const positionLogsCreated = createAction(
  `${sourceWS} Position Logs Created`,
  props<{ positionLog: IPositionLog }>()
);
// [WS] Position Logs Patched
export const positionLogsPatched = createAction(
  `${sourceWS} Position Logs Patched`,
  props<{ positionLog: IPositionLog }>()
);
// [WS] Position Logs Removed
export const positionLogsRemoved = createAction(
  `${sourceWS} Position Logs Removed`,
  props<{ id: string }>()
);
