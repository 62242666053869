import { Medication } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationOrderItemComponentActions = createActionGroup({
  source: 'Medication Order Item',
  events: {
    'Add Medication To Order Queue': props<{ medications: Medication[] }>(),
    'Remove Medication From Order Queue': props<{
      medications: Medication[];
    }>()
  }
});
