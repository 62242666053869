import { createStoreActions } from '$/lib/create-store-actions';
import { IResidentReportConfig } from '$shared/services/resident-report-configs';
import { props } from '@ngrx/store';

export const {
  ResidentReportConfigsApiActions,
  ResidentReportConfigsWsActions,
  ResidentReportConfigsGeneralActions
} = createStoreActions(
  'Resident Report Config',
  'Resident Report Configs',
  props<{ residentReportConfig: IResidentReportConfig }>(),
  props<{ residentReportConfigs: IResidentReportConfig[] }>()
);
