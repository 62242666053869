import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DocumentRelationsEffects } from './document-relations.effects';
import { documentRelationsFeature } from './document-relations.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(documentRelationsFeature),
    EffectsModule.forFeature([DocumentRelationsEffects])
  ],
  providers: [DocumentRelationsEffects]
})
export class DocumentRelationsStoreModule {}
