import { AppInfo } from '$/app/services/utils/app-info';
import { Injectable } from '@angular/core';

const shareInfoByPlatform = {
  ios: { icon: 'ios_share', tooltip: 'Share' },
  android: { icon: 'share', tooltip: 'Share' },
  web: { icon: 'download', tooltip: 'Download' }
};

@Injectable({ providedIn: 'root' })
export class PDFCapabilitiesService {
  public canPrint: boolean;
  public shareIcon: string;
  public shareTooltip: string;

  constructor() {
    const { platform } = AppInfo.deviceInfo;
    const { icon, tooltip } = shareInfoByPlatform[platform];
    this.canPrint = platform === 'web';
    this.shareIcon = `assets/icon/material/${icon}.svg`;
    this.shareTooltip = tooltip;
  }
}
