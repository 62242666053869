import {
  ILoggerTransformerOptions,
  LoggerTransformerBase
} from '$shared/logger';
import { isFeathersHookContext } from '$shared/utils';
import { EventEmitter } from '@angular/core';
import { omit } from 'lodash';

export class LoggerTransformer extends LoggerTransformerBase {
  public override transform(
    value: any,
    propertyPath: PropertyKey[],
    options: ILoggerTransformerOptions
  ) {
    if (value instanceof HTMLElement) {
      return '<HTMLElement>';
    }

    if (value instanceof Event) {
      return '<Event>';
    }

    if (value instanceof EventEmitter) {
      return '<EventEmitter>';
    }

    return super.transform(value, propertyPath, options);
  }

  protected override transformRecursive(
    value: any,
    propertyPath: PropertyKey[],
    options: ILoggerTransformerOptions
  ) {
    if (isFeathersHookContext(value)) {
      return this.transform(
        omit(value, ['app', 'service', 'original', 'arguments', 'result']),
        propertyPath,
        options
      );
    }

    return super.transformRecursive(value, propertyPath, options);
  }
}
