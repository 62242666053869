import { AuthenticationActions } from '$/app/authentication/authentication.actions';
import { State, initialState } from '$/app/store/user/user.state';
import { LoggerConfig } from '$shared/logger/logger-config';
import { LoginDataUser } from '$shared/services/user';
import { arrayToBooleanDictionary } from '$shared/utils/bool-dictionary';
import { Action, createReducer, on } from '@ngrx/store';
import { UserApiActions } from './actions/user-api.actions';

const reducer = createReducer(
  initialState,

  on(
    AuthenticationActions.signup,
    AuthenticationActions.login,
    AuthenticationActions.authenticateWithStoredToken,
    AuthenticationActions.authenticateFacilityChange,
    (state) => ({
      ...state,
      loading: true
    })
  ),

  on(
    AuthenticationActions.signupSuccess,
    AuthenticationActions.loginSuccess,
    AuthenticationActions.authenticateWithStoredTokenSuccess,
    AuthenticationActions.authenticateFacilityChangeSuccess,
    (state, { loginData }) => {
      Object.assign(LoggerConfig.alcomyContext, {
        accessToken: loginData.authentication.accessToken,
        geofenceEnforced: loginData.geofenceEnforced
      });

      return {
        ...state,
        // QUESTION(2024-03-18): Why do we need this cast?
        user: loginData.user as unknown as LoginDataUser,
        facilityUserId: loginData.facilityUser.id,
        facilityId: loginData.facilityUser.facilityId,
        orgId: loginData.facilityUser.orgId,
        permissions: arrayToBooleanDictionary(loginData.permissions),
        geofenceEnforced: loginData.geofenceEnforced,
        facilityList: loginData.facilityList,

        isAuthenticated: true,
        loading: false,
        loaded: true,
        error: null
      };
    }
  ),

  on(
    AuthenticationActions.signupFail,
    AuthenticationActions.loginFail,
    AuthenticationActions.authenticateWithStoredTokenFail,
    AuthenticationActions.authenticateFacilityChangeFail,
    (state, { error }) => {
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        loaded: false,
        error
      };
    }
  ),

  on(UserApiActions.fetchUserSuccess, (state, action) => {
    return {
      ...state,
      user: action.user,
      loading: false,
      loaded: true
    };
  }),

  on(UserApiActions.updateUserFail, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  }),

  on(UserApiActions.updateUserSuccess, (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.user
      }
    };
  })
);

export function userReducer(
  state: State = initialState,
  action: Action
): State {
  return reducer(state, action);
}
