import { Params } from '$/models';
import { createAction } from '@ngrx/store';

export const loadMedicationInventories = createAction(
  '[MedicationInventories Page] Load MedicationInventories',
  (params: Params = { query: {} }) => ({ params })
);

export const getMedicationInventories = createAction(
  '[MedicationInventories Page] Get MedicationInventories',
  (params: Params = { query: {} }) => ({ params })
);
