import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IBehavior } from '$shared/services/behavior';
import { IBehaviorLog } from '$shared/services/behavior-log';
import { createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Query } from 'sift';
import { BehaviorsSelectors } from '../behaviors';
import { behaviorLogsFeature } from './behavior-logs.reducer';
import { behaviorLogsAdapter } from './behavior-logs.state';

export type BehaviorLogGraph = IBehaviorLog & {
  behavior?: IBehavior;
};

export const {
  selectBehaviorLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = behaviorLogsFeature;

export const { selectAll, selectTotal } = behaviorLogsAdapter.getSelectors(
  selectBehaviorLogsState
);

export const selectPagination = (query) => {
  return createSelector(selectBehaviorLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectAllWithRelations = createSelector(
  selectAll,
  BehaviorsSelectors.selectEntities,
  (behaviorLogs, behaviors): BehaviorLogGraph[] => {
    return behaviorLogs.map((behaviorLog) => ({
      ...behaviorLog,
      behavior: behaviors[behaviorLog.behaviorId]
    }));
  }
);

export const selectResidentBehaviorLogs = createSelector(
  selectAllWithRelations,
  RouterSelectors.selectParam('residentId'),
  (behaviorLogs, residentId): BehaviorLogGraph[] => {
    if (isEmpty(behaviorLogs) || !residentId) {
      return [];
    }

    return behaviorLogs.filter((behaviorLog) => {
      return behaviorLog.residentId === residentId;
    });
  }
);

export const selectLogsByMedicationTask = createLogsByMedicationTaskSelector(
  selectAllWithRelations
);

export const selectLogsByCareTask = createLogsByCareTaskSelector(
  selectAllWithRelations
);

export const selectLogsByPrn = createLogsByPrnSelector(selectAllWithRelations);

export const selectLogsByQuery = (query: Query<IBehaviorLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IBehaviorLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IBehaviorLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => ({
    metrics: [
      {
        type: 'value',
        label: 'Records',
        data: logs?.length ?? 0
      }
    ],
    logs
  }));
};
