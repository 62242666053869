import { IDocumentParticipant } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const DocumentParticipantsApiActions = createActionGroup({
  source: 'Document Participants API',
  events: {
    'Update Document Participant Success': props<{
      documentParticipant: IDocumentParticipant;
    }>(),
    'Update Document Participant Fail': props<{ error: Error }>()
  }
});

export const DocumentParticipantsWsActions = createActionGroup({
  source: 'Document Participants WS',
  events: {
    'Document Participant Created': props<{
      documentParticipant: IDocumentParticipant;
    }>(),
    'Document Participant Patched': props<{
      documentParticipant: IDocumentParticipant;
    }>(),
    'Document Participant Removed': props<{ id: string }>()
  }
});

export const DocumentParticipantsGeneralActions = createActionGroup({
  source: 'Document Participants General',
  events: {
    'Clear Document Participants': emptyProps(),
    'Add Document Participants': props<{
      documentParticipants: IDocumentParticipant[];
    }>()
  }
});
