import { FacilityUserGroup } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserGroupsWsActions = createActionGroup({
  source: 'Facility User Group WS',
  events: {
    'Facility User Groups Created': props<{
      facilityUserGroup: FacilityUserGroup;
    }>(),
    'Facility User Groups Patched': props<{
      facilityUserGroup: FacilityUserGroup;
    }>(),
    'Facility User Groups Removed': props<{ id: string }>()
  }
});
