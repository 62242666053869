import { EventsListActions } from '$/app/pages/notebook/residents/events-list/events-list.actions';
import { MedicalEventModalActions } from '$/app/pages/notebook/residents/medical-event-modal/medical-event.modal.actions';
import { EffectHelpersService, MedicalEventsApiService } from '$/app/services';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { MedicalEventsApiActions } from './actions';

@Injectable()
export class MedicalEventsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly medicalEventsService = inject(MedicalEventsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadMedicalEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EventsListActions.loadMedicalEvents),
      switchMap((action) => {
        return this.medicalEventsService.getAll(action.params).pipe(
          map((medicalEvents) => {
            return MedicalEventsApiActions.loadMedicalEventsSuccess({
              medicalEvents
            });
          }),
          catchError((error) => {
            return of(MedicalEventsApiActions.loadMedicalEventsFail({ error }));
          })
        );
      })
    );
  });

  createMedicalEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicalEventModalActions.createMedicalEvent),
      exhaustMap((action) => {
        return this.medicalEventsService.create(action.medicalEvent).pipe(
          map((event) => {
            return MedicalEventsApiActions.createMedicalEventSuccess({
              medicalEvent: event
            });
          }),
          catchError((error) => {
            return of(
              MedicalEventsApiActions.createMedicalEventFail({
                error
              })
            );
          })
        );
      })
    );
  });

  updateMedicalEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicalEventModalActions.updateMedicalEvent),
      switchMap((action) => {
        return this.medicalEventsService.patch(action.id, action.changes).pipe(
          map((medicalEvent) => {
            return MedicalEventsApiActions.updateMedicalEventSuccess({
              medicalEvent
            });
          }),
          catchError((error) => {
            return of(
              MedicalEventsApiActions.updateMedicalEventFail({
                error
              })
            );
          })
        );
      })
    );
  });

  deleteMedicalEvent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsListActions.deleteMedicalEvent),
        exhaustMap((action) => {
          return this.medicalEventsService.delete(action.id).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'MedicalEvent deleted successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                MedicalEventsApiActions.deleteMedicalEventFail({
                  error
                })
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteMedicalEventFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MedicalEventsApiActions.deleteMedicalEventFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
