/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Static, TObject, Type } from '@sinclair/typebox';
import { StrictObject } from './object';

export function setRequired<T extends TObject, K extends keyof Static<T>>(
  schema: T,
  keys: K[]
) {
  return StrictObject({
    ...Type.Required(
      Type.Mapped(Type.KeyOf(Type.Pick(schema, keys)), (key) =>
        Type.Exclude(
          Type.Index(schema, key),
          Type.Union([Type.Null(), Type.Undefined()])
        )
      )
    ).properties,
    ...Type.Partial(Type.Omit(schema, keys)).properties
  });
}
