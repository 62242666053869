import { IUser } from '../services/user';
import { TypeBox } from '../type-box';

export interface JWTPayload {
  orgId?: string;
  facilityId?: string;
  facilityUserId?: string;
  iat: number;
  exp: number;
  aud: string;
  iss: string;
  sub: string; // userId
  jti: string;
}

export type ValidateSignupTokenResult =
  | {
      status: 'Valid' | 'Expired';
      user: Pick<IUser, 'id' | 'firstName' | 'lastName'>;
    }
  | {
      status: 'Invalid';
      user?: undefined;
    };

export const SignupDataSchema = TypeBox.StrictObject({
  signupToken: TypeBox.OpaqueToken(),
  facilityId: TypeBox.Optional(TypeBox.Id()),
  firstName: TypeBox.String(),
  lastName: TypeBox.String(),
  password: TypeBox.String()
});

export type SignupData = TypeBox.Infer<typeof SignupDataSchema>;
