import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const UrineLogsPageActions = createActionGroup({
  source: 'Urine Log Page',
  events: {
    'Load Urine Logs': (params: Params) => ({ params }),
    'Get Urine Logs': (params: Params) => ({ params }),
    'Delete Urine Log': props<{ id: string; params?: Params }>()
  }
});
