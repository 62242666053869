import { Logger } from '$shared/logger/logger';
import { backgroundTask } from '$shared/utils';
import { IonModal } from '@ionic/angular/common';
import { IonPopover } from '@ionic/angular/standalone';

type HasDismiss = Pick<IonModal, 'dismiss'> & Pick<IonPopover, 'dismiss'>;

export class AlcModal {
  private get controller(): HasDismiss {
    const controller = (this as any).modal ?? (this as any).popover;

    Logger.assert(controller, 'AlcomyModal.close: controller is undefined');

    return controller;
  }

  protected close(data?: any) {
    backgroundTask('AlcomyModal.close', () => this.controller.dismiss(data));
  }
}
