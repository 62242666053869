import { TMedicationInventoryItemCount } from '$shared/medications/medication-inventory-item-count.schema';
import { MaybeArray } from '$shared/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MedicationInventoryItemCountsApiActions = createActionGroup({
  source: 'Medication Inventory Item Counts API',
  events: {
    'Load Medication Inventory Item Counts Success': props<{
      medicationInventoryItemCounts: TMedicationInventoryItemCount[];
    }>(),
    'Get Medication Inventory Item Counts Success': props<{
      medicationInventoryItemCounts: TMedicationInventoryItemCount[];
    }>(),
    'Fetch Medication Inventory Item Count Success': props<{
      medicationInventoryItemCount: TMedicationInventoryItemCount;
    }>(),
    'Create Medication Inventory Item Count Success': props<{
      medicationInventoryItemCount: MaybeArray<TMedicationInventoryItemCount>;
    }>(),
    'Update Medication Inventory Item Count Success': props<{
      medicationInventoryItemCount: TMedicationInventoryItemCount;
    }>(),
    'Delete Medication Inventory Item Count Success': props<{ id: string }>(),
    'Load Medication Inventory Item Counts Fail': props<{ error: Error }>(),
    'Get Medication Inventory Item Counts Fail': props<{ error: Error }>(),
    'Fetch Medication Inventory Item Count Fail': props<{ error: Error }>(),
    'Create Medication Inventory Item Count Fail': props<{ error: Error }>(),
    'Update Medication Inventory Item Count Fail': props<{ error: Error }>(),
    'Delete Medication Inventory Item Count Fail': props<{ error: Error }>()
  }
});

export const MedicationInventoryItemCountsWsActions = createActionGroup({
  source: 'Medication Inventory Item Counts WS',
  events: {
    'Medication Inventory Item Count Created': props<{
      medicationInventoryItemCount: TMedicationInventoryItemCount;
    }>(),
    'Medication Inventory Item Count Patched': props<{
      medicationInventoryItemCount: TMedicationInventoryItemCount;
    }>(),
    'Medication Inventory Item Count Removed': props<{ id: string }>()
  }
});

export const MedicationInventoryItemCountsGeneralActions = createActionGroup({
  source: 'Medication Inventory Item Counts General',
  events: {
    'Clear Medication Inventory Item Counts': emptyProps(),
    'Add Medication Inventory Item Counts': props<{
      medicationInventoryItemCounts: TMedicationInventoryItemCount[];
    }>()
  }
});
