import { CarePlanItem, Paginated } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CarePlanItemsApiActions = createActionGroup({
  source: 'Care Plan Items API',
  events: {
    'Load Care Plan Items Success': props<{
      carePlanItems: Paginated<CarePlanItem>;
    }>(),
    'Get Care Plan Items Success': props<{
      carePlanItems: Paginated<CarePlanItem>;
    }>(),
    'Fetch Care Plan Item Success': props<{ carePlanItem: CarePlanItem }>(),
    'Create Care Plan Item Success': props<{
      carePlanItem: MaybeArray<CarePlanItem>;
    }>(),
    'Update Care Plan Item Success': props<{ carePlanItem: CarePlanItem }>(),
    'Delete Care Plan Item Success': props<{ id: string }>(),

    'Load Care Plan Items Fail': props<{ error: Error }>(),
    'Get Care Plan Items Fail': props<{ error: Error }>(),
    'Fetch Care Plan Item Fail': props<{ error: Error }>(),
    'Create Care Plan Item Fail': props<{ error: Error }>(),
    'Update Care Plan Item Fail': props<{ error: Error }>(),
    'Delete Care Plan Item Fail': props<{ error: Error }>()
  }
});

export const CarePlanItemsWsActions = createActionGroup({
  source: 'Care Plan Items WS',
  events: {
    'Care Plan Items Created': props<{ carePlanItem: CarePlanItem }>(),
    'Care Plan Items Patched': props<{ carePlanItem: CarePlanItem }>(),
    'Care Plan Items Removed': props<{ id: string }>()
  }
});

export const CarePlanItemsGeneralActions = createActionGroup({
  source: 'Care Plan Items General',
  events: {
    'Clear Care Plan Items': emptyProps(),
    'Add Care Plan Items': props<{
      carePlanItems: CarePlanItem[];
    }>()
  }
});
