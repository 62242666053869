import { FacilitiesSelectors, UserSelectors } from '$/app/store';
import { FeatureFlag } from '$shared/constants/feature-flags';
import { PermissionId } from '$shared/permissions';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { castArray } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  private readonly store = inject(Store);

  private readonly permissions = this.store.selectSignal(
    UserSelectors.selectPermissions
  );

  private readonly featureFlags = this.store.selectSignal(
    FacilitiesSelectors.selectFeatureFlags
  );

  public hasPermission(permissionId: MaybeArray<PermissionId>): boolean {
    return checkPermission(this.permissions(), permissionId);
  }

  public hasFeatureFlag(featureFlag: FeatureFlag): boolean {
    return this.featureFlags()[featureFlag];
  }
}

export function checkPermission(
  permissions: Partial<Record<PermissionId, boolean>> | undefined,
  permissionId: MaybeArray<PermissionId>
) {
  return castArray(permissionId).some(
    (permissionId) => permissions?.[permissionId]
  );
}
