import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { uiCarListPageFeature } from './car-list-page.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(uiCarListPageFeature)]
})
export class UiCarListPageStoreModule {}
