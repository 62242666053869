import {
  MedicalProfessionalPhonesApiActions,
  MedicalProfessionalPhonesGeneralActions
} from '$/app/store/medical-professional-phones/actions';
import { MedicalProfessionalPhonesStoreModule } from '$/app/store/medical-professional-phones/medical-professional-phones-store.module';
import * as MedicalProfessionalPhonesSelectors from '$/app/store/medical-professional-phones/medical-professional-phones.selectors';
import * as MedicalProfessionalPhonesState from '$/app/store/medical-professional-phones/medical-professional-phones.state';

export {
  MedicalProfessionalPhonesApiActions,
  MedicalProfessionalPhonesGeneralActions,
  MedicalProfessionalPhonesSelectors,
  MedicalProfessionalPhonesState,
  MedicalProfessionalPhonesStoreModule
};
