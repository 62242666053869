import { Update } from '$/models';
import { IBehavior } from '$shared/services/behavior';
import { createActionGroup, props } from '@ngrx/store';

export const BehaviorModalActions = createActionGroup({
  source: 'Behavior Modal',
  events: {
    'Create Behavior': props<{ behavior: IBehavior }>(),
    'Update Behavior': props<Update<IBehavior>>()
  }
});
