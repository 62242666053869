import { SeizureLog, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const SeizureLogFormPageActions = createActionGroup({
  source: 'Seizure Log Form Page',
  events: {
    'Create Seizure Log': props<{
      seizureLog: SeizureLog & { symptoms: string[] };
    }>(),
    'Update Seizure Log': props<Update<SeizureLog & { symptoms: string[] }>>()
  }
});
