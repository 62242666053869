import { Params, Update } from '$/models';
import { props } from '@ngrx/store';

import { IMedicationInventoryItemCountVM } from '$/app/pages/medications/shared/vmodels/medication-inventory-item-count.vmodel';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { createActionGroup } from '@ngrx/store';

export const NarcoticCountListPageActions = createActionGroup({
  source: 'Narcotic Count List Page',
  events: {
    'Get Medications': (params: Params) => ({ params }),
    'Create Medication Inventory Item Count': props<{
      medicationInventoryItemCount: IMedicationInventoryItemCountVM;
      params: Params;
    }>(),
    'Mark Inventory Empty': props<Update<IMedicationInventoryItem>>(),
    'Delete Medication Inventory Item Count': props<{
      id: string;
      params?: Params;
    }>()
  }
});
