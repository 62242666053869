import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { uiShiftNotesPageFeature } from './shift-notes.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(uiShiftNotesPageFeature)]
})
export class UiShiftNotesPageStoreModule {}
