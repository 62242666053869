import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { DateTimeFormats } from '$shared/constants/datetime-formats';

export function dateRangeToQuery(dateRange: IDateRangePickerValue) {
  const { type, preset, year, startDate, endDate } = dateRange;

  return {
    type,
    preset,
    year,
    startDate: startDate.toFormat(DateTimeFormats.DATE_SHORT),
    endDate: endDate.toFormat(DateTimeFormats.DATE_SHORT)
  };
}
