import { Logger } from '$shared/logger';
import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
@Pipe({
  name: 'pluralize',
  standalone: true
})
export class AlcPluralizePipe implements PipeTransform {
  /**
   * Transforms returns the plural or singular form of a word based on the count.
   * By default, the count is included in the output.
   *
   * @param count Can be a number or a string number.
   * @param word When word is an array, the first element is the singular form and the second element is the plural form.
   * @param inclusive When true, the count is included in the output.
   * @returns
   */
  transform(
    count: number | string,
    word: string | string[],
    inclusive: boolean = true
  ) {
    if (isNil(count)) {
      return count;
    }

    let _count;

    if (typeof count === 'string') {
      _count = parseFloat(count);
    } else {
      _count = count;
    }

    if (isNaN(_count)) {
      Logger.warn('PluralizePipe: count is not a number', { count, _count });
      return count;
    }

    if (Array.isArray(word)) {
      const [singular, plural] = word;

      const text = _count === 1 ? singular : plural;

      return inclusive ? `${_count} ${text}` : text;
    }

    return pluralize(word, _count, inclusive);
  }
}
