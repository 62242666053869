import { WaterLog, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const WaterLogFormPageActions = createActionGroup({
  source: 'Water Log Form Page',
  events: {
    'Create Water Log': props<{
      waterLog: WaterLog;
    }>(),
    'Update Water Log': props<Update<WaterLog>>()
  }
});
