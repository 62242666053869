import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { Dictionary } from '$shared/types';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class ReportsService extends AbstractApiService<any> {
  constructor(feathers: FeathersService, store: Store) {
    super('reports', feathers, store);

    this.service.timeout = 30000;
  }

  generatePdf(
    reportName: string,
    params: Dictionary<any>
  ): Promise<{ pdfPath: string }> {
    return this.service.find({ query: { reportName, params } });
  }
}
