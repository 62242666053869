import { sortByFields } from '$/app/utils';
import { Medication } from '$/models';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationsAdapter = createEntityAdapter<Medication>({
  sortComparer: sortByFields('brandName', 'genericName')
});

export interface State extends EntityState<Medication> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
