import { IDocumentTemplate, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityLibraryPageActions = createActionGroup({
  source: 'Facility Library Page',
  events: {
    'Load Document Templates': (params: Params) => ({ params }),
    'Get Document Template Packets': (params?: Params) => ({ params }),
    'Install Library Collections': props<{
      data: { level: 'org' | 'fac'; collectionIds: string[] };
      params?: Params;
    }>(),
    'Delete Document Template': props<{ id: string; params?: Params }>(),
    'Delete Document Template Packet': props<{ id: string; params?: Params }>(),
    'Move Documents To Template': props<Update<IDocumentTemplate>>()
  }
});
