import { Dictionary } from '$shared/types';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { keyBy } from 'lodash';

export function createLogsByMedicationTaskSelector<
  T extends { medicationTaskId?: string }
>(logSelector: MemoizedSelector<object, T[]> | ((state: any) => T[])) {
  return createSelector(logSelector, (logs): Dictionary<T> => {
    const logsWithMedicationTaskId = logs.filter((log) => log.medicationTaskId);
    return keyBy(logsWithMedicationTaskId, 'medicationTaskId');
  });
}

export function createLogsByCareTaskSelector<T extends { careTaskId?: string }>(
  logSelector: MemoizedSelector<object, T[]> | ((state: any) => T[])
) {
  return createSelector(logSelector, (logs): Dictionary<T> => {
    const logsWithCareTaskId = logs.filter((log) => log.careTaskId);
    return keyBy(logsWithCareTaskId, 'careTaskId');
  });
}

export function createLogsByPrnSelector<T extends { prnId?: string }>(
  logSelector: MemoizedSelector<object, T[]> | ((state: any) => T[])
) {
  return createSelector(logSelector, (logs): Dictionary<T> => {
    const logsWithPrnId = logs.filter((log) => log.prnId);

    return keyBy(logsWithPrnId, 'prnId');
  });
}
