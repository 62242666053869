import { MedicationSchedule, Paginated } from '$/models';
import { createAction, props } from '@ngrx/store';

// [API] Load Success/Fail
export const loadMedicationSchedulesSuccess = createAction(
  '[MedicationSchedules API] Load MedicationSchedules Success',
  props<{ medicationSchedules: Paginated<MedicationSchedule> }>()
);
export const loadMedicationSchedulesFail = createAction(
  '[MedicationSchedules API] Load MedicationSchedules Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getMedicationSchedulesSuccess = createAction(
  '[MedicationSchedules API] Get MedicationSchedules Success',
  props<{ medicationSchedules: Paginated<MedicationSchedule> }>()
);
export const getMedicationSchedulesFail = createAction(
  '[MedicationSchedules API] Get MedicationSchedules Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchMedicationScheduleSuccess = createAction(
  '[MedicationSchedule API] Fetch MedicationSchedule Success',
  props<{ medicationSchedule: MedicationSchedule }>()
);
export const fetchMedicationScheduleFail = createAction(
  '[MedicationSchedule API] Fetch MedicationSchedule Fail',
  props<{ error: Error }>()
);

// [WS] MedicationSchedules Created
export const medicationSchedulesCreated = createAction(
  '[MedicationSchedules WS] MedicationSchedules Created',
  props<{ medicationSchedule: MedicationSchedule }>()
);
// [WS] MedicationSchedules Patched
export const medicationSchedulesPatched = createAction(
  '[MedicationSchedules WS] MedicationSchedules Patched',
  props<{ medicationSchedule: MedicationSchedule }>()
);
// [WS] MedicationSchedules Removed
export const medicationSchedulesRemoved = createAction(
  '[MedicationSchedules WS] MedicationSchedules Removed',
  props<{ id: string }>()
);
