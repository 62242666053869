import { Paginated, ResidentLogType } from '$/models';
import { createAction, props } from '@ngrx/store';

const source = '[Resident Log Types API]';
const sourceWS = '[Resident Log Types WS]';

// Load
export const loadResidentLogTypesSuccess = createAction(
  `${source} Load Resident Log Types Success`,
  props<{
    residentLogTypes: Paginated<ResidentLogType>;
  }>()
);
export const loadResidentLogTypesFail = createAction(
  `${source} Load Resident Log Types Fail`,
  props<{ error: Error }>()
);
// Get
export const getResidentLogTypesSuccess = createAction(
  `${source} Get Resident Log Types Success`,
  props<{
    residentLogTypes: Paginated<ResidentLogType>;
  }>()
);
export const getResidentLogTypesFail = createAction(
  `${source} Get Resident Log Types Fail`,
  props<{ error: Error }>()
);
// Create
export const createResidentLogTypeSuccess = createAction(
  `${source} Create Resident Log Type Success`,
  props<{ residentLogType: ResidentLogType }>()
);
export const createResidentLogTypeFail = createAction(
  `${source} Create Resident Log Type Fail`,
  props<{ error: Error }>()
);
// Delete
export const deleteResidentLogTypeSuccess = createAction(
  `${source} Delete Resident Log Type Success`,
  props<{ id: string }>()
);
export const deleteResidentLogTypeFail = createAction(
  `${source} Delete Resident Log Types Fail`,
  props<{ error: Error }>()
);

// [WS] Resident Log Types Created
export const residentLogTypesCreated = createAction(
  `${sourceWS} Resident Log Types Created`,
  props<{ residentLogType: ResidentLogType }>()
);
// [WS] Resident Log Types Patched
export const residentLogTypesPatched = createAction(
  `${sourceWS} Resident Log Types Patched`,
  props<{ residentLogType: ResidentLogType }>()
);
// [WS] Resident Log Types Removed
export const residentLogTypesRemoved = createAction(
  `${sourceWS} Resident Log Types Removed`,
  props<{ id: string }>()
);
