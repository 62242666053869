import { IFacilityUser } from '$shared/services/facility-user.schema';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUsersApiActions = createActionGroup({
  source: 'Facility Users API',
  events: {
    'Load Facility Users Success': props<{ facilityUsers: IFacilityUser[] }>(),
    'Get Facility Users Success': props<{ facilityUsers: IFacilityUser[] }>(),
    'Fetch Facility User Success': props<{ facilityUser: IFacilityUser }>(),
    'Create Facility User Success': props<{ facilityUser: IFacilityUser }>(),
    'Update Facility User Success': props<{
      facilityUser: IFacilityUser;
    }>(),
    'Delete Facility User Success': props<{ id: string }>(),

    'Load Facility Users Fail': props<{ error: Error }>(),
    'Get Facility Users Fail': props<{ error: Error }>(),
    'Fetch Facility User Fail': props<{ error: Error }>(),
    'Create Facility User Fail': props<{ error: Error }>(),
    'Update Facility User Fail': props<{ error: Error }>(),
    'Delete Facility User Fail': props<{ error: Error }>()
  }
});

export const FacilityUsersWsActions = createActionGroup({
  source: 'Facility Users WS',
  events: {
    'Facility Users Created': props<{ facilityUser: IFacilityUser }>(),
    'Facility Users Patched': props<{ facilityUser: IFacilityUser }>(),
    'Facility Users Removed': props<{ id: string }>()
  }
});
