import { Facility, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityProfilePageActions = createActionGroup({
  source: 'Facility Profile Page',
  events: {
    'Fetch Facility': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),

    'Load Rooms': (params: Params = { query: {} }) => ({ params }),
    'Delete Room': props<{ id: string }>(),
    'Upload Logo File': props<Update<Facility>>(),
    'Remove Logo File': props<Update<Facility>>()
  }
});
