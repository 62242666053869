import { IMedicationOrder } from '$shared/medications/medication-order.schema';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationOrdersAdapter = createEntityAdapter<IMedicationOrder>();

export interface State extends EntityState<IMedicationOrder> {
  total: number;
  skip: number;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationOrdersAdapter.getInitialState({
  total: 0,
  skip: 0,
  loading: false,
  loaded: false,
  error: null
});
