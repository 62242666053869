import { ResidentContact } from '$/models';
import { createAction, props } from '@ngrx/store';

export const clearResidentContacts = createAction(
  '[ResidentContacts] Clear ResidentContacts'
);

export const addResidentContacts = createAction(
  'Add Resident Contacts',
  props<{ residentContacts: ResidentContact[] }>()
);
