import { MedicalHistory, Update } from '$/models';
import { createAction, props } from '@ngrx/store';

export const createMedicalHistorySuccess = createAction(
  '[Medical Histories API] Add Medical History Success',
  props<{ medicalHistory: MedicalHistory }>()
);

export const createMedicalHistoryFail = createAction(
  '[Medical Histories API] Add Medical History Fail',
  props<{ error: Error }>()
);

export const updateMedicalHistorySuccess = createAction(
  '[Medical Histories API] Update Medical History Success',
  props<{ medicalHistory: Update<MedicalHistory> }>()
);

export const updateMedicalHistoryFail = createAction(
  '[Medical Histories API] Update Medical History Fail',
  props<{ error: Error }>()
);

export const deleteMedicalHistorySuccess = createAction(
  '[Medical Histories API] Delete Medical History Success',
  props<{ id: string }>()
);

export const deleteMedicalHistoryFail = createAction(
  '[Medical Histories API] Delete Medical History Fail',
  props<{ error: Error }>()
);

// [WS] Medical Histories Created
export const medicalHistoriesCreated = createAction(
  '[Medical Histories API WS] Medical Histories Created',
  props<{ medicalHistory: MedicalHistory }>()
);
// [WS] Medical Histories Patched
export const medicalHistoriesPatched = createAction(
  '[Medical Histories API WS] Medical Histories Patched',
  props<{ medicalHistory: MedicalHistory }>()
);
// [WS] Medical Histories Removed
export const medicalHistoriesRemoved = createAction(
  '[Medical Histories API WS] Medical Histories Removed',
  props<{ id: string }>()
);
