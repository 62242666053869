import { UserGeolocation } from '$shared/facility-settings/geolocation.types';

export interface State {
  geolocation: UserGeolocation;
}

export const initialState: State = {
  geolocation: {
    status: 'initial state',
    location: {}
  } as UserGeolocation
};
