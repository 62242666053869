import { sortByDatetime } from '$/app/utils';
import { TMedicationInventoryItemCount } from '$shared/medications/medication-inventory-item-count.schema';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationInventoryItemCountsAdapter =
  createEntityAdapter<TMedicationInventoryItemCount>({
    sortComparer: sortByDatetime('createdAt')
  });

export interface State extends EntityState<TMedicationInventoryItemCount> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  medicationInventoryItemCountsAdapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
  });
