import { Params, Prn, Update } from '$/models';
import { boolDictionaryToArray } from '$shared/utils/bool-dictionary';
import { createAction, props } from '@ngrx/store';
import { PrnMarListFilters } from './prn-mar-list.vmodel';

const source = '[Prn Mar List Page]';

interface GetQueryOptions {
  medicationBatchId: string;
  filters: PrnMarListFilters;
  $skip?: number;
  params?: Params;
}

const baseQuery = {
  $limit: 100,
  $eager: {
    medication: true,
    giver: true,
    responder: true
  }
};

export const loadPrns = createAction(
  `${source} Load Prns`,
  ({ medicationBatchId, filters, $skip, params }: GetQueryOptions) => {
    const { query, ...rest } = params || {};

    return {
      params: {
        query: {
          ...baseQuery,
          medicationBatchId,
          $or: getResponseQuery(filters),
          givenAt: {
            $gte: filters.dateRange.startDate.toISO(),
            $lte: filters.dateRange.endDate.toISO()
          },
          $skip: $skip || 0
        },
        ...rest
      }
    };
  }
);

export const getPrns = createAction(
  `${source} Get Prns`,
  ({ medicationBatchId, filters, $skip, params }: GetQueryOptions) => {
    const { query, ...rest } = params || {};

    return {
      params: {
        query: {
          ...baseQuery,
          medicationBatchId,
          $or: getResponseQuery(filters),
          givenAt: {
            $gte: filters.dateRange.startDate.toISO(),
            $lte: filters.dateRange.endDate.toISO()
          },
          $skip: $skip || 0
        },
        ...rest
      }
    };
  }
);

export const updatePrn = createAction(
  `${source} Update Prn`,
  props<Update<Prn>>()
);

export const PrnMarListPageActions = {
  loadPrns,
  getPrns,
  updatePrn
};

function getResponseQuery(filters: PrnMarListFilters) {
  const { noresponse, ...responses } = filters.responses;

  const noResponseQuery = { response: { $null: true } };
  const responsesQuery = {
    response: {
      $in: boolDictionaryToArray(responses)
    }
  };

  return noresponse ? [noResponseQuery, responsesQuery] : [responsesQuery];
}
