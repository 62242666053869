import { CompleteCareTaskModalActions } from '$/app/pages/care/shared/components/complete-care-task-modal/complete-care-task.modal.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { BloodSugarLogsPageActions } from '$/app/pages/notebook/shared/logs/blood-sugar-log/blood-sugar-log.actions';
import { BloodSugarLogsApiService, EffectHelpersService } from '$/app/services';
import { BloodSugarLogFormPageActions } from '$/app/shared/pages/forms/log-forms/blood-sugar-log-form/blood-sugar-log-form.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { IBloodSugarLog } from '$shared/services/blood-sugar-log';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { BloodSugarLogsApiActions } from './blood-sugar-logs.actions';

@Injectable()
export class BloodSugarLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly bloodSugarLogsService = inject(BloodSugarLogsApiService);

  loadBloodSugarLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BloodSugarLogsPageActions.loadBloodSugarLogs),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.bloodSugarLogsService.getAll(action.params).pipe(
          mergeMap((logs) => {
            const responseData = new ApiData('bloodSugarLogs', logs);
            responseData.setPrimaryAction(
              BloodSugarLogsApiActions.loadBloodSugarLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          }),
          catchError((error) => {
            return of(
              BloodSugarLogsApiActions.loadBloodSugarLogsFail({ error })
            );
          })
        );
      })
    );
  });

  getBloodSugarLogsNoPagination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BloodSugarLogsPageActions.getBloodSugarLogs,
        LogsPageActions.getBloodSugarLogs,
        GivePrnFormActions.getBloodSugarLogs
      ),
      switchMap((action) => {
        const pageQueryType = 'all';

        return this.bloodSugarLogsService.getAll(action.params).pipe(
          getAllPages(this.bloodSugarLogsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((logs) => {
            const responseData = new ApiData('bloodSugarLogs', logs);
            responseData.setPrimaryAction(
              BloodSugarLogsApiActions.getBloodSugarLogsSuccess,
              {
                metadata: {
                  pageQueryType
                }
              }
            );
            return responseData.getActions();
          })
        );
      })
    );
  });

  createBloodSugarLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BloodSugarLogFormPageActions.createBloodSugarLog),
      exhaustMap((action) => {
        return this.bloodSugarLogsService.create(action.bloodSugarLog).pipe(
          tap((data: IBloodSugarLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((bloodSugarLog: IBloodSugarLog) =>
            BloodSugarLogsApiActions.createBloodSugarLogSuccess({
              bloodSugarLog
            })
          ),
          catchError((error) =>
            of(
              BloodSugarLogsApiActions.createBloodSugarLogFail({
                error
              })
            )
          )
        );
      })
    );
  });

  updateBloodSugarLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BloodSugarLogFormPageActions.updateBloodSugarLog),
      switchMap((action) => {
        return this.bloodSugarLogsService.patch(action.id, action.changes).pipe(
          tap((data: IBloodSugarLog) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((bloodSugarLog: IBloodSugarLog) => {
            return BloodSugarLogsApiActions.updateBloodSugarLogSuccess({
              bloodSugarLog
            });
          }),
          catchError((error) => {
            return of(
              BloodSugarLogsApiActions.updateBloodSugarLogFail({ error })
            );
          })
        );
      })
    );
  });

  deleteBloodSugarLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BloodSugarLogsPageActions.deleteBloodSugarLog,
        ResidentMedicationTasksPageActions.deleteBloodSugarLog,
        MissedMedicationsPageActions.deleteBloodSugarLog,
        RoutineMarDetailPageActions.deleteBloodSugarLog,
        RoutineMarListPageActions.deleteBloodSugarLog,
        GivePrnFormActions.deleteBloodSugarLog,
        CompleteCareTaskModalActions.deleteBloodSugarLog
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete blood sugar log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.bloodSugarLogsService.delete(action.id, action.params);
        },
        onSuccess: (bloodSugarLog: IBloodSugarLog) =>
          BloodSugarLogsApiActions.deleteBloodSugarLogSuccess({
            id: bloodSugarLog.id
          }),
        onError: (error) =>
          BloodSugarLogsApiActions.deleteBloodSugarLogFail({ error })
      })
    );
  });
}
