import { find, includes } from 'lodash';
import { MeasurementId, measurements } from '../lookups/measurements.lookup';

export function normalizeMeasurement(
  measurement: string
): MeasurementId | undefined {
  if (!measurement) {
    return undefined;
  }

  measurement = measurement.toLowerCase();

  const match = find(measurements, (m) =>
    includes(m.alternatives, measurement)
  );

  return match?.id;
}
