import { Table } from '$/models/data/base';
import { DocumentLevelId } from '$shared/lookups/document-levels.lookup';
import { DocumentParticipantRoleId } from '$shared/lookups/document-participant-roles.lookup';
import { DocumentRequirementId } from '$shared/lookups/document-requirements.lookup';
import { DocumentScopeId } from '$shared/lookups/document-scopes.lookup';
import { DocumentStatusId } from '$shared/lookups/document-statuses.lookup';
import { DocumentTypeId } from '$shared/lookups/document-types.lookup';
import { ResidentStageId } from '$shared/lookups/resident-stages.lookup';

import {
  IDocument,
  IDocumentParticipant,
  IDocumentTemplate,
  IDocumentTemplateSchedule,
  ISignerIdentity
} from './document-tables.interface';

export class Document extends Table implements IDocument {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  facilityUserId?: string;
  documentTemplateId?: string;
  batchId?: string;
  fileId?: string;

  code?: string;
  name?: string;
  description?: string;
  status?: DocumentStatusId;
  statusChangedAt?: string;
  formData?: any;
  formSchemaVersion?: number | null;
  formDraftData?: any;
  version?: number;
  expiresAt?: string;
  dueAt?: string;
  digitalFormId?: string;
  isCurrentVersion?: boolean;
  autoGenerated?: boolean;
  signature?: string | null;
  signedAt?: string | null;

  constructor(document?: IDocument) {
    super();
    if (document) {
      Object.assign(this, document);
    }
  }
}

export class DocumentTemplate extends Table implements IDocumentTemplate {
  orgId?: string;
  facilityId?: string;
  libraryDocumentTemplateId?: string;
  fileId?: string;

  name?: string;
  description?: string;
  code?: string | null;
  level?: DocumentLevelId;
  type?: DocumentTypeId;
  scope?: DocumentScopeId;
  residentStage?: ResidentStageId;
  requirement?: DocumentRequirementId;
  recommendedRequirement?: DocumentRequirementId;
  schedule?: IDocumentTemplateSchedule;
  version?: number;
  digitalFormId?: string;

  constructor(documentTemplate?: IDocumentTemplate) {
    super();

    if (documentTemplate) {
      Object.assign(this, documentTemplate);
    }
  }
}

export class DocumentParticipant extends Table implements IDocumentParticipant {
  orgId?: string;
  facilityId?: string;
  documentId?: string;
  facilityUserId: string;

  signerIdentity?: ISignerIdentity | null;
  role?: DocumentParticipantRoleId | null;
  signatureFileId?: string | null;
  signingToken?: string | null;
  signature?: string | null;
  signedAt?: string | null;

  constructor(documentParticipant?: IDocumentParticipant) {
    super();

    if (documentParticipant) {
      Object.assign(this, documentParticipant);
    }
  }
}
