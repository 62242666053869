import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';

import { ShiftNotesFilters } from '../shift-notes.vmodel';

export const ShiftNotesFilterPageActions = createActionGroup({
  source: 'Shift Notes Filter Page',
  events: {
    'Filter Changed': (
      filters: ShiftNotesFilters
    ): { filters: ShiftNotesFilters; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
