import { FacilityTimeService } from '$/app/services';
import {
  DateTimeFormatName,
  DateTimeFormats
} from '$shared/constants/datetime-formats';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DateTime } from 'luxon';
import { AlcCommonModule } from '../../alc-common.module';
import {
  AlcDateInputComponent,
  AlcDateInputEvent
} from '../../form-controls/date-input/date-input.component';

@Component({
  selector: 'alc-date-filter-header',
  standalone: true,
  imports: [AlcCommonModule, AlcDateInputComponent],
  templateUrl: './date-filter-header.component.html'
})
export class AlcDateFilterHeaderComponent {
  private readonly ft = inject(FacilityTimeService);

  @Input() public moveBy: 'days' | 'weeks' | 'months' = 'days';
  @Input() public dateFormat: DateTimeFormatName = 'FULL_DATE';
  @Input() public showDatePicker = true;

  @Input('date')
  public get currentDate(): DateTime {
    return this._currentDate;
  }
  public set currentDate(date: string) {
    this._currentDate = this.ft.createDate(date);
  }
  private _currentDate: DateTime;

  @Output() public dateChange = new EventEmitter<string>();

  protected get format() {
    return DateTimeFormats[this.dateFormat];
  }

  protected nextDay() {
    this._currentDate = this.currentDate.plus({ [this.moveBy]: 1 });
    const date = this.currentDate.toFormat(DateTimeFormats.DATE_SHORT);
    this.dateChange.emit(date);
  }

  protected previousDay() {
    this._currentDate = this.currentDate.minus({ [this.moveBy]: 1 });
    const date = this.currentDate.toFormat(DateTimeFormats.DATE_SHORT);
    this.dateChange.emit(date);
  }

  protected changeDate(event: AlcDateInputEvent) {
    this._currentDate = event.value;
    const date = this.currentDate.toFormat(DateTimeFormats.DATE_SHORT);
    this.dateChange.emit(date);
  }
}
