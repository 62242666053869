import { AcknowledgementsPageActions } from '$/app/pages/communication/acknowledgements/acknowledgements.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AcknowledgementsApiActions,
  AcknowledgementsGeneralActions,
  AcknowledgementsWsActions
} from './acknowledgements.actions';
import {
  acknowledgementsAdapter,
  initialState
} from './acknowledgements.state';

export const acknowledgementsFeature = createFeature({
  name: 'acknowledgements',
  reducer: createReducer(
    initialState,

    on(AcknowledgementsPageActions.loadAcknowledgements, (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),

    on(
      AcknowledgementsApiActions.loadAcknowledgementsSuccess,
      (state, action) => {
        return acknowledgementsAdapter.setAll(action.acknowledgements, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      AcknowledgementsApiActions.updateAcknowledgementSuccess,
      (state, action) => {
        return acknowledgementsAdapter.updateOne(action.acknowledgement, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      AcknowledgementsApiActions.loadAcknowledgementsFail,
      AcknowledgementsApiActions.updateAcknowledgementFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      AcknowledgementsWsActions.acknowledgementsCreated,
      AcknowledgementsWsActions.acknowledgementsPatched,
      (state, action) => {
        return acknowledgementsAdapter.upsertOne(action.acknowledgement, {
          ...state
        });
      }
    ),

    on(AcknowledgementsWsActions.acknowledgementsRemoved, (state, action) => {
      return acknowledgementsAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(AcknowledgementsGeneralActions.clearAcknowledgements, (state) => {
      return acknowledgementsAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    }),

    on(AcknowledgementsGeneralActions.addAcknowledgements, (state, action) => {
      return acknowledgementsAdapter.addMany(action.acknowledgements, {
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
