import { FacilityUserGroup, Update } from '$/models';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityUserGroupsApiActions = createActionGroup({
  source: 'Facility User Group API',
  events: {
    'Load Facility User Groups Success': props<{
      facilityUserGroups: FacilityUserGroup[];
    }>(),
    'Get Facility User Groups Success': props<{
      facilityUserGroups: FacilityUserGroup[];
    }>(),
    'Fetch Facility User Group Success': props<{
      facilityUserGroup: FacilityUserGroup;
    }>(),
    'Create Facility User Group Success': props<{
      facilityUserGroup: MaybeArray<FacilityUserGroup>;
    }>(),
    'Update Facility User Group Success': props<{
      facilityUserGroup: Update<FacilityUserGroup>;
    }>(),
    'Delete Facility User Group Success': props<{ id: string }>(),

    'Load Facility User Groups Fail': props<{ error: Error }>(),
    'Get Facility User Groups Fail': props<{ error: Error }>(),
    'Fetch Facility User Group Fail': props<{ error: Error }>(),
    'Create Facility User Group Fail': props<{ error: Error }>(),
    'Update Facility User Group Fail': props<{ error: Error }>(),
    'Delete Facility User Group Fail': props<{ error: Error }>()
  }
});
