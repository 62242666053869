import { Params, Update } from '$/models';
import { IResident } from '$shared/residents/residents.interface';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentModalActions = createActionGroup({
  source: 'Resident Modal',
  events: {
    'Load Rooms': (params: Params = { query: {} }) => ({ params }),
    'Load Pharmacies': (params: Params = { query: {} }) => ({ params }),
    'Create Resident': props<{ resident: IResident; params?: Params }>(),
    'Update Resident': props<Update<IResident>>()
  }
});
