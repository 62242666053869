import { Table } from '$/models/data/base';
import { CareTaskStateId } from '$shared/lookups/care-task-states.lookup';

export class CareTask extends Table {
  facilityId?: string;
  orgId?: string;
  residentId?: string;
  carePlanItemId?: string;
  carePlanItemScheduleId?: string;
  careTaskOmittedReasonId?: string;
  omittedNotes?: string;
  completedAt?: string;
  completedBy?: string;
  currentState?: CareTaskStateId;
  scheduledFor?: string;

  constructor(careTask: CareTask) {
    super();
    Object.assign(this, careTask);
  }
}
