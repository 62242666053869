import { FeathersService } from '$/app/services/feathers.service';
import { MedicalProfessionalPhonesApiActions } from '$/app/store/medical-professional-phones/actions';
import { toUpdated } from '$/app/utils';
import { MedicalProfessionalPhone, Paginated, Params, Update } from '$/models';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MedicalProfessionalPhonesApiService {
  private medicalProfessionalPhonesService: Service<MedicalProfessionalPhone>;

  constructor(feathers: FeathersService, store: Store) {
    this.medicalProfessionalPhonesService = feathers.client.service(
      'medical-professional-phones'
    );

    this.medicalProfessionalPhonesService.on(
      'created',
      (medicalProfessionalPhone) => {
        store.dispatch(
          MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesCreated({
            medicalProfessionalPhone
          })
        );
      }
    );

    this.medicalProfessionalPhonesService.on(
      'patched',
      (medicalProfessionalPhone) => {
        store.dispatch(
          MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesPatched({
            medicalProfessionalPhone
          })
        );
      }
    );

    this.medicalProfessionalPhonesService.on(
      'removed',
      (medicalProfessionalPhone) => {
        store.dispatch(
          MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesRemoved({
            id: medicalProfessionalPhone.id
          })
        );
      }
    );
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<MedicalProfessionalPhone>> {
    const promise = this.medicalProfessionalPhonesService.find(
      params
    ) as Promise<Paginated<MedicalProfessionalPhone>>;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<MedicalProfessionalPhone> {
    const promise = this.medicalProfessionalPhonesService.get(id, params);
    return from(promise);
  }

  create(
    medicalProfessionalPhone:
      | MedicalProfessionalPhone
      | MedicalProfessionalPhone[]
  ) {
    const promise = this.medicalProfessionalPhonesService.create(
      medicalProfessionalPhone
    );
    return from(promise);
  }

  patch(
    id: string,
    medicalProfessionalPhone: Partial<MedicalProfessionalPhone>
  ): Observable<Update<MedicalProfessionalPhone>> {
    const promise = this.medicalProfessionalPhonesService.patch(
      id,
      medicalProfessionalPhone
    );

    return from(promise).pipe(map(toUpdated));
  }

  delete(
    id: string,
    params: Params = { query: {} }
  ): Observable<MedicalProfessionalPhone> {
    const promise = this.medicalProfessionalPhonesService.remove(id, params);
    return from(promise);
  }
}
