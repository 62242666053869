import { AlcomyErrorCodes } from '$/config';
import { AlcomyError, AlcomyErrorMessage } from './alcomy-error.class';

/**
 * The user stopped (aborted) the action they were in to process
 * of performing which prevented further processing to to take place.
 * i.e. If a certain action requires the user's input
 * in order to continue processing (via prompt or modal), and the user
 * doesn't give that input (e.g. cancelling the modal) this exception could
 * be thrown.
 */
export class UserAborted extends AlcomyError {
  constructor(message: AlcomyErrorMessage, data?: any) {
    super(message, `${UserAborted.name}`, AlcomyErrorCodes.USER_ABORTED, data);
  }
}
