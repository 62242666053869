export function updateSplashScreenText(message: string): void {
  const splashScreenText = document.getElementById('splash-screen-text');

  splashScreenText.innerHTML = message;
}

export function hideSplashScreen(): void {
  const splashScreen = document.getElementById('splash-screen');

  splashScreen.style.display = 'none';
}
