import * as RouterSelectors from '$/app/store/router/router.selectors';
import { IBehavior } from '$shared/services/behavior';
import { createSelector } from '@ngrx/store';
import { groupBy, isEmpty } from 'lodash';
import { behaviorsFeature } from './behaviors.reducer';
import { behaviorsAdapter } from './behaviors.state';

export const {
  selectBehaviorsState,
  selectEntities,
  selectMetrics,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = behaviorsFeature;

export const { selectAll, selectTotal } =
  behaviorsAdapter.getSelectors(selectBehaviorsState);

export const selectResidentBehaviors = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (behaviors, residentId): IBehavior[] => {
    if (isEmpty(behaviors) || !residentId) {
      return [];
    }

    const sortedBehaviors = behaviors
      .filter((behavior) => {
        return behavior.residentId === residentId;
      })
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

    return sortedBehaviors;
  }
);

export const selectBehaviorsByResident = createSelector(
  selectAll,
  (behaviors) => groupBy(behaviors, 'residentId')
);
