import { ShowerLog, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ShowerLogFormPageActions = createActionGroup({
  source: 'Shower Log Form Page',
  events: {
    'Create Shower Log': props<{ showerLog: ShowerLog }>(),
    'Update Shower Log': props<Update<ShowerLog>>()
  }
});
