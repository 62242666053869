import { createSelector } from '@ngrx/store';
import { keyBy } from 'lodash';

import { libraryCollectionDocumentTemplatePacketsFeature } from './library-collection-document-template-packets.reducer';
import { libraryCollectionDocumentTemplatePacketsAdapter } from './library-collection-document-template-packets.state';

export const {
  selectLibraryCollectionDocumentTemplatePacketsState,
  selectEntities,
  selectIds,
  selectPagination,
  selectLoading,
  selectLoaded,
  selectError
} = libraryCollectionDocumentTemplatePacketsFeature;

export const { selectAll, selectTotal } =
  libraryCollectionDocumentTemplatePacketsAdapter.getSelectors(
    selectLibraryCollectionDocumentTemplatePacketsState
  );

export const selectByCollectionIdAndPacketId = createSelector(
  selectAll,
  (collectionPackets) => {
    return keyBy(
      collectionPackets,
      (collectionPacket) =>
        `${collectionPacket.libraryCollectionId}-${collectionPacket.libraryDocumentTemplatePacketId}`
    );
  }
);
