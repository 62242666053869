import { CommunityLibraryModalActions } from '$/app/pages/documents/community-library/community-library.modal.actions';
import { LibraryCollectionsApiService } from '$/app/services/api/library-collections.service';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { LibraryCollectionsApiActions } from './library-collections.actions';

@Injectable()
export class LibraryCollectionsEffects {
  private readonly actions$ = inject(Actions);
  private readonly libraryCollectionsService = inject(
    LibraryCollectionsApiService
  );

  loadLibraryCollections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CommunityLibraryModalActions.loadLibraryCollections),
      switchMap((action) => {
        return this.libraryCollectionsService.getAll(action.params).pipe(
          getAllPages(this.libraryCollectionsService, action?.params?.query),
          reducePaginatedResponses(),
          mergeMap((response) => {
            const responseData = new ApiData(
              'libraryCollections',
              response,
              LibraryCollectionsApiActions.loadLibraryCollectionsSuccess
            );

            return responseData.getActions();
          }),
          catchError((error) => {
            return of(
              LibraryCollectionsApiActions.loadLibraryCollectionsFail({ error })
            );
          })
        );
      })
    );
  });
}
