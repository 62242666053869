import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MedicalProfessionalPhonesEffects } from './medical-professional-phones.effects';
import { medicalProfessionalPhonesFeature } from './medical-professional-phones.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(medicalProfessionalPhonesFeature),
    EffectsModule.forFeature([MedicalProfessionalPhonesEffects])
  ],
  providers: [MedicalProfessionalPhonesEffects]
})
export class MedicalProfessionalPhonesStoreModule {}
