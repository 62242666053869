import { sortByName } from '$/app/utils';
import { IPaginationInfo } from '$/models';
import { IResident } from '$shared/residents/residents.interface';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const residentsAdapter = createEntityAdapter<IResident>({
  sortComparer: sortByName
});

export interface State extends EntityState<IResident> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = residentsAdapter.getInitialState({
  pagination: {
    total: 0,
    skip: 0
  },
  loading: false,
  loaded: false,
  error: null
});
