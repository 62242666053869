import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ShiftNotesEffects } from './shift-notes.effects';
import { shiftNotesReducer } from './shift-notes.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('shiftNotes', shiftNotesReducer),
    EffectsModule.forFeature([ShiftNotesEffects])
  ],
  providers: [ShiftNotesEffects]
})
export class ShiftNotesStoreModule {}
