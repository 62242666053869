import { sifter } from '$/app/utils';
import { IBodyCheckObservation } from '$shared/services/body-check-observation';
import { Dictionary } from '$shared/types';
import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import { Query } from 'sift';
import { bodyCheckObservationsFeature } from './body-check-observations.reducer';
import { bodyCheckObservationsAdapter } from './body-check-observations.state';

export const {
  selectBodyCheckObservationsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = bodyCheckObservationsFeature;

export const { selectAll, selectTotal } =
  bodyCheckObservationsAdapter.getSelectors(selectBodyCheckObservationsState);

export const selectAllByBodyCheckLogId = createSelector(
  selectAll,
  (observations): Dictionary<IBodyCheckObservation[]> => {
    return groupBy(observations, 'bodyCheckLogId');
  }
);

export const selectBodyCheckObservationsByQuery = (
  query: Query<IBodyCheckObservation>
) => {
  return createSelector(selectAll, (bodyCheckObservations) => {
    const filteredBodyCheckObservations = bodyCheckObservations.filter(
      sifter<IBodyCheckObservation>(query)
    );
    return filteredBodyCheckObservations;
  });
};
