import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LibraryDocumentTemplatePacketTemplatesEffects } from './library-document-template-packet-templates.effects';
import { libraryDocumentTemplatePacketTemplatesFeature } from './library-document-template-packet-templates.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(libraryDocumentTemplatePacketTemplatesFeature),
    EffectsModule.forFeature([LibraryDocumentTemplatePacketTemplatesEffects])
  ],
  providers: [LibraryDocumentTemplatePacketTemplatesEffects]
})
export class LibraryDocumentTemplatePacketTemplatesStoreModule {}
