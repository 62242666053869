import { FacilityUserRolesApiService } from '$/app/services';
import { Injectable, inject } from '@angular/core';

@Injectable()
export class FacilityUserRolesEffects {
  // NOTE: Do not remove - required for web socket events
  private readonly facilityUserRolesService = inject(
    FacilityUserRolesApiService
  );
}
