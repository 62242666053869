import * as ResidentMedicalProfessionalsListActions from './resident-medical-professionals-list.actions';
import * as ResidentMedicalProfessionalDetailActions from './resident-medical-professional-detail.actions';
import * as ResidentMedicalProfessionalFormActions from './resident-medical-professional-form.actions';
import * as ResidentMedicalProfessionalsApiActions from './resident-medical-professionals-api.actions';
import * as ResidentMedicalProfessionalsWsActions from './resident-medical-professionals-ws.actions';
import * as ResidentMedicalProfessionalsGeneralActions from './resident-medical-professionals-gen.actions';

export {
  ResidentMedicalProfessionalsListActions,
  ResidentMedicalProfessionalDetailActions,
  ResidentMedicalProfessionalFormActions,
  ResidentMedicalProfessionalsApiActions,
  ResidentMedicalProfessionalsWsActions,
  ResidentMedicalProfessionalsGeneralActions
};
