import { Table } from '$/models/data/base';
import { MedicalProfessionalTypeId } from '$shared/lookups/medical-professional-types.lookup';

export class MedicalProfessional extends Table {
  orgId?: string;
  facilityId?: string;
  archivedAt?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  typeId?: MedicalProfessionalTypeId;
  specialty?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  notes?: string;
}

export interface MedicalProfessionalExtras {
  isPrimary?: boolean;
  residentMedicalProfessionalId?: string;
}
