import { FeathersService } from '$/app/services/feathers.service';
import { IPaginatedResponse, Params } from '$/models';
import { BulkResult } from '$shared/types/bulk-updates';
import { MaybeArray } from '$shared/types/utility-types';
import { Service } from '@feathersjs/feathers';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { map } from 'lodash';
import pluralize from 'pluralize';
import { Observable, from } from 'rxjs';

interface IActions<T> {
  entityName: string;
  created?: (payload: Dictionary<T>) => Action;
  patched?: (payload: Dictionary<T>) => Action;
  removed?: (payload: { id: string }) => Action;
  multipleCreated?: (payload: Dictionary<T[]>) => Action;
  multiplePatched?: (payload: Dictionary<T[]>) => Action;
  multipleRemoved?: (payload: { ids: string[] }) => Action;
}

export abstract class AbstractApiService<
  T extends Record<string, any> = Record<string, any>
> {
  protected readonly service: Service<T>;

  constructor(
    serviceName: string,
    feathers: FeathersService,
    protected store: Store,
    actions?: IActions<T>
  ) {
    this.service = feathers.client.service(serviceName);

    if (actions?.created || actions?.multipleCreated) {
      this.service.on('created', (entity: T | BulkResult<T>) => {
        switch (true) {
          case !!actions?.multipleCreated && 'bulkRecords' in entity:
            const pluralEntityName = pluralize(actions.entityName);

            this.store.dispatch(
              actions.multipleCreated({
                [pluralEntityName]: entity.bulkRecords
              })
            );

            break;
          case !!actions?.created && !('bulkRecords' in entity):
            this.store.dispatch(
              actions.created({ [actions.entityName]: entity })
            );
            break;
        }
      });
    }

    if (actions?.patched || actions?.multiplePatched) {
      this.service.on('patched', (entity: T | BulkResult<T>) => {
        switch (true) {
          case !!actions?.multiplePatched && 'bulkRecords' in entity:
            const pluralEntityName = pluralize(actions.entityName);

            this.store.dispatch(
              actions.multiplePatched({
                [pluralEntityName]: entity.bulkRecords
              })
            );

            break;
          case !!actions?.patched && !('bulkRecords' in entity):
            this.store.dispatch(
              actions.patched({ [actions.entityName]: entity })
            );
            break;
        }
      });
    }

    if (actions?.removed || actions?.multipleRemoved) {
      this.service.on('removed', (entity: T | BulkResult<T>) => {
        switch (true) {
          case !!actions?.multipleRemoved && 'bulkRecords' in entity:
            this.store.dispatch(
              actions.multipleRemoved({
                ids: map(entity.bulkRecords, 'id')
              })
            );

            break;
          case !!actions?.removed && !('bulkRecords' in entity):
            this.store.dispatch(actions.removed({ id: entity.id }));
            break;
        }
      });
    }
  }

  getAll(params: Params = { query: {} }) {
    return from(
      this.service.find(params) as Promise<IPaginatedResponse<T> | T[]>
    );
  }

  get(id: string, params: Params = { query: {} }) {
    return from(this.service.get(id, params));
  }

  create(
    entityOrEntities: MaybeArray<Partial<T>>,
    params: Params = { query: {} }
  ): T extends Array<infer U> ? Observable<U[]> : Observable<T> {
    return from(this.service.create(entityOrEntities, params)) as any;
  }

  patch(id: string | null, entity: Partial<T>, params: Params = { query: {} }) {
    return from(this.service.patch(id, entity, params));
  }

  delete(id: string | null, params: Params = { query: {} }) {
    return from(this.service.remove(id, params));
  }
}
