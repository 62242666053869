import { FeathersService } from '$/app/services/feathers.service';
import { FacilityUserGroupsWsActions } from '$/app/store/facility-user-groups/actions';
import { FacilityUserGroup } from '$/models';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from './abstract-api-service.service';

@Injectable({ providedIn: 'root' })
export class FacilityUserGroupsApiService extends AbstractApiService<FacilityUserGroup> {
  constructor(feathers: FeathersService, store: Store) {
    super('facility-user-groups', feathers, store, {
      entityName: 'facilityUserGroup',
      created: FacilityUserGroupsWsActions.facilityUserGroupsCreated,
      patched: FacilityUserGroupsWsActions.facilityUserGroupsPatched,
      removed: FacilityUserGroupsWsActions.facilityUserGroupsRemoved
    });
  }
}
