import { FacilityPhone, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityPhonesGeneralActions = createActionGroup({
  source: 'Facility Phones General',
  events: {
    'Clear Facility Phones': (params: Params) => ({ params }),
    'Add Facility Phones': props<{ facilityPhones: FacilityPhone[] }>()
  }
});
