import { CreatePacketFromTemplateModalActions } from '$/app/pages/documents/create-packet-from-template/create-packet-from-template.actions';
import { DocumentPacketFormPageActions } from '$/app/pages/documents/document-packet-form/document-packet-form.actions';
import { ResidentDocumentPacketsPageActions } from '$/app/pages/documents/resident-document-packets/resident-document-packets.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DocumentPacketsApiActions,
  DocumentPacketsGeneralActions,
  DocumentPacketsWsActions
} from './document-packets.actions';
import { documentPacketsAdapter, initialState } from './document-packets.state';

export const documentPacketsFeature = createFeature({
  name: 'documentPackets',
  reducer: createReducer(
    initialState,

    on(
      ResidentDocumentPacketsPageActions.getDocumentPackets,
      DocumentPacketFormPageActions.createDocumentPacket,
      CreatePacketFromTemplateModalActions.createDocumentPackets,
      DocumentPacketFormPageActions.updateDocumentPacket,
      ResidentDocumentPacketsPageActions.deleteDocumentPacket,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      DocumentPacketsApiActions.loadDocumentPacketsSuccess,
      (state, action) => {
        const {
          documentPackets: { data: records, ...pagination }
        } = action;

        return documentPacketsAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(DocumentPacketsApiActions.getDocumentPacketsSuccess, (state, action) => {
      const {
        documentPackets: { data: records, ...pagination }
      } = action;

      return documentPacketsAdapter.upsertMany(records, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      DocumentPacketsApiActions.fetchDocumentPacketSuccess,
      (state, action) => {
        return documentPacketsAdapter.upsertOne(action.documentPacket, {
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    ),

    on(
      DocumentPacketsApiActions.createDocumentPacketSuccess,
      (state, action) => {
        return documentPacketsAdapter.addOne(action.documentPacket, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(
      DocumentPacketsApiActions.createDocumentPacketsSuccess,
      (state, action) => {
        return documentPacketsAdapter.addMany(action.documentPackets, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(
      DocumentPacketsApiActions.updateDocumentPacketSuccess,
      (state, action) => {
        return documentPacketsAdapter.upsertOne(action.documentPacket, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(DocumentPacketsApiActions.deleteDocumentPacketSuccess, (state, action) =>
      documentPacketsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      DocumentPacketsApiActions.loadDocumentPacketsFail,
      DocumentPacketsApiActions.getDocumentPacketsFail,
      DocumentPacketsApiActions.createDocumentPacketFail,
      DocumentPacketsApiActions.createDocumentPacketsFail,
      DocumentPacketsApiActions.updateDocumentPacketFail,
      DocumentPacketsApiActions.deleteDocumentPacketFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      DocumentPacketsWsActions.documentPacketCreated,
      DocumentPacketsWsActions.documentPacketPatched,
      (state, action) => {
        return documentPacketsAdapter.upsertOne(action.documentPacket, state);
      }
    ),

    on(DocumentPacketsWsActions.documentPacketRemoved, (state, action) => {
      return documentPacketsAdapter.removeOne(action.id, state);
    }),

    on(DocumentPacketsGeneralActions.addDocumentPackets, (state, action) => {
      return documentPacketsAdapter.upsertMany(action.documentPackets, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(DocumentPacketsGeneralActions.clearDocumentPackets, () => {
      return documentPacketsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
